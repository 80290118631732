import { useMutation } from "@tanstack/react-query";
import { useSetAtom } from "jotai";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { fetchApi } from "../../api/api";
import "./auth.style.css";
import { roleAtom, tokenAtom, userAtom, userIdAtom } from "../../store";
import {
  HandleStoreBlobAndBase64,
  blobToBase64,
  handleFormError,
} from "../../untilites/functions";
import { LoadingPage } from "../../components/Common/loadingPage.component";
import { CustomImageInput } from "../../components/helpers";
import i18n from "i18next";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { register } from "../../interfaces/new-api-interfaces/auth";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import fallback from "../../assets/images/user-holder.png";
import bg from "../../assets/images/profile/profile-bg.jpg";
import { MuiPhone } from "../../components/Common/mui-phone";
import i18next from "i18next";
import CustomTextField from "../../components/Common/custome-text-field";

interface Props {}

const inputStyle = {
  width: { xs: "100%", sm: "45%" },
  marginBottom: "30px",
};

const Register: React.FC<Props> = () => {
  const { isLoading, mutateAsync } = useMutation(
    (data: any) => fetchApi("/auth/user/signUp", "POST", data),
    {
      onError: (error: any) => {
        if (error?.response?.status === 422) {
          handleFormError(error.response, setError, toast, i18next);
        }
      },
    }
  );

  const { t } = useTranslation();
  const navigate = useNavigate();
  const setToken = useSetAtom(tokenAtom);
  const setRole = useSetAtom(roleAtom);
  const setUserId = useSetAtom(userIdAtom);
  const setUser = useSetAtom(userAtom);
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
    watch,
  } = useForm<register>({
    defaultValues: {},
  });

  const location = useLocation();

  const handleNavigation = (role: string) => {
    // Check if there is a previous page
    if (window.history.length > 2) {
      if (location.state?.from === "/login") {
        // If the previous page is "login", go back 2 steps in history
        navigate(-2);
      } else {
        // Go back to the previous page
        navigate(-1);
      }
    } else {
      // If no previous page, navigate based on the role and replace the history stack
      if (role === "instructor") {
        navigate("/profile", { replace: true });
      } else {
        navigate("/student-profile", { replace: true });
      }
    }
  };

  const onSubmit: SubmitHandler<register> = async (data) => {
    const modifiedData = {
      ...data,
      role: "student",
    };
    try {
      mutateAsync(modifiedData).then((response) => {
        if (response.status === 200) {
          const { data } = response.data;
          const {
            token,
            role,
            id,
            first_name,
            last_name,
            email,
            phone_number,
            address,
            birth_date,
            profile_image,
          } = data;

          if (!data.email_verified_at) {
            toast.warning(
              i18n.language === "ar"
                ? "بريدك الإلكتروني غير محقق"
                : "Your email is not verified"
            );
            const verifyState = {
              email: data.email,
              email_verified_at: data.email_verified_at,
            };
            navigate("/forget", {
              state: verifyState,
            });
          } else {
            setToken(token);
            setRole(role);
            setUserId(id);
            setUser({
              id,
              firstName: first_name,
              lastName: last_name,
              email,
              phoneNumber: phone_number,
              address,
              age: birth_date,
              role,
              profileImage: profile_image,
            });

            handleNavigation(role);
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const [localError, setLocalError] = useState(null); //to handle file uploading errors

  if (localError)
    toast.error(
      i18n.language === "en"
        ? "something went wrong uploading the picture"
        : "حصل خطأأثناء رفع الصورة"
    );
  return (
    <Box
      sx={{
        backgroundImage: `url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPositionX: "right",
      }}
      style={{ backgroundPositionX: "right" }}
    >
      <div
        style={{
          direction: `${i18n.language === "en" ? "ltr" : "rtl"}`,
          padding: "10vh 0",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            textAlign: "center",
            paddingBottom: { xs: "100px", md: "150px" },
          }}
          color="primary.main"
          style={{ direction: `${i18n.language === "en" ? "ltr" : "rtl"}` }}
        >
          {t("LoginFormRegister")}
        </Typography>
        <Box
          className="login-form-wrapper mt-sm-50 mt-xs-50"
          sx={{
            direction: `${i18n.language === "en" ? "ltr" : "rtl"}`,
            width: { xs: "90%", md: "70%" },
          }}
        >
          <form
            style={{
              direction: `${i18n.language === "en" ? "ltr" : "rtl"}`,
              position: "relative",
              paddingTop: "150px",
              gap: "2%",
            }}
            className="register-form d-flex flex-wrap justify-content-center"
            onSubmit={handleSubmit(onSubmit)}
          >
            {!isLoading && (
              <>
                <Box sx={{ position: "absolute", top: "-130px" }}>
                  <Controller
                    name="profile_image"
                    control={control}
                    render={({ field }) => (
                      <CustomImageInput
                        file={field.value}
                        handleChange={(e: any) => {
                          blobToBase64(e)
                            .then((value) => {
                              field.onChange(value); // Update the field value with the base64 string
                            })
                            .catch((err) => {
                              console.log(err);
                              setLocalError(err);
                            });
                        }}
                        fieldName={field.name}
                        classesForStyles="pb-5"
                        fallback={fallback}
                      />
                    )}
                  />
                </Box>

                <CustomTextField
                  name="first_name"
                  control={control}
                  defaultValue=""
                  label={t("GlobalFirstName")}
                  placeholder={t("GlobalFirstName")}
                  required
                  errors={errors}
                  inputStyle={inputStyle}
                />
                <CustomTextField
                  name="last_name"
                  control={control}
                  defaultValue=""
                  label={t("GlobalLastName")}
                  placeholder={t("GlobalLastName")}
                  required
                  inputStyle={inputStyle}
                  errors={errors}
                />
                <CustomTextField
                  name="first_name_en"
                  control={control}
                  defaultValue=""
                  label={t("GlobalFirstNameEnglish")}
                  placeholder={t("GlobalFirstNameEnglish")}
                  required
                  errors={errors}
                  inputStyle={inputStyle}
                />

                <CustomTextField
                  name="last_name_en"
                  control={control}
                  defaultValue=""
                  label={t("GlobalLastNameEnglish")}
                  placeholder={t("GlobalLastNameEnglish")}
                  required
                  inputStyle={inputStyle}
                  errors={errors}
                />

                <CustomTextField
                  name="email"
                  control={control}
                  defaultValue=""
                  label={i18n.language === "en" ? "Email" : "البريد الإلكتروني"}
                  placeholder={
                    i18n.language === "en" ? "Email" : "البريد الإلكتروني"
                  }
                  required
                  inputStyle={inputStyle}
                  errors={errors}
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message:
                        i18n.language === "en"
                          ? "Invalid email address"
                          : "البريد الإلكتروني غير صالح",
                    },
                  }}
                />

                <Controller
                  name="phone_number"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <MuiPhone
                      onChange={setValue}
                      value={watch("phone_number")}
                      controle_name="phone_number"
                      label={
                        i18n.language === "en" ? "Phone Number" : "رقم الهاتف"
                      }
                      sx={inputStyle}
                      forceDialCode={true}
                      setError={setError}
                      error={!!errors.phone_number}
                      helperText={errors.phone_number?.message}
                      clearError={() => clearErrors("phone_number")}
                      setIsPhoneValid={setIsPhoneValid}
                    />
                  )}
                />

                <CustomTextField
                  name="password"
                  control={control}
                  defaultValue=""
                  label={t("GlobalPassword")}
                  placeholder={t("GlobalPassword")}
                  required
                  inputStyle={inputStyle}
                  errors={errors}
                  type={showPassword ? "text" : "password"}
                  rules={{
                    required: "Confirm Password is required",
                    minLength: {
                      value: 8,
                      message:
                        i18n.language === "ar"
                          ? "يجب ان تكون كلمة المرور 8 محارف على الأقل"
                          : "Password must be at least 8 characters long",
                    },
                    validate: (value) =>
                      value == confirmPassword ||
                      "Password and Confirmation do not match",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword((prev) => !prev)}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label={t("GlobalConfirmPassword")}
                  type={showConfirmPassword ? "text" : "password"}
                  id="passwordOne"
                  name="confirm_password"
                  placeholder={t("GlobalConfirmPassword")}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  sx={inputStyle}
                  error={
                    confirmPassword !== watch("password") &&
                    confirmPassword != ""
                  }
                  helperText={
                    confirmPassword !== watch("password") &&
                    confirmPassword != ""
                      ? i18n.language === "en"
                        ? "Password and Confirmation do not match"
                        : "كلمة المرور والتأكيد غير متطابقان"
                      : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setShowConfirmPassword((prev) => !prev)
                          }
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <CustomTextField
                  name="address"
                  control={control}
                  defaultValue=""
                  label={t("GlobalAddress")}
                  placeholder={t("GlobalAddress")}
                  required
                  inputStyle={{
                    width: { xs: "100%", sm: "92%" },
                    marginBottom: "30px",
                  }}
                  errors={errors}
                />

                <CustomTextField
                  name="birth_date"
                  defaultValue={new Date()}
                  control={control}
                  label={t("birthDate")}
                  type="date"
                  placeholder={t("birthDate")}
                  required
                  inputStyle={inputStyle}
                  errors={errors}
                  inputProps={{
                    style: {
                      textAlign: i18next.language == "en" ? "left" : "right",
                    },
                  }}
                />
                <Controller
                  name="gender"
                  control={control}
                  defaultValue="male"
                  render={({ field }) => (
                    <FormControl sx={inputStyle}>
                      <InputLabel htmlFor="gender" id="gender-select-label">
                        {t("Gender")}
                      </InputLabel>
                      <Select
                        labelId="gender-select-label"
                        label={t("Gender")}
                        {...field}
                        MenuProps={{ disableScrollLock: true }}
                        inputProps={{
                          id: "gender",
                        }}
                      >
                        <MenuItem value="male">{t("Male")}</MenuItem>
                        <MenuItem value="female">{t("Female")}</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />

                <Box
                  className="single-input"
                  sx={{
                    direction: `${i18n.language === "en" ? "ltr" : "rtl"}`,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: { xs: "100%", sm: "92%", lg: "30%" },
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className="mb-2 btn-hover-secondary btn-width-100"
                    type="submit"
                    disabled={isPhoneValid == false}
                  >
                    <Typography fontSize="large" color="#fff">
                      {t("LoginFormRegister")}
                    </Typography>
                  </Button>
                  <div
                    style={{
                      width: "100%",
                      marginTop: "2em",
                      gap: 5,
                      display: "flex",
                      justifyContent: "center",
                      flexDirection:
                        i18n.language === "ar" ? "row-reverse" : "row",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "120%",
                      }}
                    >
                      {t("AlreadyHaveAccount")}
                    </Typography>
                    <Link to="/login" className="underline">
                      <Typography
                        sx={{
                          color: "#001233",
                          fontWeight: "bold",
                          fontSize: "120%",
                          textDecoration: "underline",
                        }}
                      >
                        {t("AuthFormLog")}
                      </Typography>
                    </Link>
                  </div>
                </Box>
              </>
            )}
            {isLoading && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  minHeight: "500px",
                  paddingBottom: "150px",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </form>
        </Box>
      </div>
    </Box>
  );
};

export default Register;
