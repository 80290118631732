import i18n from "i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useState } from "react";
import { useAtomValue } from "jotai";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { fetchCategories, fetchTags } from "../../api/endpoints-functions";
import { instructorIdAtom } from "../../store";
import { fetchApi } from "../../api/api";
import { CustomImageInput } from "../../components/helpers";
import { blobToBase64, defaultTranslation } from "../../untilites/functions";
import AddCategories from "../../components/InstructorDashboard/DashboardCourseComponents/add-categories";
import AddTags from "../../components/InstructorDashboard/DashboardCourseComponents/add-tags";
import NormalFileUploader from "../../components/helpers/normal-file-uploader";
import { globalTestRequest } from "../../interfaces/new-api-interfaces/global-test";
import { useNavigate } from "react-router-dom";
import IsGradedRadio from "../../components/Test/is-graded-radio";
import GradeInputSection from "../../components/Test/grade-input-section";
import ExcelOrByHandRadio from "../../components/Test/excel-or-by-hand-radio";
import i18next from "i18next";
import QuizzCertificates from "../../components/Test/quizz-certificates";
import { getImageFromServer } from "../../assets/JS/helpers";
import { CertificateResponse } from "../../interfaces/new-api-interfaces/certificate";
import { FaRegTrashAlt } from "react-icons/fa";
import { FaTrash } from "react-icons/fa6";
import { Close } from "@mui/icons-material";

const inputStyle = {
  width: { xs: "100%", sm: "45%" },
  marginBottom: "30px",
};

interface Props {}

const AddGlobalTest: React.FC<Props> = ({}) => {
  const {
    data: categories,
    isLoading: l1,
    isError: e1,
  } = useQuery(["get-categories"], () => fetchCategories());

  const {
    data: tags,
    isLoading: l2,
    isError: e2,
  } = useQuery(["get-tags"], () => fetchTags());

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
    setError,
  } = useForm<globalTestRequest>({
    defaultValues: {},
  });

  const [localError, setLocalError] = useState(null); //to handle file updloading errors
  const [fileLoading, setFileLoading] = useState<boolean>(false);

  const [certificates, setCertificates] = useState<number[]>([]);
  const [isCertificateOpen, setIsCertificateOpen] = useState(false);

  const myInstructorId = useAtomValue(instructorIdAtom);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [selectedCertificates, setSelectedCertificates] = useState<
    CertificateResponse[]
  >([]);

  const {
    isLoading: l3,
    isError: e3,
    mutateAsync: createTest,
  } = useMutation((data: any) => fetchApi("/test/globalTest", "POST", data));

  const onSubmit: SubmitHandler<any> = async (data) => {
    let modefiedData = { ...data };

    try {
      createTest({
        ...modefiedData,
        instructor_id: myInstructorId,
        is_show: 0,
        file: null,
        certificate: certificates.map((id) => id),
      }).then((response) => {
        if (response.status === 200 || response.status === 201) {
          const testId = response.data.data.id;
          if (data.is_excel) {
            setFileLoading(true);

            if (data.file && watch("is_excel")) {
              const formData = new FormData();
              formData.append("file", data.file);
              formData.append("test_type", "globaltest");
              formData.append("test_id", testId);

              fetchApi(`/test/test/createTestContent/`, "POST", formData)
                .then((contentResponse) => {
                  setFileLoading(false);
                  if (
                    contentResponse.status === 200 ||
                    contentResponse.status === 201
                  ) {
                    toast.success(
                      i18n.language === "en"
                        ? "Test created successfully"
                        : "تم انشاء الاختبار بنجاح"
                    );
                    navigate(`/quizz-details/${testId}`);
                  }
                })
                .catch((error) => {
                  setFileLoading(false);
                  reset({ file: null });
                  console.error("Error creating course content:", error);
                  toast.error("Failed to create course content");
                });
            }
          } else {
            toast.success(
              i18n.language === "en"
                ? "Test created successfully"
                : "تم انشاء الاختبار بنجاح"
            );
            navigate(`/edit-test/${testId}`, { state: 1 });
          }
        }
      });
    } catch (error) {
      reset({ file: null });
      console.log(error);
    }
  };

  const handleRemoveCertificate = (id: number) => {
    setSelectedCertificates((prev) => prev.filter((item) => item.id !== id));
    setCertificates((prev) => prev.filter((crId) => crId !== id));
  };

  if (l1 || l2 || l3 || fileLoading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "60vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  return (
    <form
      className="d-flex flex-wrap container"
      style={{
        width: "100%",
        gap: "2%",
        padding: "20px 0 50px 0",
        justifyContent: "center",
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box width="100%" sx={{ textAlign: "center" }}>
        <Typography variant="h4" color="primary" sx={{ paddingTop: "20px" }}>
          {t("addNewTest")}
        </Typography>
      </Box>
      <Controller
        name="image"
        rules={{
          required:
            i18next.language == "en" ? "Image is required" : "الصورة مطلوبة",
        }}
        control={control}
        render={({ field }) => (
          <Box sx={inputStyle}>
            <Typography sx={{ paddingBottom: "5px" }}>
              {t("testImage")}
            </Typography>
            <CustomImageInput
              file={field.value}
              handleChange={(e: any) => {
                blobToBase64(e)
                  .then((value) => {
                    field.onChange(value); // Update the field value with the base64 string
                  })
                  .catch((err) => {
                    console.log(err);
                    setLocalError(err);
                  });
              }}
              fieldName={field.name}
              isCover={true}
              isContain={true}
            />
            <Typography color="error" sx={{ paddingBottom: "5px" }}>
              {errors?.image?.message}
            </Typography>
          </Box>
        )}
      />
      <Box sx={inputStyle}></Box>

      <Controller
        name="name"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            sx={inputStyle}
            {...field}
            label={t("testNameAr")}
            placeholder={t("testNameAr")}
            required
          />
        )}
      />
      <Controller
        name="name_en"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            sx={inputStyle}
            {...field}
            label={t("testNameEn")}
            placeholder={t("testNameEn")}
            required
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            sx={inputStyle}
            {...field}
            label={t("testDescAr")}
            placeholder={t("testDescAr")}
            required
          />
        )}
      />

      <Controller
        name="description_en"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            sx={inputStyle}
            {...field}
            label={t("testDescEn")}
            placeholder={t("testDescEn")}
            required
          />
        )}
      />

      <Controller
        name="price"
        control={control}
        defaultValue={0}
        render={({ field }) => (
          <TextField
            type="text"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            sx={inputStyle}
            {...field}
            label={t("GlobalPrice")}
            placeholder={t("GlobalPrice")}
            required
          />
        )}
      />

      <Box sx={inputStyle}>
        <AddCategories
          errors={errors}
          control={control}
          categories={categories!}
        />
      </Box>
      <Box sx={inputStyle}>
        <AddTags errors={errors} tags={tags!} control={control} />
      </Box>

      <Controller
        name="attempts_number"
        control={control}
        defaultValue={1}
        render={({ field }) => (
          <TextField
            type="number"
            inputProps={{ inputMode: "numeric", min: 1 }}
            sx={inputStyle}
            {...field}
            label={t("numAttempsAllowed")}
            placeholder={t("numAttempsAllowed")}
            required
          />
        )}
      />

      <IsGradedRadio control={control} />
      {watch("is_graded") == true && (
        <GradeInputSection
          getValues={getValues}
          setValues={setValue}
          errors={errors}
          testId={0}
          control={control}
          formMethod="create"
        />
      )}
      {watch("is_graded") && (
        <Box width="90%">
          <Button
            onClick={() => setIsCertificateOpen(true)}
            variant="contained"
          >
            {i18next.language === "ar" ? "إضافة شهادات" : "Add Certificates"}
          </Button>
          <Box display="flex" gap="1rem" flexWrap="wrap" my="1.5rem">
            {selectedCertificates.map((item: CertificateResponse) => (
              <Card sx={{ position: "relative" }}>
                <IconButton
                  size="small"
                  sx={{ position: "absolute", right: "5px", top: "5px" }}
                  onClick={() => handleRemoveCertificate(item.id)}
                >
                  <Close />
                </IconButton>
                <CardContent>
                  <Stack direction="row" spacing={2}>
                    <Avatar src={getImageFromServer(item.logo)} />
                    <Box>
                      <Typography variant="h6">
                        {defaultTranslation(item, "donor")}
                      </Typography>
                      <Typography variant="body2">
                        {defaultTranslation(item, "description")}
                      </Typography>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            ))}
          </Box>
        </Box>
      )}
      {watch("is_graded") && isCertificateOpen && (
        <QuizzCertificates
          selectedIds={certificates}
          setSelectedIds={setCertificates}
          open={isCertificateOpen}
          setOpen={setIsCertificateOpen}
          setSelectedCertificates={setSelectedCertificates}
        />
      )}

      <ExcelOrByHandRadio control={control} />
      {watch("is_excel") != false && (
        <Box
          sx={{
            width: "90%",
            marginBottom: "30px",
          }}
        >
          <Box sx={{ display: "flex", gap: "20px" }}>
            <NormalFileUploader
              control={control}
              setValue={setValue}
              accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              name="file"
              label={t("uploadExcelFile")}
            />
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                const link = document.createElement("a");
                link.href =
                  "https://backend.uh-training.com/ExcelFiles/test_form_with_translation_order.xlsx";
                link.download = "test_template.xlsx";
                link.click();
              }}
            >
              {t("downloadTemplate")}
            </Button>
          </Box>
        </Box>
      )}
      <div className="col-12 d-flex justify-content-center pt-4">
        <Button
          variant="contained"
          color="primary"
          className="mb-2 btn-hover-secondary btn-width-100"
          type="submit"
          disabled={
            (watch("is_excel") != false && !watch("file")) ||
            (watch("is_graded") &&
              (!watch("evaluatable") || watch("evaluatable")?.length == 0))
          }
          sx={{ width: { xs: "100%", sm: "92%", lg: "30%" } }}
        >
          <Typography fontSize="large" color="#fff">
            {t("createTest")}
          </Typography>
        </Button>
      </div>
    </form>
  );
};

export default AddGlobalTest;
