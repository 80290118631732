import React from "react";
import { Section } from "../../../interfaces/api-interfaces";
import { LessonItem } from "./content-sidebar-item";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { courseSection } from "../../../interfaces/new-api-interfaces/course";

interface Props extends courseSection {
  currentLesson: number;
  setCurrentLesson: Function;
  isEnrolled: boolean;
  expanded: string | false;
  handleChangeExpanded: Function;
  setOpenedSideNav?: Function;
  name: string;
  is_free: boolean;
  setCertificationsOpen: Function;
  certificationsOpen: boolean;
}

export const SidebarSection: React.FC<Props> = ({
  currentLesson,
  setCurrentLesson,
  name,
  lessons,
  is_free,
  isEnrolled,
  id,
  expanded,
  handleChangeExpanded,
  setOpenedSideNav,
  setCertificationsOpen,
  certificationsOpen
}) => {
  return (
    <Accordion
      expanded={expanded == `panel${id}`}
      onChange={handleChangeExpanded(`panel${id}`)}
    >
      <AccordionSummary>
        <Typography variant="h6">{name}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className="ml-5">
          <ul
            className="section-content"
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            {React.Children.toArray(
              lessons.map((lesson) => (
                <LessonItem
                  setOpenedSideNav={setOpenedSideNav}
                  isEnrolled={isEnrolled}
                  isSectionFree={true}
                  currentLesson={currentLesson}
                  setCurrentLesson={setCurrentLesson}
                  setCertificationsOpen={setCertificationsOpen}
                  certificationsOpen={certificationsOpen}
                  {...lesson}
                />
              ))
            )}
          </ul>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
