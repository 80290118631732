import React from "react";
import LandingSection from "../components/Home/home-page-sections/landing-section";
import VideoSection from "../components/Home/home-page-sections/video-section";
import Testimonials from "../components/Home/home-page-sections/testimonials";
import { ScrollTop } from "../components/Common/scroll-top.component";
import TopInstructorsSection from "../components/Home/home-page-sections/top-instructors-section";
import TopCards from "../components/Home/home-page-sections/top-cards";
import FreeSection from "../components/Home/home-page-sections/free-section";
import { AppErrorBoundary } from "../components/Common/error-handling/app-error-boundary";
import AltFreeSection from "../components/Home/home-page-sections/alt-free-section";

const HomePage: React.FC = () => {
  return (
    <AppErrorBoundary>
      <div id="page" className="section">
        <LandingSection />
        <TopCards />
        <VideoSection />
        <TopInstructorsSection />
        <Testimonials />
        {/* <FreeSection /> */}
        <AltFreeSection />
        <ScrollTop />
      </div>
    </AppErrorBoundary>
  );
};

export default HomePage;
