import React from "react";
import { fetchItemCertificate } from "../../../api/endpoints-functions";
import { useQuery } from "@tanstack/react-query";
import CourseCertificates from "./course-certificates";

const EditCourseCertificates = ({ id }: { id: number }) => {
  const { data, isLoading, isFetching } = useQuery(
    ["item-certificates"],
    () => fetchItemCertificate("course", id),
    {
      staleTime: 0,
    }
  );

  return (
    <CourseCertificates
      id={id}
      assignedCertificates={data}
      isEdit={true}
      isLoading={isLoading}
      isFetching={isFetching}
    />
  );
};

export default EditCourseCertificates;
