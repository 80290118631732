import React from "react";
import { useNavigate } from "react-router-dom";
import { Lesson } from "../../../interfaces/api-interfaces";
import "../../../pages/course/course-content.css";
import i18n from "i18next";
import { toast } from "react-toastify";
import { IoMdArrowDropdown } from "react-icons/io";
import { AiOutlinePlayCircle, AiOutlineFileText } from "react-icons/ai";
import { LiaBookSolid } from "react-icons/lia";
import { HiOutlineSpeakerWave } from "react-icons/hi2";
import { Box, Typography } from "@mui/material";
import { BsClock } from "react-icons/bs";
import { BsCheckCircle } from "react-icons/bs";
import { courseLesson } from "../../../interfaces/new-api-interfaces/course";
import { defaultTranslation } from "../../../untilites/functions";

interface Props extends courseLesson {
  currentLesson: number;
  setCurrentLesson: Function;
  isEnrolled: boolean;
  isSectionFree: boolean;
  setOpenedSideNav?: Function;
  setCertificationsOpen: Function;
  certificationsOpen: boolean;
}

export const LessonItem: React.FC<Props> = (Props) => {
  const handleClick = () => {
    Props.setCertificationsOpen(false);
    Props.setCurrentLesson(Props.id);
    if (Props.setOpenedSideNav) Props.setOpenedSideNav(false);
  };
  return (
    <li
      style={{
        width: "100%",
        marginTop: "5px",
        border: "1px solid #ddd",
        padding: "10px",
        borderRadius: "5px",
      }}
      className={`course-item ${
        Props.currentLesson == Props.id && !Props.certificationsOpen
          ? "active"
          : null
      }`}
      onClick={(e) => {
        if (!Props.isEnrolled && !Props.isSectionFree) {
          e.preventDefault();
          e.stopPropagation();
          toast.info(
            i18n.language === "en"
              ? "you should buy the course to access this section"
              : "يجب عليك شراء الدورة لضمان الوصول الى هذا القسم"
          );
        } else {
          handleClick();
        }
      }}
    >
      <div
        style={{
          textDecoration: "none",
          color: "gray",
          fontSize: "14px",
          padding: "0 ",
          cursor: "pointer",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography color="primary.main" fontSize="x-large">
            {Props.is_watched ? (
              <BsCheckCircle color="green" />
            ) : Props.type === "video" ? (
              <AiOutlinePlayCircle />
            ) : Props.type === "test" ? (
              <AiOutlineFileText />
            ) : Props.type == "audio" ? (
              <HiOutlineSpeakerWave />
            ) : Props.type == "asset" ? (
              <LiaBookSolid />
            ) : null}
          </Typography>

          {/* {Props.isWatched ? (
          <i
            className="fa fa-check-square"
            style={{ fontSize: "20px", margin: "0 3px" }}
          ></i>
        ) : (
          <i
            className="fa fa-square"
            style={{ fontSize: "20px", margin: "0 3px" }}
          ></i>
        )} */}
          <Typography color="#000" className="item-name">
            {defaultTranslation(Props, "name")}
          </Typography>
        </Box>
        <div style={{ paddingLeft: "2rem" }}>
          {/* {Props.type === "video" && Props.video.videoDuration ? (
            <Typography
              sx={{ display: "flex", alignItems: "center", gap: "5px" }}
            >
              <BsClock />
              {Math.ceil(Props.video.videoDuration / 60)} min
            </Typography>
          ) : null}
          {Props.type === "audio" && Props.audio.audioDuration ? (
            <Typography
              sx={{ display: "flex", alignItems: "center", gap: "5px" }}
            >
              <BsClock />
              {Math.ceil(Props.audio.audioDuration / 60)} min
            </Typography>
          ) : null} */}
          {!Props.isEnrolled && !Props.isSectionFree ? (
            <i
              className="bx bxs-lock"
              style={{ fontSize: "20px", margin: "0 3px" }}
            ></i>
          ) : null}
          <span className="item-meta item-meta-icon video">
            {/* {Props.isLock ? <i className='bx bxs-lock' style={{ fontSize: "20px", margin: "0 3px" }}></i> : null} */}
            {/* {Props.isVideo ? <i className='bx bxs-video-recording' style={{ fontSize: "20px", margin: "0 3px" }}></i> : null} */}
            {/* {Props.isLive ? <i className="bx bx-news" style={{ fontSize: "20px", margin: "0 3px" }}></i> : null} */}
          </span>
        </div>
      </div>
    </li>
  );
};
