import { Autocomplete, Chip, TextField } from "@mui/material";
import React from "react";
import { defaultTranslation } from "../../../untilites/functions";
import { Controller } from "react-hook-form";
import { categoryResponse } from "../../../interfaces/new-api-interfaces/categories";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

type Props = {
  categories: categoryResponse[];
  control: any;
  errors: any;
};

const AddCategories = (props: Props) => {
  const { t } = useTranslation();
  return (
    <Controller
      name="categories"
      control={props.control}
      rules={{
        required:
          i18next.language == "en"
            ? "Categories is required!"
            : "التصنيفات مطلوبة",
      }}
      defaultValue={[]}
      render={({ field }) => (
        <Autocomplete
          {...field}
          multiple
          id="tags-filled"
          options={props.categories.map((tag: categoryResponse) => tag.id)}
          getOptionLabel={(option) => {
            const category = props.categories.find(
              (category) => category.id === option
            );
            return category ? defaultTranslation(category, "name") : option;
          }}
          filterSelectedOptions
          renderTags={(value: number[], getTagProps) =>
            value.map((option: number, index: number) => {
              const category = props.categories.find(
                (category) => category.id === option
              );
              return (
                <Chip
                  variant="outlined"
                  label={
                    category ? defaultTranslation(category, "name") : option
                  }
                  {...getTagProps({ index })}
                />
              );
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={t("addCategories")}
              error={props.errors && !!props.errors?.categories}
              helperText={
                props.errors &&
                props.errors.categories &&
                props.errors?.categories?.message
              }
            />
          )}
          value={Array.isArray(field.value) ? field.value : []}
          onChange={(event, data) => {
            field.onChange(data); // Update the field value with the category IDs
          }}
        />
      )}
    />
  );
};

export default AddCategories;
