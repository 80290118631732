import React from "react";
import NotificationsList from "../../components/Header/notifications-list";
import { Box } from "@mui/material";

type Props = {};

const MobileNotifications = (props: Props) => {
  return (
    <Box
      className="course-scrollable-side"
      sx={{ padding: "30px", overflowY: "scroll" }}
    >
      <div className="inner">
        <div className="mini-cart-products" style={{ maxHeight: "70vh" }}>
          <NotificationsList />
        </div>
      </div>
    </Box>
  );
};

export default MobileNotifications;
