import { CustomButton } from "../../helpers";
import i18n from "i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchApi } from "../../../api/api";
import { LoadingPage } from "../../Common/loadingPage.component";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

interface Props {
  id: number;
  setOpen: Function;
  type: string;
}

export const GlobalPayment: React.FC<Props> = ({
  id,
  setOpen,
  type,
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { mutate, isLoading, isError } = useMutation(
    (data: any) => fetchApi(`/general/payment`, "POST", data),
    {
      onSuccess: () => {
        toast.success(
          i18n.language === "en" ? "ordered successfully" : "تم الطلب بنجاح"
        );
        queryClient.invalidateQueries(["get-course-by-id", id]);
        setOpen(false);
        if (type == "globaltest") navigate(`/global-test/${id}`);
        if (type == "course") navigate(`/course-content/${id}`);
      },
      onError: () =>
        toast.error(
          i18n.language === "en"
            ? "something went wrong"
            : "حدث خطأ أثناء الطلب"
        ),
    }
  );
  const handleSubmit = () => {
    mutate({
      id: id,
      type: type,
      // paymentMethod: {
      //   globalPayment: {
      //     isPaypal: true,
      //   },
      // },
      // isApproved: true,
    });
  };
  if (isLoading) return <LoadingPage />;
  return (
    <section>
      <p className="m-0 fs-4">Pay With :</p>
      <div onClick={() => handleSubmit()}>
        <a
          href="https://www.paypal.com/"
          target="_blank"
          rel="noreferrer"
          className="d-flex align-items-center justify-content-center"
        >
          <img
            src={require("../../../assets/images/pay-logo/paypal-logo.png")}
            width="100"
            height="45"
            className="mx-3 p-2 border border-dark"
            style={{ borderRadius: "10px" }}
            alt="paypal"
          />
        </a>
      </div>
      <p
        className="mx-auto mt-5 py-2 border-top border-bottom border-dark text-center"
        style={{ width: "100px" }}
      >
        OR
      </p>
      <div className="mt-5 d-flex align-items-center justify-content-center">
        <img
          src={require("../../../assets/images/pay-logo/visa-logo.png")}
          alt="paymentLogo"
          width="100"
          height="50"
        />
        <img
          src={require("../../../assets/images/pay-logo/master-card-logo.jpg")}
          alt="paymentLogo"
          width="100"
          height="50"
        />
      </div>
      <div className="">
        <div className="col-12 mb-20">
          <label>Company Name</label>
          <input type="text" placeholder="Company Name" />
        </div>
        <div className="col-12 mb-20">
          <label>Address*</label>
          <input type="text" placeholder="Address line 1" />
          <input type="text" placeholder="Address line 2" />
        </div>
        <div className="col-12 mb-20">
          <label>Zip Code*</label>
          <input type="text" placeholder="Zip Code" />
        </div>
        <div className="col-md-6 col-6 mb-20">
          <label>Country*</label>
          <select>
            <option>Bangladesh</option>
            <option>China</option>
            <option>country</option>
            <option>India</option>
            <option>Japan</option>
          </select>
        </div>
      </div>
      <div className="col-12 d-flex justify-content-center">
        <CustomButton
          arLabel="اطلب الآن"
          enLabel="Order Now"
          handlePress={() => handleSubmit()}
        />
      </div>
    </section>
  );
};
