import React, { useRef, useState } from "react";

import AudioRecorder from "../../untilites/audio-recorder";
import { toast } from "react-toastify";

interface Props {
  handleChange: Function;
  file: string;
}

export const CustomAudioRecorder: React.FC<Props> = ({
  handleChange,
  file,
}) => {
  // const [localFile, setLocalFile] = useState<string>("");
  const [isRecording, setIsRecording] = useState<boolean>(false);

  const audio = useRef<HTMLAudioElement>(null);
  const { start, stop, cancel } = AudioRecorder(audio.current);

  return (
    <>
      <div className="d-inline-block">
        {/* <div>
        <button onClick={start}>Start Recording</button>
        <button onClick={stop}>Stop Recording</button>
      </div> */}

        {!isRecording ? (
          <svg
            className="inline w-6 h-6 -mt-1"
            style={{ width: "30px", cursor: "pointer" }}
            viewBox="0 0 20 20"
            fill="currentColor"
            onClick={() => {
              start();
              setIsRecording(true);
              // setLocalFile("");
              handleChange({ status: "start" });
              toast.info("Start Recording...");
            }}
          >
            <path
              fillRule="evenodd"
              d="M7 4a3 3 0 016 0v4a3 3 0 11-6 0V4zm4 10.93A7.001 7.001 0 0017 8a1 1 0 10-2 0A5 5 0 015 8a1 1 0 00-2 0 7.001 7.001 0 006 6.93V17H6a1 1 0 100 2h8a1 1 0 100-2h-3v-2.07z"
              clipRule="evenodd"
            />
          </svg>
        ) : (
          <svg
            className="inline w-6 h-6 -mt-1 cursor-pointer"
            style={{ width: "30px" }}
            viewBox="0 0 20 20"
            fill="currentColor"
            onClick={() => {
              stop()
                .then((e) => {
                  handleChange({ blob: e?.blob, status: "stop" });
                  // setLocalFile(e?.url!);
                  toast.info("Stop Recording...");
                })
                .catch(() => {
                  toast.error("Error in audio recording!");
                });
              setIsRecording(false);
            }}
          >
            <circle
              cx="10"
              cy="10"
              r="9"
              stroke="currentColor"
              stroke-width="2"
              fill="none"
            />
            <rect x="6.5" y="6.5" width="7" height="7" />
          </svg>
        )}
      </div>
    </>
  );
};

export default CustomAudioRecorder;
