import { SpinnerLoadingIcon, JumpingPoints } from "../Common/loading-icon";
import {
  useAddToWishlist,
  useRemoveFromWishlist,
} from "../../hooks/useEditWishlist";
import { CircularProgress, IconButton } from "@mui/material";
import { BsHeart } from "react-icons/bs";
import { BsHeartFill } from "react-icons/bs";
import { useAtomValue } from "jotai";
import { tokenAtom, userAtom } from "../../store";
import { useNavigate } from "react-router-dom";

interface Props {
  inWishlist: boolean;
  id: number;
  refetch?: Function;
}

const AddToWishlist: React.FC<Props> = ({ id, inWishlist, refetch }) => {
  const user = useAtomValue(userAtom);
  const navigate = useNavigate();
  const token = useAtomValue(tokenAtom);

  const { mutateAsync: postAddToWishlist, isLoading: loadingWishlist } =
    useAddToWishlist();
  const { mutateAsync: postRemoveFromWishlist, isLoading: loadingWishlist1 } =
    useRemoveFromWishlist();

  const addToWishlist = async (id: number) => {
    await postAddToWishlist({
      wishlistable_id: id,
      wishlistable_type: "shopitem",
    });
    if (refetch) refetch();
  };
  const removeFromWishlist = async (id: number) => {
    await postRemoveFromWishlist({
      wishlistable_id: id,
      wishlistable_type: "shopitem",
    });
    if (refetch) refetch();
  };
  return (
    <div>
      <div onClick={(e) => e.preventDefault()}>
        <div>
          {loadingWishlist || loadingWishlist1 ? (
            <IconButton sx={{ color: "primary.main" }}>
              <CircularProgress size={20} />
            </IconButton>
          ) : inWishlist ? (
            <IconButton
              aria-label="add to favorites"
              onClick={() => {
                token ? removeFromWishlist(id) : navigate("/login");
              }}
              sx={{ color: "primary.main" }}
            >
              <BsHeartFill color="red" />
            </IconButton>
          ) : (
            <IconButton
              aria-label="add to favorites"
              onClick={() => {
                token ? addToWishlist(id) : navigate("/login");
              }}
              sx={{ color: "primary.main" }}
            >
              <BsHeartFill color="#ccc" />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddToWishlist;
