import React, { useMemo, useRef, useState } from "react";
// @ts-ignore
import ReactStars from "react-rating-stars-component";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchApi } from "./../../../api/api";
import { LoadingPage } from "../../Common/loadingPage.component";
import { useTranslation } from "react-i18next";
import { HandleStoreBlobAndBase64 } from "../../../untilites/functions";
import CustomUploader from "../../helpers/custom-uploader";
import CustomAudioRecorder from "../../helpers/custom-audio-recorder";
import { Review } from "../../../interfaces/api-interfaces";
import { useAtomValue } from "jotai";
import { userAtom } from "../../../store";
import { Box, Button, Typography } from "@mui/material";
import { toast } from "react-toastify";
import i18n from "i18next";
import {
  review,
  reviewRequest,
} from "../../../interfaces/new-api-interfaces/course";
import DisplayFile from "../course-grid-components/display-file";
import i18next from "i18next";
import { FormSubmitHandler } from "react-hook-form";

interface Props {
  courseId: number;
  canRate: boolean;
  reviews?: review[];
  isUserReviewed: any;
  userReview: any;
  userId: any;
}
type rateRequest = {
  course: number;
  rating: number | undefined;
  textContent?: string;
  voiceContent?: string;
  videoContent?: string;
};
export const AddReview: React.FC<Props> = (Props) => {
  const [rating, setRating] = useState(0);

  const [form, setForm] = useState({
    text: "",
    asseturl: "",
  });
  const [localError, setLocalError] = useState(null); //to handle file uploading errors
  const [rateType, setRateType] = useState<
    "text" | "audio" | "video" | "image" | "asset"
  >("text");
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isError } = useMutation(
    (data: any) => fetchApi("/review/review", "POST", data),
    {
      onSuccess: () => {
        setForm({
          text: "",
          asseturl: "",
        });
        setRating(0);
        toast.success(
          i18n.language === "en"
            ? "Review Added successfully"
            : "تم التقييم بنجاح"
        );
        queryClient.invalidateQueries([`get-course-reviews`, Props.courseId]);
      },
      onError: (error: any) => {
        setForm({
          text: "",
          asseturl: "",
        });
        setRating(0);
        toast.error(
          i18n.language === "en"
            ? "something went wrong"
            : "حدث خطأ أثناء التقييم, حاول مجدداً لاحقاً"
        );
      },
    }
  );
  const submit = () => {
    const formData = new FormData();
    formData.append("text", form["text"]);
    formData.append("commentable_type", "course");
    formData.append("commentable_id", Props.courseId.toString());
    formData.append(
      "rating",
      Props.isUserReviewed ? Props.userReview?.rating : rating
    );
    formData.append("user_id", Props.userId?.id!);
    formData.append("type", rateType);
    if (form["asseturl"] && form["asseturl"] !== "") {
      formData.append("asseturl", form["asseturl"]);
    }

    mutateAsync(formData).then(() => {
      setRateType("text");
      setForm({
        text: "",
        asseturl: "",
      });
    });
  };
  const handleChange = (e: any, fieldName: string) => {
    setForm((prev: any) => ({ ...prev, [fieldName]: e.target?.value }));
  };

  const { t } = useTranslation();
  if (isLoading) return <LoadingPage />;
  return (
    <div>
      {Props.canRate ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "20px 10px",
            gap: "20px",
          }}
        >
          {!Props.isUserReviewed && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                paddingLeft: "5px",
              }}
            >
              <Typography className="rating-title secondary-color">
                {t("addRating")}
              </Typography>
              <div className="review-star">
                <div className="tm-star-rating">
                  <ReactStars
                    count={5}
                    onChange={setRating}
                    size={24}
                    isHalf={false}
                    emptyIcon={<i className="far fa-star"></i>}
                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                    fullIcon={<i className="fa fa-star"></i>}
                    activeColor="#ffd700"
                  />
                </div>
              </div>
            </div>
          )}

          <div className="flex items-center">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                paddingBottom: "20px",
              }}
            >
              <input
                style={{ borderRadius: "20px", width: "70%", padding: "10px" }}
                type="text"
                placeholder={
                  i18next.language == "en" ? "Leave a Comment" : "اكتب تعليقك"
                }
                value={form["text"]}
                onChange={(e) => {
                  handleChange(e, "text");
                }}
              />
              <CustomUploader
                file={form["asseturl"]}
                handleChange={(e: any) => {
                  const type = e.type.split("/")[0];
                  console.log(type);
                  HandleStoreBlobAndBase64(
                    e,
                    handleChange,
                    setLocalError,
                    "asseturl"
                  );
                  if (type === "image" || type === "video") {
                    setRateType(type);
                  } else {
                    setRateType("asset");
                  }
                }}
                uploadIcon={
                  <svg
                    className="inline w-6 h-6 ml-2 -mt-1"
                    style={{ width: "30px", cursor: "pointer" }}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                    />
                  </svg>
                }
              />
              <CustomAudioRecorder
                file={rateType == "audio" ? form["asseturl"] : ""}
                handleChange={(e: any) => {
                  if (e.status === "stop") {
                    HandleStoreBlobAndBase64(
                      e.blob,
                      handleChange,
                      setLocalError,
                      "asseturl"
                    );
                    setRateType("audio");
                  } else {
                    handleChange("", "voiceContent");
                    setRateType("text");
                  }
                }}
              />
            </Box>
            {form["asseturl"] &&
              (rateType === "audio" || rateType === "video") && (
                <Box>
                  <Box
                    sx={{ paddingY: "20px", width: { xs: "90%", sm: "50%" } }}
                  >
                    <DisplayFile
                      file={form["asseturl"]}
                      type={rateType == "audio" ? "audio" : "video"}
                    />
                  </Box>
                </Box>
              )}
            {form["asseturl"] && rateType === "image" && (
              <img src={form["asseturl"]} style={{ marginBottom: "1rem" }} />
            )}
            {form["asseturl"] && rateType === "asset" && (
              <a
                target="_blank"
                style={{ marginBottom: "1rem", display: "block" }}
                href={form["asseturl"]}
              >
                Priview File
              </a>
            )}
            <div className="flex-column">
              <Button
                variant="outlined"
                onClick={() => submit()}
                disabled={
                  form.text === "" || (rating == 0 && !Props.isUserReviewed)
                }
              >
                {i18next.language == "en" ? "Post Review" : "أضف مراجعتك"}
              </Button>
            </div>
          </div>
        </Box>
      ) : null}
    </div>
  );
};
