import React, { useEffect, useState } from "react";
import audioImage from "../../../assets/images/courses/audio-placeholder-2.jpg";
import {
  BigPlayButton,
  ControlBar,
  ReplayControl,
  LoadingSpinner,
  ForwardControl,
  PlayToggle,
} from "video-react";
import Player from "./custom-player";
import { Document, Page, pdfjs } from "react-pdf";
import Vimeo from "@u-wave/react-vimeo";
import "./index.css";
import CustomVimeoPlayer from "./custom-vimeo-player";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props {
  file: string;
  type: "video" | "audio" | "asset" | string;
  id?: string | number;
  onProgress?: () => void;
  is_Watched?: boolean;
  isCourse?: boolean;
}

const DisplayFile: React.FC<Props> = ({
  file,
  type,
  onProgress,
  id,
  is_Watched,
  isCourse,
}) => {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  const [pageWidth, setPageWidth] = useState(0);

  useEffect(() => {
    if (window.innerWidth >= 768 && window.innerWidth <= 1024) {
      setPageWidth(600);
    } else if (window.innerWidth > 1024 && window.innerWidth <= 1440) {
      setPageWidth(800);
    } else if (window.innerWidth > 1440) {
      setPageWidth(1000);
    } else if (window.innerWidth < 768) {
      setPageWidth(window.innerWidth - 60);
    }
  }, [window.innerWidth]);

  if (type == "video") {
    return (
      <>
        {isCourse ? (
          <CustomVimeoPlayer
            file={file}
            is_Watched={is_Watched!}
            onProgress={onProgress}
          />
        ) : (
          <Player src={file} aspectRatio="16:9">
            <BigPlayButton position="center" />
            <LoadingSpinner />
            <ControlBar autoHide={false}>
              <PlayToggle />
              <ReplayControl seconds={10} />
            </ControlBar>
          </Player>
        )}
      </>
    );
  } else if (type == "audio") {
    return (
      <Player
        src={file}
        poster={audioImage}
        id={id}
        onEnded={() => {
          if (onProgress && is_Watched == false) onProgress();
        }}
      >
        <BigPlayButton position="center" />
        <LoadingSpinner />
        <ControlBar autoHide={false}>
          <PlayToggle />
          <ReplayControl seconds={10} />
          <ForwardControl seconds={10} />
        </ControlBar>
      </Player>
    );
  } else {
    console.log(file);
    return (
      // <embed
      //   type="application/pdf"
      //   width="100%"
      //   className="innerImageDiv embedded-pdf"
      //   onClick={(e) => {
      //     if (
      //       (e.target as HTMLDivElement).classList.contains("innerImageDiv")
      //     ) {
      //       const el = document.createElement("a");
      //       el.href = file;
      //       el.target = "blank";
      //       el.click();
      //     }
      //   }}
      //   style={{
      //     cursor: "pointer",
      //     minHeight: "480px",
      //     position: "relative",
      //     width: "100%",
      //     display: "flex",
      //     justifyContent: "center",
      //     alignItems: "center",
      //     border: "none",
      //   }}
      //   src={file}
      // ></embed>

      <div
        style={{ overflowY: "scroll", maxHeight: "600px", width: "100%" }}
        className="course-scrollable-side"
      >
        <Document
          file={{
            url: file,
          }}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              renderTextLayer={false}
              width={pageWidth}
            />
          ))}
        </Document>
      </div>
    );
  }
};

export default DisplayFile;
