import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import i18next from "i18next";
import React from "react";
import IsGradedRadio from "../../Test/is-graded-radio";
import GradeInputSection from "../../Test/grade-input-section";
import NormalFileUploader from "../../helpers/normal-file-uploader";
import { useTranslation } from "react-i18next";
import ExcelOrByHandRadio from "../../Test/excel-or-by-hand-radio";
import { Controller, SubmitHandler } from "react-hook-form";
import { AddCourseTest } from "../../../interfaces/new-api-interfaces/course";
import { fetchCourseTestInformation } from "../../../api/endpoints-functions";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { fetchApi } from "../../../api/api";
import { toast } from "react-toastify";

type Props = {
  control: any;
  open: boolean;
  onClose: Function;
  watch: Function;
  setValue: Function;
  getValues: Function;
  errors: any;
  addTest: Function;
  lesson_id: number;
  isLoading: boolean;
  testHandleSubmit: Function;
  testModalId: number;
  testReset: Function;
};

const AddCourseTestModal = ({
  control,
  open,
  onClose,
  watch,
  setValue,
  getValues,
  errors,
  addTest,
  lesson_id,
  isLoading,
  testHandleSubmit,
  testModalId,
  testReset,
}: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const {
    isLoading: mutationLoading,
    isError: mutationError,
    mutateAsync: updateTest,
  } = useMutation(
    (data: any) => fetchApi(`/test/test/${testModalId}`, "PUT", data),
    {
      onSuccess: () => {
        toast.success(
          i18next.language === "en"
            ? "Test Edited successfully"
            : "تم تعديل الاختبار بنجاح"
        );
        onClose();
        queryClient.invalidateQueries(["get-test-information", testModalId]);
      },
      onError: () => {
        toast.error(
          i18next.language === "en"
            ? "Something went wrong, please try again later."
            : "حدث خطأ ما، يرجى المحاولة مرة أخرى لاحقًا."
        );
        onClose();
      },
    }
  );

  const {
    isLoading: testLoading,
    isError,
    isFetching,
    data,
  } = useQuery(
    ["get-test-information", testModalId],
    () => (testModalId !== 0 ? fetchCourseTestInformation(testModalId) : null),
    {
      onSuccess: (data) => {
        testReset({
          ...data,
          evaluatable: data?.evaluations?.length
            ? data.evaluations.map((item) => ({
                value: item.value,
                text: item.translations?.ar?.text,
                text_en: item.translations?.en?.text,
              }))
            : [],
        });
      },
    }
  );

  const onSubmit: SubmitHandler<AddCourseTest> = async (data) => {
    if (testModalId == 0) {
      addTest({
        file_url: data?.file,
        lesson_id: lesson_id,
        is_graded: data.is_graded,
        total_mark: data?.total_mark,
        attempts_number: data.attempts_number,
        evaluatable: data.evaluatable,
      });
    } else {
      updateTest({
        lesson_id: lesson_id,
        total_mark: data?.total_mark,
        attempts_number: data.attempts_number,
        evaluatable: data.evaluatable,
      });
    }
  };
  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      sx={{
        width: "60vw",
        height: testModalId ? "40vh" : "60vh",
        position: "absolute",
        top: testModalId ? "25vh" : "20vh",
        left: "20vw",
        right: "20vw",
        direction: i18next.language == "ar" ? "rtl" : "ltr",
      }}
    >
      <Stack
        sx={{ py: 2, height: "100%", boxSizing: "border-box" }}
        direction="column"
      >
        <Paper
          sx={{
            flex: 1,
            mx: "auto",
            width: "90%",
            p: 3,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
          dir={i18next.language == "en" ? "ltr" : "rtl"}
          component="form"
          onSubmit={testHandleSubmit(onSubmit)}
        >
          {!testLoading && !isFetching ? (
            <>
              <Typography
                variant="h6"
                color="primary"
                sx={{ marginBottom: "30px" }}
              >
                {i18next.language == "en"
                  ? "Add Test Options"
                  : "إضافة خيارات الاختبار"}
              </Typography>

              <Controller
                name="attempts_number"
                control={control}
                defaultValue={1}
                rules={{
                  validate: (value) =>
                    value >= 1 ||
                    (i18next.language == "en"
                      ? "The Value must be at least 1"
                      : "القيمة يجب ان تكون على الاقل 1"),
                  required: true,
                }}
                render={({ field }) => (
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "45%" },
                      marginBottom: "30px",
                    }}
                  >
                    <TextField
                      type="number"
                      sx={{ width: "100%" }}
                      inputProps={{ inputMode: "numeric" }}
                      {...field}
                      label={t("numAttempsAllowed")}
                      placeholder={t("numAttempsAllowed")}
                      required
                    />
                    {errors.attempts_number && (
                      <Typography color="error" sx={{ marginTop: "5px" }}>
                        {errors.attempts_number.message}
                      </Typography>
                    )}
                  </Box>
                )}
              />
              {testModalId == 0 && (
                <IsGradedRadio default={true} control={control} />
              )}
              {watch("is_graded") != false && (
                <GradeInputSection
                  getValues={getValues}
                  setValues={setValue}
                  errors={errors}
                  testId={testModalId}
                  control={control}
                  formMethod={testModalId == 0 ? "create" : "update"}
                />
              )}
              {testModalId == 0 && <ExcelOrByHandRadio control={control} />}
              {watch("is_excel") != false && testModalId == 0 && (
                <Box
                  sx={{
                    width: "90%",
                    marginBottom: "30px",
                  }}
                >
                  <Box sx={{ display: "flex", gap: "20px" }}>
                    <NormalFileUploader
                      control={control}
                      setValue={setValue}
                      accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      name="file"
                      label={t("uploadExcelFile")}
                    />
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => {
                        const link = document.createElement("a");
                        link.href =
                          "https://backend.uh-training.com/ExcelFiles/test_form_with_translation_order.xlsx";
                        link.download = "test_template.xlsx";
                        link.click();
                      }}
                    >
                      {t("downloadTemplate")}
                    </Button>
                  </Box>
                </Box>
              )}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="mb-2 btn-hover-secondary btn-width-100"
                disabled={
                  (testModalId == 0 &&
                    watch("is_excel") != false &&
                    !watch("file")) ||
                  isLoading ||
                  (watch("is_graded") &&
                    watch("evaluatable") &&
                    watch("evaluatable").length == 0)
                }
                sx={{ width: { xs: "100%", sm: "92%", lg: "30%" } }}
              >
                {!isLoading && !mutationLoading ? (
                  <Typography fontSize="large" color="#fff">
                    {testModalId == 0 ? t("createTest") : t("updateTest")}
                  </Typography>
                ) : (
                  <CircularProgress size={24} sx={{ color: "#fff" }} />
                )}
              </Button>
            </>
          ) : (
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <CircularProgress size={32} sx={{ color: "primary.main" }} />
            </Stack>
          )}
        </Paper>
      </Stack>
    </Modal>
  );
};

export default AddCourseTestModal;
