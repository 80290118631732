import React, { ReactNode } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import {ErrorFallBack} from "./error-fallback";

interface AppErrorBoundaryProps {
  children: ReactNode;
}

export function AppErrorBoundary({ children }: AppErrorBoundaryProps) {
  const handleError = (error: Error, errorInfo: React.ErrorInfo) => {
    // Handle the error here or log it to an error tracking service
    console.error(error, errorInfo);
  };

  const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
    const staticError = {
      message: "Something went Wrong, please try again later",
    };
    // Render different components based on the error thrown
    if (error.message === "UnauthorizedError") {
      return (
        <ErrorFallBack error={error} resetErrorBoundary={resetErrorBoundary} />
      );
    } else if (error.message === "NotFoundError") {
      return (
        <ErrorFallBack error={error} resetErrorBoundary={resetErrorBoundary} />
      );
    } else if (error.message === "NetworkError") {
      return (
        <ErrorFallBack error={error} resetErrorBoundary={resetErrorBoundary} />
      );
    } else {
      return (
        <ErrorFallBack
          error={staticError}
          resetErrorBoundary={resetErrorBoundary}
        />
      );
    }
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
      {children}
    </ErrorBoundary>
  );
}