import React, { ReactNode, useId, useState } from "react";
import DisplayFile from "./display-file";
import i18n from "i18next";
import { Box } from "@mui/material";

interface Props {
  handleChange: Function;
  file?: string;
  uploadIcon: ReactNode;
}

export const CustomUploader: React.FC<Props> = ({
  handleChange,
  file,
  uploadIcon,
}) => {
  // @ts-ignore
  // const [localFile, setLocalFile] = useState<string>();
  const id = useId();
  return (
    <>
      <div className="d-inline-block ">
        <input
          type="file"
          id={id}
          style={{ display: "none" }}
          accept="video/mp4, audio/m4a, image/gif, image/JPG, image/JPEG ,image/png, file/pdf, file/docx, file/doc, file/xls"
          onChange={(e: any) => {
            if (e.target.files[0]) {
              if (e.target.files[0].size > 5000 * 1048576) {
                alert(
                  i18n.language === "en"
                    ? "The file is too large , maximum 5GB "
                    : "حجم الملف كبير جداً, الحد الأقصى 5 غيغابايت"
                );
                return;
              }
              handleChange(e.target.files?.[0]);
              // setLocalFile(URL.createObjectURL(e.target.files?.[0]));
            }
          }}
        />
        <label htmlFor={id}>{uploadIcon}</label>
        {/* <label style={{ position: "relative", top: "-250px" }} htmlFor={id}>
        <i
          style={{
            position: "absolute",
            top: "0",
            cursor: "pointer",
            right: "0",
            color: "black",
            border: "1px solid #000",
            background: "#fff",
            padding: "5px",
            margin: "10px",
            borderRadius: "50%",
          }}
          className="fas fa-pencil-alt"
        ></i>
      </label> */}
      </div>
    </>
  );
};

export default CustomUploader;
