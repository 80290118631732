import React, { ChangeEvent, useState } from "react";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { GrFormClose } from "react-icons/gr";
import { Controller } from "react-hook-form";

interface NormalFileUploaderProps {
  control: any;
  name: string;
  setValue: any;
  accept: string;
  label: string;
}

const NormalFileUploader: React.FC<NormalFileUploaderProps> = ({
  control,
  name,
  setValue,
  accept,
  label,
}) => {
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const [fileUrl, setFileUrl] = useState<string | undefined>(undefined);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      setValue(name, selectedFile);
      setFileName(selectedFile.name);
      setFileUrl(URL.createObjectURL(selectedFile));
    }
  };

  const handleButtonClick = () => {
    const fileInput = document.getElementById(name);
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleDeleteFile = () => {
    const fileInput = document.getElementById(name) as HTMLInputElement;
    if (fileInput) {
      fileInput.value = ""; // Reset the value of the input field
    }
    setValue(name, null); // Reset the value in the form controller
    setFileName(undefined); // Reset the file name state
    setFileUrl(undefined); // Reset the file URL state
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <Stack direction="row" gap={1} alignItems="center">
          <input
            id={name}
            type="file"
            accept={accept}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <Button
            variant="contained"
            component="span"
            onClick={handleButtonClick}
          >
            {label}
          </Button>
          {field.value && (
            <Typography variant="body1">
              {fileName && (
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                    {fileName}
                  </a>
                  <IconButton onClick={() => handleDeleteFile()}>
                    <GrFormClose color="red" />
                  </IconButton>
                </Box>
              )}
            </Typography>
          )}
        </Stack>
      )}
    />
  );
};

export default NormalFileUploader;
