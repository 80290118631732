import { useState } from "react";
import { HandleStoreBlobAndBase64 } from "../../../untilites/functions";
import { CustomButton, CustomImageInput, CustomInput } from "../../helpers";
import CustomPhoneInput from "../../helpers/custom-phone-input";
import i18n from "i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchApi } from "../../../api/api";
import { LoadingPage } from "../../Common/loadingPage.component";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

interface Props {
  id: number;
  setOpen: Function;
  type: string;
}

export const LocalPayment: React.FC<Props> = ({
  id,
  setOpen,
  type,
}) => {
  const queryClient = useQueryClient();
  const [form, setForm] = useState({
    companyName: "",
    phoneNumber: "",
    idNumber: "",
    proofImage: "",
  });

  const navigate = useNavigate();

  const { mutate, isLoading, isError } = useMutation(
    (data: any) => fetchApi(`/general/payment`, "POST", data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-course-by-id", id]);
        toast.success(
          i18n.language === "en" ? "ordered successfully" : "تم الطلب بنجاح"
        );
        setOpen(false);
        if (type == "globaltest") navigate(`/global-test/${id}`);
        if (type == "course") navigate(`/course-content/${id}`);
      },
      onError: () =>
        toast.error(
          i18n.language === "en"
            ? "something went wrong"
            : "حدث خطأ أثناء الطلب"
        ),
    }
  );
  const [localError, setLocalError] = useState(null); //to handle file updloading errors
  const handleChange = (e: any, fieldName: string) => {
    setForm((prev: any) => ({ ...prev, [fieldName]: e.target?.value }));
  };
  const handleSubmit = () => {
    mutate({
      id: id,
      type: type,
      // paymentMethod: {
      //   globalPayment: {
      //     isPaypal: true,
      //   },
      // },
      // paymentApproved: true,
    });
  };
  if (isLoading) return <LoadingPage />;
  return (
    <form
      className="d-flex flex-wrap container"
      style={{ width: "100%" }}
      onSubmit={(e) => e.preventDefault()}
    >
      <CustomInput
        enLabel="company name"
        arLabel="اسم الشركة"
        handleChange={handleChange}
        fieldName="companyName"
        prevValue={form["companyName"]}
        classesForStyles="my-2 col-12"
      />
      <div className="mb-30 col-6 col-sm-12">
        <CustomPhoneInput
          enLabel="Phone Number"
          arLabel="رقم الهاتف"
          handleChange={(e: any) =>
            handleChange({ target: { value: e } }, "phoneNumber")
          }
          prevValue={form["phoneNumber"]}
          fieldName="phoneNumber"
        />
      </div>
      <CustomInput
        enLabel="ID number"
        arLabel="رقم الوصل"
        handleChange={handleChange}
        fieldName="idNumber"
        prevValue={form["idNumber"]}
        classesForStyles="my-2 col-12"
      />
      <div className="col-12">
        <label className="form-label">
          {i18n.language === "en" ? "Proof Image" : "صورة الإثبات"}
        </label>
        <CustomImageInput
          fieldName=""
          handleChange={(e: any) =>
            HandleStoreBlobAndBase64(
              e,
              handleChange,
              setLocalError,
              "proofImage"
            )
          }
          isCover
          classesForStyles="my-2 col-12"
        />
      </div>
      <div className="col-12 d-flex justify-content-center">
        <CustomButton
          arLabel="اطلب الآن"
          enLabel="Order Now"
          handlePress={() => handleSubmit()}
        />
      </div>
    </form>
  );
};
