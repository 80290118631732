import React from "react";
import { useParams } from "react-router-dom";
import TestDND from "../../components/Test/edit-test/test-dnd";
import { Box } from "@mui/material";

type Props = {};

const EditCourseQuestions = (props: Props) => {
  const params = useParams();
  return (
    <Box className="container">
      <TestDND id={params.id!} testType="test" />
    </Box>
  );
};

export default EditCourseQuestions;
