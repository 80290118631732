import React from "react";
interface Props {
    question:number,
    questionValue:string,
    answerValue:string
}

export const DisplayCase: React.FC<Props> = ({question,questionValue,answerValue}) => {
  return (
    <div
      key={question}
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h1
        className="font-bold text-2xl text-align-center font-sans"
        style={{ marginBottom: "20px" }}
      >
        {questionValue}
      </h1>
      <div
        className="flex flex-col rounded items-center hover:shadow-md hover:shadow-blue-700 duration-200 bg-blue-400 shadow-md shadow-blue-700 duration-200 text-white"
        style={{
          height: "auto",
          padding: "20px",
          minWidth: "250px",
        }}
      >
        <p>{answerValue}</p>
      </div>
    </div>
  );
};
