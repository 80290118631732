import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaFacebookSquare, FaPhoneSquareAlt } from "react-icons/fa";
import { RiMapPin2Fill } from "react-icons/ri";
import { TbSocial } from "react-icons/tb";

interface Props {}

const ContactInformation: React.FC<Props> = () => {
  const { t } = useTranslation();
  return (
    <div className="contact-information-section section section-padding-bottom">
      <div className="container" style={{ marginTop: "80px" }}>
        <div className="row margin-bottom-85">
          <div className="col-12">
            <div className="contact-title text-center">
              <Typography variant="h2">{t("ContactUsTitle")}</Typography>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-between" style={{ gap: "20px" }}>
          <Stack gap={2} alignItems={"flex-start"}>
            <Stack
              direction="row"
              alignItems="center"
              gap={1}
              sx={{ textAlign: "start" }}
            >
              <RiMapPin2Fill size={25} color="#3f3a64" />
              <Typography variant="h4">{t("FooterAddress")}</Typography>
            </Stack>
            <Typography>
              شارع الإمام أبي حنيفة النعمان – دمشق – سوريا
            </Typography>
            <Typography>
              شارع كورنيش الميدان – دمشق – سوريا
            </Typography>
          </Stack>
          <Stack gap={2} alignItems={"flex-start"}>
            <Stack direction="row" alignItems="center" gap={1}>
              <FaPhoneSquareAlt size={25} color="#3f3a64" />
              <Typography variant="h4">{t("ContactUs")}</Typography>
            </Stack>
            <Box
              sx={{
                textAlign: "start",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Typography>{`${t(
                "ContactUsMobile"
              )} : 9590 11 963+ `}</Typography>
              <Typography>{`${t("Mobile")} : 446 655 9388 963+`}</Typography>
              <Typography>
                <a href="mailto:info@uh-training.com">{`${t(
                  "GlobalMail"
                )} : info@uh-training.com`}</a>
              </Typography>
            </Box>
          </Stack>
          <Stack gap={2} alignItems={"flex-start"}>
            <Stack direction="row" alignItems="center" gap={1}>
              <TbSocial size={25} color="#3f3a64" />
              <Typography variant="h4">{t("SocialMedia")}</Typography>
            </Stack>
            <Typography>
              <Stack direction="row" alignItems="center" gap={1}>
                <Typography>
                  <a
                    href="https://www.facebook.com/unlimitedhorizonssy"
                    target="_blank"
                  >
                    unlimitedhorizonssy
                  </a>
                </Typography>
                <FaFacebookSquare size={25} color="#3f3a64" />
              </Stack>
            </Typography>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default ContactInformation;
