import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { LoadingPage } from "./../../Common/loadingPage.component";
import { ShopResponse } from "../../../interfaces/api-interfaces";
import WishlistItem from "../wishlist-components/wishlist-item.component";
import EmptyWishlist from "../wishlist-components/empty-wishlist";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import { fetchWishistItems } from "../../../api/endpoints-functions";
import { wishListResponse } from "../../../interfaces/new-api-interfaces/cart";

interface Props {}

const WishlistSection: React.FC<Props> = () => {
  const { data, isLoading, isError, refetch } = useQuery(["get-wishlist"], () =>
    fetchWishistItems()
  );
  const { t } = useTranslation();

  if (isLoading) return <LoadingPage />;
  return (
    <div className="wishlist-section section section-padding-bottom">
      {data?.length ? (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="cart-table table-responsive">
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead sx={{ bgcolor: "mypurple2.main" }}>
                      <TableRow>
                        <TableCell sx={{ width: "15%" }}>
                          <Typography fontSize="large" color="#fff">
                            {t("GlobalImage")}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ width: "15%" }}>
                          <Typography fontSize="large" color="#fff">
                            {t("GlobalType")}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ width: "25%" }}>
                          <Typography fontSize="large" color="#fff">
                            {t("GlobalProduct")}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ width: "20%" }}>
                          <Typography fontSize="large" color="#fff">
                            {t("GlobalPrice")}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ width: "20%" }}>
                          <Typography fontSize="large" color="#fff">
                            {t("WishListAddToCart")}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ width: "20%" }}>
                          <Typography fontSize="large" color="#fff">
                            {t("GlobalRemove")}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((el: wishListResponse, index: number) => (
                        <WishlistItem refetch={refetch} {...el} index={index} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <EmptyWishlist />
      )}
    </div>
  );
};

export default WishlistSection;
