import React from "react";
import ShopItemTable from "../../Shop/shop-item-table";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import LoadingTable from "../../Common/loading-table";
import { StudentShopItemResponse } from "../../../interfaces/new-api-interfaces/profile";

interface Props {
  shopItem: StudentShopItemResponse[];
  isLoading: boolean;
  isFetching: boolean;
  activeTab: string | null;
}

const ShopItems: React.FC<Props> = ({
  shopItem,
  isLoading,
  isFetching,
  activeTab,
}) => {
  const { t } = useTranslation();
  return (
    <div
      id="shopItems"
      className={`profile-content-quizzes tab-pane fade pt-5 show ${
        activeTab === "shopItems" ? "active" : ""
      }`}
    >
      <div className="learn-press-subtab-content">
        <div className="row">
          <div className="col-12 scroll">
            <LoadingTable
              isLoading={isLoading}
              isFetching={isFetching}
              columns={3}
            >
              {shopItem?.length > 0 ? (
                <TableContainer>
                  <Table className="lp-list-table table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className="pro-thumbnail"
                          sx={{ borderTopLeftRadius: "20px" }}
                        >
                          <Typography fontSize="large">
                            {t("GlobalImage")}
                          </Typography>
                        </TableCell>
                        <TableCell className="pro-title">
                          <Typography fontSize="large">
                            {t("GlobalProduct")}
                          </Typography>
                        </TableCell>
                        <TableCell
                          className="pro-price"
                          sx={{ borderTopRightRadius: "20px" }}
                        >
                          <Typography fontSize="large">
                            {t("BoughtAt")}
                          </Typography>
                        </TableCell>
                        {/* <TableCell className="pro-remove">File</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {shopItem.map((product: any) => (
                        <ShopItemTable item={product} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className="row">
                  <div className="col-12">
                    <div className="learn-press-message success">
                      <i className="bx bx-info-circle"></i>
                      {i18n.language === "en"
                        ? "No Items!"
                        : "لا تملك عناصر سابقة!"}
                    </div>
                  </div>
                </div>
              )}
            </LoadingTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopItems;
