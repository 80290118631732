import { Button, Stack } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { fetchApi } from "../../../api/api";
import { useTranslation } from "react-i18next";
// import { useForget } from "src/hooks/query/auth/useForget";

function ResendTimer({ min, email }: { min: number; email: string }) {
  const [minutes, setMinutes] = useState(min);
  const [seconds, setSeconds] = useState(10);

  const { t } = useTranslation();

  const resend = useMutation(
    (data: any) => fetchApi(`/auth/user/send-otp`, "POST", data),
    {
      onSuccess: (res) => {
        toast.success(res.data.message);
        setMinutes(1);
        setSeconds(10);
      },
      onError: (error: any) => {
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).forEach((errorKey) => {
            toast.error(error.response.data.errors[errorKey].join());
          });
        } else {
          toast.error(error.response.data.message);
        }
      },
    }
  );

  useEffect(() => {
    let timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        if (minutes === 0) {
          clearInterval(timer);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [minutes, seconds]);

  const handleResend = () => {
    resend.mutateAsync({
      email,
    });
  };

  return (
    <Stack
      mt={1}
      direction="row"
      alignItems="center"
      gap="0.5rem"
      justifyContent="center"
    >
      <div>
        <span>{minutes < 10 ? `0${minutes}` : minutes}</span>:
        <span>{seconds < 10 ? `0${seconds}` : seconds}</span>
      </div>
      <Button
        onClick={handleResend}
        disabled={minutes !== 0 || seconds !== 0 || resend.isLoading}
        variant="text"
      >
        {t("resend")}
      </Button>
    </Stack>
  );
}

export default ResendTimer;
