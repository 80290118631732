import React, { useRef } from "react";
import {
  BigPlayButton,
  ControlBar,
  ForwardControl,
  LoadingSpinner,
  PlayToggle,
  Player,
  ReplayControl,
} from "video-react";

interface Props {
  file: string;
  type: "video" | "audio" | "asset";
  handleLocalChange?: Function;
  action?: string;
}

const DisplayFile: React.FC<Props> = ({
  file,
  type,
  handleLocalChange,
  action,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const handleDurationChange = (duration: number) => {
    if (handleLocalChange) {
      if (action == "edit" && type == "video") {
        handleLocalChange({ target: { value: duration } }, "videoDuration");
      } else if (action == "edit" && type == "audio") {
        handleLocalChange({ target: { value: duration } }, "audioDuration");
      } else if (action == "add") {
        handleLocalChange({ target: { value: duration } }, "duration");
      }
    }
  };
  if (type === "video") {
    return (
      <Player src={file} aspectRatio="16:9">
        <BigPlayButton position="center" />
        <LoadingSpinner />
        <ControlBar autoHide={false}>
          <PlayToggle />
          <ReplayControl seconds={10} />
          <ForwardControl seconds={10} />
        </ControlBar>
      </Player>
    );
  } else if (type === "audio") {
    return (
      <audio
        ref={audioRef}
        onLoadedMetadata={() => {
          const duration = audioRef.current?.duration;
          if (duration) handleDurationChange(duration);
        }}
        className="innerImageDiv"
        onClick={(e) => {
          if (
            (e.target as HTMLDivElement).classList.contains("innerImageDiv")
          ) {
            const el = document.createElement("a");
            el.href = file;
            el.target = "blank";
            el.click();
          }
        }}
        style={{
          cursor: "pointer",
          height: "280px",
          position: "relative",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        src={file}
        controls={true}
      ></audio>
    );
  } else {
    return (
      <embed
        className="innerImageDiv"
        onClick={(e) => {
          if (
            (e.target as HTMLDivElement).classList.contains("innerImageDiv")
          ) {
            const el = document.createElement("a");
            el.href = file;
            el.target = "blank";
            el.click();
          }
        }}
        style={{
          cursor: "pointer",
          height: "480px",
          position: "relative",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        src={file + "#toolbar=0"}
      ></embed>
    );
  }
};

export default DisplayFile;
