import { useMutation, useQuery } from "@tanstack/react-query";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { fetchApi } from "../../api/api";
import "./index.css";
import i18n from "i18next";
import { toast } from "react-toastify";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import bg from "../../assets/images/profile/profile-bg.jpg";
import { defaultTranslation, handleFormError } from "../../untilites/functions";
import { addVideoConsultationRequest } from "../../interfaces/new-api-interfaces/consultations";
import { fetchInstructors } from "../../api/endpoints-functions";
import i18next from "i18next";
import CustomTextField from "../../components/Common/custome-text-field";

interface Props {}

const AddVideoConsultation: React.FC<Props> = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();

  const currentDate = new Date().toISOString().split("T")[0]; // Get current date in "yyyy-mm-dd" format
  const maxDate = new Date();
  maxDate.setMonth(maxDate.getMonth() + 6); // Set max date to 6 months from now
  const maxDateString = maxDate.toISOString().split("T")[0]; // Get max date in "yyyy-mm-dd" format

  //fetch instructors
  const {
    data: instructors,
    isLoading: l1,
    isError: e1,
  } = useQuery(["get-instructors"], () => fetchInstructors());

  //mutate video consultation
  const { isLoading, mutateAsync } = useMutation(
    (data: any) => fetchApi("/consultation/liveConsultation", "POST", data),
    {
      onError: (error: any) => {
        if (error?.response?.status === 422) {
          handleFormError(error.response, setError, toast, i18next);
        }
      },
    }
  );

  //react-hook-form
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<addVideoConsultationRequest>({
    defaultValues: {},
  });

  //submit form function
  const onSubmit: SubmitHandler<addVideoConsultationRequest> = async (data) => {
    let modefiedData = { ...data };

    if (modefiedData.instructor_id) {
      //@ts-ignore
      modefiedData.instructor_id = modefiedData.instructor_id.id;
    }
    if (state) {
      modefiedData.instructor_id = state;
    }
    try {
      mutateAsync(modefiedData).then((response) => {
        if (response.status === 201) {
          toast.success(
            i18n.language === "en"
              ? "Consultation Added Successfully"
              : "تم إضافة الاستشارة بنجاح"
          );
          navigate("/student-profile");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPositionX: "right",
      }}
      style={{ backgroundPositionX: "right" }}
    >
      <div
        style={{
          direction: `${i18n.language === "en" ? "ltr" : "rtl"}`,
          padding: "5vh 0",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            paddingBottom: { xs: "20px", md: "20px" },
          }}
          color="primary.main"
          style={{ direction: `${i18n.language === "en" ? "ltr" : "rtl"}` }}
        >
          {t("addConsultationRequest")}
        </Typography>
        <Box
          className="login-form-wrapper mt-sm-50 mt-xs-50"
          sx={{
            direction: `${i18n.language === "en" ? "ltr" : "rtl"}`,
            width: { xs: "90%", md: "70%" },
            borderRadius: { xs: "10px", md: "50px" }
          }}
        >
          <form
            style={{
              direction: `${i18n.language === "en" ? "ltr" : "rtl"}`,
              position: "relative",
              paddingTop: "50px",
              gap: "2%",
            }}
            className="register-form d-flex flex-wrap justify-content-center"
            onSubmit={handleSubmit(onSubmit)}
          >
            {!isLoading && !l1 && (
              <>
                <Box
                  sx={{
                    width: { xs: "100%", sm: "92%" },
                    marginBottom: "30px",
                  }}
                >
                  {!state ? (
                    <Controller
                      name="instructor_id"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          disablePortal
                          id="combo-box-demo"
                          options={instructors!}
                          getOptionLabel={(instructor) => {
                            if (instructor)
                              return (
                                defaultTranslation(instructor, "first_name") +
                                " " +
                                defaultTranslation(instructor, "last_name")
                              );
                            return "";
                          }}
                          onChange={(e, value) => field.onChange(value)}
                          //@ts-ignore
                          value={field.value}
                          sx={{ width: "100%" }}
                          disabled={state}
                          renderInput={(params) => (
                            <TextField
                              required
                              {...params}
                              label={
                                i18next.language === "en"
                                  ? "Select Trainer"
                                  : "اختر المدرب"
                              }
                              error={errors && !!errors.instructor_id}
                              helperText={
                                errors &&
                                errors.instructor_id &&
                                errors?.instructor_id?.message
                              }
                            />
                          )}
                        />
                      )}
                    />
                  ) : (
                    <CustomTextField
                      name="instructor_id"
                      control={control}
                      disabled
                      defaultValue=""
                      value={
                        instructors?.find(
                          (instructor) => instructor.id == state
                        )
                          ? defaultTranslation(
                              instructors.find(
                                (instructor) => instructor.id == state
                              ),
                              "first_name"
                            ) +
                            " " +
                            defaultTranslation(
                              instructors.find(
                                (instructor) => instructor.id == state
                              ),
                              "last_name"
                            )
                          : ""
                      }
                      label={
                        i18next.language === "en"
                          ? "Select Trainer"
                          : "اختر المدرب"
                      }
                      placeholder={
                        i18next.language === "en"
                          ? "Select Trainer"
                          : "اختر المدرب"
                      }
                      required
                      inputStyle={{
                        width: { xs: "100%", sm: "100%" },
                      }}
                      errors={errors}
                    />
                  )}
                </Box>

                <CustomTextField
                  name="student_date"
                  control={control}
                  defaultValue={new Date()}
                  label={t("consultationDate")}
                  placeholder={t("consultationDate")}
                  required
                  type="date"
                  inputStyle={{
                    width: { xs: "100%", sm: "92%" },
                    marginBottom: "30px",
                  }}
                  inputProps={{
                    style: {
                      textAlign: i18next.language === "en" ? "left" : "right",
                    },
                    min: currentDate, // Disable past dates
                    max: maxDateString, // Disable dates after 6 months from now
                  }}
                  errors={errors}
                />

                <CustomTextField
                  name="student_reason"
                  control={control}
                  defaultValue=""
                  label={t("consultationReason")}
                  placeholder={t("consultationReason")}
                  required
                  inputStyle={{
                    width: { xs: "100%", sm: "92%" },
                    marginBottom: "30px",
                  }}
                  errors={errors}
                />

                <CustomTextField
                  name="student_notes"
                  required
                  control={control}
                  multiline
                  defaultValue=""
                  label={t("consultationNotes")}
                  placeholder={t("consultationNotesHint")}
                  inputStyle={{
                    width: { xs: "100%", sm: "92%" },
                    marginBottom: "30px",
                  }}
                  InputLabelProps={{ shrink: true }}
                  errors={errors}
                />

                <Box
                  className="single-input"
                  sx={{
                    direction: `${i18n.language === "en" ? "ltr" : "rtl"}`,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: { xs: "100%", sm: "92%", lg: "30%" },
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className="mb-2 btn-hover-secondary btn-width-100"
                    type="submit"
                  >
                    <Typography color="#fff">
                      {t("submitConsultationRequest")}
                    </Typography>
                  </Button>
                </Box>
              </>
            )}
            {(isLoading || l1) && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  minHeight: "500px",
                  paddingBottom: "150px",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </form>
        </Box>
      </div>
    </Box>
  );
};

export default AddVideoConsultation;
