import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createGroupRequest,
  updateGroupRequest,
  vrcGroupMessage,
  vrcGroupResponse,
} from "../../interfaces/new-api-interfaces/vcr";
import {
  CreateVrcGroup,
  UpdateVrcGroup,
  fetchVrcGroupById,
  fetchVrcGroupMessagesById,
  fetchVrcGroups,
} from "../../api/endpoints-functions";

export function useCreateGroup() {
  return useMutation((data: createGroupRequest) => CreateVrcGroup(data));
}

export function useUpdateGroup() {
  return useMutation((data: updateGroupRequest) => UpdateVrcGroup(data));
}

export function useGetGroups(onSuccess: (data: vrcGroupResponse[]) => void) {
  return useQuery({
    queryKey: ['groups'],
    queryFn: fetchVrcGroups,
    onSuccess: (data) => {
      onSuccess(data!);
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
  });
}

export function useGetGroupById(id: number) {
  return useQuery(["group", id], () =>
    id != 0 ? fetchVrcGroupById(id) : null
  );
}

export function useGetGroupMessagesById(
  id: number,
  onSuccess: (data: vrcGroupMessage[]) => void
) {
  return useQuery(
    ["group-messages", id],
    () => (id ? fetchVrcGroupMessagesById(id) : null),
    {
      onSuccess: (data) => {
        onSuccess(data!);
      },
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: false,
    }
  );
}
