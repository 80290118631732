import React from "react";
import { RatingItem } from "./rating-item";
import { useTranslation } from "react-i18next";
import { review } from "../../../interfaces/new-api-interfaces/course";
import { Box } from "@mui/material";

interface Props {
  reviewItems: review[] | undefined;
}

export const DetailedRating: React.FC<Props> = ({ reviewItems }) => {
  const ratingCounts: number[] = [0, 0, 0, 0, 0]; // Initialize an array to store the counts for each rating

  if (reviewItems) {
    reviewItems.forEach((review) => {
      const rating = review.rating;
      if (rating != undefined)
        if (rating >= 1 && rating <= 5) {
          ratingCounts[rating - 1]++; // Increment the count for the corresponding rating
        }
    });
  }

  const totalRaters = reviewItems?.length;

  const ratingItems = ratingCounts.map((count, index) => {
    const rating = index + 1;
    let progress = 0;
    if (totalRaters) {
      progress = totalRaters > 0 ? (count / totalRaters) * 100 : 0;
    }

    return {
      key: rating,
      progress,
      rating,
      raters: count,
    };
  });
  const { t } = useTranslation();
  return (
    <div className="detailed-rating">
      <Box
        className="rating-box"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.7rem",
          width: { xs: "95%", sm: "90%" },
        }}
      >
        {ratingItems.map(({ key, progress, rating, raters }) => (
          <RatingItem
            key={key}
            progress={progress}
            raters={raters}
            rating={rating}
          />
        ))}
      </Box>
    </div>
  );
};
