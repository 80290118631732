import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Box,
  Chip,
  CircularProgress,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { AiOutlineFileText } from "react-icons/ai";
import { WhiteButton } from "../Common/whiteButton";
import { globalTestListResponse } from "../../interfaces/new-api-interfaces/global-test";
import { getImageFromServer } from "../../assets/JS/helpers";
import { defaultTranslation } from "../../untilites/functions";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchApi } from "../../api/api";
import { toast } from "react-toastify";
import i18next from "i18next";

interface Props extends globalTestListResponse {
  isShowDeleteBtn?: boolean;
  isStudentProfile?: boolean;
}

const AltQuizBox: React.FC<Props> = (Props) => {
  const { t } = useTranslation();

  const backgroundImageStyle = {
    backgroundImage: `url(${getImageFromServer(Props.image)})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100%", // Adjust the height of the image
  };

  const queryClient = useQueryClient();
  const { isLoading: loadingActivation, mutateAsync: Activation } = useMutation(
    (id: number) => fetchApi(`/test/globalTest/activate/${id}`, "PUT"),
    {
      onError: (error: any) => {
        toast.error(
          i18next.language === "en"
            ? "Something went wrong, please try again later."
            : "حدث خطأ ما، يرجى المحاولة مرة أخرى لاحقًا."
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["instructor-global-tests"]);
        toast.success(
          i18next.language === "en"
            ? "Test Edited Successfully"
            : "تم تعديل الاختبار بنجاح"
        );
      },
    }
  );

  return (
    <Link
      to={
        Props.isShowDeleteBtn
          ? `/edit-test/${Props.id}`
          : Props.isStudentProfile
          ? `/global-test/${Props.id}`
          : `/quizz-details/${Props.id}`
      }
      className="swiper-slide mb-10"
      style={{ margin: "0px 1%" }}
    >
      <Box
        className="course box-shadow"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          position: "relative",
          overflow: "hidden",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Add box shadow style
          transition: "box-shadow 0.3s ease", // Add transition for smooth hover effect
          ":hover": {
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.4)", // Adjust the shadow on hover
          },
        }}
      >
        {/* Image on the left */}
        <Box
          className="image-container"
          sx={{
            position: "relative",
            flex: "0 0 40%",
            ...backgroundImageStyle,
          }}
        >
          {Props?.is_approved == false && Props.isShowDeleteBtn && (
            <Chip
              variant="filled"
              label={t("underReview")}
              sx={{
                position: "absolute",
                top: 10,
                left: 10,
                bgcolor: "#ffdf76",
                fontWeight: 600,
              }}
            />
          )}
        </Box>
        {/* Content on the right */}
        <Box
          sx={{
            flex: "0 0 60%",
            paddingY: "20px",
            paddingLeft: "15px",
            paddingRight: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <Typography variant="h5" color="#000">
              {" "}
              {/* Set text color to black */}
              {defaultTranslation(Props, "name")}
            </Typography>
            <Typography
              color="#000"
              sx={{ display: { xs: "none", md: "block" } }}
            >
              {" "}
              {/* Set text color to black */}
              {defaultTranslation(Props, "description")?.slice(0, 30)}
              {defaultTranslation(Props, "description")?.length > 30
                ? "..."
                : ""}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexWrap: "nowrap",
              gap: "10px",
              justifyContent: "space-between",
              paddingRight: "20px",
            }}
          >
            {Props.questions_count && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  flexWrap: "nowrap",
                }}
                className=""
              >
                <Box sx={{ color: "#000", fontWeight: 600 }}>
                  <AiOutlineFileText />
                </Box>
                <Typography
                  sx={{ textWrap: "nowrap", color: "#000", fontWeight: 600 }}
                >
                  {Props?.questions_count} {t("Questions")}
                </Typography>
              </Box>
            )}
            <Box
              className="price"
              style={{
                marginTop: "20px",
                margin: 0,
                justifyContent: "center",
                display: "flex",
                whiteSpace: "nowrap",
              }}
            >
              <Typography fontWeight={600} color="primary" variant="h5">
                {Props.price === 0
                  ? t("GlobalFree")
                  : `${Props.price?.toLocaleString()} ${t("syp")}`}
              </Typography>
            </Box>
          </Box>
          {!loadingActivation && Props.isShowDeleteBtn && (
            <Stack flexDirection="row" alignItems="center">
              <Typography fontWeight={600}>
                {t("Activation") + " : "}
              </Typography>
              <Switch
                checked={Props.is_active}
                onClick={(e) => {
                  e.stopPropagation();
                  Activation(Props.id);
                }}
              />
            </Stack>
          )}
          {loadingActivation && Props.isShowDeleteBtn && (
            <CircularProgress size={24} />
          )}
        </Box>
      </Box>
    </Link>
  );
};

export default AltQuizBox;
