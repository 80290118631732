import React, { useMemo } from "react";
import { AverageRating } from "./average-rating";
import { CommentReview } from "./comment-review";
import { DetailedRating } from "./detailed-rating";
import { useTranslation } from "react-i18next";
import { CourseApiResponse, Review } from "../../../interfaces/api-interfaces";
import { Skeleton } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../api/api";
import { getImageFromServer } from "../../../assets/JS/helpers";
import { AddReview } from "./add-review";
import {
  courseResponse,
  review,
} from "../../../interfaces/new-api-interfaces/course";
import { fetchReviews } from "../../../api/endpoints-functions";
import { LoadingPage } from "../../Common/loadingPage.component";
import i18next from "i18next";
import { useAtomValue } from "jotai";
import { userAtom } from "../../../store";

interface Props {
  course: courseResponse;
  isLoading: boolean;
}

export const CourseReviews: React.FC<Props> = ({ course, isLoading }) => {
  const { t } = useTranslation();
  const {
    data: reviews,
    isLoading: isLoading1,
    refetch,
  } = useQuery([`get-course-reviews`, course.id], () =>
    fetchReviews(course.id, "course")
  );

  const user = useAtomValue(userAtom);

  const isUserReviewed = useMemo(() => {
    return reviews?.some((review) => review.users.id === user?.id);
  }, [reviews, user]);

  const userReview = useMemo<review | undefined>(() => {
    return reviews?.find((review) => review.users.id === user?.id);
  }, [reviews, user]);

  if (isLoading) return <LoadingPage />;
  return (
    <div className="course-reviews">
      <div className="course-rating">
        <div
          className="course-rating-content"
          style={{ alignItems: "center", gap: "20px" }}
        >
          {!isLoading ? (
            <AverageRating
              raters={reviews?.length}
              rating={course?.rating || 0}
            />
          ) : (
            <Skeleton
              variant="rectangular"
              width={200}
              height={200}
              sx={{ mr: 3 }}
            />
          )}
          {!isLoading ? (
            <DetailedRating reviewItems={reviews} />
          ) : (
            <Skeleton
              variant="rectangular"
              width={500}
              height={200}
              sx={{ mr: 3 }}
            />
          )}
        </div>
      </div>
      {!isUserReviewed && (
        <div>
          <AddReview
            reviews={reviews}
            canRate={course?.is_bought}
            courseId={course?.id}
            isUserReviewed={isUserReviewed}
            userReview={userReview}
            userId={user}
          />
        </div>
      )}
      <div className="course-reviews-area">
        <ul
          className="course-reviews-list"
          style={{ padding: 0, maxHeight: "720px", overflowY: "scroll" }}
        >
          {!isLoading && reviews ? (
            reviews?.map((review: review) => (
              <CommentReview
                key={review.id}
                userId={review.users.id}
                id={review.id}
                name={
                  review.users.translations[i18next.language].first_name +
                  " " +
                  review.users.translations[i18next.language].last_name
                }
                date={review?.created_at}
                textContent={review.text}
                file={review.asseturl}
                fileType={review.type}
                imgLink={getImageFromServer(review.users.profile_image)}
                rating={review.rating}
                refetch={refetch}
              />
            ))
          ) : (
            <>
              <Skeleton
                variant="rectangular"
                width={500}
                height={200}
                sx={{ mr: 3 }}
              />
            </>
          )}
        </ul>
      </div>
    </div>
  );
};
