import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";

interface Props {
  counter: number;
  text: string;
}

const Funfact: React.FC<Props> = ({ counter, text }) => {
  const [innerCounter, setInnerCounter] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (innerCounter >= counter) {
        clearInterval(interval);
        return;
      }
      setInnerCounter(innerCounter + 1);
    }, 1);

    return () => clearInterval(interval);
  }, [innerCounter, counter]);
  return (
    <div className="col-12 col-sm-6 col-lg-3 max-mb-30" data-aos="fade-up">
      <div className="funfact">
        <Box
          sx={{
            color: "#000",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h1" component="p" color="primary.main" fontSize={"4.5rem"}>
            {innerCounter}
          </Typography>
          <Typography variant="h1" component="p" color="primary.main">
            +
          </Typography>
        </Box>
        <Typography variant="h2" component="p" sx={{color:"#000"}}>
          {text}
        </Typography>
      </div>
    </div>
  );
};

export default Funfact;
