import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import Profile from "../../components/Profile/profile-sections/profile-section";
import { ScrollTop } from "../../components/Common/scroll-top.component";
import { LoadingPage } from "../../components/Common/loadingPage.component";
import { fetchProfile } from "../../api/endpoints-functions";

interface Props {}

const InstructorProfile: React.FC<Props> = () => {
  const { t } = useTranslation();

  const { data, isLoading, isError } = useQuery(["get-profile"], () =>
    fetchProfile()
  );
  if (isLoading) return <LoadingPage />;
  return (
    <>
      {data ? <Profile profileData={data} isLoading={isLoading} /> : null}
      {/* <ProfileStatus {...data.data} /> */}
      <ScrollTop />
    </>
  );
};

export default InstructorProfile;
