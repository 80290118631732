import i18n from "i18next";
import { CustomImageInput } from "../../helpers";
import { blobToBase64 } from "../../../untilites/functions";

import "../styles.css";
import { courseRequest } from "../../../interfaces/new-api-interfaces/course";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../api/api";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useAtomValue } from "jotai";
import { instructorIdAtom, userAtom } from "../../../store";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import FileUploader from "../../helpers/custom-file-uploader";
import CustomColorPicker from "../../helpers/custom-color-picker";
import NormalFileUploader from "../../helpers/normal-file-uploader";
import AddTags from "../DashboardCourseComponents/add-tags";
import AddCategories from "../DashboardCourseComponents/add-categories";
import {
  fetchCategories,
  fetchCourse,
  fetchTags,
} from "../../../api/endpoints-functions";
import { ErrorFallBackNotFound } from "../../Common/error-handling/error-404";

const inputStyle = {
  width: { xs: "100%", sm: "45%" },
  marginBottom: "30px",
};

interface Props {
  handleNext: Function;
  courseId: string;
}

const EditCourse: React.FC<Props> = ({ handleNext, courseId }) => {
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
    setError,
  } = useForm<courseRequest>({
    defaultValues: {},
  });

  //get old data
  const {
    data: course,
    isError,
    isLoading: profileLoading,
  } = useQuery(["get-course", courseId], () => fetchCourse(courseId), {
    refetchOnWindowFocus: false, // Disable refetch on focus
    refetchOnReconnect: false,
  });

  useEffect(() => {
    if (course) {
      const {
        instructors,
        lesson_count,
        in_cart,
        in_wishlist,
        translations,
        ...mCourse
      } = course;
      reset({
        ...mCourse,
        name: course.translations.ar.name || "",
        name_en: course.translations.en.name || "",
        web_description: course.translations.ar?.web_description || "",
        web_description_en: course.translations.en?.web_description || "",
        mobile_description: course.translations.ar?.mobile_description || "",
        mobile_description_en: course.translations.en.mobile_description || "",
        categories: course.categories.map((category) => category.id),
        tags: course.tags.map((tag) => tag.id),
      });
    }
  }, [course, reset]);

  const {
    data: categories,
    isLoading: l1,
    isError: e1,
  } = useQuery(["get-categories"], () => fetchCategories());

  const {
    data: tags,
    isLoading: l2,
    isError: e2,
  } = useQuery(["get-tags"], () => fetchTags());

  const [localError, setLocalError] = useState(null); //to handle file updloading errors
  const myInstructorId = useAtomValue(instructorIdAtom);
  const { t } = useTranslation();

  const {
    isLoading: l3,
    isError: e3,
    mutateAsync: editCourse,
  } = useMutation(
    (data: any) => fetchApi(`/course/course/${courseId}`, "PUT", data),
    {
      onError: () => {
        toast.error(
          i18n.language === "en"
            ? "Something went wrong, please try again later."
            : "حدث خطأ ما، يرجى المحاولة مرة أخرى لاحقًا."
        );
      },
    }
  );

  const onSubmit: SubmitHandler<courseRequest> = async (data) => {
    let modefiedData = { ...data };

    try {
      editCourse({
        ...modefiedData,
        instructor_id: course?.instructors.id,
        mobile_description: data.web_description,
        mobile_description_en: data.web_description_en,
      }).then((response) => {
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 202
        ) {
          toast.success(
            i18n.language === "en"
              ? "course Edited successfully"
              : "تم تعديل الدورة بنجاح"
          );
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  if (isError) return <ErrorFallBackNotFound />;
  if (l1 || l2 || l3 || profileLoading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "60vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  return (
    <form
      className="d-flex flex-wrap container"
      style={{
        width: "100%",
        gap: "2%",
        padding: "20px 0 50px 0",
        justifyContent: "center",
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="course_image"
        control={control}
        render={({ field }) => (
          <Box sx={inputStyle}>
            <Typography sx={{ paddingBottom: "5px" }}>
              {t("cardImage")}
            </Typography>
            <CustomImageInput
              file={field.value}
              handleChange={(e: any) => {
                blobToBase64(e)
                  .then((value) => {
                    field.onChange(value); // Update the field value with the base64 string
                  })
                  .catch((err) => {
                    console.log(err);
                    setLocalError(err);
                  });
              }}
              fieldName={field.name}
              isCover={true}
              isContain={true}
            />
          </Box>
        )}
      />

      <Controller
        name="overview_image"
        control={control}
        render={({ field }) => (
          <Box sx={inputStyle}>
            <Typography sx={{ paddingBottom: "5px" }}>
              {t("PannerImage")}
            </Typography>
            <CustomImageInput
              file={field.value}
              handleChange={(e: any) => {
                blobToBase64(e)
                  .then((value) => {
                    field.onChange(value); // Update the field value with the base64 string
                  })
                  .catch((err) => {
                    console.log(err);
                    setLocalError(err);
                  });
              }}
              fieldName={field.name}
              isCover={true}
              isContain={true}
            />
          </Box>
        )}
      />

      <Controller
        name="name"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            sx={inputStyle}
            {...field}
            label={t("courseNameAr")}
            placeholder={t("courseNameAr")}
            required
          />
        )}
      />
      <Controller
        name="name_en"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            sx={inputStyle}
            {...field}
            label={t("courseNameEn")}
            placeholder={t("courseNameEn")}
            required
          />
        )}
      />
      <Controller
        name="web_description"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            sx={inputStyle}
            {...field}
            label={t("courseDescAr")}
            placeholder={t("courseDescAr")}
            required
          />
        )}
      />

      <Controller
        name="web_description_en"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            sx={inputStyle}
            {...field}
            label={t("courseDescEn")}
            placeholder={t("courseDescEn")}
            required
          />
        )}
      />

      <Controller
        name="price"
        control={control}
        defaultValue={0}
        render={({ field }) => (
          <TextField
            type="text"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            sx={inputStyle}
            {...field}
            label={t("GlobalPrice")}
            placeholder={t("GlobalPrice")}
            required
          />
        )}
      />

      <Box sx={inputStyle}>
        <AddCategories errors={errors} control={control} categories={categories!} />
      </Box>
      <Box
        sx={{
          width: { xs: "100%", sm: "92%" },
          marginBottom: "30px",
        }}
      >
        <AddTags errors={errors} tags={tags!} control={control} />
      </Box>
      <Box sx={inputStyle}>
        <FileUploader
          control={control}
          name="guide_file"
          setValue={setValue}
          accept=".pdf,.doc,.docx"
          label={t("uploadGuideFile")}
          value={course?.guide_file}
        />
      </Box>
      <Box sx={inputStyle}>
        <FileUploader
          control={control}
          name="description_video"
          setValue={setValue}
          accept="video/mp4"
          label={t("uploadIntroVideo")}
          value={course?.description_video}
        />
      </Box>
      <Box
        sx={{
          width: { xs: "100%", sm: "92%" },
          marginBottom: "30px",
        }}
      >
        <CustomColorPicker
          name="color"
          control={control}
          setValue={setValue}
          value={course?.color}
        />
      </Box>

      <div className="col-12 d-flex justify-content-center pt-4">
        <Button
          variant="contained"
          color="primary"
          className="mb-2 btn-hover-secondary btn-width-100"
          type="submit"
          sx={{ width: { xs: "100%", sm: "92%", lg: "30%" } }}
        >
          <Typography fontSize="large" color="#fff">
            {t("editCourse")}
          </Typography>
        </Button>
      </div>
    </form>
  );
};

export default EditCourse;
