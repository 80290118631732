import React from "react";
import { styled } from "@mui/system";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import image from "../../assets/images/tests/test-img.png";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
import { useAtomValue } from "jotai";
import { tokenAtom } from "../../store";

const HeroSection = styled("div")({
  display: "flex",
  gap: "20px",
  justifyContent: "space-around",
  width: "100%",
  alignItems: "self-end",
  color: "white",
  textAlign: "center",
  height: "500px",
  "@media (min-width: 600px)": {
    height: "400px",
  },
});

const ContentContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  paddingBottom: "50px",
  "@media (min-width: 600px)": {
    alignItems: "flex-start",
  },
});

const Image = styled("img")({
  maxWidth: "100%",
  height: "auto",
  "@media (min-width: 600px)": {
    maxHeight: "100%",
  },
});

const ActionButton = styled(Button)({
  margin: "8px",
  fontWeight: "bold",
  borderRadius: "24px",
  padding: "12px 24px",
  fontSize: "1.2rem",
});

const Consultations: React.FC = () => {
  const navigate = useNavigate();
  const token = useAtomValue(tokenAtom);
  return (
    <>
      <div
        className="container"
        style={{
          minHeight: "70vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: "20vh",
        }}
      >
        <HeroSection>
          <ContentContainer>
            <Box sx={{ padding: "10px",textAlign:"start" }}>
              <Typography variant="h2" style={{ marginBottom: "16px" }}>
                {i18next.language == "en"
                  ? "Book a Consultation"
                  : "احجز استشارة"}
              </Typography>
              <Typography
                variant="h4"
                style={{ marginBottom: "24px", textAlign: "start" }}
              >
                {i18next.language == "en"
                  ? "Get expert advice from our professionals"
                  : "احصل على استشارة من خبراءنا"}
              </Typography>
            </Box>
            <div>
              <ActionButton
                variant="contained"
                color="myred2"
                onClick={() => {
                  navigate(token ? "/add-video-consultation" : "/login");
                }}
              >
                {i18next.language == "en"
                  ? "Book a Video Consultation"
                  : "احجز استشارة فيديو"}
              </ActionButton>
              <ActionButton
                variant="outlined"
                color="primary"
                onClick={() => {
                  navigate(token ? "/add-consultation" : "/login");
                }}
              >
                {i18next.language == "en"
                  ? "Ask a Text Question"
                  : "اسأل سؤال نصي"}
              </ActionButton>
            </div>
          </ContentContainer>
          <Box
            sx={{
              maxWidth: "100%",
              maxHeight: "100%",
              display: { xs: "none", md: "block" },
            }}
          >
            <Image src={image} alt="Consultation Image" />
          </Box>
        </HeroSection>
      </div>
    </>
  );
};

export default Consultations;
