import { Stack, Typography } from "@mui/material";
import React from "react";

const EnPrivacyPolicy = () => {
  return (
    <div className="container">
      <div className="order-lg-2 max-mb-50">
        <Stack gap={3} sx={{ marginTop: "50px", marginBottom: "50px" }}>
          <Typography variant="h2" color="primary">
            Privacy Policy
          </Typography>
          <Typography variant="h6">LAST UPDATED: March. 13TH 2024</Typography>
          <Typography>
            This statement (“Privacy Policy”) explains what we do with students’
            personal data which we receive through the educational services (the
            “Services”) provided by Unlimited Horizons, and how trainees
            enrolled in a course provided by Unlimited Horizons can exercise
            their rights over it.
          </Typography>
          <Typography variant="h4" color="primary">
            Who we are and how to contact us?
          </Typography>
          <Typography>
            We are Unlimited Horizons for Training and Consultations, Limited
            Liability Company wholly owned by Mr. Houssam Ataya, of address Afif
            Street, Damascus, Syria (“Unlimited Horizons”). References to “we”,
            “our” or “us” means Unlimited Horizons. We offer an open online
            learning platform to trainees looking to advance their education via
            a platform known as, “Unlimited Horizons”. We are the controller of
            your data. This means that we are responsible for deciding how we
            collect, store and use personal information about you. We may from
            time to time engage third parties to process your information and we
            may also process your information ourselves (as described further
            below). If you have any questions or concerns about the information
            in this Privacy Policy, or about our handling of personal data more
            generally, or if you would like more detailed information, you
            should email us at [info@uh-training.com] or write to us at [afif
            Street, Damascus, Syria] for the attention of “Privacy Officer”.
          </Typography>
          <Typography variant="h4" color="primary">
            The information we collect about you and why we collect it
          </Typography>
          <Typography>
            By registering an account on Unlimited Horizons and in turn using
            our Services, we will collect from you the following information
            (“Personal Data”):
          </Typography>
          <Typography>
            <ul>
              <li>Your name.</li>
              <li>Your email address.</li>
              <li>Your year of birth.</li>
              <li>Highest education level achieved.</li>
              <li>Gender.</li>
              <li>Country of residence/origin.</li>
              <li>Phone numbers.</li>
              <li>
                Social media accounts (If you login/register using social media,
                namely Facebook or Google)
              </li>
              <li>
                Records of your visits to Unlimited Horizons and engagement with
                educational content such as pages, videos, assessments, and
                posts, comments, and any other interactions you perform on
                course discussion forums
              </li>
              <li>
                Details of grades scored on assessments you take on Unlimited
                Horizons
              </li>
              <li>
                Online footage of interactions you performed in online lecture
                videos, called “pause and seek events”.
              </li>
              <li>
                Records of creation of virtual classrooms by teachers on
                Unlimited Horizons
              </li>
              <li>
                Information you add to child accounts you create for your
                children on Unlimited Horizons through the parents’ section on
                the dashboard page
              </li>
            </ul>
          </Typography>

          <Typography variant="h4" color="primary">
            If you are a parent or a guardian creating an account for your
            child,
          </Typography>
          <Typography>
            the following child (“Personal Data”) will be collected:
          </Typography>

          <Typography>
            <ul>
              <li>The child’s name</li>
              <li>The child’s gender (optional)</li>
              <li>The child’s academic grade</li>
              <li>
                The country whose curricula you want the child’s programs to
                follow
              </li>
            </ul>
          </Typography>
          <Typography>
            Unlimited Horizons will never share externally a child’s Personal
            Data, or any linkage between child and parent data.
          </Typography>
          <Typography variant="h4" color="primary">
            Our legal basis for using your Personal Data
          </Typography>

          <Typography>
            Where we use your Personal Data, we process it on the following
            bases:
          </Typography>
          <Typography>
            <ul>
              <li>
                For the legitimate interest of Unlimited Horizons carrying out
                the following activities:
                <ul>
                  <li>
                    Internal research purposes, for example, the improvement of
                    course design and operation.
                  </li>
                  <li>
                    The following research and marketing purposes (unless you
                    decide to opt-out when registering or subsequently):
                    <ol>
                      <li>
                        Internal research in connection with how the Unlimited
                        Horizons platform is used; and
                      </li>
                      <li>
                        For the purpose of sending an update to you in respect
                        of online courses offered by Unlimited Horizons.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Improving the platform user experience of Unlimited
                    Horizons.
                  </li>
                  <li>
                    The publication of scientific papers in journals or
                    conferences. No data that identifies you are ever shared in
                    a scientific publication. We only publish aggregated or
                    anonymized data.
                  </li>
                </ul>
              </li>
            </ul>
          </Typography>
          <Typography>
            We will never collect any unnecessary Personal Data from you and do
            not process your information in any way, other than as specified in
            this Policy.
          </Typography>
          <Typography>
            We make every effort to maintain the accuracy and completeness of
            your Personal Data, which we store, and to ensure all of your
            Personal Data is up to date. However, you can assist us with this by
            promptly contacting us if there are any changes to your Personal
            Data. We shall not be responsible for any losses you suffer arising
            from any inaccurate, inauthentic, deficient or incomplete Personal
            Information that you provide to us.
          </Typography>
          <Typography variant="h4" color="primary">
            How long we will keep your Personal Data
          </Typography>
          <Typography>
            For EU users and users in other countries with data protection
            legislations that require personal user data to be deleted after
            some time, your account data will be deleted automatically after 7
            years of inactivity. Inactivity is defined as the absence of
            interaction with course content. Following 7 years of inactivity, we
            will contact you with a 1-month prior notice, informing you that
            your Personal Data will be deleted from the computers on which it is
            stored, and giving you the option to request your Personal Data is
            retained for another 7 years.
          </Typography>
          <Typography>
            For users in all other countries, your account data will be saved by
            Unlimited Horizons until you submit a request for us to delete your
            data through the Website, after which your data will be deleted
            according to the timeline provided by the Website when you request
            the deletion.
          </Typography>
          <Typography>
            Unlimited Horizons may shorten the retention period for your
            Personal Data depending on the amount, nature, and sensitivity of
            your Personal Data, the potential risk of harm from unauthorized use
            or disclosure of your Personal Data, the purposes for which we
            process your Personal Data and whether we can achieve those purposes
            through other means, and the applicable legal requirements.
          </Typography>
          <Typography variant="h4" color="primary">
            Who we share your Personal Data with?
          </Typography>
          <Typography>
            <ul>
              <li>The internal course management team.</li>
              <li>The internal marketing teams.</li>
              <li>The internal leadership teams.</li>
              <li>
                Third parties we partner with for the purposes of course
                creation, operation, or evaluation (including instructors,
                teaching assistants, and personnel from organizations that
                partner with Unlimited Horizons for the purpose of a course you
                are enrolled in) and marketing agencies we partner with for
                Unlimited Horizons’ specific marketing needs. All third parties
                who receive personal data from Unlimited Horizons sign a data
                processing agreement with Unlimited Horizons that mandates they
                use your Personal Data only for the purposes for which Unlimited
                Horizons contracted them and in accordance with GDPR or any
                other applicable data regulations, and that the third party
                deletes your personal data within 10 days of the termination or
                completion of the contract.
              </li>
            </ul>
          </Typography>
          <Typography>
            We may also from time to time share your Personal Data (in an
            anonymized form) with external educational researchers for the
            purpose of conducting educational research.
          </Typography>
          <Typography variant="h4" color="primary">
            Changes to this policy
          </Typography>
          <Typography>
            Except to the extent limited by applicable law, we reserve the right
            to update this policy to reflect changes to our information
            practices by prominently posting notice of the update on our
            Services, and as required, obtaining your consent. Any updates will
            become effective immediately after posting the updates to this
            policy and apply to all information collected about you, or where
            required, upon your consent. You agree that you will review this
            policy periodically. If we make any changes to this policy, we will
            change the "Last Updated" date above.
          </Typography>
          <Typography variant="h4" color="primary">
            Your rights and how to exercise them
          </Typography>
          <Typography>
            Privacy laws give you certain rights in respect of the data that we
            hold about you. Below is a short overview of those rights.
          </Typography>
          <Typography>
            <ul>
              <li>
                With some exceptions, you have the right to have a copy of the
                Personal Data that we hold about you. We may make a reasonable
                charge for additional copies of that data beyond the first copy,
                based on our administrative costs. Where the data is data that
                you have given to us, you have the right to receive your copy of
                it in a common electronic format, and to provide copies of it to
                other people if you wish.
              </li>
              <li>
                You have the right to have the Personal Data we hold about you
                corrected if it is factually inaccurate.
              </li>
              <li>
                In some circumstances, you have the right to have Personal Data
                that we hold about you erased.
              </li>
              <li>
                You have the right to require us to stop using your Personal
                Data for marketing purposes.
              </li>
              <li>
                If we are processing your Personal Data on the basis of your
                consent, you have the right to withdraw that consent at any
                time, in which case we will stop that processing unless we have
                another legal basis on which to continue. In some cases, the
                only way for Unlimited Horizons to stop that processing would be
                by terminating your accounts. Unlimited Horizons will request
                your confirmation before terminating your account, through the
                email address through which you request to withdraw your
                consent. If you do not provide written confirmation within 21
                days for Unlimited Horizons to terminate your account, your
                request to withdraw your consent will be considered canceled.
              </li>
            </ul>
          </Typography>
          <Typography>
            In order to protect you and others, we may require you to prove your
            identity before you exercise these rights.
          </Typography>
          <Typography>
            If you are unhappy with how we have handled your Personal Data, you
            have the right to contact us.
          </Typography>
        </Stack>
      </div>
    </div>
  );
};

export default EnPrivacyPolicy;
