import { useId } from "react";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import 'react-phone-number-input/style.css'
import i18n from "i18next";

// import MuiPhoneNumber from "material-ui-phone-number";

interface Props {
  arLabel: string;
  enLabel: string;
  prevValue?: string | number | undefined;
  otherOptions?: object;
  isReadOnly?: boolean;
  type?: string;
  fieldName?: string;
  classesForStyles?: string;
  handleChange: Function;
}

export const CustomPhoneInput: React.FC<Props> = ({
  arLabel,
  enLabel,
  prevValue,
  isReadOnly,
  fieldName,
  classesForStyles,
  handleChange,
  otherOptions,
}) => {
  const id = useId();
  return (
    <div className={"col " + classesForStyles}>
      <label htmlFor={id} className="form-label">
        {i18n.language === "en" ? enLabel : arLabel}
      </label>
      <PhoneInputWithCountrySelect
        // className="form-control phone-select"
        id={id}
        countryCallingCodeEditable={false}
        defaultCountry={"SY"}
        type="text"
        // @ts-ignore
        value={prevValue}
        onChange={(e) => handleChange(e, fieldName)}
        aria-describedby={enLabel}
        readOnly={isReadOnly}
        {...otherOptions}
      />
      {/* <MuiPhoneNumber
        defaultCountry={"sy"}
        value={prevValue}
        onChange={(v) => handleChange({ target: { value: v } }, fieldName)}
      /> */}
    </div>
  );
};

export default CustomPhoneInput;
