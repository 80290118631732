import { Box, Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import DraggableSections from "./DraggableSections";
import TextModal from "./TextModal";
import { SectionType } from "../EditBlogForm";
import { Stack } from "@mui/system";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  postSections,
  updateSectionsOrder,
} from "../../../../api/endpoints-functions";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

let sectionHasError = false;

interface Props {
  AddSectionHandler: any;
  onDragEnd: any;
  dragItems: SectionType[];
  onDelete: any;
  openTextModalHandler: any;
  selectedSection: SectionType;
  textModalOpen: boolean;
  setTextModalOpen: any;
  addDescriptionHandler: any;
  arabicDescription: string;
  setArabicDescription: any;
  englishDescription: string;
  setEnglishDescription: any;
  onAddFile: any;
}

const SectionsList = ({
  AddSectionHandler,
  onDragEnd,
  dragItems,
  onDelete,
  openTextModalHandler,
  selectedSection,
  textModalOpen,
  setTextModalOpen,
  addDescriptionHandler,
  arabicDescription,
  setArabicDescription,
  englishDescription,
  setEnglishDescription,
  onAddFile,
}: Props) => {
  //   const add = AddSections();

  const { t } = useTranslation();

  const { id } = useParams();

  console.log(id);

  const queryClient = useQueryClient();

  const add = useMutation((data: any) => postSections(data), {
    onSuccess: () => {
      toast.success("Sections added succfully!");
      queryClient.invalidateQueries(["get-blog"]);
    },
    onError: () => {
      toast.error("Something went wrong, please try again!");
    },
  });

  //   const order = UpdateSectionsOrder();

  const order = useMutation((data: any) => updateSectionsOrder(data), {
    onSuccess: () => {
      toast.success("Sorted succefully");
      queryClient.invalidateQueries(["get-blog"]);
    },
    onError: () => {
      toast.error("Something went wrong, please try again");
    },
  });

  const filteredSections = dragItems.filter(
    (item: SectionType) => !item.blog_id
  );

  const addSectionsHandler = () => {
    const formData = new FormData();

    formData.append("blog_id", id!);

    filteredSections.forEach((section: SectionType) => {
      if (section.translations.ar.text === "") {
        sectionHasError = true;
        toast.error("Arabic description is required!");
      } else if (section.translations.en.text === "") {
        sectionHasError = true;
        toast.error("English description is required!");
      } else {
        sectionHasError = false;
      }
    });

    if (sectionHasError) {
      return;
    }

    filteredSections.forEach((section: any, index: any) => {
      formData.append(`sections[${index}][file]`, section.file);
      formData.append(
        `sections[${index}][type]`,
        section.type !== "" ? section.type : "text"
      );
      formData.append(`sections[${index}][text]`, section.translations.ar.text);
      formData.append(
        `sections[${index}][text_en]`,
        section.translations.en.text
      );
      formData.append(`sections[${index}][order]`, `${dragItems.length - 1}`);
    });

    add.mutateAsync(formData);
  };

  const updateOrderingHandler = () => {
    const formData = new FormData();
    formData.append("_method", "PUT");
    dragItems.forEach((item: any, index: any) => {
      formData.append(`sections[${index}][id]`, item.id);
      formData.append(`sections[${index}][order]`, index);
    });

    order.mutateAsync(formData);
  };

  return (
    <Box sx={{ marginTop: "1rem", textAlign: "right" }}>
      {dragItems.length > 1 && (
        <Button
          variant="contained"
          sx={{ marginBottom: "1rem" }}
          color="secondary"
          onClick={updateOrderingHandler}
          disabled={order.isLoading}
        >
          {t("saveOrder")}
        </Button>
      )}
      <DraggableSections
        onDragEnd={onDragEnd}
        items={dragItems}
        onDelete={onDelete}
        onOpen={openTextModalHandler}
        onAddFile={onAddFile}
      />
      <TextModal
        section={selectedSection}
        open={textModalOpen}
        setOpen={setTextModalOpen}
        onSave={addDescriptionHandler}
        item={selectedSection}
        arabicDescription={arabicDescription}
        englishDescription={englishDescription}
        setArabicDescription={setArabicDescription}
        setEnglishDescription={setEnglishDescription}
      />
      <Stack
        direction={"row"}
        marginTop={"1rem"}
        spacing={1}
        justifyContent={"end"}
      >
        <Button
          onClick={AddSectionHandler}
          variant="outlined"
          startIcon={<Add />}
        >
          {t("addNewSection")}
        </Button>

        <Button
          onClick={addSectionsHandler}
          variant="contained"
          disabled={filteredSections.length === 0 || add.isLoading}
        >
          {add.isLoading ? "Loading..." : `${t("save")}`}
        </Button>
      </Stack>
    </Box>
  );
};

export default SectionsList;
