import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import {
  assignCertificate,
  fetchCertificates,
} from "../../../api/endpoints-functions";
import { Box, Stack } from "@mui/system";
import { CircularProgress, Grid, Button } from "@mui/material";
import CertificateCard from "./CertificateCard";
import i18next, { t } from "i18next";
import { toast } from "react-toastify";
import { CertificateResponse } from "../../../interfaces/new-api-interfaces/certificate";
import { useNavigate } from "react-router-dom";

const CourseCertificates = ({
  id,
  assignedCertificates,
  isEdit = false,
  isLoading,
  isFetching,
}: {
  id: number;
  assignedCertificates?: CertificateResponse[];
  isEdit?: boolean;
  isLoading?: boolean;
  isFetching?: boolean;
}) => {
  const [selectedIds, setSelectedIds] = useState<any[]>([]);

  const { data, isLoading: certificatesLoading } = useQuery(
    ["certificates"],
    () => fetchCertificates()
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (assignedCertificates && isEdit) {
      let arr: any = [];
      assignedCertificates.forEach((item) => {
        arr.push(item.id);
      });
      setSelectedIds(arr);
    }
  }, [assignedCertificates]);

  const assign = useMutation(({ data }: any) => assignCertificate(data), {
    onSuccess: (res) => {
      console.log(res);
      navigate(`/course-details/${id}`);
      toast.success(res.data[0]);
    },
    onError: (error: any) => {
      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).forEach((errorKey) => {
          toast.error(error.response.data.errors[errorKey].join("\n"));
        });
      } else {
        toast.error(error.response.data.message);
      }
    },
  });

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("certificatable_id", id.toString());
    formData.append("certificatable_type", "course");
    if (selectedIds && selectedIds.length > 0) {
      selectedIds.forEach((id, index) => {
        formData.append(`certificate[${index}]`, id);
      });
    }
    assign
      .mutateAsync({
        data: formData,
      })
      .then(() => {
        setSelectedIds([]);
      });
  };

  return isLoading || certificatesLoading || isFetching ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "60vh",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box my={6}>
      <Stack mb={2} justifyContent="end" direction="row" spacing={1}>
        <Button
          variant="contained"
          disabled={assign.isLoading}
          onClick={handleSubmit}
        >
          {isEdit ? t("edit") : t("submit")}
        </Button>
      </Stack>
      <Grid container spacing={2}>
        {data?.map((item) => (
          <Grid item xs={12} sm={6} md={3}>
            <CertificateCard
              setSelectedIds={setSelectedIds}
              selectedIds={selectedIds}
              key={item.id}
              item={item}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CourseCertificates;
