import { useParams } from "react-router-dom";
import LearnPress from "../../components/Profile/profile-sections/learn-press-section";
import i18next, { t } from "i18next";
import { getImageFromServer } from "../../assets/JS/helpers";
import { ScrollTop } from "../../components/Common/scroll-top.component";
import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../api/api";
import { LoadingPage } from "../../components/Common/loadingPage.component";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
// @ts-ignore
import ReactStars from "react-rating-stars-component";
import { useAtomValue } from "jotai";
import { roleAtom, userAtom } from "../../store";
import {
  fetchInstructorBlogs,
  fetchInstructorDetails,
  fetchSingleInstructorCourses,
  fetchSingleInstructorGlobalTests,
} from "../../api/endpoints-functions";
import { defaultTranslation } from "../../untilites/functions";
import { AiOutlineFileText } from "react-icons/ai";
import { FaRegCircleUser } from "react-icons/fa6";
import { useEffect, useState } from "react";
import { BlogResponse } from "../../interfaces/new-api-interfaces/blog";
import InstructorDetailsButtons from "../../components/instructors/instructor-details-buttons";

interface Props {}

const InstructorPage: React.FC<Props> = () => {
  const { id } = useParams();
  const [blogs, setBlogs] = useState<BlogResponse[]>();
  const { data, isLoading, isError, isFetching } = useQuery(
    ["teacher-profile"],
    () => fetchInstructorDetails(+id!)
  );

  const { data: courses, isLoading: coursesLoading } = useQuery(
    ["get-instructor-courses"],
    () => fetchSingleInstructorCourses(+id!)
  );

  const { data: globalTests } = useQuery(["get-instructor-global-tests"], () =>
    fetchSingleInstructorGlobalTests(+id!)
  );

  useEffect(() => {
    if (data) {
      const fetchBlogs = async () => {
        const fetchedBlogs = await fetchInstructorBlogs(data.user_id!);
        setBlogs(fetchedBlogs);
      };
      fetchBlogs();
    }
  }, [data]);

  const role = useAtomValue(roleAtom);

  if (isLoading || isFetching) return <LoadingPage />;
  return (
    <div className="profile-section section section-padding-bottom">
      <div className="container">
        <Box
          sx={{
            display: "flex",
            gap: "40px",
            padding: "50px 0px",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: { xs: "center", md: "flex-start" },
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", sm: "45%", md: "25%" },
              display: "flex",
              justifyContent: { xs: "center", md: "flex-start" },
            }}
          >
            <div className="profile-image">
              <img
                src={
                  data?.profile_image && data?.profile_image.length
                    ? getImageFromServer(data?.profile_image)
                    : require("../../assets/images/person-fallback.jpeg")
                }
                alt="profile"
                style={{ borderRadius: "20px" }}
              />
            </div>
          </Box>
          <Stack sx={{ width: { xs: "90%", sm: "45%", md: "25%" } }} gap={2}>
            <Typography variant="h5">
              {defaultTranslation(data, "first_name") +
                " " +
                defaultTranslation(data, "last_name")}
            </Typography>
            <div className="author-career">
              {i18next.language === "ar"
                ? data?.instructors_translations.ar.education
                : data?.instructors_translations.en.education}
            </div>
            {/* <div className="d-flex align-items-center">
              {!isLoading && !isFetching ? (
                <>
                  <div className="review-star">
                    <div className="tm-star-rating">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          justifyContent: "center",
                        }}
                      >
                        <Typography>
                          {t("CourseDetailsAverageRating") + " : "}
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Box
                            style={{
                              direction: "ltr",
                              transform:
                                i18next.language === "ar" ? "scaleX(-1)" : "",
                            }}
                          >
                            <ReactStars
                              size={24}
                              isHalf={true}
                              value={data?.rating}
                              edit={false}
                              emptyIcon={<i className="far fa-star"></i>}
                              halfIcon={<i className="fa fa-star-half-alt"></i>}
                              fullIcon={<i className="fa fa-star"></i>}
                              activeColor="#ffd700"
                            />
                          </Box>
                          <div className="author-career ml-2">
                            ({data?.rating})
                          </div>
                        </Box>
                      </Box>
                    </div>
                  </div>
                </>
              ) : (
                <Skeleton variant="rectangular" width={200} height={20} />
              )}
            </div> */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: { xs: "10px", sm: "10px", xlg: "20px" },
                flexWrap: "nowrap",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Box sx={{ fontSize: "large" }}>
                  <AiOutlineFileText />
                </Box>
                <Typography sx={{ whiteSpace: "nowrap" }}>
                  {data?.course_enrollment} {t("course")}
                </Typography>
              </Box>
              {parseInt(data?.student_count!) > 0 && (
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <Box sx={{ fontSize: "large" }}>
                    <FaRegCircleUser />
                  </Box>
                  <Typography sx={{ whiteSpace: "nowrap" }}>
                    {data?.student_count} {t("CourseDetailsStudents")}
                  </Typography>
                </Box>
              )}
            </Box>
            <Stack gap={1}>
              <Typography variant="h5">{t("ContactUsContact")}</Typography>
              {/* <Typography className="phone">
                {t("ContactUsMobile")}: {"   " + data?.phone_number}
              </Typography> */}
              <Typography>
                {t("GlobalMail")}:
                <a
                  style={{ textDecoration: "underline" }}
                  href={`mailto:${data?.email}`}
                  className="email"
                >
                  {"   " + data?.email}
                </a>
              </Typography>
            </Stack>
          </Stack>
          <Box sx={{ width: { xs: "90%", sm: "90%", md: "40%" } }}>
            <Typography variant="h4" sx={{ paddingBottom: "10px" }}>
              {t("Brief")}
            </Typography>
            <Typography>
              {i18next.language === "ar"
                ? data?.instructors_translations.ar.bio
                : data?.instructors_translations.en.bio}
            </Typography>
            {role == "student" && <InstructorDetailsButtons id={`${id}`} />}
          </Box>
        </Box>
      </div>
      <LearnPress
        courses={courses}
        globalTests={globalTests!}
        isShowTestCorrection={false}
        instructorId={+id!}
        blogs={blogs}
      />
      <ScrollTop />
    </div>
  );
};

export default InstructorPage;
