import React from "react";
import { SetStateAction } from "jotai";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import i18next from "i18next";
import { defaultTranslation } from "../../../untilites/functions";
import { studentVideoConsultation } from "../../../interfaces/new-api-interfaces/consultations";
import { getStatusTranslation } from "../profile-components/video-consultation-table-item";
import { useTranslation } from "react-i18next";
const PreviewStudentConsultationModal = ({
  item,
  open,
  setOpen,
}: {
  item: studentVideoConsultation;
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
}) => {
  const translatedStatus = getStatusTranslation(
    item.status,
    i18next.languages[0]
  );
  const { t } = useTranslation();
  return (
    <Dialog
      dir={i18next.language === "ar" ? "rtl" : "ltr"}
      fullWidth
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h5">
            {i18next.language === "ar"
              ? `تفاصيل الاستشارة`
              : `Consultation Details`}
          </Typography>
          <Typography
            sx={{
              color: item.status.includes("rejected")
                ? "red"
                : item.status == "pending"
                ? "orange"
                : item.status == "scheduled"
                ? "blue"
                : item.status == "paid"
                ? "green"
                : "default",
            }}
            variant="h6"
          >
            {translatedStatus}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ padding: "1rem" }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography fontWeight={600} component="span">
                        {i18next.language === "ar"
                          ? "اسم المدرب"
                          : "trainer's Name"}
                        :{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        {defaultTranslation(item.instructor, "first_name") +
                          " " +
                          defaultTranslation(item.instructor, "last_name")}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography fontWeight={600} component="span">
                        {i18next.language === "ar"
                          ? "سبب الاستشارة"
                          : "Consultation Reason"}
                        :{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{item.student_reason}</Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
              {[
                "pending",
                "rejected by student",
                "rejected by instructor",
              ].includes(item.status) && (
                <TableRow>
                  <TableCell>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography fontWeight={600} component="span">
                          {i18next.language === "ar"
                            ? "الوقت المفضل للاستشارة"
                            : "Preffered Date"}
                          :{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{item.student_date}</Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography fontWeight={600} component="span">
                        {i18next.language === "ar"
                          ? "ملاحظات اضافية"
                          : "Additional Notes"}
                        :{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{item.student_notes}</Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
              {["scheduled", "rejected by student"].includes(item.status) && (
                <>
                  <TableRow>
                    <TableCell>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography fontWeight={600} component="span">
                            {i18next.language === "ar"
                              ? "سعر الاستشارة"
                              : "Consultation Price"}
                            :{" "}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>{`${item.price?.toLocaleString()} ${t("syp")}`}</Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography fontWeight={600} component="span">
                            {i18next.language === "ar"
                              ? "موعد الاستشارة"
                              : "Scheduled Date"}
                            :{" "}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>{item.instructor_date}</Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography fontWeight={600} component="span">
                            {i18next.language === "ar"
                              ? "وقت الاستشارة"
                              : "Consultation Time"}
                            :{" "}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>
                            {item.instructor_start_time?.slice(0, -3)} -{" "}
                            {item.instructor_end_time?.slice(0, -3)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                </>
              )}
              {item.status === "rejected by instructor" && (
                <TableRow>
                  <TableCell>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography fontWeight={600} component="span">
                          {i18next.language === "ar"
                            ? "سبب الرفض"
                            : "Consultation Reason"}
                          :{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{item.instructor_notes}</Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} variant="outlined">
          {i18next.language === "ar" ? "إغلاق" : "Close"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default PreviewStudentConsultationModal;
