import React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import i18next from "i18next";
import ListItemIcon from "@mui/material/ListItemIcon";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
interface ConsultationStatusProps {
  selectedStatus: string;
  setSelectedStatus: Function;
}
const ConsultationStatusSelect: React.FC<ConsultationStatusProps> = ({
  selectedStatus,
  setSelectedStatus,
}) => {
  const getStatusColor = (status: string) => {
    switch (status) {
      case "pending":
        return "orange";
      case "scheduled":
        return "blue";
      case "paid":
        return "green";
      case "rejected by student":
        return "red";
      case "rejected by instructor":
        return "red";
      case "done":
        return "gray";
      default:
        return "black";
    }
  };
  return (
    <Box
      sx={{ display: "flex", justifyContent: "flex-end", marginBottom: "1rem" }}
    >
      <FormControl>
        <InputLabel htmlFor="status" id="status-label">
          {i18next.language === "ar" ? "حالة الاستشارة" : "Consultation Status"}
        </InputLabel>
        <Select
          labelId="status-label"
          id="status"
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value as string)}
          inputProps={{ id: "status" }}
          label={
            i18next.language === "ar" ? "حالة الاستشارة" : "Consultation Status"
          }
          MenuProps={{ disableScrollLock: true }}
          sx={{ minWidth: "200px" }}
        >
          <MenuItem value="pending">
            <ListItemIcon>
              <FiberManualRecordIcon
                sx={{ color: getStatusColor("pending") }}
              />
            </ListItemIcon>
            {i18next.language === "ar" ? "قيد الانتظار" : "Pending"}
          </MenuItem>
          <MenuItem value="scheduled">
            <ListItemIcon>
              <FiberManualRecordIcon
                sx={{ color: getStatusColor("scheduled") }}
              />
            </ListItemIcon>
            {i18next.language === "ar" ? "مجدولة" : "Scheduled"}
          </MenuItem>
          <MenuItem value="paid">
            <ListItemIcon>
              <FiberManualRecordIcon sx={{ color: getStatusColor("paid") }} />
            </ListItemIcon>
            {i18next.language === "ar" ? "مثبتة" : "Submitted"}
          </MenuItem>
          <MenuItem value="rejected by student">
            <ListItemIcon>
              <FiberManualRecordIcon
                sx={{ color: getStatusColor("rejected by student") }}
              />
            </ListItemIcon>
            {i18next.language === "ar"
              ? "مرفوضة من قبل الطالب"
              : "Rejected by student"}
          </MenuItem>
          <MenuItem value="rejected by instructor">
            <ListItemIcon>
              <FiberManualRecordIcon
                sx={{ color: getStatusColor("rejected by instructor") }}
              />
            </ListItemIcon>
            {i18next.language === "ar" ? "مرفوضة من قبلك" : "Rejected by you"}
          </MenuItem>
          <MenuItem value="done">
            <ListItemIcon>
              <FiberManualRecordIcon sx={{ color: getStatusColor("done") }} />
            </ListItemIcon>
            {i18next.language === "ar" ? "منتهية" : "Done"}
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};
export default ConsultationStatusSelect;
