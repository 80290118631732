import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { SideSectionItem } from "./side-section-item";
import i18next from "i18next";
import { Box, CircularProgress, Typography } from "@mui/material";
import {
  fetchCourseRecents,
  fetchShopItemsRecents,
} from "../../../api/endpoints-functions";
import { Recents } from "../../../interfaces/new-api-interfaces/course";

interface Props {
  type: string;
}

export const SideSection: React.FC<Props> = ({ type }) => {
  const [topItems, setTopItems] = useState<Recents[]>();
  const { isLoading, isError } = useQuery(
    [`get-top-${type}`],
    () => (type == "course" ? fetchCourseRecents() : fetchShopItemsRecents()),
    {
      onSuccess: (data: Recents[]) => {
        setTopItems(data);
      },
    }
  );
  const { t } = useTranslation();
  return (
    <Box
      className="col-lg-4 col-12 order-lg-2 max-mb-50"
      sx={{ marginTop: { xs: "50px", lg: 0 } }}
    >
      <div
        className="sidebar-widget-wrapper pl-md-0 pl-sm-0 pl-xs-0"
        style={{
          paddingLeft: i18next.language == "en" ? "30px" : "0px",
          paddingRight: i18next.language == "ar" ? "30px" : "0px",
        }}
      >
        <div className="sidebar-widget recent__courses__details_sidebar">
          {!isLoading ? (
            <>
              <Typography variant="h5" marginBottom={3}>
                {type == "course"
                  ? t("CourseDetailsRecentCourses")
                  : t("ShopDetailsRecentItems")}
              </Typography>

              <div className="sidebar-widget-content">
                <ul className="sidebar-widget-course" style={{padding:0}}>
                  {topItems?.map((item) => (
                    <SideSectionItem
                      key={item.id}
                      name={item.translations[i18next.language].name}
                      description={
                        type == "course"
                          ? item.translations[i18next.language].web_description!
                          : item.translations[i18next.language].description!
                      }
                      image={
                        type == "course" ? item.course_image! : item.image!
                      }
                      id={item.id}
                      link={type == "course" ? "course-details" : "shop-item"}
                    />
                  ))}
                </ul>
              </div>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                minHeight: "200px",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </div>
      </div>
    </Box>
  );
};
