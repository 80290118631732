import React from "react";
import { IStudentResult } from "../../../interfaces/api-interfaces";
import { useNavigate } from "react-router-dom";
import { TableRow, TableCell } from "@mui/material";
import { defaultTranslation } from "../../../untilites/functions";
import { StudentTest } from "../../../interfaces/new-api-interfaces/profile";
import i18next from "i18next";

interface Props {
  result: StudentTest;
  linkTo: string;
  id: number;
  isChecked?: boolean;
}

const ResultTableItem: React.FC<Props> = ({
  id,
  linkTo,
  result,
  isChecked,
}) => {
  const navigate = useNavigate();
  return (
    <TableRow
      onClick={() => navigate(!linkTo ? "/edit-test/" + id : linkTo)}
      style={{ cursor: "pointer" }}
    >
      <TableCell className="column-quiz column-quiz-1308">
        {defaultTranslation(result, "name")}
      </TableCell>
      <TableCell className="column-quiz column-quiz-1308">
        {defaultTranslation(result, "description")?.slice(0, 100)}
        {defaultTranslation(result, "description")?.length > 100 ? "..." : ""}
      </TableCell>
      <TableCell className="column-date">
        {result.created_at?.slice(0, 10)}
      </TableCell>
      {isChecked && (
        <TableCell className="column-date">{result.mark}</TableCell>
      )}
      <TableCell
        sx={{ color: result.is_checked ? "green" : "salmon" }}
        className="column-quiz column-quiz-1308"
      >
        {result.is_checked
          ? i18next.language === "en"
            ? "Checked"
            : "مصحح"
          : i18next.language === "en"
          ? "Not Checked"
          : "غير مصحح"}
      </TableCell>
    </TableRow>
  );
};

export default ResultTableItem;
