import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
interface Props {
  title?: string;
  link: string;
  prevLinks: { title: string; link: string }[];
}

export const PageTitle: React.FC<Props> = (Props) => {
  return (
    <div
      className="page-title-section section"
      style={{ padding: `${Props.title ? "6vh 0" : "0px"}` }}
    >
      {Props.title ? (
        <div className="page-title">
          <div className="container">
            <h1 className="title">{Props.title}</h1>
          </div>
        </div>
      ) : null}
      <div className="page-breadcrumb">
        <div className="container">
          <ul className="breadcrumb">
            {Props.prevLinks.map((prevLink) => (
              <li key={prevLink.title}>
                <Link to={prevLink.link}>{prevLink.title}</Link>
              </li>
            ))}

            <li className="current">{Props.link}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};