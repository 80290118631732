import { Delete, Edit, ExpandMore, Upload } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  IconButton,
  ListItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Draggable } from "react-beautiful-dnd";

import DisplayFile from "../../../../helpers/display-file";
import { getImageFromServer } from "../../../../../assets/JS/helpers";
import { SectionType } from "../../EditBlogForm";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  postSections,
  updateSection,
} from "../../../../../api/endpoints-functions";
import { toast } from "react-toastify";
import { t } from "i18next";
import CustomUploader from "../../../../helpers/custom-uploader";

export type DraggableListItemProps = {
  item: SectionType;
  index: number;
  onDelete: any;
  onOpen: any;
  onAddFile: any;
  id: any;
  handleDeleteSection: any;
};

const DraggableSection = ({
  item,
  index,
  onDelete,
  onOpen,
  onAddFile,
  id,
  handleDeleteSection,
}: DraggableListItemProps) => {
  const queryClient = useQueryClient();

  const update = useMutation(({ data, id }: any) => updateSection(data, id), {
    onSuccess: () => {
      toast.success("Section updated succfully");
      queryClient.invalidateQueries(["get-blog"]);
    },
    onError: () => {
      toast.error("Something went wrong, pleast try again!");
    },
  });

  const updateSectionHandler = () => {
    const formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("blog_id", item.blog_id!.toString());
    formData.append("text", item.translations.ar.text);
    formData.append("text_en", item.translations.en.text);
    formData.append("file", item.file);
    formData.append("type", item.type);

    update.mutateAsync({ data: formData, id: item.id });
  };

  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <Accordion
          sx={{
            backgroundColor: snapshot.isDragging
              ? "rgb(235,235,235)"
              : "#f5f5f5",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            marginBottom: "1rem",
          }}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography>{`${t("section")} # ${index + 1}`}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction={"row"} justifyContent={"end"}>
              <CustomUploader
                handleChange={(value: any) => onAddFile(value, item.id)}
                uploadIcon={<Upload sx={{ cursor: "pointer" }} />}
              />
              <Tooltip arrow title="Discription">
                <IconButton size="small" onClick={() => onOpen(item)}>
                  <Edit />
                </IconButton>
              </Tooltip>
            </Stack>
            <ListItem>
              <Box sx={{ width: "100%", marginTop: "1rem" }}>
                {item.type === "image" && (
                  <Box
                    sx={{
                      width: "600px",
                      margin: "auto",
                      marginBottom: "1rem",
                    }}
                  >
                    <img
                      style={{ width: "100%", objectFit: "cover" }}
                      src={
                        item.file.includes("base64")
                          ? item.file
                          : getImageFromServer(item.file)
                      }
                      alt="file"
                    />
                  </Box>
                )}

                {item.type === "video" && (
                  <Box sx={{ marginBottom: "1rem" }}>
                    <DisplayFile
                      type="video"
                      file={
                        item.file.includes("base64")
                          ? item.file
                          : getImageFromServer(item.file)
                      }
                    />
                  </Box>
                )}
                <Stack direction={"row"} spacing={2} justifyContent={"center"}>
                  {item.translations.ar.text && (
                    <Box
                      sx={{
                        borderRadius: "8px",
                        border: "1px solid #aaa",
                        flexBasis: "50%",
                        padding: "1rem",
                      }}
                    >
                      <Typography>{t("descAr")}</Typography>
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: item.translations.ar.text,
                        }}
                      ></Box>
                    </Box>
                  )}
                  {item.translations.en.text && (
                    <Box
                      sx={{
                        borderRadius: "8px",
                        border: "1px solid #aaa",
                        flexBasis: "50%",
                        padding: "1rem",
                      }}
                    >
                      <Typography>{t("descEn")}</Typography>
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: item.translations.en.text,
                        }}
                      ></Box>
                    </Box>
                  )}
                </Stack>
              </Box>
            </ListItem>
            {item.blog_id && (
              <Stack
                direction={"row"}
                justifyContent={"end"}
                marginTop={"1rem"}
              >
                <Button
                  variant="contained"
                  onClick={updateSectionHandler}
                  disabled={update.isLoading}
                >
                  {update.isLoading ? "Loading..." : "Update Section"}
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  sx={{ margin: "0 0.5rem" }}
                  onClick={() => handleDeleteSection(item.id)}
                >
                  Delete
                </Button>
              </Stack>
            )}
          </AccordionDetails>
        </Accordion>
      )}
    </Draggable>
  );
};

export default DraggableSection;
