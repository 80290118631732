import { Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { PageTitle } from "../../components/Common/page-title-section.component";
import { BlogContent } from "../../components/Blog/blog-content";

interface Props {}

const BlogDetails: React.FC<Props> = () => {
  const { t } = useTranslation();
  return (
    <div>
      <PageTitle
        link={t("BlogPageBlogDetails")}
        prevLinks={[
          {
            title: t("GlobalHome"),
            link: "/",
          },
          {
            title: t("HeaderNavBlog"),
            link: "/blog-grid",
          },
        ]}
      />
      <div className="section section-padding-bottom fix">
        <div className="container">
          <Grid container md={8} sx={{ mx: "auto" }}>
            <BlogContent />
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
