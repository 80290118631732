import React, { useState } from "react";
import { Button, Grid, Pagination, Typography } from "@mui/material";
import i18n from "i18next";
import ProfileCourseItem from "../profile-components/profile-course-item";
import { profileCoursesResponse } from "../../../interfaces/new-api-interfaces/profile";
import { useLocation, useNavigate } from "react-router-dom";
import emptyCoures from "../../../assets/images/courses/undraw_Online_learning_re_qw08.png";
import i18next from "i18next";
import EmptyTab from "./empty-tab";

interface Props {
  courses: profileCoursesResponse[];
  activeTab: string | null;
}

const ProfileCourses: React.FC<Props> = ({ courses, activeTab }) => {
  const ITEMS_PER_PAGE: number = 9;
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(ITEMS_PER_PAGE);

  const paginate = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPageNumber(page);
    setMin(ITEMS_PER_PAGE * (page - 1));
    setMax(page * ITEMS_PER_PAGE);
  };

  const navigate = useNavigate();

  return (
    <div
      id="courses"
      className={`profile-content-courses tab-pane fade pt-5 show ${
        activeTab === "courses" ? "active" : ""
      }`}
    >
      <div>
        <div className="section section-padding-bottom">
          <div className="container">
            <Grid container spacing={2}>
              {React.Children.toArray(
                courses && courses?.length ? (
                  courses
                    .sort(
                      (a, b) =>
                        new Date(b.created_at).valueOf() -
                        new Date(a.created_at).valueOf()
                    )
                    .filter((_1: any, idx: number) => idx >= min && idx < max)
                    .map((course) => (
                      <Grid item xs={12} sm={6} md={4}>
                        <ProfileCourseItem
                          progress={course.progress}
                          // testsCount={course.lesson_count}
                          // lesson_count={course.lesson_count}
                          {...course}
                        />
                      </Grid>
                    ))
                ) : (
                  <EmptyTab
                    image={emptyCoures}
                    text={
                      i18n.language === "en"
                        ? "There are no courses yet!"
                        : "لا تملك دورات بعد!"
                    }
                    buttonText={
                      i18next.language == "en"
                        ? "start Learning"
                        : "ابدأ بالتعلم"
                    }
                    link="/course-grid"
                  />
                )
              )}
            </Grid>
            <>
              {courses.length > ITEMS_PER_PAGE ? (
                <Pagination
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "20px",
                  }}
                  count={Math.ceil(courses.length / ITEMS_PER_PAGE)}
                  page={currentPageNumber}
                  onChange={paginate}
                  variant="outlined"
                  color="primary"
                  size="large"
                  dir="ltr"
                />
              ) : null}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCourses;
