import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchApi } from "../api/api";
import { toast } from "react-toastify";
import i18n from "i18next";
import { wishListRequest } from "../interfaces/new-api-interfaces/cart";
import { useAtomValue } from "jotai";
import { userAtom } from "../store";

export const useAddToWishlist = () => {
  const user = useAtomValue(userAtom);
  const queryClient = useQueryClient();
  const { isLoading, isError, mutate, mutateAsync } = useMutation(
    ({ wishlistable_id, wishlistable_type }: wishListRequest) =>
      fetchApi("/wishlist/wishlist", "POST", {
        wishlistable_id,
        wishlistable_type,
        user_id: user?.id,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-shop-items"]);
        toast.success(
          i18n.language === "en"
            ? "added successfully to wishlist"
            : "تمت الإضافة الى المفضلة بنجاح"
        );
      },
      onError: () =>
        toast.error(
          i18n.language === "en"
            ? "An error occurred while performing the operation"
            : "حدث خطأ أثناء تنفيذ العملية"
        ),
    }
  );
  return { isLoading, isError, mutate, mutateAsync };
};
export const useRemoveFromWishlist = () => {
  const user = useAtomValue(userAtom);
  const queryClient = useQueryClient();
  const { isLoading, isError, mutate, mutateAsync } = useMutation(
    ({ wishlistable_id, wishlistable_type }: wishListRequest) =>
      fetchApi("/wishlist/wishlist", "POST", {
        wishlistable_id,
        wishlistable_type,
        user_id: user?.id,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-shop-items"]);
        toast.success(
          i18n.language === "en"
            ? "successfully removed from wishlist"
            : "تم الحذف من المفضلة بنجاح"
        );
      },
      onError: () =>
        toast.error(
          i18n.language === "en"
            ? "An error occurred while performing the operation"
            : "حدث خطأ أثناء تنفيذ العملية"
        ),
    }
  );
  return { isLoading, isError, mutate, mutateAsync };
};
