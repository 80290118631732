import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import TextEditor from "../TextEditor";
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Typography,
} from "@mui/material";
import { FeedbackOutlined } from "@mui/icons-material";
import i18next from "i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postTestNotes } from "../../api/endpoints-functions";
import { toast } from "react-toastify";

interface Props {
  testId: number;
  userId: number;
  type: string;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  hasFeedback: boolean;
}

const TestCorrectionFeedback = ({
  value,
  setValue,
  type,
  testId,
  userId,
  hasFeedback,
}: Props) => {
  const queryClient = useQueryClient();

  const add = useMutation((data: any) => postTestNotes(data), {
    onSuccess: () => {
      toast.success(
        i18next.language === "ar"
          ? "تم إضافة الملاحظات بنجاح"
          : "Notes added succefully"
      );
      queryClient.invalidateQueries(["get-global-test"]);
    },
    onError: (error: any) => {
      toast.error(error.message);
    },
  });

  const [isError, setIsError] = useState(false);

  const addHandler = () => {
    if (!value || value === "" || value === "<p><br></p>") {
      setIsError(true);
      return;
    }
    const data = {
      type: type,
      id: testId,
      user_id: userId,
      notes: value,
    };
    add.mutateAsync(data);
  };

  useEffect(() => {
    if (value !== "" && value !== "<p><br></p>") {
      setIsError(false);
    } else {
      setIsError(true);
    }
  }, [value]);

  return (
    <Box>
      <Typography
        display={"flex"}
        gap={"0.4rem"}
        alignItems={"center"}
        variant="h5"
        color="mypurple2.main"
        marginBottom={"1rem"}
      >
        <FeedbackOutlined />
        {i18next.language == "en" ? `Add Feedback` : `إضافة مراجعة`}
      </Typography>

      <TextEditor height="300px" value={value} setValue={setValue} />

      {isError && (
        <FormHelperText error sx={{ fontSize: "1rem" }}>
          {i18next.language === "ar"
            ? "حقل الملاحظات مطلوب"
            : "Feedback is required!"}
        </FormHelperText>
      )}
      <Box marginTop={"1rem"} textAlign={"right"}>
        <Button
          variant="contained"
          onClick={addHandler}
          disabled={add.isLoading}
        >
          {add.isLoading ? (
            <CircularProgress size={16} />
          ) : hasFeedback ? (
            "Update"
          ) : (
            "Add"
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default TestCorrectionFeedback;
