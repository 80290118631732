import React from "react";

import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";

import Profile from "../../components/Profile/student-profile-sections/profile-section";
import LearnPress from "../../components/Profile/student-profile-sections/learn-press-section";

import { LoadingPage } from "../../components/Common/loadingPage.component";
import { ScrollTop } from "../../components/Common/scroll-top.component";

import { fetchProfile } from "../../api/endpoints-functions";

interface Props {}

const StudentProfilePage: React.FC<Props> = () => {
  const { t } = useTranslation();

  const { data, isLoading, isError } = useQuery(["get-profile"], () =>
    fetchProfile()
  );
  if (isLoading) return <LoadingPage />;
  return (
    <div>
      {data ? <Profile {...data} /> : null}
      <LearnPress id={data?.id!} />
      <ScrollTop />
    </div>
  );
};

export default StudentProfilePage;
