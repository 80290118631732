import React from "react";
import { useTranslation } from "react-i18next";
import { Course } from "../../../interfaces/api-interfaces";
import { getBasicDate, getImageFromServer } from "./../../../assets/JS/helpers";
import { Link } from "react-router-dom";
import { Box, CardMedia, Typography } from "@mui/material";
import {
  FaArrowLeftLong,
  FaArrowRightLong,
  FaRegCircleUser,
} from "react-icons/fa6";
import { AiOutlineFileText } from "react-icons/ai";
import { courseResponse } from "../../../interfaces/new-api-interfaces/course";
import i18next from "i18next";
import { BlogResponse } from "../../../interfaces/new-api-interfaces/blog";
import { PiNotePencilBold } from "react-icons/pi";
import { BsCalendarDate } from "react-icons/bs";

interface props {
  id: number;
  name: string;
  description: string;
  type: "course" | "blog" | "shop-item";
  price?: number;
  enrollment_number?: number;
  lesson_count?: number;
  image: string;
  createdAt?: string;
  createdBy?: BlogResponse["created_by"];
}

export const RelatedItemCard: React.FC<props> = ({
  id,
  name,
  description,
  type,
  price,
  enrollment_number,
  lesson_count,
  image,
  createdAt,
  createdBy,
}) => {
  const [imgLink, setImgLink] = React.useState(getImageFromServer(image));
  const { t } = useTranslation();
  return (
    <Link
      to={
        type == "course"
          ? `/course-details/${id}`
          : type == "shop-item"
          ? `/shop-item/${id}`
          : `/blogs/${id}`
      }
      className="swiper-slide mb-30 "
      style={{ margin: "0px 1%" }}
    >
      <Box
        className="course box-shadow"
        sx={{ padding: { xs: "0px 10px", md: "0px 10px" } }}
      >
        <div className="thumbnail">
          {/* {+course.price === 0 ? <span className="badge">Free</span> : null} */}
          <div className="thumbnail">
            <div className="image">
              <CardMedia
                onError={(e: any) =>
                  setImgLink("/assets/images/placeholder.png")
                }
                component="img"
                image={imgLink}
                alt="green iguana"
                sx={{
                  borderRadius: type != "blog" ? "10px" : "10px 10px 0 0",
                  maxHeight: {
                    xs: "10rem",
                    sm: "10rem",
                    md: "8.5rem",
                    lg: "12rem",
                  },
                }}
                className="img-fluid"
              />
              {type == "blog" && (
                <Box
                  sx={{
                    bgcolor: "#ECECEC",
                    padding: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                      color: "primary.main",
                      fontWeight: 600,
                    }}
                  >
                    <PiNotePencilBold />
                    {t("By") + " : "}
                    {i18next.language === "ar"
                      ? createdBy?.ar.first_name + " " + createdBy?.ar.last_name
                      : createdBy?.en.first_name +
                        " " +
                        createdBy?.en.last_name}
                  </Typography>
                  <Typography
                    sx={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                      color: "primary.main",
                      fontWeight: 600,
                    }}
                  >
                    <BsCalendarDate />
                    {createdAt ? getBasicDate(createdAt) : ""}
                  </Typography>
                </Box>
              )}
            </div>
          </div>
        </div>
        <div
          className="info"
          style={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          <Typography variant="h5">{name}</Typography>
          <Typography>
            {description.slice(0, 100)}
            {description.length > 100 ? "..." : ""}
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: { xs: "10px", sm: "10px", lg: "10px" },
              flexWrap: "wrap",
              justifyContent:
                type == "shop-item"
                  ? i18next.language == "en"
                    ? "flex-end"
                    : "flex-start"
                  : "space-between",
            }}
          >
            {type == "course" && (
              <>
                <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <Box sx={{ fontSize: "large" }}>
                    <AiOutlineFileText />
                  </Box>
                  <Typography sx={{ fontSize: "1rem", whiteSpace: "nowrap" }}>
                    {lesson_count} {t("CourseDetailsLessons")}
                  </Typography>
                </Box>

                {enrollment_number! > 0 && (
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "5px" }}
                  >
                    <Box sx={{ fontSize: "large" }}>
                      <FaRegCircleUser />
                    </Box>

                    <Typography sx={{ fontSize: "1rem", whiteSpace: "nowrap" }}>
                      {enrollment_number} {t("CourseDetailsStudents")}
                    </Typography>
                  </Box>
                )}
              </>
            )}
            {(type == "course" || type == "shop-item") && (
              <Box sx={{ textAlign: "start" }}>
                <span style={{ margin: 0 }} className="price">
                  {price == 0
                    ? t("GlobalFree")
                    : `${price?.toLocaleString()} ${t("syp")}`}
                </span>
              </Box>
            )}
          </Box>
        </div>
      </Box>
    </Link>
  );
};
