import {
  Box,
  FormControlLabel,
  InputAdornment,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import i18next from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsSearch } from "react-icons/bs";
import { defaultTranslation } from "../../../untilites/functions";
import { testCorrectionResponse } from "../../../interfaces/new-api-interfaces/profile";
import TestToCorrectItem from "./test-to-correct";

interface Props {
  tests: testCorrectionResponse[];
  isRelatedToCourse?: boolean;
  setIsCorrected: any;
  isCorrected: boolean;
}

const TestsResults = ({
  tests,
  isRelatedToCourse = false,
  isCorrected,
  setIsCorrected,
}: Props) => {
  const [search, setSearch] = useState("");
  const { t } = useTranslation();
  const [orderBy, setOrderBy] = useState<any>("created_at");
  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filteredData, setFilteredData] =
    useState<testCorrectionResponse[]>(tests);

  const handleSort = (property: any) => {
    // Update the type of property
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const searchHandler = (name: string) => {
    return name.toLowerCase().includes(search.toLowerCase());
  };

  useEffect(() => {
    if (tests && tests.length > 0) {
      const filteredData = tests.filter((item: testCorrectionResponse) => {
        return searchHandler(
          isRelatedToCourse
            ? defaultTranslation(item.lesson, "name")
            : defaultTranslation(item, "name")
        );
      });
      setFilteredData(filteredData);
    } else {
      setFilteredData([]);
    }
  }, [tests, search]);

  return (
    <>
      <div>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "0.5rem",
            flexWrap: "wrap",
            marginBottom: "1rem",
          }}
        >
          <Box className="search-corrected">
            <TextField
              name="search"
              placeholder={
                i18next.language === "ar" ? "بحث حسب الاسم" : "search by name"
              }
              variant="outlined"
              onChange={(event) => {
                setSearch(event.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <BsSearch className="pl-1" fontSize="large" />
                  </InputAdornment>
                ),
                sx: { borderRadius: "30px", padding: "0.8rem" },
              }}
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={isCorrected}
                  onChange={(e) => setIsCorrected(e.target.checked)}
                />
              }
              label={i18next.language === "ar" ? "مصححة" : "Corrected"}
            />
          </Box>
        </Box>
        {tests && tests.length > 0 ? (
          <div>
            <TableContainer component={Paper}>
              <Table>
                <TableHead
                  sx={{
                    bgcolor: "mypurple2.main",
                  }}
                >
                  <TableRow>
                    <TableCell sx={{ borderTopLeftRadius: "20px" }}>
                      <Typography fontSize="large" color="#fff">
                        {t("name")}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "25%" }}>
                      <Typography fontSize="large" color="#fff">
                        {t("Description")}
                      </Typography>
                    </TableCell>
                    {isRelatedToCourse && (
                      <TableCell sx={{ width: "25%" }}>
                        <Typography fontSize="large" color="#fff">
                          {t("courseName")}
                        </Typography>
                      </TableCell>
                    )}
                    <TableCell sx={{ width: "15%" }}>
                      <TableSortLabel
                        active={orderBy === "student_count"}
                        direction={orderBy === "student_count" ? order : "asc"}
                        onClick={() => handleSort("student_count")}
                      >
                        <Typography fontSize="large" color="#fff">
                          {t("studentsCount")}
                        </Typography>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ borderTopRightRadius: "20px" }}>
                      <TableSortLabel
                        active={orderBy === "created_at"}
                        direction={orderBy === "created_at" ? order : "asc"}
                        onClick={() => handleSort("created_at")}
                      >
                        <Typography fontSize="large" color="#fff">
                          {t("date")}
                        </Typography>
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData
                    ?.sort((a: any, b: any) => {
                      if (orderBy === null) {
                        return 0;
                      }
                      const aValue = a[orderBy];
                      const bValue = b[orderBy];
                      if (
                        typeof aValue === "string" &&
                        typeof bValue === "string"
                      ) {
                        return order === "asc"
                          ? aValue.localeCompare(bValue)
                          : bValue.localeCompare(aValue);
                      }
                      if (
                        typeof aValue === "number" &&
                        typeof bValue === "number"
                      ) {
                        return order === "asc"
                          ? aValue - bValue
                          : bValue - aValue;
                      }
                      return 0;
                    })
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item: testCorrectionResponse, index: number) => {
                      return (
                        <TestToCorrectItem
                          isRelatedToCourse={isRelatedToCourse}
                          test={item}
                          index={index}
                          isCorrected={isCorrected}
                        />
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredData?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(event, newPage) => setPage(newPage)}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
              }}
              dir="ltr"
            />
          </div>
        ) : (
          <div className="row">
            <div className="col-12">
              <div className="learn-press-message success">
                <i className="bx bx-info-circle"></i>
                {i18next.language === "en"
                  ? "There are no test to correct yet"
                  : "لا يوجد اختبارات لتصحيحها حالياً!"}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TestsResults;
