import React from "react";

interface Props {
  mapLink:string
}

export const GoogleMap: React.FC<Props> = (Props) => {
  return (
    <div className="google-map-area section text-center mb-5">
        <div className="container">
            <div className="contact-map-area">
                <iframe className="contact-map" src={Props.mapLink} aria-hidden="false"></iframe>
            </div>
        </div>
    </div>
  );
}