import { useState, useEffect } from "react";

interface DeviceType {
  isMobile: boolean;
  isTablet: boolean;
  isLaptop: boolean;
  isLargeScreen: boolean;
}

function useDeviceType(): DeviceType {
  const [deviceType, setDeviceType] = useState<DeviceType>({
    isMobile: false,
    isTablet: false,
    isLaptop: false,
    isLargeScreen: false,
  });

  useEffect(() => {
    const checkDeviceType = () => {
      const width = window.innerWidth;

      setDeviceType({
        isMobile: width <= 480,
        isTablet: width > 480 && width <= 768,
        isLaptop: width > 768 && width <= 1024,
        isLargeScreen: width > 1024,
      });
    };

    // Run the check on mount
    checkDeviceType();

    // Add event listener on resize
    window.addEventListener("resize", checkDeviceType);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener("resize", checkDeviceType);
    };
  }, []);

  return deviceType;
}

export default useDeviceType;
