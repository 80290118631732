import React, { useState, useRef, useEffect } from "react";
import { BlockPicker, ColorResult } from "react-color";
import { Controller } from "react-hook-form";
import { Button, Popper } from "@mui/material";
import { useTranslation } from "react-i18next";

interface ColorPickerProps {
  name: string;
  control: any;
  setValue: any;
  value?: string;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  name,
  control,
  setValue,
  value,
}) => {
  const [showPicker, setShowPicker] = useState(false);
  const [selectedColor, setSelectedColor] = useState("#3f3a64");
  const anchorRef = useRef<HTMLButtonElement>(null);
  const popperRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (value) {
      setValue(name, value);
      setSelectedColor(value);
    }
  }, [value]);

  const handleColorChange = (color: ColorResult) => {
    setSelectedColor(color.hex);
    setValue(name, color.hex);
  };

  const handleButtonClick = () => {
    setShowPicker(!showPicker);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      popperRef.current &&
      !popperRef.current.contains(event.target as Node)
    ) {
      setShowPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const isColorDark = (color: any) => {
    const hexColor = color.replace("#", "");
    const red = parseInt(hexColor.substr(0, 2), 16);
    const green = parseInt(hexColor.substr(2, 2), 16);
    const blue = parseInt(hexColor.substr(4, 2), 16);
    const brightness = (red * 299 + green * 587 + blue * 114) / 1000;
    return brightness < 128;
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <>
          <Button
            variant="contained"
            onClick={handleButtonClick}
            ref={anchorRef}
            style={{
              backgroundColor: selectedColor,
              color: isColorDark(selectedColor) ? "#FFFFFF" : "#000000",
            }}
          >
            {t("pickColor")}
          </Button>
          {showPicker && (
            <Popper
              open={showPicker}
              sx={{ zIndex: 1000 }}
              anchorEl={anchorRef.current}
              modifiers={[
                {
                  name: "offset",
                  options: {
                    offset: [0, 10],
                  },
                },
              ]}
              ref={popperRef}
            >
              <div>
                <BlockPicker
                  color={field.value || selectedColor}
                  onChange={handleColorChange}
                />
              </div>
            </Popper>
          )}
        </>
      )}
    />
  );
};

export default ColorPicker;
