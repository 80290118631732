import React, { useState } from "react";
import { CourseApiResponse } from "../../../interfaces/api-interfaces";
import { CustomButton } from "../../helpers";
import {
  CircularProgress,
  Dialog,
  Grid,
  Pagination,
  Stack,
} from "@mui/material";
import { PaymentMethod } from "../../Cart/BuyNow/payment-method";
import { fetchCourseCerifications } from "../../../api/endpoints-functions";
import { useQuery } from "@tanstack/react-query";
import CourseCertificateCard from "./course-certificate-card";

interface Props {
  currentSection: string;
  courseId: number;
}

const CourseCertificates: React.FC<Props> = ({ currentSection, courseId }) => {
  // const [open, setOpen] = useState(false);
  // const handleBuy = async () => {
  //   setOpen(true);
  // };

  const { isLoading, data: certifications } = useQuery(
    ["get-certifications", courseId],
    () => fetchCourseCerifications(courseId),
    { keepPreviousData: true, staleTime: 60000 }
  );

  const colors = ["myorange", "myred", "myyellow", "mygreen", "mypurple"];
  return (
    <>
      <div
        id="certifications"
        className={
          " tab-pane fade" +
          `${currentSection === "certifications" ? "show active" : ""}`
        }
      >
        <Grid container spacing={2}>
          {!isLoading &&
            certifications &&
            React.Children.toArray(
              certifications.map((certificate) => (
                <Grid item xs={12} md={6}>
                  <CourseCertificateCard certificate={certificate} />
                </Grid>
              ))
            )}
          {isLoading && (
            <Stack
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress />
            </Stack>
          )}
        </Grid>
      </div>
      <></>
      {/* <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ zIndex: 9999 }}
        transitionDuration={500}
      >
        <PaymentMethod
          id={course?.course.id || 0}
          setOpen={setOpen}
          isTest={true}
          type="test"
        />
      </Dialog>
      {course?.course.price !== 0 && (
        <div className="col-12 d-flex justify-content-center mt-30">
          <CustomButton
            arLabel="شراء اختبارات هذا الدورة"
            enLabel="Buy this course's certificates"
            handlePress={() => handleBuy()}
          />
        </div>
      )} */}
    </>
  );
};

export default CourseCertificates;
