import { Box, CircularProgress } from "@mui/material";
import "../../assets/CSS/loading-page.css";

export const LoadingPage = () => (
  // <div className='spinner-overlay'>
  //   <div className="spinner-container"/>
  // </div>
  <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "80vh", // 100% of the viewport height
      }}
    >
      <CircularProgress />
    </Box>
);
