import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { fetchApi } from "../../api/api";
import "./index.css";
import i18n from "i18next";
import { toast } from "react-toastify";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import bg from "../../assets/images/profile/profile-bg.jpg";
import { defaultTranslation, handleFormError } from "../../untilites/functions";
import { addConsultationRequest } from "../../interfaces/new-api-interfaces/consultations";
import { fetchInstructors } from "../../api/endpoints-functions";
import i18next from "i18next";
import CustomTextField from "../../components/Common/custome-text-field";

interface Props {}

const AddTextConsultation: React.FC<Props> = () => {
  const [isGeneralConsultation, setIsGeneralConsultation] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();

  //fetch instructors
  const {
    data: instructors,
    isLoading: l1,
    isError: e1,
  } = useQuery(["get-instructors"], () => fetchInstructors());

  //mutate consultation
  const { isLoading, mutateAsync } = useMutation(
    (data: any) => fetchApi("/consultation/consultation", "POST", data),
    {
      onError: (error: any) => {
        if (error?.response?.status === 422) {
          handleFormError(error.response, setError, toast, i18next);
        }
      },
    }
  );

  //react-hook-form
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<addConsultationRequest>({
    defaultValues: {},
  });

  //form submit function
  const onSubmit: SubmitHandler<addConsultationRequest> = async (data) => {
    let modefiedData = { ...data };

    if (modefiedData.instructor_id) {
      //@ts-ignore
      modefiedData.instructor_id = modefiedData.instructor_id.id;
    }
    if (state) {
      modefiedData.instructor_id = state;
    }
    try {
      mutateAsync(modefiedData).then((response) => {
        if (response.status === 201) {
          toast.success(
            i18n.language === "en"
              ? "Consultation Added Successfully"
              : "تم إضافة الاستشارة بنجاح"
          );
          navigate("/student-profile");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPositionX: "right",
      }}
      style={{ backgroundPositionX: "right" }}
    >
      <div
        style={{
          direction: `${i18n.language === "en" ? "ltr" : "rtl"}`,
          padding: "5vh 0",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            paddingBottom: { xs: "20px", md: "20px" },
          }}
          color="primary.main"
          style={{ direction: `${i18n.language === "en" ? "ltr" : "rtl"}` }}
        >
          {t("addNewConsultation")}
        </Typography>
        <Box
          className="login-form-wrapper mt-sm-50 mt-xs-50"
          sx={{
            direction: `${i18n.language === "en" ? "ltr" : "rtl"}`,
            width: { xs: "90%", md: "70%" },
            borderRadius: { xs: "10px", md: "50px" },
          }}
        >
          <form
            style={{
              direction: `${i18n.language === "en" ? "ltr" : "rtl"}`,
              position: "relative",
              paddingTop: "50px",
              gap: "2%",
            }}
            className="register-form d-flex flex-wrap justify-content-center"
            onSubmit={handleSubmit(onSubmit)}
          >
            {!isLoading && !l1 && (
              <>
                <Box
                  sx={{
                    width: { xs: "100%", sm: "92%" },
                    marginBottom: "30px",
                  }}
                >
                  {!state && (
                    <Stack direction="row" alignItems="center">
                      <FormControlLabel
                        sx={{
                          marginBottom: !isGeneralConsultation ? "10px" : "0px",
                          marginLeft: "-11px",
                          marginRight: "5px",
                        }}
                        control={
                          <Checkbox
                            checked={isGeneralConsultation}
                            onChange={(e) =>
                              setIsGeneralConsultation(e.target.checked)
                            }
                            color="primary"
                          />
                        }
                        label={
                          i18next.language === "en"
                            ? "General Consultation"
                            : "استشارة عامة"
                        }
                      />
                      <Box>
                        <Typography
                          sx={{
                            marginBottom: !isGeneralConsultation
                              ? "10px"
                              : "0px",
                          }}
                          variant="subtitle1"
                        >
                          {i18next.language == "en"
                            ? "(when selecting general consultation we pick the right trainer to answer you)"
                            : "(عند اختيارك لاستشارة عامة سنقوم باختيار المدرب المناسب لإجابتك)"}
                        </Typography>
                      </Box>
                    </Stack>
                  )}
                  {!isGeneralConsultation &&
                    (!state ? (
                      <Controller
                        name="instructor_id"
                        control={control}
                        rules={{
                          required:
                            i18next.language == "en"
                              ? "Trainer is required!"
                              : "حقل المدرب مطلوب",
                        }}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            disablePortal
                            id="combo-box-demo"
                            options={instructors!}
                            getOptionLabel={(instructor) => {
                              if (instructor)
                                return (
                                  defaultTranslation(instructor, "first_name") +
                                  " " +
                                  defaultTranslation(instructor, "last_name")
                                );
                              return "";
                            }}
                            onChange={(e, value) => field.onChange(value)}
                            //@ts-ignore
                            value={field.value}
                            sx={{ width: "100%" }}
                            disabled={isGeneralConsultation || state} // Disable if it's a general consultation
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={
                                  i18next.language === "en"
                                    ? "Select Trainer*"
                                    : "اختر المدرب*"
                                }
                                error={errors && !!errors.instructor_id}
                                helperText={
                                  errors &&
                                  errors.instructor_id &&
                                  errors?.instructor_id?.message
                                }
                              />
                            )}
                          />
                        )}
                      />
                    ) : (
                      <CustomTextField
                        name="instructor_id"
                        control={control}
                        disabled
                        defaultValue=""
                        value={
                          instructors?.find(
                            (instructor) => instructor.id == state
                          )
                            ? defaultTranslation(
                                instructors.find(
                                  (instructor) => instructor.id == state
                                ),
                                "first_name"
                              ) +
                              " " +
                              defaultTranslation(
                                instructors.find(
                                  (instructor) => instructor.id == state
                                ),
                                "last_name"
                              )
                            : ""
                        }
                        label={
                          i18next.language === "en"
                            ? "Select Trainer"
                            : "اختر المدرب"
                        }
                        placeholder={
                          i18next.language === "en"
                            ? "Select Trainer"
                            : "اختر المدرب"
                        }
                        required
                        inputStyle={{
                          width: { xs: "100%", sm: "100%" },
                        }}
                        errors={errors}
                      />
                    ))}
                </Box>

                <CustomTextField
                  name="question"
                  control={control}
                  multiline
                  defaultValue=""
                  label={t("consultationContent")}
                  placeholder={t("consultationContent")}
                  required
                  inputStyle={{
                    width: { xs: "100%", sm: "92%" },
                    marginBottom: "30px",
                  }}
                  errors={errors}
                />

                <Box
                  className="single-input"
                  sx={{
                    direction: `${i18n.language === "en" ? "ltr" : "rtl"}`,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: { xs: "100%", sm: "92%", lg: "30%" },
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className="mb-2 btn-hover-secondary btn-width-100"
                    type="submit"
                  >
                    <Typography color="#fff">
                      {t("submitConsultation")}
                    </Typography>
                  </Button>
                </Box>
              </>
            )}
            {(isLoading || l1) && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  minHeight: "500px",
                  paddingBottom: "150px",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </form>
        </Box>
      </div>
    </Box>
  );
};

export default AddTextConsultation;
