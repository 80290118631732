import { Navigate, Outlet } from 'react-router-dom'
import { useAtomValue } from 'jotai';
import { tokenAtom } from '../store';


const PrivateRoutes = () => {
  const token = useAtomValue(tokenAtom);
  return (
    token !== ''  ? <Outlet /> : <Navigate to='/login' />
  )
}

export default PrivateRoutes;