import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  CircularProgress,
  Stack,
  Typography,
  IconButton,
  Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getGlobalTestUserCertifications } from "../../api/endpoints-functions";
import CourseGetCertificate from "../../components/Course/course-details-components/course-get-certificate";
import { MdClose } from "react-icons/md";

interface Props {
  open: boolean;
  onClose: () => void;
  testId: string;
}

export const CertificationTestDialog: React.FC<Props> = ({
  open,
  onClose,
  testId,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { isLoading, data: certifications } = useQuery(
    ["get-certifications", testId],
    () => getGlobalTestUserCertifications(testId),
    { keepPreviousData: true }
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      dir={i18next.language == "ar" ? "rtl" : "ltr"}
    >
      <Box sx={{ position: "absolute", top: 5, right: 5, zIndex: "100" }}>
        <IconButton onClick={onClose}>
          <MdClose />
        </IconButton>
      </Box>
      <DialogContent sx={{ width: "100%", padding: "30px" }}>
        <Grid
          container
          sx={{
            width: "100%",
            gap: "20px",
            justifyContent: { xs: "center", md: "flex-start" },
          }}
        >
          {/* Content of the dialog */}
          {!isLoading && certifications && (
            <Grid item xs={12} marginTop={2}>
              <Typography>
                {i18next.language == "ar"
                  ? "تهانينا لقد أكملت هذا الاختبار بنجاح ويمكنك الآن الحصول على شهاداتك :"
                  : "Congratulations, you have successfully completed this Test and can now obtain your certificates:"}
              </Typography>
              <Grid container spacing={2}>
                {certifications.map((certificate) => (
                  <Grid
                    item
                    key={certificate.id}
                    xs={12}
                    md={12 / certifications.length}
                    lg={12 / certifications.length}
                  >
                    <Box p={3}>
                      <CourseGetCertificate certificate={certificate} />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
          {isLoading && (
            <Grid item xs={12} marginTop={10}>
              <Stack
                width="100%"
                height="80%"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress />
              </Stack>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
