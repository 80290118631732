import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import i18next from "i18next";
import { studentVideoConsultation } from "../../../interfaces/new-api-interfaces/consultations";
import VideoConsultationTableItem from "../profile-components/video-consultation-table-item";
import ConsultationStatusSelect from "./consultation-status-select";
import LoadingTable from "../../Common/loading-table";
import StudentVideoConsultationDetails from "./preview-student-consultation-modal";
import PreviewStudentConsultationModal from "./preview-student-consultation-modal";
import EmptyTab from "./empty-tab";
import emptyConsultation from "../../../assets/images/consultation/undraw_informed_decision_p2lh.png";

interface Props {
  Consultations: studentVideoConsultation[];
  selectedStatus: string;
  setSelectedStatus: Function;
  isLoading: boolean;
  isFetching: boolean;
}

const VideoConsultations: React.FC<Props> = ({
  Consultations,
  selectedStatus,
  setSelectedStatus,
  isLoading,
  isFetching,
}) => {
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);
  const [id, setId] = useState(0);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const modalItem = useMemo(() => {
    return Consultations?.find((consultation) => consultation.id == id);
  }, [Consultations, id]);

  return (
    <div className="">
      {id != 0 && (
        <PreviewStudentConsultationModal
          item={modalItem!}
          open={modalOpen}
          setOpen={setModalOpen}
        />
      )}
      <div className="learn-press-subtab-content">
        <div className="tab-content">
          <ConsultationStatusSelect
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
          />
          <div className="col-12 scroll">
            <LoadingTable
              isLoading={isLoading}
              isFetching={isFetching}
              columns={5}
            >
              {Consultations && Consultations.length > 0 ? (
                <TableContainer>
                  <Table className="lp-list-table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ borderTopLeftRadius: "20px" }}>
                          <Typography fontSize="large">
                            {t("CourseDetailsInstructor")}
                          </Typography>
                        </TableCell>
                        {["pending", "scheduled", "paid"].includes(
                          selectedStatus
                        ) && (
                          <TableCell>
                            <Typography fontSize="large">
                              {selectedStatus === "pending"
                                ? t("preferredDate")
                                : t("scheduledDate")}
                            </Typography>
                          </TableCell>
                        )}
                        {["scheduled", "paid", "done"].includes(
                          selectedStatus
                        ) && (
                          <TableCell>
                            <Typography fontSize="large">
                              {t("consultationTime")}
                            </Typography>
                          </TableCell>
                        )}
                        {["scheduled", "paid", "done"].includes(
                          selectedStatus
                        ) && (
                          <TableCell>
                            <Typography fontSize="large">
                              {t("consultationPrice")}
                            </Typography>
                          </TableCell>
                        )}
                        <TableCell>
                          <Typography fontSize="large">
                            {t("consultationReason")}
                          </Typography>
                        </TableCell>
                        {selectedStatus == "rejected by instructor" && (
                          <TableCell>
                            <Typography fontSize="large">
                              {t("rejectionReason")}
                            </Typography>
                          </TableCell>
                        )}
                        <TableCell>
                          <Typography fontSize="large">
                            {t("status")}
                          </Typography>
                        </TableCell>

                        <TableCell
                          sx={{ borderTopRightRadius: "20px" }}
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Consultations.map(
                        (Consultation: studentVideoConsultation) => (
                          <VideoConsultationTableItem
                            key={Consultation.id}
                            linkTo={"/consultation-details/" + Consultation.id}
                            {...Consultation}
                            Consultation={Consultation}
                            setSelectedStatus={setSelectedStatus}
                            handleOpenModal={handleOpenModal}
                            setId={setId}
                          />
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <EmptyTab
                  image={emptyConsultation}
                  text={
                    i18n.language === "en"
                      ? "You don't have any consultations yet"
                      : "لا تملك استشارات بعد!"
                  }
                  buttonText={
                    i18next.language == "en"
                      ? "Start Consulting"
                      : "اطلب استشارة"
                  }
                  link="/consultations"
                />
              )}
            </LoadingTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoConsultations;
