import { Box, Typography } from "@mui/material";
import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  fullMarks: number;
  currentMarks: number;
};

const TotalCurrentMarksStatus = ({ fullMarks, currentMarks }: Props) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography
        fontSize="18px"
        fontWeight={600}
        color={currentMarks == fullMarks ? "success.main" : "primary.main"}
      >
        {`${t("fullMark")} : ${fullMarks}  /   ${t(
          "currentTotal"
        )} : ${currentMarks}`}
      </Typography>
      {currentMarks != fullMarks && (
        <Typography color="error">
          {i18next.language == "en"
            ? "Warning : inputed Questions Marks Total is not equal to the full mark"
            : "تحذير : مجموع علامات الأسئلة المدخلة لا يساوي العلامة العظمى"}
        </Typography>
      )}
    </Box>
  );
};

export default TotalCurrentMarksStatus;
