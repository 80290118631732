import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import i18next from "i18next";
import React from "react";
import { Controller } from "react-hook-form";
import { SiMicrosoftexcel } from "react-icons/si";
import { RiDragDropFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";

type Props = {
  control: any;
};

const ExcelOrByHandRadio = ({ control }: Props) => {
  const { t } = useTranslation();
  return (
    <Controller
      name="is_excel"
      control={control}
      defaultValue={true}
      render={({ field }) => (
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          gap={3}
          sx={{
            width: "90%",
            marginBottom: "30px",
          }}
        >
          <Typography>
            {i18next.language == "en"
              ? "What Input Way Do You Prefer ?"
              : "ماهي طريقة الإدخال التي تفضلها ؟"}
          </Typography>
          <RadioGroup
            row
            {...field}
            onChange={(e) => field.onChange(e.target.value === "true")}
          >
            <FormControlLabel
              value={true}
              control={<Radio sx={{ display: "none" }} />} // Hide the radio circle
              label={
                <Box
                  sx={{
                    padding: "10px 50px",
                    width: "100%",
                    borderRadius: "10px",
                    border: "1px solid #bbb",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    borderColor: field.value === true ? "primary.main" : "#bbb", // Primary color for selected "Yes"
                  }}
                >
                  <SiMicrosoftexcel
                    style={{
                      color: field.value === true ? "#3f3a64" : "#bbb",
                    }}
                  />

                  <Typography
                    sx={{ color: field.value === true ? "#3f3a64" : "#bbb" }}
                  >
                    {t("byExcel")}
                  </Typography>
                </Box>
              }
            />

            <FormControlLabel
              value={false}
              control={<Radio sx={{ display: "none" }} />} // Hide the radio circle
              label={
                <Box
                  sx={{
                    padding: "10px 50px",
                    width: "100%",
                    borderRadius: "10px",
                    border: "1px solid #bbb",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    borderColor:
                      field.value === false ? "primary.main" : "#bbb", // Primary color for selected "No"
                  }}
                >
                  <RiDragDropFill
                    style={{
                      color: field.value === false ? "#3f3a64" : "#bbb",
                    }}
                  />

                  <Typography
                    sx={{ color: field.value === false ? "#3f3a64" : "#bbb" }}
                  >
                    {t("byHand")}
                  </Typography>
                </Box>
              }
            />
          </RadioGroup>
        </Stack>
      )}
    />
  );
};

export default ExcelOrByHandRadio;
