import React from "react";
import { useTranslation } from "react-i18next";
import { IStudentResult } from "../../../interfaces/api-interfaces";
import ResultTableItem from "../profile-components/result-table-item";
import i18n from "i18next";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { StudentTest } from "../../../interfaces/new-api-interfaces/profile";
import i18next from "i18next";
import LoadingTable from "../../Common/loading-table";
import EmptyTab from "./empty-tab";
import emptyTests from "../../../assets/images/tests/undraw_Questions_re_1fy7.png";

interface Props {
  results: StudentTest[];
  isChecked: boolean;
  setIsChecked: any;
  isLoading: boolean;
  isFetching: boolean;
  activeTab: string | null;
}

const Results: React.FC<Props> = ({
  results,
  isChecked,
  setIsChecked,
  isLoading,
  isFetching,
  activeTab,
}) => {
  const { t } = useTranslation();
  return (
    <div
      id="results"
      className={`profile-content-quizzes tab-pane fade pt-5 show ${
        activeTab === "results" ? "active" : ""
      }`}
    >
      <div className="learn-press-subtab-content">
        <div className="tab-content">
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "1rem",
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={isChecked}
                  onChange={(e) => setIsChecked(e.target.checked)}
                />
              }
              label={i18next.language === "ar" ? "مصححة" : "Checked"}
            />
          </Box>
          <div className="col-12 scroll">
            <LoadingTable
              isLoading={isLoading}
              isFetching={isFetching}
              columns={4}
            >
              {results && results.length > 0 ? (
                <TableContainer>
                  <Table className="lp-list-table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ borderTopLeftRadius: "20px" }}>
                          <Typography fontSize="large">{t("name")}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize="large">
                            {t("Description")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize="large">
                            {t("GlobalCreatedAt")}
                          </Typography>
                        </TableCell>
                        {isChecked && (
                          <TableCell>
                            <Typography fontSize="large">
                              {t("mark")}
                            </Typography>
                          </TableCell>
                        )}
                        <TableCell sx={{ borderTopRightRadius: "20px" }}>
                          <Typography fontSize="large">
                            {t("status")}
                          </Typography>
                        </TableCell>
                        {/* <TableCell className="column-delete" style={{ borderRadius: "0px 20px 0 0" }}>
                        {t("GlobalDelete")}
                      </TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {results.map((result: StudentTest) => (
                        <ResultTableItem
                          key={result.id}
                          linkTo={"/corrected-test/" + result.id}
                          {...result}
                          result={result}
                          isChecked={isChecked}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <EmptyTab
                  image={emptyTests}
                  text={
                    i18n.language === "en"
                      ? "You don't have any tests yet"
                      : "لا تملك اختبارات بعد!"
                  }
                  buttonText={
                    i18next.language == "en" ? "start Learning" : "ابدأ بالتعلم"
                  }
                  link="/quizzes-grid"
                />
              )}
            </LoadingTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Results;
