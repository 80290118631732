import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { defaultTranslation } from "../../untilites/functions";
import { CustomTextarea } from "../helpers";
import {
  studentCorrectedTest,
  studentCorrectedTestQuestion,
} from "../../interfaces/new-api-interfaces/test";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

interface Props {
  isTrue: boolean;
  value: string;
  question: studentCorrectedTestQuestion;
  isGraded: boolean | 0 | 1;
}

const DisplayWrittenAnswers: React.FC<Props> = ({
  value,
  isTrue,
  question,
  isGraded,
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ padding: "20px", bgcolor: "#FBFBFB" }}>
      <Stack width={"90%"} direction={"row"} justifyContent={"space-between"}>
        <Typography variant="h6">
          {defaultTranslation(question, "name")}
        </Typography>
        {isGraded == 1 && (
          <Typography variant="h6" color="mypurple2.main">
            {question.mark} {t("Mark")}
          </Typography>
        )}
      </Stack>
      <CustomTextarea
        arLabel=""
        enLabel=""
        isReadOnly
        prevValue={value}
        handleChange={() => {}}
      />
      {isGraded == 1 && (
        <div className="d-flex align-items-center">
          <div className="mr-2 mt-3">
            {!question.written_answers[0].is_checked && (
              <Box>
                <Typography color="orange">
                  {i18next.language == "ar"
                    ? "بانتظار تصحيح المدرب"
                    : "Pending For Correction"}
                </Typography>
              </Box>
            )}
            {/* {isTrue && question.written_answers[0].is_checked ? (
              <Box>
                <Typography color="green">
                  {i18next.language == "en"
                    ? "The Answer is Correct"
                    : "الإجابة صحيحة"}
                </Typography>
              </Box>
            ) : null}
            {isTrue == false && question.written_answers[0].is_checked ? (
              <Box>
                <Typography color="red">
                  {" "}
                  {i18next.language == "en"
                    ? "The Answer is Wrong"
                    : "الإجابة خاظئة"}
                </Typography>
              </Box>
            ) : null} */}
            {isTrue && question.written_answers[0].answer_mark ? (
              <Box>
                <Typography
                  color="primary"
                  fontWeight={600}
                  variant="h5"
                >
                  {i18next.language === "ar"
                    ? "العلامة المستحقة: "
                    : "Your Mark : "}
                  {question.written_answers[0].answer_mark}
                </Typography>
              </Box>
            ) : null}
          </div>
        </div>
      )}
    </Box>
  );
};

export default DisplayWrittenAnswers;
