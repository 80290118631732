import { Box, Button, Chip, Skeleton, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { CourseInstructor } from "../course-details-components/course-instructor";
import { Instructor } from "../../../interfaces/api-interfaces";
import {
  FaArrowLeftLong,
  FaArrowRightLong,
  FaRegCircleUser,
} from "react-icons/fa6";
import { AiOutlineFileText } from "react-icons/ai";
import { BsCalendarDate } from "react-icons/bs";
import CourseButtons from "./course-buttons";
import { useAtomValue } from "jotai";
import { instructorIdAtom, roleAtom, userAtom } from "../../../store";
import bannerBackground from "../../../assets/images/trainer-details/baner-background.jpg";
import { useNavigate } from "react-router-dom";
import { courseInstructor } from "../../../interfaces/new-api-interfaces/course";
import i18next from "i18next";
import { getImageFromServer } from "../../../assets/JS/helpers";

interface Props {
  overviewImageUrl: string;
  title: string;
  instructor: courseInstructor;
  lessons: number;
  students: number;
  isLoading: boolean;
  price: number;
  description: string;
  createdAt: string;
  id: number;
  inCart: boolean;
  inWishlist: boolean;
  enrolled: boolean;
  refetch: Function;
  instructorId: number;
  guide_file: string;
  isApproved: boolean;
}

const PageBanner: React.FC<Props> = ({
  overviewImageUrl,
  lessons,
  instructor,
  students,
  title,
  isLoading,
  price,
  description,
  createdAt,
  id,
  inCart,
  inWishlist,
  enrolled,
  refetch,
  instructorId,
  guide_file,
  isApproved,
}) => {
  const { t } = useTranslation();
  const role = useAtomValue(roleAtom);
  const user = useAtomValue(instructorIdAtom);
  const navigate = useNavigate();

  return (
    <Box
      className=""
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // backgroundImage: { xs: "none", md: `url(${bannerBackground})` },
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        paddingTop: { xs: "50px", md: "50px" },
        marginBottom: { xs: 0, md: "70px" },
      }}
    >
      <Box
        className="container row"
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "50px",
            paddingBottom: "30px",
          }}
          className="col-12 col-lg-8"
        >
          <Box
            className="row"
            sx={{
              justifyContent: {
                xs: "center",
                sm: "space-between",
                gap: { xs: "30px", sm: 0 },
              },
              alignItems: "center",
            }}
          >
            <div className="col-12 col-md-5">
              <Box>
                {!isLoading ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "20px",
                    }}
                  >
                    <Stack
                      flexDirection="row"
                      justifyContent={"space-between"}
                      flexWrap={"nowrap"}
                      width={"100%"}
                    >
                      <Typography
                        variant="h4"
                        component="h1"
                        color="primary.main"
                        width="100%"
                      >
                        {title}
                      </Typography>
                      <Chip label={price == 0 ? t("GlobalFree") : `${price?.toLocaleString()} ${t("syp")}`} color="primary" variant="filled" size="medium" />
                    </Stack>
                    <Typography width="100%" textAlign="justify">
                      {description}
                    </Typography>
                    <Box>
                      <a
                        href={getImageFromServer(guide_file)}
                        download={true}
                        target={"_blank"}
                        rel="noreferrer"
                        className="home-action"
                      >
                        <Typography
                          sx={{
                            fontSize: "large",
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            whiteSpace: "nowrap",
                            width: "100%",
                            justifyContent: "center",
                          }}
                        >
                          {t("LandingDownload")}
                          {i18next.language === "en" ? (
                            <FaArrowRightLong />
                          ) : (
                            <FaArrowLeftLong />
                          )}
                        </Typography>
                      </a>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "20px",
                        width: "100%",
                        flexWrap: { xs: "wrap", lg: "nowrap" },
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <AiOutlineFileText color="#3f3a64" />
                        <Typography
                          sx={{ whiteSpace: "nowrap", color: "primary.main" }}
                        >
                          {lessons} {t("CourseDetailsLessons")}
                        </Typography>
                      </Box>
                     {students > 0 && <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <FaRegCircleUser color="#3f3a64" />
                        <Typography
                          style={{
                            whiteSpace: "nowrap",
                            color: "primary.main",
                          }}
                        >
                          {students} {t("CourseDetailsStudents")}
                        </Typography>
                      </Box>}
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <BsCalendarDate color="#3f3a64" />
                        <Typography
                          style={{
                            whiteSpace: "nowrap",
                            color: "primary.main",
                          }}
                        >
                          {createdAt?.slice(0, 10)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Skeleton
                      width={370}
                      height={50}
                      style={{ margin: "0 auto" }}
                    />
                    <Skeleton
                      width={370}
                      height={50}
                      style={{ margin: "0 auto" }}
                    />
                    <Skeleton
                      width={370}
                      height={50}
                      style={{ margin: "0 auto" }}
                    />
                  </>
                )}
              </Box>
            </div>
            <Box
              className="col-12 col-md-6"
              sx={{
                order: { xs: 3, md: 2 },
                paddingTop: { xs: "20px", sm: 0 },
              }}
            >
              <CourseInstructor instructor={instructor} isLoading={isLoading} />
            </Box>
          </Box>
          <Box>
            {role != "instructor" && (
              <CourseButtons
                id={id}
                refetch={refetch}
                inCart={inCart}
                inWishlist={inWishlist}
                enrolled={enrolled}
                isFree={price === 0}
              />
            )}

            {role == "instructor" && user == instructorId && (
              <>
                {!isApproved && (
                  <Box>
                    <div
                      className="learn-press-message success"
                      style={{
                        width: "fit-content",
                        paddingLeft: 0,
                        padding: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <i className="bx bx-info-circle"></i>{" "}
                        {t("courseUnderReview")}
                      </Typography>
                    </div>
                  </Box>
                )}
                <Box>
                  <button
                    className="home-action"
                    onClick={() => navigate(`/edit-course/${id}`)}
                  >
                    <Typography
                      sx={{
                        fontSize: "large",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        whiteSpace: "nowrap",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      {t("ProfileEditCourse")}
                    </Typography>
                  </button>
                </Box>
              </>
            )}
          </Box>
        </Box>

        <Box
          className="col-12 col-md-4"
          sx={{
            order: { xs: 2, md: 3 },
            paddingTop: { xs: "20px", sm: 0 },
            display: { xs: "none", md: "block" },
            alignSelf: "stretch",
          }}
        >
          {!isLoading ? (
            <img
              src={overviewImageUrl}
              style={{
                width: "100%",
                borderRadius: "5px",
                height: "100%",
              }}
            />
          ) : (
            <Skeleton width={370} height={400} style={{ margin: "0 auto" }} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PageBanner;
