import React from "react";
import ErrorSvg from "../../assets/images/error/error.svg";
import { Box, Button, Typography } from "@mui/material";

const NotFoundPage: React.FC<any> = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "20px",
        padding: "50px",
      }}
      className="container"
    >
      <Box style={{ textAlign: "center" }}>
        <Typography color="primary" variant="h4">404 Page Not Found</Typography>
      </Box>
      {/*<pre>{error.message}</pre>*/}
      <Box>
        <img src={ErrorSvg} width="400px" height="400px" />
      </Box>
    </Box>
  );
};

export default NotFoundPage;
