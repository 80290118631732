import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { fetchApi } from "../../../api/api";
import { CustomButton } from "../../helpers";
import i18n from "i18next";
import {ThankYouModal} from "./thank-you";
import { useState } from "react";

interface Props {}

export const GlobalPayment: React.FC<Props> = () => {
  const { data } = useQuery(["get-cart"], () => fetchApi("/cart", "GET", {}));
  const [thankYouModal, setThankYouModal] = useState(false);
  const { mutate } = useMutation(
    (data: any) => fetchApi("/orders", "POST", data),
    {
      onSuccess: () => {
        toast.success(
          i18n.language === "en" ? "oredered successfully" : "تم الطلب بنجاح"
        );
        setThankYouModal(true);
      },
      onError: () =>
        toast.error(
          i18n.language === "en"
            ? "something went wrong"
            : "حدث خطأ أثناء الطلب"
        ),
    }
  );
  const handleSubmit = () => {
    mutate({
      // cartItems: data?.data.map((item: any) => item.id),
      // paymentMethod: {
      //   globalPayment: {
      //     isPaypal: true,
      //   },
      // },
      // isApproved: true,
      paymentApproved: true,
      paymentMethod: {
        globalPayment: {
          isPaypal: true,
        },
      },
    });
  };
  return (
    <section>
      <ThankYouModal isOpen={thankYouModal} setIsOpen={setThankYouModal} />
      <p className="m-0 fs-4">Pay With :</p>
      <div onClick={() => handleSubmit()}>
        <a
          href="https://www.paypal.com/"
          target="_blank"
          rel="noreferrer"
          className="d-flex align-items-center justify-content-center"
        >
          <img
            src={require("../../../assets/images/pay-logo/paypal-logo.png")}
            width="100"
            height="45"
            className="mx-3 p-2 border border-dark"
            style={{ borderRadius: "10px" }}
            alt="paypal"
          />
        </a>
      </div>
      <p
        className="mx-auto mt-5 py-2 border-top border-bottom border-dark text-center"
        style={{ width: "100px" }}
      >
        OR
      </p>
      <div className="mt-5 d-flex align-items-center justify-content-center">
        <img
          src={require("../../../assets/images/pay-logo/visa-logo.png")}
          alt="paymentLogo"
          width="100"
          height="50"
        />
        <img
          src={require("../../../assets/images/pay-logo/master-card-logo.jpg")}
          alt="paymentLogo"
          width="100"
          height="50"
        />
      </div>
      <div className="">
        <div className="col-12 mb-20">
          <label>Company Name</label>
          <input type="text" placeholder="Company Name" />
        </div>
        <div className="col-12 mb-20">
          <label>Address*</label>
          <input type="text" placeholder="Address line 1" />
          <input type="text" placeholder="Address line 2" />
        </div>
        <div className="col-12 mb-20">
          <label>Zip Code*</label>
          <input type="text" placeholder="Zip Code" />
        </div>
        <div className="col-md-6 col-6 mb-20">
          <label>Country*</label>
          <select>
            <option>Bangladesh</option>
            <option>China</option>
            <option>country</option>
            <option>India</option>
            <option>Japan</option>
          </select>
        </div>
      </div>
      <div className="col-12 d-flex justify-content-center">
        <CustomButton
          arLabel="اطلب الآن"
          enLabel="Order Now"
          handlePress={() => handleSubmit()}
        />
      </div>
    </section>
  );
};
