import React from "react";

// @ts-ignore
import ReactStars from "react-rating-stars-component";
import { getBasicDate, getImageFromServer } from "../../../assets/JS/helpers";
import { getLink } from "../../../untilites/functions";
import DisplayFile from "../course-grid-components/display-file";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { fetchApi } from "../../../api/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";

interface Props {
  id: number;
  userId: number;
  name: string;
  imgLink: string;
  date: string;
  textContent: string;
  rating: number | undefined;
  refetch: Function;
  file: string;
  fileType: string;
}

export const CommentReview: React.FC<Props> = (Props) => {
  const userId = localStorage.getItem("userId");

  const {
    isLoading: l3,
    isError: e3,
    mutateAsync: deleteReview,
  } = useMutation((id: number) => {
    return fetchApi("/review/review/" + id, "DELETE");
  });

  const deleteReviewHandler = async (id: number) => {
    await deleteReview(id);
    Props.refetch();
  };

  return (
    <li className="review">
      <Box
        className="review-container"
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "20px",
        }}
      >
        <Avatar
          src={Props.imgLink}
          alt={Props.name}
          sx={{ width: 56, height: 56 }}
        />
        <Box
          className="review-content"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            width: "80%",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Stack flexDirection={"column"} gap={1} alignItems={"flex-start"}>
              <Typography variant="h6" color="primary.main">
                {Props.name}
              </Typography>
              <Typography>{getBasicDate(Props.date)}</Typography>
            </Stack>
            <div title={`${Props.rating} out of 5 stars`}>
              <div className="stars-empty">
                <ReactStars
                  count={5}
                  size={24}
                  isHalf={true}
                  edit={false}
                  value={Props.rating}
                  emptyIcon={<i className="far fa-star"></i>}
                  halfIcon={<i className="fa fa-star-half-alt"></i>}
                  fullIcon={<i className="fa fa-star"></i>}
                  activeColor="#ffd700"
                />
              </div>
            </div>
          </Box>
          <div
            className="review-text"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography>{Props.textContent}</Typography>
            {userId === Props.userId + "" && (
              <IconButton
                className="block"
                onClick={() => deleteReviewHandler(Props.id)}
                disabled={l3}
              >
                {l3 ? (
                  <CircularProgress size={24} /> // Show loading indicator
                ) : (
                  <DeleteOutlinedIcon className="hover:text-red-700 cursor-pointer" />
                )}
              </IconButton>
            )}
          </div>

          {(Props.fileType === "video" || Props.fileType === "audio") && (
            <DisplayFile
              type={Props.fileType}
              file={getImageFromServer(Props.file)}
            />
          )}
          {Props.fileType === "image" && (
            <img
              src={getImageFromServer(Props.file)}
              style={{ objectFit: "contain", maxHeight: "250px" }}
            />
          )}
          {Props.fileType === "asset" && (
            <a
              href={getImageFromServer(Props.file)}
              style={{ textDecoration: "underline", color: "#3f3a64" }}
            >
              Asset File
            </a>
          )}
        </Box>
      </Box>
    </li>
  );
};
