import { useMutation } from "@tanstack/react-query";
import { useSetAtom } from "jotai";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { fetchApi } from "../../api/api";
import "../Auth/auth.style.css";
import { tokenAtom } from "../../store";
import i18n from "i18next";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { resetPassword } from "../../interfaces/new-api-interfaces/auth";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import bg from "../../assets/images/profile/profile-bg.jpg";
import { handleFormError } from "../../untilites/functions";
import i18next from "i18next";

interface Props {}

const inputStyle = {
  width: { xs: "100%", sm: "90%" },
  marginBottom: "30px",
};

const ResetPassword: React.FC<Props> = () => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setError,
  } = useForm<resetPassword>({
    defaultValues: {},
  });

  const { isLoading, mutateAsync, isError } = useMutation(
    (data: any) => fetchApi("/auth/user/updateMyPassword", "PUT", data),
    {
      onError: (error: any) => {
        if (error?.response?.status === 422) {
          handleFormError(error.response, setError, toast, i18next);
        }
      },
    }
  );

  const { t } = useTranslation();
  const navigate = useNavigate();
  const setToken = useSetAtom(tokenAtom);

  const onSubmit: SubmitHandler<resetPassword> = async (data) => {
    try {
      mutateAsync(data).then((response) => {
        if (response.status === 200) {
          //const { data } = response.data;
          setToken("");
          navigate("/login", { replace: true });
          toast.success(
            i18n.language === "en"
              ? "Password edited successfully"
              : "تم تعديل كلمة المرور بنجاح"
          );
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  return (
    <Box
      sx={{
        backgroundImage: `url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPositionX: "right",
      }}
    >
      <div
        style={{
          direction: `${i18n.language === "en" ? "ltr" : "rtl"}`,
          padding: "10vh 0",
        }}
      >
        <Typography
          variant="h3"
          sx={{ textAlign: "center", paddingBottom: { xs: "0", md: "30px" } }}
          color="primary.main"
          style={{ direction: `${i18n.language === "en" ? "ltr" : "rtl"}` }}
        >
          {t("ResetPassword")}
        </Typography>
        <Box
          className="login-form-wrapper mt-sm-50 mt-xs-50"
          sx={{
            direction: `${i18n.language === "en" ? "ltr" : "rtl"}`,
            width: { xs: "90%", sm: "70%", md: "40%" },
          }}
        >
          <form
            style={{
              direction: `${i18n.language === "en" ? "ltr" : "rtl"}`,
              position: "relative",
              paddingTop: "50px",
              gap: "2%",
              flexDirection: "column",
              alignItems: "center",
            }}
            className="register-form d-flex justify-content-center"
            onSubmit={handleSubmit(onSubmit)}
          >
            {!isLoading && (
              <>
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Old Password is required",
                    minLength: {
                      value: 8,
                      message:
                        i18n.language === "ar"
                          ? "يجب ان تكون كلمة المرور 8 محارف على الأقل"
                          : "Password must be at least 8 characters long",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("GlobalOldPassword")}
                      type={showOldPassword ? "text" : "password"}
                      required
                      sx={inputStyle}
                      error={!!errors.password}
                      helperText={errors.password?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setShowOldPassword((prev) => !prev)
                              }
                            >
                              {showOldPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />

                <Controller
                  name="new_password"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Confirm Password is required",
                    minLength: {
                      value: 8,
                      message:
                        i18n.language === "ar"
                          ? "يجب ان تكون كلمة المرور 8 محارف على الأقل"
                          : "Password must be at least 8 characters long",
                    },
                    validate: (value) =>
                      value == confirmPassword ||
                      "Password and Confirmation do not match",
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("GlobalPassword")}
                      type={showPassword ? "text" : "password"}
                      required
                      sx={inputStyle}
                      error={
                        errors.new_password?.type == "minLength" ? true : false
                      }
                      helperText={
                        errors.new_password?.type == "minLength"
                          ? errors.new_password?.message
                          : null
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword((prev) => !prev)}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                <TextField
                  label={t("GlobalConfirmPassword")}
                  type={showConfirmPassword ? "text" : "password"}
                  id="passwordOne"
                  name="confirm_password"
                  placeholder={t("GlobalConfirmPassword")}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  sx={inputStyle}
                  error={
                    confirmPassword !== watch("new_password") &&
                    confirmPassword != ""
                  }
                  helperText={
                    confirmPassword !== watch("new_password") &&
                    confirmPassword != ""
                      ? i18n.language === "en"
                        ? "Password and Confirmation do not match"
                        : "كلمة المرور والتأكيد غير متطابقان"
                      : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setShowConfirmPassword((prev) => !prev)
                          }
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {errors.root && (
                  <Typography
                    color="error"
                    sx={{
                      textAlign: "start",
                      width: { xs: "100%", sm: "90%" },
                      paddingBottom: "10px",
                    }}
                  >
                    {errors.root.message}
                  </Typography>
                )}

                <Box
                  className="single-input"
                  sx={{
                    direction: `${i18n.language === "en" ? "ltr" : "rtl"}`,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: { xs: "100%", sm: "90%", lg: "90%" },
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className="mb-2 btn-hover-secondary btn-width-100"
                    type="submit"
                  >
                    <Typography fontSize="large" color="#fff">
                      {t("ResetPassword")}
                    </Typography>
                  </Button>
                </Box>
              </>
            )}
            {isLoading && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  minHeight: "250px",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </form>
        </Box>
      </div>
    </Box>
  );
};

export default ResetPassword;
