import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, CircularProgress } from "@mui/material";
import FilepondUploader from "../../../components/filepond-uploader";
import { useTranslation } from "react-i18next";

interface Props {
  open: boolean;
  onClose: () => void;
  control: any;
  type: "audio" | "video" | "image" | "asset" | "";
  sendFunction: Function;
  isLoading: boolean;
  handleSubmit: Function;
}

export default function AddVrcFileModal({
  open,
  onClose,
  control,
  type,
  sendFunction,
  isLoading,
  handleSubmit,
}: Props) {
  const handleClose = () => {
    onClose();
  };

  const { t } = useTranslation();

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={open}
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit(sendFunction)}>
          <DialogTitle>{t("AddFiles")}</DialogTitle>
          <DialogContent>
            <Box
              sx={{
                width: "97%",
                marginBottom: "30px",
                marginLeft: { sm: "16px" },
              }}
            >
              <FilepondUploader
                control={control}
                name="file"
                type={type}
                required={true}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              disabled={isLoading} // Disable the button when loading
              startIcon={isLoading && <CircularProgress size={20} />}
            >
              {t("send")}
            </Button>
            <Button onClick={handleClose}>{t("close")}</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
