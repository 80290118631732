import React from "react";
import { CertificateResponse } from "../../../interfaces/new-api-interfaces/certificate";
import { getImageFromServer } from "../../../assets/JS/helpers";
import {
  Avatar,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { defaultTranslation } from "../../../untilites/functions";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { TbWorld } from "react-icons/tb";
import { FaEye } from "react-icons/fa6";

interface CertificateCardProps {
  certificate: CertificateResponse;
}

const typographyStyle = {
  whiteSpace: "nowrap",
  fontSize: "0.8rem",
  color: "#fff",
};

const btnStyle = {
  borderRadius: "50px",
  padding: "10px 15px",
  gap: "5px",
  width: { xs: "45%", sm: "auto" },
};

const iconStyle = { color: "#fff", fontSize: "1rem" };

const CourseCertificateCard: React.FC<CertificateCardProps> = ({
  certificate,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        dir={i18next.dir()}
      >
        <DialogTitle>
          <Typography variant="h5" sx={{ textTransform: "uppercase" }}>
            {defaultTranslation(certificate, "donor")}
          </Typography>
          <Typography>
            {defaultTranslation(certificate, "description")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <img
            src={getImageFromServer(certificate.image)}
            alt="Certificate Image"
            width={"400px"}
            height={"500px"}
            style={{ borderRadius: "5px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            sx={btnStyle}
            LinkComponent={"a"}
            href={certificate.donor_link}
            target="_blank"
          >
            <TbWorld style={iconStyle} />
            <Typography sx={typographyStyle}>{t("visit_website")}</Typography>
          </Button>
          <Button onClick={() => setOpen(false)}>{t("close")}</Button>
        </DialogActions>
      </Dialog>
      <Card sx={{ padding: "30px", cursor: "pointer" }}>
        <Stack flexDirection="row" gap="20px" width={"100%"}>
          <Avatar
            src={getImageFromServer(certificate.logo)}
            alt="Certificate Logo"
            sx={{ width: 60, height: 60 }}
          />
          <Stack flexDirection="column" gap={2} width={"100%"}>
            <Stack
              flexDirection="row"
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              
              <Typography variant="h5" sx={{ textTransform: "uppercase" }}>
                {defaultTranslation(certificate, "donor")}
              </Typography>
              <Tooltip title={t("view_certificate")} arrow color="primary" >
                <IconButton onClick={() => setOpen(true)}>
                  <FaEye />
                </IconButton>
              </Tooltip>
            </Stack>
            <Typography>
              {defaultTranslation(certificate, "description")}
            </Typography>
            {certificate.price > 0 ? (
              <Stack flexDirection="row" gap={3}>
                <Typography sx={{ fontWeight: 600, whiteSpace: "nowrap" }}>
                  {i18next.language == "en"
                    ? "certificate fees"
                    : "رسوم الشهادة"}
                </Typography>
                <Typography
                  sx={{ fontWeight: 600, whiteSpace: "nowrap" }}
                >{`${certificate.price?.toLocaleString()} ${t(
                  "syp"
                )}`}</Typography>
              </Stack>
            ) : (
              <Typography sx={{ fontWeight: 600 }}>
                {i18next.language == "en"
                  ? "without additional fees"
                  : "بدون رسوم إضافية"}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Card>
    </>
  );
};

export default CourseCertificateCard;
