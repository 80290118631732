import { Box, Skeleton, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useTranslation } from "react-i18next";
import { fetchApi } from "../../../api/api";
import videoCircles from "../../../assets/images/video/video-circles.svg";
import i18n from "i18next";
import { fetchIntroVideo } from "../../../api/endpoints-functions";
import {
  BigPlayButton,
  ControlBar,
  ForwardControl,
  LoadingSpinner,
  PlayToggle,
  Player,
  ReplayControl,
} from "video-react";
import { getImageFromServer } from "../../../assets/JS/helpers";
import { defaultTranslation } from "../../../untilites/functions";
import i18next from "i18next";

interface Props {}

const VideoSection: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { isLoading, data, isError } = useQuery(["intro-video"], () =>
    fetchIntroVideo()
  );
  return (
    <Box className="section" sx={{ background: "#F9F9F9" }}>
      <Box
        className="container"
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "space-between" },
          alignItems: "center",
          width: "100%",
          flexWrap: "wrap",
          padding: "100px 0",
          gap: { xs: "50px", sm: 0 },
        }}
      >
        {!isLoading ? (
          <Box
            data-aos="fade-up"
            sx={{
              maxWidth: { xs: "100%", md: "30%" },
              padding: { sm: "70px 0px", lg: "0px" },
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", md: "flex-start" },
              gap: "30px",
              textAlign: {
                xs: "center",
                md: i18n.language == "en" ? "left" : "right",
              },
            }}
          >
            <Box>
              <Typography
                sx={{
                  whiteSpace: "wrap",
                  textTransform: "uppercase",
                  textAlign: {
                    xs: "center",
                    md: "start",
                  },
                }}
                variant="h1"
              >
                {defaultTranslation(data![0], "title")}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="h4"
                component={"div"}
                dir="rtl"
                color={"primary.main"}
                sx={{
                  textAlign: {
                    xs: "center",
                    md: "start",
                  },
                }}
              >
                {defaultTranslation(data![0], "description")}
              </Typography>
            </Box>
            {/* <a href="" download={true} target={"_blank"} rel="noreferrer">
              <Typography className="home-action" variant="h6">
                {i18n.language === "en"
                  ? "Watch in Youtube"
                  : "شاهد على يوتيوب"}
              </Typography>
            </a> */}
          </Box>
        ) : (
          <>
            <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </>
        )}
        <Box
          data-aos="fade-up"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: { xs: "100%", md: "60%" },
            backgroundImage: { xs: "none", md: `url(${videoCircles})` },
            backgroundRepeat: "no-repeat",
            backgroundSize: "30%",
            backgroundPositionX: i18n.language === "en" ? "left" : "right",
          }}
        >
          {!isLoading ? (
            <Box
              className="intro-div-frame"
              sx={{ justifyContent: { xs: "center", lg: "flex-end" } }}
            >
              <Player
                src={getImageFromServer(data![0].file_url)}
                aspectRatio="16:9"
              >
                <BigPlayButton position="center" />
                <LoadingSpinner />
                <ControlBar autoHide={false}>
                  <PlayToggle />
                  <ReplayControl seconds={10} />
                  <ForwardControl seconds={10} />
                </ControlBar>
              </Player>
            </Box>
          ) : (
            <Skeleton variant="rectangular" width={700} height={350} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default VideoSection;
