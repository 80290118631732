import { SetStateAction } from "jotai";
import React, { Dispatch, useEffect, useState } from "react";
import {
  acceptConsultationRequest,
  instructorLiveConsultation,
} from "../../../interfaces/new-api-interfaces/consultations";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import i18next from "i18next";
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  acceptLiveConsutation,
} from "../../../api/endpoints-functions";
import { toast } from "react-toastify";

const currentDate = new Date().toISOString().split("T")[0];
const maxDate = new Date();
maxDate.setMonth(maxDate.getMonth() + 6);
const maxDateString = maxDate.toISOString().split("T")[0];

const AcceptConsultationModal = ({
  open,
  setOpen,
  id,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  id: number | undefined;
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  const queryClient = useQueryClient();

  const accept = useMutation(
    ({ id, data }: any) => acceptLiveConsutation(id, data),
    {
      onSuccess: () => {
        setOpen(false);
        queryClient.invalidateQueries(["live-consultations"]);
      },
      onError: (error: any) => {
        Object.keys(error.response.data.errors).forEach((errorKey) => {
          toast.error(`${errorKey} field is required!`);
        });
      },
    }
  );

  const onSubmit: SubmitHandler<FieldValues> = (values) => {
    accept.mutateAsync({ id, data: values });
  };

  useEffect(() => {
    reset();
  }, [open]);

  return (
    <Dialog
      dir={i18next.language === "ar" ? "rtl" : "ltr"}
      fullWidth
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle align="center" fontSize="1.3rem">
        {i18next.language === "ar"
          ? `قبول الاستشارة - #${id}`
          : `Accept Consultation - #${id}`}
      </DialogTitle>
      <DialogContent sx={{ padding: "1rem" }}>
        <form style={{ margin: "1rem 0" }} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {" "}
              <Controller
                name="price"
                control={control}
                rules={{
                  required:
                    i18next.language === "ar"
                      ? "يرجى ادخال السعر!"
                      : "Price is required!",
                }}
                render={({ field }: any) => (
                  <TextField
                    error={!!errors.price?.message}
                    helperText={errors.price?.message}
                    type="number"
                    {...field}
                    label={i18next.language === "ar" ? "السعر" : "Price"}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="instructor_date"
                control={control}
                rules={{
                  required:
                    i18next.language === "ar"
                      ? "يرجى ادخال التاريخ!"
                      : "Date is required!",
                }}
                render={({ field }: any) => (
                  <TextField
                    error={!!errors.instructor_date?.message}
                    helperText={errors.instructor_date?.message}
                    type="date"
                    {...field}
                    label={i18next.language === "ar" ? "التاريخ" : "Date"}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: currentDate,
                      max: maxDateString,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {" "}
              <Controller
                name="instructor_start_time"
                control={control}
                rules={{
                  required:
                    i18next.language === "ar"
                      ? "يرجى ادخال وقت البدء!"
                      : "Start Time is required!",
                }}
                render={({ field }: any) => (
                  <TextField
                    error={!!errors.instructor_start_time?.message}
                    helperText={errors.instructor_start_time?.message}
                    type="time"
                    {...field}
                    label={
                      i18next.language === "ar" ? "وقت البدء" : "Start Time"
                    }
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="instructor_end_time"
                control={control}
                rules={{
                  required:
                    i18next.language === "ar"
                      ? "يرجى ادخال وقت الانتهاء!"
                      : "ُEnd time is required!",
                }}
                render={({ field }: any) => (
                  <TextField
                    error={!!errors.instructor_end_time?.message}
                    helperText={errors.instructor_end_time?.message}
                    type="time"
                    {...field}
                    label={
                      i18next.language === "ar" ? "وقت الانتهاء" : "End Tim"
                    }
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>

          <DialogActions sx={{ marginTop: "1rem" }}>
            <Stack
              justifyContent={i18next.language === "ar" ? "start" : "end"}
              width="100%"
              direction="row"
              gap="0.7rem"
            >
              <Button
                disabled={accept.isLoading}
                type="submit"
                variant="contained"
              >
                {accept.isLoading
                  ? "Loading..."
                  : i18next.language === "ar"
                  ? "تأكيد"
                  : "Confirm"}
              </Button>
              <Button variant="outlined" onClick={() => setOpen(false)}>
                {i18next.language === "ar" ? "إغلاق" : "Close"}
              </Button>
            </Stack>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AcceptConsultationModal;
