import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { CartTotal } from "../../components/Cart/CheckOut/cart-total.component";
import { PaymentMethod } from "../../components/Cart/CheckOut/payment-method";
import { LoadingPage } from "../../components/Common/loadingPage.component";
import { PageTitle } from "../../components/Common/page-title-section.component";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../api/api";
import { CartItem } from "../../interfaces/api-interfaces";
import { CustomButton } from "../../components/helpers";
import { ThankYouModal } from "../../components/Cart/CheckOut/thank-you";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

interface Props {}

const CheckOutPage: React.FC<Props> = () => {
  const { t } = useTranslation();
  const [thankYouModal, setThankYouModal] = useState(false);
  const { data, isLoading, isError } = useQuery(["get-cart"], () =>
    fetchApi("/cart", "GET", {})
  );
  const { mutate } = useMutation(
    (data: any) => fetchApi("/orders", "POST", data),
    {
      onSuccess: () => {
        toast.success(
          i18n.language === "en" ? "oredered successfully" : "تم الطلب بنجاح"
        );
        setThankYouModal(true);
      },
      onError: () =>
        toast.error(
          i18n.language === "en"
            ? "something went wrong"
            : "حدث خطأ أثناء الطلب"
        ),
    }
  );
  const handleSubmit = () => {
    mutate({
      // cartItems: data?.data.map((item: any) => item.id),
      // paymentMethod: {
      //   globalPayment: {
      //     isPaypal: true,
      //   },
      // },
      // isApproved: true,
      paymentApproved: true,
      paymentMethod: {
        globalPayment: {
          isPaypal: true,
        },
      },
    });
  };
  if (isLoading) return <LoadingPage />;
  return (
    <>
      <ThankYouModal isOpen={thankYouModal} setIsOpen={setThankYouModal} />
      {data?.data.length === 0 ? (
        <div>
          <div className="section " style={{ marginTop: "10vh" }}>
            <div className="container">
              <div className="cart-empty-content">
                <span
                  className="icon d-flex mx-auto"
                  style={{ width: "100px", height: "100px" }}
                >
                  <img
                    src={require("../../assets/images/empty-cart.png")}
                    alt="empty cart"
                    style={{ width: "100%", height: "100%" }}
                  />
                </span>
                <h3 className="title">Your cart is currently empty.</h3>
                <Link
                  to="/shop"
                  className="btn btn-primary btn-hover-secondary"
                >
                  Return to shop
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <PageTitle
            link={t("CheckOutTitle")}
            prevLinks={[
              {
                title: t("GlobalHome"),
                link: "/",
              },
            ]}
            title={t("CheckOutTitle")}
          />
          <div className="checkout-section section section-padding-bottom">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <form action="#" className="checkout-form">
                    <div className="row row-40">
                      <div className="col-8 mx-auto">
                        <div className="row">
                          <CartTotal
                            products={data?.data.map((el: CartItem) => ({
                              title: el.item.name,
                              price: el.item.price,
                              quantity: el.quantity,
                            }))}
                          />

                          {data?.data.reduce(
                            (acc: number, product: any) =>
                              acc + product.quantity * product.item.price,
                            0
                          ) == 0 ? (
                            <div
                              className="col-12 d-flex justify-content-center"
                              style={{
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <p style={{ fontSize: "2em" }}>
                                {i18n.language === "en"
                                  ? " you order is totally free, order directly!"
                                  : "الطلب مجاني, اطلبه مباشرةً الآن!"}
                              </p>
                              <CustomButton
                                arLabel="اطلب الآن"
                                enLabel="Order Now"
                                handlePress={() => handleSubmit()}
                              />
                            </div>
                          ) : (
                            <PaymentMethod />
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CheckOutPage;
