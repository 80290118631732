import React from "react";
import {
  createLiveConsultationRoom,
  instructorLiveConsultation,
} from "../../../interfaces/new-api-interfaces/consultations";
import {
  Button,
  CircularProgress,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { ImCancelCircle } from "react-icons/im";
import i18next from "i18next";
import { FaRegCheckCircle } from "react-icons/fa";
import { SetStateAction } from "jotai";
import { defaultTranslation } from "../../../untilites/functions";
import { FaEye } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGoogleLogin } from "@react-oauth/google";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createLiveRoom } from "../../../api/endpoints-functions";
import { toast } from "react-toastify";
import useCreateGoogleMeetConsultation from "../../../hooks/consultations/useCreateGoogleMeetConsultation";

export const statusKeyName: any = {
  pending: { ar: "قيد الانتظار", en: "Pending" },
  scheduled: { ar: "مجدولة", en: "Scheduled" },
  paid: { ar: "مدفوعة", en: "Paid" },
  "rejected by student": {
    ar: "مرفوضة من قبل الطالب",
    en: "Rejected by student",
  },
  "rejected by instructor": {
    ar: "مرفوضة من قبلك",
    en: "Rejected by you",
  },
  done: {
    ar: "منتهية",
    en: "Done",
  },
};

const PendingCells = ({
  consultation,
}: {
  consultation: instructorLiveConsultation;
}) => {
  return (
    <>
      <TableCell align="center">
        <Typography textAlign="center" fontSize="large">
          {consultation.student_date}
        </Typography>
      </TableCell>
    </>
  );
};

const ScheduledCells = ({
  consultation,
}: {
  consultation: instructorLiveConsultation;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <TableCell align="center">
        <Typography>{consultation.instructor_date}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography>
          {consultation.instructor_start_time} -{" "}
          {consultation.instructor_end_time}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography>{`${consultation.price?.toLocaleString()} ${t(
          "syp"
        )}`}</Typography>
      </TableCell>
    </>
  );
};

const RejectedCells = ({
  consultation,
}: {
  consultation: instructorLiveConsultation;
}) => {
  return (
    <TableCell align="center">
      <Typography>{consultation.instructor_notes}</Typography>
    </TableCell>
  );
};
const PaidCells = ({
  consultation,
}: {
  consultation: instructorLiveConsultation;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <TableCell align="center">
        <Typography>{consultation.instructor_date}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography>
          {consultation.instructor_start_time} -{" "}
          {consultation.instructor_end_time}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography>{`${consultation.price?.toLocaleString()} ${t(
          "syp"
        )}`}</Typography>
      </TableCell>
    </>
  );
};

const DoneCells = ({
  consultation,
}: {
  consultation: instructorLiveConsultation;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <TableCell align="center">
        <Typography>{consultation.instructor_date}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography>
          {consultation.instructor_start_time} -{" "}
          {consultation.instructor_end_time}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography>{`${consultation.price?.toLocaleString()} ${t(
          "syp"
        )}`}</Typography>
      </TableCell>
    </>
  );
};

const LiveConsultationTableItem = ({
  consultation,
  setId,
  setRejectOpen,
  setAcceptOpen,
  status,
  setPreviewOpen,
}: {
  consultation: instructorLiveConsultation;
  setId: React.Dispatch<SetStateAction<number | undefined>>;
  setRejectOpen: React.Dispatch<SetStateAction<boolean>>;
  setAcceptOpen: React.Dispatch<SetStateAction<boolean>>;
  setPreviewOpen: React.Dispatch<SetStateAction<boolean>>;
  status:
    | "pending"
    | "scheduled"
    | "paid"
    | "rejected by instructor"
    | "rejected by student"
    | "done";
}) => {
  let statusCells;
  let statusColor;

  if (status === "pending") {
    statusCells = <PendingCells consultation={consultation} />;
    statusColor = "orange";
  } else if (status === "scheduled") {
    statusCells = <ScheduledCells consultation={consultation} />;
    statusColor = "blue";
  } else if (status === "rejected by instructor") {
    statusCells = <RejectedCells consultation={consultation} />;
    statusColor = "error";
  } else if (status === "rejected by student") {
    statusColor = "error";
    statusCells = <TableCell></TableCell>;
  } else if (status === "paid") {
    statusCells = <PaidCells consultation={consultation} />;
    statusColor = "green";
  } else if (status === "done") {
    statusCells = <DoneCells consultation={consultation} />;
    statusColor = "gray";
  }

  const navigate = useNavigate();
  const { t } = useTranslation();
  const createGoogleMeetConsultation = useCreateGoogleMeetConsultation(
    consultation.id
  );
  return (
    <TableRow hover>
      <TableCell align="center">
        <Typography>{consultation.id}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography>
          {defaultTranslation(consultation, "first_name") +
            " " +
            defaultTranslation(consultation, "last_name")}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography>
          {consultation.student_reason &&
          consultation.student_reason.length > 30
            ? consultation.student_reason.slice(0, 30) + "..."
            : consultation.student_reason}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography>
          {consultation.student_notes.length > 30
            ? consultation.student_notes.slice(0, 30) + "..."
            : consultation.student_notes}
        </Typography>
      </TableCell>
      {statusCells}
      <TableCell align="center">
        <Typography color={statusColor}>
          {i18next.language === "ar"
            ? statusKeyName[consultation.status].ar
            : statusKeyName[consultation.status].en}
        </Typography>
      </TableCell>

      <TableCell align="center">
        {status === "pending" && (
          <>
            <Tooltip arrow title={i18next.language === "ar" ? "رفض" : "Reject"}>
              <IconButton
                onClick={() => {
                  setId(consultation.id);
                  setRejectOpen(true);
                }}
                color="error"
              >
                <ImCancelCircle />
              </IconButton>
            </Tooltip>
            <Tooltip
              arrow
              title={i18next.language === "ar" ? "موافقة" : "Accept"}
            >
              <IconButton
                onClick={() => {
                  setId(consultation.id);
                  setAcceptOpen(true);
                }}
                color="success"
              >
                <FaRegCheckCircle />
              </IconButton>
            </Tooltip>
          </>
        )}
        <Tooltip arrow title={i18next.language === "ar" ? "معاينة" : "Preview"}>
          <IconButton
            onClick={() => {
              setId(consultation.id);
              setPreviewOpen(true);
            }}
            color="primary"
          >
            <FaEye />
          </IconButton>
        </Tooltip>
        {status === "paid" && !consultation.meet_link && (
          <Button
            size="small"
            variant="contained"
            color="success"
            onClick={() => {
              createGoogleMeetConsultation.googleLogin();
            }}
            disabled={createGoogleMeetConsultation.isLoading}
            endIcon={
              createGoogleMeetConsultation.isLoading && (
                <CircularProgress size={12} />
              )
            }
          >
            {t("createMeeting")}
          </Button>
        )}
        {status === "paid" && consultation.meet_link && (
          <Button
            size="small"
            variant="contained"
            color="myorange2"
            onClick={() => {
              window.open(consultation.meet_link!, "_blank");
              // navigate("/join-consultation-room", {
              //   state: {
              //     meet_link: consultation.meet_link,
              //     consultationId: consultation.id,
              //     instructorId: consultation.instructor?.user_id,
              //     remoteUser: {
              //       profile_image: consultation.profile_image,
              //       translations: consultation.translations,
              //     },
              //   },
              // });
            }}
          >
            {t("joinMeeting")}
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

export default LiveConsultationTableItem;
