import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAtomValue } from "jotai";
import { roleAtom } from "../store";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchApi } from "../api/api";

const useNotificationNavigation = () => {
  const queryClient = useQueryClient();
  const role = useAtomValue(roleAtom);
  const navigate = useNavigate();

  const { mutate: readNotification } = useMutation(
    (id: number) => fetchApi(`/auth/notification/${id}`, "PUT"),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-notifications-number"]);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );
  const notificationNavigationFunc = (
    type: string,
    pointer_id: number,
    id: number,
    isRead: boolean,
    setIsModalVisible?: Function
  ) => {
    switch (type) {
      case "liveconsultation":
        navigate(
          role === "student"
            ? `/student-profile?activeTab=consultations`
            : `/profile?activeTab=consultations`
        );
        break;
      case "consultation":
        navigate(
          role === "student"
            ? `/consultation-details/${pointer_id}`
            : `/instructor-consultation-details/${pointer_id}`
        );
        break;
      case "course":
        navigate(`/course-details/${pointer_id}`);
        break;
      case "album":
        navigate(`/student-profile?activeTab=albums`);
        break;
      case "chat":
        navigate("/virtual-classroom");
        break;
      default:
        navigate(role === "student" ? `/student-profile` : `/profile`);
        break;
    }
    if (!isRead) {
      readNotification(id);
    }
    if (setIsModalVisible) setIsModalVisible(false);
  };

  // Return the function so it can be used outside of the hook
  return { notificationNavigationFunc };
};

export default useNotificationNavigation;
