import React from "react";
import i18n from "i18next";

interface Props {}

export const EmptyCart: React.FC<Props> = () => {
  return (
    <div>
      <div className="section section-padding-bottom">
        <div className="container">
          <div className="cart-empty-content">
            <span
              className="icon d-flex mx-auto"
              style={{ width: "100px", height: "100px" }}
            >
              <img
                src={require("../../../assets/images/empty-cart.png")}
                alt="empty cart"
                style={{ width: "100%", height: "100%" }}
              />
            </span>
            <h3 className="title">
              {i18n.language === "en"
                ? "Your cart is currently empty."
                : "السلة فارغة"}
            </h3>
            {/* <p>{i18n.language === 'en' ? "You may check out all the available products and buy some in the shop." : 'يمكن تصفح المتجر وإضافة كتاب الى السلة'} </p>
            <a href="/shop" className="btn btn-primary btn-hover-secondary">{i18n.language === 'en' ? "Return to shop" : "الذهاب للمتجر"}</a> */}
          </div>
        </div>
      </div>
    </div>
  );
};
