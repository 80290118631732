import { Navigate, Outlet } from 'react-router-dom'
import { useAtomValue } from 'jotai';
import { roleAtom } from '../store';

const InstructorRoutes = () => {
  const role = useAtomValue(roleAtom);
  return (
    role === 'instructor' ? <Outlet /> : <Navigate to='/' />
  )
}

export default InstructorRoutes;