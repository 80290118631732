import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { Notification } from "../../interfaces/new-api-interfaces/notification";
import { defaultTranslation } from "../../untilites/functions";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import { useAtomValue } from "jotai";
import { roleAtom } from "../../store";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchApi } from "../../api/api";
import useNotificationNavigation from "../../notification/useNotificationNavigation";

type Props = {
  notification: Notification;
  setIsModalVisible: Function;
};

const NotificationItem = ({ notification, setIsModalVisible }: Props) => {
  const { notificationNavigationFunc } = useNotificationNavigation();
  return (
    <Box
      sx={{
        borderRadius: "10px",
        minWidth: "300px",
        cursor: "pointer",
        minHeight: "100px",
        padding: "15px",
        bgcolor:
          notification.pivot.read_at == null
            ? "rgb(220 213 255 / 50%)"
            : "#eee",
        "&:hover": {
          bgcolor:
            notification.pivot.read_at == null
              ? "rgb(200 193 255 / 50%)"
              : "#ddd",
        },
      }}
      onClick={() => {
        notificationNavigationFunc(
          notification.pointerable_type,
          notification.pointerable_id,
          notification.id,
          notification.pivot.read_at != null,
          setIsModalVisible
        );
      }}
    >
      <Stack alignItems="flex-start" justifyContent="center" width="100%">
        <Stack
          flexDirection={i18next.language == "ar" ? "row" : "row-reverse"}
          width={"100%"}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          gap={2}
        >
          <Typography variant="subtitle2" sx={{ whiteSpace: "nowrap" }}>
            {notification.created_at}
          </Typography>
          <Typography fontWeight={600}>
            {defaultTranslation(notification, "title")}
          </Typography>
        </Stack>

        <Typography sx={{ width: "100%" }}>
          {defaultTranslation(notification, "message")}
        </Typography>
      </Stack>
    </Box>
  );
};

export default NotificationItem;
