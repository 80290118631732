import { useAtomValue } from "jotai";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CourseApiResponse } from "../../../interfaces/api-interfaces";
import { roleAtom } from "../../../store";
import { CourseOverview } from "../course-details-components/course-overview";
import { CourseReviews } from "../course-details-components/course-reviews";
import { CurriculumSection } from "../course-details-components/curriculum-section";
import CourseButtons from "./course-buttons";
import CourseTests from "./course-test";
import CourseTestsTab from "./course-tests";
import { CourseInstructorTab } from "../course-details-components/course-instructor-tab";
import { SideSection } from "../../Common/Side-section/side-section";
import { courseResponse } from "../../../interfaces/new-api-interfaces/course";
import i18next from "i18next";
import { useQuery } from "@tanstack/react-query";
import {
  fetchCourseCerifications,
  fetchCourseTests,
  fetchSections,
  fetchSectionsWithoutFiles,
} from "../../../api/endpoints-functions";
import { useParams } from "react-router-dom";
import { Box, CircularProgress, colors, Typography } from "@mui/material";
import { defaultTranslation } from "../../../untilites/functions";
import CourseCertificates from "../course-details-components/course-certificates";

interface Props {
  course?: courseResponse;
  isLoading: boolean;
}

const tabTextStyle = {
  fontSize: "18px",
  fontWeight: 700,
  cursor: "pointer",
  color: "inherit",
};

const CourseDetailsSection: React.FC<Props> = ({ course, isLoading }) => {
  const { t } = useTranslation();
  const params = useParams();
  const [currentSection, setCurrentSection] = useState("overview");
  const {
    isLoading: sectionsLoading,
    isError,
    data: sections,
  } = useQuery(["get-sections", params.id], () =>
    fetchSectionsWithoutFiles(params.id!)
  );

  const { isLoading: testsLoading, data: tests } = useQuery(
    ["get-tests", params.id],
    () => fetchCourseTests(params.id!)
  );

  return (
    <div className="section">
      <div className="container">
        <div className="row max-mb-n50">
          <div className="col-lg-8 col-12 order-lg-1 max-mb-50">
            <div className="course-details-wrapper">
              <div className="course-nav-tab">
                <ul
                  className="nav scrollable"
                  style={{
                    flexWrap: "nowrap",
                    whiteSpace: "nowrap",
                    paddingRight: 0,
                  }}
                >
                  <li
                    style={{ cursor: "pointer", userSelect: "none" }}
                    className={currentSection === "overview" ? "active" : ""}
                    onClick={() => setCurrentSection("overview")}
                  >
                    <Typography sx={tabTextStyle}>
                      {t("CourseDetailsOverview")}
                    </Typography>
                  </li>
                  <li
                    style={{ cursor: "pointer", userSelect: "none" }}
                    className={currentSection === "curriculum" ? "active" : ""}
                    onClick={() => setCurrentSection("curriculum")}
                  >
                    <Typography sx={tabTextStyle}>
                      {t("CourseDetailsCurriculum")}
                    </Typography>
                  </li>
                  <li
                    style={{ cursor: "pointer", userSelect: "none" }}
                    className={currentSection === "instructor" ? "active" : ""}
                    onClick={() => setCurrentSection("instructor")}
                  >
                    <Typography sx={tabTextStyle}>
                      {t("CourseDetailsInstructor")}
                    </Typography>
                  </li>
                  {/* <li
                    style={{ cursor: "pointer", userSelect: "none" }}
                    className={currentSection === "reviews" ? "active" : ""}
                    onClick={() => setCurrentSection("reviews")}
                  >
                    {t("CourseDetailsReviews")}
                  </li> */}
                  <li
                    style={{ cursor: "pointer", userSelect: "none" }}
                    className={
                      currentSection === "certifications" ? "active" : ""
                    }
                    onClick={() => setCurrentSection("certifications")}
                  >
                    <Typography sx={tabTextStyle}>
                      {t("cetrtificates")}
                    </Typography>
                  </li>
                  {/* {tests?.length! > 0 && (
                    <li
                      style={{ cursor: "pointer", userSelect: "none" }}
                      className={currentSection === "tests" ? "active" : ""}
                      onClick={() => setCurrentSection("tests")}
                    >
                      {t("CourseDetailsTests")}
                    </li>
                  )} */}
                </ul>
              </div>
              <div className="tab-content">
                {currentSection === "overview" ? (
                  <div
                    id="overview"
                    className={
                      "tab-pane fade" +
                      `${currentSection === "overview" ? "show active" : ""}`
                    }
                  >
                    <CourseOverview
                      desctiption={defaultTranslation(
                        course,
                        "web_description"
                      )}
                      descriptionVideo={course?.description_video || ""}
                      isLoading={isLoading}
                    />
                  </div>
                ) : currentSection === "curriculum" ? (
                  <div
                    id="curriculum"
                    className={
                      "tab-pane fade" +
                      `${currentSection === "curriculum" ? "show active" : ""}`
                    }
                  >
                    <div className="course-curriculum">
                      {!sectionsLoading && (
                        <ul
                          className="curriculum-sections"
                          style={{ padding: 0 }}
                        >
                          {React.Children.toArray(
                            sections && sections.length > 0 ? (
                              <>
                                {sections.map((section, index) => (
                                  <CurriculumSection
                                    key={section.id}
                                    isSectionFree={section.is_free}
                                    isEnrolled={course?.is_bought!}
                                    courseId={course?.id!}
                                    description={defaultTranslation(
                                      section,
                                      "description"
                                    )}
                                    title={defaultTranslation(section, "name")}
                                    courseItems={section.lessons}
                                    isLoading={isLoading}
                                    index={index}
                                    color={course?.color!}
                                  />
                                ))}
                              </>
                            ) : (
                              <div className="learn-press-message success ml-15 mr-15">
                                <i className="bx bx-info-circle"></i> No
                                sections in this course
                              </div>
                            )
                          )}
                        </ul>
                      )}
                      {sectionsLoading && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            paddingTop: "50px",
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      )}
                    </div>
                  </div>
                ) : currentSection === "instructor" ? (
                  <div
                    id="instructor"
                    className={
                      "tab-pane fade" +
                      `${currentSection === "instructor" ? "show active" : ""}`
                    }
                  >
                    {course && (
                      <CourseInstructorTab
                        instructor={course?.instructors}
                        isLoading={isLoading}
                      />
                    )}
                  </div>
                ) : currentSection === "reviews" ? (
                  <div
                    id="reviews"
                    className={
                      "tab-pane fade" +
                      `${currentSection === "reviews" ? "show active" : ""}`
                    }
                  >
                    {course && (
                      <CourseReviews course={course} isLoading={isLoading} />
                    )}
                  </div>
                ) : currentSection === "certifications" ? (
                  <div
                    id="reviews"
                    className={
                      "tab-pane fade" +
                      `${
                        currentSection === "certifications" ? "show active" : ""
                      }`
                    }
                  >
                    <CourseCertificates
                      currentSection="certifications"
                      courseId={course?.id!}
                    />
                  </div>
                ) : // ) : currentSection === "tests" ? (
                //   <CourseTestsTab
                //     tests={tests!}
                //     currentSection={currentSection}
                //     courseId={course?.id!}
                //     enrolled={course?.is_bought!}
                //   />
                // )
                null}
              </div>
            </div>
          </div>
          <SideSection type="course" />
        </div>
      </div>
    </div>
  );
};

export default CourseDetailsSection;
