import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import { InstructorCard } from "../../components/instructors/instructor-card";
import { LoadingPage } from "../../components/Common/loadingPage.component";

import { Pagination, Skeleton } from "@mui/material";
import { useState } from "react";
import { Box } from "@mui/material";
import gridBackground from "../../assets/images/trainers-grid/bg.jpg";
import { GridTitle } from "../../components/Common/grids-common/grid-title";
import { GridSearch } from "../../components/Common/grids-common/grid-search";
import { GridSelect } from "../../components/Common/grids-common/grid-select";
import { fetchInstructors } from "../../api/endpoints-functions";
import { defaultTranslation } from "../../untilites/functions";
import { InstructorResponse } from "../../interfaces/new-api-interfaces/instructor";
import useDebounce from "../../hooks/useDebounce";

interface Props {
  isComponent: boolean;
}

export const InstructorsGrid: React.FC<Props> = (Props) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const search = useDebounce(searchValue);

  const { data, isLoading, isError, isFetching } = useQuery(
    ["get-instructors", search],
    () => fetchInstructors(search),
    { staleTime: 120000 }
  );

  const ITEMS_PER_PAGE: number = 10;
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(ITEMS_PER_PAGE);

  const paginatedData = data?.slice(min, max);

  const paginate = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPageNumber(page);
    setMin(ITEMS_PER_PAGE * (page - 1));
    setMax(page * ITEMS_PER_PAGE);
  };

  return (
    <Box>
      <GridTitle>{t("NavHeaderBecome")}</GridTitle>
      <Box
        className="section section-padding-bottom"
        sx={{
          backgroundImage: { xs: "none", md: `url(${gridBackground})` },
          backgroundRepeat: "no-repeat",
          backgroundSize: "80vw 60vh",
        }}
      >
        <Box className={isLoading ? "container-lg" : "container"}>
          <div className="row justify-content-center align-items-center max-mb-20">
            <div
              className="col-sm-10 col-md-2 max-mb-10 mb-20"
              style={{ width: !isLoading && !isFetching ? "fit-content" : "" }}
            >
              {!isLoading && !isFetching ? (
                <p className="result-count">
                  <Trans i18nKey="TeachersGridSubTitle" count={data?.length}>
                    We found <span>count</span> courses available for you
                  </Trans>
                </p>
              ) : (
                <p className="result-count">
                  <Skeleton variant="text" height={"3rem"} width={"100%"} />
                </p>
              )}
            </div>
            <GridSearch
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
          </div>
          {!isLoading && !isFetching ? (
            <Box
              className={
                !isLoading && !isFetching
                  ? "row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30 justify-content-center justify-content-md-start"
                  : ""
              }
            >
              {data && data.length ? (
                paginatedData?.map((instructors: InstructorResponse) => (
                  <InstructorCard key={instructors.id} {...instructors} />
                ))
              ) : (
                <p style={{ margin: "0 auto", fontSize: "2em" }}>
                  There are no instructors yet
                </p>
              )}
            </Box>
          ) : (
            <div className="row max-mb-n30">
              {[1, 2, 3, 4, 5, 6].map((el) => (
                <div className="col-12 col-md-6 col-lg-3 my-2 c-s" key={el}>
                  <Skeleton variant="rectangular" width={300} height={200} />
                  <div className="info">
                    <Skeleton
                      style={{ margin: "30px 0" }}
                      variant="text"
                      width="50%"
                    />
                    <Skeleton
                      style={{ margin: "30px 0" }}
                      variant="text"
                      width="70%"
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
          {!isLoading && !isFetching && (
            <div>
              {data?.length! > ITEMS_PER_PAGE ? (
                <Pagination
                  count={Math.ceil(data?.length! / ITEMS_PER_PAGE) || 0}
                  page={currentPageNumber}
                  onChange={paginate}
                  variant="outlined"
                  color="primary"
                  size="large"
                  dir="ltr"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "40px",
                  }}
                />
              ) : null}
            </div>
          )}
        </Box>
      </Box>
    </Box>
  );
};
