import { Button, Stack, Typography } from "@mui/material";
import React, { SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OTPInput from "react-otp-input";
import { toast } from "react-toastify";
import ResendTimer from "./ResnsedTimer";
import { useMutation } from "@tanstack/react-query";
import { fetchApi } from "../../../api/api";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import { useSetAtom } from "jotai";
import { roleAtom, tokenAtom, userAtom, userIdAtom } from "../../../store";

const OtpForm = ({
  email,
  setIsVerify,
  setOtpValue,
  isResetPassword,
}: {
  email: string;
  setIsVerify: React.Dispatch<SetStateAction<boolean>>;
  setOtpValue: React.Dispatch<SetStateAction<string>>;
  isResetPassword: boolean;
}) => {
  const [otp, setOtp] = useState("");

  const navigate = useNavigate();
  const setToken = useSetAtom(tokenAtom);
  const setRole = useSetAtom(roleAtom);
  const setUserId = useSetAtom(userIdAtom);
  const setUser = useSetAtom(userAtom);
  const send = useMutation(
    (data: any) => fetchApi(`/auth/user/verify`, "POST", data),
    {
      onSuccess: (res) => {
        toast.success(res.data.message);
        if (isResetPassword) {
          setIsVerify(true);
          setOtpValue(otp);
        } else {
          const { data } = res.data;
          const {
            token,
            role,
            id,
            first_name,
            last_name,
            email,
            phone_number,
            address,
            birth_date,
            profile_image,
          } = data;

          setToken(token);
          setRole(role);
          setUserId(id);
          setUser({
            id,
            firstName: first_name,
            lastName: last_name,
            email,
            phoneNumber: phone_number,
            address,
            age: birth_date,
            role,
            profileImage: profile_image,
          });

          if (role == "instructor") {
            navigate("/profile", { replace: true });
          } else {
            navigate("/student-profile", { replace: true });
          }
        }
      },
      onError: (error: any) => {
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).forEach((errorKey) => {
            toast.error(error.response.data.errors[errorKey].join());
          });
        } else {
          toast.error(error.response.data.message);
        }
      },
    }
  );
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds < 59) {
        setSeconds(seconds + 1);
      } else {
        setMinutes(minutes + 1);
        setSeconds(0);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleSubmit = () => {
    send.mutateAsync({
      otp: otp,
      email: email,
      is_reset_password: isResetPassword,
    });
  };

  return (
    <>
      <Typography textAlign="center">
        {i18next.language === "ar"
          ? `${email} تم ارسال رمز التأكيد إلى  `
          : `Verification code has been sent to ${email}`}
      </Typography>
      <OTPInput
        value={otp}
        onChange={setOtp}
        numInputs={6}
        renderInput={(props:any) => <input {...props} />}
        containerStyle={{
          marginTop: "1rem",
          display: "flex",
          gap: "0.6rem",
          justifyContent: "center",
        }}
        inputStyle={{
          width: "3rem",
          height: "3rem",
          borderRadius: "15px",
          border: "1px solid #eee",
          fontSize: "18px",
          backgroundColor: "#eee",
        }}
      />

      <Button
        disabled={otp.length < 6 || send.isLoading}
        variant="contained"
        fullWidth
        sx={{ mt: 2 }}
        onClick={handleSubmit}
      >
        {t("Send")}
      </Button>

      <ResendTimer email={email} min={1} />
    </>
  );
};

export default OtpForm;
