import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import TopInstructor from "../home-page-components/top-instructor";
import { Box, Skeleton, Typography, Button } from "@mui/material";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import trainersBg from "../../../assets/images/home-trainers/trainers-background.jpg";
import i18n from "i18next";
import { LoadingPage } from "../../Common/loadingPage.component";
import { fetchTopInstructors } from "../../../api/endpoints-functions";
import { instructorProfile } from "../../../interfaces/new-api-interfaces/profile";

interface Props {}

var settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const TopInstructorsSection: React.FC<Props> = () => {
  const { isLoading, data, isError } = useQuery(["get-top-instructors"], () =>
    fetchTopInstructors()
  );
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (isLoading) return <LoadingPage />;

  return (
    <Box
      className="section"
      sx={{
        backgroundImage: { xs: "none", md: `url(${trainersBg})` },
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        paddingBottom: "30px",
      }}
    >
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
        className="container"
      >
        <div
          className="section-title text-center"
          style={{ marginTop: "60px", marginBottom: 20 }}
          data-aos="flip-left"
        >
          <Typography
            variant="h1"
            color={"primary.main"}
          >
            {i18n.language === "en" ? "Trainers" : "المدربون"}
          </Typography>
          <Typography variant="h5" sx={{ textAlign: "center", mb: 4, mt: 2 }}>
            {i18n.language === "en" ? (
              <>
                Meet our expert Trainers who are dedicated to helping you
                achieve your learning goals.
              </>
            ) : (
              <>
                تعرف على مدربينا الخبراء الذين يعملون بتفانٍ لمساعدتك في تحقيق
                أهداف التعلم الخاصة بك.
              </>
            )}
          </Typography>
        </div>

        <Box
          className="home-trainers"
          data-aos="fade-up"
          sx={{
            padding: { xs: "50px 50px 50px 50px", md: "0px" },
            width: "100%",
          }}
        >
          <Slider {...settings}>
            {data?.map((instructor: instructorProfile) => (
              <TopInstructor
                key={instructor.id}
                user={instructor.users}
                id={instructor.id}
              />
            ))}
          </Slider>
        </Box>

        {/* Call to Action */}
        <Typography
          className="home-action"
          variant="h5"
          onClick={() => navigate("/teachers")}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            color: "primary.main",
            border: "1px solid",
            borderColor: "primary.main",
            padding: "10px 20px",
            borderRadius: "50px",
            cursor: "pointer",
            marginTop: "2rem",
          }}
        >
          {i18n.language === "en" ? "See All Trainers" : "عرض جميع المدربين"}
          {i18n.language === "en" ? <FaArrowRightLong /> : <FaArrowLeftLong />}
        </Typography>
      </Box>
    </Box>
  );
};

export default TopInstructorsSection;
