import React from "react";
import Vimeo from "@u-wave/react-vimeo";

type Props = {
  file: string;
  onProgress?: () => void;
  is_Watched?: boolean;
};

const CustomVimeoPlayer = ({ file, onProgress, is_Watched }: Props) => {
  return (
    <div
      className="video-container"
      style={{ width: "100%", height: "calc(1000px * 9 / 16)" }}
    >
      <Vimeo
        video={file}
        responsive={true}
        onEnd={() => {
          if (onProgress && is_Watched == false) onProgress();
        }}
        color="#3f3a64"
      />
    </div>
  );
};

export default CustomVimeoPlayer;
