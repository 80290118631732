import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import "./auth.style.css";
import { useSetAtom } from "jotai";
import { instructorIdAtom, roleAtom, userAtom, userIdAtom } from "../../store";
import { tokenAtom } from "../../store";
import { useMutation } from "@tanstack/react-query";
import { fetchApi } from "../../api/api";
import {LoadingPage} from "../../components/Common/loadingPage.component";
import { CustomTextarea } from "../../components/helpers";

interface Props {}

const InstructorRegisterForm: React.FC<Props> = () => {
  const location = useLocation();
  const { basicInfo } = location.state;
  const [form, setForm] = useState({
    bio: basicInfo?.bio ||  "",
    education: basicInfo?.education ||  "",
    role: "instructor",
  });
  const navigate = useNavigate();

  const setToken = useSetAtom(tokenAtom);
  const setRole = useSetAtom(roleAtom);
  const setUserId = useSetAtom(userIdAtom);
  const setUser = useSetAtom(userAtom);
  const setInstructorId = useSetAtom(instructorIdAtom);

  const { isLoading, mutateAsync } = useMutation((data: any) =>
    fetchApi("/register", "POST", data)
  );
  const handleChange = (e: any, fieldName: string) => {
    setForm((prev: any) => ({ ...prev, [fieldName]: e.target?.value }));
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (form.bio.length && form.education.length) {
      await mutateAsync({
        ...basicInfo,
        ...form,
        role: form.role,
      })
        .then((response) => {
          if (response.status === 200) {
            setToken(response.data["jwt"]);
            setRole(response.data["user"]["role"]);
            setUserId(response.data["user"]["id"]);
            setUser(response.data["user"]);
            setInstructorId(response.data["id"]);
            navigate("/");
          } else {
            alert("Email already exist..");
            navigate("/register", { state: { ...form,...basicInfo } });
          }
        })
        .catch(() => {
          alert("Email already exist..");
          navigate("/register", { state: { ...form,...basicInfo } });
        });
    } else {
      alert();
    }
  };
  const { t } = useTranslation();
  if (isLoading) return <LoadingPage />;
  return (
    <div className="auth-container">
      <div className="login-form-wrapper mt-sm-50 mt-xs-50">
        <h3 className="title">{t("LoginFormRegisterInstructor")}</h3>
        <form
          className="register-form"
          action="#"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="single-input mb-30">
            <CustomTextarea
              arLabel="السيرة الذاتية"
              enLabel="bio"
              handleChange={handleChange}
              fieldName="bio"
              prevValue={form["bio"]}
            />
          </div>
          <div className="single-input mb-30">
            <CustomTextarea
              arLabel="التعليم"
              enLabel="education"
              handleChange={handleChange}
              fieldName="education"
              prevValue={form["education"]}
            />
          </div>
          <div className="single-input">
            <button
              className="btn btn-primary mb-2 btn-hover-secondary btn-width-100"
              onClick={handleSubmit}
            >
              {t("LoginFormRegisterInstructor")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default InstructorRegisterForm;
