import { atomWithStorage } from "jotai/utils";
import { User } from "./interfaces/api-interfaces";

export const tokenAtom = atomWithStorage("token", "");
export const roleAtom = atomWithStorage<"student" | "instructor" | "">(
  "role",
  ""
);
export const userIdAtom = atomWithStorage("userId", 0);
export const userAtom = atomWithStorage<User | undefined>("user", undefined);
export const instructorIdAtom = atomWithStorage("instructorId", 0);
export const showProgressAtom = atomWithStorage("showProgress", true);
