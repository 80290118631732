import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import "../../../pages/course/course-content.css";
import { defaultTranslation, getLink } from "../../../untilites/functions";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { BsListUl } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { courseLesson } from "../../../interfaces/new-api-interfaces/course";
import {
  fetchCourseCerifications,
  getCourseUserCertifications,
} from "../../../api/endpoints-functions";
import CourseCertificateCard from "../course-details-components/course-certificate-card";
import CourseGetCertificate from "../course-details-components/course-get-certificate";
import i18next from "i18next";

interface Props {
  setOpenedSideNav: any;
  openedSideNav: any;
  courseId: string;
}

export const CourseContentCertifications: React.FC<Props> = (Props) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { isLoading, data: certifications } = useQuery(
    ["get-certifications", Props.courseId],
    () => getCourseUserCertifications(Props.courseId),
    { keepPreviousData: true, staleTime: 60000 }
  );

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1001);
  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 1001); // Adjust the breakpoint as per your requirements
    }

    // Add event listener to window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: { xs: "column", md: "row" },
        gap: "10px",
      }}
    >
      {isMobile && (
        <Box sx={{ width: "90%", alignSelf: "center" }}>
          <Button
            sx={{ padding: 0, justifyContent: "flex-start" }}
            onClick={() => Props.setOpenedSideNav(!Props.openedSideNav)}
          >
            <BsListUl style={{ fontSize: "30px" }} />
          </Button>
        </Box>
      )}

      <Box
        sx={{
          width: { xs: "90%", md: "90%" },
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          alignSelf: { xs: "center", md: "flex-start" },
        }}
      >
        {!isLoading && certifications && (
          <Box marginTop={5}>
            <Typography>
              {i18next.language == "ar"
                ? "تهانينا لقد أكملت هذه الدورة بنجاح ويمكنك الآن الحصول على شهاداتك :"
                : "Congratulations, you have successfully completed this course and can now obtain your certificates:"}
            </Typography>
            <Box className="row">
              {certifications.map((certificate) => (
                <>
                  <div
                    className="col-12 col-md-5 col-lg-4 my-2 p-3"
                    key={certificate.id}
                  >
                    <CourseGetCertificate certificate={certificate} />
                  </div>
                </>
              ))}
            </Box>
          </Box>
        )}
        {isLoading && (
          <Stack
            width="100%"
            height="80%"
            alignItems="center"
            marginTop={10}
            justifyContent="center"
          >
            <CircularProgress />
          </Stack>
        )}
      </Box>
    </Box>
  );
};
