import React from "react";
import { useNavigate } from "react-router-dom";
// @ts-ignore
import ReactStars from "react-rating-stars-component";
import { Instructor } from "../../../interfaces/api-interfaces/courses";
import { getImageFromServer } from "./../../../assets/JS/helpers";
import { Avatar, Box, Skeleton, Typography } from "@mui/material";
import { courseInstructor } from "../../../interfaces/new-api-interfaces/course";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { defaultTranslation } from "../../../untilites/functions";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";

interface Props {
  instructor: courseInstructor;
  isLoading: boolean;
}

export const CourseInstructorTab: React.FC<Props> = ({
  instructor,
  isLoading,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Box className="course-instructor" sx={{ cursor: "pointer" }}>
      <div className="row" style={{ alignItems: "flex-start" }}>
        <div
          className="col-md-4"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            {!isLoading ? (
              <img
                src={
                  instructor?.users?.profile_image.length
                    ? getImageFromServer(instructor.users?.profile_image)
                    : require("../../../assets/images/person-fallback.jpeg")
                }
                alt="profile"
                style={{ width: 300, height: 300, borderRadius: "20px" }}
                onClick={() => navigate("/teacher/" + instructor?.id)}
              />
            ) : (
              <Skeleton variant="circular" width={150} height={150} />
            )}
          </div>
        </div>
        <Box
          className="col-md-8"
          sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "start" },
            flexDirection: "column",
            gap: "20px",
            alignItems: { xs: "center", sm: "start" },
            paddingTop: { xs: "30px", sm: 0 },
          }}
        >
          <Box
            className="profile-info"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            {!isLoading ? (
              <Typography
                variant="h5"
                style={{ textAlign: "center", whiteSpace: "nowrap" }}
              >
                {t("CourseDetailsInstructor") +
                  " : " +
                  defaultTranslation(instructor.users, "first_name") +
                  " " +
                  defaultTranslation(instructor.users, "last_name")}
              </Typography>
            ) : (
              <Skeleton variant="rectangular" width={200} height={30} />
            )}
            {!isLoading ? (
              <Typography>
                {i18next.language in instructor?.users.instructors_translations
                  ? instructor?.users.instructors_translations[
                      i18next.language
                    ]["education"]
                  : instructor?.users.instructors_translations["ar"][
                      "education"
                    ]}
              </Typography>
            ) : (
              <Skeleton
                variant="rectangular"
                width={200}
                height={50}
                sx={{ mt: 2 }}
              />
            )}
            {/* <div className="d-flex align-items-center">
              {!isLoading ? (
                <>
                  <div className="review-star">
                    <div className="tm-star-rating">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          justifyContent: "center",
                        }}
                      >
                        <Typography>
                          {t("CourseDetailsAverageRating") + " : "}
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Box
                            style={{
                              direction: "ltr",
                              transform:
                                i18next.language === "ar" ? "scaleX(-1)" : "",
                            }}
                          >
                            <ReactStars
                              size={24}
                              isHalf={true}
                              value={+instructor?.rating}
                              edit={false}
                              emptyIcon={<i className="far fa-star"></i>}
                              halfIcon={<i className="fa fa-star-half-alt"></i>}
                              fullIcon={<i className="fa fa-star"></i>}
                              activeColor="#ffd700"
                            />
                          </Box>
                          <div className="author-career ml-2">
                            ({instructor?.rating})
                          </div>
                        </Box>
                      </Box>
                    </div>
                  </div>
                </>
              ) : (
                <Skeleton variant="rectangular" width={200} height={20} />
              )}
            </div> */}
          </Box>
          <Box sx={{ width: { xs: "80%", sm: "100%" } }}>
            <Typography
              sx={{ width: { xs: "100%", sm: "80%" }, marginBottom: "20px" }}
            >
              {i18next.language in instructor?.users.instructors_translations
                ? instructor?.users.instructors_translations[i18next.language][
                    "bio"
                  ]
                : instructor?.users.instructors_translations["ar"]["bio"]}
            </Typography>
            <button
              onClick={() => navigate("/teacher/" + instructor?.id)}
              className="home-action"
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                {i18next.language == "en" && (
                  <>
                    <span>Read More</span> <FaArrowRightLong />
                  </>
                )}
                {i18next.language == "ar" && (
                  <>
                    <span>قراءة المزيد</span> <FaArrowLeftLong />
                  </>
                )}
              </Typography>
            </button>
          </Box>
        </Box>
      </div>
    </Box>
  );
};
