import React from "react";
import { CartTableItem } from "../cart-components/cart-table-item";
import { CartItem } from "../../../interfaces/api-interfaces";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import { cartResponse } from "../../../interfaces/new-api-interfaces/cart";
import { useTranslation } from "react-i18next";
interface Props {
  shopItems: cartResponse[];
}
export const CartTable: React.FC<Props> = ({ shopItems }) => {
  const { t } = useTranslation();
  return (
    <div className=" table-responsive max-mb-30">
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ bgcolor: "mypurple2.main" }}>
            <TableRow>
              <TableCell>
                <Typography fontSize="large" color="#fff">
                  {t("GlobalImage")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontSize="large" color="#fff">
                  {t("GlobalType")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontSize="large" color="#fff">
                  {t("GlobalProduct")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontSize="large" color="#fff">
                  {t("GlobalPrice")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontSize="large" color="#fff">
                  {t("GlobalRemove")}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shopItems.length > 0 ? (
              shopItems.map((product) => (
                <CartTableItem key={product.id} {...product} />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4}>
                  <div className="row">
                    <div className="col-12">
                      <div className="learn-press-message success">
                        <i className="bx bx-info-circle"></i> No Cart Items!
                      </div>
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
