import i18n from "i18next";
import { CustomImageInput } from "../../helpers";
import { blobToBase64 } from "../../../untilites/functions";

import "../styles.css";
import { courseRequest } from "../../../interfaces/new-api-interfaces/course";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../api/api";
import { toast } from "react-toastify";
import { useState } from "react";
import { useAtomValue } from "jotai";
import { instructorIdAtom, userAtom } from "../../../store";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import FileUploader from "../../helpers/custom-file-uploader";
import CustomColorPicker from "../../helpers/custom-color-picker";
import NormalFileUploader from "../../helpers/normal-file-uploader";
import AddTags from "../DashboardCourseComponents/add-tags";
import AddCategories from "../DashboardCourseComponents/add-categories";
import { fetchCategories, fetchTags } from "../../../api/endpoints-functions";
import ExcelOrByHandRadio from "../../Test/excel-or-by-hand-radio";

const inputStyle = {
  width: { xs: "100%", sm: "45%" },
  marginBottom: "30px",
};

interface Props {
  setCourseId: Function;
  handleNext: Function;
}

const AddCourse: React.FC<Props> = ({ setCourseId, handleNext }) => {
  const {
    data: categories,
    isLoading: l1,
    isError: e1,
  } = useQuery(["get-categories"], () => fetchCategories());

  const {
    data: tags,
    isLoading: l2,
    isError: e2,
  } = useQuery(["get-tags"], () => fetchTags());

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: { errors },
    setError,
  } = useForm<courseRequest>({
    defaultValues: {},
  });

  const [localError, setLocalError] = useState(null); //to handle file updloading errors
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const [currentProgress, setCurrentProgress] = useState(0);
  const myInstructorId = useAtomValue(instructorIdAtom);
  const { t } = useTranslation();

  const {
    isLoading: l3,
    isError: e3,
    mutateAsync: createCourse,
  } = useMutation((data: any) => fetchApi("/course/course", "POST", data));

  const onSubmit: SubmitHandler<courseRequest> = async (data) => {
    let modefiedData = { ...data };
    if (data.color == "" || data.color == undefined) {
      modefiedData.color = "#fff";
    }
    try {
      createCourse({
        ...modefiedData,
        instructor_id: myInstructorId,
        mobile_description: data.web_description,
        mobile_description_en: data.web_description_en,
        rating: 0,
        file: null,
      }).then((response) => {
        if (response.status === 200 || response.status === 201) {
          setFileLoading(true);
          const courseId = response.data.data.id;
          // Use the course ID in the createContent mutation

          if (data.file && watch("is_excel")) {
            const formData = new FormData();
            formData.append("file", data.file);

            fetchApi(
              `/course/course/createCourseContent/${courseId}`,
              "POST",
              formData
            )
              .then((contentResponse) => {
                setFileLoading(false);
                if (
                  contentResponse.status === 200 ||
                  contentResponse.status === 201
                ) {
                  toast.success(
                    i18n.language === "en"
                      ? "course created successfully"
                      : "تم انشاء الدورة بنجاح"
                  );
                  setCourseId(courseId);
                  handleNext();
                }
              })
              .catch((error) => {
                setFileLoading(false);
                reset({ file: null, guide_file: "", description_video: "" });
                console.error("Error creating course content:", error);
                toast.error("Failed to create course content");
              });
          } else {
            setFileLoading(false);
            toast.success(
              i18n.language === "en"
                ? "course created successfully"
                : "تم انشاء الدورة بنجاح"
            );
            setCourseId(courseId);
            handleNext();
          }
        }
      });
    } catch (error) {
      reset({ file: null, guide_file: "", description_video: "" });
      console.log(error);
    }
  };

  if (l1 || l2 || l3 || fileLoading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "60vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  return (
    <form
      className="d-flex flex-wrap container"
      style={{
        width: "100%",
        gap: "2%",
        padding: "20px 0 50px 0",
        justifyContent: "center",
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="course_image"
        rules={{ required: "Course Image is required" }}
        control={control}
        render={({ field }) => (
          <Box sx={inputStyle}>
            <Typography sx={{ paddingBottom: "5px" }}>
              {t("cardImage")}
            </Typography>
            <CustomImageInput
              file={field.value}
              handleChange={(e: any) => {
                blobToBase64(e)
                  .then((value) => {
                    field.onChange(value); // Update the field value with the base64 string
                  })
                  .catch((err) => {
                    console.log(err);
                    setLocalError(err);
                  });
              }}
              fieldName={field.name}
              isCover={true}
              isContain={true}
            />
            <Typography color="error" sx={{ paddingBottom: "5px" }}>
              {errors?.course_image?.message}
            </Typography>
          </Box>
        )}
      />

      <Controller
        name="overview_image"
        rules={{ required: "Panner Image is required" }}
        control={control}
        render={({ field }) => (
          <Box sx={inputStyle}>
            <Typography sx={{ paddingBottom: "5px" }}>
              {t("PannerImage")}
            </Typography>
            <CustomImageInput
              file={field.value}
              handleChange={(e: any) => {
                blobToBase64(e)
                  .then((value) => {
                    field.onChange(value); // Update the field value with the base64 string
                  })
                  .catch((err) => {
                    console.log(err);
                    setLocalError(err);
                  });
              }}
              fieldName={field.name}
              isCover={true}
              isContain={true}
            />
            <Typography color="error" sx={{ paddingBottom: "5px" }}>
              {errors?.overview_image?.message}
            </Typography>
          </Box>
        )}
      />

      <Controller
        name="name"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            sx={inputStyle}
            {...field}
            label={t("courseNameAr")}
            placeholder={t("courseNameAr")}
            required
          />
        )}
      />
      <Controller
        name="name_en"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            sx={inputStyle}
            {...field}
            label={t("courseNameEn")}
            placeholder={t("courseNameEn")}
            required
          />
        )}
      />
      <Controller
        name="web_description"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            sx={inputStyle}
            {...field}
            label={t("courseDescAr")}
            placeholder={t("courseDescAr")}
            required
          />
        )}
      />

      <Controller
        name="web_description_en"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            sx={inputStyle}
            {...field}
            label={t("courseDescEn")}
            placeholder={t("courseDescEn")}
            required
          />
        )}
      />

      <Controller
        name="price"
        control={control}
        defaultValue={0}
        render={({ field }) => (
          <TextField
            type="text"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            sx={inputStyle}
            {...field}
            label={t("GlobalPrice")}
            placeholder={t("GlobalPrice")}
            required
          />
        )}
      />

      <Box sx={inputStyle}>
        <AddCategories
          errors={errors}
          control={control}
          categories={categories!}
        />
      </Box>
      <Box
        sx={{
          width: { xs: "100%", sm: "92%" },
          marginBottom: "30px",
        }}
      >
        <AddTags errors={errors} tags={tags!} control={control} />
      </Box>
      <Box sx={inputStyle}>
        <FileUploader
          control={control}
          name="guide_file"
          setValue={setValue}
          accept=".pdf,.doc,.docx"
          label={t("uploadGuideFile")}
          setError={setError}
        />
        <Typography color="error" sx={{ paddingBottom: "5px" }}>
          {errors?.guide_file?.message}
        </Typography>
      </Box>
      <Box sx={inputStyle}>
        <FileUploader
          control={control}
          name="description_video"
          setValue={setValue}
          accept="video/mp4"
          label={t("uploadIntroVideo")}
        />
      </Box>
      <Box
        sx={{
          width: { xs: "100%", sm: "92%" },
          marginBottom: "30px",
        }}
      >
        <CustomColorPicker name="color" control={control} setValue={setValue} />
      </Box>

      <ExcelOrByHandRadio control={control} />
      {watch("is_excel") != false && (
        <Box
          sx={{
            width: { xs: "100%", sm: "92%" },
            marginBottom: "30px",
          }}
        >
          <Box sx={{ display: "flex", gap: "20px" }}>
            <NormalFileUploader
              control={control}
              setValue={setValue}
              accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              name="file"
              label={t("uploadExcelFile")}
            />
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                const link = document.createElement("a");
                link.href =
                  "https://backend.uh-training.com/ExcelFiles/Course_Form_with_translation.xlsx";
                link.download = "course_template.xlsx";
                link.click();
              }}
            >
              {t("downloadTemplate")}
            </Button>
          </Box>
        </Box>
      )}
      <div className="col-12 d-flex justify-content-center pt-4">
        <Button
          variant="contained"
          color="primary"
          className="mb-2 btn-hover-secondary btn-width-100"
          type="submit"
          sx={{ width: { xs: "100%", sm: "92%", lg: "30%" } }}
          disabled={watch("is_excel") != false && !watch("file")}
        >
          <Typography fontSize="large" color="#fff">
            {t("createCourse")}
          </Typography>
        </Button>
      </div>
    </form>
  );
};

export default AddCourse;
