import React, { useState, useEffect } from "react";
import Header from "./components/Header/header";
import Footer from "./components/Footer/Footer";
import Router from "./route";
import ScrollToTop from "./components/helpers/scroll-to-top";
import "./App.css";
import { LoadingLinearProgress } from "./components/Common/loading-linear-progress";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchApi } from "./api/api";
import { roleAtom, tokenAtom, userAtom, userIdAtom } from "./store";
import { useSetAtom } from "jotai";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import {
  createTheme,
  colors,
  ThemeProvider,
  alpha,
  getContrastRatio,
  Stack,
  CircularProgress,
} from "@mui/material";
import { AppErrorBoundary } from "./components/Common/error-handling/app-error-boundary";
import { UnderMaintenance } from "./components/Common/error-handling/under-maintenance";
import SpecialRouter from "./route/special";
import Notification from "./notification/pushNotification";

const App: React.FC = () => {
  const { i18n } = useTranslation();

  const cacheRtl = createCache({
    key: i18n.language == "ar" ? "muirtl" : "muiltr",
    stylisPlugins: i18n.language == "ar" ? [prefixer, rtlPlugin] : undefined,
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const setToken = useSetAtom(tokenAtom);
  const setRole = useSetAtom(roleAtom);
  const setUserId = useSetAtom(userIdAtom);
  const setUser = useSetAtom(userAtom);
  React.useEffect(() => {
    if (searchParams.get("lang")) {
      i18n.changeLanguage(searchParams.get("lang") ?? "en");
    } else {
      const lang = localStorage.getItem("lang");
      if (lang) {
        i18n.changeLanguage(lang);
      } else {
        i18n.changeLanguage("ar");
      }
    }

    // fetchApi("/profile-data", "get")
    //   .then((res) => {
    //     setUser(res.data);
    //   })
    //   .catch((err) => {
    //     setToken("");
    //     setRole("");
    //     setUserId(0);
    //   });
  }, []);

  const { palette } = createTheme();
  const { augmentColor } = palette;
  const createColor = (mainColor: any) =>
    augmentColor({
      color: {
        main: alpha(mainColor, 0.9),
        light: alpha(mainColor, 0.5),
        dark: alpha(mainColor, 1),
        contrastText: "#fff",
      },
    });

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1000,
        lg: 1200,
        xl: 1536,
      },
    },
    palette: {
      primary: {
        main: "#3f3a64",
      },
      myorange: {
        main: "#FFB75C",
      },
      myred: {
        main: "#ED7D64",
      },
      myyellow: {
        main: "#F9F871",
      },
      mygreen: {
        main: "#7CD888",
      },
      mypurple: {
        main: "#B4AED1",
      },
      mypurple2: createColor("#5D5488"),
      myred2: createColor("#ff5b37e6"),
      myorange2: createColor("#ff9816"),
      mygreen2: createColor("#00b7a3"),
    },
    typography: {
      fontFamily:
        i18n.language == "en"
          ? ["MulishRegular"].join(",")
          : ["Almarai"].join(","),
          fontSize: 13,
      h1: {
        fontFamily:
          i18n.language == "en"
            ? ["TiemposRegular"].join(",")
            : ["Almarai-bold"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily:
          i18n.language == "en"
            ? ["TiemposRegular"].join(",")
            : ["Almarai-bold"].join(","),
        fontSize: 35,
      },
      h3: {
        fontFamily:
          i18n.language == "en"
            ? ["TiemposRegular"].join(",")
            : ["Almarai-bold"].join(","),
        fontSize: 30,
      },
      h4: {
        fontFamily:
          i18n.language == "en"
            ? ["TiemposRegular"].join(",")
            : ["Almarai-bold"].join(","),
        fontSize: 25,
      },
      h5: {
        fontFamily:
          i18n.language == "en"
            ? ["TiemposRegular"].join(",")
            : ["Almarai-bold"].join(","),
        fontSize: 20,
      },
      h6: {
        fontFamily:
          i18n.language == "en"
            ? ["TiemposRegular"].join(",")
            : ["Almarai-bold"].join(","),
        fontSize: 15,
      },
    },
  });

  const [publicIP, setPublicIP] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetch("https://api.ipify.org?format=json")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setIsLoading(false);
        setPublicIP(data.ip);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });
  }, []);

  if (isLoading) {
    return (
      <Stack height={"100vh"} alignItems={"center"} justifyContent={"center"}>
        <ThemeProvider theme={theme}>
          <CircularProgress size={50} />
        </ThemeProvider>
      </Stack>
    );
  }
  if (publicIP && !isLoading && publicIP !== "91.144.22.34") {
    if (window.location.pathname === "/") {
      return (
        <ThemeProvider theme={theme}>
          <UnderMaintenance />;
        </ThemeProvider>
      );
    } else {
      return (
        <CacheProvider value={cacheRtl}>
          <ThemeProvider theme={theme}>
            {publicIP !== "" && (
              <div
                style={{
                  minHeight: "100vh",
                  display: "flex",
                  flexDirection: "column",
                  textAlign: i18n.language == "en" ? "left" : "right",
                }}
                dir={i18n.language == "en" ? "ltr" : "rtl"}
              >
                <ScrollToTop /> {/* on navigation */}
                <LoadingLinearProgress />
                <Notification />
                <Header />
                <div style={{ flex: 1 }}>
                  <AppErrorBoundary>
                    <SpecialRouter />
                  </AppErrorBoundary>
                  {/* <UnderMaintenance /> */}
                </div>
                <Footer />
              </div>
            )}
          </ThemeProvider>
        </CacheProvider>
      );
    }
  }

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        {publicIP !== "" && (
          <div
            style={{
              minHeight: "100vh",
              display: "flex",
              flexDirection: "column",
              textAlign: i18n.language == "en" ? "left" : "right",
            }}
            dir={i18n.language == "en" ? "ltr" : "rtl"}
          >
            <ScrollToTop /> {/* on navigation */}
            <LoadingLinearProgress />
            <Notification />
            <Header />
            <div style={{ flex: 1 }}>
              <AppErrorBoundary>
                <Router />
              </AppErrorBoundary>
              {/* <UnderMaintenance /> */}
            </div>
            <Footer />
          </div>
        )}
      </ThemeProvider>
    </CacheProvider>
  );
};

export default App;
