import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAddToCart, useRemoveFromCart } from "../../../hooks/useEditCart";
import {
  useAddToWishlist,
  useRemoveFromWishlist,
} from "../../../hooks/useEditWishlist";
import i18n from "i18next";
import { Box, CircularProgress, Dialog, Typography } from "@mui/material";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchApi } from "../../../api/api";
import { toast } from "react-toastify";
import { PaymentMethod } from "../../Cart/BuyNow/payment-method";
import { BsCart2 } from "react-icons/bs";
import { MdOutlineFavoriteBorder } from "react-icons/md";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useAtom, useAtomValue } from "jotai";
import { tokenAtom, userAtom } from "../../../store";
import i18next from "i18next";

interface Props {
  id: number;
  refetch: Function;
  inCart: boolean;
  inWishlist: boolean;
  isFree: boolean;
  enrolled: boolean;
}

const buttonContentStyle = {
  fontSize: "large",
  display: "flex",
  alignItems: "center",
  gap: "10px",
  whiteSpace: "nowrap",
  width: "100%",
  justifyContent: "center",
};

const CourseButtons: React.FC<Props> = ({
  id,
  refetch,
  inCart,
  inWishlist,
  isFree,
  enrolled,
}) => {
  const [open, setOpen] = useState(false);
  const token = useAtomValue(tokenAtom);
  const queryClient = useQueryClient();
  const { isLoading: isLoadingAddToCart, mutateAsync: addToCart } =
    useAddToCart();
  const { isLoading: isLoadingRemoveFromCart, mutateAsync: removeFromCart } =
    useRemoveFromCart();
  const { isLoading: isLoadingAddToWishlist, mutateAsync: addToWishlist } =
    useAddToWishlist();
  const {
    isLoading: isLoadingRemoveFromWishlist,
    mutateAsync: removeFromWishlist,
  } = useRemoveFromWishlist();
  const { mutateAsync: BuyFreeCourse, isLoading: isLoadingBuyFreeCourse } =
    useMutation((data: any) => fetchApi("/general/payment", "POST", data), {
      onSuccess: () => {
        toast.success(
          i18n.language === "en"
            ? "course bought successfully"
            : "تم شراء الدورة بنجاح"
        );
        queryClient.invalidateQueries(["get-course-by-id"]);
        navigate(`/course-content/${id}`);
      },
      onError: () =>
        toast.error(
          i18n.language === "en"
            ? "something went wrong"
            : "حدث خطأ أثناء الطلب"
        ),
    });
  const handleBuy = async () => {
    if (isFree) {
      await BuyFreeCourse({
        id: id,
        type: "course",
        // paymentApproved: true,
        // paymentMethod: {
        //   globalPayment: {
        //     isPaypal: true,
        //   },
        // },
      });
    } else {
      setOpen(true);
    }
  };

  const handleAddToCart = async () => {
    await addToCart({
      cartable_id: id,
      quantity: 1,
      cartable_type: "course",
    });
    refetch();
  };

  const handleRemoveFromCart = async () => {
    await removeFromCart({
      cartable_id: id,
      quantity: 1,
      cartable_type: "course",
    });
    refetch();
  };

  const handleAddToWishlist = async () => {
    await addToWishlist({
      wishlistable_id: id,
      wishlistable_type: "course",
    });
    refetch();
  };

  const handleRemoveFromWishlist = async () => {
    await removeFromWishlist({
      wishlistable_id: id,
      wishlistable_type: "course",
    });
    refetch();
  };
  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  const { t } = useTranslation();
  return (
    <>
      <Box
        className="lp-course-buttons container"
        sx={{ paddingBottom: { xs: "40px", md: 0 }, paddingX: 0 }}
      >
        {!isLoadingBuyFreeCourse ? (
          !enrolled ? (
            <button
              onClick={() => {
                token ? handleBuy() : navigate("/login");
              }}
              className="home-action"
              style={{ marginTop: "20px" }}
            >
              <Typography sx={buttonContentStyle}>
                {isFree ? t("enrollFree") : t("CourseDetailsBuyCourse")}
                <AiOutlineDollarCircle />
              </Typography>
            </button>
          ) : (
            <Box
              sx={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography>
                {i18n.language === "en"
                  ? "this course is already bought "
                  : "لقد اشتريت هذه الدورة"}
              </Typography>
              <Link
                className="in__cart go-to-course-btn"
                style={{ marginTop: "20px", cursor: "pointer" }}
                to={`/course-content/${id}`}
                state={{ courseId: id }}
              >
                <Typography sx={buttonContentStyle}>
                  {i18next.language == "en"
                    ? "Go To Course"
                    : "الذهاب إلى الدورة"}
                </Typography>
              </Link>
            </Box>
          )
        ) : (
          <div
            className="d-flex justify-content-center"
            style={{ width: "150px" }}
          >
            <button className="in__cart" style={{ marginTop: "20px" }}>
              <CircularProgress sx={{ color: "#fff" }} size="1.8rem" />
            </button>
          </div>
        )}
        {isLoadingAddToCart || isLoadingRemoveFromCart ? (
          <button className="in__cart" style={{ marginTop: "20px" }}>
            <CircularProgress sx={{ color: "#fff" }} size="1.8rem" />
          </button>
        ) : !inCart && !enrolled ? (
          <button
            onClick={() => {
              token ? handleAddToCart() : navigate("/login");
            }}
            className="home-action"
            style={{ marginTop: "20px" }}
          >
            <Typography sx={buttonContentStyle}>
              {t("WishListAddToCart")}
              <BsCart2 />
            </Typography>
          </button>
        ) : (
          inCart &&
          !enrolled && (
            <button
              onClick={() => {
                token ? handleRemoveFromCart() : navigate("/login");
              }}
              className="in__cart"
              style={{ marginTop: "20px" }}
            >
              <Typography sx={buttonContentStyle}>
                {t("removeFromCart")}
                <BsCart2 />
              </Typography>
            </button>
          )
        )}
        {isLoadingAddToWishlist || isLoadingRemoveFromWishlist ? (
          <button className="in__cart" style={{ marginTop: "20px" }}>
            <CircularProgress sx={{ color: "#fff" }} size="1.8rem" />
          </button>
        ) : !inWishlist && !enrolled ? (
          <button
            onClick={() => {
              token ? handleAddToWishlist() : navigate("/login");
            }}
            className="home-action"
            style={{ marginTop: "20px", marginLeft: "5px" }}
          >
            <Typography sx={buttonContentStyle}>
              {t("WishListAddToWishlist")}
              <MdOutlineFavoriteBorder />
            </Typography>
          </button>
        ) : (
          inWishlist &&
          !enrolled && (
            <button
              onClick={() => {
                token ? handleRemoveFromWishlist() : navigate("/login");
              }}
              className="in__cart"
              style={{ marginTop: "20px", marginLeft: "5px" }}
            >
              <Typography sx={buttonContentStyle}>
                {t("removeFromWishlist")}
                <MdOutlineFavoriteBorder />
              </Typography>
            </button>
          )
        )}
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ zIndex: 9999 }}
        transitionDuration={500}
      >
        <PaymentMethod
          type={"course"}
          isTest={false}
          id={id}
          setOpen={setOpen}
        />
      </Dialog>
    </>
  );
};

export default CourseButtons;
