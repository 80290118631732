import React from "react";
import { Link } from "react-router-dom";
import { getImageFromServer } from "./../../assets/JS/helpers";
import { cartResponse } from "../../interfaces/new-api-interfaces/cart";
import { defaultTranslation } from "../../untilites/functions";
import { useTranslation } from "react-i18next";

interface Props extends cartResponse {}

const getLinkByType = (type: string) => {
  if (type === "course") return "course-details";
  if (type === "globaltest") return "quizz-details";
  if (type === "shopitem") return "shop-item";
};

const MiniCartProduct: React.FC<Props> = (Props) => {
  const { t } = useTranslation();
  return (
    <Link
      className="mini-cart-product"
      to={`/${getLinkByType(Props.cartable_type)}/${Props.cartable_id}`}
    >
      <div className="thumb">
        <img
          src={
            Props.image && Props.image.length
              ? getImageFromServer(Props.image)
              : require("../../assets/images/test-fallback.png")
          }
          alt=""
        />
      </div>
      <div className="content">
        <span className="title">{defaultTranslation(Props, "name")}</span>
        <span className="quantity">
          {Props.quantity} x{" "}
          <span className="price">{`${(
            Props.quantity * Props.price
          )?.toLocaleString()} ${t("syp")}`}</span>
        </span>
      </div>
      <button className="remove btn">
        <svg
          width="20"
          height="20"
          fill="currentColor"
          className="bi bi-x"
          viewBox="0 0 16 16"
        >
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg>
      </button>
    </Link>
  );
};

export default MiniCartProduct;
