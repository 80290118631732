import React from "react";
import { CertificateResponse } from "../../../interfaces/new-api-interfaces/certificate";
import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { getImageFromServer } from "../../../assets/JS/helpers";
import { Box, Stack } from "@mui/system";
import { defaultTranslation } from "../../../untilites/functions";
import i18next from "i18next";

const CertificateCard = ({
  item,
  setSelectedIds,
  selectedIds,
}: {
  item: CertificateResponse;
  selectedIds: any[];
  setSelectedIds: any;
}) => {
  const handleSelect = (id: number) => {
    if (!selectedIds.includes(id)) {
      setSelectedIds((prev: any) => [...prev, id]);
    } else {
      setSelectedIds((prev: any) => prev.filter((item: any) => item !== id));
    }
  };
  return (
    <Card
      sx={{
        border: selectedIds.includes(item.id) ? "2px solid #3f3a64" : "",
        borderRadius: "15px",
      }}
    >
      <CardActionArea onClick={() => handleSelect(item.id)}>
        <Box sx={{ width: "100%", height: "100%" }}>
          <CardMedia
            component="img"
            src={getImageFromServer(item.template)}
            alt={defaultTranslation(item, "description")}
          />
        </Box>
        <CardContent>
          <Stack direction="row" spacing={1}>
            <Avatar src={getImageFromServer(item.logo)} />
            <Box>
              <Typography gutterBottom variant="h6" component="div">
                {defaultTranslation(item, "donor")}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {defaultTranslation(item, "description")}
              </Typography>
            </Box>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CertificateCard;
