import { Editor } from "@tinymce/tinymce-react";

export default function TextEditor({
  value,
  setValue,
  dir = "ltr",
  height,
}: {
  value: string;
  setValue: any;
  dir?: "ltr" | "rtl";
  height?: string;
}) {
  const handleEditorChange = (content: any, editor: any) => {
    console.log(content);
    setValue(content);
  };
  return (
    <Editor
      apiKey="6ohcoh9sw1pndp954g3qenjqh8xh343podfzolra2jg8p3c3"
      init={{
        plugins: "link lists table emoticons charmap forecolor backcolor ",
        toolbar:
          "undo redo | blocks fontfamily fontsize | forecolor backcolor bold italic underline link strikethrough | image media table | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat ",
        directionality: dir,
        height: height ? height : "100%",
        branding: false,
        menubar: false,
      }}
      onEditorChange={handleEditorChange}
      value={value}
    />
  );
}
