import i18n from "i18next";

interface Props {
  arLabel: string;
  enLabel: string;
  prevValue?: string | number | undefined;
  otherOptions?: object;
  isReadOnly?: boolean;
  isRequired?: boolean;
  type?: string;
  fieldName?: string;
  classesForStyles?: string;
  handleChange: Function;
}

export const CustomTextarea: React.FC<Props> = ({
  arLabel,
  enLabel,
  prevValue,
  isReadOnly,
  fieldName,
  classesForStyles,
  handleChange,
  otherOptions,
  isRequired,
}) => {
  return (
    <div className={"col " + classesForStyles}>
      <label htmlFor={enLabel} className="form-label">
        {i18n.language === "en" ? enLabel : arLabel}
      </label>
      <textarea
        className="form-control"
        id={enLabel}
        aria-describedby={enLabel}
        onChange={(e) => handleChange(e, fieldName)}
        value={prevValue}
        disabled={isReadOnly}
        required={isRequired}
        {...otherOptions}
      />
    </div>
  );
};

export default CustomTextarea;
