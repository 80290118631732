import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation, Trans } from "react-i18next";

import { Pagination } from "@mui/material";
import { fetchApi } from "../../api/api";
import { Quizz } from "../../interfaces/api-interfaces";
import { useLocation } from "react-router-dom";
import { Skeleton } from "@mui/material";
import QuizzBox from "../../components/Test/quizz-box";
import { GridTitle } from "../../components/Common/grids-common/grid-title";
import { GridSearch } from "../../components/Common/grids-common/grid-search";
import { GridSelect } from "../../components/Common/grids-common/grid-select";
import { fetchGlobalTests } from "../../api/endpoints-functions";
import { globalTestListResponse } from "../../interfaces/new-api-interfaces/global-test";
import { defaultTranslation } from "../../untilites/functions";
import useDebounce from "../../hooks/useDebounce";
import GridFilter from "../../components/Common/grids-common/grid-filter";
import AltQuizBox from "../../components/Test/alt-quiz-box";

interface Props {}
type filterType = "-price" | "-created_at" | "price";

const GlobalTestsGrid: React.FC<Props> = ({}) => {
  const { state } = useLocation();
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const showParam = queryParams.get("show");
  //states for search and filter and sort
  const [searchValue, setSearchValue] = useState("");
  const search = useDebounce(searchValue);
  const [sortValue, setSortValue] = useState<filterType>("-created_at");
  const [priceRange, setPriceRange] = useState<number[]>([0]);
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);

  const { data, isLoading, isFetching } = useQuery(
    ["get-quizzes", search, sortValue, priceRange, selectedCategories],
    () => fetchGlobalTests(search, sortValue, priceRange, selectedCategories),
    { staleTime: 120000 }
  );

  const ITEMS_PER_PAGE: number = 9;
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(ITEMS_PER_PAGE);

  const paginatedData = data?.slice(min, max);

  const paginate = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPageNumber(page);
    setMin(ITEMS_PER_PAGE * (page - 1));
    setMax(page * ITEMS_PER_PAGE);
  };

  const filterList = [
    {
      value: "-created_at",
      title: t("CourseGridLatest"),
    },
    {
      value: "price",
      title: t("CourseGridPriceLTH"),
    },
    {
      value: "-price",
      title: t("CourseGridPriceHTL"),
    },
  ];

  const colors = ["myorange", "myred", "myyellow", "mygreen", "mypurple"];

  return (
    <div>
      <GridTitle>{t("NavHeaderQuizzesGrid")}</GridTitle>
      <div className="section section-padding-bottom">
        <div className={isLoading ? "container-lg" : "container"}>
          <div className="row justify-content-center align-items-center max-mb-20">
            <div
              className="col-sm-10 col-md-2 max-mb-10 mb-20"
              style={{ width: !isLoading && !isFetching ? "fit-content" : "" }}
            >
              {!isLoading && !isFetching ? (
                <p className="result-count">
                  <Trans i18nKey="QuizzGridSubTitle" count={data?.length}>
                    We found <span>count</span> quizzes available for you
                  </Trans>
                </p>
              ) : (
                <p className="result-count">
                  <Skeleton variant="text" height={"3rem"} width={"100%"} />
                </p>
              )}
            </div>
            <GridSearch
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
            <GridSelect
              filterValue={sortValue}
              setFilterValue={setSortValue}
              filterList={filterList}
            />
            <GridFilter
              priceRange={priceRange}
              selectedCategories={selectedCategories}
              setPriceRange={setPriceRange}
              setSelectedCategories={setSelectedCategories}
              free={showParam == "free"}
            />
          </div>

          <div
            className={
              !isLoading && !isFetching
                ? "row row-cols-lg-3 row-cols-md-2 row-cols-1 justify-content-center justify-content-md-start"
                : ""
            }
          >
            {!isLoading && !isFetching ? (
              typeof data == "object" && data && data.length ? (
                // @ts-ignore
                paginatedData?.map(
                  (quizz: globalTestListResponse, index: number) => (
                    <div
                      className="col-10 col-md-6 col-lg-4 my-2 p-3"
                      style={{ maxHeight: "350px" }}
                      key={quizz.id}
                    >
                      <>
                        <AltQuizBox key={quizz.id} {...quizz} />
                      </>
                    </div>
                  )
                )
              ) : (
                <p style={{ margin: "0 auto", fontSize: "2em" }}>
                  There are no Quizzes yet
                </p>
              )
            ) : (
              <div className="row max-mb-n30">
                {[1, 2, 3, 4, 5, 6].map((el) => (
                  <div className="col-12 col-md-6 col-lg-3 my-2 c-s" key={el}>
                    <Skeleton variant="rectangular" width={300} height={200} />
                    <div className="info">
                      <Skeleton
                        style={{ margin: "30px 0" }}
                        variant="text"
                        width="50%"
                      />
                      <Skeleton
                        style={{ margin: "30px 0" }}
                        variant="text"
                        width="70%"
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          {!isLoading && !isFetching && (
            <div>
              <>
                {data?.length! > ITEMS_PER_PAGE ? (
                  <Pagination
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "20px",
                    }}
                    count={Math.ceil(data?.length! / ITEMS_PER_PAGE) || 0}
                    page={currentPageNumber}
                    onChange={paginate}
                    variant="outlined"
                    color="primary"
                    size="large"
                    dir="ltr"
                  />
                ) : null}
              </>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GlobalTestsGrid;
