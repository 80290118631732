import React from "react";
import {
  courseUserCertificate,
  profileCertificate,
} from "../../../interfaces/new-api-interfaces/certificate";
import { getImageFromServer } from "../../../assets/JS/helpers";
import { Avatar, Box, Button, Card, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MdOutlineFileDownload } from "react-icons/md";
import { defaultTranslation } from "../../../untilites/functions";
import i18next from "i18next";
import { PiCoins } from "react-icons/pi";

interface CertificateCardProps {
  certificate: profileCertificate;
}

const ProfileCertificateItem: React.FC<CertificateCardProps> = ({
  certificate,
}) => {
  const { t } = useTranslation();

  const handleDownload = () => {
    // Check if certificationLink is available
    if (certificate.file) {
      // Create a temporary anchor element
      const link = document.createElement("a");
      link.href = getImageFromServer(certificate.file); // Set the download link
      link.target = "_blank"; // Open in another tab
      link.download = "certificate.pdf"; // Set the desired filename

      // Append the anchor element to the document
      document.body.appendChild(link);

      // Trigger the click event on the anchor element
      link.click();

      // Remove the anchor element from the document
      document.body.removeChild(link);
    }
  };

  return (
    <Card sx={{ padding: "10px", height: "100%" }}>
      <Stack
        flexDirection="column"
        gap="20px"
        alignItems="center"
        justifyContent="space-around"
      >
        {/* <img
          src={getImageFromServer(certificate.template)}
          alt="Certificate Logo"
          style={{ width: 250, height: 250, borderRadius: "10px" }}
        /> */}
        <Box>
          <Typography variant="h6" color="primary.main">
            {defaultTranslation(certificate.model, "name")}
          </Typography>
        </Box>
        <Stack className="certificate-details" flexDirection="row" gap={2}>
          <Avatar
            src={getImageFromServer(certificate.logo)}
            alt="Certificate Logo"
            sx={{ width: 40, height: 40 }}
          />
          <Box>
            <Typography variant="h6">
              {defaultTranslation(certificate.certificate, "donor")}
            </Typography>
            <Typography>
              {defaultTranslation(certificate.certificate, "description")}
            </Typography>
          </Box>
        </Stack>
        {certificate.file ? (
          <Button variant="contained" onClick={handleDownload}>
            <Stack
              flexDirection="row"
              gap={1}
              alignItems="center"
              justifyContent="center"
            >
              <Typography>{t("Download")}</Typography>
              <MdOutlineFileDownload fontSize={20} />
            </Stack>
          </Button>
        ) : (
          <Button variant="contained" color="mypurple2" onClick={() => {}}>
            <Stack
              flexDirection="row"
              gap={1}
              alignItems="center"
              justifyContent="center"
            >
              <Typography>
                {i18next.language === "en"
                  ? "Pay certificate fees"
                  : "دفع رسوم الشهادة"}
              </Typography>
              <PiCoins fontSize={20} />
            </Stack>
          </Button>
        )}
      </Stack>
    </Card>
  );
};

export default ProfileCertificateItem;
