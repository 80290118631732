import { useMutation, useQuery } from "@tanstack/react-query";
import { useAtomValue, useSetAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { fetchApi } from "../../api/api";
import "../Auth/auth.style.css";
import { userAtom, userIdAtom } from "../../store";
import { blobToBase64, handleFormError } from "../../untilites/functions";
import { CustomImageInput } from "../../components/helpers";
import i18n from "i18next";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { editProfile } from "../../interfaces/new-api-interfaces/auth";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import fallback from "../../assets/images/user-holder.png";
import bg from "../../assets/images/profile/profile-bg.jpg";
import InstructorEditFields from "../../components/Profile/profile-components/instructor-edit-fields";
import { MuiPhone } from "../../components/Common/mui-phone";
import i18next from "i18next";
import CustomTextField from "../../components/Common/custome-text-field";

interface Props {}

const inputStyle = {
  width: { xs: "100%", sm: "45%" },
  marginBottom: "30px",
};

const EditProfile: React.FC<Props> = () => {
  const {
    data: profile,
    isError,
    isLoading: profileLoading,
  } = useQuery(
    ["get-my-profile"],
    () => fetchApi("/auth/user/showMyProfile", "GET", {}),
    {
      refetchOnWindowFocus: false, // Disable refetch on focus
      refetchOnReconnect: false,
    }
  );

  const user = useAtomValue(userAtom);

  const { isLoading, mutateAsync } = useMutation(
    (data: any) => fetchApi(`/auth/user/${user?.id}`, "PUT", data),
    {
      onError: (error: any) => {
        if (error?.response?.status === 422) {
          handleFormError(error.response, setError, toast, i18next);
        }
      },
    }
  );

  const { t } = useTranslation();
  const navigate = useNavigate();
  const setUserId = useSetAtom(userIdAtom);
  const setUser = useSetAtom(userAtom);
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<editProfile>({
    defaultValues: {},
  });

  const [oldImage, setOldImage] = useState("");

  useEffect(() => {
    if (profile) {
      const { data } = profile.data;
      const {
        password,
        is_approved,
        is_blocked,
        is_reviewed,
        block_massage,
        ...profileDataWithoutPassword
      } = data;
      setOldImage(profileDataWithoutPassword.profile_image);

      if (data.role == "instructor") {
        reset({
          ...profileDataWithoutPassword,
          first_name: data.translations.ar.first_name || "",
          last_name: data.translations.ar.last_name || "",
          first_name_en: data.translations.en?.first_name || "",
          last_name_en: data.translations.en?.last_name || "",
          bio: data.instructors_translations?.ar?.bio || "",
          bio_en: data.instructors_translations?.en?.bio || "",
          education: data.instructors_translations?.ar?.education || "",
          education_en: data.instructors_translations?.en?.education || "",
        });
      } else {
        reset({
          ...profileDataWithoutPassword,
          first_name: data.translations.ar.first_name || "",
          last_name: data.translations.ar.last_name || "",
          first_name_en: data.translations.en?.first_name || "",
          last_name_en: data.translations.en?.last_name || "",
        });
      }
    }
  }, [profile, reset]);

  const onSubmit: SubmitHandler<editProfile> = async (data) => {
    let newData = { ...data }; // Create a shallow copy of the data object
    if (oldImage == data.profile_image) {
      delete newData.profile_image; // Remove the profile_image key from newData if its not changed
    }
    delete newData?.block_message;
    try {
      mutateAsync(newData).then((response) => {
        if (response.status === 200 || response.status === 202) {
          const { data } = response.data;
          const {
            role,
            id,
            first_name,
            last_name,
            email,
            phone_number,
            address,
            birth_date,
            profile_image,
          } = data;
          setUserId(id);
          setUser({
            id,
            firstName: first_name,
            lastName: last_name,
            email,
            phoneNumber: phone_number,
            address,
            age: birth_date,
            role,
            profileImage: profile_image,
            firstNameEn: data?.translations[1].first_name,
            lastNameEn: data?.translations[1].last_name,
          });

          if (role == "instructor") {
            navigate("/profile", { replace: true });
          } else {
            navigate("/student-profile", { replace: true });
          }
          toast.success(
            i18n.language === "en"
              ? "profile Edited successfully"
              : "تم تعديل الحساب بنجاح"
          );
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const [localError, setLocalError] = useState(null); //to handle file uploading errors

  if (localError)
    toast.error(
      i18n.language === "en"
        ? "something went wrong uploading the picture"
        : "حصل خطأأثناء رفع الصورة"
    );
  return (
    <Box
      sx={{
        backgroundImage: `url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPositionX: "right",
      }}
      style={{ backgroundPositionX: "right" }}
    >
      <div
        style={{
          direction: `${i18n.language === "en" ? "ltr" : "rtl"}`,
          padding: "10vh 0",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            textAlign: "center",
            paddingBottom: { xs: "100px", md: "150px" },
          }}
          color="primary.main"
          style={{ direction: `${i18n.language === "en" ? "ltr" : "rtl"}` }}
        >
          {t("UpdateProfile")}
        </Typography>
        <Box
          className="login-form-wrapper mt-sm-50 mt-xs-50"
          sx={{
            direction: `${i18n.language === "en" ? "ltr" : "rtl"}`,
            width: { xs: "90%", md: "70%" },
          }}
        >
          <form
            style={{
              direction: `${i18n.language === "en" ? "ltr" : "rtl"}`,
              position: "relative",
              paddingTop: "150px",
              gap: "2%",
            }}
            className="register-form d-flex flex-wrap justify-content-center"
            onSubmit={handleSubmit(onSubmit)}
          >
            {!isLoading && !profileLoading && (
              <>
                <Box sx={{ position: "absolute", top: "-130px" }}>
                  <Controller
                    name="profile_image"
                    control={control}
                    render={({ field }) => (
                      <CustomImageInput
                        file={field.value}
                        handleChange={(e: any) => {
                          blobToBase64(e)
                            .then((value) => {
                              field.onChange(value); // Update the field value with the base64 string
                            })
                            .catch((err) => {
                              console.log(err);
                              setLocalError(err);
                            });
                        }}
                        fieldName={field.name}
                        classesForStyles="pb-5"
                        fallback={fallback}
                      />
                    )}
                  />
                </Box>

                <CustomTextField
                  name="first_name"
                  control={control}
                  defaultValue=""
                  label={t("GlobalFirstName")}
                  placeholder={t("GlobalFirstName")}
                  required
                  errors={errors}
                  inputStyle={inputStyle}
                />

                <CustomTextField
                  name="first_name_en"
                  control={control}
                  defaultValue=""
                  label={t("GlobalFirstNameEnglish")}
                  placeholder={t("GlobalFirstNameEnglish")}
                  required
                  errors={errors}
                  inputStyle={inputStyle}
                />

                <CustomTextField
                  name="last_name"
                  control={control}
                  defaultValue=""
                  label={t("GlobalLastName")}
                  placeholder={t("GlobalLastName")}
                  required
                  inputStyle={inputStyle}
                  errors={errors}
                />

                <CustomTextField
                  name="last_name_en"
                  control={control}
                  defaultValue=""
                  label={t("GlobalLastNameEnglish")}
                  placeholder={t("GlobalLastNameEnglish")}
                  required
                  inputStyle={inputStyle}
                  errors={errors}
                />

                <CustomTextField
                  name="email"
                  control={control}
                  defaultValue=""
                  label={i18n.language === "en" ? "Email" : "البريد الإلكتروني"}
                  placeholder={
                    i18n.language === "en" ? "Email" : "البريد الإلكتروني"
                  }
                  required
                  inputStyle={inputStyle}
                  errors={errors}
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message:
                        i18n.language === "en"
                          ? "Invalid email address"
                          : "البريد الإلكتروني غير صالح",
                    },
                  }}
                />

                <Controller
                  name="phone_number"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <MuiPhone
                      onChange={setValue}
                      value={field.value}
                      controle_name="phone_number"
                      label={
                        i18n.language === "en" ? "Phone Number" : "رقم الهاتف"
                      }
                      sx={inputStyle}
                      forceDialCode={true}
                      setError={setError}
                      error={!!errors.phone_number}
                      helperText={errors.phone_number?.message}
                      clearError={() => clearErrors("phone_number")}
                      setIsPhoneValid={setIsPhoneValid}
                    />
                  )}
                />

                <CustomTextField
                  name="address"
                  control={control}
                  defaultValue=""
                  label={t("GlobalAddress")}
                  placeholder={t("GlobalAddress")}
                  required
                  inputStyle={{
                    width: { xs: "100%", sm: "92%" },
                    marginBottom: "30px",
                  }}
                  errors={errors}
                />
                <CustomTextField
                  name="birth_date"
                  defaultValue={new Date()}
                  control={control}
                  label={t("birthDate")}
                  type="date"
                  placeholder={t("birthDate")}
                  required
                  inputStyle={inputStyle}
                  errors={errors}
                  inputProps={{
                    style: {
                      textAlign: i18next.language == "en" ? "left" : "right",
                    },
                  }}
                />
                <Controller
                  name="gender"
                  control={control}
                  defaultValue="male"
                  render={({ field }) => (
                    <FormControl sx={inputStyle}>
                      <InputLabel htmlFor="gender" id="gender-select-label">
                        {t("Gender")}
                      </InputLabel>
                      <Select
                        labelId="gender-select-label"
                        label={t("Gender")}
                        {...field}
                        MenuProps={{ disableScrollLock: true }}
                        inputProps={{
                          id: "gender",
                        }}
                      >
                        <MenuItem value="male">{t("Male")}</MenuItem>
                        <MenuItem value="female">{t("Female")}</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
                {user?.role == "instructor" && (
                  <InstructorEditFields control={control} />
                )}

                <Box
                  className="single-input"
                  sx={{
                    direction: `${i18n.language === "en" ? "ltr" : "rtl"}`,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: { xs: "100%", sm: "92%", lg: "30%" },
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className="mb-4 btn-hover-secondary btn-width-100"
                    type="submit"
                    disabled={isPhoneValid == false}
                  >
                    <Typography fontSize="large" color="#fff">
                      {t("UpdateProfile")}
                    </Typography>
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className="mb-2 btn-hover-secondary btn-width-100"
                    onClick={() => {
                      navigate("/edit/reset");
                    }}
                  >
                    <Typography fontSize="large" color="#fff">
                      {t("ResetPassword")}
                    </Typography>
                  </Button>
                </Box>
              </>
            )}
            {(isLoading || profileLoading) && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  minHeight: "500px",
                  paddingBottom: "150px",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </form>
        </Box>
      </div>
    </Box>
  );
};

export default EditProfile;
