import React from "react";
import FunFact from "../home-page-components/funfact";
import { useQuery } from "@tanstack/react-query";

import { Skeleton } from "@mui/material";
import { fetchStatistics } from "../../../api/endpoints-functions";
import { defaultTranslation } from "../../../untilites/functions";

interface Props {}

const Testimonials: React.FC<Props> = () => {
  const { isLoading, data } = useQuery(["statistics"], () => fetchStatistics());
  return (
    <div
      style={{ backgroundColor: "#f8f8f8" }}
      className="section bg-pattern fix"
      data-aos="fade-up"
    >
      <div className="container" style={{ padding: "70px 0px" }}>
        <div className="row">
          <div className="col-lg-12 mx-auto">
            <div
              className="row row-cols-md-4 row-cols-1 max-mb-n30"
              style={{ justifyContent: "center" }}
            >
              {!isLoading ? (
                data?.map((item: any) => (
                  <FunFact
                    key={item.id}
                    counter={item.value}
                    text={defaultTranslation(item, "title")}
                  />
                ))
              ) : (
                <>
                  <Skeleton variant="rectangular" width={260} height={150} />
                  <Skeleton variant="rectangular" width={260} height={150} />
                  <Skeleton variant="rectangular" width={260} height={150} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
