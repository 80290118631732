import React from "react";
import {
  studentCorrectedTestQuestion,
  testToCorrect,
  testToCorrectQuestion,
} from "../../interfaces/new-api-interfaces/test";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { defaultTranslation } from "../../untilites/functions";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

interface Props {
  question: studentCorrectedTestQuestion;
  isGraded?: 0 | 1 | boolean;
}
const DisplayOptionalAnswers: React.FC<Props> = ({ question, isGraded }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ padding: "20px", bgcolor: "#FBFBFB" }}>
      <Stack width={"90%"} direction={"row"} justifyContent={"space-between"}>
        <Typography variant="h6">
          {defaultTranslation(question, "name")}
        </Typography>
        {isGraded == 1 && (
          <Typography variant="h6" color="mypurple2.main">
            {question.mark} {t("Mark")}
          </Typography>
        )}
      </Stack>
      <FormControl component="fieldset" required sx={{ width: "90%" }}>
        <RadioGroup>
          {question.options.map((option) => (
            <>
              <FormControlLabel
                key={option.id}
                value={option.id.toString()}
                control={
                  <Radio
                    disabled={true}
                    checked={option.is_user_answer == true}
                  />
                }
                label={defaultTranslation(option, "value")}
                sx={{
                  border: "solid 1px",
                  padding: "10px",
                  width: "100%",
                  marginY: "10px",
                  marginX: "0px",
                  borderRadius: "5px",
                  borderColor:
                    isGraded == 0
                      ? ""
                      : option.is_user_answer == true && option.is_true == true
                      ? "mygreen.main"
                      : option.is_user_answer == true && option.is_true == false
                      ? "myred.main"
                      : "#ccc",
                }}
              />
              {option.is_user_answer == true &&
                option.is_true == true &&
                isGraded != 0 && (
                  <Box>
                    <Typography color="green">
                      {i18next.language == "en"
                        ? "The Answer is Correct"
                        : "الإجابة صحيحة"}
                    </Typography>
                  </Box>
                )}
              {option.is_user_answer == true &&
                option.is_true == false &&
                isGraded != 0 && (
                  <Box>
                    <Typography color="red">
                      {i18next.language == "en"
                        ? "The Answer is Wrong"
                        : "الإجابة خاطئة"}
                    </Typography>
                  </Box>
                )}
            </>
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default DisplayOptionalAnswers;
