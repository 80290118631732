import { useState } from "react";
import { HandleStoreBlobAndBase64 } from "../../../untilites/functions";
import { CustomButton, CustomImageInput, CustomInput } from "../../helpers";
import CustomPhoneInput from "../../helpers/custom-phone-input";
import i18n from "i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../api/api";
import {LoadingPage} from "../../Common/loadingPage.component";
import { toast } from "react-toastify";
import {ThankYouModal} from "./thank-you";

interface Props {}

export const LocalPayment: React.FC<Props> = () => {
  const [form, setForm] = useState({
    companyName: "",
    phoneNumber: "",
    idNumber: "",
    proofImage: "",
  });
  const [thankYouModal, setThankYouModal] = useState(false);
  const { data: cart } = useQuery(["get-cart"], () =>
    fetchApi("/cart", "GET", {})
  );
  const { mutate, isLoading, isError } = useMutation(
    (data: any) => fetchApi("/orders", "POST", data),
    {
      onSuccess: () => {
        toast.success(
          i18n.language === "en" ? "oredered successfully" : "تم الطلب بنجاح"
        );
        setThankYouModal(true);
      },
      onError: () =>
        toast.error(
          i18n.language === "en"
            ? "something went wrong"
            : "حدث خطأ أثناء الطلب"
        ),
    }
  );
  const [localError, setLocalError] = useState(null); //to handle file updloading errors
  const handleChange = (e: any, fieldName: string) => {
    setForm((prev: any) => ({ ...prev, [fieldName]: e.target?.value }));
  };
  const handleSubmit = () => {
    mutate({
      // cartItems: cart?.data.map((item: any) => item.id),
      // paymentMethod: {
      //   globalPayment: {
      //     isPaypal: false,
      //     transferPayment: form,
      //   },
      // },
      paymentApproved: true,
      paymentMethod: {
        globalPayment: {
          isPaypal: true,
        },
      },
    });
  };
  if (isLoading) return <LoadingPage />;
  return (
    <form
      className="d-flex flex-wrap container"
      style={{ width: "100%" }}
      onSubmit={(e) => e.preventDefault()}
    >
      <ThankYouModal isOpen={thankYouModal} setIsOpen={setThankYouModal} />
      <CustomInput
        enLabel="company name"
        arLabel="اسم الشركة"
        handleChange={handleChange}
        fieldName="companyName"
        prevValue={form["companyName"]}
        classesForStyles="my-2 col-12"
      />
      <div className="mb-30 col-6 col-sm-12">
        <CustomPhoneInput
          enLabel="Phone Number"
          arLabel="رقم الهاتف"
          handleChange={(e: any) =>
            handleChange({ target: { value: e } }, "phoneNumber")
          }
          prevValue={form["phoneNumber"]}
          fieldName="phoneNumber"
        />
      </div>
      <CustomInput
        enLabel="ID number"
        arLabel="رقم الوصل"
        handleChange={handleChange}
        fieldName="idNumber"
        prevValue={form["idNumber"]}
        classesForStyles="my-2 col-12"
      />
      <div className="col-12">
        <label className="form-label">
          {i18n.language === "en" ? "Proof Image" : "صورة الإثبات"}
        </label>
        <CustomImageInput
          fieldName=""
          handleChange={(e: any) =>
            HandleStoreBlobAndBase64(
              e,
              handleChange,
              setLocalError,
              "proofImage"
            )
          }
          isCover
          classesForStyles="my-2 col-12"
        />
      </div>
      <div className="col-12 d-flex justify-content-center">
        <CustomButton
          arLabel="اطلب الآن"
          enLabel="Order Now"
          handlePress={() => handleSubmit()}
          disabled={
            form.companyName.length === 0 ||
            form.idNumber.length === 0 ||
            form.phoneNumber.length === 0 ||
            form.proofImage.length === 0
          }
        />
      </div>
    </form>
  );
};
