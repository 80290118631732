import { useId } from "react";
import i18n from 'i18next';

interface Props {
  arLabel: string;
  enLabel: string;
  prevValue?: any;
  otherOptions?: object;
  isReadOnly?: boolean;
  type?: string;
  fieldName?: string;
  classesForStyles?: string;
  handleChange: Function;
  stylesObject?: Object;
  isRequired?: boolean
}
export const CustomInput: React.FC<Props> = ({
  arLabel,
  enLabel,
  prevValue,
  isReadOnly,
  type,
  fieldName,
  classesForStyles,
  handleChange,
  otherOptions,
  stylesObject,
  isRequired
}) => {
  const id = useId();
  return (
    <div className={"col " + classesForStyles} style={stylesObject || {}}>
      <label htmlFor={id} className="form-label">
        {i18n.language === "en" ? enLabel : arLabel}
      </label>
      <input
        className="form-control"
        type={type ? type : "text"}
        id={id}
        aria-describedby={enLabel}
        onChange={(e) => handleChange(e, fieldName)}
        value={prevValue}
        disabled={isReadOnly}
        readOnly={isReadOnly}
        required={isRequired}
        {...otherOptions}
      />
    </div>
  );
};

export default CustomInput;