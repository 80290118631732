import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  products: { title: string; price: number; quantity: number }[];
}

export const CartTotal: React.FC<Props> = (Props) => {
  const { t } = useTranslation();

  return (
    <div className="col-12 max-mb-60">
      <h4 className="checkout-title">{t("GlobalTotal")}</h4>
      <div className="checkout-cart-total">
        <h4 style={{ padding: "0 10px" }}>
          {t("GlobalProduct")} <span>{t("GlobalTotal")}</span>
        </h4>
        <ul
          style={{ maxHeight: "30vh", overflowY: "scroll", padding: "0 10px" }}
          className="recent__courses__details_sidebar"
        >
          {React.Children.toArray(
            Props.products.map(({ price, title, quantity }) => (
              <li>
                {title}
                <span>{`${(price * quantity)?.toLocaleString()} ${t(
                  "syp"
                )}`}</span>
              </li>
            ))
          )}
        </ul>
        <h4>
          {t("GlobalTotal")}{" "}
          <span>
            {`${Props.products
              .reduce(
                (acc, product) => acc + product.quantity * product.price,
                0
              )
              ?.toLocaleString()} ${t("syp")}`}
          </span>
        </h4>
      </div>
    </div>
  );
};
