import { TextField } from "@mui/material";
import React from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { editProfile } from "../../../interfaces/new-api-interfaces/auth";

const inputStyle = {
  width: { xs: "100%", sm: "45%" },
  marginBottom: "30px",
};

type Props = {
  control: Control<editProfile>;
};

const InstructorEditFields = (props: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Controller
        name="education"
        control={props.control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            label={t("educationAr")}
            placeholder={t("educationAr")}
            required
            sx={inputStyle}
          />
        )}
      />
      <Controller
        name="education_en"
        control={props.control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            label={t("educationEn")}
            placeholder={t("educationEn")}
            required
            sx={inputStyle}
          />
        )}
      />
      <Controller
        name="bio"
        control={props.control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            sx={inputStyle}
            {...field}
            label={t("bioAr")}
            placeholder={t("bioAr")}
            required
          />
        )}
      />
      <Controller
        name="bio_en"
        control={props.control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            sx={inputStyle}
            {...field}
            label={t("bioEn")}
            placeholder={t("bioEn")}
            required
          />
        )}
      />
    </>
  );
};

export default InstructorEditFields;
