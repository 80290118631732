import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAddToCart, useRemoveFromCart } from "../../hooks/useEditCart";
import {
  useAddToWishlist,
  useRemoveFromWishlist,
} from "../../hooks/useEditWishlist";
import { SpinnerLoadingIcon, JumpingPoints } from "../Common/loading-icon";
import i18n from "i18next";
import { Box, CircularProgress, Dialog, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchApi } from "../../api/api";
import { toast } from "react-toastify";
import { PaymentMethod } from "../Cart/BuyNow/payment-method";
import { useNavigate } from "react-router-dom";
import { MyShopItem } from "../../interfaces/api-interfaces";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { BsCart2 } from "react-icons/bs";
import { BsDownload } from "react-icons/bs";
import { useAtomValue } from "jotai";
import { tokenAtom } from "../../store";

interface Props {
  id: number;
  refetch: Function;
  inCart: boolean;
  inWishlist: boolean;
  isFree: boolean;
  isBought: boolean;
  file: string;
  handleDownload: Function;
  isPhysical: 0 | 1;
}

const buttonContentStyle = {
  fontSize: "medium",
  display: "flex",
  alignItems: "center",
  gap: "10px",
  whiteSpace: "nowrap",
  width: "100%",
  justifyContent: "center",
};

const ShopButtons: React.FC<Props> = ({
  id,
  refetch,
  inCart,
  inWishlist,
  isFree,
  isBought,
  file,
  handleDownload,
  isPhysical,
}) => {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutateAsync: postToCart, isLoading: cartLoading } = useAddToCart();
  const { mutateAsync: postRemoveFromCart, isLoading: cartRemoveLoading } =
    useRemoveFromCart();
  const { mutateAsync: postAddToWishlist, isLoading: loadingWishlist } =
    useAddToWishlist();
  const { mutateAsync: postRemoveFromWishlist, isLoading: loadingWishlist1 } =
    useRemoveFromWishlist();

  const { mutateAsync: BuyFreeItem, isLoading: isLoadingBuyFreeItem } =
    useMutation((data: any) => fetchApi("/general/payment", "POST", data), {
      onSuccess: () => {
        toast.success(
          i18n.language === "en"
            ? "item bought successfully"
            : "تم شراء العنصر بنجاح"
        );
        queryClient.invalidateQueries(["get-shop-item", id]);
      },
      onError: () =>
        toast.error(
          i18n.language === "en"
            ? "something went wrong"
            : "حدث خطأ أثناء الطلب"
        ),
    });

  const addToCart = async (id: number) => {
    await postToCart({
      cartable_id: id,
      cartable_type: "shopitem",
      quantity: 1,
    });
    if (refetch) refetch();
  };

  const removeFromCart = async (id: number) => {
    await postRemoveFromCart({
      cartable_id: id,
      cartable_type: "shopitem",
      quantity: 1,
    });
    if (refetch) refetch();
  };
  const addToWishlist = async (id: number) => {
    await postAddToWishlist({
      wishlistable_id: id,
      wishlistable_type: "shopitem",
    });
    if (refetch) refetch();
  };
  const removeFromWishlist = async (id: number) => {
    await postRemoveFromWishlist({
      wishlistable_id: id,
      wishlistable_type: "shopitem",
    });
    if (refetch) refetch();
  };

  const handleBuy = async () => {
    if (isFree) {
      await BuyFreeItem({
        id: id,
        type: "shopitem",
        // paymentApproved: true,
        // paymentMethod: {
        //   globalPayment: {
        //     isPaypal: true,
        //   },
        // },
      });
      queryClient.invalidateQueries(["get-shop-item", id]);
      queryClient.invalidateQueries(["get-shop-item"]);
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { t } = useTranslation();
  const token = useAtomValue(tokenAtom);

  return (
    <>
      <Box
        className="d-flex justify-content-start align-items-center pb-3"
        sx={{ width: { xs: "100%" }, gap: "20px", flexWrap: "wrap-reverse" }}
        onClick={(e) => e.preventDefault()}
      >
        <>
          {!isLoadingBuyFreeItem ? (
            !isBought ? (
              <button
                onClick={() => {
                  token ? handleBuy() : navigate("/login");
                }}
                className="home-action"
              >
                <Typography sx={buttonContentStyle}>
                  {t("BuyItem")}
                  <AiOutlineDollarCircle />
                </Typography>
              </button>
            ) : (
              <p className="in__cart" style={{ width: "fit-content" }}>
                {i18n.language === "en" ? "already bought " : "تم شراءه"}
              </p>
            )
          ) : (
            <div
              className="d-flex justify-content-center"
              style={{ width: "150px" }}
            >
              <SpinnerLoadingIcon />
            </div>
          )}
        </>
        {!isBought ? (
          <>
            {cartLoading || cartRemoveLoading ? (
              <JumpingPoints />
            ) : !inCart ? (
              <button
                onClick={() => {
                  token ? addToCart(id) : navigate("/login");
                }}
                className="home-action"
              >
                <Typography sx={buttonContentStyle}>
                  {t("WishListAddToCart")}
                  <BsCart2 />
                </Typography>
              </button>
            ) : (
              <button
                onClick={() => {
                  token ? removeFromCart(id) : navigate("/login");
                }}
                className="in__cart"
              >
                <Typography sx={buttonContentStyle}>
                  {t("removeFromCart")}
                  <BsCart2 />
                </Typography>
              </button>
            )}
          </>
        ) : !isPhysical ? (
          <>
            <button
              onClick={() => {
                handleDownload(file);
              }}
              className="home-action"
            >
              <Typography sx={buttonContentStyle}>
                {i18n.language === "en" ? "Download" : "تنزيل"}
                <BsDownload />
              </Typography>
            </button>
          </>
        ) : (
          <></>
        )}
        {!isBought && (
          <div
            style={{ width: "50px" }}
            className="d-flex justify-content-center align-items-center"
          >
            {loadingWishlist || loadingWishlist1 ? (
              <div className="shop__wishlist__icon__loader">
                <CircularProgress size={24} />
              </div>
            ) : inWishlist ? (
              <span role="button">
                <svg
                  width="36"
                  height="36"
                  fill="#ff0000"
                  className="bi bi-suit-heart-fill"
                  viewBox="0 0 16 16"
                  onClick={() => {
                    token ? removeFromWishlist(id) : navigate("/login");
                  }}
                >
                  <path d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                </svg>
              </span>
            ) : (
              <span role="button">
                <svg
                  width="36"
                  height="36"
                  fill="#000"
                  className="bi bi-suit-heart"
                  viewBox="0 0 16 16"
                  onClick={() => {
                    token ? addToWishlist(id) : navigate("/login");
                  }}
                >
                  <path d="m8 6.236-.894-1.789c-.222-.443-.607-1.08-1.152-1.595C5.418 2.345 4.776 2 4 2 2.324 2 1 3.326 1 4.92c0 1.211.554 2.066 1.868 3.37.337.334.721.695 1.146 1.093C5.122 10.423 6.5 11.717 8 13.447c1.5-1.73 2.878-3.024 3.986-4.064.425-.398.81-.76 1.146-1.093C14.446 6.986 15 6.131 15 4.92 15 3.326 13.676 2 12 2c-.777 0-1.418.345-1.954.852-.545.515-.93 1.152-1.152 1.595L8 6.236zm.392 8.292a.513.513 0 0 1-.784 0c-1.601-1.902-3.05-3.262-4.243-4.381C1.3 8.208 0 6.989 0 4.92 0 2.755 1.79 1 4 1c1.6 0 2.719 1.05 3.404 2.008.26.365.458.716.596.992a7.55 7.55 0 0 1 .596-.992C9.281 2.049 10.4 1 12 1c2.21 0 4 1.755 4 3.92 0 2.069-1.3 3.288-3.365 5.227-1.193 1.12-2.642 2.48-4.243 4.38z" />
                </svg>
              </span>
            )}
          </div>
        )}
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ zIndex: 9999 }}
        transitionDuration={500}
      >
        <PaymentMethod type="shopitem" id={id} setOpen={setOpen} />
      </Dialog>
    </>
  );
};

export default ShopButtons;
