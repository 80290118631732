import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  TextField,
  InputAdornment,
  TableSortLabel,
  TablePagination,
} from "@mui/material";
import { studentToCorrectInfo } from "../../../interfaces/new-api-interfaces/test";
import { defaultTranslation } from "../../../untilites/functions";
import { BsSearch } from "react-icons/bs";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import StudentToCorrectTableItem from "./student-to-correct-table-item";
import LoadingTable from "../../Common/loading-table";

interface Props {
  students: studentToCorrectInfo[];
  testId: string;
  isRelatedToCourse?: boolean;
  isLoading: boolean;
  isFetching: boolean;
}

const StudentstudentsToCorrectTable: React.FC<Props> = ({
  students,
  testId,
  isRelatedToCourse,
  isLoading,
  isFetching,
}) => {
  const navigate = useNavigate();
  const { id: studentId } = useParams();
  const [search, setSearch] = useState("");
  const { t } = useTranslation();
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const searchHandler = (name: string) => {
    return name.toLowerCase().includes(search.toLowerCase());
  };

  const filteredData =
    students && students.length > 0
      ? students.filter((item: studentToCorrectInfo) => {
          return searchHandler(defaultTranslation(item, "first_name"));
        })
      : [];

  const sortedData = filteredData?.sort((a: any, b: any) => {
    if (orderBy === "studentsCount") {
      return order === "asc"
        ? a.studentsCount - b.studentsCount
        : b.studentsCount - a.studentsCount;
    } else if (orderBy === "createdAt") {
      return order === "asc"
        ? a.createdAt - b.createdAt
        : b.createdAt - a.createdAt;
    }
    return 0;
  });

  const paginatedData = sortedData?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      <div style={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "0.5rem",
            flexWrap: "wrap",
            marginBottom: "1rem",
          }}
        >
          <Box className="search-corrected">
            <TextField
              name="search"
              placeholder="search by name"
              variant="outlined"
              onChange={(event) => {
                setSearch(event.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <BsSearch className="pl-1" fontSize="large" />
                  </InputAdornment>
                ),
                sx: { borderRadius: "30px", padding: "0.8rem" },
              }}
            />
          </Box>
        </Box>
        {students && students.length > 0 ? (
          <div>
            <LoadingTable
              isFetching={isFetching}
              isLoading={isLoading}
              columns={3}
            >
              <TableContainer component={Paper}>
                <Table>
                  <TableHead sx={{ bgcolor: "mypurple2.main" }}>
                    <TableRow>
                      <TableCell
                        sx={{ borderTopLeftRadius: "5px", width: "25%" }}
                      >
                        <Typography fontSize="large" color="#fff">
                          {t("GlobalImage")}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ width: "25%" }}>
                        <TableSortLabel
                          active={orderBy === "firstName"}
                          direction={orderBy === "firstName" ? order : "asc"}
                          onClick={() => handleSort("firstName")}
                        >
                          <Typography fontSize="large" color="#fff">
                            {t("firstName")}
                          </Typography>
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sx={{ borderTopRightRadius: "5px", width: "25%" }}
                      >
                        <Typography fontSize="large" color="#fff">
                          {t("lastName")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedData?.map(
                      (item: studentToCorrectInfo, index: number) => {
                        return (
                          <StudentToCorrectTableItem
                            student={item}
                            index={index}
                            testId={testId!}
                            isRelatedToCourse={isRelatedToCourse}
                          />
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </LoadingTable>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={sortedData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              dir="ltr"
            />
          </div>
        ) : (
          <div className="row">
            <div className="col-12">
              <div className="learn-press-message success">
                <i className="bx bx-info-circle"></i>
                {i18next.language === "en"
                  ? "There are no test to correct yet"
                  : "لا يوجد اختبارات لتصحيحها حالياً!"}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default StudentstudentsToCorrectTable;
