import Results from "../profile-components/results";
import ProfileCourses from "../profile-components/courses-tap";
import Quizzes from "../profile-components/quizzes";
import { useTranslation } from "react-i18next";

import { LoadingPage } from "../../Common/loadingPage.component";
import { useAtomValue } from "jotai";
import { instructorIdAtom } from "../../../store";
import {
  profileCoursesResponse,
  testCorrectionResponse,
} from "../../../interfaces/new-api-interfaces/profile";
import { GlobalTestResponse } from "../../../interfaces/new-api-interfaces/test";
import { BlogResponse } from "../../../interfaces/new-api-interfaces/blog";
import Blogs from "../profile-components/blogs";
import { instructorConsultation } from "../../../interfaces/new-api-interfaces/consultations";
import InstructorConsultations from "../profile-components/instructor-consultations";
import { useLocation } from "react-router-dom";
import { Typography } from "@mui/material";

interface Props {
  courses?: profileCoursesResponse[];
  isLoading: boolean;
  globalTests?: GlobalTestResponse[];
  testsToCorrect: testCorrectionResponse[];
  globalTestsToCorrect: testCorrectionResponse[];
  setIsCorrected: any;
  isCorrected: boolean;
  instructorId?: number;
  isShowTestCorrection: boolean;
  blogs?: BlogResponse[];
  consultations: instructorConsultation[];
  isAnswered: boolean;
  setIsAnswered: Function;
}

const tabTextStyle = {
  fontSize: "18px",
  fontWeight: 700,
  cursor: "pointer",
  color: "inherit",
};

const LearnPress: React.FC<any> = ({
  courses,
  isLoading,
  globalTests,
  testsToCorrect,
  instructorId,
  isShowTestCorrection,
  globalTestsToCorrect,
  setIsCorrected,
  isCorrected,
  blogs,
  consultations,
  isAnswered,
  setIsAnswered,
}: Props) => {
  const { t } = useTranslation();
  const atomId = useAtomValue(instructorIdAtom);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const activeTab = params.get("activeTab");
  const defaultActiveTab = activeTab || "courses";

  return (
    <div className="learn-press-profile-section section section-padding-bottom">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="learn-press-profile-nav">
              <ul
                className="nav scrollable"
                style={{
                  flexWrap: "nowrap",
                  whiteSpace: "nowrap",
                  padding: "0px",
                }}
              >
                <li>
                  <a
                    data-bs-toggle="tab"
                    href="#courses"
                    className={defaultActiveTab == "courses" ? "active" : ""}
                  >
                    <Typography sx={tabTextStyle}>
                      {t("FootelListCourses")}
                    </Typography>
                  </a>
                </li>
                <li>
                  <a
                    data-bs-toggle="tab"
                    href="#quizzes"
                    className={activeTab == "quizzes" ? "active" : ""}
                  >
                    <Typography sx={tabTextStyle}>
                      {t("GlobalQuizzes")}
                    </Typography>
                  </a>
                </li>
                {isShowTestCorrection && (
                  <li>
                    <a
                      data-bs-toggle="tab"
                      href="#results"
                      className={activeTab == "results" ? "active" : ""}
                    >
                      <Typography sx={tabTextStyle}>
                        {t("GlobalTestCorrection")}
                      </Typography>
                    </a>
                  </li>
                )}
                {isShowTestCorrection && (
                  <li>
                    <a
                      data-bs-toggle="tab"
                      href="#consultations"
                      className={activeTab == "consultations" ? "active" : ""}
                    >
                      <Typography sx={tabTextStyle}>
                        {t("consultations")}
                      </Typography>
                    </a>
                  </li>
                )}
                <li>
                  <a
                    data-bs-toggle="tab"
                    href="#blogs"
                    className={activeTab == "blogs" ? "active" : ""}
                  >
                    <Typography sx={tabTextStyle}>
                      {t("GlobalBlogs")}
                    </Typography>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="tab-content lp-profile-content">
              {!isLoading ? (
                <ProfileCourses
                  courses={courses!}
                  activeTab={defaultActiveTab}
                />
              ) : (
                <LoadingPage />
              )}

              {isShowTestCorrection && (
                <Results
                  globalTests={globalTestsToCorrect}
                  tests={testsToCorrect}
                  setIsCorrected={setIsCorrected}
                  isCorrected={isCorrected}
                  activeTab={activeTab}
                />
              )}

              <Quizzes
                tests={globalTests!}
                isShowDeleteBtn={isShowTestCorrection}
                activeTab={activeTab}
              />
              {isShowTestCorrection && (
                <InstructorConsultations
                  isChecked={isAnswered}
                  setIsChecked={setIsAnswered}
                  Consultations={consultations!}
                  activeTab={activeTab}
                />
              )}

              <Blogs
                blogs={blogs!}
                isShowDeleteBtn={isShowTestCorrection}
                activeTab={activeTab}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearnPress;
