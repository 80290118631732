import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation, Trans } from "react-i18next";

import { CourseBox } from "../../components/Course/course-details-components/course-box";
import { Pagination, Stack } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { Skeleton, Typography } from "@mui/material";
import { GridTitle } from "../../components/Common/grids-common/grid-title";
import { GridSearch } from "../../components/Common/grids-common/grid-search";
import { GridSelect } from "../../components/Common/grids-common/grid-select";
import i18next from "i18next";
import { fetchCourses } from "../../api/endpoints-functions";
import useDebounce from "../../hooks/useDebounce";
import GridFilter from "../../components/Common/grids-common/grid-filter";

interface Props {}

type filterType = "-price" | "-created_at" | "price";

const CoursePage: React.FC<Props> = () => {
  const { state } = useLocation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const showParam = queryParams.get("show");
  //states for search and filter and sort
  const [searchValue, setSearchValue] = useState("");
  const search = useDebounce(searchValue);
  const [sortValue, setSortValue] = useState<filterType>("-created_at");
  const [priceRange, setPriceRange] = useState<number[]>([0]);
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);

  const { t } = useTranslation();
  const { data, isLoading, isFetching } = useQuery(
    ["get-courses", search, sortValue, priceRange, selectedCategories],
    () => fetchCourses(search, sortValue, priceRange, selectedCategories),
    { staleTime: 120000 }
  );

  const ITEMS_PER_PAGE: number = 9;
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(ITEMS_PER_PAGE);

  const paginatedData = data?.slice(min, max);

  const paginate = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPageNumber(page);
    setMin(ITEMS_PER_PAGE * (page - 1));
    setMax(page * ITEMS_PER_PAGE);
  };

  const filterList = [
    {
      value: "-created_at",
      title: t("CourseGridLatest"),
    },
    {
      value: "price",
      title: t("CourseGridPriceLTH"),
    },
    {
      value: "-price",
      title: t("CourseGridPriceHTL"),
    },
  ];

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const searchParam = params.get("category");
      if (searchParam) {
        setSelectedCategories([parseInt(searchParam)]);
      }
    }
  }, [location.search]);

  return (
    <div>
      <GridTitle>{t("NavHeaderCoursesGrid")}</GridTitle>
      <div className="section section-padding-bottom">
        <div className={isLoading ? "container-lg" : "container"}>
          <div className="row justify-content-center align-items-center max-mb-20">
            <div
              className="col-sm-10 col-md-3 max-mb-10 mb-20"
              style={{ width: !isLoading && !isFetching ? "fit-content" : "" }}
            >
              {!isLoading && !isFetching ? (
                <p className="result-count">
                  <Trans i18nKey="CourseGridSubTitle" count={data?.length}>
                    <Typography>
                      We found <span>count</span> courses available for you
                    </Typography>
                  </Trans>
                </p>
              ) : (
                <p className="result-count">
                  <Skeleton variant="text" height={"3rem"} width={"100%"} />
                </p>
              )}
            </div>
            <GridSearch
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
            <GridSelect
              filterValue={sortValue}
              setFilterValue={setSortValue}
              filterList={filterList}
            />
            <GridFilter
              priceRange={priceRange}
              selectedCategories={selectedCategories}
              setPriceRange={setPriceRange}
              setSelectedCategories={setSelectedCategories}
              free={showParam == "free"}
            />
          </div>

          <div
            className={
              !isLoading && !isFetching
                ? "row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30 justify-content-center justify-content-md-start"
                : ""
            }
          >
            {!isLoading && !isFetching ? (
              data && data?.length ? (
                paginatedData?.map((course) => (
                  <div
                    className="col-12 col-md-6 col-lg-4 my-2"
                    key={course.id}
                  >
                    <>
                      <CourseBox key={course.id} {...course} />
                    </>
                  </div>
                ))
              ) : (
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  minHeight={"40vh"}
                >
                  <Typography style={{ margin: "0 auto", fontSize: "2em" }}>
                    {i18next.language == "en"
                      ? "No Courses Available"
                      : "لا يوجد دورات متاحة"}
                  </Typography>
                </Stack>
              )
            ) : (
              <div className="row max-mb-n30">
                {[1, 2, 3, 4, 5, 6].map((el) => (
                  <div className="col-12 col-md-6 col-lg-3 my-2 c-s" key={el}>
                    <Skeleton variant="rectangular" width={300} height={200} />
                    <div className="info">
                      <Skeleton
                        style={{ margin: "30px 0" }}
                        variant="text"
                        width="50%"
                      />
                      <Skeleton
                        style={{ margin: "30px 0" }}
                        variant="text"
                        width="70%"
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          {!isLoading && !isFetching && (
            <div>
              <>
                {/* @ts-ignore */}
                {data.length > ITEMS_PER_PAGE ? (
                  <Pagination
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      pt: "20px",
                    }}
                    count={Math.ceil(data?.length! / ITEMS_PER_PAGE) || 0}
                    page={currentPageNumber}
                    onChange={paginate}
                    variant="outlined"
                    color="primary"
                    size="large"
                    dir="ltr"
                  />
                ) : null}
              </>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CoursePage;
