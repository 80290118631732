import { User } from "../../../interfaces/api-interfaces";
import { getImageFromServer } from "../../../assets/JS/helpers";
import React from "react";
import { Box, Skeleton, Typography } from "@mui/material";
import StudentBanner from "../../../assets/images/profile/student-banner.png";
import bannerBackground from "../../../assets/images/trainer-details/baner-background.jpg";
import StudentButtons from "../profile-components/student-buttons";
import { FaMobileScreenButton } from "react-icons/fa6";
import { AiOutlineMail } from "react-icons/ai";
import { BiMap } from "react-icons/bi";
import { BsCalendarDate } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { profileResponse } from "../../../interfaces/new-api-interfaces/profile";
import i18next from "i18next";

const Profile: React.FC<profileResponse> = (Props) => {
  const imageProfile =
    Props.profile_image && Props.profile_image.length > 0
      ? getImageFromServer(Props.profile_image)
      : require("../../../assets/images/person-fallback.jpeg");

  const { t } = useTranslation();

  return (
    <Box
      sx={{
        backgroundImage: { xs: "none", md: `url(${bannerBackground})` },
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        marginBottom: "20px",
      }}
    >
      <div className="container">
        <div className="row">
          <Box
            sx={{ display: "flex", flexDirection: "column" }}
            className="col-12 col-lg-7"
          >
            <Box
              sx={{
                display: "flex",
                gap: { xs: "20px", md: "10px" },
                padding: "50px 0px 30px 0",
                alignItems: { xs: "center", sm: "flex-start" },
                flexWrap: "wrap",
                justifyContent: { xs: "center", md: "flex-start" },
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", sm: "45%", md: "45%" },
                  display: "flex",
                  justifyContent: { xs: "center", md: "flex-start" },
                }}
              >
                <div className="profile-image">
                  <img
                    src={imageProfile}
                    alt="profile"
                    style={{
                      borderRadius: "20px",
                      maxHeight: 350,
                      minHeight: 350,
                      width: 300,
                    }}
                  />
                </div>
              </Box>
              <Box
                className="profile-info"
                sx={{
                  width: { xs: "90%", sm: "45%", md: "45%" },
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <Typography variant="h4">
                  {Props.translations[i18next.language].first_name +
                    " " +
                    Props.translations[i18next.language].last_name}
                </Typography>
                <Box
                  sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <Box className="header-social" width="fit-content">
                    <FaMobileScreenButton />
                  </Box>
                  <Typography sx={{ whiteSpace: { sm: "nowrap" } }}>
                    {t("ContactUsMobile")} : {Props.phone_number}
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <Box className="header-social" width="fit-content">
                    <AiOutlineMail />
                  </Box>
                  <Typography sx={{ whiteSpace: { sm: "nowrap" } }}>
                    {t("GlobalMail")} : {Props.email}
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <Box className="header-social" width="fit-content">
                    <BiMap />
                  </Box>
                  <Typography sx={{ whiteSpace: { sm: "nowrap" } }}>
                    {t("GlobalAddress")} : {Props.address}
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <Box className="header-social" width="fit-content">
                    <BsCalendarDate />
                  </Box>
                  <Typography sx={{ whiteSpace: { sm: "nowrap" } }}>
                    {t("GlobalAge")} : {Props.age}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <StudentButtons />
          </Box>
          <Box
            className="col-md-5"
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <img src={StudentBanner} style={{ height: "100%" }} />
          </Box>
        </div>
      </div>
    </Box>
  );
};

export default Profile;
