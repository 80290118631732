import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import PageBanner from "../../components/Test/quizz-details/page-banner";
import { ScrollTop } from "../../components/Common/scroll-top.component";
import { getImageFromServer } from "../../assets/JS/helpers";
import QuizzButtons from "../../components/Test/quizz-details/quizz-buttons";
import { LoadingPage } from "../../components/Common/loadingPage.component";
import {
  fetchGlobalTest,
  fetchGlobalTestCerifications,
} from "../../api/endpoints-functions";
import { defaultTranslation } from "../../untilites/functions";
import { useAtomValue } from "jotai";
import { instructorIdAtom, roleAtom } from "../../store";
import CourseCertificateCard from "../../components/Course/course-details-components/course-certificate-card";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import i18next from "i18next";

interface Props {}

const GlobalTestDetails: React.FC<Props> = () => {
  const quizzId = useParams();
  const role = useAtomValue(roleAtom);
  const user = useAtomValue(instructorIdAtom);
  const {
    isLoading,
    refetch,
    data: quizz,
  } = useQuery(["get-test-by-id", quizzId.id], () =>
    fetchGlobalTest(quizzId.id!)
  );

  // const { isLoading: certificateLoading, data: certifications } = useQuery(
  //   ["get-certifications", quizzId.id],
  //   () => fetchGlobalTestCerifications(quizzId.id!),
  //   { keepPreviousData: true, staleTime: 60000 }
  // );
  if (isLoading) return <LoadingPage />;
  else
    return (
      <div>
        <PageBanner
          overviewImageUrl={getImageFromServer(quizz?.image || "")}
          question_count={quizz?.questions_count!}
          title={quizz?.translations ? defaultTranslation(quizz, "name") : ""}
          instructor={quizz?.instructors!}
          isLoading={isLoading}
          description={
            quizz?.translations ? defaultTranslation(quizz, "description") : ""
          }
          totalMark={quizz?.total_mark!}
          isGraded={quizz?.is_graded!}
        >
          {quizz && role != "instructor" && (
            <QuizzButtons
              id={quizz?.id}
              refetch={refetch}
              inCart={quizz?.in_cart}
              inWishlist={quizz?.in_wishlist}
              enrolled={quizz?.is_bought}
              isFree={quizz?.price === 0}
              isAnswered={quizz.is_answered}
            />
          )}

          {quizz?.certificates &&
            quizz.certificates.length >
              0 && (
                <Box sx={{ paddingTop: "20px" }}>
                  {quizz.certificates.length > 1 ? (
                    <Typography>
                      {i18next.language == "en"
                        ? "Get this certificates after completing this Test:"
                        : "احصل على الشهادات االتالية عند إكمال هذا الاختبار:"}
                    </Typography>
                  ) : (
                    <Typography>
                      {i18next.language == "en"
                        ? "Get this certification after completing this Test:"
                        : "احصل على الشهادة التالية عند إكمال هذا الاختبار:"}
                    </Typography>
                  )}
                  <div className="row">
                    {quizz.certificates.map((certificate) => (
                      <>
                        <div
                          className="col-12 col-md-6 col-lg-6 my-2 p-3"
                          key={certificate.id}
                        >
                          <CourseCertificateCard certificate={certificate} />
                        </div>
                      </>
                    ))}
                  </div>
                </Box>
              )}
        </PageBanner>
        <ScrollTop />
      </div>
    );
};

export default GlobalTestDetails;
