import { SpinnerLoadingIcon, JumpingPoints } from "./loading-icon";
import { useAddToCart, useRemoveFromCart } from "../../hooks/useEditCart";
import i18n from "i18next";
import { Box, Typography } from "@mui/material";
import { BsCart2 } from "react-icons/bs";
import { useAtomValue } from "jotai";
import { tokenAtom, userAtom } from "../../store";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  inCart: boolean;
  id: number;
  refetch?: Function;
  isBought: boolean;
  type: string;
  padding: string;
}
const buttonContentStyle = {
  fontSize: "small",
  display: "flex",
  alignItems: "center",
  gap: "10px",
  whiteSpace: "nowrap",
  width: "100%",
  justifyContent: "center",
};

const AddToCart: React.FC<Props> = ({
  id,
  inCart,
  refetch,
  isBought,
  type,
  padding,
}) => {
  const user = useAtomValue(userAtom);
  const token = useAtomValue(tokenAtom);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { mutateAsync: postToCart, isLoading: cartLoading } = useAddToCart();
  const { mutateAsync: postRemoveFromCart, isLoading: cartRemoveLoading } =
    useRemoveFromCart();

  const addToCart = async (id: number) => {
    await postToCart({
      cartable_id: id,
      cartable_type: type.toLocaleLowerCase(),
      quantity: 1,
    });
    if (refetch) refetch();
  };

  const removeFromCart = async (id: number) => {
    await postRemoveFromCart({
      cartable_id: id,
      cartable_type: type.toLocaleLowerCase(),
      quantity: 1,
    });
    if (refetch) refetch();
  };

  return (
    <div>
      <div onClick={(e) => e.preventDefault()}>
        <>
          {cartLoading || cartRemoveLoading ? (
            <JumpingPoints />
          ) : isBought ? (
            <Box className="in__cart" style={{ padding: padding }}>
              <Typography sx={buttonContentStyle}>
                {i18n.language === "en" ? "already bought " : "تم شراءه سابقاً"}
              </Typography>
            </Box>
          ) : !inCart ? (
            <button
              onClick={() => (token ? addToCart(id) : navigate("/login"))}
              className="home-action"
              style={{ padding: padding }}
            >
              <Typography sx={buttonContentStyle}>
                {i18n.language === "en" ? "add to cart" : "إضافة الى السلة"}
                <BsCart2 />
              </Typography>
            </button>
          ) : (
            <button
              onClick={() => (token ? removeFromCart(id) : navigate("/login"))}
              className="in__cart"
              style={{ padding: padding }}
            >
              <Typography sx={buttonContentStyle}>
                {t("removeFromCart")}
                <BsCart2 />
              </Typography>
            </button>
          )}
        </>
      </div>
    </div>
  );
};

export default AddToCart;
