const translationEn = {
  // Header Start
  // header main nav,
  HeaderNavPages: "Pages",
  HeaderNavLearning: "Training Services",
  HeaderNavEvents: "Events",
  books: "Books",
  trainingTools: "Training Tools",
  HeaderNavBlog: "Blog",
  resourses: "Resources",
  HeaderNavShop: "Shop",
  HeaderNavSearch: "Search...",
  HeaderNavQA: "Ask Us",
  //header pages menu
  NavHeaderVRC: "Virtual Classroom",
  NavHeaderAboutUs: "About Unlimited Horizons",
  NavHeaderContactUs: "Contact us",
  NavHeaderPurchase: "Purchase Guide",
  NavHeaderPrivacy: "Privacy Policy & Terms",
  NavHeaderTerms: "Terms of Service",
  //header courses menu
  NavHeaderCoursesGrid: "Training Programs",
  NavHeaderQuizzesGrid: "Personality & Performance Tests",
  NavHeaderCasesGrid: "Cases grid",
  NavHeaderMembership: "Membership Levels",
  NavHeaderBecome: "Trainers & Consultants",
  NavHeaderProfile: "Profile",
  NavHeaderCheckout: "Checkout",
  //header single layout menu
  NavHeaderFree: "Free Course",
  NavHeaderSticky: "Sticky Features Bar",
  NavHeaderStandard: "Standard Sidebar",
  NavHeaderNoSidebar: "No Sidebar",
  //header events menu
  NavHeaderEvent: "Event",
  NavHeaderZoom: "Zoom Meetings",
  NavHeaderEventDetails: "Event Details",
  NavHeaderZoomDetail: "Zoom Meeting Detail",
  //header blog menu
  NavHeaderBlogGrid: "Blog",
  NavHeaderBlogList: "Blog List",
  //header shop menu
  NavHeaderShop: "Shop",
  NavHeaderCart: "Cart",
  NavHeaderWishlist: "Wishlist",
  NavHeaderMyAccount: "My Account",
  NavHeaderLoginRegister: "Login Register",
  // Header End

  // Langind Start
  LandingTitleSmall: "UH Training is the lifebuoy",
  LandingTitleMain: "In Mentoring <br />2B a Better You.",
  LandingDescription:
    "The right mentoring relationship can be a powerful tool for professional growth. Bark up the right tree.",
  LandingDownload: "Download free guide",
  // Langind End

  // Feature Start
  FeatureSecHeader: "Education for everyone",
  FeatureMainHeader: "Online <1>Coaching Lessons</1> For Remote Learning.",
  Feature1Title: "Life Mentoring",
  Feature1Description:
    "Get some soul healing and guidance for your future career and vocational directions.",
  Feature1Link: "Start here",
  Feature2Title: "Self Development",
  Feature2Description:
    "Develop skills for career of various majors including computer science & language competence.",
  Feature2Link: "Discover now",
  Feature3Title: "Ask Consultancy",
  Feature3Description:
    "Make a reservation with our special consultant to learn what's best for your dream career.",
  Feature3Link: "Get Free Quote",
  Feature4Title: "Remote Learning",
  Feature4Description:
    "Learn from anywhere in the world on desktop, tablet or mobile phone with an Internet connection.",
  Feature4Link: "Start now",
  // Feature End

  // TeacherQuote Start
  TeacherQuoteTitle: "Get rid of <1>Old School Assumptions<1>",
  TeacherQuoteQuote:
    "I assist learners in finding new effective belief and get rid of the outdated, discouraged and irrelevant life attitudes. You're upgrading to be a better you.",
  TeacherQuoteYear: "years",
  TeacherQuoteWork: "Work Experience",
  TeacherQuoteLink: "Get Free Guide",
  // TeacherQuote End

  // Testimonials Start
  TestimonialsSubTitle: "MAXIMIZE YOUR POTENTIALS and possibilities",
  TestimonialsTitle:
    "Learn the secrets to <1>Life Success</1>, <br />these people have got the key.​",
  TestimonialsRegistered: "Registered Enrolls",
  TestimonialsFinished: "Finished sessions",
  TestimonialsSatisfaction: "Satisfaction rate",
  // Testimonials End

  // Video Start
  VideoSubTitle: "The combination of all senses into learning",
  VideoTitle: "Learn with all your <1>SENSES​</1>",
  // Video End

  // Course Section Start
  CourseSectionSubTitle: "Learn at your Pace",
  CourseSectionTitle: "Current Signature Courses",
  CourseSectionOffer:
    "Get the most dedicated consultation for your life-changing course. Earn a certification for your effort and passion",
  CourseSectionGuide: "Get Free Guide",
  // Course Section End

  // ShopItems Section Start
  ShopItemsSectionSubTitle: "Brows our shop",
  ShopItemsSectionTitle: "Our best shop items",
  ShopDetailsRecentItems: "Recent Items",
  GlobalShopItems: "Shop",
  // ShopItems Section End

  // Category Section Start
  CategorySectionSubTitle: "Brows Our Categories",
  CategorySectionTitle: "Our best categories",
  // Category Section End

  // Instructors Section Start
  InstructorsSectionSubTitle: "Brows Our Instructors",
  InstructorsSectionTitle: "Our best instructors",
  // Instructors Section End

  // Blog Section Start
  BlogSectionSubTitle: "study with your favorite tutors",
  BlogSectionTitle: "Secrets of learning revealed in blogs",
  BlogSectionOffer: "Get into details now?​",
  BlogSectionGuide: "View all blogs",
  BlogSectionCard1Title: "ONLINE EDUCATION",
  BlogSectionCard1Description: "Become a Better Blogger: Content Planning",
  BlogSectionCard2Title: "LEARNING STRATEGIES",
  BlogSectionCard2Description: "Five Essential Online Learning Strategies",
  BlogSectionCard3Title: "LEARNING STRATEGIES",
  BlogSectionCard3Description: "Five Essential Online Learning Strategies",
  BlogSectionViews: "{{count}} Views",
  BlogRecentBlogs: "Recent Blogs",
  // Blog Section End

  // Success Section Start
  SuccessSectionSubTitle: "Four major Steps to Success",
  SuccessSectionTitle:
    "Best chances for expanding your <br /> success to the max",
  SuccessSectionCard1Title: "BROADENING",
  SuccessSectionCard1Head: "#1 Career advancement and hobbies",
  SuccessSectionCard1Description:
    "Studying online gives you more flexibility. You can work and fit your work schedule around your coursework more easily; even more so if you are taking an asynchronous className: an online className where you can study and interact with your instructor and fellow classNamemates at your own pace.",
  SuccessSectionCard2Title: "SCHEDULING",
  SuccessSectionCard2Head: "#2 Flexible schedule & environment",
  SuccessSectionCard2Description:
    "Taking an online course also means that you don’t have to commute to className, which means less time spent on the bus and more study time sitting on your couch, the sound of a crackling fireplace in the background. You no longer have to worry about missing an important className!",
  SuccessSectionCard3Title: "SELF-DISCIPLINE",
  SuccessSectionCard3Head: "#3 Self-discipline & responsibility",
  SuccessSectionCard3Description:
    "Who says that having to be more self-disciplined is a disadvantage? It is true that studying online requires more time-management skills, because you will spend a lot of time on your own without someone physically close to keep you focused on deadlines.",
  SuccessSectionCard4Title: "Investment",
  SuccessSectionCard4Head: "#4 Investment",
  SuccessSectionCard4Description:
    "Studying online means that you pay the tuition fee, possibly book supplies, an online application fee, and few other items. You don’t, however, incur the costs of housing (which can range up to $12,000 per year) and transportation, which translates to lower debts and more savings.",
  // Success Section End

  CoursesCount: "Belongs Courses",

  // CTA Section Start
  CTASubTitle: "Get yourself a <1> UH Training</1>  ID?",
  CTATitle:
    "Take your interest and register an online class <br /> today to enjoy life fully",
  CTAGuide: "Get started for free",
  // CTA Section End

  //  Faq Section Start
  FaqSectionSubTitle: "Four major Steps to Success",
  FaqSectionTitle: "Best chances for expanding your <br /> success to the max",
  FaqSectionQuis1Title: "How to register online?",
  FaqSectionQuis1Content:
    "Enrolling in an online className is easy. At  UH Training, we serve several categories of online learners. Select the student category that you identify with to get started.",
  FaqSectionQuis2Title: "How do I customize my account?",
  FaqSectionQuis2Content:
    "In order to customize your account’s information, just log in with the provided passcode and change the info.",
  FaqSectionQuis3Title: "How do you process my information?",
  FaqSectionQuis3Content:
    "Your data will be kept private and secure on our database. No illegal use without prior notice.",
  FaqSectionQuis4Title: "How do I contact support?",
  FaqSectionQuis4Content:
    "You can contact our help center or any contact method (phone call, email or appear at our office) to get help",
  FaqSectionQuis5Title: "How to cancel my membership?",
  FaqSectionQuis5Content:
    "You just need to submit a membership termination request, we’ll proceed within 24 hours.",
  FaqSectionQuis6Title: "How can I get a discount?",
  FaqSectionQuis6Content:
    "Learners can get a discount by coupon or voucher. If you have one, fill in the code box before making any payment.",
  // Faq Section End

  // Blog Details Page Start
  BlogPageBlogDetails: "blog details",
  BlogPagePopularPosts: "Popular Posts",
  BlogPagePopularTags: "Popular Tags",
  BlogPageShareThis: "Share This Post",
  BlogPageComments: "Comments",
  BlogPageReply: "Reply",
  BlogPageThought: "Leave your thought here ",
  BlogPageEmailNote:
    "Your email address will not be published. Required fields are marked *",
  BlogPageCashCredNode:
    "Save my name, email, and website in this browser for the next time I comment.",
  // Blog Details Page End
  EmptyBlogs: "There are no blogs yet",
  // Wishlist Page Start
  WishListStockStatus: "Stock Status",
  WishListAddToCart: "Add to cart",
  WishListAddToWishlist: "Add to wishlist",
  // Wishlist Page End

  // CheckOut Page Start
  CheckOutTitle: "Checkout",
  CheckOutSubTotal: "Sub Total",
  CheckOutShipping: "Shipping Fee",
  CheckOutPaymentMethod: "Payment Method",
  CheckOutInsideSyria: "Inside Syria",
  CheckOutGlobal: "Global",
  CheckOutConditionsNote: "I’ve read and accept the terms & conditions",
  CheckOutOrder: "Place Order",
  // CheckOut Page End

  // Profile Start
  ProfileCoursesCompleted: "Courses Completed",
  ProfileCoursesInProgress: "Courses In Progress",
  ProfileQuizzesCompleted: "Quizzes Completed",
  ProfileQuizzesInProgress: "Quizzes In Progress",
  ProfileOwned: "Owned",
  ProfilePurchased: "Purchased",
  ProfilePassed: "Passed",
  ProfileFailed: "Failed",
  CreateVrc: "Create Vrc",
  EditVrc: "Edit Vrc",
  ProfileCreateCourse: "Create Course",
  ProfileEditCourse: "Edit Course",
  ProfileCreateTest: "Create Test",
  ProfileCreateBlog: "Create Blog",
  ProfileEditProfile: "Edit Profile",
  ProfileAlbum: "Album",
  ProfileBecomeStudent: "Become A Student",
  ProfileDeleteAccount: "Delete Account",
  ProfileRevieweMessage: "Your account is being reviewed",
  ProfileApproveMessage: "Your account information has not been approved",
  ProfileBlockMessage:
    "Your account has been banned for <1>count</1> because of:",
  ToggleRole: "Toggle Role",
  LessonsCount: "Topics Count",
  TestsCount: "Tests Count",
  // Profile End

  // Footer Start
  FooterAddress: "Address",
  FooterWidgetExplore: "Explore",
  FootelListStart: "Start here",
  FootelListSuccess: "Success story",
  FootelListBlog: "Blog",
  FootelListCourses: "Courses",
  FootelListAbout: "About us",
  FootelListContact: "Contact us",
  FooterWidgetInformation: "Company Info",
  FootelListMembership: "Membership",
  FootelListPurchase: "Purchase guide",
  FootelListPrivacy: "Privacy policy",
  FootelListTerms: "Terms of service",
  FooterRights: "All Rights Reserved",
  // Footer End

  // Course Grid Start
  CourseGridSubTitle: "We found <1>{{count}}</1> course available for you",
  CourseGridDefault: "Default",
  CourseGridPopularity: "Popularity",
  CourseGridLatest: "Latest",
  CourseGridPriceLTH: "Price: low to high",
  CourseGridPriceHTL: "Price: high to low",
  // Course Grid End

  // Teachers Grid Start
  TeachersGridSubTitle: "We found <1>{{count}}</1> trainer for you",
  TeachersGridRating: "Rating",
  // Teachers Grid End

  // Quizzes Grid Start
  QuizzGridSubTitle: "We found <1>{{count}}</1> Quizzes for you",
  // Quizzes Grid End

  // Blogs Grid Start
  BlogGridSubTitle: "We found <1>{{count}}</1> blog available for you",
  // Blogs Grid End

  // Cases Grid Start
  CaseGridSubTitle: "We found <1>{{count}}</1> Cases for you",
  // Cases Grid End

  // Course Details Start
  CourseDetails: "Courses Details",
  CourseDetailsRelated: "Related Courses",
  CourseDetailsOverview: "Overview",
  CourseDetailsCurriculum: "Curriculum",
  CourseDetailsInstructor: "Trainer",
  CourseDetailsStudents: "Trainees",
  CourseDetailsLessons: "Topics",
  CourseDetailsWeek: "Week",
  CourseDetailsSearch: "Search",
  CourseDetailsRecentCourses: "Recent Courses",
  CourseDetailsBuyMembership: "Buy Membership",
  CourseDetailsBuyCourse: "Buy this course",
  CourseDetailsShareCourse: "Share this course",
  CourseDetailsReviews: "Reviews",
  CourseDetailsAverageRating: "Average Rating",
  CourseDetailsDetailedRating: "Detailed Rating",
  CourseDetailsRating: "ratings",
  CourseDetailsDescription: "Course Description",
  CourseDetailsTests: "Tests",
  CourseProgress: "Course Progress",
  NextLesson: "Next Lesson",
  // Course Details End

  // Contact Us Start
  ContactUs: "Contact us",
  ContactUsTitle:
    "For more information about our courses, get in touch with  UH Training via contacts",
  ContactUsContact: "Contact",
  ContactUsMobile: "Mobile",
  ContactUsHotline: "Hotline",
  ContactUsHour: "Hour of operation",
  ContactUsFormTitle:
    "Fill out this form for booking a consultant advising session.",
  Mobile: "Mobile",
  SocialMedia: "Social Media",
  // Contact Us End

  // Privacy Policy Start
  PrivacyPolicyApplicabled:
    "This Privacy Policy is applicabled for any one involves with the registering and course engagement at  UH Training Online Course Educational center.",
  PrivacyPolicyAvailability: "Availability of Website",
  PrivacyPolicyMember:
    "In order to buy any course or become a member of our center, customers/ learners must first register with a personal account providing the following information:",
  PrivacyPolicyRecognizes:
    "Member recognizes that the traffic of data through the Internet may cause delays<br />during the download of information from the website and accordingly, it shall not hold the Company liable for delays that are ordinary in the course of Internet use.",
  PrivacyPolicyAcknowledges:
    "Member further acknowledges and accepts that the website will not be available on a continual twenty-four hour basis due to such delays, or delays caused by the Company’s upgrading, modification, or standard maintenance of the website.",
  PrivacyPolicyProperty: "Intellectual Property Rights",
  PrivacyPolicyProtected:
    "The online course is owned by the Company and is protected by American and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws.",
  PrivacyPolicyRight:
    "No right, title or interest in or to the online course or any portion thereof, is transferred to any Member, and all rights not expressly granted herein, are reserved by the Company.",
  PrivacyPolicyCompany:
    "The Company name, the Company logo, and all related names, logos, product and service names, designs<br />and slogans, are trademarks of the Company. Member may not use such marks<br />without the prior written permission of the Company.",
  PrivacyPolicyObligations: "Company Obligations",
  PrivacyPolicyReasonable:
    "The Company will use commercially reasonable efforts to enable the online course to be accessible, except for scheduled maintenance and required repairs, and except for any interruption due to causes beyond the reasonable control of, or not reasonably foreseeable by the Company.",
  PrivacyPolicyGoverning: "Governing Law and Venue",
  PrivacyPolicyTerms:
    "These Terms of Service are construed and governed by the laws of the United States of America.",
  PrivacyPolicyContract:
    "If any of the provisions, either in whole or in part, of the contract is or becomes invalid or unenforceable, this shall not serve to invalidate the remaining provisions thereof.",
  PrivacyPolicyEffective: "Effective Date",
  // Privacy Policy End

  // Auth Form Start
  AuthFormLog: "Login",
  LoginRegister: "Register",
  AlreadyHaveAccount: "Already Have An Account?",
  DontHaveAccount: "Don't have an account?",
  LoginFormRegister: "Register",
  ForgetPassword: "Forgot Password?",
  send: "Send",
  resend: "Resend",
  reset: "Reset",
  UpdateProfile: "Update Profile",
  ResetPassword: "Reset Password",
  LoginFormRegisterInstructor: "Register as Instructor",
  ContinueRegisterInstructor:
    "Complete the informations to resgister as instructor",
  LoginFormNote:
    'The password must be at least twelve characters long, contain upper and lower case letters, contain numbers, contain symbols like ! " ? $ % ^ ; ).',
  LoginFormUsername: "Username or email",
  LoginFormRemember: "Remember me",
  LoginFormLost: "Lost your password?",
  AuthFormUsername: "Email",
  AuthFormRemember: "remember me",
  AuthFormLost: "did you forger you password?",
  // Auth Form End

  CourseShopSubTitle: "We found <1>{{count}}</1> shop item available for you",
  CartSummaryMessage: "Totla:  <1>{{count}}</1>$",

  // Global Start
  GlobalHome: "Home",
  GlobalPrev: "Prev",
  GlobalNext: "Next",
  GlobalLoadMore: "Load More",
  GlobalMonday: "Monday ",
  GlobalFriday: "Friday ",
  GlobalSunday: "Sunday ",
  GlobalSaturday: "Saturday",
  GlobalPassword: "Password",
  GlobalOldPassword: "Current Password",
  GlobalConfirmPassword: "Confirm Password",
  GlobalNewPassword: "New Password",
  GlobalUsername: "Username",
  GlobalName: "Your Name",
  GlobalMail: "Mail",
  GlobalAge: "Age",
  GlobalAddress: "Address",
  GlobalFirstName: "First Name in Arabic",
  GlobalFirstNameEnglish: "First Name in English",
  GlobalLastName: "Last Name in Arabic",
  GlobalLastNameEnglish: "Last Name in English",
  GlobalPhone: "Phone",
  GlobalSubject: "Subject",
  GlobalMessage: "Message",
  GlobalSubmit: "Submit",
  GlobalYourComment: "Your comment",
  GlobalTotal: "Total",
  GlobalImage: "Image",
  GlobalType: "Type",
  GlobalProfileImage: "Profile Photo",
  GlobalProduct: "Product",
  GlobalPrice: "Price",
  GlobalRemove: "Remove",
  GlobalOr: "OR",
  GlobalDashboard: "Dashboard",
  GlobalTestCorrection: "Test Correction",
  GlobalResults: "Results",
  GlobalQuizzes: "Quizzes",
  GlobalBlogs: "Blogs",
  LiveConsultations: "Live Consultations",
  GlobalQuiz: "Quiz",
  GlobalAll: "All",
  GlobalPublish: "Publish",
  GlobalPending: "Pending",
  GlobalFree: "Free",
  GlobalFinished: "Finished",
  GlobalCourse: "Course",
  GlobalDate: "Date",
  birthDate: "Birth Date",
  GlobalAnsweredAt: "Answered At",
  GlobalCreatedAt: "Created At",
  GlobalDelete: "Delete",
  GlobalCategory: "Category",
  // Global End
  Certificates: "Certificates",
  Male: "Male",
  Female: "Female",
  Gender: "Gender",
  Videos: "Videos",
  Readings: "Readings",
  Audio: "Audio",
  Quizz: "Quiz",
  Questions: "Questions",
  Mark: "Marks",
  ToKnowMore: "To Know More",
  BuyQuizz: "Buy Quiz",
  BuyItem: "Buy Item",
  By: "By",
  completed: "Completed",
  Activation: "Activation",
  name: "Name",
  date: "Date",
  studentsCount: "Trainees Count",
  Description: "Description",
  courseName: "Course Name",
  Brief: "Brief",
  status: "Status",
  BoughtAt: "Bought At",
  goToTest: "Go To Test",
  previewYourAnswer: "Preview your Answer",
  noContent: "No Content Yet",
  markAsRead: "Mark as Read",
  cardImage: "Card Image",
  PannerImage: "Panner Image",
  courseNameAr: "course name in Arabic",
  courseNameEn: "course Name in English",
  courseDescAr: "Description in Arabic",
  courseDescEn: "Description in English",
  uploadGuideFile: "upload guide file",
  uploadIntroVideo: "upload Introduction video",
  uploadExcelFile: "Upload Excel File",
  downloadTemplate: "Download Template",
  createCourse: "Create Course",
  addCategories: "Add Categories",
  addTags: "Add Tags",
  pickColor: "Pick Color",
  addRelatedCourses: "Add Related Courses",
  addRelatedShopItems: "Add Related Shop Items",
  addRelatedBlogs: "Add Related Blogs",
  submit: "Submit",
  editCourse: "Edit Course",
  skip: "Skip",
  addNewSection: "Add New Section",
  saveOrder: "Save Order",
  finish: "Finish",
  editSection: "Edit Section",
  addNewLesson: "Add New Lesson",
  editLesson: "Edit Lesson",
  addContent: "Add Content",
  editContent: "Edit Content",
  editTest: "Edit Test",
  editTestQuestions: "Edit Test Questions",
  editTestMarks: "Edit Test Marks",
  addRating: "Add Rating",
  cancel: "Cancel",
  nameEn: "Name in English",
  descEn: "Description in English",
  nameAr: "Name in Arabic",
  descAr: "Description in Arabic",
  video: "Video",
  audio: "Audio",
  asset: "File",
  test: "Test",
  preview: "Preview",
  addNewTest: "Add New Test",
  testImage: "Test Image",
  testNameAr: "Test Name in Arabic",
  testNameEn: "Test Name in English",
  testDescAr: "Test Description in Arabic",
  testDescEn: "Test Description in English",
  createTest: "Create Test",
  addNewQuestion: "Add New Question",
  optionsQuestion: "Options Question",
  matchQuestion: "Match Question",
  writtenQuestion: "Written Question",
  edit: "Edit",
  editQuestion: "Edit Question",
  createNewoptionsQuestion: "Create New Options Question",
  createNewmatchQuestion: "Create New Match Question",
  createNewwrittenQuestion: "Create New Written Question",
  options: "options",
  written: "written",
  match: "match",
  questionEn: "Question in English",
  questionAr: "Question in Arabic",
  optionAr: "Option in Arabic",
  optionEn: "Option in English",
  matchedOptionAr: "matched Option in Arabic",
  matchedOptionEn: "matched Option in English",
  addSentence: "Add Sentence",
  option: "Option",
  matchedOption: "matched Option",
  inEn: "in English",
  inAr: "in Arabic",
  sentence: "Sentence",
  addOption: "Add Option",
  updateQuestion: "Update Question",
  createQuestion: "Create Question",
  section: "Section",
  createBlog: "Create Blog",
  save: "Save",
  close: "Close",
  update: "Update",
  sections: "Sections",
  basicInfo: "Basic Info",
  educationAr: "Education in Arabic",
  educationEn: "Education in English",
  bioAr: "Bio in Arabic",
  bioEn: "Bio in English",
  courseUnderReview: "The Course Is Under Review",
  firstName: "First Name",
  lastName: "Last Name",
  shopitem: "shop item",
  globaltest: "test",
  course: "course",
  numAttempsAllowed: "number of allowed attempts",
  Yes: "Yes",
  No: "No",
  fullMark: "Full Mark",
  moreThan: "More Than",
  resultAr: "Result in Arabic",
  resultEn: "Result in English",
  byExcel: "By Excel",
  byHand: "By Hand",
  addEvaluation: "Add Evaluation",
  mark: "Mark",
  currentTotal: "Current Questions Total Marks",
  updateTest: "Update Test",
  consultations: "Consultations",
  addNewConsultation: "Add New Consultation",
  nameArabic: "Name In Arabic",
  nameEnglish: "Name In English",
  submitConsultation: "Submit Consultation",
  consultationContent: "Consultation Content",
  trainerAnswer: "Trainer Answer",
  studentConsultationDate: "Prefered Date",
  consultationScheduledDate: "Scheduled Date",
  rejectReason: "Reject Reason",
  consultationTime: "Consultation Time",
  studentConsultationNotes: "Additional Notes",
  consultationReason: "Consultation Reason",
  email: "Email",
  consultationDate: "Consultation Date",
  consultationNotes: "Consultation Notes",
  consultationNotesHint:
    "Enter the suitable time for you and your preferences regarding the consultation",
  submitConsultationRequest: "Submit Consultation Request",
  addConsultationRequest: "Add Consultation Request",
  preferredDate: "Preferred Date",
  scheduledDate: "scheduled date",
  rejectionReason: "Rejection Reason",
  consultationPrice: "Consultation Price",
  syp: "SP",
  removeFromCart: "Remove From Cart",
  removeFromWishlist: "Remove From Wishlist",
  viewMore: "View More",
  enrollFree: "Enroll For Free",
  totalAmount: "Total Amount",
  joinMeeting: "Join Meeting",
  createMeeting: "Create Meeting",
  underReview: "Under Review",
  GlobalResultsTests: "Tests Results",
  cetrtificates: "Certificates",
  Download: "Download",
  TypeMessagePlaceHolder: "Type your message here",
  AddFiles: "Add Files",
  EmptyClassRooms: "You Don't Have Any Class Room",
  Image: "Image",
  Members: "Members",
  You: "You",
  AdminsOnly: "Only Admins Can Send Messages in This Groups",
  ClickTojoinMeeting: "Click Here To Join Meeting",
  MembershipCertificate: "Membership Certificate",
  TOS: "Terms of Service",
  locations: "Locations",
  visit_website: "Visit Website",
  view_certificate: "View Certificates"
};

export default translationEn;
