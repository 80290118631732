interface AudioRecorderInterface {
  audioBlobs: Blob[];
  mediaRecorder: MediaRecorder | null;
  streamBeingCaptured: MediaStream | null;
  start: () => Promise<void>;
  stop: () => Promise<Blob>;
  cancel: () => void;
  stopStream: () => void;
  resetRecordingProperties: () => void;
}
//API to handle audio recording

var audioRecorder: AudioRecorderInterface = {
  /** Stores the recorded audio as Blob objects of audio data as the recording continues*/
  audioBlobs: [] /*of type Blob[]*/,
  /** Stores the reference of the MediaRecorder instance that handles the MediaStream when recording starts*/
  mediaRecorder: null /*of type MediaRecorder*/,
  /** Stores the reference to the stream currently capturing the audio*/
  streamBeingCaptured: null /*of type MediaStream*/,
  /** Start recording the audio
   * @returns {Promise} - returns a promise that resolves if audio recording successfully started
   */
  start: () => {
    //Feature Detection
    if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
      //Feature is not supported in browser
      //return a custom error
      return Promise.reject(
        new Error(
          "mediaDevices API or getUserMedia method is not supported in this browser."
        )
      );
    } else {
      //Feature is supported in browser
      //create an audio stream
      return (
        navigator.mediaDevices
          .getUserMedia({ audio: true } /*of type MediaStreamConstraints*/)
          //returns a promise that resolves to the audio stream
          .then((stream) /*of type MediaStream*/ => {
            //save the reference of the stream to be able to stop it when necessary
            audioRecorder.streamBeingCaptured = stream;

            //create a media recorder instance by passing that stream into the MediaRecorder constructor
            audioRecorder.mediaRecorder = new MediaRecorder(
              stream
            ); /*the MediaRecorder interface of the MediaStream Recording
                        API provides functionality to easily record media*/

            //clear previously saved audio Blobs, if any
            audioRecorder.audioBlobs = [];

            //add a dataavailable event listener in order to store the audio data Blobs when recording
            audioRecorder.mediaRecorder.addEventListener(
              "dataavailable",
              (event) => {
                //store audio Blob object
                audioRecorder.audioBlobs.push(event.data);
              }
            );

            //start the recording by calling the start method on the media recorder
            audioRecorder.mediaRecorder.start();
          })
      );
    }
  },
  /** Stop the started audio recording
   * @returns {Promise} - returns a promise that resolves to the audio as a blob file
   */
  stop: (): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      if (!audioRecorder.mediaRecorder) {
        reject(new Error("MediaRecorder not found"));
        return;
      }

      audioRecorder.mediaRecorder.addEventListener("stop", () => {
        const mimeType = audioRecorder.mediaRecorder?.mimeType ?? "audio/wav";
        const audioBlob = new Blob(audioRecorder.audioBlobs, {
          type: mimeType,
        });
        resolve(audioBlob);
      });

      audioRecorder.mediaRecorder.addEventListener("error", () => {
        reject(new Error("Unknown MediaRecorder error"));
      });

      audioRecorder.cancel();
    });
  },
  /** Stop all the tracks on the active stream in order to stop the stream and remove
   * the red flashing dot showing in the tab
   */
  stopStream: () => {
    //stopping the capturing request by stopping all the tracks on the active stream
    audioRecorder.streamBeingCaptured
      ?.getTracks() //get all tracks from the stream
      .forEach((track) /*of type MediaStreamTrack*/ => track.stop()); //stop each one
  },
  /** Reset all the recording properties including the media recorder and stream being captured*/
  resetRecordingProperties: () => {
    audioRecorder.mediaRecorder = null;
    audioRecorder.streamBeingCaptured = null;

    /*No need to remove event listeners attached to mediaRecorder as
        If a DOM element which is removed is reference-free (no references pointing to it), the element itself is picked
        up by the garbage collector as well as any event handlers/listeners associated with it.
        getEventListeners(audioRecorder.mediaRecorder) will return an empty array of events.*/
  },
  /** Cancel audio recording*/
  cancel: () => {
    //stop the recording feature
    audioRecorder.mediaRecorder?.stop();

    //stop all the tracks on the active stream in order to stop the stream
    audioRecorder.stopStream();

    //reset API properties for next recording
    audioRecorder.resetRecordingProperties();
  },
};

/** Starts the audio recording*/
const startAudioRecording = (ref: HTMLAudioElement | null) => {
  //start recording using the audio recording API
  if (ref) {
    ref.src = "";
  }
  audioRecorder
    .start()
    .then(() => {
      //on success
      console.log("Recording Audio...");
    })
    .catch((error) => {
      //on error
      //No Browser Support Error
      if (
        error.message.includes(
          "mediaDevices API or getUserMedia method is not supported in this browser."
        )
      ) {
        console.log("To record audio, use browsers like Chrome and Firefox.");
        //Error handling structure
        switch (error.name) {
          case "AbortError": //error from navigator.mediaDevices.getUserMedia
            console.log("An AbortError has occured.");
            break;
          case "NotAllowedError": //error from navigator.mediaDevices.getUserMedia
            console.log(
              "A NotAllowedError has occured. User might have denied permission."
            );
            break;
          case "NotFoundError": //error from navigator.mediaDevices.getUserMedia
            console.log("A NotFoundError has occured.");
            break;
          case "NotReadableError": //error from navigator.mediaDevices.getUserMedia
            console.log("A NotReadableError has occured.");
            break;
          case "SecurityError": //error from navigator.mediaDevices.getUserMedia or from the MediaRecorder.start
            console.log("A SecurityError has occured.");
            break;
          case "TypeError": //error from navigator.mediaDevices.getUserMedia
            console.log("A TypeError has occured.");
            break;
          case "InvalidStateError": //error from the MediaRecorder.start
            console.log("An InvalidStateError has occured.");
            break;
          case "UnknownError": //error from the MediaRecorder.start
            console.log("An UnknownError has occured.");
            break;
          default:
            console.log("An error occured with the error name " + error.name);
        }
      }
    });
};

const StopAudioRecording = (ref: HTMLAudioElement | null) => {
  //stop the recording using the audio recording API
  console.log("Stopping Audio Recording...");
  return audioRecorder
    .stop()
    .then((audioAsblob) => {
      //stopping makes promise resolves to the blob file of the recorded audio
      console.log("stopped with audio Blob:", audioAsblob);
      // create a new URL object from the audio blob
      const audioUrl = URL.createObjectURL(audioAsblob);
      if (ref) {
        // get the audio element and set its source to the audio URL
        ref.src = audioUrl;
      }

      return { blob: audioAsblob, url: audioUrl };
    })
    .catch((error) => {
      //Error handling structure
      switch (error.name) {
        case "InvalidStateError": //error from the MediaRecorder.stop
          console.log("An InvalidStateError has occured.");
          break;
        default:
          console.log("An error occured with the error: " + error);
      }
      return null;
    });
};

/** Cancel the currently started audio recording */
const cancelAudioRecording = (ref: HTMLAudioElement | null) => {
  console.log("Canceling audio...");
  //cancel the recording using the audio recording API
  audioRecorder.cancel();

  //Do something after audio recording is cancelled
  if (ref) {
    ref.src = "";
  }
};

const AudioRecorder = (ref: HTMLAudioElement | null) => {
  return {
    start: () => startAudioRecording(ref),
    stop: () => StopAudioRecording(ref),
    cancel: () => cancelAudioRecording(ref),
  };
};
export default AudioRecorder;
