import React from "react";
import { useNavigate } from "react-router-dom";
import { defaultTranslation } from "../../../untilites/functions";
import { TableRow, TableCell, Typography, Avatar } from "@mui/material";
import { studentToCorrectInfo } from "../../../interfaces/new-api-interfaces/test";
import { getImageFromServer, getUserImageFromServer } from "../../../assets/JS/helpers";

interface Props {
  student: studentToCorrectInfo;
  index: number;
  testId: string;
  isRelatedToCourse?: boolean;
}

const StudentToCorrectTableItem: React.FC<Props> = ({
  index,
  student,
  testId,
  isRelatedToCourse,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (isRelatedToCourse) {
      navigate(`/course-test-correction/${student.id}/${testId}`);
    } else {
      navigate(`/test-correction/${student.id}/${testId}`);
    }
  };
  return (
    <TableRow
      onClick={handleClick}
      sx={{
        cursor: "pointer",
        bgcolor: index % 2 == 0 ? "#fff" : "#F5F5F5",
      }}
    >
      <TableCell>
        <Typography>
          <Avatar
            alt={defaultTranslation(student, "first_name")}
            src={getUserImageFromServer(student.profile_image)}
          ></Avatar>
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>{defaultTranslation(student, "first_name")}</Typography>
      </TableCell>

      <TableCell>
        <Typography> {defaultTranslation(student, "last_name")}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default StudentToCorrectTableItem;
