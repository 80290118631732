import React from "react";
import { useTranslation } from "react-i18next";
import { arrayFromRange } from "../../assets/JS/helpers";
import { Typography } from "@mui/material";

interface Props {
  currentPageNumber: number;
  totalPages: number;
  paginate: Function;
}

export const Pagination: React.FC<Props> = ({
  currentPageNumber,
  paginate,
  totalPages,
}) => {
  const { t } = useTranslation();

  return (
    <div className="row max-mt-50">
      <div className="col">
        <ul
          className="pagination center"
          style={{ alignItems: "center", gap: "15px" }}
        >
          <li>
            <div
              className="prev"
              onClick={() => paginate(currentPageNumber - 1)}
              style={{ cursor: "pointer" }}
            >
              <Typography variant="h6" component="button">
                {t("GlobalPrev")}
              </Typography>
            </div>
          </li>
          {arrayFromRange(currentPageNumber, totalPages).map((num) => (
            <li key={num}>
              <span
                className={`${num === currentPageNumber ? "active" : ""}`}
                onClick={() => paginate(num)}
              >
                {num}
              </span>
            </li>
          ))}
          <li>
            <div
              className="next"
              onClick={() => paginate(currentPageNumber + 1)}
              style={{ cursor: "pointer" }}
            >
              <Typography variant="h6" component="button">
                {t("GlobalNext")}
              </Typography>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
