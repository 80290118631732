import React from "react";
import { useTranslation } from "react-i18next";
import { PageTitle } from "../../components/Common/page-title-section.component";
import AboutSidebar from "../../components/static-pages/About/about-sidebar";
import ArPrivacyPolicy from "./ar-privacy-policy";
import EnPrivacyPolicy from "./en-privacy-policy";

interface Props {}

const PrivacyPolicySection: React.FC<Props> = () => {
  const { t, i18n } = useTranslation();
  return i18n.language === "ar" ? <ArPrivacyPolicy /> : <EnPrivacyPolicy />;
};

export default PrivacyPolicySection;
