import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { CustomImageInput } from "../../helpers";
import fallback from "../../../assets/images/placeholder.png";

import { blobToBase64, handleFormError } from "../../../untilites/functions";

import { useState } from "react";

import AddCategories from "../../InstructorDashboard/DashboardCourseComponents/add-categories";
import AddTags from "../../InstructorDashboard/DashboardCourseComponents/add-tags";

import SectionsList from "./sections/SectionsList";

import { DropResult } from "react-beautiful-dnd";

import { useEffect } from "react";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  fetchCategories,
  fetchTags,
  postBlog,
} from "../../../api/endpoints-functions";
import i18next, { t } from "i18next";

import { useNavigate } from "react-router-dom";

let sectionHasError = false;
let index = 2;

export const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export interface SectionType {
  id?: any;
  name?: string;
  file: any;
  type: string;
  text: string;
  text_en: string;
}

const BlogForm = () => {
  const [localError, setLocalError] = useState(null);

  const navigate = useNavigate();

  const {
    handleSubmit,
    setError,
    control,
    formState: { errors },
  } = useForm();

  const [textModalOpen, setTextModalOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState<SectionType>({
    file: "",
    type: "",
    text: "",
    text_en: "",
  });
  const [arabicDescription, setArabicDescription] = useState(
    selectedSection.text
  );
  const [englishDescription, setEnglishDescription] = useState(
    selectedSection.text_en
  );

  useEffect(() => {
    setArabicDescription(selectedSection.text);
    setEnglishDescription(selectedSection.text_en);
  }, [selectedSection]);

  const { data: tags, isLoading: tagsLoading } = useQuery(["tags"], () =>
    fetchTags()
  );
  const { data: categories, isLoading: catsLoading } = useQuery(
    ["categories"],
    () => fetchCategories()
  );

  const add = useMutation((data: any) => postBlog(data), {
    onSuccess: () => {
      toast.success("Blog added succsfully");
      navigate("/profile");
    },
    onError: (error: any) => {
      if (error?.response?.status === 422) {
        handleFormError(error.response, setError, toast, i18next);
      }
    },
  });

  const [sections, setSections] = useState<SectionType[]>([
    {
      id: 1,
      name: "Section",
      file: "",
      type: "",
      text: "",
      text_en: "",
    },
  ]);

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return;
    const newItems = reorder(sections, source.index, destination.index);
    setSections(newItems);
  };

  const onDelete = (deletedItem: SectionType) => {
    setSections((prevState) =>
      prevState.filter((item) => item.id !== deletedItem.id)
    );
  };

  const openTextModalHandler = (item: SectionType) => {
    setSelectedSection(item);
    setTextModalOpen(true);
  };

  const AddSectionHandler = () => {
    let arr: any = [];

    arr.push({
      id: index,
      name: `Section`,
      file: ``,
      type: ``,
      text: ``,
      text_en: ``,
    });
    setSections((prevState) => [...prevState, ...arr]);
    index++;
  };

  const addDescriptionHandler = (
    arDescription: string,
    enDescription: string,
    id: number
  ) => {
    const updatedItems = sections.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          text: arDescription,
          text_en: enDescription,
        };
      }
      return item;
    });
    setSections([...updatedItems]);
    setArabicDescription("");
    setEnglishDescription("");
    setTextModalOpen(false);
  };

  const addFileHandler = async (files: any, id: number) => {
    const base64File = await blobToBase64(files);
    const type = files.type.split("/")[0];
    const updatedItems = sections.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          file: base64File,
          type: type,
        };
      }
      return item;
    });
    setSections([...updatedItems]);
  };

  const onSubmit = (values: any) => {
    sections.forEach((section) => {
      if (section.text === "") {
        toast.error("Arabic Description of section is required!");
        sectionHasError = true;
      } else if (section.text_en === "") {
        toast.error("English Description of section is required!");
        sectionHasError = true;
      } else {
        sectionHasError = false;
      }
    });

    if (sections.length === 0) {
      toast.error("You should at least one section");
      return;
    } else if (sectionHasError) {
      return;
    }

    const data = {
      ...values,
      sections: [...sections],
    };

    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("name_en", data.name_en);
    formData.append("description", data.description);
    formData.append("description_en", data.description_en);
    formData.append("image", data.image);
    formData.append("is_show", "0");
    formData.append("is_primary", "0");
    data.categories.forEach((cat: any, index: any) => {
      formData.append(`categories[${index}]`, cat);
    });
    data.tags.forEach((tag: any, index: any) => {
      formData.append(`tags[${index}]`, tag);
    });
    data.sections.forEach((section: SectionType, index: any) => {
      formData.append(`sections[${index}][file]`, section.file);
      formData.append(`sections[${index}][type]`, section.type);
      formData.append(`sections[${index}][order]`, index);
      formData.append(`sections[${index}][text]`, section.text);
      formData.append(`sections[${index}][text_en]`, section.text_en);
    });

    add.mutateAsync(formData);
  };

  return catsLoading || tagsLoading ? (
    <Stack alignItems={"center"} justifyContent={"center"}>
      <CircularProgress />
    </Stack>
  ) : (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Box sx={{ width: "420px", margin: "auto" }}>
              <Controller
                name="image"
                control={control}
                rules={{ required: "Image is required" }}
                render={({ field }) => (
                  <CustomImageInput
                    file={field.value}
                    handleChange={(e: any) => {
                      blobToBase64(e)
                        .then((value) => {
                          field.onChange(value);
                        })
                        .catch((err) => {
                          console.log(err);
                          setLocalError(err);
                        });
                    }}
                    fieldName={field.name}
                    classesForStyles="pb-5"
                    fallback={fallback}
                    // id={field.name}
                    isCover={true}
                  />
                )}
              />
              <Typography color="error">
                {errors.image?.message?.toString()}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="name"
              control={control}
              rules={{ required: "Arabic Name is required!" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t("nameAr")}
                  required
                  error={!!errors.name}
                  helperText={errors.name?.message?.toString()}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="name_en"
              control={control}
              rules={{ required: "English Name is required!" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t("nameEn")}
                  required
                  error={!!errors.name_en}
                  helperText={errors.name_en?.message?.toString()}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="description"
              control={control}
              rules={{ required: "Arabic Description is required!" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t("descAr")}
                  required
                  error={!!errors.description}
                  helperText={errors.description?.message?.toString()}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="description_en"
              control={control}
              rules={{ required: "English Description is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t("descEn")}
                  required
                  error={!!errors.description_en}
                  helperText={errors.description?.message?.toString()}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AddCategories
              errors={errors}
              categories={categories!}
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AddTags errors={errors} tags={tags!} control={control} />
          </Grid>
        </Grid>
        <SectionsList
          AddSectionHandler={AddSectionHandler}
          onDragEnd={onDragEnd}
          dragItems={sections}
          onDelete={onDelete}
          openTextModalHandler={openTextModalHandler}
          selectedSection={selectedSection}
          textModalOpen={textModalOpen}
          setTextModalOpen={setTextModalOpen}
          addDescriptionHandler={addDescriptionHandler}
          arabicDescription={arabicDescription}
          englishDescription={englishDescription}
          setArabicDescription={setArabicDescription}
          setEnglishDescription={setEnglishDescription}
          onAddFile={addFileHandler}
        />

        {add.isLoading ? (
          <Stack alignItems={"center"} justifyContent={"center"}>
            <CircularProgress />
          </Stack>
        ) : (
          <Button
            fullWidth
            variant="contained"
            sx={{ marginTop: "1rem" }}
            type="submit"
          >
            {t("createBlog")}
          </Button>
        )}
      </form>
    </>
  );
};

export default BlogForm;
