import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
// @ts-ignore
import { useNavigate } from "react-router-dom";
import { getImageFromServer } from "../../../assets/JS/helpers";
import { defaultTranslation } from "../../../untilites/functions";

interface TopInstructorProps {
  id: number;
  user: any;
}

const TopInstructorCard: React.FC<TopInstructorProps> = ({ user, id }) => {
  const navigate = useNavigate();
  return (
    <Card
      sx={{ maxWidth: 300, padding: "20px", bgcolor: "transparent" }}
      onClick={() => navigate("/teacher/" + id)}
      style={{ boxShadow: "none" }}
    >
      <CardActionArea sx={{ background: "transparent" }}>
        <CardMedia
          component="img"
          sx={{
            height: "22rem",
            filter: { xs: "grayscale(0%)", md: "grayscale(100%)" },
            transition: "filter 0.7s ease-in-out",
            boxShadow: "5px 5px 5px -1px rgba(0, 0, 0, 0.3)",
          }}
          image={
            user.profile_image && user?.profile_image.length
              ? getImageFromServer(user?.profile_image)
              : require("../../../assets/images/person-fallback.jpeg")
          }
          alt={
            defaultTranslation(user, "first_name") +
            " " +
            defaultTranslation(user, "last_name")
          }
          onMouseOver={(e: React.MouseEvent<HTMLImageElement>) => {
            e.currentTarget.style.filter = "grayscale(0%)";
          }}
        />
        <CardContent sx={{ background: "transparent",textAlign: "center" }}>
          <Typography gutterBottom variant="h5" component="div">
            {defaultTranslation(user, "first_name") +
              " " +
              defaultTranslation(user, "last_name")}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default TopInstructorCard;
