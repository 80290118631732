import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Controller, SubmitHandler } from "react-hook-form";
import { courseLessonUpdate } from "../../../interfaces/new-api-interfaces/course";
import { toast } from "react-toastify";
import i18next from "i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchApi } from "../../../api/api";
import axios from "axios";
import {
  testQuestion,
  testQuestionRequest,
} from "../../../interfaces/new-api-interfaces/test";
import CustomPickQuestionType from "./custom-pick-question-type";
import { FaPen, FaListUl, FaExchangeAlt } from "react-icons/fa";
import AddEditOptions from "./add-edit-options";
import AddEditMatch from "./add-edit-match";
import { useTranslation } from "react-i18next";

const inputStyle = {
  width: { xs: "100%", sm: "45%" },
  marginBottom: "30px",
};

type Props = {
  typeState: boolean;
  modalInfo?: testQuestion;
  modalOpen: boolean;
  isValid: boolean;
  control: any;
  handleModalClose: Function;
  handleSubmit: any;
  questionMethod: "create" | "update";
  section_id?: number;
  order: number;
  setProgress: Function;
  test_id: number;
  setTypeState: Function;
  getValues: Function;
  setValue: Function;
  errors: any;
  setType: Function;
  type: string;
  testType?: "test" | "globaltest";
  is_graded: boolean;
  fullMarks: number;
  currentMarks: number;
};

function QuestionEditModal({
  typeState,
  modalInfo,
  modalOpen,
  control,
  handleModalClose,
  handleSubmit,
  isValid,
  questionMethod,
  section_id,
  order,
  setProgress,
  test_id,
  setTypeState,
  getValues,
  setValue,
  setType,
  errors,
  type,
  testType,
  is_graded,
  fullMarks,
  currentMarks,
}: Props) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { isLoading, mutateAsync } = useMutation(
    (data: any) => fetchApi(`/test/question/${modalInfo?.id}`, "PUT", data),
    {
      onError: (error: any) => {
        toast.error(
          i18next.language === "en"
            ? "Something went wrong, please try again later."
            : "حدث خطأ ما، يرجى المحاولة مرة أخرى لاحقًا."
        );
        queryClient.invalidateQueries(["get-questions", `${test_id}`]);
        handleModalClose();
      },
    }
  );

  const { isLoading: loadingCreate, mutateAsync: createQuestion } = useMutation(
    (data: any) => fetchApi(`/test/question`, "POST", data),
    {
      onError: (error: any) => {
        toast.error(
          i18next.language === "en"
            ? "Something went wrong, please try again later."
            : "حدث خطأ ما، يرجى المحاولة مرة أخرى لاحقًا."
        );
        queryClient.invalidateQueries(["get-questions", `${test_id}`]);
        handleModalClose();
      },
    }
  );

  const onSubmit: SubmitHandler<testQuestionRequest> = async (data) => {
    const modefiedData = {
      ...data,
      type: type,
      questionable_type: testType,
      questionable_id: test_id,
      order: order,
    };

    try {
      if (questionMethod == "update") {
        if (!data.name_en) delete data.name_en;
        mutateAsync({ ...data, questionable_type: testType }).then(
          (response) => {
            if (response.status === 200 || response.status === 202) {
              toast.success(
                i18next.language === "en"
                  ? "Question Edited successfully"
                  : "تم تعديل السؤال بنجاح"
              );
              handleModalClose();
            }
            queryClient.invalidateQueries(["get-questions", `${test_id}`]);
          }
        );
      } else {
        if (!data.name_en) delete data.name_en;
        createQuestion(modefiedData).then((response) => {
          if (response.status === 200 || response.status === 201) {
            toast.success(
              i18next.language === "en"
                ? "Question Created successfully"
                : "تم إنشاء السؤال بنجاح"
            );
            handleModalClose();
          }
          queryClient.invalidateQueries(["get-questions", `${test_id}`]);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={() => handleModalClose()}
      sx={{
        direction: i18next.language == "en" ? "ltr" : "rtl",
        width: type == "match" && !typeState ? "80vw" : "60vw",
        height: typeState ? "50vh" : type == "written" ? "50vh" : "70vh",
        position: "absolute",
        top: typeState ? "20vh" : type == "written" ? "20vh" : "10vh",
        left: type == "match" && !typeState ? "10vw" : "20vw",
        right: type == "match" && !typeState ? "10vw" : "20vw",
      }}
      disableScrollLock={true}
    >
      <Stack
        sx={{
          py: 0,
          height: "100%",
          boxSizing: "border-box",
          width: "100%",
          overflowY: "scroll",
        }}
        className="course-scrollable-side"
        direction="column"
      >
        <Paper
          sx={{
            flex: 1,
            mx: "auto",
            width: "100%",
            p: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: typeState ? "center" : "flex-start",
          }}
          component={"div"}
          dir={i18next.language == "en" ? "ltr" : "rtl"}
        >
          {!isLoading && !loadingCreate && (
            <>
              {!typeState && (
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  gap="2%"
                  component="form"
                  justifyContent="flex-start"
                  onSubmit={handleSubmit(onSubmit)}
                  sx={{ width: "100%" }}
                >
                  <Typography
                    variant="h6"
                    sx={{ width: "70%", paddingBottom: "20px" }}
                  >
                    {questionMethod == "update"
                      ? `${t("editQuestion")} #${modalInfo?.order}`
                      : type == "options"
                      ? t("createNewoptionsQuestion")
                      : type == "written"
                      ? t("createNewwrittenQuestion")
                      : t("createNewmatchQuestion")}
                  </Typography>
                  {is_graded ? (
                    <Controller
                      control={control}
                      name="mark"
                      defaultValue=""
                      rules={{
                        validate: (value) => {
                          if (questionMethod == "update") {
                            const preValue = modalInfo?.mark;
                            if (
                              currentMarks - preValue! + parseInt(value) >
                              fullMarks
                            ) {
                              return i18next.language == "en"
                                ? "Total mark exceeds the full mark"
                                : "مجموع علامات الأسئلة يتجاوز العلامة العظمى";
                            }
                          } else {
                            if (currentMarks + parseInt(value) > fullMarks) {
                              return i18next.language == "en"
                                ? "Total mark exceeds the full mark"
                                : "مجموع علامات الأسئلة يتجاوز العلامة العظمى";
                            }
                          }
                          return true;
                        },
                      }}
                      render={({ field, fieldState: { invalid } }) => (
                        <TextField
                          sx={{ width: "20%", paddingBottom: "20px" }}
                          type="number"
                          label={t("mark")}
                          inputProps={{ inputMode: "numeric", min: 1 }}
                          {...field}
                          required
                          error={invalid || (errors && !!errors?.mark)}
                          helperText={
                            errors && errors.mark && errors?.mark?.message
                          }
                        />
                      )}
                    />
                  ) : (
                    <></>
                  )}

                  <Controller
                    control={control}
                    name="name"
                    defaultValue=""
                    render={({ field, fieldState: { invalid } }) => (
                      <TextField
                        sx={{ ...inputStyle }}
                        label={t("questionAr")}
                        error={invalid}
                        multiline={type == "match" ? false : true}
                        {...field}
                        required
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    defaultValue=""
                    name="name_en"
                    render={({ field, fieldState: { invalid } }) => (
                      <TextField
                        sx={inputStyle}
                        label={t("questionEn")}
                        error={invalid}
                        multiline={type == "match" ? false : true}
                        {...field}
                      />
                    )}
                  />
                  {type == "options" && (
                    <AddEditOptions
                      control={control}
                      getValues={getValues}
                      setValue={setValue}
                      errors={errors}
                      questionMethod={questionMethod}
                      questionId={modalInfo?.id!}
                      is_graded={is_graded}
                    />
                  )}

                  {type == "match" && (
                    <AddEditMatch
                      control={control}
                      getValues={getValues}
                      setValue={setValue}
                      errors={errors}
                      questionMethod={questionMethod}
                      questionId={modalInfo?.id!}
                    />
                  )}
                  <Box sx={{ width: "100%", paddingTop: "20px" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      disabled={!isValid}
                      sx={{ padding: "10px" }}
                    >
                      <Typography fontSize="1rem" color="#fff">
                        {questionMethod == "update"
                          ? t("updateQuestion")
                          : t("createQuestion")}
                      </Typography>
                    </Button>
                  </Box>
                </Stack>
              )}
              {typeState && (
                <Stack
                  direction="row"
                  justifyContent="space-around"
                  sx={{ width: "100%" }}
                >
                  <CustomPickQuestionType
                    icon={<FaPen fontSize="xx-large" />}
                    type="written"
                    setType={setType}
                    setTypeState={setTypeState}
                  />
                  <CustomPickQuestionType
                    icon={<FaListUl fontSize="xx-large" />}
                    type="options"
                    setType={setType}
                    setTypeState={setTypeState}
                  />
                  <CustomPickQuestionType
                    icon={<FaExchangeAlt fontSize="xx-large" />}
                    type="match"
                    setType={setType}
                    setTypeState={setTypeState}
                  />
                </Stack>
              )}
            </>
          )}
          {(isLoading || loadingCreate) && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Paper>
      </Stack>
    </Modal>
  );
}

export default QuestionEditModal;
