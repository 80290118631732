import React, { useState } from "react";
import {
  courseResponse,
  relatedItemRequest,
} from "../../../interfaces/new-api-interfaces/course";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  fetchBlogs,
  fetchCourses,
  fetchShopItems,
} from "../../../api/endpoints-functions";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import AddRelatedItem from "../DashboardCourseComponents/add-related-item";
import { defaultTranslation } from "../../../untilites/functions";
import { fetchApi } from "../../../api/api";
import { toast } from "react-toastify";
import i18next, { t } from "i18next";

type Props = {
  id: number;
  handleNext: Function;
};
const inputStyle = {
  width: { xs: "100%", sm: "92%" },
  marginBottom: "30px",
};

const AddRelatedCourseItems = (props: Props) => {
  const [modifiedCourses, setModifiedCourses] = useState<
    { id: number; type: string; name: string }[]
  >([]);

  const [modifiedShopItems, setModifiedShopItems] = useState<
    { id: number; type: string; name: string }[]
  >([]);

  const [modifiedBlogs, setModifiedBlogs] = useState<
    { id: number; type: string; name: string }[]
  >([]);

  const { isLoading: loadingRequest, mutateAsync } = useMutation(
    (data: any) =>
      fetchApi(
        `/course/relatedItem/createRelatableItem/${props.id}`,
        "POST",
        data
      ),
    {
      onError: (error: any) => {
        toast.error(
          i18next.language === "en"
            ? "Something went wrong, please try again later."
            : "حدث خطأ ما، يرجى المحاولة مرة أخرى لاحقًا."
        );
      },
    }
  );

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
    setError,
  } = useForm<relatedItemRequest[]>({});

  const onSubmit: SubmitHandler<relatedItemRequest[]> = async (data) => {
    const relatable = Object.values(data).flat();
    try {
      mutateAsync({
        relatable,
      }).then((response) => {
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 202
        ) {
          toast.success(
            i18next.language === "en"
              ? "Related Items Added successfully"
              : "تم إضافة العناصر المتعلقة بالدورة بنجاح"
          );
          props.handleNext();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const {
    data: courses,
    isLoading,
    isError,
  } = useQuery(["get-courses"], () => fetchCourses(null), {
    onSuccess: (data) => {
      // Modify the data to the desired format
      const modifiedData = data.map((course) => ({
        id: course.id,
        type: "course",
        name: defaultTranslation(course, "name"),
      }));

      setModifiedCourses(modifiedData);
    },
  });

  const {
    data: shopItems,
    isLoading: l2,
    isError: e2,
  } = useQuery(["get-shop-items"], () => fetchShopItems(null), {
    onSuccess: (data) => {
      // Modify the data to the desired format
      const modifiedData = data.map((item) => ({
        id: item.id,
        type: "shopitem",
        name: defaultTranslation(item, "name"),
      }));

      setModifiedShopItems(modifiedData);
    },
  });

  const {
    data: blogs,
    isLoading: l3,
    isError: e3,
  } = useQuery(["get-blogs"], () => fetchBlogs(), {
    onSuccess: (data) => {
      // Modify the data to the desired format
      const modifiedData = data.map((item) => ({
        id: item.id,
        type: "blog",
        name: defaultTranslation(item, "name"),
      }));

      setModifiedBlogs(modifiedData);
    },
  });

  if (isLoading || l2 || l3 || loadingRequest)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "60vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  return (
    <form
      className="d-flex flex-wrap container"
      style={{
        width: "100%",
        gap: "2%",
        padding: "20px 0 50px 0",
        justifyContent: "center",
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box sx={inputStyle}>
        <AddRelatedItem
          control={control}
          tags={modifiedCourses!}
          name="course"
          label={t("addRelatedCourses")}
          watch={watch}
          setValue={setValue}
        />
      </Box>

      <Box sx={inputStyle}>
        <AddRelatedItem
          control={control}
          tags={modifiedShopItems!}
          name="shopitem"
          label={t("addRelatedShopItems")}
          watch={watch}
          setValue={setValue}
        />
      </Box>

      <Box sx={inputStyle}>
        <AddRelatedItem
          control={control}
          tags={modifiedBlogs!}
          name="blog"
          label={t("addRelatedBlogs")}
          watch={watch}
          setValue={setValue}
        />
      </Box>

      <div className="col-12 d-flex justify-content-center pt-4">
        <Button
          variant="contained"
          color="primary"
          className="mb-2 btn-hover-secondary btn-width-100"
          type="submit"
          sx={{ width: { xs: "100%", sm: "92%", lg: "30%" } }}
        >
          <Typography fontSize="large" color="#fff">
            {t("submit")}
          </Typography>
        </Button>
      </div>
    </form>
  );
};

export default AddRelatedCourseItems;
