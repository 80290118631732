import React from "react";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CardMedia } from '@mui/material';
import { Case, Course } from "../../interfaces/api-interfaces";
import { getImageFromServer } from "../../assets/JS/helpers";

export const CaseBox: React.FC<Case> = (myCase) => {
  const { t } = useTranslation()
  return (
    <Link to={`/case-content/${myCase.id}`} className="swiper-slide mb-30" style={{ margin: "0px 1%" }}>
      <div className="course box-shadow">
        <div className="info">
          <h3 className="title">{myCase.name}</h3>
        </div>
      </div>
    </Link>
  );
}