import React, { useState, useEffect } from "react";
import { CourseContent } from "../../components/Course/course-content-sections/course-content";
import { CourseContentSide } from "../../components/Course/course-content-sections/course-content-sidebar";
import "./course-content.css";
import { useLocation, useParams } from "react-router-dom";
import { LoadingPage } from "../../components/Common/loadingPage.component";
import { fetchApi } from "../../api/api";
import { useQuery } from "@tanstack/react-query";
import i18n from "i18next";
import { CourseApiResponse, Lesson } from "../../interfaces/api-interfaces";
import { Box, IconButton, SwipeableDrawer } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import {
  fetchCourse,
  fetchSections,
  fetchStudentCourse,
} from "../../api/endpoints-functions";
import {
  courseLesson,
  courseSection,
} from "../../interfaces/new-api-interfaces/course";
import { defaultTranslation } from "../../untilites/functions";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { CourseContentCertifications } from "../../components/Course/course-content-sections/course-content-certifications";

interface Props {}

const CourseContentPage: React.FC<Props> = () => {
  const cacheRtl = createCache({
    key: "muiltr",
    stylisPlugins: undefined,
  });

  const [openedSideNav, setOpenedSideNav] = useState(false);
  //state for oprning the certification lesson
  const [certificationsOpen, setCertificationsOpen] = useState(false);
  const urlState = useLocation();
  const [currentLesson, setCurrentLesson] = useState<any>({});
  const [firstMount, setFirstMount] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1001);
  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 1001); // Adjust the breakpoint as per your requirements
    }

    // Add event listener to window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [sideWidth, setSideWidth] = useState(25);
  const params = useParams();
  const {
    isLoading,
    isError,
    data: sections,
  } = useQuery(["course-content", params.id], () => fetchSections(params.id!), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      //on first mount if lesson sended by url state then move to the sended lesson, if not go to first unwatched lesson
      if (firstMount) {
        if (urlState?.state?.lesson) {
          setCurrentLesson(urlState.state.lesson);
        } else {
          const firstUnwatchedLesson = data
            .flatMap((section) => section.lessons)
            .find((lesson) => !lesson.is_watched);
          setCurrentLesson(firstUnwatchedLesson || data[0].lessons[0]);
        }
        setFirstMount(false);
      }
    },
  });

  const {
    isLoading: courseLoading,
    isError: courseError,
    refetch,
    data: course,
  } = useQuery(
    ["get-course-by-id", params.id],
    () => fetchStudentCourse(params.id!),
    {
      refetchOnWindowFocus: false,
    }
  );

  //function to handle navigating to next item (next item button)
  const navigateToNextLesson = () => {
    setCurrentLesson((prevLesson: Lesson) => {
      const currentSectionIndex = sections?.findIndex((section) =>
        section.lessons.some((lesson) => lesson.id === prevLesson.id)
      );
      const currentLessonIndex =
        currentSectionIndex !== -1
          ? sections![currentSectionIndex!].lessons.findIndex(
              (lesson: courseLesson) => lesson.id === prevLesson.id
            )
          : -1;
      if (
        currentSectionIndex !== -1 &&
        currentLessonIndex !== -1 &&
        currentLessonIndex! <
          sections![currentSectionIndex!].lessons.length! - 1
      ) {
        // If there is a next lesson in the current section, navigate to it
        return sections![currentSectionIndex!].lessons[currentLessonIndex! + 1];
      } else if (
        currentSectionIndex !== -1 &&
        currentSectionIndex! < sections?.length! - 1
      ) {
        // If the current lesson is the last one in the section, navigate to the first lesson of the next section
        return sections![currentSectionIndex! + 1].lessons[0];
      } else {
        // If the current lesson is the last one in the last section, do nothing
        return prevLesson;
      }
    });
  };

  //set current lesson by click on it
  const setCurrentLessonById = (id: number) => {
    sections?.forEach((sec) => {
      if (!!sec.lessons.find((les) => les.id === id)) {
        setCurrentLesson(sec.lessons.find((les) => les.id === id)!);
      }
    });
  };

  //handle section accordion state
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChangeExpanded =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  //when navigating between lessons throught next item button open the section that is navigated to
  useEffect(() => {
    const currentSectionId = sections?.find((section) =>
      section.lessons.map((lesson) => lesson.id).includes(currentLesson.id)
    )?.id;
    setExpanded(`panel${currentSectionId}`);
  }, [currentLesson]);

  //state to handle watching lesson state
  const [watched, setWatched] = useState<boolean>(currentLesson?.is_watched);
  useEffect(() => {
    setWatched(currentLesson.is_watched);
  }, [currentLesson]);

  //calculate if the current lesson is the last in course to not show next lesson button
  function isLastLessonInCourse(
    currentLesson: courseLesson,
    courseSections: courseSection[]
  ): boolean {
    const currentSectionId = currentLesson.section_id;

    // Find the current section
    const currentSection = courseSections.find(
      (section) => section.id === currentSectionId
    );

    if (currentSection) {
      // Check if the current section is the last section in the course
      const isLastSection = courseSections.length === currentSection.order;

      // Check if the current lesson is the last lesson in the current section
      const isLastLessonInSection =
        currentSection.lessons.length === currentLesson.order;

      // Return true if both conditions are met
      return isLastSection && isLastLessonInSection;
    }

    // Return false if the current section is not found
    return false;
  }

  if (isLoading || courseLoading) return <LoadingPage />;
  else
    return (
      <Box
        className="container"
        sx={{
          paddingBottom: { xs: "3rem", md: "10rem" },
          paddingTop: { xs: "1rem", md: "3rem" },
        }}
      >
        <Box className="row">
          {isMobile && (
            <Box
              sx={{
                width: "100%",
                overflowX: "hidden",
                transition: "0.3s",
                margin: 0,
                padding: 0,
              }}
            >
              <CacheProvider value={cacheRtl}>
                <SwipeableDrawer
                  open={openedSideNav}
                  onClose={() => setOpenedSideNav(false)}
                  onOpen={() => setOpenedSideNav(true)}
                  sx={{ width: "100%" }}
                >
                  <IconButton
                    onClick={() => setOpenedSideNav(false)}
                    sx={{
                      alignSelf: "flex-end",
                      paddingTop: "20px",
                      paddingRight: "20px",
                    }}
                  >
                    <AiOutlineClose size="40px" />
                  </IconButton>
                  <CourseContentSide
                    setOpenedSideNav={setOpenedSideNav}
                    currentLesson={+currentLesson?.id}
                    setCurrentLesson={setCurrentLessonById}
                    sectoins={sections!}
                    isEnrolled={true}
                    progress={
                      course?.progress ? Math.round(course?.progress! * 100) : 0
                    }
                    expanded={expanded}
                    handleChangeExpanded={handleChangeExpanded}
                    setCertificationsOpen={setCertificationsOpen}
                    certificationsOpen={certificationsOpen}
                  />
                </SwipeableDrawer>
              </CacheProvider>
            </Box>
          )}
          {!isMobile && (
            <Box
              sx={{
                width: isMobile ? 0 : `${sideWidth}%`,
                overflowX: "hidden",
                overflowY: "scroll",
                maxHeight: "600px",
                transition: "0.3s",
                margin: 0,
                padding: 0,
              }}
              className="course-scrollable-side"
            >
              <CourseContentSide
                currentLesson={+currentLesson?.id}
                setCurrentLesson={setCurrentLessonById}
                sectoins={sections!}
                isEnrolled={true}
                progress={
                  course?.progress ? Math.round(course?.progress! * 100) : 0
                }
                expanded={expanded}
                handleChangeExpanded={handleChangeExpanded}
                setCertificationsOpen={setCertificationsOpen}
                certificationsOpen={certificationsOpen}
              />
            </Box>
          )}
          <div
            className="m-0 p-0 crs-cnt-cont"
            style={{
              width: !isMobile ? `${100 - sideWidth}%` : "100%",
              transition: "0.3s",
            }}
          >
            {!certificationsOpen ? (
              <CourseContent
                watched={watched}
                setWatched={setWatched}
                courseId={params.id}
                {...currentLesson}
                setOpenedSideNav={setOpenedSideNav}
                openedSideNav={openedSideNav}
                navigateToNextLesson={navigateToNextLesson}
                lastLesson={isLastLessonInCourse(currentLesson, sections!)}
              />
            ) : Math.round(course?.progress! * 100) == 100 ? (
              <CourseContentCertifications
                setOpenedSideNav={setOpenedSideNav}
                openedSideNav={openedSideNav}
                courseId={params.id!}
              />
            ) : null}
          </div>
        </Box>
      </Box>
    );
};

export default CourseContentPage;
