import React, { useRef, useState } from "react";
import { IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
  icon: any;
  type: string;
  setType: Function;
  setTypeState: Function;
}

const CustomPickQuestionType: React.FC<Props> = ({
  icon,
  type,
  setType,
  setTypeState,
}) => {
  const handleSquareClick = () => {
    setType(type);
    setTypeState(false);
  };

  const { t } = useTranslation();

  return (
    <>
      <div
        style={{
          width: "130px",
          height: "130px",
          border: "1px solid #ddd",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          padding: "20px",
          borderRadius: "5px",
          gap: "10px",
          flexDirection: "column",
        }}
        onClick={handleSquareClick}
      >
        <IconButton edge="start" disabled sx={{ margin: 0, padding: 0 }}>
          {icon}
        </IconButton>
        <Typography color="primary.main" variant="h6">
          {t(`${type}`)}
        </Typography>
      </div>
    </>
  );
};

export default CustomPickQuestionType;
