import { useMutation } from "@tanstack/react-query";
import { useSetAtom } from "jotai";
import React, { Suspense, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { fetchApi } from "../../api/api";
import "./auth.style.css";
import {
  instructorIdAtom,
  roleAtom,
  tokenAtom,
  userAtom,
  userIdAtom,
} from "../../store";
import { LoadingPage } from "../../components/Common/loadingPage.component";
import i18n from "i18next";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { login } from "../../interfaces/new-api-interfaces/auth";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import bg from "../../assets/images/profile/profile-bg.jpg";
import { handleFormError } from "../../untilites/functions";
import i18next from "i18next";
import { Sendrequest } from "../../notification/firebase";

interface Props {}

const inputStyle = {
  width: { xs: "100%", sm: "90%" },
  marginBottom: "30px",
};

const Login: React.FC<Props> = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm<login>({
    defaultValues: {},
  });

  const { isLoading, mutateAsync, isError } = useMutation(
    (data: any) => fetchApi("/auth/user/login", "POST", data),
    {
      onError: (error: any) => {
        if (error?.response?.status === 420) {
          setError("root", {
            type: "manual",
            message: error.response.data.data[0],
          });
        }
      },
    }
  );

  const { t } = useTranslation();
  const navigate = useNavigate();
  const setToken = useSetAtom(tokenAtom);
  const setRole = useSetAtom(roleAtom);
  const setUserId = useSetAtom(userIdAtom);
  const setInstructorId = useSetAtom(instructorIdAtom);
  const setUser = useSetAtom(userAtom);
  const location = useLocation();

  const handleNavigation = (role: string) => {
    // Check if there is a previous page
    if (window.history.length > 2) {
      if (location.state?.from === "/register") {
        // If the previous page is "register", go back 2 steps in history
        navigate(-2);
      } else {
        // Go back to the previous page
        navigate(-1);
      }
    } else {
      // If no previous page, navigate based on the role and replace the history stack
      if (role === "instructor") {
        navigate("/profile", { replace: true });
      } else {
        navigate("/student-profile", { replace: true });
      }
    }
  };

  const onSubmit: SubmitHandler<login> = async (data) => {
    try {
      mutateAsync(data).then((response) => {
        if (response.status === 200) {
          //request for notification
          Sendrequest();
          const { data } = response.data;
          const {
            token,
            role,
            id,
            first_name,
            last_name,
            email,
            phone_number,
            address,
            birth_date,
            profile_image,
          } = data;

          if (!data.email_verified_at) {
            toast.warning(
              i18n.language === "ar"
                ? "بريدك الإلكتروني غير محقق"
                : "Your email is not verified"
            );
            const verifyState = {
              email: data.email,
              email_verified_at: data.email_verified_at,
            };
            navigate("/forget", {
              state: verifyState,
            });
          } else {
            setUser({
              id,
              firstName: first_name,
              lastName: last_name,
              email,
              phoneNumber: phone_number,
              address,
              age: birth_date,
              role,
              profileImage: profile_image,
              firstNameEn: data?.translations[1].first_name,
              lastNameEn: data?.translations[1].last_name,
              email_verified_at: data?.email_verified_at,
            });
            setToken(token);
            setRole(role);
            if (role == "instructor") setInstructorId(data.instructor.id);
            setUserId(id);

            handleNavigation(role);
          }
        }
      });
    } catch (error: any) {
      console.log("error", error);
    }
  };
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Box
      sx={{
        backgroundImage: `url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPositionX: "right",
      }}
    >
      <div
        style={{
          direction: `${i18n.language === "en" ? "ltr" : "rtl"}`,
          padding: "10vh 0",
        }}
      >
        <Typography
          variant="h3"
          sx={{ textAlign: "center", paddingBottom: { xs: "0", md: "30px" } }}
          color="primary.main"
          style={{ direction: `${i18n.language === "en" ? "ltr" : "rtl"}` }}
        >
          {t("AuthFormLog")}
        </Typography>
        <Box
          className="login-form-wrapper mt-sm-50 mt-xs-50"
          sx={{
            direction: `${i18n.language === "en" ? "ltr" : "rtl"}`,
            width: { xs: "90%", sm: "70%", md: "40%" },
          }}
        >
          <form
            style={{
              direction: `${i18n.language === "en" ? "ltr" : "rtl"}`,
              position: "relative",
              paddingTop: "50px",
              gap: "2%",
              flexDirection: "column",
              alignItems: "center",
            }}
            className="register-form d-flex justify-content-center"
            onSubmit={handleSubmit(onSubmit)}
          >
            {!isLoading && (
              <>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message:
                        i18n.language === "en"
                          ? "Invalid email address"
                          : "البريد الإلكتروني غير صالح",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={
                        i18n.language === "en" ? "Email" : "البريد الإلكتروني"
                      }
                      placeholder={
                        i18n.language === "en" ? "Email" : "البريد الإلكتروني"
                      }
                      required
                      error={!!errors.email}
                      helperText={errors.email?.message}
                      sx={inputStyle}
                    />
                  )}
                />

                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Confirm Password is required",
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("GlobalPassword")}
                      type={showPassword ? "text" : "password"}
                      required
                      sx={inputStyle}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword((prev) => !prev)}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />

                {errors.root && (
                  <Typography
                    color="error"
                    sx={{
                      textAlign: "start",
                      width: { xs: "100%", sm: "90%" },
                      paddingBottom: "10px",
                    }}
                  >
                    {errors.root.message}
                  </Typography>
                )}

                <Box
                  className="single-input"
                  sx={{
                    direction: `${i18n.language === "en" ? "ltr" : "rtl"}`,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: { xs: "100%", sm: "90%", lg: "90%" },
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className="mb-2 btn-hover-secondary btn-width-100"
                    type="submit"
                  >
                    <Typography fontSize="large" color="#fff">
                      {t("AuthFormLog")}
                    </Typography>
                  </Button>
                  <div
                    style={{
                      width: "100%",
                      marginTop: "2em",
                      gap: 5,
                      display: "flex",
                      justifyContent: "center",
                      flexDirection:
                        i18n.language === "ar" ? "row-reverse" : "row",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "120%",
                      }}
                    >
                      {t("DontHaveAccount")}
                    </Typography>
                    <Link to="/register" className="underline">
                      <Typography
                        sx={{
                          color: "#001233",
                          fontWeight: "bold",
                          fontSize: "120%",
                          textDecoration: "underline",
                        }}
                      >
                        {t("LoginFormRegister")}
                      </Typography>
                    </Link>
                  </div>
                  <Link to="/forget">
                    <Typography>{t("ForgetPassword")}</Typography>
                  </Link>
                </Box>
              </>
            )}
            {isLoading && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  minHeight: "250px",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </form>
        </Box>
      </div>
    </Box>
  );
};

export default Login;
