import { Box, Skeleton, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useParams } from "react-router-dom";
import { getBasicDate, getImageFromServer } from "../../assets/JS/helpers";
import { defaultTranslation } from "../../untilites/functions";
import { fetchBlog } from "../../api/endpoints-functions";
import { BlogSection } from "../../interfaces/new-api-interfaces/blog";
import i18next, { t } from "i18next";
import { PiNotePencilBold } from "react-icons/pi";
import { BsCalendarDate } from "react-icons/bs";
import audioImage from "../../assets/images/courses/audio-placeholder-2.jpg";
import {
  BigPlayButton,
  ControlBar,
  ForwardControl,
  LoadingSpinner,
  PlayToggle,
  Player,
  ReplayControl,
} from "video-react";

export const BlogContent: React.FC = () => {
  const blogId = useParams();
  const { isLoading, data, isError } = useQuery(["get-blog"], () =>
    fetchBlog(blogId.id!)
  );

  return (
    <div
      className="blog-3 blog-details col"
      data-aos="fade-up"
      style={{ marginTop: "10vh", overflowWrap: "anywhere" }}
    >
      {!isLoading ? (
        <div style={{ margin: "5vh auto", position: "relative" }}>
          <img
            src={getImageFromServer(data?.image!)}
            alt="Blog"
            style={{
              width: "100%",
              maxHeight: "580px",
              objectFit: "cover",
              borderRadius: "8px",
            }}
          />
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              background:
                "linear-gradient(90deg, rgba(92,83,135,1) 16%, rgba(0,0,0,0.3757878151260504) 100%)",
            }}
          ></div>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              padding: { xs: "0 30px", md: "0 80px" },
            }}
          >
            <Typography
              sx={{
                marginBottom: "10px",
                color: "white",
                fontSize: { xs: "22px", sm: "38px" },
              }}
            >
              {defaultTranslation(data, "name")}
            </Typography>
            <div style={{ display: "flex", gap: "1.5rem", flexWrap: "wrap" }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  color: "white",
                }}
              >
                <PiNotePencilBold />
                {t("By") + " : "}
                {i18next.language === "ar"
                  ? data?.created_by.ar.first_name +
                    " " +
                    data?.created_by.ar.last_name
                  : data?.created_by.en.first_name +
                    " " +
                    data?.created_by.en.last_name}
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                  fontWeight: 600,
                  color: "white",
                }}
              >
                <BsCalendarDate />
                {data?.created_at ? getBasicDate(data?.created_at) : ""}
              </Typography>
            </div>
          </Box>
        </div>
      ) : (
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={250}
          sx={{ mb: 2 }}
        />
      )}

      {!isLoading ? (
        <>
          {data?.blog_sections.map((section: BlogSection) => (
            <>
              <Box>
                <p
                  style={{ marginBottom: "1rem" }}
                  dangerouslySetInnerHTML={{
                    __html: defaultTranslation(section, "text"),
                  }}
                />
              </Box>
              <Box>
                {section.type === "image" && (
                  <img
                    src={getImageFromServer(section.file)}
                    alt="Blog"
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      marginBottom: "1rem",
                      maxHeight: "480px",
                    }}
                  />
                )}
                {section.type === "video" && (
                  <Player
                    src={getImageFromServer(section.file)}
                    aspectRatio="16:9"
                  >
                    <BigPlayButton position="center" />
                    <LoadingSpinner />
                    <ControlBar autoHide={false}>
                      <PlayToggle />
                      <ReplayControl seconds={10} />
                      <ForwardControl seconds={10} />
                    </ControlBar>
                  </Player>
                )}
                {section.type === "audio" && (
                  <Player
                    poster={audioImage}
                    src={section.file}
                    aspectRatio="16:9"
                  >
                    <BigPlayButton position="center" />
                    <LoadingSpinner />
                    <ControlBar autoHide={false}>
                      <PlayToggle />
                      <ReplayControl seconds={10} />
                      <ForwardControl seconds={10} />
                    </ControlBar>
                  </Player>
                )}
              </Box>
            </>
          ))}
        </>
      ) : (
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={250}
          sx={{ mb: 2 }}
        />
      )}
    </div>
  );
};
