import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  FormControlLabel,
  Switch,
  Tabs,
  Tab,
} from "@mui/material";
import i18next from "i18next";
import { instructorConsultation } from "../../../interfaces/new-api-interfaces/consultations";
import InstructorConsultationTableItem from "./instructor-consultation-table-item";
import LiveConsultations from "./live-consultations";
import { useQuery } from "@tanstack/react-query";
import { fetchInstructorLiveConsultations } from "../../../api/endpoints-functions";
import LoadingTable from "../../Common/loading-table";

interface Props {
  Consultations: instructorConsultation[];
  isChecked: boolean;
  setIsChecked: any;
  activeTab: string | null;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const InstructorConsultations: React.FC<Props> = ({
  Consultations,
  isChecked,
  setIsChecked,
  activeTab,
}) => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);

  const [selectedStatus, setSelectedStatus] = useState<
    | "pending"
    | "scheduled"
    | "paid"
    | "rejected by instructor"
    | "rejected by student"
    | "done"
  >("pending");
  const {
    data: liveConsultations,
    isLoading: liveConsLoading,
    isFetching: liveConsFetching,
  } = useQuery(["live-consultations", selectedStatus], () =>
    fetchInstructorLiveConsultations(selectedStatus)
  );

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <div
      id="consultations"
      className={`tab-pane fade show ${
        activeTab == "consultations" ? "active" : ""
      }`}
    >
      <div className="learn-press-subtab-content">
        <div className="tab-content">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabValue}
                onChange={handleChangeTab}
                aria-label="basic tabs example"
              >
                <Tab
                  label={
                    i18next.language == "en"
                      ? "Text Consultations"
                      : "استشارات نصية"
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  label={
                    i18next.language == "en"
                      ? "Video Consultations"
                      : "استشارات فيديو"
                  }
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
          </Box>
          <CustomTabPanel value={tabValue} index={0}>
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: "1rem",
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={isChecked}
                      onChange={(e) => setIsChecked(e.target.checked)}
                    />
                  }
                  label={i18next.language === "ar" ? "مجاب عليها" : "answered"}
                />
              </Box>
              <div className="col-12 scroll">
                {Consultations && Consultations.length > 0 ? (
                  <TableContainer>
                    <Table className="lp-list-table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ borderTopLeftRadius: "20px" }}>
                            <Typography fontSize="large">
                              {t("consultationContent")}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography fontSize="large">
                              {t("name")}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography fontSize="large">
                              {t("GlobalCreatedAt")}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ borderTopRightRadius: "20px" }}>
                            <Typography fontSize="large">
                              {t("status")}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Consultations.map(
                          (Consultation: instructorConsultation) => (
                            <InstructorConsultationTableItem
                              key={Consultation.id}
                              linkTo={
                                "/instructor-consultation-details/" +
                                Consultation.id
                              }
                              {...Consultation}
                              Consultation={Consultation}
                            />
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <div className="row">
                    <div className="col-12">
                      <div className="learn-press-message success">
                        <i className="bx bx-info-circle"></i>
                        {i18n.language === "en"
                          ? "You Don't have any previous consultation!"
                          : "لا تملك استشارات سابقة!"}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            <LiveConsultations
              data={liveConsultations!}
              status={selectedStatus}
              setStatus={setSelectedStatus}
              isLoading={liveConsLoading}
              isFetching={liveConsFetching}
            />
          </CustomTabPanel>
        </div>
      </div>
    </div>
  );
};

export default InstructorConsultations;
