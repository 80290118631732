import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import StudentsTestsToCorrectTable from "../../components/InstructorDashboard/TestConrrection/student-tests-to-correct-table";
import { Box, Typography } from "@mui/material";
import { fetchStudentsForCourseTestList } from "../../api/endpoints-functions";
import { LoadingPage } from "../../components/Common/loadingPage.component";
import { defaultTranslation } from "../../untilites/functions";
import i18next from "i18next";

interface Props {}

const StudentsCourseTestsToCorrect: React.FC<Props> = () => {
  const { id } = useParams();
  const { isLoading, isError, isFetching, data } = useQuery(
    ["student-test-list"],
    () => fetchStudentsForCourseTestList(id!)
  );

  if (isLoading) return <LoadingPage />;
  return (
    <Box
      className="container"
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        gap: "20px",
        paddingTop: "40px",
      }}
    >
      <Typography variant="h4" color="primary.main">
        {i18next.language == "en"
          ? "Student's Tests To Correct - "
          : "اختبارات متاحة للتصحيح - "}{" "}
        {defaultTranslation(data?.name, "name")}
      </Typography>
      <StudentsTestsToCorrectTable
        students={data?.users!}
        testId={id!}
        isRelatedToCourse={true}
        isFetching={isFetching}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default StudentsCourseTestsToCorrect;
