import React from "react";
import { useTranslation } from "react-i18next";
import { getImageFromServer } from "../../../assets/JS/helpers";
import { Link } from "react-router-dom";
import {
  Box,
  CardMedia,
  Chip,
  CircularProgress,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { AiOutlineFileText } from "react-icons/ai";
import { BsCheckCircle } from "react-icons/bs";
import i18next from "i18next";
import { profileCoursesResponse } from "../../../interfaces/new-api-interfaces/profile";
import { defaultTranslation } from "../../../untilites/functions";
import { fetchApi } from "../../../api/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

const InstructorCourseItem: React.FC<profileCoursesResponse> = (course) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [imgLink, setImgLink] = React.useState(
    getImageFromServer(course.image)
  );

  const { isLoading: loadingActivation, mutateAsync: Activation } = useMutation(
    (id: number) => fetchApi(`/course/course/activate/${id}`, "PUT"),
    {
      onError: (error: any) => {
        toast.error(
          i18next.language === "en"
            ? "Something went wrong, please try again later."
            : "حدث خطأ ما، يرجى المحاولة مرة أخرى لاحقًا."
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["instructor-courses"]);
        toast.success(
          i18next.language === "en"
            ? "Course Edited Successfully"
            : "تم تعديل الدورة بنجاح"
        );
      },
    }
  );

  return (
    <Link
      to={`/course-details/${course.id}`}
      className="swiper-slide mb-30 "
      style={{ margin: "0px 1%" }}
    >
      <Box
        className="course box-shadow"
        sx={{ padding: { xs: "0px 10px", md: "0px 30px" } }}
      >
        <div>
          <div>
            <div className="image" style={{ position: "relative" }}>
              <CardMedia
                onError={(e: any) =>
                  setImgLink("/assets/images/placeholder.png")
                }
                component="img"
                image={imgLink}
                alt={defaultTranslation(course, "name")}
                sx={{
                  borderRadius: "10px",
                  maxHeight: {
                    xs: "10rem",
                    sm: "10rem",
                    md: "8.5rem",
                    lg: "12rem",
                  },
                }}
                className="img-fluid"
              />
              {course?.is_approved == false && (
                <Chip
                  variant="filled"
                  label={t("underReview")}
                  sx={{
                    position: "absolute",
                    top: 10,
                    left: 10,
                    bgcolor: "#ffdf76",
                    fontWeight: 600,
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div
          className="info"
          style={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          <Typography variant="h5">
            {defaultTranslation(course, "name")}
          </Typography>
          {course.translations[i18next.language]?.web_description && (
            <Typography>
              {course.translations[i18next.language]?.web_description &&
                course.translations[i18next.language]?.web_description.slice(
                  0,
                  90
                )}
              {course.translations[i18next.language]?.web_description &&
              course.translations[i18next.language]?.web_description.length > 90
                ? "..."
                : ""}
            </Typography>
          )}
          {course?.progress &&
          course?.progress != 100 &&
          course?.progress != 0 ? (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Typography>{t("CourseProgress") + " : "} </Typography>
              <div className="progress">
                <Box
                  className="progress-bar"
                  sx={{
                    width: `${course?.progress * 100}%`,
                    bgcolor: course?.progress === 100 ? "#49b749" : "",
                  }}
                >
                  <Typography fontSize="small">
                    {(course?.progress * 100).toFixed(0)}%
                  </Typography>
                </Box>
              </div>
            </Box>
          ) : null}
          {course?.progress && course?.progress == 100 ? (
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
                padding: "5px 0px",
              }}
            >
              <BsCheckCircle fontSize="xx-large" color="green" />
              <Typography>{t("completed")}</Typography>
            </Box>
          ) : null}
        </div>
        {course.lesson_count && (
          <Box
            sx={{
              display: "flex",
              gap: { xs: "10px", sm: "10px", lg: "10px" },
              flexWrap: "wrap",
              padding: "5px",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <Box sx={{ fontSize: "large" }}>
                <AiOutlineFileText />
              </Box>
              <Typography sx={{ fontSize: "1rem", whiteSpace: "nowrap" }}>
                {course.lesson_count} {t("CourseDetailsLessons")}
              </Typography>
            </Box>
            {/* {course.testsCount != 0 && (
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <Box sx={{ fontSize: "large" }}>
                  <AiOutlineFileText />
                </Box>
                <Typography sx={{ fontSize: "1rem", whiteSpace: "nowrap" }}>
                  {course.testsCount} tests
                </Typography>
              </Box>
            )} */}
          </Box>
        )}
        {course?.is_active != undefined && (
          <Stack
            direction="row"
            gap="10px"
            alignItems="center"
            justifyContent="flex-end"
          >
            {!loadingActivation && (
              <>
                <Typography>{t("Activation") + " : "}</Typography>
                <Switch
                  checked={course.is_active}
                  onClick={(e) => {
                    e.stopPropagation();
                    Activation(course.id);
                  }}
                />
              </>
            )}
            {loadingActivation && <CircularProgress size={24} />}
          </Stack>
        )}
      </Box>
    </Link>
  );
};

export default InstructorCourseItem;
