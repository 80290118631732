import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation, Trans } from "react-i18next";
import {PageTitle} from "../../components/Common/page-title-section.component";
import {Pagination} from "../../components/Common/pagination";
import { fetchApi } from "../../api/api";
import { Case} from "../../interfaces/api-interfaces";
import { useLocation } from "react-router-dom";
import { Skeleton } from "@mui/material";
import {CaseBox} from "../../components/Case/case-box";

interface Props {}
type filterType = "" | "date";

const filterBySearchValue = (Cases: Case[], searchValue: string) =>
  Cases.filter((course: Case) =>
    course?.name?.toLowerCase().includes(searchValue)
  );

const filterCases = (
  Cases: Case[],
  filter: filterType,
  searchValue: string,
  categoryId?: number
) => {
  if (filter === "") return filterBySearchValue(Cases, searchValue);
  if (filter === "date")
    return filterBySearchValue(
      Cases.sort(
        (a, b) =>
          new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
      ),
      searchValue
    );
  return filterBySearchValue(Cases, searchValue);
};

const CasePage: React.FC<Props> = ({}) => {
  const { state } = useLocation();
  const { t } = useTranslation();
  const { data, isLoading } = useQuery(["get-cases"], () =>
    fetchApi("/use-case-studies", "GET", {})
  );
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState<filterType>("");

  const ITEMS_PER_PAGE: number = +process.env.REACT_APP_ITEMS_PER_PAGE!;
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(ITEMS_PER_PAGE);

  const paginate = (current: number) => {
    setCurrentPageNumber(current);
    setMin(ITEMS_PER_PAGE * (current - 1));
    setMax(current * ITEMS_PER_PAGE);
  };

  return (
    <div>
      {
        <PageTitle
          link={t("NavHeaderCasesGrid")}
          prevLinks={[
            {
              title: t("GlobalHome"),
              link: "/",
            },
          ]}
          title={t("NavHeaderCasesGrid")}
        />
      }

      <div className="section section-padding-bottom">
        <div className={isLoading ? "container-lg" : "container"}>
          {!isLoading ? (
            <div className="row justify-content-center align-items-center max-mb-20">
              <div className="col-sm-10 col-md-3 max-mb-10 mb-20">
                <p className="result-count">
                  <Trans
                    i18nKey="CaseGridSubTitle"
                    count={
                      filterCases(data?.data, filterValue, searchValue)
                        .length
                    }
                  >
                    We found <span>count</span> cases available for you
                  </Trans>
                </p>
              </div>
              <div className="sidebar-widget-content col-sm-10 col-md-6 mb-20">
                <div className="sidebar-widget-search">
                  <form action="#">
                    <input
                      type="text"
                      placeholder={t("HeaderNavSearch")}
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.currentTarget.value)}
                    />
                    <button>
                      <i className="fas fa-search"></i>
                    </button>
                  </form>
                </div>
              </div>
              <div className="col-sm-10 col-md-3 max-mb-10 mb-20">
                <select
                  className="sort-by w-100"
                  defaultValue={state?.id ? "category" : ""}
                  // @ts-ignore
                  onChange={(e) => setFilterValue(e.target.value)}
                >
                  <option value="">{t("CaseGridDefault")}</option>
                  <option value="date">{t("CaseGridLatest")}</option>
                </select>
              </div>
            </div>
          ) : (
            <div className="row justify-content-between align-items-center max-mb-20">
              <Skeleton variant="text" height={"3rem"} width={"20%"} />
              <Skeleton variant="text" height={"3rem"} width={"50%"} />
              <Skeleton variant="text" height={"3rem"} width={"20%"} />
            </div>
          )}
          <div
            className={
              !isLoading
                ? "row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30 justify-content-center justify-content-md-start"
                : ""
            }
          >
            {!isLoading ? (
              filterCases(data?.data, filterValue, searchValue)
                .filter((_1: any, idx: number) => idx >= min && idx < max)
                .map((myCase: Case) => (
                  <div className="col-3 my-2">
                    <>
                      {!isLoading ? (
                        <CaseBox key={myCase.id} {...myCase} />
                      ) : (
                        <Skeleton
                          key={myCase.id}
                          variant="rectangular"
                          width={300}
                          height={250}
                          sx={{ mb: 2 }}
                        />
                      )}
                    </>
                  </div>
                ))
            ) : (
              <p style={{ margin: "0 auto", fontSize: "2em" }}>
                There are no cases yet
              </p>
            )}

            {!isLoading && (
              <div>
                <>
                  {filterCases(data?.data, filterValue, searchValue).length >
                  ITEMS_PER_PAGE ? (
                    <Pagination
                      currentPageNumber={currentPageNumber}
                      paginate={paginate}
                      totalPages={
                        Math.ceil(
                          filterCases(data?.data, filterValue, searchValue)
                            .length / ITEMS_PER_PAGE
                        ) || 0
                      }
                    />
                  ) : null}
                </>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CasePage;
