// Import the functions you need from the SDKs you need
import { useMutation } from "@tanstack/react-query";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { fetchApi } from "../api/api";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC_HvIgpi7OH2pds02r7mX_iN8irY3ic4Q",
  authDomain: "uh-training-ffa60.firebaseapp.com",
  projectId: "uh-training-ffa60",
  storageBucket: "uh-training-ffa60.appspot.com",
  messagingSenderId: "551318642185",
  appId: "1:551318642185:web:6b634ea6ab1522ddd0a2d8",
}; // Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const onMessager = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export const Sendrequest = () => {
  console.log("Requesting User Permission……");

  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification User Permission Granted.");

      return getToken(messaging, {
        vapidKey: `BFTBPC4MTbP3H4dwFrwN1p6c6p-qWi6V8kUHq_iPvuntDYGpL7-vQLyOBjunQpnqKDBIdprTYRckqdHoCkysAxs`,
      })
        .then((currentToken) => {
          if (currentToken) {
            fetchApi("/auth/notification/deviceToken", "POST", {
              platform: "web",
              device_token: currentToken,
            }).then((res) => {
              console.log(res);
              if (res.status === 200) {
                console.log("Token saved successfully.");
                console.log(res.data.data.id);
                localStorage.setItem("deviceId", res.data.data.id);
              }
            });
          } else {
            console.log("Failed to generate the registration token.");
          }
        })

        .catch((err) => {
          console.log(
            "An error occurred when requesting to receive the token.",
            err
          );
        });
    } else {
      console.log("User Permission Denied.");
    }
  });
};
