import React from "react";
import { useTranslation } from "react-i18next";
import { TextField, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import i18next from "i18next";

type Props = {
  searchValue: string;
  setSearchValue: Function;
};

export function GridSearch({ searchValue, setSearchValue }: Props) {
  const { t } = useTranslation();

  return (
    <div className="sidebar-widget-content col-sm-10 col-md-5 mb-20">
      <div className="sidebar-widget-search">
        <TextField
          fullWidth
          variant="outlined"
          placeholder={t("HeaderNavSearch")}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          InputProps={{
            endAdornment:
              i18next.language == "en" ? (
                <IconButton aria-label="search">
                  <SearchIcon />
                </IconButton>
              ) : null,
            startAdornment:
              i18next.language == "ar" ? (
                <IconButton aria-label="search">
                  <SearchIcon />
                </IconButton>
              ) : null,
          }}
        />
      </div>
    </div>
  );
}
