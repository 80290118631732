import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import i18next from "i18next";
import { SetStateAction } from "jotai";
import React from "react";
import { fetchCertificates } from "../../api/endpoints-functions";
import CertificateCard from "../InstructorDashboard/DashboardCourseComponents/CertificateCard";

const QuizzCertificates = ({
  selectedIds,
  setSelectedIds,
  open,
  setOpen,
  setSelectedCertificates,
}: {
  selectedIds: number[];
  setSelectedIds: React.Dispatch<SetStateAction<number[]>>;
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  setSelectedCertificates: React.Dispatch<SetStateAction<any>>;
}) => {
  const { data, isLoading } = useQuery(["certificates"], () =>
    fetchCertificates()
  );

  const handleAddCertificates = () => {
    const arr = data?.filter((item) => selectedIds.includes(item.id));
    setSelectedCertificates(arr);
    setOpen(false);
  };

  return (
    <Dialog
      maxWidth="lg"
      dir={i18next.language === "ar" ? "rtl" : "ltr"}
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle align="center">
        {i18next.language === "ar" ? "إضافة شهادات" : "Add Certificates"}
      </DialogTitle>
      <DialogContent>
        <Box>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Grid container spacing={2}>
              {data?.map((item) => (
                <Grid key={item.id} xs={12} md={6} lg={3} item>
                  <CertificateCard
                    item={item}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAddCertificates} variant="contained">
          {i18next.language === "ar" ? "تم" : "Done"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuizzCertificates;
