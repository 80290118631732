import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";
import { SetStateAction } from "jotai";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { rejectLiveConsultation } from "../../../api/endpoints-functions";
import { toast } from "react-toastify";

const RejectConsultationModal = ({
  open,
  setOpen,
  id,
}: {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  id: number | undefined;
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();
  const queryClient = useQueryClient();
  const reject = useMutation(
    ({ id, data }: any) => rejectLiveConsultation(id, data),
    {
      onSuccess: () => {
        setOpen(false);
        queryClient.invalidateQueries(["live-consultations"]);
      },
      onError: (error: any) => {
        Object.keys(error.response.data.errors).forEach((errorKey) => {
          toast.error(`${errorKey} field is required!`);
        });
      },
    }
  );
  const onSubmit = (value: any) => {
    console.log(value);
    reject.mutateAsync({
      id: id,
      data: { notes: value.notes },
    });
  };

  useEffect(() => {
    reset();
  }, [open]);

  return (
    <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
      <DialogTitle align="center" fontSize="1.3rem">
        {i18next.language === "ar"
          ? `رفض الاستشارة - #${id}`
          : `Reject Consultation - #${id}`}
      </DialogTitle>
      <DialogContent sx={{ padding: "1rem" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="notes"
            rules={{
              required:
                i18next.language === "ar"
                  ? "!يرجى ادخال سبب الرفض"
                  : "Reason is required!",
            }}
            render={({ field }: any) => (
              <TextField
                {...field}
                label={
                  i18next.language === "ar" ? "سبب الرفض" : "Reject Reason"
                }
                error={!!errors?.notes}
                helperText={errors?.notes?.message?.toString()}
                fullWidth
                sx={{ margin: "1rem 0" }}
                dir={i18next.language === "ar" ? "rtl" : "ltr"}
              />
            )}
          />{" "}
          <DialogActions>
            <Stack
              justifyContent={i18next.language === "ar" ? "start" : "end"}
              width="100%"
              direction="row"
              gap="0.7rem"
            >
              <Button
                disabled={reject.isLoading}
                type="submit"
                variant="contained"
              >
                {reject.isLoading
                  ? "Loading..."
                  : i18next.language === "ar"
                  ? "تأكيد"
                  : "Confirm"}
              </Button>
              <Button variant="outlined" onClick={() => setOpen(false)}>
                {i18next.language === "ar" ? "إغلاق" : "Close"}
              </Button>
            </Stack>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default RejectConsultationModal;
