import React from "react";
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
type Props = {
  columns: number;
  isLoading: boolean;
  isFetching: boolean;
  children: React.ReactNode;
};
const LoadingTable: React.FC<Props> = ({
  columns,
  isLoading,
  isFetching,
  children,
}) => {
  const renderSkeletonRow = () => {
    return (
      <TableRow>
        {[...Array(columns)].map((_, index) => (
          <TableCell key={index}>
            <Skeleton animation="wave" />
          </TableCell>
        ))}
      </TableRow>
    );
  };
  if (isLoading || isFetching) {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {[...Array(columns)].map((_, index) => (
                <TableCell key={index}>
                  <Skeleton animation="wave" />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {renderSkeletonRow()}
            {renderSkeletonRow()}
            {renderSkeletonRow()}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    return <>{children}</>;
  }
};
export default LoadingTable;
