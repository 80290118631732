import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { TiDeleteOutline } from "react-icons/ti";
import { fetchApi } from "../../../api/api";
import { toast } from "react-toastify";
import i18next, { t } from "i18next";

type Props = {
  control: any;
  getValues: Function;
  setValue: Function;
  errors: any;
  questionMethod: "update" | "create";
  questionId: number;
  is_graded: boolean;
};

const AddEditOptions = (props: Props) => {
  const {
    isLoading,
    isError,
    mutateAsync: deleteOption,
  } = useMutation(
    (data: any) => fetchApi(`/test/option/${data.id}`, "DELETE", data),
    {
      onError: () => {
        toast.error(
          i18next.language === "en"
            ? "Something went wrong Deleting Option, please try again later."
            : "حدث خطأ ما أثناء حذف الخيارات، يرجى المحاولة مرة أخرى لاحقًا."
        );
      },
      onSuccess: () => {
        toast.success(
          i18next.language === "en"
            ? "option deleted successfully"
            : "تم حذف الخيار بنجاح"
        );
      },
    }
  );

  const {
    isLoading: loadingAdd,
    isError: errorAdd,
    mutateAsync: addOption,
  } = useMutation((data: any) => fetchApi(`/test/option`, "POST", data), {
    onError: () => {
      toast.error(
        i18next.language === "en"
          ? "Something went wrong Adding Option, please try again later."
          : "حدث خطأ ما أثناء إضافة الخيارات، يرجى المحاولة مرة أخرى لاحقًا."
      );
    },
    onSuccess: () => {
      toast.success(
        i18next.language === "en"
          ? "option deleted successfully"
          : "تم إضافة الخيار بنجاح"
      );
    },
  });

  const [newKey, setNewKey] = useState("");
  const [newKeyEn, setNewKeyEn] = useState("");

  const handleAddOption = (newKey: string, newKeyEn: string) => {
    const currentOptions = props.getValues("options");

    if (props.questionMethod == "update") {
      addOption(
        {
          question_id: props.questionId,
          value: newKey,
          value_en: newKeyEn,
          is_true: false,
        },
        {
          onSuccess: (data) => {
            const newOptions = [
              ...currentOptions,
              {
                id: data.data.data.id,
                key: newKey,
                key_en: newKeyEn,
                is_true: false,
              },
            ];
            props.setValue("options", newOptions);
          },
        }
      );
    } else {
      const newOptions = [
        ...currentOptions,
        { key: newKey, key_en: newKeyEn, is_true: false },
      ];
      props.setValue("options", newOptions);
    }
  };

  const handleDeleteOption = (index: number, id: number) => {
    const currentOptions = props.getValues("options");
    const newOptions = [...currentOptions];

    if (props.questionMethod == "update") {
      deleteOption(
        { id: id },
        {
          onSuccess: () => {
            newOptions.splice(index, 1);
            props.setValue("options", newOptions);
          },
        }
      );
    } else {
      newOptions.splice(index, 1);
      props.setValue("options", newOptions);
    }
  };

  return (
    <Box sx={{ width: "95%" }}>
      <Stack direction="row" alignItems="center" gap={3} paddingBottom="20px">
        <TextField
          label={t("optionAr")}
          defaultValue=""
          onChange={(e) => {
            setNewKey(e.target.value);
          }}
          value={newKey}
          sx={{ width: "45%" }}
          size="small"
        />
        <TextField
          label={t("optionEn")}
          defaultValue=""
          onChange={(e) => {
            setNewKeyEn(e.target.value);
          }}
          value={newKeyEn}
          sx={{ width: "45%" }}
          size="small"
        />
        <Button
          onClick={() => {
            handleAddOption(newKey, newKeyEn);
            setNewKey("");
            setNewKeyEn("");
          }}
          variant="contained"
          size="small"
          disabled={loadingAdd || newKey == ""}
        >
          <Typography fontSize="small" whiteSpace="nowrap">
            {!loadingAdd && <>{t("addOption")}</>}
            {loadingAdd && (
              <Stack justifyContent="center" alignItems="center">
                <CircularProgress size={18} sx={{ color: "#fff" }} />
              </Stack>
            )}
          </Typography>
        </Button>
      </Stack>
      <Controller
        name="options"
        control={props.control}
        defaultValue={[]}
        rules={{
          validate: (value) =>
            props.is_graded
              ? value.some((option: any) => option.is_true)
              : true || "Please select an option",
        }}
        render={({ field: { onChange, value } }) => (
          <RadioGroup>
            {value.map((option: any, index: number) => (
              <Stack
                key={index}
                direction="row"
                alignItems="center"
                paddingY={2}
              >
                <Stack direction="row" gap={2} width="100%">
                  {isLoading ? (
                    <IconButton>
                      <CircularProgress size={18} />
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDeleteOption(index, option.id)}
                    >
                      <TiDeleteOutline />
                    </IconButton>
                  )}
                  <TextField
                    label={`${t("option")} #${index + 1} ${t("inAr")}`}
                    defaultValue=""
                    value={option.key}
                    onChange={(e) => {
                      const newOptions = [...value];
                      newOptions[index].key = e.target.value;
                      onChange(newOptions);
                    }}
                    sx={{ width: "45%" }}
                    size="small"
                  />
                  <TextField
                    label={`${t("option")} #${index + 1} ${t("inEn")}`}
                    defaultValue=""
                    value={option.key_en}
                    onChange={(e) => {
                      const newOptions = [...value];
                      newOptions[index].key_en = e.target.value;
                      onChange(newOptions);
                    }}
                    sx={{ width: "45%" }}
                    size="small"
                  />
                </Stack>
                <FormControlLabel
                  value={option.key}
                  control={
                    <Radio
                      size="small"
                      sx={{
                        width: "5%",
                        display: props.is_graded ? "inline" : "none",
                      }}
                    />
                  }
                  label={""}
                  checked={option.is_true}
                  onChange={(e, checked) => {
                    const newOptions = value.map((item: any, i: number) => {
                      if (i === index) {
                        return { ...item, is_true: checked };
                      } else {
                        return { ...item, is_true: false };
                      }
                    });
                    onChange(newOptions);
                  }}
                />
              </Stack>
            ))}
          </RadioGroup>
        )}
      />
      <Typography color="error" sx={{ paddingBottom: "5px" }}>
        {props.errors?.options?.message}
      </Typography>
    </Box>
  );
};

export default AddEditOptions;
