import React, { useState } from "react";

import { Typography, Box, Tabs, Tab } from "@mui/material";
import TestsResults from "./TestsResults";
import i18next from "i18next";

interface Props {
  tests: any[];
  globalTests: any[];
  setIsCorrected: any;
  isCorrected: boolean;
  activeTab: string | null;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const Results: React.FC<Props> = ({
  tests,
  globalTests,
  setIsCorrected,
  isCorrected,
  activeTab,
}) => {
  const [tabValue, setTabValue] = useState(0);

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <div
      id="results"
      className={`profile-content-quizzes tab-pane fade pt-5  show ${
        activeTab === "results" ? "active" : ""
      }`}
    >
      <div className="learn-press-subtab-content">
        <div className="tab-content">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabValue}
                onChange={handleChangeTab}
                aria-label="basic tabs example"
              >
                <Tab
                  label={
                    i18next.language == "en" ? "Global Tests" : "اختبارات عامة"
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  label={
                    i18next.language == "en"
                      ? "Courses Tests"
                      : "اختبارات الدورات"
                  }
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
          </Box>
          <div>
            <CustomTabPanel value={tabValue} index={0}>
              <TestsResults
                isCorrected={isCorrected}
                setIsCorrected={setIsCorrected}
                tests={globalTests}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <TestsResults
                isCorrected={isCorrected}
                setIsCorrected={setIsCorrected}
                isRelatedToCourse={true}
                tests={tests}
              />
            </CustomTabPanel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Results;
