import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import i18n from "i18next";
import { fetchApi } from "../../api/api";
import {DisplayCase} from "../../components/Case/display-case";
import {LoadingPage} from "../../components/Common/loadingPage.component";
import { Button } from "@mui/material";
import { toast } from "react-toastify";

interface RequestObject {
  question: any;
  answer: string;
  questionValue: string;
  answerValue: string;
  next: number;
}

const CaseContent: React.FC = () => {
  const params = useParams();

  const { data, isLoading, isError } = useQuery(["get-case", params.id], () =>
    fetchApi(`/use-case-studies/${params.id}`, "GET", {})
  );
  //states
  const [request, setRequest] = useState<RequestObject[]>([]);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [nextStepIndex, setNextStepIndex] = useState(1);
  const [isSubmited, setIsSubmited] = useState(false);
  const [prevStepIndex, setPrevStepIndex] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOptionValue, setSelectedOptionVAlue] = useState("");
  const [currentStep, setCurrentStep] = useState(data?.data.questions[0]);

  useEffect(() => {
    setCurrentStep(data?.data.questions[0]);
  }, [data]);

  //get current step

  useEffect(() => {
    data?.data.questions.map((question: any) => {
      if (question.id == currentStepIndex) setCurrentStep(question);
    });
  }, [currentStepIndex, data]);

  const updateRequestArray = (currentObject: any) => {
    const updatedRequest = [...request]; // Create a copy of the request array

    // Check if the question already exists in the request array
    const existingObjectIndex = updatedRequest.findIndex(
      (obj) => obj.question === currentObject.question
    );

    if (existingObjectIndex !== -1) {
      // Question exists in the request array
      const existingObject = updatedRequest[existingObjectIndex];

      // Compare the currentObject's answer with the existing object's answer
      if (existingObject.answer !== currentObject.answer) {
        // Update the existing object with the new answer
        updatedRequest[existingObjectIndex] = currentObject;
        // Splice the elements after existingObjectIndex
        updatedRequest.splice(existingObjectIndex + 1);
      }
    } else {
      // Question does not exist in the request array, add it
      updatedRequest.push(currentObject);
    }

    // Update the state with the updated request array
    setRequest(updatedRequest);
  };

  const { mutateAsync } = useMutation(
    (data: any) => fetchApi("/user-use-case-studies", "POST", data),
    {
      onSuccess: () => {
        toast.success(
          i18n.language === "en"
            ? "Case Submitted Successfully"
            : "تم تثبيت الحالة بنجاح"
        );
      },
      onError: () => {
        toast.error(
          i18n.language === "en"
            ? "Something gone wrong, try again later"
            : "حدث خطأ ما, حاول مجدداً لاحقاً"
        );
      },
    }
  );
  const navigate = useNavigate();

  const handleSubmit = async () => {
    //@ts-ignore
    await mutateAsync({ answers: request, useCaseStudy: parseInt(params.id) });
    navigate("/");
  };

  const handleDisplay = () => {
    setIsSubmited(true);
    setDisplay(false);
    setPrevStepIndex([...prevStepIndex, currentStep.id] as never[]);
  };

  //handle clicking on option
  const handleclick = (answer: string, next: number, value: string) => {
    setSelectedOption(answer);
    setNextStepIndex(next);
    setSelectedOptionVAlue(value);
  };

  //update requested array on changing option
  useEffect(() => {
    if (selectedOption !== "") {
      const newObject: RequestObject = {
        question: currentStep?.id,
        answer: selectedOption,
        questionValue: currentStep?.question,
        answerValue: selectedOptionValue,
        next: nextStepIndex,
      };
      updateRequestArray(newObject);
    }
  }, [selectedOption]);

  //pop last element from prevStepIndex array
  const popLastElement = () => {
    setPrevStepIndex(prevStepIndex.slice(0, -1));
  };

  const [display, setDisplay] = useState(false);

  useEffect(() => {
    if (
      request[request.length - 1]?.question == currentStep?.id &&
      request[request.length - 1]?.next == 0 &&
      isSubmited == false
    ) {
      setDisplay(true);
    } else {
      setDisplay(false);
    }
  }, [
    prevStepIndex,
    nextStepIndex,
    selectedOption,
    display,
    request,
    currentStep,
  ]);


  if (isLoading) return <LoadingPage />;
  return (
    <div className="min-h-[75vh] flex items-center w-screen flex-col pt-10">
      <h1 className="font-bold text-3xl font-sans">
        {!isSubmited ? currentStep?.question : "Your Answers"}
      </h1>
      <div
        className="flex justify-between max-w-3/4 gap-5 my-14 mx-auto flex-wrap items-center"
        style={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
        }}
      >
        {!isSubmited
          ? currentStep?.answers?.map((answer: any) => (
              <Button
                key={answer.id}
                onClick={() =>
                  handleclick(answer.id, answer.next, answer.value)
                }
                style={{ height: "auto", padding: "20px", minWidth: "250px" }}
                variant={
                  request.some(
                    (item: any) =>
                      item.answer == answer.id &&
                      currentStep.id == item.question
                  )
                    ? "contained"
                    : "outlined"
                }
              >
                <p>{answer.value}</p>
              </Button>
            ))
          : request.map(({ questionValue, answerValue, question }) => {
              return (
                <DisplayCase
                  questionValue={questionValue}
                  answerValue={answerValue}
                  question={question}
                />
              );
            })}
      </div>
      <div className="flex items-center gap-3">
        <Button
          className={`w-[200px] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2`}
          disabled={prevStepIndex.length == 0}
          variant="contained"
          color="error"
          onClick={() => {
            if (isSubmited == true) {
              setNextStepIndex(0);
            } else {
              setNextStepIndex(currentStep.id);
            }
            popLastElement();
            setCurrentStepIndex(prevStepIndex[prevStepIndex.length - 1]);
            setIsSubmited(false);
          }}
        >
          {i18n.language === "en" ? "Previous" : "السابق"}
        </Button>

        {isSubmited == false && nextStepIndex != 0 && display == false ? (
          <Button
            className={`w-[200px] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2`}
            disabled={!selectedOption}
            variant="contained"
            color="primary"
            onClick={() => {
              setPrevStepIndex([...prevStepIndex, currentStep.id] as never[]);
              setCurrentStepIndex(nextStepIndex);
              setSelectedOption("");
            }}
          >
            {i18n.language === "en" ? "Next" : "التالي"}
          </Button>
        ) : display == true ? (
          <Button
            className="w-[200px] font-medium rounded-lg text-sm px-3 py-2.5 mr-2 mb-2"
            variant="contained"
            color="secondary"
            onClick={() => {
              handleDisplay();
            }}
          >
            {i18n.language === "en" ? "Display Answers" : "إظهار إجاباتك"}
          </Button>
        ) : (
          <Button
            className="w-[200px] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
            variant="contained"
            color="success"
            onClick={() => {
              handleSubmit();
            }}
          >
            {i18n.language === "en" ? "Submit" : "إرسال"}
          </Button>
        )}
      </div>
    </div>
  );
};

export default CaseContent;
