import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify"; // Assuming you are using react-toastify
import { CreateVrcMeeting } from "../../api/endpoints-functions";
import { useGoogleLogin } from "@react-oauth/google";
import { createVrcMeeting } from "../../interfaces/new-api-interfaces/vcr";

const useCreateVrcGoogleMeet = (id: number) => {

  const { mutateAsync: accept, isLoading } = useMutation(
    ({ id, token }: createVrcMeeting) => CreateVrcMeeting(id, token),
    {
      onSuccess: () => {},
      onError: (error: any) => {
        console.log(error);
        toast.error("something went wrong, please try again later");
      },
    }
  );

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      accept({
        id: id,
        token: tokenResponse.access_token,
      });
    },
    scope:
      "https://www.googleapis.com/auth/meetings.space.created email profile openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
  });

  return { googleLogin, isLoading };
};

export default useCreateVrcGoogleMeet;
