import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import i18n from "i18next";
import { GlobalPayment } from "./buy-now-global";
import { LocalPayment } from "./buy-now-local";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface Props {
  id: number;
  setOpen: Function;
  isTest?: boolean;
  type: string;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const PaymentMethod: React.FC<Props> = ({
  id,
  setOpen,
  isTest,
  type,
}) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <div className="col-12">
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab
              label={i18n.language === "en" ? "Global payment" : "تحويل"}
              {...a11yProps(0)}
            />
            <Tab
              label={i18n.language === "en" ? "E-pay" : "دفع الكتروني"}
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <LocalPayment type={type} id={id} setOpen={setOpen} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <GlobalPayment type={type} id={id} setOpen={setOpen} />
        </TabPanel>
      </Box>
    </div>
  );
};
