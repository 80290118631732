import { Link } from "react-router-dom";
import { Modal, Typography } from "@mui/material";

interface Props {
  isOpen: boolean;
  setIsOpen: Function;
}

const modalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const imageStyle = {
  maxWidth: "100%",
  height: "auto",
  marginBottom: "16px",
  backgroundImage: "url('/assets/images/thank-you.png')",
};

export function ThankYouModal({ isOpen, setIsOpen }: Props) {
  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)} style={modalStyle}>
      <div
        style={{
          backgroundColor: "#f5f5f5",
          boxShadow: "0px 10px 30px -5px rgba(0, 0, 0, 0.3)",
          padding: "24px",
          textAlign: "center",
          borderRadius: "8px",
          maxWidth: "400px",
        }}
      >
        <div style={imageStyle} />
        <Typography variant="h5" gutterBottom>
          Thank you for visiting our website!
        </Typography>
        <Typography variant="body1" gutterBottom>
          We appreciate your interest in our products and services.
        </Typography>
        <div className="col-10 mx-auto my-3 p-2 bg-red bg-success text-light text-center rounded">
          <Link to="/" className="no-hover">
            Go Back To Home
          </Link>
        </div>
      </div>
    </Modal>
  );
}
