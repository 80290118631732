import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEn from './en/translations'
import translationAr from './ar/translations'

i18next.use(initReactI18next).init({
  resources: {
    en: { translation: translationEn },
    ar: { translation: translationAr }
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: { escapeValue: false },
})

