import React, { useEffect, useState } from "react";
import bg from "../../../assets/images/profile/profile-bg.jpg";
import { Box, Button, TextField } from "@mui/material";
import i18next from "i18next";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { fetchApi } from "../../../api/api";
import { toast } from "react-toastify";
import OtpForm from "./OtpForm";
import ResetPasswordForm from "./ResetPasswordForm";
import { useAtomValue } from "jotai";
import { userAtom } from "../../../store";
import { useLocation } from "react-router-dom";

const Forget = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    getValues,
    setValue,
  } = useForm();

  const { t } = useTranslation();

  const [otpSent, setOtpSent] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [otp, setOtp] = useState("");
  const [isResetPassword, setIsResetPassword] = useState(true);

  const send = useMutation(
    (data: any) => fetchApi(`/auth/user/send-otp`, "POST", data),
    {
      onSuccess: (res) => {
        toast.success(res.data.message);
        setOtpSent(true);
      },
      onError: (error: any) => {
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).forEach((errorKey) => {
            toast.error(error.response.data.errors[errorKey].join("\n"));
          });
        } else {
          toast.error(error.response.data.message);
        }
      },
    }
  );

  const { state } = useLocation();

  useEffect(() => {
    if (state && !state?.email_verified_at) {
      setIsResetPassword(false);
      setValue("email", state.email);
      send.mutateAsync({
        email: state.email,
      });
    }
  }, []);

  const onSubmit = (value: any) => {
    send.mutateAsync(value);
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPositionX: "right",
      }}
    >
      <div
        style={{
          direction: `${i18next.language === "en" ? "ltr" : "rtl"}`,
          padding: "10vh 0",
        }}
      >
        <Box
          className="login-form-wrapper mt-sm-50 mt-xs-50"
          sx={{
            direction: `${i18next.language === "en" ? "ltr" : "rtl"}`,
            width: { xs: "90%", sm: "70%", md: "40%" },
          }}
        >
          {!otpSent && (
            <form
              style={{
                direction: `${i18next.language === "en" ? "ltr" : "rtl"}`,
                position: "relative",
                paddingTop: "50px",
                gap: "2%",
                flexDirection: "column",
                alignItems: "center",
              }}
              className="register-form d-flex justify-content-center"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message:
                      i18next.language === "en"
                        ? "Invalid email address"
                        : "البريد الإلكتروني غير صالح",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={
                      i18next.language === "en" ? "Email" : "البريد الإلكتروني"
                    }
                    placeholder={
                      i18next.language === "en" ? "Email" : "البريد الإلكتروني"
                    }
                    required
                    error={!!errors.email}
                    helperText={errors.email?.message?.toString()}
                    fullWidth
                  />
                )}
              />
              <Button
                fullWidth
                variant="contained"
                sx={{ marginTop: "1rem" }}
                type="submit"
                disabled={send.isLoading}
              >
                {t("send")}
              </Button>
            </form>
          )}
          {otpSent && !isVerify && (
            <OtpForm
              setOtpValue={setOtp}
              setIsVerify={setIsVerify}
              email={getValues("email")}
              isResetPassword={isResetPassword}
            />
          )}
          {isVerify && (
            <ResetPasswordForm email={getValues("email")} otp={otp} />
          )}
        </Box>
      </div>
    </Box>
  );
};

export default Forget;
