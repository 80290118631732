import React, { ReactNode } from "react";
import {
  Control,
  FieldValues,
  FieldErrors,
  RegisterOptions,
  Controller,
} from "react-hook-form";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";

interface CustomTextFieldProps
  extends Omit<TextFieldProps, "name" | "defaultValue" | "control"> {
  name: string;
  control: any;
  defaultValue: string | Date;
  errors: FieldErrors<FieldValues>;
  rules?: RegisterOptions; // Include rules prop
  label: string; // Include label prop
  required?: boolean; // Include required prop
  placeholder: string;
  inputStyle: any;
}

const CustomTextField: React.FC<CustomTextFieldProps> = ({
  name,
  control,
  defaultValue,
  label,
  placeholder,
  required,
  inputStyle,
  errors,
  rules, // Accept rules prop
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules} // Pass rules to Controller
      render={({ field }) => (
        <TextField
          sx={inputStyle}
          {...field}
          label={label}
          placeholder={placeholder}
          required={required}
          error={!!errors[name]}
          helperText={errors[name]?.message as ReactNode}
          {...rest}
        />
      )}
    />
  );
};

export default CustomTextField;
