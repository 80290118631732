import { Box, Chip, Stack, Typography } from "@mui/material";
import i18next from "i18next";
import React from "react";
import { FaCalendar } from "react-icons/fa6";
import { MdPhotoSizeSelectActual } from "react-icons/md";

interface Props {
  firstImageUrl: string;
  name: string;
  id: number;
  handleOpen: (id: number) => void;
  mediaLength: number;
  date: string;
}

const ProfileAlbumItem = ({
  handleOpen,
  firstImageUrl,
  name,
  id,
  mediaLength,
  date,
}: Props) => {
  return (
    <Box
      sx={{
        borderRadius: "12px",
        overflow: "hidden",
        border: "1px solid #00000020",
        height: "240px",
        width: "100%",
      }}
      component={"a"}
      onClick={() => handleOpen(id)}
    >
      <Box
        sx={{
          position: "relative",
          height: "100%",
          backgroundImage: firstImageUrl
            ? `url('${firstImageUrl}')`
            : "url('https://buffer.com/cdn-cgi/image/w=1000,fit=contain,q=90,f=auto/library/content/images/size/w600/2023/10/free-images.jpg')",
          backgroundSize: "cover",
          padding: "1rem",
          ":hover .box": {
            opacity: 1,
          },
        }}
      >
        <Box
          className="box"
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "#0005",
            opacity: 0.5,
            transition: "0.3s ease",
          }}
        />
        <Stack
          height={"100%"}
          justifyContent={"space-between"}
          position={"relative"}
        >
          <Chip
            label={`${mediaLength} ${
              i18next.language === "ar" ? "صورة" : "photos"
            }`}
            color="primary"
            sx={{ width: "fit-content" }}
            icon={<MdPhotoSizeSelectActual />}
          />
          <Typography variant="h6" color="white" maxWidth={"200px"}>
            {name}
          </Typography>
          <Typography
            variant="body2"
            color="white"
            sx={{ display: "flex", alignItems: "center", gap: "0.3rem" }}
          >
            <FaCalendar />
            {date.split("T")[0]}
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};

export default ProfileAlbumItem;
