import React from "react";
import { Controller } from "react-hook-form";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
//@ts-ignore
import FilePondPluginMediaPreview from "filepond-plugin-media-preview";
import "./index.css";
import "filepond-plugin-media-preview/dist/filepond-plugin-media-preview.min.css";
import i18next from "i18next";

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginMediaPreview
);

interface Props {
  control: any; // Pass the control prop from react-hook-form
  name: string; // Name of the field in the form
  type: "image" | "video" | "audio" | "asset" | "";
  required?: boolean;
}

const FilepondUploader: React.FC<Props> = ({
  control,
  name,
  type,
  required,
}) => {
  return (
    <div style={{ width: "100%" }}>
      <Controller
        name={name}
        control={control}
        rules={{ required: required ? "Field is required" : false }}
        render={({ field: { onChange } }) => (
          <FilePond
            onupdatefiles={(fileItems) => {
              // Set the file items to the form state upon upload
              onChange(fileItems.map((fileItem) => fileItem.file));
            }}
            allowMultiple={true}
            name={name}
            labelIdle={
              i18next.language == "en"
                ? 'Drag & Drop your Files or <span class="filepond--label-action">Browse</span>'
                : 'اسحب الملفات او <span class="filepond--label-action">تصفح الملفات</span>'
            }
            credits={false}
            imagePreviewHeight={200}
            storeAsFile={true}
            acceptedFileTypes={
              type == "image"
                ? ["image/png", "image/jpeg", "image/jpg"]
                : type == "video"
                ? ["video/mp4"]
                : type == "audio"
                ? ["audio/mp3", "audio/wav", "audio/mpeg", "audio/x-wav"]
                : [
                    "application/pdf",
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                  ]
            }
            checkValidity={true}
            allowFileTypeValidation={true}
            required={required}
          />
        )}
      />
    </div>
  );
};

export default FilepondUploader;
