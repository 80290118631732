import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { fetchApi } from "../../../api/api";
import "../../../pages/course/course-content.css";
import { defaultTranslation, getLink } from "../../../untilites/functions";
import DisplayFile from "../course-grid-components/display-file";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  IconButton,
  Skeleton,
  Slide,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { BsListUl } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { courseLesson } from "../../../interfaces/new-api-interfaces/course";
import { getImageFromServer } from "../../../assets/JS/helpers";
import { AiOutlineFileText, AiOutlineNumber } from "react-icons/ai";
import { TransitionProps } from "@mui/material/transitions";
import { Close } from "@mui/icons-material";
import CourseTestPaper from "../../../pages/test/course-test-paper";
import CourseCorrectedTestPage from "../../../pages/test/course-corrected-test";
import i18next from "i18next";
import { fetchCourseTestDetails } from "../../../api/endpoints-functions";
import { MdOutlineReplay } from "react-icons/md";
import CourseContentTest from "./course-content-test";

interface Props extends courseLesson {
  setOpenedSideNav: any;
  openedSideNav: any;
  navigateToNextLesson: Function;
  courseId: number;
  watched: boolean;
  setWatched: Function;
  lastLesson: boolean;
}

const buttonContentStyle = {
  fontSize: "large",
  display: "flex",
  alignItems: "center",
  gap: "10px",
  whiteSpace: "nowrap",
  width: "100%",
  justifyContent: "center",
};

export const CourseContent: React.FC<Props> = (Props) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [testId, setTestId] = useState<any>();
  const [open, setOpen] = React.useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [isToAnswer, setIsToAnswer] = useState(false);

  const { mutate } = useMutation(
    (data: any) => fetchApi(`/course/progressLesson`, "POST", data),
    {
      onSuccess() {
        Props.setWatched(true);
        queryClient.invalidateQueries(["get-course-by-id", Props.courseId]);
        queryClient.invalidateQueries(["course-content", Props.courseId]);
      },
    }
  );

  const [attempts, setAttempts] = useState<number>(0);

  const { data: testDetails, isFetching: testDetailsFetching } = useQuery(
    ["get-test-details", testId],
    () => fetchCourseTestDetails(testId),
    {
      enabled: !!testId,
      retry: false,
    }
  );

  useEffect(() => {
    if (Props.test && Props.test.length > 0 && testDetails) {
      setAttempts(Props.test[0].attempts_number - testDetails.attempts);
    }
    if (Props.test && Props.test.length > 0 && !testDetails) {
      setAttempts(Props.test[0].attempts_number);
    }
  }, [Props.test, testDetails]);

  useEffect(() => {
    if (Props.test && Props.test.length > 0) {
      setTestId(Props.test[0].id);
    }
  }, [Props.test]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1001);
  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 1001); // Adjust the breakpoint as per your requirements
    }

    // Add event listener to window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: { xs: "column", md: "row" },
        gap: "10px",
      }}
    >
      {isMobile && (
        <Box sx={{ width: "90%", alignSelf: "center" }}>
          <Button
            sx={{ padding: 0, justifyContent: "flex-start" }}
            onClick={() => Props.setOpenedSideNav(!Props.openedSideNav)}
          >
            <BsListUl style={{ fontSize: "30px" }} />
          </Button>
        </Box>
      )}

      <Box
        sx={{
          width: { xs: "90%", md: "90%" },
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          alignSelf: { xs: "center", md: "flex-start" },
        }}
      >
        <Box>
          <Typography variant="h5">
            {Props?.translations ? defaultTranslation(Props, "name") : ""}
          </Typography>
          {Props?.type == "test" && Props?.test.length > 0 && (
            <CourseContentTest
              courseId={Props.courseId}
              testDetails={testDetails!}
              open={open}
              isPreview={isPreview}
              isToAnswer={isToAnswer}
              setIsPreview={setIsPreview}
              setIsToAnswer={setIsToAnswer}
              setOpen={setOpen}
              test={Props.test}
              attempts={attempts}
              lessonId={Props.id}
              testDetailsFetching={testDetailsFetching}
            />
          )}
          <Typography>
            {Props?.translations
              ? defaultTranslation(Props, "description")
              : ""}
          </Typography>
        </Box>
        {(Props?.file?.length > 0 || Props?.test?.length > 0) && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              gap: "20px",
              height: "100%",
            }}
          >
            {Props.type == "video" ? (
              <DisplayFile
                id={Props.id}
                file={Props.file[0].file_url}
                type="video"
                is_Watched={Props.watched}
                onProgress={() => {
                  mutate({ lesson_id: Props.id, course_id: Props.courseId });
                }}
                isCourse={true}
              />
            ) : Props.type == "audio" ? (
              <DisplayFile
                id={Props.id}
                file={getImageFromServer(Props.file[0].file_url)}
                type="audio"
                is_Watched={Props.watched}
                onProgress={() => {
                  mutate({ lesson_id: Props.id, course_id: Props.courseId });
                }}
              />
            ) : Props.type === "asset" ? (
              <DisplayFile
                file={getImageFromServer(Props.file[0].file_url)}
                type="asset"
              />
            ) : Props.type == "test" ? (
              <Box>
                {attempts > 0 && !testDetails && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setIsToAnswer(true);
                      setOpen(true);
                    }}
                  >
                    {t("goToTest")}
                  </Button>
                )}
                {testDetails?.is_answered && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={async () => {
                      setIsPreview(true);
                      setOpen(true);
                    }}
                  >
                    {!Props.test[0].is_graded && !!testDetails?.is_checked
                      ? i18next.language === "ar"
                        ? "مشاهدة مراجعة المدرب"
                        : "Watch trainer review"
                      : t("previewYourAnswer")}
                  </Button>
                )}
              </Box>
            ) : (
              ""
            )}
            {Props.type == "asset" && !Props.watched && (
              <Button
                className="home-action"
                variant="contained"
                onClick={() => {
                  if (!Props.watched)
                    mutate({ lesson_id: Props.id, course_id: Props.courseId });
                }}
              >
                <Typography sx={buttonContentStyle}>
                  {t("markAsRead")}
                </Typography>
              </Button>
            )}
            {Props.watched && !Props.lastLesson && (
              <Button
                className="home-action"
                variant="contained"
                onClick={() => Props.navigateToNextLesson()}
              >
                <Typography sx={buttonContentStyle}>
                  {t("NextLesson")}
                </Typography>
              </Button>
            )}
          </div>
        )}
        {Props?.file?.length == 0 && Props.test.length == 0 && (
          <Box>
            <Typography>{t("noContent")}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
