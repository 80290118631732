import React, { useMemo } from "react";
import { Draggable } from "react-beautiful-dnd";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import { useState } from "react";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import { courseLesson } from "../../../interfaces/new-api-interfaces/course";
import { defaultTranslation } from "../../../untilites/functions";
import FileProgress from "./fileProgress";
import { getImageFromServer } from "../../../assets/JS/helpers";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RiDeleteBinLine } from "react-icons/ri";
import i18next from "i18next";

interface Props {
  task: courseLesson;
  index: number;
  setModalInfo: Function;
  handleModalOpen: Function;
  setFileState: Function;
  reset: any;
  setLessonMethod: Function;
  progress: any;
  handleDeleteModalOpen: Function;
  handleaddTestModalOpen: Function;
  setTestModalId: Function;
}

const LessonItem: React.FC<Props> = ({
  task,
  index,
  setModalInfo,
  handleModalOpen,
  setFileState,
  reset,
  setLessonMethod,
  progress,
  handleDeleteModalOpen,
  handleaddTestModalOpen,
  setTestModalId,
}) => {
  const [expande, setExpande] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isLessonInProgress = useMemo(() => {
    const index = progress.findIndex((item: any) => item.id === task.id);
    return (
      index !== -1 &&
      progress[index]?.value !== 0 &&
      progress[index]?.value !== 100
    );
  }, [progress, task]);
  return (
    <Draggable draggableId={`${task.id}`} index={index} key={task.id}>
      {(provided) => (
        <Accordion
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          expanded={expande}
        >
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Stack direction="row" alignItems="center" gap={2}>
                {/* {task.file[0] && (
                  <IconButton
                    sx={{ padding: 0 }}
                    onClick={() => setExpande(!expande)}
                  >
                    {expande == false ? (
                      <AiOutlinePlusCircle />
                    ) : (
                      <AiOutlineMinusCircle />
                    )}
                  </IconButton>
                )}
                {!task.file[0] && (
                  <IconButton sx={{ padding: 0 }}>
                    <AiOutlinePlusCircle style={{ visibility: "hidden" }} />
                  </IconButton>
                )} */}
                <Typography>
                  #{task.order} {defaultTranslation(task, "name")}
                </Typography>
              </Stack>
              <Stack direction="row" gap={2}>
                {isLessonInProgress && (
                  <FileProgress
                    value={
                      progress.find((item: any) => item.id === task.id)?.value
                    }
                  />
                )}
                <Button
                  onClick={(e) => {
                    setFileState(false);
                    setLessonMethod("update");
                    setModalInfo(task);
                    reset({
                      name: task.translations.ar.name || "",
                      name_en: task.translations.en?.name || "",
                      description: task.translations.ar.description || "",
                      description_en: task.translations.en?.description || "",
                    });
                    handleModalOpen();
                  }}
                  color="myorange2"
                  variant="contained"
                  sx={{ whiteSpace: "nowrap" }}
                  size="small"
                >
                  <Typography fontSize="0.8rem" color="#fff">
                    {t("editLesson")}
                  </Typography>
                </Button>
                {!task.file[0] && task.test.length == 0 && (
                  <Button
                    onClick={(e) => {
                      setFileState(true);
                      setModalInfo(task);
                      handleModalOpen();
                      setLessonMethod("create");
                    }}
                    variant="contained"
                    sx={{ whiteSpace: "nowrap" }}
                    size="small"
                    color="success"
                  >
                    <Typography fontSize="0.8rem" color="#fff">
                      {t("addContent")}
                    </Typography>
                  </Button>
                )}
                {task.file[0] && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      window.open(
                        getImageFromServer(task.file[0].file_url),
                        "_blank"
                      );
                    }}
                    size="small"
                  >
                    {t("preview") +
                      " " +
                      (i18next.language == "ar" ? "ال" : "") +
                      t(`${task.file[0].info}`)}
                  </Button>
                )}
                {task.file[0] && (
                  <Button
                    onClick={(e) => {
                      setFileState(true);
                      setModalInfo(task);
                      handleModalOpen();
                      setLessonMethod("update");
                    }}
                    variant="contained"
                    sx={{ whiteSpace: "nowrap" }}
                    size="small"
                    color="warning"
                  >
                    <Typography fontSize="0.8rem" color="#fff">
                      {t("editContent")}
                    </Typography>
                  </Button>
                )}
                {task.test.length > 0 && (
                  <>
                    <Button
                      component="a"
                      href={`/edit-course-test/${task.test[0].id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      variant="contained"
                      sx={{ whiteSpace: "nowrap" }}
                      size="small"
                      color="info"
                    >
                      <Typography fontSize="0.8rem" color="#fff">
                        {t("editTestQuestions")}
                      </Typography>
                    </Button>
                    {task.test[0].is_graded ? (
                      <Button
                        variant="contained"
                        sx={{ whiteSpace: "nowrap" }}
                        size="small"
                        color="mypurple2"
                        onClick={() => {
                          setTestModalId(task.test[0].id);
                          handleaddTestModalOpen();
                        }}
                      >
                        <Typography fontSize="0.8rem" color="#fff">
                          {t("editTestMarks")}
                        </Typography>
                      </Button>
                    ) : (
                      <></>
                    )}
                  </>
                )}
                <IconButton
                  onClick={(e) => {
                    setModalInfo(task);
                    handleDeleteModalOpen();
                  }}
                  color="error"
                  size="small"
                >
                  <RiDeleteBinLine />
                </IconButton>
              </Stack>
            </Box>
          </AccordionSummary>
          {/* {task.file[0] && (
            <AccordionDetails>
              <Typography>
                {task.file[0].info} : {defaultTranslation(task, "name")}
              </Typography>
            </AccordionDetails>
          )} */}
        </Accordion>
      )}
    </Draggable>
  );
};

export default LessonItem;
