import { Stack, Typography } from "@mui/material";
import React from "react";

const EnTermsOfServices = () => {
  return (
    <div className="container">
      <div className="order-lg-2 max-mb-50">
        <Stack gap={3} sx={{ marginTop: "50px", marginBottom: "50px" }}>
          <Typography variant="h2" color="primary">
            Terms of Service
          </Typography>
          <Typography variant="h6">Welcome to Unlimited Horizons.</Typography>
          <Typography>
            Please read these Terms of Service and the Unlimited Horizons
            Privacy Policy and Honor Code prior to registering for
            uh-training.com or using any portion of the Unlimited Horizons
            platform (the "Platform," which consists of Unlimited Horizons’
            website and all content and pages located within the uh-training.com
            web domain, in addition to Unlimited Horizons’ mobile applications),
            including accessing any course material, chat rooms, or other
            electronic services. These TOS, the Privacy Policy and the Honor
            Code are agreements (may be hereinafter referred to, collectively,
            as the "Agreements") between you and Unlimited Horizons. By using
            the Platform, you accept and agree to be legally bound by the
            Agreements, whether or not you are a registered user on the
            Platform. Unlimited Horizons reserves the right to modify these TOS
            at any time without any advance notice to you. Any changes to these
            TOS will be effective immediately upon posting on this page or on
            any other section of the Platform. By accessing the Platform after
            any changes have been made, you signify your agreement to the
            modified TOS and all of the changes contained therein. Be sure to
            return to this page periodically to ensure familiarity with the most
            current version of these TOS.
          </Typography>
          <Typography variant="h4" color="primary">
            DESCRIPTION OF UNLIMITED HORIZONS
          </Typography>
          <Typography>
            Unlimited Horizons offers online courses, authored by colleges,
            universities and other institutions (collectively, the
            “Institutions”, individually as the “Institution”), that include
            opportunities for instructor-to-student and student-to-student
            interactivity, individual assessment of a student's work and, for
            students who demonstrate their mastery of subjects, a certificate of
            completion (the “Courses”).
          </Typography>
          <Typography variant="h4" color="primary">
            RULES FOR ONLINE CONDUCT
          </Typography>
          <Typography>
            You agree that you are fully responsible for your own use of the
            Platform and for your user postings, which shall include all content
            submitted, posted, published or distributed on the Platform by you
            or by other users of the Platform, including but not limited to all
            forum posts, wiki edits, notes, questions, comments, videos and file
            uploads (the “User Postings”). You agree that you will use the
            Platform in compliance with the Agreements and all applicable local,
            national and international laws, rules and regulations, including
            copyright laws, and any laws regarding the transmission of technical
            data exported from your country of residence. As a condition of your
            use of the services offered by Unlimited Horizons on the Platform,
            you hereby undertake that you will not use the Platform in any
            manner intended to damage, disable, overburden or impair any
            Unlimited Horizons server or the network(s) connected to any
            Unlimited Horizons server or to interfere with any third party's use
            and enjoyment of the Platform. You may not attempt to gain
            unauthorized access to the Platform, other accounts, computer
            systems or networks connected to any Unlimited Horizons server
            through hacking, password mining or any other means. You may not
            obtain or attempt to obtain any materials or information stored on
            the Platform, its servers or associated computers through any means
            not intentionally made available through the Platform. THE FOLLOWING
            LIST OF ITEMS IS STRICTLY PROHIBITED ON THE PLATFORM:
          </Typography>
          <Typography>
            <ul>
              <li>Content that defames, harasses or threatens others;</li>
              <li>Content that discusses illegal activities;</li>
              <li>
                Content that infringes a third party’s intellectual property,
                including, but not limited to, copyrights or trademarks;
              </li>
              <li>
                Profane, pornographic, obscene, indecent or unlawful content;
              </li>
              <li>Advertising or any form of commercial solicitation;</li>
              <li>Content related to partisan political activities;</li>
              <li>
                Viruses, trojan horses, worms, time bombs, corrupted files,
                malware, spyware or any other similar software that may damage
                the operation of another's computer or property;
              </li>
              <li>
                Content that contains intentionally inaccurate information or
                that is posted with the intent of misleading others.
              </li>
            </ul>
          </Typography>
          <Typography>
            Furthermore, you agree not to scrape, or otherwise download in bulk,
            any Platform content, including but not limited to a list or
            directory of users on the system, on-line textbooks, User Postings
            or user information. You agree not to misrepresent or attempt to
            misrepresent your identity while using the Platform. Any violation
            of the above list of prohibitions shall entitle Unlimited Horizons
            to terminate you use, access and/or participation to the Platform
            without prior notice.
          </Typography>
          <Typography variant="h4" color="primary">
            USER ACCOUNTS AND AUTHORITY
          </Typography>
          <Typography>
            In order to participate fully in the activities and services offered
            by Unlimited Horizons on the Platform, you must provide your name,
            an email address and a user password in order to create a user
            account (the "User Account"). You agree that you will never divulge
            or share access or access information for your User Account with any
            third party for any reason. In setting up your User Account, you may
            be prompted to enter additional optional information (e.g., your
            address). You represent that all information provided by you is
            accurate and current. You agree to maintain and update your
            information to keep it accurate and current. We care about the
            confidentiality and security of your personal information. Please
            see our Privacy Policy for more information about what information
            Unlimited Horizons may collect about you and how Unlimited Horizons
            uses that information. By accessing the Site, you signify that you
            consent to the collection and use of your information by Unlimited
            Horizons.
          </Typography>

          <Typography variant="h4" color="primary">
            YOUR RIGHT TO USE CONTENT ON THE PLATFORM
          </Typography>
          <Typography>
            Unless indicated as being in the public domain, all content on the
            Platform is protected by the Syrian Protection of Copy Rights Law
            No. 00 of 0000. Unless otherwise expressly stated on the Platform,
            the texts, exams, videos, images and other instructional materials
            provided with the Courses offered on the Site are for your personal
            use in connection with those Courses only. Certain reference
            documents, digital textbooks, articles and other information on the
            Platform are used with the permission of third parties, and use of
            that information is subject to certain rules and conditions, which
            will be posted along with the information. By using this Platform,
            you hereby undertake to abide by all such rules and conditions. You
            agree to retain all copyright and other notices on any content you
            obtain from the Platform. All rights in the Platform and its
            content, if not expressly granted to the Unlimited Horizons L.L.C.,
            or under a license granted to the Unlimited Horizons L.L.C.
          </Typography>
          <Typography variant="h4" color="primary">
            USER POSTINGS
          </Typography>

          <Typography>
            User Postings Representations and Warranties: Notwithstanding the
            list of items strictly prohibited on the Platform as highlighted
            above, by submitting or distributing your User Postings, you affirm,
            represent and warrant (A) that you have the necessary rights,
            licenses, consents and/or permissions to reproduce and publish the
            User Postings onto the Platform and to authorize Unlimited Horizons
            and its users to reproduce, modify, publish and otherwise use and
            distribute your User Postings in a manner consistent with the
            licenses granted by you under these TOS, and (B) that neither your
            submission of your User Postings nor the exercise of the licenses
            granted below will infringe or violate the rights of any third
            party. You, and not Unlimited Horizons, are solely responsible for
            your User Postings and the consequences of posting or publishing
            them onto the Platform. License Grant to Unlimited Horizons: By
            submitting or distributing your User Postings, you hereby grant to
            Unlimited Horizons a worldwide, non-exclusive, transferable,
            assignable, sub-licensable, fully paid-up, royalty-free, perpetual,
            irrevocable right and license to host, transfer, display, perform,
            reproduce, modify, distribute, re-distribute, relicense and
            otherwise use, make available and exploit your User Postings, in
            whole or in part, in any form and in any media formats and through
            any media channels (now known or hereafter developed). License Grant
            to the Users: By submitting or distributing your User Postings, you
            hereby grant to each user of the Site (the “User(s)”) a
            non-exclusive license to access and use your User Postings in
            connection with their use of the Platform for their own personal
            purposes.
          </Typography>
          <Typography variant="h4" color="primary">
            CERTIFICATES, ETC.
          </Typography>
          <Typography>
            The Institutions providing the Courses on the Platform may offer a
            certificate of completion or other acknowledgment (the
            "Certificate") for students who, in their judgment, have
            satisfactorily demonstrated mastery of the relevant Course material.
            The Certificates will be issued by Unlimited Horizons under the name
            of the relevant Institution from where the Course originated. The
            decision as to whether a Certificate will be awarded to a student
            who completed the Course will be solely within the discretion of the
            awarding Institution. Unlimited Horizons and/or the Institutions
            providing the Courses on the Platform may, up to their sole
            discretion, choose not to offer a Certificate in relation to some
            Courses. No Other Enrollment: When you take any Course through
            Unlimited Horizons on the Site, you will not be an applicant for
            admission to, or enrolled in, any degree program in any of the
            authoring Institutions as a result of registering for or completing
            a Course provided by such institution through Unlimited Horizons on
            the Platform. You will not be entitled to use any of the resources
            of the Institution beyond the Courses provided on the Platform, nor
            will you be eligible to receive student privileges or benefits
            provided to students enrolled in degree programs of the
            Institutions.
          </Typography>
          <Typography variant="h4" color="primary">
            TRADEMARKS
          </Typography>
          <Typography>
            Use of Unlimited Horizons, other participating Institutions Names,
            and Trademarks: The " Unlimited Horizons " name and logo are
            trademarks of the Unlimited Horizons L.L.C. Likewise, the names,
            logos, and seals of the other participating Institutions are
            trademarks owned by the respective Institution. You may not use any
            of Unlimited Horizons’ trademarks or any of the Institutions’
            trademarks, or any variations thereof, without the relevant owner's
            prior written consent. You may not use any of the Institutions’
            trademarks, or any variations thereof, for promotional purposes, or
            in any way that deliberately or inadvertently claims, suggests or,
            in the Institutions' sole judgment, gives the appearance or
            impression of a relationship with or endorsement by these
            Institutions. All Trademarks not owned by Unlimited Horizons, or the
            other participating Institutions that appear on the Platform or on
            or through the services made available on or through the Platform,
            if any, are the sole property of their respective owners. Nothing
            contained on the Platform should be construed as granting, by
            implication, estoppel or otherwise, any license or right to use any
            trademark displayed on the Platform without the written permission
            of the owner of the relevant trademark.
          </Typography>
          <Typography variant="h4" color="primary">
            DISCLAIMERS OF WARRANTY / LIMITATIONS OF LIABILITIES
          </Typography>
          <Typography fontWeight={600}>
            THE PLATFORM AND ANY INFORMATION, CONTENT OR SERVICES MADE AVAILABLE
            ON OR THROUGH THE PLATFORM ARE PROVIDED "AS IS" AND "AS AVAILABLE"
            WITHOUT WARRANTY OF ANY KIND (EXPRESS, IMPLIED OR OTHERWISE),
            INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
            NON-INFRINGEMENT, EXCEPT INSOFAR AS ANY SUCH IMPLIED WARRANTIES MAY
            NOT BE DISCLAIMED UNDER APPLICABLE LAW. UNLIMITED HORIZONS AND THE
            UNLIMITED HORIZONS PARTICIPANTS (AS DEFINED HEREUNDER) DO NOT
            WARRANT THAT THE PLATFORM WILL OPERATE IN AN UNINTERRUPTED OR
            ERROR-FREE MANNER, THAT THE SITE IS FREE OF VIRUSES OR OTHER HARMFUL
            COMPONENTS, OR THAT THE COURSES OR CONTENT PROVIDED WILL MEET YOUR
            NEEDS OR EXPECTATIONS. UNLIMITED HORIZONS AND THE UNLIMITED HORIZONS
            PARTICIPANTS ALSO MAKE NO WARRANTY ABOUT THE ACCURACY, COMPLETENESS,
            TIMELINESS, OR QUALITY OF THE PLATFORM OR ANY COURSES OR CONTENT, OR
            THAT ANY PARTICULAR COURSES OR CONTENT WILL CONTINUE TO BE MADE
            AVAILABLE. THE “UNLIMITED HORIZONS PARTICIPANTS” SHALL MEAN …., ……,
            ………, THE OTHER PARTICIPATING INSTITUTIONS, THE ENTITIES PROVIDING
            INFORMATION, CONTENT OR SERVICES FOR THE SITE, THE COURSE
            INSTRUCTORS, THEIR RESPECTIVE STAFF, EMPLOYEES, AND TEACHING
            ASSISTANTS. USE OF THE PLATFORM AND THE CONTENT AND SERVICES
            OBTAINED FROM OR THROUGH THE PLATFORM IS AT YOUR OWN RISK. YOUR
            ACCESS TO OR DOWNLOAD OF INFORMATION, MATERIALS OR DATA THROUGH THE
            PLATFORM OR ANY REFERENCE SITES IS AT YOUR OWN DISCRETION AND RISK,
            AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY
            (INCLUDING YOUR COMPUTER SYSTEM) OR LOSS OF DATA THAT RESULTS FROM
            THE DOWNLOAD OR USE OF SUCH MATERIAL OR DATA, UNLESS OTHERWISE
            EXPRESSLY PROVIDED FOR IN THE UNLIMITED HORIZONS PRIVACY POLICY.
          </Typography>
          <Typography>
            User Postings Disclaimer: You understand that when using the
            Platform, you will be exposed to User Postings from a variety of
            sources and that neither Unlimited Horizons nor the Unlimited
            Horizons Participants are responsible for the accuracy, usefulness,
            reliability or intellectual property rights of or relating to such
            User Postings. You further understand and acknowledge that you may
            be exposed to User Postings that are inaccurate, offensive,
            defamatory, indecent or objectionable and you agree to waive, and
            hereby do waive, any legal or equitable rights or remedies you have
            or may have against Unlimited Horizons or any of the Unlimited
            Horizons Participants with respect thereto. Neither Unlimited
            Horizons nor any of the Unlimited Horizons Participants endorse any
            User Postings or any opinion, recommendation or advice expressed
            therein. Neither Unlimited Horizons nor any of the Unlimited
            Horizons Participants have any obligation to monitor any User
            Postings or any other user communications through the Platform.
            However, Unlimited Horizons reserves the right to review User
            Postings and to exercise its sole discretion to edit or remove, in
            whole or in part, any User Posting at any time and for any reason,
            or to allow the Unlimited Horizons Participants to do so at any time
            and for any reason. Without limiting the foregoing, upon receiving
            notice from a user or a content owner that a User Posting allegedly
            does not conform with these TOS, Unlimited Horizons may investigate
            the allegation and determine, in its sole discretion, whether to
            edit or remove, in whole or in part, the non-conforming User Posting
            and at any time it chooses, without notice. Links to Other Sites:
            The Platform may include hyperlinks to other sites, maintained or
            controlled by others. Unlimited Horizons and the Unlimited Horizons
            Participants are not responsible for and do not routinely screen,
            approve, review or endorse the contents of or use of any of the
            products or services that may be offered at these other sites. If
            you decide to access linked third-party sites, you do so at your own
            risk.
          </Typography>
          <Typography fontWeight={600}>
            TO THE FULLEST EXTENT PERMITTED BY THE APPLICABLE LAW, YOU AGREE
            THAT NEITHER UNLIMITED HORIZONS NOR ANY OF THE UNLIMITED HORIZONS
            PARTICIPANTS WILL BE LIABLE TO YOU FOR ANY LOSS OR DAMAGES, EITHER
            ACTUAL OR CONSEQUENTIAL, ARISING OUT OF OR RELATING TO THESE TOS’s,
            OR YOUR (OR ANY THIRD PARTY'S) USE OF OR INABILITY TO USE THE
            PLATFORM, OR YOUR PLACEMENT OF CONTENT ON THE PLATFORM, OR YOUR
            RELIANCE UPON INFORMATION OBTAINED FROM OR THROUGH THE PLATFORM,
            WHETHER YOUR CLAIM IS BASED IN CONTRACT, TORT, STATUTORY OR OTHER
            LAW. IN PARTICULAR, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE
            LAW, NEITHER UNLIMITED HORIZONS NOR ANY OF THE UNLIMITED HORIZONS
            PARTICIPANTS WILL HAVE ANY LIABILITY FOR ANY CONSEQUENTIAL,
            INDIRECT, PUNITIVE, SPECIAL, EXEMPLARY OR INCIDENTAL DAMAGES,
            WHETHER FORESEEABLE OR UNFORESEEABLE AND WHETHER OR NOT UNLIMITED
            HORIZONS OR ANY OF THE UNLIMITED HORIZONS PARTICIPANTS HAS BEEN
            NEGLIGENT OR OTHERWISE AT FAULT (INCLUDING, BUT NOT LIMITED TO,
            CLAIMS FOR DEFAMATION, ERRORS, LOSS OF PROFITS, LOSS OF DATA OR
            INTERRUPTION IN AVAILABILITY OF DATA).
          </Typography>
          <Typography variant="h4" color="primary">
            INDEMNIFICATION
          </Typography>
          <Typography>
            You agree to defend, hold harmless and indemnify Unlimited Horizons
            and the Unlimited Horizons Participants, and their respective
            subsidiaries, affiliates, officers, faculty, students, fellows,
            governing board members, agents and employees from and against any
            third-party claims, actions or demands arising out of, resulting
            from or in any way related to your use of the Platform, including
            any liability or expense arising from any and all claims, losses,
            damages (actual and consequential), suits, judgments, litigation
            costs and attorneys' fees, of every kind and nature. In such a case,
            Unlimited Horizons or one of the Unlimited Horizons Participants
            may, in their sole discretion, provide you with written or oral
            notice of such claim, suit or action.
          </Typography>
          <Typography variant="h4" color="primary">
            EXTERNAL SERVICE PROVIDERS
          </Typography>
          <Typography>
            Unlimited Horizons uses third party services to provide various
            functionalities on the Platform. By agreeing to Unlimited Horizons’
            terms of service, you also expressly agree to the following third
            party terms and policies:
            <ul>
              <li>
                <a href="https://www.youtube.com/t/terms" target="_blank">
                  YouTube Terms of Service
                </a>
              </li>
              <li>
                <a href="https://policies.google.com/privacy" target="_blank">
                  Google Privacy Policy
                </a>
              </li>
            </ul>
          </Typography>
          <Typography variant="h4" color="primary">
            MISCELLANEOUS
          </Typography>
          <Typography>
            Termination Rights; Discontinuation of Courses and Content: You
            agree that Unlimited Horizons, in its sole discretion, may terminate
            your use of the Platform or your participation in it, for any reason
            or no reason, upon serving notice to you to the effect. It is
            Unlimited Horizons’ policy to terminate, in circumstances it deems
            appropriate, users of the Platform who are repeat copyright
            infringers. Unlimited Horizons and the Unlimited Horizons
            Participants reserve the right at any time in their sole discretion
            to cancel, delay, reschedule or alter the format of any Course
            offered through Unlimited Horizons, or to cease providing any part
            or all of the Platform content or related services, and you agree
            that neither Unlimited Horizons nor any of the Unlimited Horizons
            Participants will have any liability to you for such an action. If
            you no longer desire to participate in the Platform, you may
            terminate your participation at any time. The rights granted to you
            hereunder will terminate upon any termination of your right to use
            the Platform, but the other provisions of these TOS will survive any
            such termination. Entire Agreement: These TOS, the Honor Code, and
            the Privacy Policy together constitute the entire agreement between
            you and Unlimited Horizons with respect to your use of the Platform,
            superseding any prior agreements between you and Unlimited Horizons
            regarding your use of the Platform. Waiver and Severability of TOS:
            The failure of Unlimited Horizons to exercise or enforce any right
            or provision of these TOS shall not constitute a waiver of such
            right or provision. If any provision of these TOS is found by a
            court of competent jurisdiction to be invalid, the parties to the
            TOS nevertheless agree that the court should endeavor to give effect
            to the parties' intentions as reflected in the provision; the other
            provisions of these TOS shall remain in full force and effect.
            Choice of Law/Forum Selection: You agree that these TOS and any
            claim or dispute arising out of or relating to these TOS or any
            content or service obtained from or through the Platform will be
            governed by the laws applicable in the Syrian Arab Republic. You
            agree that all such claims and disputes will be heard and resolved
            exclusively in the Courts of Damascus. You consent to the personal
            jurisdiction of the said courts over you for this purpose, and you
            waive and agree not to assert any objection to such proceedings in
            said courts (including any defense or objection of lack of proper
            jurisdiction or venue or inconvenience of forum). Effective Date:
            13/03/2024. Unlimited Horizons Honor Code Pledge
          </Typography>
          <Typography variant="h4" color="primary">
            COLLABORATION POLICY
          </Typography>
          <Typography>
            By enrolling in a Course on Unlimited Horizons, you are joining a
            special community of learners. The aspiration of Unlimited Horizons
            is to revolutionize education across the Middle East and North
            Africa (MENA) region in order to create an Arab society that is
            empowered with a new voice, skills, and mindset. Unlimited Horizons
            aspires to achieve its vision by delivering high quality education
            to the MENA region, and the rest of the world, that is free,
            accessible, and open to all; you are part of the community who will
            help Unlimited Horizons achieve this goal. Unlimited Horizons
            depends upon your motivation to learn the material contained within
            the Courses and to do so with honesty. In order to participate in
            Unlimited Horizons, you must agree to the Honor Code Pledge below
            and any additional terms specific to a Course. This Honor Code, and
            any additional terms, will be posted in each Course's content.
          </Typography>
          <Typography variant="h4" color="primary">
            THE UNLIMITED HORIZONS HONOR CODE PLEDGE
          </Typography>
          <Typography>
            By enrolling in an Unlimited Horizons Course, I agree that I will:
            <ul>
              <li>
                Complete all mid-terms and final exams with my own work and only
                my own work. I will not submit the work of any other person.
              </li>
              <li>
                Maintain only one User Account and not let anyone else use my
                username and/or password.
              </li>
              <li>
                Not engage in any activity that would dishonestly improve my
                results, or improve or hurt the results of others.
              </li>
              <li>
                Not post answers to problems that are being used to assess
                student performance.
              </li>
              <li>
                Unless otherwise indicated by the instructor of an Unlimited
                Horizons Course, learners on Unlimited Horizons are encouraged
                to:
                <ul>
                  <li>
                    Collaborate with others on the lecture videos, exercises,
                    homework and labs.
                  </li>
                  <li>
                    Discuss with others general concepts and materials in each
                    course.
                  </li>
                  <li>
                    Present ideas and written work to fellow Unlimited Horizons
                    learners or others for comment or criticism.
                  </li>
                </ul>
              </li>
            </ul>
          </Typography>
          <Typography variant="h4" color="primary">
            CHANGING THE HONOR CODE
          </Typography>
          <Typography>
            Please note that we review and may make changes to this Honor Code
            from time to time. Any changes to this Honor Code will be effective
            immediately upon posting on this page, with an updated effective
            date. By accessing the Platform after any changes have been made,
            you signify your agreement on a prospective basis to the modified
            Honor Code and any changes contained therein. Be sure to return to
            this page periodically to ensure familiarity with the most current
            version of this Honor Code.
          </Typography>
        </Stack>
      </div>
    </div>
  );
};

export default EnTermsOfServices;
