import React, { useState } from "react";
import {
  Stack,
  Box,
  Typography,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
} from "@mui/material";
import { AiOutlineFileText, AiOutlineNumber } from "react-icons/ai";
import { MdOutlineReplay } from "react-icons/md";
import { useTranslation } from "react-i18next";
import Skeleton from "@mui/material/Skeleton";
import { studentTestDetails } from "../../../interfaces/new-api-interfaces/test";
import { courseLesson } from "../../../interfaces/new-api-interfaces/course";
import { Close } from "@mui/icons-material";
import CourseTestPaper from "../../../pages/test/course-test-paper";
import CourseCorrectedTestPage from "../../../pages/test/course-corrected-test";
import { TransitionProps } from "@mui/material/transitions";
import i18next from "i18next";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  courseId: number;
  testDetails: studentTestDetails;
  open: boolean;
  isPreview: boolean;
  isToAnswer: boolean;
  test: courseLesson["test"];
  setOpen: Function;
  setIsPreview: Function;
  setIsToAnswer: Function;
  attempts: number;
  lessonId: number;
  testDetailsFetching: boolean;
}

const CourseContentTest: React.FC<Props> = ({
  courseId,
  testDetails,
  open,
  isPreview,
  isToAnswer,
  test,
  setIsPreview,
  setOpen,
  setIsToAnswer,
  attempts,
  lessonId,
  testDetailsFetching,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        flexWrap={"wrap"}
      >
        <Box sx={{ paddingY: "10px" }} display={"flex"} gap={"1rem"}>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              fontSize: "large",
              color: "primary.main",
            }}
          >
            <AiOutlineFileText fontSize="x-large" /> {test[0]?.questions_count}{" "}
            {t("Questions")}
          </Typography>
          {!!test[0].is_graded && (
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                fontSize: "large",
                color: "primary.main",
              }}
            >
              <AiOutlineNumber fontSize="x-large" /> {test[0].total_mark}{" "}
              {t("Mark")}
            </Typography>
          )}
        </Box>

        <Box>
          {!testDetailsFetching ? (
            <>
              {testDetails && (
                <>
                  {!!test[0].is_graded ? (
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        color: "primary.main",
                      }}
                    >
                      <AiOutlineNumber fontSize="x-large" />
                      {i18next.language === "ar"
                        ? "العلامة المستحقة: "
                        : "Obtained Mark: "}{" "}
                      {!!testDetails?.is_checked ? (
                        <Typography>
                          {testDetails?.mark}{" "}
                          {i18next.language === "ar" ? "درجة" : "Mark"} /{" "}
                          {testDetails.evaluations
                            ? i18next.language === "ar"
                              ? testDetails.evaluations.ar.text
                              : testDetails.evaluations.en.text
                            : ""}
                        </Typography>
                      ) : (
                        <Typography color="orange">
                          {i18next.language === "ar"
                            ? "قيد الانتظار"
                            : "Pending"}
                        </Typography>
                      )}
                    </Typography>
                  ) : !testDetails?.is_checked ? (
                    <Typography color="orange">
                      {i18next.language === "ar"
                        ? "بانتظار مراجعة المدرب"
                        : "Pending for trainer review"}
                    </Typography>
                  ) : (
                    <Typography color="green">
                      {i18next.language === "ar" ? "تمت المراجعة" : "Reviewed"}
                    </Typography>
                  )}
                </>
              )}
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  color: "primary.main",
                }}
              >
                {i18next.language === "ar"
                  ? "عدد المحاولات المتبقية:"
                  : "Number of remaining attempts: "}{" "}
                {attempts}
              </Typography>

              {attempts > 0 && !!testDetails?.is_checked && (
                <Button
                  onClick={() => {
                    setIsToAnswer(true);
                    setOpen(true);
                  }}
                  startIcon={<MdOutlineReplay />}
                  variant="text"
                >
                  {i18next.language === "ar" ? "إعادة المحاولة" : "Try Again"}
                </Button>
              )}
            </>
          ) : (
            <>
              <Skeleton width="200px" variant="text" />
              <Skeleton width="200px" variant="text" />
              <Skeleton width="200px" variant="text" />
            </>
          )}
        </Box>
      </Stack>
      <Dialog
        fullScreen
        open={open}
        onClose={() => {
          setIsToAnswer(false);
          setIsPreview(false);
          setOpen(false);
        }}
        TransitionComponent={Transition}
        dir={i18next.language === "ar" ? "rtl" : "ltr"}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setIsToAnswer(false);
                setIsPreview(false);
                setOpen(false);
              }}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        {isToAnswer ? (
          <CourseTestPaper
            id={test[0].id}
            lessonId={lessonId}
            courseId={courseId}
            setOpen={setOpen}
            isGraded={test[0].is_graded}
            attempts={testDetails ? testDetails.attempts : 0}
          />
        ) : isPreview ? (
          <CourseCorrectedTestPage testId={test[0].id} />
        ) : (
          <></>
        )}
      </Dialog>
    </>
  );
};

export default CourseContentTest;
