import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MiniCartProduct from "./mini-cart-product";
import { useQuery } from "@tanstack/react-query";
import i18n from "i18next";
import { fetchCartItems } from "../../api/endpoints-functions";
import { cartResponse } from "../../interfaces/new-api-interfaces/cart";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";

interface Props {}

const MiniCartList: React.FC<Props> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, isLoading, isFetching } = useQuery(["get-cart"], () =>
    fetchCartItems()
  );
  if (isLoading || isFetching)
    return (
      <Stack justifyContent="center" alignItems="center" minHeight="10vh">
        <CircularProgress size={30} />
      </Stack>
    );
  return (
    <div className="inner">
      <div className="mini-cart-products">
        {data?.length ? (
          React.Children.toArray(
            data?.map((item: cartResponse) => {
              return <MiniCartProduct {...item} />;
            })
          )
        ) : (
          <Typography>
            {i18n.language === "en" ? "cart is empty" : "السلة فارغة"}
          </Typography>
        )}
      </div>

      {data && data?.length > 0 && (
        <div className="mini-cart-footer">
          <div className="mini-cart-total">
            <b>{t("GlobalTotal")}:</b>
            <span className="amount">
              {`${data?.reduce(
                (acc: number, item: cartResponse) =>
                  (acc = acc + item.price * item.quantity),
                0
              )} ${t("syp")}`}
            </span>
          </div>
          <Box sx={{ display: "flex", justifyContent: "space-around", mt: 3 }}>
            <Button
              onClick={() => navigate("/cart")}
              variant="contained"
              sx={{ width: "45%" }}
            >
              {t("NavHeaderCart")}
            </Button>
            <Button
              onClick={() => navigate("/checkout")}
              variant="contained"
              sx={{ width: "45%" }}
            >
              {t("NavHeaderCheckout")}
            </Button>
          </Box>
        </div>
      )}
    </div>
  );
};

export default MiniCartList;
