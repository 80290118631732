import React from "react";

interface Props {
  containerStyle: string;
  title: string;
  listItems: { id: number; link: string; name: string }[];
}

const FooterWidget: React.FC<Props> = (Props) => {
  return (
    <div className={Props.containerStyle}>
      <div className="footer-widget">
        <h4 className="footer-widget-title" style={{ color: "white" }}>
          {Props.title}
        </h4>
        <div className="footer-widget-content">
          <ul className="column-2" style={{ padding: 0 }}>
            {Props.listItems.map((li) => (
              <li key={li.id}>
                <a className="footer-link" href={li.link}>
                  {li.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FooterWidget;
