import { useAtom } from "jotai";
import React from "react";
import { useNavigate } from "react-router-dom";
import { roleAtom, tokenAtom } from "../../../store";
import { fetchApi } from "../../../api/api";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@mui/material";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { BiPhotoAlbum } from "react-icons/bi";

type Props = {};

const btnStyle = {
  borderRadius: "50px",
  padding: "10px 15px",
  gap: "5px",
  width: { xs: "45%", sm: "auto" },
};

const iconStyle = { color: "#fff", fontSize: "1rem" };

const typographyStyle = {
  whiteSpace: "nowrap",
  fontSize: "0.8rem",
  color: "#fff",
};

const StudentButtons = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const deleteAccountHandler = () => {
    fetchApi("/student-account", "DELETE");
    navigate("/", { replace: true });
  };
  return (
    <Box
      sx={{
        display: "flex",
        gap: "20px",
        alignItems: "center",
        padding: "20px 0",
        flexWrap: { xs: "wrap", lg: "nowrap" },
        justifyContent: { xs: "center", md: "flex-start" },
      }}
    >
      <Button
        onClick={() => navigate("/profile/edit")}
        variant="contained"
        color="mypurple2"
        sx={btnStyle}
      >
        <FiEdit style={iconStyle} />
        <Typography sx={typographyStyle}>{t("ProfileEditProfile")}</Typography>
      </Button>

      {/* <Button
        onClick={() => deleteAccountHandler()}
        variant="contained"
        color="error"
        sx={btnStyle}
      >
        <RiDeleteBinLine style={iconStyle} />
        <Typography sx={typographyStyle}>
          {t("ProfileDeleteAccount")}
        </Typography>
      </Button> */}
    </Box>
  );
};

export default StudentButtons;
