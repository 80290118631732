// OptionalTest Component
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { defaultTranslation } from "../../untilites/functions";
import { testQuestionForStudent } from "../../interfaces/new-api-interfaces/test";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

interface Props {
  setValue: any;
  question: testQuestionForStudent;
  index: number;
  getValues: Function;
  clearErrors: any;
  isGraded?: boolean | 0 | 1;
}

const OptionalTest: React.FC<Props> = ({
  question,
  setValue,
  index,
  getValues,
  clearErrors,
  isGraded,
}) => {
  const { t } = useTranslation();

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const optionId = e.target.value;
    clearErrors("answer");

    // Get the current value of the answer array
    const currentanswer = getValues("answer");

    // Check if an answer for the current question already exists in the array
    const existingAnswerIndex = currentanswer.findIndex(
      (answer: any) => answer?.question_id === question?.id
    );

    if (existingAnswerIndex !== -1) {
      // Update the existing answer with the new option_id
      const updatedanswer = currentanswer.map((answer: any, index: number) =>
        index === existingAnswerIndex
          ? { ...answer, option_id: optionId }
          : answer
      );

      // Set the updated answer array using setValue
      setValue("answer", updatedanswer);
    } else {
      // Create a new answer object and add it to the answer array
      const newAnswer = {
        question_id: question.id,
        option_id: optionId,
        type: "options",
      };
      const updatedanswer = [...currentanswer, newAnswer];

      // Set the updated answer array using setValue
      setValue("answer", updatedanswer);
    }
  };

  return (
    <Box sx={{ border: "solid 1px #ccc" }}>
      <Box sx={{ bgcolor: "mypurple2.main", padding: "20px" }}>
        <Typography variant="h5" color="#fff">
          {i18next.language == "en"
            ? `# ${question.order} Question`
            : `السؤال # ${question.order}`}
        </Typography>
      </Box>
      <Box sx={{ padding: "20px", bgcolor: "#FBFBFB" }}>
        <Stack width={"90%"} direction={"row"} justifyContent={"space-between"}>
          <Typography variant="h6">
            {defaultTranslation(question, "name")}
          </Typography>
          {isGraded == 1 && (
            <Typography variant="h6" color="mypurple2.main">
              {question.mark} {t("Mark")}
            </Typography>
          )}
        </Stack>
        <FormControl component="fieldset" required sx={{ width: "90%" }}>
          <RadioGroup onChange={handleOptionChange}>
            {question.options.map((option) => (
              <FormControlLabel
                key={option.id}
                value={option.id.toString()}
                control={<Radio />}
                label={defaultTranslation(option, "value")}
                sx={{
                  border: "solid 1px #ccc",
                  padding: "10px",
                  width: "100%",
                  marginY: "10px",
                  marginX: "0px",
                  borderRadius: "5px",
                }}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
};

export default OptionalTest;
