import { useQuery } from "@tanstack/react-query";
import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { LoadingPage } from "../../Common/loadingPage.component";
import { Box, Skeleton, Typography } from "@mui/material";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaArrowLeftLong } from "react-icons/fa6";
import Circles from "../../../assets/images/hero-image/hero-circle.svg";
import i18n from "i18next";
import {
  fetchGuideFile,
  fetchTopCategories,
} from "../../../api/endpoints-functions";
import { categoryResponse } from "../../../interfaces/new-api-interfaces/categories";
import { defaultTranslation } from "../../../untilites/functions";
import { getImageFromServer } from "../../../assets/JS/helpers";
import { useNavigate } from "react-router-dom";

const LandingSection: React.FC = () => {
  const { t } = useTranslation();
  const { isLoading, data } = useQuery(["top-categories"], () =>
    fetchTopCategories()
  );

  const { data: guidFile, isLoading: guideLoading } = useQuery(
    ["guide-file"],
    () => fetchGuideFile(1)
  );

  const navigate = useNavigate();

  const colors = ["myorange", "myred", "primary"];
  return (
    <Suspense fallback={<LoadingPage />}>
      <div className="section">
        <Box
          className="container"
          //sx={{ paddingLeft: "2.5rem", paddingRight: "2.5rem" }}
        >
          <div
            className="row row-cols-lg-2 row-cols-1 max-mb-n30"
            style={{ alignItems: "center" }}
          >
            <div className="col align-self-center max-mb-30" data-aos="fade-up">
              {!isLoading && !guideLoading ? (
                <Box
                  className="intro1-content"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: { xs: "center", md: "flex-start" },
                    gap: "20px",
                    paddingTop: "80px",
                  }}
                >
                  <Box
                    sx={{
                      backgroundImage: `url(${Circles})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPositionX:
                        i18n.language === "en" ? "right" : "left",
                      paddingTop: "70px",
                      paddingBottom: i18n.language === "en" ? "0px" : "20px",
                    }}
                  >
                    <Typography
                      variant="h1"
                      sx={{
                        whiteSpace: { xs: "normal", sm: "nowrap" },
                        fontSize: { xs: "4rem", sm: "6rem" },
                        textAlign: {
                          xs: "center",
                          sm: "start",
                        },
                      }}
                      fontFamily="Almarai"
                      fontWeight="500"
                    >
                      {i18n.language === "en" ? (
                        <>
                          Watch, Learn <br /> and Grow
                        </>
                      ) : (
                        <>
                          شاهد, تعلم <br /> ونمِّ مهاراتك
                        </>
                      )}
                    </Typography>
                  </Box>
                  <Box>
                    <a
                      href={getImageFromServer(guidFile?.file_url!)}
                      download={true}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <Typography className="home-action" variant="h5">
                        {t("LandingDownload")}
                        {i18n.language === "en" ? (
                          <FaArrowRightLong />
                        ) : (
                          <FaArrowLeftLong />
                        )}
                      </Typography>
                    </a>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: { xs: "center", md: "start" },
                      flexWrap: "wrap",
                      gap: "30px",
                      paddingTop: "20px",
                      width: "100%",
                    }}
                  >
                    {data?.map((category: categoryResponse, index: number) => {
                      return (
                        <Box
                          key={category.id}
                          sx={{
                            width: { xs: "100%", sm: "40%", md: "40%" },
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            navigate(`/course-grid?category=${category.id}`);
                          }}
                        >
                          <Typography
                            className=""
                            sx={{
                              whiteSpace: "nowrap",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "5px",
                              borderBottom: "2px solid",
                              borderColor: `${colors[index]}.main`,
                              flexWrap: "nowrap",
                              gap: "20px",
                            }}
                            variant="h5"
                          >
                            {defaultTranslation(category, "name")}{" "}
                            {i18n.language === "en" ? (
                              <FaArrowRightLong />
                            ) : (
                              <FaArrowLeftLong />
                            )}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              ) : (
                <div className="intro1-content text-center text-md-start max-mb-30">
                  <Skeleton
                    variant="rectangular"
                    style={{ margin: "1em 0" }}
                    width={310}
                    height={30}
                  />
                  <Skeleton
                    variant="rectangular"
                    style={{ margin: "3em 0 0 0" }}
                    width={350}
                    height={70}
                  />
                  <Skeleton
                    variant="rectangular"
                    style={{ margin: "1em 0" }}
                    width={400}
                    height={30}
                  />
                  <Skeleton
                    variant="rectangular"
                    style={{ margin: "2em 0" }}
                    width={120}
                    height={40}
                  />
                </div>
              )}
            </div>
            <div className="col max-mb-30 mt-md-0 mt-5" data-aos="fade-up">
              <img
                src={require("../../../assets/images/hero-image/hero.jpg")}
                alt=""
                style={{
                  transform: i18n.language === "ar" ? "scaleX(-1)" : "",
                }}
              />
            </div>
          </div>
        </Box>
      </div>
    </Suspense>
  );
};

export default LandingSection;
