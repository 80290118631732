import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { getBasicDate, getImageFromServer } from "../../assets/JS/helpers";
import { BlogResponse } from "../../interfaces/new-api-interfaces/blog";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Stack,
  Chip,
} from "@mui/material";
import { BsCalendarDate } from "react-icons/bs";

import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import { PiNotePencilBold } from "react-icons/pi";
import i18next from "i18next";
import { useAtomValue } from "jotai";
import { instructorIdAtom, roleAtom } from "../../store";
import { useTranslation } from "react-i18next";
import { Delete } from "@mui/icons-material";
import { fetchApi } from "../../api/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { defaultTranslation } from "../../untilites/functions";

interface BlogCardPorps {
  id: number;
  name: string;
  createdAt: BlogResponse["created_at"];
  description: string;
  image: string;
  createdBy: BlogResponse["created_by"] | null;
  instructorId: BlogResponse["user_id"];
  isProfile?: boolean;
  categories?: BlogResponse["categories"];
}

export const BlogCard: React.FC<BlogCardPorps> = ({
  id,
  name,
  createdAt,
  description,
  image,
  createdBy,
  instructorId,
  isProfile = false,
  categories,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const role = useAtomValue(roleAtom);
  const user = useAtomValue(instructorIdAtom);

  const [open, setOpen] = React.useState(false);

  const queryClient = useQueryClient();

  const [deletedID, setDeletedID] = React.useState(0);

  const { isLoading, mutateAsync: DeleteBlog } = useMutation(
    (id: any) => fetchApi(`/blog/blog/${deletedID}`, "Delete"),
    {
      onError: (error: any) => {
        setOpen(false);
        toast.error(
          i18next.language === "en"
            ? "Something went wrong, please try again later."
            : "حدث خطأ ما، يرجى المحاولة مرة أخرى لاحقًا."
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["instructor-blogs"]);
        setOpen(false);
        toast.success(
          i18next.language === "en"
            ? "Blog Deleted successfully"
            : "تم حذف المقالة بنجاح"
        );
      },
    }
  );

  const handleClickOpen = (id: any) => {
    setDeletedID(id);
    setOpen(true);
  };

  const handleClose = (event: any) => {
    event.stopPropagation();
    setOpen(false);
  };

  console.log(categories);

  return (
    <div className="w-100" style={{ margin: "0px 1%" }}>
      <Dialog
        open={open}
        onClose={(e) => handleClose(e)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <>
          <DialogTitle id="alert-dialog-title">
            {i18next.language === "en"
              ? "Are You Sure You Want To Delete This Blog ?"
              : "هل أنت متأكد من حذف هذه المقالة؟"}
          </DialogTitle>
          <DialogContent></DialogContent>
          <DialogActions sx={{ gap: "10px" }}>
            <Button
              variant="contained"
              size="small"
              onClick={(e) => handleClose(e)}
            >
              {t("cancel")}
            </Button>
            <Button
              color="error"
              onClick={(e) => DeleteBlog(deletedID)}
              autoFocus
              variant="contained"
              size="small"
              disabled={isLoading}
            >
              {t("GlobalDelete")}
            </Button>
          </DialogActions>
        </>
      </Dialog>
      <Card
        className="col max-mb-30"
        data-aos="fade-up"
        sx={{ minHeight: { xs: "auto", sm: "450px" }, borderRadius: "10px" }}
      >
        <Box
          sx={{ cursor: "pointer" }}
          onClick={() =>
            navigate(isProfile ? `/edit-blog/${id}` : `/blogs/${id}`)
          }
        >
          <CardMedia
            image={getImageFromServer(image)}
            sx={{ height: 200, width: "100%" }}
            title={name}
          />
          <Box
            sx={{
              bgcolor: "#ECECEC",
              padding: "10px",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: "10px",
            }}
          >
            {!isProfile && (
              <Typography
                sx={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                  color: "primary.main",
                  fontWeight: 600,
                }}
              >
                <PiNotePencilBold />
                {t("By") + " : "}
                {i18next.language === "ar"
                  ? createdBy?.ar?.first_name + " " + createdBy?.ar?.last_name
                  : createdBy?.en?.first_name + " " + createdBy?.en?.last_name}
              </Typography>
            )}
            <Typography
              sx={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
                color: "primary.main",
                fontWeight: 600,
              }}
            >
              <BsCalendarDate />
              {createdAt ? getBasicDate(createdAt) : ""}
            </Typography>
          </Box>
        </Box>
        <CardContent>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <Typography variant="h5">{name}</Typography>
            <Typography>
              {description.slice(0, 70)}
              {description.length > 70 ? "..." : ""}
            </Typography>
          </Box>
        </CardContent>
        <CardActions>
          {categories && (
            <Stack
              sx={{ position: "absolute", bottom: 20, right: 20 }}
              flexDirection={"row"}
              gap={1}
              alignItems={"center"}
            >
              {categories?.map((category, i) => (
                <Chip
                  key={i}
                  label={defaultTranslation(category, "name")}
                  color="primary"
                  variant="filled"
                />
              ))}
            </Stack>
          )}
          <Box
            sx={{
              padding: { xs: 0, sm: "16px" },
              position: { xs: "block", sm: "absolute" },
              bottom: "0px",
              left: "0px",
              display: "flex",
              alignItems: "end",
              justifyContent: "",
              flexWrap: "wrap",
              gap: "1rem",
              width: "100%",
            }}
          >
            <button
              onClick={() => navigate(`/blogs/${id}`)}
              className="home-action"
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                {i18next.language == "en" && (
                  <>
                    <span>Read More</span> <FaArrowRightLong />
                  </>
                )}
                {i18next.language == "ar" && (
                  <>
                    <span>قراءة المزيد</span> <FaArrowLeftLong />
                  </>
                )}
              </Typography>
            </button>
          </Box>
        </CardActions>
        {isProfile && (
          <IconButton
            onClick={() => handleClickOpen(id)}
            color="error"
            id={id.toString()}
            sx={{ position: "absolute", bottom: "20px", right: "20px" }}
          >
            <Delete />
          </IconButton>
        )}
      </Card>
    </div>
  );
};
