import { Button, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import EvaluationRangesModal from "./evaluation-ranges-modal";
import i18next from "i18next";

const inputStyle = {
  width: { xs: "100%", sm: "45%" },
  marginBottom: "30px",
};

type Props = {
  control: any;
  getValues: Function;
  setValues: Function;
  errors: any;
  formMethod: "update" | "create";
  testId: number;
};

const GradeInputSection = ({
  control,
  getValues,
  setValues,
  errors,
  formMethod,
  testId,
}: Props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const { t } = useTranslation();
  return (
    <>
      <EvaluationRangesModal
        control={control}
        modalOpen={open}
        handleModalClose={handleClose}
        getValues={getValues}
        setValues={setValues}
        errors={errors}
        formMethod={formMethod}
        testId={testId}
      />
      <Stack
        alignItems="center"
        direction="row"
        width="92%"
        justifyContent="space-between"
      >
        <Controller
          name="total_mark"
          control={control}
          defaultValue={100}
          render={({ field }) => (
            <TextField
              type="number"
              inputProps={{ inputMode: "numeric", min: 1 }}
              sx={inputStyle}
              {...field}
              label={t("fullMark")}
              placeholder={t("fullMark")}
              required
              size="small"
            />
          )}
        />
        <Button
          sx={inputStyle}
          size="small"
          onClick={() => handleOpen()}
          variant="contained"
        >
          {formMethod == "create" ? (
            <Typography>
              {i18next.language == "en"
                ? "Add Evaluation Ranges"
                : "إضافة نطاقات التقييم"}
            </Typography>
          ) : (
            <Typography>
              {i18next.language == "en"
                ? "Edit Evaluation Ranges"
                : "تعديل نطاقات التقييم"}
            </Typography>
          )}
        </Button>
      </Stack>
    </>
  );
};

export default GradeInputSection;
