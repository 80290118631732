import * as React from "react";
import DraggableSection from "./DraggableSection/DraggableSection";
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd";
import { SectionType } from "../EditBlogForm";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteSection } from "../../../../api/endpoints-functions";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

export type DraggableListProps = {
  items: SectionType[];
  onDragEnd: OnDragEndResponder;
  onDelete?: any;
  onOpen: any;
  onAddFile: any;
};

const DraggableSections = React.memo(
  ({ items, onDragEnd, onDelete, onOpen, onAddFile }: DraggableListProps) => {
    const [deletedId, setDeletedId] = React.useState<number>(0);
    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = React.useState(false);

    const queryClient = useQueryClient();

    const { id } = useParams();

    const Delete = useMutation((id: number) => deleteSection(id), {
      onSuccess: () => {
        toast.success("Section deleted succefully");
        queryClient.invalidateQueries(["get-blog"]);
      },
      onError: () => {
        toast.error("Something went wrong, please try again");
      },
    });

    const handleDeleteSection = (id: number) => {
      setDeletedId(id);
      setDeleteDialogIsOpen(true);
    };

    return (
      <>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-list">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {items.map((item, index) => (
                  <DraggableSection
                    id={`section-${item.id}`}
                    item={item}
                    index={index}
                    onDelete={onDelete}
                    onOpen={onOpen}
                    onAddFile={onAddFile}
                    handleDeleteSection={handleDeleteSection}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Dialog
          open={deleteDialogIsOpen}
          onClose={() => setDeleteDialogIsOpen(false)}
        >
          <DialogTitle>
            <Typography variant="h5">
              Are you sure you want to delete section ?
            </Typography>
          </DialogTitle>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => setDeleteDialogIsOpen(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                Delete.mutateAsync(deletedId).then(() => {
                  setDeleteDialogIsOpen(false);
                });
              }}
              variant="contained"
              color="error"
              disabled={Delete.isLoading}
            >
              {Delete.isLoading ? "Loading..." : "Confirm"}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
);

export default DraggableSections;
