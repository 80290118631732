import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
  mainName: string;
  subLinksArr: { href: string; linkName: string; isHot?: boolean }[];
  children?: any;
  hasStyle?: boolean;
}

const NavbarItem: React.FC<Props> = ({
  hasStyle,
  mainName,
  subLinksArr,
  children,
}) => {
  return (
    <li
      style={{ whiteSpace: "nowrap" }}
      className={`has-children ${hasStyle ? "subMegaMenu" : ""}`}
    >
      <a href="/" onClick={(e) => e.preventDefault()}>
        <span className="menu-text">
          <Typography>{mainName}</Typography>
          {hasStyle ? (
            <i className="bx bx-chevron-right"></i>
          ) : (
            <i className="bx bx-chevron-down"></i>
          )}
        </span>
      </a>
      <span className="menu-toggle">
        <i className="far fa-angle-down"></i>
      </span>
      <ul className="mega-menu" style={{ width: "unset" }}>
        <li>
          <ul>
            {React.Children.toArray(
              subLinksArr.map((link) => {
                return (
                  <li>
                    <Link to={link.href}>
                      <span className="menu-text">
                        <Typography>
                          {link.linkName}{" "}
                          {link.isHot ? (
                            <span className="badge">Hot</span>
                          ) : null}
                        </Typography>
                      </span>
                    </Link>
                  </li>
                );
              })
            )}
            {children}
          </ul>
        </li>
      </ul>
    </li>
  );
};
export default NavbarItem;
