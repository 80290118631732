import React from "react";
interface Props {

}

export const ScrollTop: React.FC<Props> = () => {
  return (
    <a href="#" className="scroll-top" id="scroll-top">
      <i className="arrow-top fal fa-long-arrow-up"></i>
      <i className="arrow-bottom fal fa-long-arrow-up"></i>
    </a>
  );
}