import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { TiDeleteOutline } from "react-icons/ti";
import { fetchApi } from "../../../api/api";
import { toast } from "react-toastify";
import i18next, { t } from "i18next";

type Props = {
  control: any;
  getValues: Function;
  setValue: Function;
  errors: any;
  questionMethod: "update" | "create";
  questionId: number;
};

const AddEditMatch = (props: Props) => {
  const [newKey, setNewKey] = useState("");
  const [newKeyEn, setNewKeyEn] = useState("");
  const [matchedKey, setMatchedKey] = useState("");
  const [matchedKeyEn, setMatchedKeyEn] = useState("");
  const {
    isLoading,
    isError,
    mutateAsync: deleteOption,
  } = useMutation(
    (data: any) => fetchApi(`/test/matchLeft/${data.id}`, "DELETE", data),
    {
      onError: () => {
        toast.error(
          i18next.language === "en"
            ? "Something went wrong Deleting Option, please try again later."
            : "حدث خطأ ما أثناء حذف الخيارات، يرجى المحاولة مرة أخرى لاحقًا."
        );
      },
      onSuccess: () => {
        toast.success(
          i18next.language === "en"
            ? "match sentence deleted successfully"
            : "تم حذف عبارة الوصل بنجاح"
        );
      },
    }
  );

  const {
    isLoading: loadingAdd,
    isError: errorAdd,
    mutateAsync: addOption,
  } = useMutation(
    (data: any) => fetchApi(`/test/question/createMatchQuestion`, "POST", data),
    {
      onError: () => {
        toast.error(
          i18next.language === "en"
            ? "Something went wrong Adding Option, please try again later."
            : "حدث خطأ ما أثناء إضافة الخيارات، يرجى المحاولة مرة أخرى لاحقًا."
        );
      },
      onSuccess: () => {
        toast.success(
          i18next.language === "en"
            ? "option deleted successfully"
            : "تم إضافة الخيار بنجاح"
        );
      },
    }
  );

  const handleAddMatch = (
    newKey: string,
    newKeyEn: string,
    matchedKey: string,
    matchedKeyEn: string
  ) => {
    const currentOptions = props.getValues("match");
    if (props.questionMethod == "update") {
      addOption(
        {
          question_id: props.questionId,
          key: newKey,
          key_en: newKeyEn,
          value: matchedKey,
          value_en: matchedKeyEn,
        },
        {
          onSuccess: (data) => {
            const newOptions = [
              ...currentOptions,
              {
                id: data.data.data.id,
                key: newKey,
                key_en: newKeyEn,
                value: matchedKey,
                value_en: matchedKeyEn,
              },
            ];
            props.setValue("match", newOptions);
          },
        }
      );
    } else {
      const newOptions = [
        ...currentOptions,
        {
          key: newKey,
          key_en: newKeyEn,
          value: matchedKey,
          value_en: matchedKeyEn,
        },
      ];
      props.setValue("match", newOptions);
    }
  };

  const handleDeleteMatch = (index: number, id: number) => {
    const currentOptions = props.getValues("match");
    const newOptions = [...currentOptions];

    if (props.questionMethod == "update") {
      deleteOption(
        { id: id },
        {
          onSuccess: () => {
            newOptions.splice(index, 1);
            props.setValue("match", newOptions);
          },
        }
      );
    } else {
      newOptions.splice(index, 1);
      props.setValue("match", newOptions);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stack
        direction="row"
        alignItems="center"
        gap={3}
        paddingBottom="20px"
        flexWrap="wrap"
      >
        <TextField
          label={t("optionAr")}
          defaultValue=""
          onChange={(e) => {
            setNewKey(e.target.value);
          }}
          value={newKey}
          sx={{ width: "45%" }}
          size="small"
        />
        <TextField
          label={t("matchedOptionAr")}
          defaultValue=""
          onChange={(e) => {
            setMatchedKey(e.target.value);
          }}
          value={matchedKey}
          sx={{ width: "45%" }}
          size="small"
        />
        <TextField
          label={t("optionEn")}
          defaultValue=""
          onChange={(e) => {
            setNewKeyEn(e.target.value);
          }}
          value={newKeyEn}
          sx={{ width: "45%" }}
          size="small"
        />
        <TextField
          label={t("matchedOptionEn")}
          defaultValue=""
          onChange={(e) => {
            setMatchedKeyEn(e.target.value);
          }}
          value={matchedKeyEn}
          sx={{ width: "45%" }}
          size="small"
        />
        <Button
          onClick={() => {
            handleAddMatch(newKey, newKeyEn, matchedKey, matchedKeyEn);
            setNewKey("");
            setNewKeyEn("");
            setMatchedKey("");
            setMatchedKeyEn("");
          }}
          variant="contained"
          size="small"
          disabled={loadingAdd || newKey == "" || matchedKey == ""}
        >
          <Typography fontSize="small" whiteSpace="nowrap">
            {!loadingAdd && <>{t("addSentence")}</>}
            {loadingAdd && (
              <Stack justifyContent="center" alignItems="center">
                <CircularProgress size={18} sx={{ color: "#fff" }} />
              </Stack>
            )}
          </Typography>
        </Button>
      </Stack>
      <Controller
        name="match"
        control={props.control}
        defaultValue={[]}
        render={({ field: { onChange, value } }) => (
          <>
            {value.map((option: any, index: number) => (
              <Stack
                key={index}
                direction="row"
                alignItems="center"
                paddingY={2}
                gap={2}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {isLoading ? (
                    <IconButton>
                      <CircularProgress size={18} />
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="delete"
                      onClick={() =>
                        handleDeleteMatch(index, option.match_left_id)
                      }
                    >
                      <TiDeleteOutline />
                    </IconButton>
                  )}
                  <Typography whiteSpace="nowrap">{`${t("sentence")} #${
                    index + 1
                  }`}</Typography>
                </Box>
                <Stack
                  direction="row"
                  gap={2}
                  width="90%"
                  flexWrap="wrap"
                  sx={{
                    border: "solid",
                    borderWidth: "1px",
                    borderColor: "#ccc",
                    borderRadius: "10px",
                    padding: "20px",
                  }}
                >
                  <TextField
                    label={`${t("option")} #${index + 1} ${t("inAr")}`}
                    defaultValue=""
                    value={option.key}
                    onChange={(e) => {
                      const newOptions = [...value];
                      newOptions[index].key = e.target.value;
                      onChange(newOptions);
                    }}
                    sx={{ width: "48%" }}
                    size="small"
                  />
                  <TextField
                    label={`${t("matchedOption")} #${index + 1} ${t("inAr")}`}
                    defaultValue=""
                    value={option.value}
                    onChange={(e) => {
                      const newOptions = [...value];
                      newOptions[index].value = e.target.value;
                      onChange(newOptions);
                    }}
                    sx={{ width: "48%" }}
                    size="small"
                  />
                  <TextField
                    label={`${t("option")} #${index + 1} ${t("inEn")}`}
                    defaultValue=""
                    value={option.key_en}
                    onChange={(e) => {
                      const newOptions = [...value];
                      newOptions[index].key_en = e.target.value;
                      onChange(newOptions);
                    }}
                    sx={{ width: "48%" }}
                    size="small"
                  />
                  <TextField
                    label={`${t("matchedOption")} #${index + 1} ${t("inEn")}`}
                    defaultValue=""
                    value={option.value_en}
                    onChange={(e) => {
                      const newOptions = [...value];
                      newOptions[index].value_en = e.target.value;
                      onChange(newOptions);
                    }}
                    sx={{ width: "48%" }}
                    size="small"
                  />
                </Stack>
              </Stack>
            ))}
          </>
        )}
      />
    </Box>
  );
};

export default AddEditMatch;
