import { LoadingPage } from "../../components/Common/loadingPage.component";
import DisplayCorrectedTest from "../../components/Test/display-corrected-test";
import { useParams } from "react-router-dom";
import { fetchApi } from "../../api/api";
import { useQuery } from "@tanstack/react-query";
import { fetchStudentCorrectedTest } from "../../api/endpoints-functions";
import { Box, Stack, Typography } from "@mui/material";
import { defaultTranslation } from "../../untilites/functions";
import { FeedbackOutlined } from "@mui/icons-material";
import i18next from "i18next";

interface Props {
  testId: any;
}
const CourseCorrectedTestPage: React.FC<Props> = ({ testId }) => {
  // const testId = useParams();

  const { data, isLoading, isError, isFetching } = useQuery(
    ["get-my-solved-tests"],
    () => fetchStudentCorrectedTest(testId, "test")
  );

  if (isLoading || isFetching) return <LoadingPage />;

  if (isError)
    return (
      <Stack height={"100%"} alignItems={"center"} justifyContent={"center"}>
        <Typography variant="h4">
          {i18next.language === "ar"
            ? "حدث خطأ ما، الرجاء إعادة المحاولة!"
            : "Something went wrong, please try again!"}
        </Typography>
      </Stack>
    );

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          paddingTop: "50px",
          paddingBottom: "50px"
        }}
      >
        <Typography variant="h4" color="primary.main">
          {defaultTranslation(data?.Test_name, "name")}
        </Typography>
      </Box>
      {data && !data.is_graded && (
        <Box className="container">
          <Box
            sx={{
              border: "solid 1px #ccc",
              marginTop: "1rem",
              padding: "20px",
            }}
          >
            <Typography
              display={"flex"}
              gap={"0.4rem"}
              alignItems={"center"}
              variant="h5"
              color="mypurple2.main"
              marginBottom={"1rem"}
            >
              <FeedbackOutlined />
              {i18next.language == "en" ? `Instructor Notes` : `ملاحظات المدرب`}
            </Typography>
            {data.notes ? (
              <div dangerouslySetInnerHTML={{ __html: data?.notes }} />
            ) : (
              <Typography variant="h6" color="myorange.main">
                {i18next.language === "ar"
                  ? "بانتظار مراجعة المدرب"
                  : "Pending for trainer's feedback"}
              </Typography>
            )}
          </Box>
          <Typography variant="h4" marginTop={"1.5rem"}>
            {i18next.language === "ar" ? "إجاباتك:" : "Your Answers:"}
          </Typography>
        </Box>
      )}
      {data && (
        <DisplayCorrectedTest
          isGraded={data?.is_graded!}
          totalMark={data?.total_mark!}
          questions={data?.answers!}
        />
      )}
    </>
  );
};

export default CourseCorrectedTestPage;
