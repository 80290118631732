import React from "react";
import { useNavigate } from "react-router-dom";
import {
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
  CircularProgress,
  Typography,
  Button,
  Box,
} from "@mui/material";
import i18next from "i18next";
import { studentVideoConsultation } from "../../../interfaces/new-api-interfaces/consultations";
import { defaultTranslation } from "../../../untilites/functions";
import { useTranslation } from "react-i18next";
import { FaRegCheckCircle } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchApi } from "../../../api/api";
import { toast } from "react-toastify";
import { FaEye } from "react-icons/fa6";

interface Props {
  Consultation: studentVideoConsultation;
  linkTo: string;
  id: number;
  setSelectedStatus: Function;
  setId: Function;
  handleOpenModal: Function;
}

interface StatusTranslations {
  ar: string;
  en: string;
}

interface StatusKeyName {
  [key: string]: StatusTranslations;
}

export const getStatusTranslation = (status: string, language: string) => {
  const statusKeyName: StatusKeyName = {
    pending: { ar: "قيد الانتظار", en: "Pending" },
    scheduled: { ar: "مجدولة", en: "Scheduled" },
    paid: { ar: "مثبتة", en: "Paid" },
    "rejected by student": {
      ar: "مرفوضة من قبلك",
      en: "Rejected by you",
    },
    "rejected by instructor": {
      ar: "مرفوضة من قبل المدرب",
      en: "Rejected by trainer",
    },
    done: { ar: "منتهية", en: "Done" },
  };
  const statusObject = statusKeyName[status];
  if (statusObject) {
    return statusObject[language as "ar" | "en"];
  }
  return "Status not found"; // Handle the case when status is not found in statusKeyName
};

const VideoConsultationTableItem: React.FC<Props> = ({
  id,
  Consultation,
  setSelectedStatus,
  setId,
  handleOpenModal,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Usage for get status translation
  const translatedStatus = getStatusTranslation(
    Consultation.status,
    i18next.languages[0]
  );

  const queryClient = useQueryClient();

  //mutate accept consultation
  const { isLoading, mutateAsync: acceptConsultation } = useMutation(
    (id: number) =>
      fetchApi(`/consultation/liveConsultation/studentConfirm/${id}`, "POST"),
    {
      onSuccess: () => {
        toast.success(
          i18next.language === "en"
            ? "Consultation Submitted Successfuly"
            : "تم تثبيت الاستشارة بنجاح"
        );
        queryClient.invalidateQueries(["video-consultations"]);
        setSelectedStatus("paid");
      },
      onError: (error: any) => {
        toast.error(
          i18next.language === "en"
            ? "Something went wrong, please try again later."
            : "حدث خطأ ما، يرجى المحاولة مرة أخرى لاحقًا."
        );
      },
    }
  );
  //mutate reject consultation
  const { isLoading: loadingReject, mutateAsync: rejectConsultation } =
    useMutation(
      (id: number) =>
        fetchApi(`/consultation/liveConsultation/studentReject/${id}`, "POST"),
      {
        onSuccess: () => {
          toast.success(
            i18next.language === "en"
              ? "Consultation Cancelled Successfuly"
              : "تم إلغاء الاستشارة بنجاح"
          );
          queryClient.invalidateQueries(["video-consultations"]);
          setSelectedStatus("rejected by student");
        },
        onError: (error: any) => {
          toast.error(
            i18next.language === "en"
              ? "Something went wrong, please try again later."
              : "حدث خطأ ما، يرجى المحاولة مرة أخرى لاحقًا."
          );
        },
      }
    );
  return (
    <TableRow>
      <TableCell className="column-date">
        <Typography>
          {Consultation.instructor?.id
            ? defaultTranslation(Consultation.instructor, "first_name") +
              " " +
              defaultTranslation(Consultation.instructor, "last_name")
            : "-"}
        </Typography>
      </TableCell>
      {["pending", "scheduled", "paid"].includes(Consultation.status) && (
        <TableCell>
          <Typography>
            {Consultation.status == "scheduled"
              ? Consultation.instructor_date?.slice(0, 10)
              : Consultation.student_date?.slice(0, 10)}
          </Typography>
        </TableCell>
      )}
      {["scheduled", "paid", "done"].includes(Consultation.status) && (
        <>
          <TableCell>
            <Typography>
              {`${Consultation.instructor_start_time?.slice(
                0,
                -3
              )} - ${Consultation.instructor_end_time?.slice(0, -3)}`}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography>{`${Consultation.price?.toLocaleString()} ${t("syp")}`}</Typography>
          </TableCell>
        </>
      )}
      <TableCell className="column-quiz column-quiz-1308">
        <Typography>
          {Consultation.student_reason?.slice(0, 30)}
          {Consultation.student_reason?.length > 30 ? "..." : ""}
        </Typography>
      </TableCell>
      {Consultation.status == "rejected by instructor" &&
        Consultation.instructor_notes && (
          <TableCell className="column-quiz column-quiz-1308">
            <Typography>
              {Consultation.instructor_notes?.slice(0, 30)}
              {Consultation.instructor_notes?.length > 30 ? "..." : ""}
            </Typography>
          </TableCell>
        )}
      <TableCell
        sx={{
          color: Consultation.status.includes("rejected")
            ? "red"
            : Consultation.status == "pending"
            ? "orange"
            : Consultation.status == "scheduled"
            ? "blue"
            : Consultation.status == "paid"
            ? "green"
            : "default",
        }}
        className="column-quiz column-quiz-1308"
      >
        <Typography>{translatedStatus}</Typography>
      </TableCell>
      <TableCell align="center">
        {Consultation.status == "scheduled" && (
          <>
            <Tooltip
              arrow
              title={i18next.language === "ar" ? "إلغاء" : "Cancel"}
            >
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  rejectConsultation(Consultation.id);
                }}
                color="error"
              >
                {!loadingReject ? (
                  <ImCancelCircle />
                ) : (
                  <CircularProgress size={20} />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip
              arrow
              title={i18next.language === "ar" ? "موافقة" : "Accept"}
            >
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  acceptConsultation(Consultation.id);
                }}
                color="success"
              >
                {!isLoading ? (
                  <FaRegCheckCircle />
                ) : (
                  <CircularProgress size={20} />
                )}
              </IconButton>
            </Tooltip>
          </>
        )}
        <Tooltip arrow title={i18next.language === "ar" ? "معاينة" : "Preview"}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setId(Consultation.id);
              handleOpenModal();
            }}
            color="primary"
          >
            <FaEye />
          </IconButton>
        </Tooltip>
        {Consultation.status === "paid" && (
          <Button
            size="small"
            variant="contained"
            color="myorange2"
            disabled={!Consultation.meet_link}
            onClick={() => {
              window.open(Consultation.meet_link!, "_blank");
              // navigate("/join-consultation-room", {
              //   state: {
              //     meet_link: Consultation.meet_link,
              //     instructorId: Consultation.instructor?.user_id,
              //     consultationId: Consultation.id,
              //     remoteUser: {
              //       profile_image: Consultation.instructor?.profile_image,
              //       translations: Consultation.instructor?.translations,
              //     },
              //   },
              // });
            }}
          >
            {t("joinMeeting")}
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

export default VideoConsultationTableItem;
