import React, { useState } from "react";
import { instructorLiveConsultation } from "../../../interfaces/new-api-interfaces/consultations";
import { SetStateAction } from "jotai";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import LiveConsultationTableItem from "./live-consultation-table-item";
import RejectConsultationModal from "./reject-consultation-modal";
import AcceptConsultationModal from "./accept-consultaiton-modal";
import ConsultationStatusSelect from "./consultation-status";
import LoadingTable from "../../Common/loading-table";
import PreviewConsultationModal from "./preview-consultation-moda";

const PendingCells = () => {
  const { t } = useTranslation();
  return (
    <>
      <TableCell align="center">
        <Typography textAlign="center" fontSize="large">
          {t("studentConsultationDate")}
        </Typography>
      </TableCell>
    </>
  );
};

const ScheduledCells = () => {
  const { t } = useTranslation();
  return (
    <>
      <TableCell align="center">
        <Typography textAlign="center" fontSize="large">
          {t("consultationScheduledDate")}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography textAlign="center" fontSize="large">
          {t("consultationTime")}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography textAlign="center" fontSize="large">
          {t("consultationPrice")}
        </Typography>
      </TableCell>
    </>
  );
};

const RejectedCells = () => {
  const { t } = useTranslation();
  return (
    <TableCell align="center">
      <Typography textAlign="center" fontSize="large">
        {t("rejectReason")}
      </Typography>
    </TableCell>
  );
};
const PaidCells = () => {
  const { t } = useTranslation();
  return (
    <>
      <TableCell align="center">
        <Typography textAlign="center" fontSize="large">
          {t("scheduledDate")}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography textAlign="center" fontSize="large">
          {t("consultationTime")}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography textAlign="center" fontSize="large">
          {t("consultationPrice")}
        </Typography>
      </TableCell>
    </>
  );
};

const DoneCells = () => {
  const { t } = useTranslation();

  return (
    <>
      <TableCell align="center">
        <Typography textAlign="center" fontSize="large">
          {t("consultationScheduledDate")}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography textAlign="center" fontSize="large">
          {t("consultationTime")}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography textAlign="center" fontSize="large">
          {t("consultationPrice")}
        </Typography>
      </TableCell>
    </>
  );
};

const LiveConsultations = ({
  data,
  status,
  setStatus,
  isLoading,
  isFetching,
}: {
  data: instructorLiveConsultation[];
  status:
    | "pending"
    | "scheduled"
    | "paid"
    | "rejected by instructor"
    | "rejected by student"
    | "done";
  setStatus: any;
  isLoading: boolean;
  isFetching: boolean;
}) => {
  const { t } = useTranslation();
  const [rejectOpen, setRejectOpen] = useState(false);
  const [acceptOpen, setAcceptOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [id, setId] = useState<number>();

  const existingItem =
    data && id ? data.find((item) => item.id === id) : undefined;

  let statusCells;
  let statusColor: any;

  if (status === "pending") {
    statusCells = <PendingCells />;
    statusColor = "orange";
  } else if (status === "scheduled") {
    statusCells = <ScheduledCells />;
    statusColor = "blue";
  } else if (status === "rejected by instructor") {
    statusCells = <RejectedCells />;
    statusColor = "error";
  } else if (status === "rejected by student") {
    statusColor = "error";
    statusCells = <TableCell></TableCell>;
  } else if (status === "paid") {
    statusCells = <PaidCells />;
    statusColor = "green";
  } else if (status === "done") {
    statusCells = <DoneCells />;
    statusColor = "gray";
  }

  return (
    <>
      <div className="learn-press-subtab-content">
        <ConsultationStatusSelect
          setSelectedStatus={setStatus}
          selectedStatus={status}
        />
        <div className="tab-content">
          <div className="col-12 scroll">
            <LoadingTable
              isLoading={isLoading}
              isFetching={isFetching}
              columns={5}
            >
              {data && data.length > 0 ? (
                <TableContainer>
                  <Table className="lp-list-table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{ borderTopLeftRadius: "20px" }}
                        >
                          <Typography textAlign="center" fontSize="large">
                            {i18next.language === "ar" ? "الرقم" : "ID"}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography textAlign="center" fontSize="large">
                            {t("name")}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography textAlign="center" fontSize="large">
                            {t("consultationReason")}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography textAlign="center" fontSize="large">
                            {t("studentConsultationNotes")}
                          </Typography>
                        </TableCell>
                        {statusCells}
                        <TableCell align="center">
                          <Typography textAlign="center" fontSize="large">
                            {t("status")}
                          </Typography>
                        </TableCell>{" "}
                        <TableCell
                          align="center"
                          sx={{ borderTopRightRadius: "20px" }}
                        >
                          <Typography
                            textAlign="center"
                            fontSize="large"
                          ></Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((consultation: instructorLiveConsultation) => (
                        <LiveConsultationTableItem
                          key={consultation.id}
                          consultation={consultation}
                          setRejectOpen={setRejectOpen}
                          setId={setId}
                          setAcceptOpen={setAcceptOpen}
                          status={status}
                          setPreviewOpen={setPreviewOpen}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className="row">
                  <div className="col-12">
                    <div className="learn-press-message success">
                      <i className="bx bx-info-circle"></i>
                      {i18next.language === "en"
                        ? `You Don't have any previous consultation!`
                        : "لا تملك استشارات سابقة!"}
                    </div>
                  </div>
                </div>
              )}
            </LoadingTable>
          </div>
        </div>
      </div>
      <RejectConsultationModal
        open={rejectOpen}
        setOpen={setRejectOpen}
        id={id}
      />
      <AcceptConsultationModal
        open={acceptOpen}
        setOpen={setAcceptOpen}
        id={id}
      />
      {existingItem && (
        <PreviewConsultationModal
          open={previewOpen}
          setOpen={setPreviewOpen}
          item={existingItem}
          statusColor={statusColor}
        />
      )}
    </>
  );
};

export default LiveConsultations;
