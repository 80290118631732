import React from "react";
import Image from "../../../assets/images/error/contruction.png";
import { Box, Stack, Typography } from "@mui/material";
import logo from "../../../assets/images/logo/logo.svg";
import {
  FaInstagram,
  FaLinkedinIn,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa6";

export const UnderMaintenance: React.FC<any> = () => {
  return (
    <Box minHeight={"100vh"} className="container">
      <Stack
        padding={"1rem 0"}
        direction={{ xs: "column", sm: "row" }}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={"1rem"}
      >
        <Box>
          <img src={logo} alt="Learts Logo" />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            padding: "5px 20px",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Follow Us:</Typography>
          <Box className="header-social">
            <FaLinkedinIn />
          </Box>
          <Box className="header-social">
            <FaYoutube />
          </Box>
          <Box className="header-social">
            <FaInstagram />
          </Box>
          <Box className="header-social">
            <FaWhatsapp />
          </Box>
        </Box>
      </Stack>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
        className="container"
        padding={"6rem 0"}
      >
        {/*<pre>{error.message}</pre>*/}
        <Box>
          <img src={Image} width="500px" height="500px" />
        </Box>
      </Box>
    </Box>
  );
};
