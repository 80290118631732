import React from "react";
import FooterWidget from "./footer-widget";
import { useTranslation } from "react-i18next";
import Logo from "../../assets/images/logo/white-logo.png";
import { Box, Typography } from "@mui/material";

interface Props {}

const Footer: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <div
      className="footer-section section"
      style={{ backgroundColor: "#171621", color: "white", zIndex: "999" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-md-5 col-12">
            <div className="footer-widget">
              <Box className="footer-logo" sx={{ marginBottom: "10px" }}>
                <img src={Logo} alt="UH Training" width={100} />
              </Box>
              <h4 className="footer-widget-title" style={{ color: "white" }}>
                {t("FooterAddress")} :
              </h4>
              <div className="footer-widget-content">
                <div className="content">
                  <Typography>
                    {" "}
                    شارع الإمام أبي حنيفة النعمان – دمشق – سوريا
                  </Typography>
                  <Typography>
                    <a href="mailto:info@example.com" className="footer-link">
                      info@uh-training.com
                    </a>
                  </Typography>
                </div>
                <div className="footer-social-inline">
                  <a
                    href="https://www.facebook.com/unlimitedhorizonssy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook-square"></i>
                  </a>
                  <a href="#" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="#" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a href="#" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-linkedin"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <FooterWidget
            containerStyle="col-xl-6 col-md-6 col-sm-10 col-12"
            title={t("FooterWidgetInformation")}
            listItems={[
              { id: 5, link: "/about", name: t("NavHeaderAboutUs") },
              { id: 6, link: "/contact-us", name: t("locations") },
              { id: 2, link: "/terms-of-service", name: t("TOS") },
              {
                id: 3,
                link: "/privacy-and-terms",
                name: t("FootelListPrivacy"),
              },
            ]}
          />
        </div>
        <div className="row">
          <div className="col">
            <p className="copyright">
              &copy; {new Date().getFullYear()} UH Training. {t("FooterRights")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
