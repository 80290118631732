import React, { useState } from "react";

import { Pagination } from "@mui/material";
import { EnrollmentCourseOuterResponse } from "../../../interfaces/api-interfaces";
import ProfileCourseItem from "../profile-components/profile-course-item";
import i18n from "i18next";
import { profileCoursesResponse } from "../../../interfaces/new-api-interfaces/profile";

interface Props {
  courses: profileCoursesResponse[];
  activeTab: string | null;
}

const ProfileCourses: React.FC<Props> = ({ courses, activeTab }) => {
  const ITEMS_PER_PAGE: number = 9;
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(ITEMS_PER_PAGE);

  const paginate = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPageNumber(page);
    setMin(ITEMS_PER_PAGE * (page - 1));
    setMax(page * ITEMS_PER_PAGE);
  };

  return (
    <div
      id="courses"
      className={`profile-content-courses tab-pane fade pt-5 show ${
        activeTab === "courses" ? "active" : ""
      }`}
    >
      <div className="learn-press-subtab-content">
        <div className="section section-padding-bottom">
          <div className="container">
            <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30">
              {React.Children.toArray(
                courses && courses.length ? (
                  courses
                    .sort(
                      (a, b) =>
                        new Date(b.created_at).valueOf() -
                        new Date(a.created_at).valueOf()
                    )
                    .filter((_1: any, idx: number) => idx >= min && idx < max)
                    .map((course) => (
                      <div
                        className="col-12 col-md-6 col-lg-4 my-2"
                        key={course.id}
                      >
                        <ProfileCourseItem {...course} />
                      </div>
                    ))
                ) : (
                  <div className="row">
                    <div className="col-12">
                      <div className="learn-press-message success">
                        <i className="bx bx-info-circle"></i>
                        {i18n.language === "en"
                          ? "No Courses!"
                          : "لا تملك دورات !"}
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
            <>
              {courses?.length > ITEMS_PER_PAGE ? (
                <Pagination
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "20px",
                  }}
                  count={Math.ceil(courses.length / ITEMS_PER_PAGE)}
                  page={currentPageNumber}
                  onChange={paginate}
                  variant="outlined"
                  color="primary"
                  size="large"
                  dir="ltr"
                />
              ) : null}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCourses;
