import React from "react";
import { useNavigate } from "react-router-dom";
import { TableRow, TableCell } from "@mui/material";
import i18next from "i18next";
import { studentTextConsultation } from "../../../interfaces/new-api-interfaces/consultations";
import { defaultTranslation } from "../../../untilites/functions";

interface Props {
  Consultation: studentTextConsultation;
  linkTo: string;
  id: number;
}

const ConsultationTableItem: React.FC<Props> = ({
  id,
  linkTo,
  Consultation,
}) => {
  const navigate = useNavigate();
  return (
    <TableRow
      onClick={() => navigate(!linkTo ? "/edit-test/" + id : linkTo)}
      style={{ cursor: "pointer" }}
    >
      <TableCell className="column-quiz column-quiz-1308">
        {Consultation.question.slice(0, 30)}
        {Consultation.question.length > 30 ? "..." : ""}
      </TableCell>
      <TableCell className="column-date">
        {Consultation.instructor_id
          ? defaultTranslation(Consultation.instructor, "first_name") +
            " " +
            defaultTranslation(Consultation.instructor, "last_name")
          : "-"}
      </TableCell>
      <TableCell className="column-date">
        {Consultation.created_at?.slice(0, 10)}
      </TableCell>
      <TableCell
        sx={{ color: Consultation.is_answered ? "green" : "salmon" }}
        className="column-quiz column-quiz-1308"
      >
        {Consultation.is_answered
          ? i18next.language === "en"
            ? "Answered"
            : "مجاب عليها"
          : i18next.language === "en"
          ? "Not Answered"
          : "غير مجاب عليها"}
      </TableCell>
    </TableRow>
  );
};

export default ConsultationTableItem;
