import { Typography, Box, Stack } from "@mui/material";
import {
  testQuestionAnswerRequest,
  testQuestionForStudent,
} from "../../interfaces/new-api-interfaces/test";
import { useTranslation } from "react-i18next";
import { defaultTranslation } from "../../untilites/functions";
import { useState } from "react";
import { getRandomColor } from "../../assets/JS/helpers";
import i18next from "i18next";

interface Props {
  question: testQuestionForStudent;
  control: any;
  setValue: any;
  getValues: Function;
  clearErrors: any;
  isGraded?: boolean | 0 | 1;
}

const matchStyle = {
  border: "1px solid #ccc",
  padding: "20px",
  borderRadius: "20px",
  cursor: "pointer",
  margin: "20px 20px 20px 0",
};

type LeftItemBackgroundColors = {
  [key: number]: string;
};

const MatchQuestion: React.FC<Props> = ({
  question,
  control,
  setValue,
  getValues,
  clearErrors,
  isGraded,
}) => {
  const { t } = useTranslation();

  const handleMatchSelect = (leftId: number, rightId: number, bg: string) => {
    clearErrors("answer");
    if (selectedValue !== 0) {
      const currentanswer = getValues("answer");

      const newAnswer = {
        match_left_id: leftId,
        match_right_id: rightId,
        question_id: question.id,
        type: "match",
        bg: bg,
      };

      const updatedanswer = [...currentanswer, newAnswer];

      setValue("answer", updatedanswer);
      setSelectedValue(0);
      setSelectedValueBg("");
    }
  };

  const [selectedValue, setSelectedValue] = useState(0);
  const [selectedValueBg, setSelectedValueBg] = useState("");

  const [leftItemBackgroundColors, setLeftItemBackgroundColors] =
    useState<LeftItemBackgroundColors>({});

  // Function to update the background color of a left item
  const updateLeftItemBackgroundColor = (id: number, color: string) => {
    setLeftItemBackgroundColors((prevColors) => ({
      ...prevColors,
      [id]: color,
    }));
  };

  const handleUnselect = (id: number) => {
    const currentanswer = getValues("answer");
    const isAlreadySelected = currentanswer.some(
      (answer: testQuestionAnswerRequest) => answer?.match_left_id === id
    );

    if (isAlreadySelected) {
      // If the item is already selected, remove it from the answer and update the background color
      const updatedanswer = currentanswer.filter(
        (answer: testQuestionAnswerRequest) => answer?.match_left_id !== id
      );
      setValue("answer", updatedanswer);
      updateLeftItemBackgroundColor(id, "transparent"); // Update the background color immediately
    }
  };

  const isSelectedRight = (id: number) => {
    let backgroundColor = "transparent"; // Default background color

    getValues("answer")?.forEach((right: testQuestionAnswerRequest) => {
      if (right?.match_right_id === id) {
        backgroundColor = right.bg!; // Update the background color if a match is found
      }
    });

    return backgroundColor;
  };

  return (
    <Box sx={{ border: "solid 1px #ccc" }}>
      <Box sx={{ bgcolor: "mypurple2.main", padding: "20px" }}>
        <Typography variant="h5" color="#fff">
          {i18next.language == "en"
            ? `# ${question.order} Question`
            : `السؤال # ${question.order}`}
        </Typography>
      </Box>
      <Box sx={{ padding: "20px", bgcolor: "#FBFBFB" }}>
        <Stack width={"90%"} direction={"row"} justifyContent={"space-between"}>
          <Typography variant="h6">
            {defaultTranslation(question, "name")}
          </Typography>
          {isGraded == 1 && (
            <Typography variant="h6" color="mypurple2.main">
              {question.mark} {t("Mark")}
            </Typography>
          )}
        </Stack>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>
            {/* Render the left column */}
            {question.match_lefts.map((left) => (
              <Box
                sx={{
                  ...matchStyle,
                  backgroundColor:
                    leftItemBackgroundColors[left.id] !== undefined
                      ? leftItemBackgroundColors[left.id]
                      : selectedValue === left.id
                      ? selectedValueBg
                      : "transparent",
                }}
                key={left.id}
                onClick={() => {
                  if (
                    leftItemBackgroundColors[left.id] !== undefined &&
                    leftItemBackgroundColors[left.id] != "transparent"
                  ) {
                    handleUnselect(left.id);
                    updateLeftItemBackgroundColor(left.id, "transparent"); // Update the background color immediately
                  } else {
                    setSelectedValue(left.id);
                    const randomColor = getRandomColor();
                    setSelectedValueBg(randomColor);
                    updateLeftItemBackgroundColor(left.id, randomColor); // Update the background color immediately
                  }
                }}
              >
                <Typography>{defaultTranslation(left, "question")}</Typography>
              </Box>
            ))}
          </div>
          <div style={{ flex: 1 }}>
            {/* Render the right column */}
            {question.match_rights.map((right, index: number) => (
              <Box
                key={right.id}
                sx={{
                  ...matchStyle,
                  backgroundColor: isSelectedRight(right.id),
                }}
                onClick={() =>
                  handleMatchSelect(selectedValue, right.id, selectedValueBg)
                }
              >
                <Typography> {defaultTranslation(right, "value")}</Typography>
              </Box>
            ))}
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default MatchQuestion;
