import React from "react";
import "../../../pages/course/course-content.css";
import i18n from "i18next";
import { toast } from "react-toastify";
import { Box, Typography } from "@mui/material";
import { GrCertificate } from "react-icons/gr";
import i18next from "i18next";
import { PiCertificateLight } from "react-icons/pi";

interface Props {
  currentLesson: number;
  setCertificationsOpen: Function;
  isEnrolled: boolean;
  isSectionFree: boolean;
  setOpenedSideNav?: Function;
  certificationsOpen: boolean;
  progress: number;
}

export const CetificationItem: React.FC<Props> = (Props) => {
  const handleClick = () => {
    Props.setCertificationsOpen(true);
    if (Props.setOpenedSideNav) Props.setOpenedSideNav(false);
  };
  return (
    <li
      style={{
        width: "100%",
        marginTop: "5px",
        border: "1px solid #ddd",
        padding: "10px",
        borderRadius: "5px",
      }}
      className={`course-item ${Props.certificationsOpen ? "active" : null}`}
      onClick={(e) => {
        if (Props.progress != 100) {
          e.preventDefault();
          e.stopPropagation();
          toast.info(
            i18n.language === "en"
              ? "you should complete the course to get the certifications"
              : "يجب عليك إكمال الدورة للحصول على الشهادات"
          );
        } else {
          handleClick();
        }
      }}
    >
      <div
        style={{
          textDecoration: "none",
          color: "gray",
          fontSize: "14px",
          padding: "0 ",
          cursor: "pointer",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography color="primary.main" fontSize="x-large">
          <PiCertificateLight />
          </Typography>

          <Typography color="#000" className="item-name">
            {i18next.language == "en"
              ? "claim your certifications"
              : "احصل على شهاداتك"}
          </Typography>
        </Box>
        <div style={{ paddingLeft: "2rem" }}>
          {!Props.isEnrolled && !Props.isSectionFree ? (
            <i
              className="bx bxs-lock"
              style={{ fontSize: "20px", margin: "0 3px" }}
            ></i>
          ) : null}
          <span className="item-meta item-meta-icon video"></span>
        </div>
      </div>
    </li>
  );
};
