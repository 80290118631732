import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  handleNavigation: Function;
  tos?: boolean;
}

export default function PagesItem({ handleNavigation, tos }: Props) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = () => {
    setAnchorEl(!anchorEl);
  };
  const { t } = useTranslation();

  if (tos)
    return (
      <div className="text-black" style={{ width: "100%" }}>
        <ListItemButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={{ width: "100%" }}
        >
          <ListItemText primary={t("TOS")} />
        </ListItemButton>

        {anchorEl && (
          <List>
            <ListItem onClick={() => handleNavigation("/terms-of-service")}>
              <ListItemButton>
                <ListItemText primary={t("TOS")} />
              </ListItemButton>
            </ListItem>
            <ListItem onClick={() => handleNavigation("/privacy-and-terms")}>
              <ListItemButton>
                <ListItemText primary={t("NavHeaderPrivacy")} />
              </ListItemButton>
            </ListItem>
          </List>
        )}
      </div>
    );
  else {
    return (
      <div className="text-black" style={{ width: "100%" }}>
        <ListItemButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={{ width: "100%" }}
        >
          <ListItemText primary={t("NavHeaderContactUs")} />
        </ListItemButton>

        {anchorEl && (
          <List>
            <ListItem onClick={() => handleNavigation("/contact-us")}>
              <ListItemButton>
                <ListItemText primary={t("NavHeaderContactUs")} />
              </ListItemButton>
            </ListItem>
            <ListItem onClick={() => handleNavigation("/about")}>
              <ListItemButton>
                <ListItemText primary={t("NavHeaderAboutUs")} />
              </ListItemButton>
            </ListItem>
          </List>
        )}
      </div>
    );
  }
}
