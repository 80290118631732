import { Course } from "../../interfaces/api-interfaces";
import imaePlaceHolder from "../../assets/images/placeholder.png";
import userPlaceHolder from "../../assets/images/user-holder.png";

export const arrayFromRange = (min: number, max: number) => {
  const arr = [];

  for (let i = min; i <= max; i++) {
    arr.push(i);
  }
  return arr;
};

export const getBasicDate = (date: string) =>
  date ? new Date(date)?.toISOString()?.slice(0, 10) : "";

export const getImageFromServer = (imgUrl: string) =>
  imgUrl ? "https://backend.uh-training.com" + `${imgUrl}` : imaePlaceHolder;

export const getUserImageFromServer = (imgUrl: string) =>
  imgUrl ? "https://backend.uh-training.com" + `${imgUrl}` : userPlaceHolder;

export const getLinkFromServer = (link: string) =>
  link ? "https://backend.uh-training.com" + `${link}` : "";

export const getLessonsCount = (course: Course) => {
  let lessonsCount = 0;
  course?.sections?.map((section) =>
    section?.lessons?.map(() => lessonsCount++)
  );
  return lessonsCount;
};

export function reorderArray(array: any[]) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
  return array;
}

export function getRandomColor() {
  var letters = "BCDEF".split("");
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * letters.length)];
  }
  return color;
}

export const uid = () => {
  return (
    Math.random().toString(36).slice(2) +
    Math.random().toString(36).slice(2) +
    Math.random().toString(36).slice(2)
  );
};

export function isInt(value: number | string) {
  if (typeof value === "string") {
    return false;
  }
  if (isNaN(value)) {
    return false;
  }
  //   @ts-ignore
  var x = parseFloat(value);
  return (x | 0) === x;
}
