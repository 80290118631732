import React, { useState } from "react";
import {
  getImageFromServer,
  getLinkFromServer,
} from "./../../../assets/JS/helpers";
import { instructorIdAtom, userIdAtom } from "../../../store";
import LearnPress from "./learn-press-section";
import { useAtomValue } from "jotai";
import { Box } from "@mui/system";
import { Button, Skeleton, Typography } from "@mui/material";
// @ts-ignore
import ReactStars from "react-rating-stars-component";
import bannerBackground from "../../../assets/images/trainer-details/baner-background.jpg";
import InstructorButtons from "../profile-components/instructor-buttons";
import instructorImage from "../../../assets/images/profile/profile-page-img.png";
import ProfileStatus from "./profile-status";
import { profileResponse } from "../../../interfaces/new-api-interfaces/profile";
import { defaultTranslation } from "../../../untilites/functions";
import i18next from "i18next";
import { useQuery } from "@tanstack/react-query";
import {
  fetchBlogsInstructor,
  fetchInstructorAnsweredGlobalTests,
  fetchInstructorAnsweredTests,
  fetchInstructorCourses,
  fetchInstructorGlobalTests,
  fetchInstructorsConsultations,
} from "../../../api/endpoints-functions";
import { useTranslation } from "react-i18next";
import { MdCardMembership } from "react-icons/md";

interface Props {
  profileData: profileResponse;
  isLoading: boolean;
}

const iconStyle = { color: "#fff", fontSize: "1rem" };

const typographyStyle = {
  whiteSpace: "nowrap",
  fontSize: "0.8rem",
  color: "#fff",
};

const Profile: React.FC<Props> = ({ profileData, isLoading }) => {
  const [isCorrected, setIsCorrected] = useState(false);
  const { t } = useTranslation();

  const { data: courses, isLoading: coursesLoading } = useQuery(
    ["instructor-courses"],
    () => fetchInstructorCourses()
  );
  const { data: globalTests, isLoading: globalTestLoading } = useQuery(
    ["instructor-global-tests"],
    () => fetchInstructorGlobalTests()
  );
  const { data: testsToCorrect, isLoading: testsToCorrectLoading } = useQuery(
    ["tests-to-correct", isCorrected],
    () => fetchInstructorAnsweredTests(isCorrected)
  );

  const { data: globalTestsToCorrect } = useQuery(
    ["global-tests-to-correct", isCorrected],
    () => fetchInstructorAnsweredGlobalTests(isCorrected)
  );

  const { data: blogs } = useQuery(["instructor-blogs"], () =>
    fetchBlogsInstructor()
  );

  const [isAnswered, setIsAnswered] = useState(false);
  const { data: consultations } = useQuery(["consultations", isAnswered], () =>
    fetchInstructorsConsultations(isAnswered)
  );

  const instructorId = useAtomValue(instructorIdAtom);

  const handleDownloadMemberShip = async () => {
    if (profileData?.memberShip?.file) {
      const url = getLinkFromServer(profileData?.memberShip?.file);

      const anchorElement = document.createElement("a");
      anchorElement.href = url; // Set the href attribute to the certificate URL
      anchorElement.target = "_blank"; // Open the link in a new tab
      anchorElement.download = "MembershipCertificate.pdf"; // Set the default download filename

      // Programmatically click the anchor element to trigger the download
      anchorElement.click();
    }
  };

  const imageProfile =
    profileData.profile_image && profileData.profile_image.length > 0
      ? getImageFromServer(profileData.profile_image)
      : require("../../../assets/images/person-fallback.jpeg");

  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Box
        sx={{
          backgroundImage: { xs: "none", md: `url(${bannerBackground})` },
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          marginBottom: "20px",
        }}
      >
        <div className="container">
          <div className="row">
            <Box
              sx={{ display: "flex", flexDirection: "column" }}
              className="col-12 col-lg-7"
            >
              <Box
                sx={{
                  display: "flex",
                  gap: { xs: "20px", md: "10px" },
                  padding: "50px 0px 30px 0",
                  alignItems: { xs: "center", sm: "flex-start" },
                  flexWrap: "wrap",
                  justifyContent: { xs: "center", md: "flex-start" },
                }}
              >
                <Box
                  sx={{
                    width: { xs: "100%", sm: "45%", md: "45%" },
                    display: "flex",
                    justifyContent: { xs: "center", md: "flex-start" },
                  }}
                >
                  <div className="profile-image">
                    <img
                      src={imageProfile}
                      alt="profile"
                      style={{
                        borderRadius: "20px",
                        maxHeight: 350,
                        minHeight: 350,
                        width: 300,
                      }}
                    />
                  </div>
                </Box>
                <Box
                  className="profile-info"
                  sx={{ width: { xs: "90%", sm: "45%", md: "45%" } }}
                >
                  <Typography variant="h4">
                    {defaultTranslation(profileData, "first_name") +
                      " " +
                      defaultTranslation(profileData, "last_name")}
                  </Typography>
                  <div className="author-career">
                    {i18next.language === "ar"
                      ? profileData.instructors_translations?.ar.education
                      : profileData.instructors_translations?.en.education}
                  </div>
                  {/* <div className="d-flex align-items-center">
                    {!isLoading ? (
                      <>
                        <div className="review-star">
                          <div className="tm-star-rating">
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                justifyContent: "center",
                              }}
                            >
                              <Typography>
                                {t("CourseDetailsAverageRating") + " : "}
                              </Typography>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Box
                                  style={{
                                    direction: "ltr",
                                    transform:
                                      i18next.language === "ar"
                                        ? "scaleX(-1)"
                                        : "",
                                  }}
                                >
                                  <ReactStars
                                    size={24}
                                    isHalf={true}
                                    value={profileData.rating}
                                    edit={false}
                                    emptyIcon={<i className="far fa-star"></i>}
                                    halfIcon={
                                      <i className="fa fa-star-half-alt"></i>
                                    }
                                    fullIcon={<i className="fa fa-star"></i>}
                                    activeColor="#ffd700"
                                  />
                                </Box>
                                <div className="author-career ml-2">
                                  ({profileData.rating})
                                </div>
                              </Box>
                            </Box>
                          </div>
                        </div>
                      </>
                    ) : (
                      <Skeleton variant="rectangular" width={200} height={20} />
                    )}
                  </div> */}
                  <Typography sx={{ paddingTop: "20px", fontWeight: "600" }}>
                    {i18next.language === "ar"
                      ? profileData.instructors_translations?.ar.bio
                      : profileData.instructors_translations?.en.bio}
                  </Typography>
                  {/* <ProfileStatus {...profileData} /> */}
                  {profileData?.memberShip?.file && (
                    <Button
                      color="mygreen2"
                      variant="contained"
                      onClick={() => {
                        handleDownloadMemberShip();
                      }}
                      sx={{
                        borderRadius: "50px",
                        padding: "10px 15px",
                        marginTop: "20px",
                        gap: "5px",
                        width: { xs: "auto", sm: "auto" },
                      }}
                    >
                      <MdCardMembership style={iconStyle} />
                      <Typography sx={typographyStyle}>
                        {t("MembershipCertificate")}
                      </Typography>
                    </Button>
                  )}
                </Box>
              </Box>
              <InstructorButtons />
            </Box>
            <Box
              className="col-md-5"
              sx={{ display: { xs: "none", md: "block" } }}
            >
              <img src={instructorImage} style={{ height: "100%" }} />
            </Box>
          </div>
        </div>
      </Box>
      <LearnPress
        courses={courses!}
        isLoading={coursesLoading}
        globalTests={globalTests!}
        testsToCorrect={testsToCorrect!}
        globalTestsToCorrect={globalTestsToCorrect!}
        isShowTestCorrection={true}
        setIsCorrected={setIsCorrected}
        isCorrected={isCorrected}
        blogs={blogs!}
        consultations={consultations}
        setIsAnswered={setIsAnswered}
        isAnswered={isAnswered}
      />
    </Box>
  );
};

export default Profile;
