import { useMutation } from "@tanstack/react-query";
import { fetchApi } from "../../api/api";

// Define the custom hook
export function useSendMessage() {
  const sendMessageMutation = useMutation(
    async (data: {
      id: number;
      type: string;
      message?: string;
      file?: string[];
    }) => {
      // Create the request payload object
      const payload: { type: string; message?: string; file?: string[] } = {
        type: data.type,
      };

      // Add message to payload if it exists
      if (data.message) {
        payload.message = data.message;
      }

      // Add file to payload if it exists
      if (data.file && data.file.length > 0) {
        payload.file = data.file;
      }

      // Make the API call with the constructed payload
      return fetchApi(`/chat/chat/message/${data.id}`, "POST", payload);
    }
  );

  // Destructure and return the necessary variables and functions
  const { isLoading, isError, mutateAsync: sendMessage } = sendMessageMutation;

  return { isLoading, isError, sendMessage };
}
