import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { FreeItemResponse } from "../../../interfaces/new-api-interfaces/free-items";
import { defaultTranslation } from "../../../untilites/functions";
import { getImageFromServer } from "../../../assets/JS/helpers";
import { useTranslation } from "react-i18next";

type Props = {
  free: FreeItemResponse;
};

export default function AltFreeCard({ free }: Props) {
  const navigate = useNavigate();

  const description = defaultTranslation(free, "description");
  const webDescription = defaultTranslation(free, "web_description");
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        border: "1px solid #ccc",
        borderRadius: "20px",
        padding: "16px",
        transition: "transform 0.2s ease-in-out",
        "&:hover": {
          transform: "scale(1.05)",
        },
        cursor: "pointer",
      }}
      data-aos="flip-left"
      onClick={() => {
        navigate(
          free.itemable_type === "course"
            ? `/course-details/${free.itemable_id}`
            : free.itemable_type == "globaltest"
            ? `/quizz-details/${free.itemable_id}`
            : `/shop-item/${free.itemable_id}`
        );
      }}
    >
      <img
        src={getImageFromServer(free.image)} // Assuming there's an image URL in your FreeItemResponse
        alt={`Free ${free.itemable_type}`}
        style={{ width: "100%", borderRadius: "20px" }}
        height={"220px"}
      />
      <Box
        sx={{
          marginTop: "12px",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Typography variant="h5">{defaultTranslation(free, "name")}</Typography>
        <Typography>
          {free.itemable_type === "course"
            ? `${webDescription.slice(0, 30)}${
                webDescription.length > 30 ? "..." : ""
              }`
            : `${description.slice(0, 30)}${
                description.length > 30 ? "..." : ""
              }`}
        </Typography>
      </Box>
      <Box sx={{ paddingTop: "20px" }}>
        <Button
          variant="outlined"
          onClick={(e) => {
            e.stopPropagation();
            navigate(
              free.itemable_type === "course"
                ? `/course-grid?show=free`
                : free.itemable_type == "globaltest"
                ? `/quizzes-grid?show=free`
                : `/shop?show=free`
            );
          }}
        >
          {t("viewMore")}
        </Button>
      </Box>
      <Typography paddingTop={2} variant="subtitle1">
        {t(`${free.itemable_type}`)}
      </Typography>
    </Box>
  );
}
