import { useState } from "react";
import { Box } from "@mui/system";
import { Step, StepButton, Stepper } from "@mui/material";
import React from "react";
import EditGlobalTest from "../../components/Test/edit-test/edit-global-test";
import { useLocation, useParams } from "react-router-dom";
import TestDND from "../../components/Test/edit-test/test-dnd";
import i18next from "i18next";

const EditTestHOC = () => {
  const location = useLocation();
  const testState = location?.state;
  const steps = [
    { en: "Edit Test Information", ar: "تعديل معلومات الاختبار" },
    { en: "Edit Test Content", ar: "تعديل محتوى الاختبار" },
  ];
  const [activeStep, setActiveStep] = useState(testState ? testState : 0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const testId = useParams();
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  return (
    <Box className="container" sx={{ paddingTop: "50px" }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={index} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {i18next.language == "en" ? label.en : label.ar}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      {activeStep == 0 ? (
        <React.Fragment>
          <EditGlobalTest testId={testId.id!} />
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
          </Box>
        </React.Fragment>
      ) : activeStep == 1 ? (
        <React.Fragment>
          <TestDND id={testId.id!} testType="globaltest" />
        </React.Fragment>
      ) : null}
    </Box>
  );
};

export default EditTestHOC;
