import React, { useState } from "react";
import { Typography, Box, Tabs, Tab } from "@mui/material";
import i18next from "i18next";
import { useQuery } from "@tanstack/react-query";
import {
  fetchStudentsConsultations,
  fetchStudentsVideoConsultations,
} from "../../../api/endpoints-functions";
import TextConsultations from "./text-consultations";
import VideoConsultations from "./video-consultations";

interface Props {
  activeTab: string | null;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const Consultations: React.FC<Props> = ({ activeTab }) => {
  const [tabValue, setTabValue] = useState(0);

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const [isAnswered, setIsAnswered] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("scheduled");

  const {
    data: consultations,
    isLoading: l2,
    isFetching: f2,
  } = useQuery(
    ["consultations", isAnswered],
    () => fetchStudentsConsultations(isAnswered),
    { staleTime: 6000 }
  );

  const {
    data: Videoconsultations,
    isLoading,
    isFetching,
  } = useQuery(
    ["video-consultations", selectedStatus],
    () => fetchStudentsVideoConsultations(selectedStatus),
    { keepPreviousData: true, staleTime: 6000 }
  );

  return (
    <div
      id="consultations"
      className={`profile-content-quizzes tab-pane fade pt-5  show ${
        activeTab === "consultations" ? "active" : ""
      }`}
    >
      <div className="learn-press-subtab-content">
        <div className="tab-content">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabValue}
                onChange={handleChangeTab}
                aria-label="basic tabs example"
              >
                <Tab
                  label={
                    i18next.language == "en"
                      ? "Video Consultations"
                      : "استشارات الفيديو"
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  label={
                    i18next.language == "en"
                      ? "Text Consultations"
                      : "استشارات نصية"
                  }
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
          </Box>
          <div>
            <CustomTabPanel value={tabValue} index={0}>
              <VideoConsultations
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
                Consultations={Videoconsultations!}
                isLoading={isLoading}
                isFetching={isFetching}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <TextConsultations
                isChecked={isAnswered}
                setIsChecked={setIsAnswered}
                Consultations={consultations!}
                isLoading={l2}
                isFetching={f2}
              />
            </CustomTabPanel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Consultations;
