import { Avatar, Box, Skeleton, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { getImageFromServer } from "../../../assets/JS/helpers";
import testImage from "../../../assets/images/tests/test-img.png";
import { AiOutlineFileText } from "react-icons/ai";
import { globalTestInstructor } from "../../../interfaces/new-api-interfaces/global-test";
import { defaultTranslation } from "../../../untilites/functions";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
import { AiOutlineNumber } from "react-icons/ai";

interface Props {
  overviewImageUrl: string;
  title: string;
  lessons?: number;
  question_count: number;
  isLoading: boolean;
  children: React.ReactNode;
  instructor: globalTestInstructor;
  description: string;
  totalMark: number;
  isGraded: 0 | 1 | boolean;
}

const PageBanner: React.FC<Props> = ({
  overviewImageUrl,
  lessons,
  instructor,
  question_count,
  title,
  isLoading,
  children,
  description,
  totalMark,
  isGraded,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div
      className="page-banner-section section"
      data-bg-image={overviewImageUrl}
      style={{ paddingBottom: "100px" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: { xs: "center", lg: "space-between" },
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "40px",
                  paddingTop: "50px",
                }}
                className="col-12 col-lg-6"
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    gap: "40px",
                    flexDirection: { xs: "column", lg: "row" },
                  }}
                >
                  {!isLoading ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        flexWrap: "nowrap",
                      }}
                    >
                      <Typography variant="h4">{title}</Typography>
                      <Stack
                        direction={"row"}
                        gap={"1rem"}
                        sx={{ padding: "5px" }}
                      >
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                            fontSize: "large",
                            color: "primary.main",
                            flexWrap: "nowrap",
                          }}
                        >
                          <AiOutlineFileText fontSize="x-large" />
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "5px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "large",
                                color: "primary.main",
                              }}
                            >
                              {question_count}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "large",
                                color: "primary.main",
                              }}
                            >
                              {" "}
                              {t("Questions")}
                            </Typography>
                          </Box>
                        </Typography>
                        {isGraded == 1 && (
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              fontSize: "large",
                              color: "primary.main",
                              flexWrap: "nowrap",
                            }}
                          >
                            <AiOutlineNumber fontSize="x-large" />
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "5px",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "large",
                                  color: "primary.main",
                                }}
                              >
                                {totalMark}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "large",
                                  color: "primary.main",
                                }}
                              >
                                {" "}
                                {t("Mark")}
                              </Typography>
                            </Box>
                          </Typography>
                        )}
                      </Stack>
                    </Box>
                  ) : (
                    <Skeleton
                      width={370}
                      height={50}
                      style={{ margin: "0 auto" }}
                    />
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-end",
                    }}
                  >
                    <div
                      className="course-instructor post-author"
                      onClick={() => navigate(`/teacher/${instructor?.id}`)}
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        gap: "20px",
                        cursor: "pointer",
                      }}
                    >
                      {!isLoading ? (
                        <Avatar
                          src={
                            instructor?.users?.profile_image?.length
                              ? getImageFromServer(
                                  instructor.users.profile_image
                                )
                              : require("../../../assets/images/person-fallback.jpeg")
                                  .default
                          }
                          alt="profile"
                          sx={{ width: 120, height: 120 }}
                        />
                      ) : (
                        <Skeleton variant="circular" width={30} height={30} />
                      )}
                      {!isLoading ? (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography
                            variant="h5"
                            color="primary.main"
                            sx={{ whiteSpace: "nowrap", fontWeight: 600 }}
                          >
                            {i18next.language == "en"
                              ? "Supervised By : "
                              : "بإشراف المدرب : "}
                          </Typography>
                          {instructor?.users && (
                            <Typography
                              variant="h5"
                              sx={{
                                textAlign: "center",
                                paddingTop: { xs: "10px", sm: 0 },
                                whiteSpace: "nowrap",
                              }}
                            >
                              {defaultTranslation(
                                instructor?.users,
                                "first_name"
                              ) +
                                " " +
                                defaultTranslation(
                                  instructor?.users,
                                  "last_name"
                                )}
                            </Typography>
                          )}
                        </Box>
                      ) : (
                        <Skeleton width={80} height={20} />
                      )}
                    </div>
                  </div>
                </Box>
                <Box
                  sx={{
                    borderTop: "1px solid #ccc",
                    borderBottom: "1px solid #ccc",
                    padding: "30px 0px",
                  }}
                >
                  <Typography>{description}</Typography>
                </Box>
                <Box>{children}</Box>
              </Box>
              <Box
                className="col-12 col-lg-5"
                sx={{
                  order: { xs: 2, lg: 3 },
                  paddingTop: { xs: "20px", sm: 0 },
                  display: { xs: "none", lg: "block" },
                }}
              >
                {!isLoading ? (
                  <img
                    src={testImage}
                    style={{
                      width: "100%",
                      borderRadius: "5px",
                    }}
                  />
                ) : (
                  <Skeleton
                    width={370}
                    height={150}
                    style={{ margin: "0 auto" }}
                  />
                )}
              </Box>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageBanner;
