import { useMutation, useQuery } from "@tanstack/react-query";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { fetchApi } from "../../api/api";
import "./index.css";
import i18n from "i18next";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useForm, SubmitHandler } from "react-hook-form";
import bg from "../../assets/images/profile/profile-bg.jpg";
import { defaultTranslation, handleFormError } from "../../untilites/functions";
import { addConsultationAnswerRequest } from "../../interfaces/new-api-interfaces/consultations";
import { fetchStudentsConsultation } from "../../api/endpoints-functions";
import i18next from "i18next";
import CustomTextField from "../../components/Common/custome-text-field";

interface Props {}

const InstructorConsultationDetails: React.FC<Props> = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    data: consultation,
    isLoading: l1,
    isError: e1,
  } = useQuery(["get-consultation", id], () => fetchStudentsConsultation(id!));

  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    reset,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
    watch,
  } = useForm<addConsultationAnswerRequest>({
    defaultValues: {},
  });

  const { isLoading, mutateAsync } = useMutation(
    (data: any) => fetchApi("/consultation/consultationAnswer", "POST", data),
    {
      onError: (error: any) => {
        if (error?.response?.status === 422) {
          handleFormError(error.response, setError, toast, i18next);
        }
      },
    }
  );

  const onSubmit: SubmitHandler<addConsultationAnswerRequest> = async (
    data
  ) => {
    let modefiedData = { ...data, consultation_id: id };

    console.log(modefiedData);
    try {
      mutateAsync(modefiedData).then((response) => {
        if (response.status === 201) {
          toast.success(
            i18n.language === "en"
              ? "Consultation Answered Successfully"
              : "تم الإجابة عن الاستشارة بنجاح"
          );
          navigate("/profile");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPositionX: "right",
      }}
    >
      <div
        style={{
          direction: `${i18n.language === "en" ? "ltr" : "rtl"}`,
          padding: "5vh 0",
        }}
      >
        <Box
          className="login-form-wrapper mt-sm-50 mt-xs-50"
          sx={{
            direction: `${i18n.language === "en" ? "ltr" : "rtl"}`,
            width: { xs: "90%", md: "70%" },
            borderRadius: { xs: "10px", md: "50px" }
          }}
        >
          <form
            style={{
              direction: `${i18n.language === "en" ? "ltr" : "rtl"}`,
              position: "relative",
              paddingTop: "50px",
              gap: "2%",
            }}
            className="register-form d-flex flex-wrap justify-content-center"
            onSubmit={handleSubmit(onSubmit)}
          >
            {!l1 && !isLoading && (
              <>
                <TextField
                  sx={{
                    width: { xs: "100%", sm: "92%" },
                    marginBottom: "30px",
                  }}
                  label={t("name")}
                  placeholder={t("name")}
                  disabled
                  value={
                    defaultTranslation(consultation, "first_name") +
                    " " +
                    defaultTranslation(consultation, "last_name")
                  }
                />

                <TextField
                  multiline
                  value={consultation?.question}
                  InputProps={{ readOnly: true }}
                  label={t("consultationContent")}
                  sx={{
                    width: { xs: "100%", sm: "92%" },
                    marginBottom: "30px",
                  }}
                />

                {consultation?.is_answered ? (
                  <>
                    <Box
                      sx={{
                        width: { xs: "100%", sm: "92%" },
                        marginBottom: "10px",
                      }}
                    >
                      <Typography sx={{ textAlign: "start" }} color="green">
                        {i18next.language == "en"
                          ? "You Answered This Question : "
                          : "لقد أجبت عن هذا السؤال :"}
                      </Typography>
                    </Box>
                    <TextField
                      multiline
                      value={consultation?.consultation_answer?.answer}
                      InputProps={{
                        readOnly: true,
                      }}
                      label={t("trainerAnswer")}
                      sx={{
                        width: { xs: "100%", sm: "92%" },
                        marginBottom: "10px",
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Box
                      sx={{
                        width: { xs: "100%", sm: "92%" },
                        marginBottom: "10px",
                      }}
                    >
                      <Typography sx={{ textAlign: "start" }}>
                        {i18next.language == "en"
                          ? "Add Answer : "
                          : "أضف إجابتك :"}
                      </Typography>
                    </Box>

                    <CustomTextField
                      name="answer"
                      control={control}
                      defaultValue=""
                      multiline
                      label={t("trainerAnswer")}
                      placeholder={t("trainerAnswer")}
                      required
                      inputStyle={{
                        width: { xs: "100%", sm: "92%" },
                        marginBottom: "30px",
                      }}
                      errors={errors}
                    />
                    <Box
                      className="single-input"
                      sx={{
                        direction: `${i18n.language === "en" ? "ltr" : "rtl"}`,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: { xs: "100%", sm: "92%", lg: "30%" },
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        className="mb-2 btn-hover-secondary btn-width-100"
                        type="submit"
                      >
                        <Typography fontSize="large" color="#fff">
                          {t("submitConsultation")}
                        </Typography>
                      </Button>
                    </Box>
                  </>
                )}
              </>
            )}

            {(l1 || isLoading) && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  minHeight: "500px",
                  paddingBottom: "150px",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </form>
        </Box>
      </div>
    </Box>
  );
};

export default InstructorConsultationDetails;
