import axios, { AxiosError } from "axios";
import i18next from "i18next";

export const fetchApi = async (url: string, method?: string, data?: any) => {
  const token = localStorage.getItem("token")?.toString().slice(1, -1); //slice to remove the ""

  try {
    const headers = {
      Authorization: token ? `Bearer ${token}` : null,
      "Accept-Language": i18next.language,
      "Content-Type": "application/json",
    };

    if (data instanceof FormData) {
      headers["Content-Type"] = "multipart/form-data";
    } else {
      headers["Content-Type"] = "application/json";
    }

    const response = await axios({
      url: process.env.REACT_APP_API_ROOT + url,
      method: method || "GET",
      data,
      headers,
    });

    return response;
  } catch (error: any) {
    handleApiError(error, token!);
    throw error;
  }
};

const handleApiError = (error: AxiosError, token: string) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    const { data, status } = error.response;
    console.log("Response Error:", data);
    console.log("Response Status:", status);
    console.log("Response Headers:", error.response.headers);

    // Handle specific status codes
    if (status === 401) {
      // Handle UnAuthenticated error
      console.log("UnAuthenticated Error");
      if (token) {
        window.localStorage.clear();
        window.location.href = "/login";
      }
    } else if (status === 403) {
      // Handle Unauthorized error
      console.log("UnauthorizedError");
      throw new Error("UnauthorizedError");
    } else if (status === 404) {
      // Handle not found error
      console.log("Not Found Error");
      throw new Error("NotFoundError");
    } else if (status === 422) {
      // Handle status code 422
      console.log("Status Code 422 Error");
      return error.response; // Return the response
    } else if (status === 421) {
      return error.response;
    } else if (status === 420) {
      return error.response;
    } else if (status === 429) {
      return error.response;
    } else {
      // Handle other status codes
      console.log("Other Error");
      throw new Error("OtherError");
    }
  } else if (error.request) {
    // The request was made but no response was received
    console.log("Request Error:", error.request);
    throw new Error("NetworkError");
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("Error:", error.message);
  }
};
