import React from "react";
import { useTranslation } from "react-i18next";
// @ts-ignore
import ReactStars from "react-rating-stars-component";
import { BsStarFill } from "react-icons/bs";
import { Box, Typography } from "@mui/material";
import i18next from "i18next";

interface Props {
  rating: number;
  raters: number | undefined;
}

export const AverageRating: React.FC<Props> = (Props) => {
  const { t } = useTranslation();
  return (
    <Box
      className="average-rating"
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div className="rating-box">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            borderBottom: "1px solid #DADADA",
            paddingBottom: "10px",
          }}
        >
          <div className="average-value primary-color">{Props.rating}</div>

          <div className="review-star">
            <BsStarFill fontSize="xxx-large" color="#f6b500" />
          </div>
        </Box>
        <p className="rating-title secondary-color">
          {t("CourseDetailsAverageRating")}
        </p>
        <Box
          color="primary.main"
          sx={{ fontSize: "56px", lineHeight: "1", fontWeight: "600" }}
        >
          {Props.raters}
        </Box>
        <p className="rating-title secondary-color">{i18next.language == "en" ? "ratings" : "مراجعة"}</p>
      </div>
    </Box>
  );
};