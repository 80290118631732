import React from "react";
import { useLocation } from "react-router-dom";
import { useAtomValue, useSetAtom } from "jotai";
import LinearProgress from "@mui/material/LinearProgress";
import { showProgressAtom } from "../../store";
export const LoadingLinearProgress = () => {
  const showProgress = useAtomValue(showProgressAtom);
  const setShowProgress = useSetAtom(showProgressAtom);
  const location = useLocation();
  React.useLayoutEffect(() => {
    setShowProgress(true);
    setTimeout(() => {
      setShowProgress(false);
    }, 1000);
  }, [location, setShowProgress]);

  return (
    <LinearProgress style={{ display: showProgress ? "block" : "none" }} />
  );
};
