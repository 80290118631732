import React from "react";
interface Props {
  rating: number,
  raters: number,
  progress: number,
}
const drowStart = (rating: number) => {
  let arrayOfStars = [];
  let badRating = Math.floor(5 - rating);
  while (rating >= 1) {
    arrayOfStars.push(1);
    { rating--; }
  }
  if (rating != 0) {
    arrayOfStars.push(0.5);
  }
  while (badRating != 0) {
    arrayOfStars.push(-1);
    { badRating--; }
  }
  return arrayOfStars;
}
export const RatingItem: React.FC<Props> = (Props) => {
  const starsArray = drowStart(Props.rating);
  return (
    <div className="rating-rated-item">
      <div className="rating-point">
        <div className="tm-star-rating">
          {
            starsArray.map((star: any, idx: number) => {
              if (star === 1) return <span key={idx} className="fas fa-star"></span>;
              else if (star === 0.5) return <span key={idx} className="fas fa-star-half-alt"></span>
              else return <span key={idx} className="far fa-star"></span>;
            })
          }
        </div>
      </div>
      <div className="rating-progress">
        <div className="single-progress-bar">
          <div className="progress">
            {
              Props.progress !== 0 ?
                <div className="progress-bar" role="progressbar" style={{ width: `${Props.progress}%` }}></div>
                : null
            }
          </div>
        </div>
      </div>
      <div className="rating-count">{Props.raters}</div>
    </div>
  );
};