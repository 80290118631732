import { useState } from "react";
import { Box } from "@mui/system";
import { Button, Step, StepButton, Stepper } from "@mui/material";
import React from "react";
import CourseDND from "../../components/InstructorDashboard/DashboardCourseComponents/course-dnd";
import EditCourse from "../../components/InstructorDashboard/EditCourse/edit-course";
import { useParams } from "react-router-dom";
import EditRelatedCourseItems from "../../components/InstructorDashboard/EditCourse/edit-related-course-items";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import AddCourseCertificates from "../../components/InstructorDashboard/DashboardCourseComponents/course-certificates";
import EditCourseCertificates from "../../components/InstructorDashboard/DashboardCourseComponents/edit-course-certificates";

const EditCourseHOC = () => {
  const { t } = useTranslation();
  const steps = [
    { en: "Edit Course Information", ar: "تعديل معلومات الدورة" },
    { en: "Edit Related Items", ar: "تعديل العناصر ذات علاقة" },
    { en: "Edit Course Resources", ar: "تعديل محتوى الدورة" },
    { en: "Edit Course Certificates", ar: "تعديل شهادات الدورة" },
  ];
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };
  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const { id } = useParams();

  return (
    <>
      <Box className="container" sx={{ paddingTop: "50px" }}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={index} completed={completed[index]}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {i18next.language == "en" ? label.en : label.ar}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        {activeStep == 0 ? (
          <React.Fragment>
            <EditCourse handleNext={handleNext} courseId={id!} />
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
            </Box>
          </React.Fragment>
        ) : activeStep == 1 ? (
          <React.Fragment>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              {isStepOptional(activeStep) && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSkip}
                  sx={{ mr: 1 }}
                >
                  {t("skip")}
                </Button>
              )}
            </Box>
            <EditRelatedCourseItems
              id={parseInt(id!)}
              handleNext={handleNext}
            />
          </React.Fragment>
        ) : activeStep == 2 ? (
          <React.Fragment>
            <CourseDND id={parseInt(id!)} />
          </React.Fragment>
        ) : (
          <EditCourseCertificates id={parseInt(id!)} />
        )}
      </Box>
    </>
  );
};

export default EditCourseHOC;
