import React from "react";
import { instructorLiveConsultation } from "../../../interfaces/new-api-interfaces/consultations";
import { SetStateAction } from "jotai";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import i18next from "i18next";
import { statusKeyName } from "./live-consultation-table-item";
import { defaultTranslation } from "../../../untilites/functions";
import { useTranslation } from "react-i18next";

const PreviewConsultationModal = ({
  item,
  open,
  setOpen,
  statusColor,
}: {
  item: instructorLiveConsultation;
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  statusColor: string;
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      dir={i18next.language === "ar" ? "rtl" : "ltr"}
      fullWidth
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h5">
            {i18next.language === "ar"
              ? `الاستشارة - #${item.id}`
              : `Consultation - #${item.id}`}
          </Typography>
          <Typography color={statusColor} variant="h6">
            {i18next.language === "ar"
              ? statusKeyName[item.status].ar
              : statusKeyName[item.status].en}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ padding: "1rem" }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography fontWeight={600} component="span">
                        {i18next.language === "ar"
                          ? "اسم المستشير"
                          : "Consultant Name"}
                        :{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        {defaultTranslation(item, "first_name") +
                          " " +
                          defaultTranslation(item, "last_name")}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography fontWeight={600} component="span">
                        {t("consultationReason")}:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{item.student_reason}</Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
              {item.status === "pending" && (
                <TableRow>
                  <TableCell>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography fontWeight={600} component="span">
                          {t("studentConsultationDate")}:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{item.student_date}</Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography fontWeight={600} component="span">
                        {t("studentConsultationNotes")}:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{item.student_notes}</Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
              {item.status === "scheduled" && (
                <>
                  <TableRow>
                    <TableCell>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography fontWeight={600} component="span">
                            {i18next.language === "ar"
                              ? "سعر الاستشارة"
                              : "Consultation Price"}
                            :
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>{`${item.price?.toLocaleString()} ${t(
                            "syp"
                          )}`}</Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography fontWeight={600} component="span">
                            {t("consultationScheduledDate")}:
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>{item.instructor_date}</Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography fontWeight={600} component="span">
                            {t("consultationTime")}:
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>
                            {item.instructor_start_time} -{" "}
                            {item.instructor_end_time}
                          </Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                </>
              )}
              {item.status === "rejected by instructor" && (
                <TableRow>
                  <TableCell>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography fontWeight={600} component="span">
                          {t("rejectReason")}:{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{item.instructor_notes}</Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} variant="outlined">
          {i18next.language === "ar" ? "إغلاق" : "Close"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PreviewConsultationModal;
