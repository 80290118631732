import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  fetchGlobalTestQuestions,
  fetchSections,
  fetchTestQuestions,
} from "../../../api/endpoints-functions";
import {
  courseLessonUpdate,
  courseSection,
} from "../../../interfaces/new-api-interfaces/course";
import {
  Box,
  Button,
  CircularProgress,
  Popover,
  Typography,
} from "@mui/material";
import { defaultTranslation } from "../../../untilites/functions";
import { fetchApi } from "../../../api/api";
import { toast } from "react-toastify";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import SectionModal from "../../InstructorDashboard/DashboardCourseComponents/sectionModal";
import SectionItem from "../../InstructorDashboard/DashboardCourseComponents/section-item";
import {
  testQuestion,
  testQuestionRequest,
} from "../../../interfaces/new-api-interfaces/test";
import QuestionItem from "./question-item";
import QuestionEditModal from "./QuestionEditModal";
import { ErrorFallBackNotFound } from "../../Common/error-handling/error-404";
import QuestionDeleteModal from "./question-delete-modal";
import { useTranslation } from "react-i18next";
import TotalCurrentMarksStatus from "./total-current-marks-status";

interface Props {
  id: string;
  testType: "test" | "globaltest";
}

const TestDND: React.FC<Props> = ({ id, testType }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState<testQuestion[]>([]);
  const [saveStatus, setSaveStatus] = useState(false);

  const { isLoading, mutate } = useMutation(
    (data: any) => fetchApi(`/test/question/updateOrders`, "PUT", data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-questions", id]);
        toast.success(
          i18next.language === "en"
            ? "Test Edited successfully"
            : "تم تعديل الاختبار بنجاح"
        );
        setSaveStatus(false);
      },
      onError: (error: any) => {
        toast.error(
          i18next.language === "en"
            ? "Something went wrong, please try again later."
            : "حدث خطأ ما، يرجى المحاولة مرة أخرى لاحقًا."
        );
      },
    }
  );

  const {
    isLoading: testLoading,
    isError,
    data,
  } = useQuery(
    ["get-questions", id],
    () =>
      testType == "test"
        ? fetchTestQuestions(id)
        : fetchGlobalTestQuestions(id),
    {
      onSuccess: (data) => {
        setQuestions(data.questions!);
      },
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const handleDragEnd = (result: any) => {
    console.log(result);
    const { destination, source, draggableId } = result;
    //if no destenation
    if (!result.destination) return;

    const tempQuestions = [...questions!];
    setSaveStatus(true);

    const [movedItem] = tempQuestions!.splice(source.index, 1);
    tempQuestions!.splice(destination.index, 0, {
      ...movedItem,
      order: destination.index + 1,
    });

    const questionsWithOreder = tempQuestions.map((section, index) => ({
      ...section,
      order: index + 1,
    }));

    setQuestions(questionsWithOreder);
  };

  const handleUpdateOrder = (question: testQuestion[]) => {
    const questionsWithOreder = question.map((question, index) => ({
      id: question.id,
      order: index + 1,
    }));

    mutate({ questions: questionsWithOreder });
  };

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { isValid, errors },
    reset,
  } = useForm<testQuestionRequest>({
    mode: "onChange",
  });

  //section modal states
  const [typeState, setTypeState] = useState(false);
  const [questionMethod, setQuestionMethod] = useState<"create" | "update">(
    "create"
  );
  const [questionModalInfo, setQuestionModalInfo] = useState<testQuestion>();
  const [questionModalOpen, setQuestionModalOpen] = useState(false);
  const handleQuestionModalOpen = () => setQuestionModalOpen(true);
  const handleQuestionModalClose = () => setQuestionModalOpen(false);

  //question  Delete modal states
  const [questionDeleteModalOpen, setQuestionDeleteModalOpen] = useState(false);
  const handleQuestionDeleteModalOpen = () => setQuestionDeleteModalOpen(true);
  const handleQuestionDeleteModalClose = () =>
    setQuestionDeleteModalOpen(false);

  const handleResetForm = () => {
    const testData = {
      id: 0,
      name: "",
      name_en: "",
      options: [], // Set options to an empty array
      match: [], // Set match to an empty array
    };
    reset(testData); // This will reset the form to the testData values
  };

  const [type, setType] = useState("");
  const { t } = useTranslation();

  //popover states and methos

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handlePopClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopClose = () => {
    setAnchorEl(null);
  };
  const popOpen = Boolean(anchorEl);
  const popOverId = popOpen ? "simple-popover" : undefined;

  if (isError) return <ErrorFallBackNotFound />;
  if (testLoading || isLoading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "60vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  return (
    <>
      <QuestionEditModal
        typeState={typeState}
        setTypeState={setTypeState}
        modalInfo={questionModalInfo!}
        modalOpen={questionModalOpen}
        control={control}
        handleModalClose={handleQuestionModalClose}
        handleSubmit={handleSubmit}
        isValid={isValid}
        questionMethod={questionMethod}
        order={questions.length + 1}
        setProgress={() => {}}
        test_id={data?.id!}
        setValue={setValue}
        getValues={getValues}
        errors={errors}
        type={type}
        setType={setType}
        testType={testType!}
        is_graded={data.is_graded}
        fullMarks={data.total_mark!}
        currentMarks={data.current_total_mark!}
      />

      <QuestionDeleteModal
        questionId={questionModalInfo?.id!}
        modalOpen={questionDeleteModalOpen}
        handleModalClose={handleQuestionDeleteModalClose}
        testId={id}
        QuestionOrder={questionModalInfo?.order!}
      />

      <Box sx={{ padding: "50px 0 50px 0" }}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: data?.is_graded ? "space-between" : "flex-end",
            gap: "20px",
          }}
        >
          {data?.is_graded ? (
            <TotalCurrentMarksStatus
              fullMarks={data.total_mark!}
              currentMarks={data.current_total_mark!}
            />
          ) : (
            <></>
          )}
          <Box
            sx={{
              display: "flex",
              gap: "20px",
            }}
          >
            <Button
              variant="contained"
              aria-describedby={popOverId}
              color="myorange2"
              onClick={(e) => {
                if (data?.total_mark != data?.current_total_mark) {
                  setTypeState(true);
                  setQuestionMethod("create");
                  handleResetForm();
                  handleQuestionModalOpen();
                  setQuestionModalInfo({
                    ...questionModalInfo!,
                    id: parseInt(id),
                  });
                } else {
                  handlePopClick(e);
                }
              }}
              sx={{ marginBottom: "20px" }}
            >
              {t("addNewQuestion")}
            </Button>
            <Popover
              id={popOverId}
              open={popOpen}
              anchorEl={anchorEl}
              onClose={handlePopClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Typography sx={{ p: 2 }}>
                {i18next.language == "en"
                  ? "Total Inputed Marks Is Equal To Full Mark"
                  : "مجموع علامات الأسئلة المدخلة يساوي العلامة العظمى"}
              </Typography>
            </Popover>
            <Button
              variant="contained"
              onClick={() => handleUpdateOrder(questions)}
              sx={{ marginBottom: "20px" }}
              disabled={!saveStatus}
            >
              {t("saveOrder")}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                testType == "globaltest"
                  ? navigate(`/quizz-details/${id}`)
                  : navigate(`/profile`);
              }}
              sx={{ marginBottom: "20px" }}
              color="success"
            >
              {t("finish")}
            </Button>
          </Box>
        </Box>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId={`test`} type="droppableItem">
            {(provided) => (
              <div ref={provided.innerRef}>
                {questions
                  ?.sort((a, b) => a.order - b.order)
                  .map((question, index: number) => (
                    <QuestionItem
                      key={question.id}
                      id={`question-${question.id}`}
                      title={defaultTranslation(question, "name")}
                      index={index}
                      question_id={question.id}
                      question={question}
                      order={questions.length + 1}
                      questionModalOpen={questionModalOpen}
                      handleQuestionModalOpen={handleQuestionModalOpen}
                      handleQuestionModalClose={handleQuestionModalClose}
                      setQuestionMethod={setQuestionMethod}
                      setQuestionModalInfo={setQuestionModalInfo}
                      questionReset={reset}
                      questionModalInfo={questionModalInfo!}
                      setType={setType}
                      questionMethod={questionMethod}
                      handleQuestionDeleteModalOpen={
                        handleQuestionDeleteModalOpen
                      }
                    />
                  ))}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      {questions.length == 0 && (
        <Box sx={{ padding: 4, display: "flex", justifyContent: "center" }}>
          <Typography variant="h5">Test Have No Questions Yet</Typography>
        </Box>
      )}
    </>
  );
};

export default TestDND;
