import React, { useState } from "react";
import { Section } from "../../../interfaces/api-interfaces";
import { SidebarSection } from "../course-content-components/content-sidebar-section";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { courseSection } from "../../../interfaces/new-api-interfaces/course";
import { defaultTranslation } from "../../../untilites/functions";
import i18next from "i18next";
import { SidebarCertificationSection } from "../course-content-components/content-sidebar-certification-section";

interface Props {
  sectoins: courseSection[];
  currentLesson: number;
  setCurrentLesson: Function;
  isEnrolled: boolean;
  progress: number;
  expanded: string | false;
  handleChangeExpanded: Function;
  setOpenedSideNav?: Function;
  setCertificationsOpen: Function;
  certificationsOpen: boolean;
}

export const CourseContentSide: React.FC<Props> = (Props) => {
  const { t } = useTranslation();
  return (
    <Box role="presentation" sx={{ maxWidth: { xs: "800px", lg: "100%" } }}>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            gap: "5px",
            padding: "0px 15px",
          }}
        >
          <Typography variant="h6">{t("CourseProgress")}</Typography>
          <div
            className="progress"
            style={{
              width: Props.progress == 0 ? "10%" : `${Props.progress}%`,
            }}
          >
            <div
              className="progress-bar"
              style={{
                width: "100%",
                backgroundColor: Props.progress === 100 ? "#07bc0c" : "",
              }}
            >
              {Props.progress}%
            </div>
          </div>
        </div>
        <ul className="pt-4" style={{ paddingLeft: 0, paddingRight: 0 }}>
          {React.Children.toArray(
            Props.sectoins.map((section) => (
              <SidebarSection
                setOpenedSideNav={Props.setOpenedSideNav}
                setCurrentLesson={Props.setCurrentLesson}
                currentLesson={Props.currentLesson}
                isEnrolled={Props.isEnrolled}
                name={defaultTranslation(section, "name")}
                {...section}
                lessons={section.lessons}
                expanded={Props.expanded}
                handleChangeExpanded={Props.handleChangeExpanded}
                setCertificationsOpen={Props.setCertificationsOpen}
                certificationsOpen={Props.certificationsOpen}
              />
            ))
          )}
          <SidebarCertificationSection
            setOpenedSideNav={Props.setOpenedSideNav}
            setCertificationsOpen={Props.setCertificationsOpen}
            currentLesson={Props.currentLesson}
            progress={Props.progress}
            isEnrolled={Props.isEnrolled}
            name={i18next.language === "en" ? "Certifications" : "الشهادات"}
            expanded={Props.expanded}
            handleChangeExpanded={Props.handleChangeExpanded}
            certificationsOpen={Props.certificationsOpen}
          />
        </ul>
      </div>
    </Box>
  );
};
