import { LoadingPage } from "../../components/Common/loadingPage.component";
import DisplayCorrectedTest from "../../components/Test/display-corrected-test";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { fetchStudentCorrectedTest } from "../../api/endpoints-functions";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { defaultTranslation } from "../../untilites/functions";
import { FeedbackOutlined } from "@mui/icons-material";
import i18next from "i18next";
import { MdOutlineReplay } from "react-icons/md";
import { PiCertificateLight } from "react-icons/pi";
import { CertificationTestDialog } from "./certification-test-dialog";
import { useState } from "react";

interface Props {}
const CorrectedTestPage: React.FC<Props> = () => {
  const testId = useParams();
  const [certificatesOpen, setcertificatesOpen] = useState(false);

  const navigate = useNavigate();

  const { data, isLoading, isError } = useQuery(["get-my-solved-tests"], () =>
    fetchStudentCorrectedTest(testId.id!, "globaltest")
  );

  if (isLoading) return <LoadingPage />;
  return data && !isLoading ? (
    <>
      <CertificationTestDialog
        open={certificatesOpen}
        onClose={() => setcertificatesOpen(false)}
        testId={testId.id!}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          paddingTop: "50px",
        }}
      >
        <Typography variant="h4" color="primary.main">
          {defaultTranslation(data?.GlobalTest_name, "name")}
        </Typography>
      </Box>

      <Stack
        direction={"row"}
        gap="6px"
        justifyContent="space-between"
        className="container"
        flexWrap="wrap"
        my="1rem"
        alignItems="end"
      >
        {!!data.is_graded && (
          <Box>
            <Typography variant="h5" color="primary">
              {i18next.language === "ar" ? "العلامة الكلية: " : "Total Mark: "}
              {data.total_mark} {i18next.language === "en" ? "Marks" : "درجة"}
            </Typography>
            {!!data.is_checked ? (
              <>
                <Typography variant="h5" color="primary">
                  {i18next.language === "ar"
                    ? "العلامة المستحقة: "
                    : "Obtained Mark: "}{" "}
                  {data.mark} /{" "}
                  {i18next.language === "ar"
                    ? data.evaluations.ar.text
                    : data.evaluations.en.text}
                </Typography>
                {data.evaluations.en.text != "failed" && (
                  <Box sx={{ paddingTop: "1rem" }}>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => setcertificatesOpen(true)}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Typography color="#fff" fontSize="x-large">
                          <PiCertificateLight />
                        </Typography>

                        <Typography color="#fff" className="item-name">
                          {i18next.language == "en"
                            ? "claim your certifications"
                            : "احصل على شهاداتك"}
                        </Typography>
                      </Box>
                    </Button>
                  </Box>
                )}
              </>
            ) : (
              <Typography color="orange">
                {i18next.language === "ar" ? "بانتظار تصحيح المدرب" : "Pending"}
              </Typography>
            )}
          </Box>
        )}
        {!!data.is_checked && (
          <Box>
            <Typography
              display="flex"
              gap="0.5rem"
              variant="h6"
              color="primary"
            >
              {i18next.language === "ar"
                ? "عدد المحاولات المتبقية: "
                : "Number of remaining attempts: "}
              {data.attempts_number - data.attempts}
            </Typography>
            {data.attempts_number - data.attempts > 0 && (
              <Button
                size="small"
                startIcon={<MdOutlineReplay />}
                onClick={() => navigate(`/global-test/${testId.id}`)}
              >
                {i18next.language === "ar" ? "إعادة المحاولة" : "Try again"}
              </Button>
            )}
          </Box>
        )}
      </Stack>

      {!data?.is_graded && (
        <Box className="container">
          <Box
            sx={{
              border: "solid 1px #ccc",
              marginTop: "1rem",
              padding: "20px",
            }}
          >
            <Typography
              display={"flex"}
              gap={"0.4rem"}
              alignItems={"center"}
              variant="h5"
              color="mypurple2.main"
              marginBottom={"1rem"}
            >
              <FeedbackOutlined />
              {i18next.language == "en" ? `Instructor Notes` : `ملاحظات المدرب`}
            </Typography>
            {data.notes ? (
              <div dangerouslySetInnerHTML={{ __html: data?.notes }} />
            ) : (
              <Typography variant="h6" color="myorange.main">
                {i18next.language === "ar"
                  ? "بانتظار مراجعة المدرب"
                  : "Pending for trainer's feedback"}
              </Typography>
            )}
          </Box>
          <Typography variant="h4" marginTop={"1.5rem"}>
            {i18next.language === "ar" ? "إجاباتك:" : "Your Answers:"}
          </Typography>
        </Box>
      )}
      <DisplayCorrectedTest
        isGraded={data?.is_graded!}
        totalMark={data?.total_mark!}
        questions={data?.answers!}
      />
    </>
  ) : (
    <CircularProgress size="30" />
  );
};

export default CorrectedTestPage;
