import React, { useState, useEffect, useRef } from "react";
import { Player } from "video-react";
import "video-react/dist/video-react.css";
import "./custom-player.css";

export default ({ onVideoEnd = () => {}, ...props }) => {
  const ref = useRef<any>(null);
  const [videoState, setVideoState] = useState<any>(null);
  const [hasEnded, setHasEnded] = useState<any>(false);

  // Copying the player's state to a local component state on every change
  useEffect(() => {
    ref.current.subscribeToStateChange(setVideoState);
  }, [setVideoState]);

  // Checking if the video has ended on every state update
  useEffect(() => {
    if (videoState && videoState.ended && !hasEnded) {
      // Preventing onVideoEnd from being called multiple times
      setHasEnded(true);
      onVideoEnd();
      setHasEnded(false);
    }
  }, [videoState]);

  return <Player ref={ref} {...props} />;
};
