import React from "react";
import { useNavigate } from "react-router-dom";
import { defaultTranslation } from "../../../untilites/functions";
import { TableRow, TableCell, Typography } from "@mui/material";
import { testCorrectionResponse } from "../../../interfaces/new-api-interfaces/profile";
import i18next from "i18next";

interface Props {
  test: testCorrectionResponse;
  index: number;
  isRelatedToCourse: boolean;
  isCorrected: boolean;
}

const TestToCorrectItem: React.FC<Props> = ({
  index,
  test,
  isRelatedToCourse,
  isCorrected,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (isRelatedToCourse) {
      if (isCorrected) {
        navigate(`/course-test-list-corrected/${test.id}`);
      } else {
        navigate(`/course-test-list/${test.id}`);
      }
    } else {
      if (isCorrected) {
        navigate(`/test-list-corrected/${test.id}`);
      } else {
        navigate(`/test-list/${test.id}`);
      }
    }
  };
  return (
    <TableRow
      onClick={handleClick}
      sx={{
        cursor: "pointer",
        bgcolor: index % 2 == 0 ? "#fff" : "#F5F5F5",
      }}
    >
      <TableCell>
        <Typography>
          {!isRelatedToCourse
            ? defaultTranslation(test, "name")
            : defaultTranslation(test.lesson, "name")}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {!isRelatedToCourse
            ? defaultTranslation(test, "description")?.slice(0, 50)
            : defaultTranslation(test.lesson, "description")?.slice(0, 50)}
          {!isRelatedToCourse
            ? defaultTranslation(test, "description")?.length > 50
              ? "..."
              : ""
            : defaultTranslation(test.lesson, "description")?.length > 50
            ? "..."
            : ""}
        </Typography>
      </TableCell>
      {isRelatedToCourse && (
        <TableCell>
          <Typography>{defaultTranslation(test.course, "name")}</Typography>
        </TableCell>
      )}
      <TableCell sx={{ paddingLeft: "50px" }}>
        <Typography>{test.student_count}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{test.created_at.split("T")[0]}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default TestToCorrectItem;
