import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Avatar, Box, Checkbox, Stack, Typography } from "@mui/material";
import { PiUsersBold } from "react-icons/pi";
import { toast } from "react-toastify";
import AddGroupModal from "./add-group-modal";
import { vrcGroupResponse } from "../../../interfaces/new-api-interfaces/vcr";
import {
  getImageFromServer,
  getUserImageFromServer,
} from "../../../assets/JS/helpers";
import { defaultTranslation } from "../../../untilites/functions";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

interface Props {
  open: boolean;
  onClose: () => void;
  activeConversation: vrcGroupResponse;
}

export default function GroupInfoModal({
  open,
  onClose,
  activeConversation,
}: Props) {
  const handleClose = () => {
    onClose();
  };

  const { t } = useTranslation();

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <Stack sx={{ flexDirection: { xs: "column", sm: "row" } }} gap={2}>
            <Stack
              sx={{
                width: { xs: "100%", sm: "50%" },
              }}
              justifyContent={"center"}
              alignItems={"center"}
              flexWrap={"wrap"}
              gap={3}
            >
              <Avatar
                src={getUserImageFromServer(activeConversation.image)}
                alt={activeConversation.name}
                sx={{ width: 250, height: 250 }}
              />
              <Stack flexDirection={"row"} gap={2}>
                <Typography sx={{ fontWeight: 600 }}>
                  {activeConversation.name}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    fontWeight: 600,
                  }}
                >
                  <PiUsersBold />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      flexDirection:
                        i18next.language == "en" ? "row" : "row-reverse",
                    }}
                  >
                    <Typography sx={{ fontWeight: 600 }}>
                      {activeConversation.users.length}
                    </Typography>
                    <Typography sx={{ fontWeight: 600 }}>
                      {" "}
                      {t("Members")}{" "}
                    </Typography>
                  </Box>
                </Box>
              </Stack>
            </Stack>
            <Stack
              sx={{
                width: { xs: "100%", sm: "50%" },
                maxHeight: 400,
                overflowY: "scroll",
              }}
              className="course-scrollable-side"
            >
              {activeConversation.users.map((user) => (
                <Stack
                  key={user.id}
                  flexDirection={"row"}
                  gap={1}
                  alignItems={"center"}
                  sx={{
                    padding: 1,
                    transition: "background-color 0.2s",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                >
                  <Avatar
                    src={getUserImageFromServer(user.profile_image)}
                    alt={
                      defaultTranslation(user, "first_name") +
                      " " +
                      defaultTranslation(user, "last_name")
                    }
                  />
                  <Typography>
                    {defaultTranslation(user, "first_name") +
                      " " +
                      defaultTranslation(user, "last_name")}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("close")}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
