import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "react-chat-elements/dist/main.css";
import Pusher from "pusher-js";
// import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import "./styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Avatar,
  ConversationHeader,
  Sidebar,
  ConversationList,
  Search,
  InputToolbox,
} from "@chatscope/chat-ui-kit-react";
import { useAtomValue } from "jotai";
import { roleAtom, userIdAtom } from "../../store";
import {
  useGetGroupMessagesById,
  useGetGroups,
} from "../../hooks/vrc/grouphooks";
import i18next from "i18next";
import {
  vrcGroupMessage,
  vrcGroupResponse,
} from "../../interfaces/new-api-interfaces/vcr";
import { getUserImageFromServer } from "../../assets/JS/helpers";
import VrcInput from "./components/vrc-input";
import ConversationComponent from "./components/conversation-component";
import MessageComponent from "./components/message-component";
import MessageSkeleton from "./components/message-skeleton";
import VrcHeaderActions from "./components/vrc-header-actions";
import emptyChat from "../../assets/images/vrc/empty_chat.png";
import { useTranslation } from "react-i18next";

const pusherKey = process.env.REACT_APP_PUSHER_KEY;
Pusher.logToConsole = true;

const VirtualClassRoom: React.FC = () => {
  const { t } = useTranslation();

  //init pusher and disconnect on unmount
  const [pusher, setPusher] = useState<any>(null);
  useEffect(() => {
    const pusher = new Pusher(pusherKey!, {
      cluster: "mt1",
    });
    setPusher(pusher);

    return () => {
      pusher.disconnect();
    };
  }, []);
  //get user role and id
  const role = useAtomValue(roleAtom);
  const userId = useAtomValue(userIdAtom);

  //states for current group messages and active conversation and my groups
  const [groupMessages, setGroupMessages] = useState<vrcGroupMessage[]>([]);
  const [activeConversation, setActiveConversation] =
    useState<vrcGroupResponse>({} as vrcGroupResponse);
  const [myGroups, setMyGroups] = useState<vrcGroupResponse[]>(
    [] as vrcGroupResponse[]
  );

  //get groups
  const {
    data: groups,
    isLoading,
    isError,
    isFetching,
  } = useGetGroups((data) => {
    setMyGroups(data);
  });

  //get group messages
  const {
    data: Messages,
    refetch: refetchMessages,
    isLoading: messagesLoading,
    isFetching: messagesFetching,
  } = useGetGroupMessagesById(activeConversation.id, (data) => {
    setGroupMessages(data);
  });

  //setting active conversation logic
  const handleConversationClick = (id: number) => {
    myGroups?.forEach((conversation) => {
      if (conversation.id == id) {
        conversation.unread_message_count = 0;
      }
    });
    const activeConversationFound = myGroups?.find(
      (conversation) => conversation.id == id
    );
    if (activeConversationFound) setActiveConversation(activeConversationFound);
    refetchMessages();
  };

  //active group messages logic
  useEffect(() => {
    if (activeConversation && activeConversation.id) {
      const channel = pusher?.subscribe(`channel${activeConversation.id}`);

      // Bind to the specific channel related to activeConversation.id
      channel?.bind(`message`, (data: any) => {
        console.log("new message", data);
        setGroupMessages((prevMessages) => [...prevMessages, data]);
      });

      // Clean up subscription on unmount
      return () => {
        channel?.unbind(`message`);
        pusher?.unsubscribe(`channel${activeConversation.id}`);
      };
    }
  }, [activeConversation.id, pusher]);

  //my conversations logic

  useEffect(() => {
    if (userId) {
      const channel = pusher?.subscribe(`user${userId}`);
      // Bind to the specific channel related to userId
      channel?.bind(`UserEvents`, (data: any) => {
        console.log("new User Event", data);
        setMyGroups((prevGroups) => {
          const editedGroupIndex = prevGroups?.findIndex(
            (group) => group.id === data.room.id
          );
          if (editedGroupIndex !== -1) {
            const updatedGroups = [...prevGroups];
            updatedGroups.splice(editedGroupIndex, 1); // Remove the edited group from its current position
            updatedGroups.unshift({ ...data.room }); // Insert the edited group at the beginning of the array
            return updatedGroups;
          } else {
            return [data.room, ...prevGroups]; // Add new group to the beginning
          }
        });

        // Update active conversation with the latest value
        setActiveConversation((prevActiveConversation) => {
          if (
            prevActiveConversation &&
            prevActiveConversation.id === data.room.id
          ) {
            return data.room;
          }
          return prevActiveConversation;
        });
      });
      // Clean up subscription on unmount
      return () => {
        channel?.unbind(`UserEvents`);
        pusher?.unsubscribe(`user${userId}`);
      };
    }
  }, [userId, pusher]);

  if (isLoading) {
    return (
      <Stack justifyContent={"center"} alignItems={"center"} height={"80vh"}>
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <>
      <Box
        sx={{
          height: "90vh",
          position: "relative",
          padding: { xs: "0", sm: "40px 0" },
        }}
        className="container"
      >
        <MainContainer responsive={activeConversation?.id != undefined}>
          <Sidebar
            position="left"
            scrollable={true}
            style={{
              minWidth: activeConversation?.id == undefined ? "100%" : "",
            }}
          >
            <Search
              placeholder={t("HeaderNavSearch")}
              onChange={(value) => {
                if (groups)
                  setMyGroups(
                    groups.filter((conversation) =>
                      conversation.name
                        .toLowerCase()
                        .includes(value.toLowerCase())
                    )
                  );
              }}
              onClearClick={() => setMyGroups(groups!)}
            />
            {myGroups && myGroups.length > 0 ? (
              <>
                <ConversationList>
                  {myGroups?.map((conversation) => (
                    <ConversationComponent
                      key={conversation.id}
                      conversation={conversation}
                      handleConversationClick={handleConversationClick}
                      activeConversation={activeConversation}
                      userId={userId}
                    />
                  ))}
                </ConversationList>
              </>
            ) : !isFetching ? (
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                height={"80vh"}
              >
                <img
                  src={emptyChat}
                  alt="empty-chat"
                  width={400}
                  height={400}
                />
                <Typography>{t("EmptyClassRooms")}</Typography>
              </Stack>
            ) : null}
          </Sidebar>
          {activeConversation?.name && (
            <ChatContainer>
              <ConversationHeader>
                <ConversationHeader.Back
                  onClick={() => setActiveConversation({} as vrcGroupResponse)}
                  style={{
                    transform:
                      i18next.language === "ar" ? "rotateY(180deg)" : "none", // Conditionally apply transform based on language
                    cursor: "pointer", // Add cursor pointer for better UX
                  }}
                />
                <Avatar
                  src={getUserImageFromServer(activeConversation.image)}
                  name={activeConversation.name}
                />
                <ConversationHeader.Content
                  userName={activeConversation.name}
                  // info={activeConversation.info}
                />
                <ConversationHeader.Actions style={{ gap: 10 }}>
                  <VrcHeaderActions
                    activeConversations={activeConversation}
                    role={role}
                  />
                </ConversationHeader.Actions>
              </ConversationHeader>
              {messagesLoading || messagesFetching ? (
                <MessageList>
                  {Array.from({ length: 10 }).map((_, index) => (
                    <MessageSkeleton key={index} index={index} />
                  ))}
                </MessageList>
              ) : (
                <MessageList>
                  {groupMessages?.map((msg, index) => (
                    <MessageComponent msg={msg} userId={userId} key={index} />
                  ))}
                </MessageList>
              )}
              <InputToolbox style={{ width: "100%" }}>
                {!activeConversation.is_read_only ? (
                  <VrcInput activeConversationId={activeConversation.id} />
                ) : (
                  <Box
                    sx={{
                      textAlign: "center",
                      borderTop: "1px solid #ccc",
                      width: "100%",
                      paddingTop: 1,
                      margin: 2,
                    }}
                  >
                    <Typography>{t("AdminsOnly")}</Typography>
                  </Box>
                )}
              </InputToolbox>
            </ChatContainer>
          )}
        </MainContainer>
      </Box>
    </>
  );
};

export default VirtualClassRoom;
