import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Controller, SubmitHandler } from "react-hook-form";
import CustomAddLessonFile from "./custom-add-lesson-file";
import { AiOutlineFileText, AiOutlinePlayCircle } from "react-icons/ai";
import { HiOutlineSpeakerWave } from "react-icons/hi2";
import { LiaBookSolid } from "react-icons/lia";
import {
  courseLesson,
  courseLessonUpdate,
} from "../../../interfaces/new-api-interfaces/course";
import { toast } from "react-toastify";
import i18next, { t } from "i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchApi } from "../../../api/api";
import axios from "axios";

const inputStyle = {
  width: { xs: "100%", sm: "45%" },
  marginBottom: "30px",
};

type Props = {
  fileState: boolean;
  modalInfo?: courseLesson;
  modalOpen: boolean;
  isValid: boolean;
  control: any;
  handleModalClose: Function;
  handleSubmit: any;
  lessonMethod: "create" | "update";
  section_id?: number;
  order: number;
  setProgress: Function;
  course_id: number;
  handleaddTestModalOpen: Function;
  setTestModalId:Function;
};

function LessonModal({
  fileState,
  modalInfo,
  modalOpen,
  control,
  handleModalClose,
  handleSubmit,
  isValid,
  lessonMethod,
  section_id,
  order,
  setProgress,
  course_id,
  handleaddTestModalOpen,
  setTestModalId
}: Props) {
  const queryClient = useQueryClient();
  const apiRoot = process.env.REACT_APP_API_ROOT;

  const { isLoading, mutateAsync } = useMutation(
    (data: any) => fetchApi(`/course/lesson/${modalInfo?.id}`, "PUT", data),
    {
      onError: (error: any) => {
        toast.error(
          i18next.language === "en"
            ? "Something went wrong, please try again later."
            : "حدث خطأ ما، يرجى المحاولة مرة أخرى لاحقًا."
        );
      },
    }
  );

  const { isLoading: loadingCreate, mutateAsync: createLesson } = useMutation(
    (data: any) => fetchApi(`/course/lesson`, "POST", data),
    {
      onError: (error: any) => {
        toast.error(
          i18next.language === "en"
            ? "Something went wrong, please try again later."
            : "حدث خطأ ما، يرجى المحاولة مرة أخرى لاحقًا."
        );
      },
    }
  );

  const onSubmit: SubmitHandler<courseLessonUpdate> = async (data) => {
    if (data.description_en == "") delete data.description_en;
    if (data.name_en == "") delete data.name_en;
    try {
      if (lessonMethod == "update") {
        mutateAsync({ ...data, section_id: modalInfo?.section_id }).then(
          (response) => {
            if (response.status === 200 || response.status === 202) {
              toast.success(
                i18next.language === "en"
                  ? "Lesson Edited successfully"
                  : "تم تعديل الدرس بنجاح"
              );
              handleModalClose();
            }
            queryClient.invalidateQueries(["get-sections", course_id]);
          }
        );
      } else {
        createLesson({
          ...data,
          section_id: section_id,
          order: order,
          type: "video",
        }).then((response) => {
          if (response.status === 200 || response.status === 201) {
            toast.success(
              i18next.language === "en"
                ? "Lesson Created successfully"
                : "تم إنشاء الدرس بنجاح"
            );
            handleModalClose();
          }
          queryClient.invalidateQueries(["get-sections", course_id]);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const token = localStorage.getItem("token")?.toString().slice(1, -1);

  const { isLoading: loadingResource, mutateAsync: addResource } = useMutation(
    async (data: any) => {
      const formData = new FormData();

      formData.append("file_url", data.file_url);
      formData.append("info", data.info);
      formData.append("lesson_id", data.lesson_id);

      return axios.post(`${apiRoot}/course/lessonFile`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent: any) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress((prevProgress: any) => {
            const updatedProgress = [...prevProgress];
            const index = updatedProgress.findIndex(
              (item: any) => item.id === modalInfo?.id
            );
            if (index !== -1) {
              updatedProgress[index] = { id: modalInfo?.id, value: progress };
            } else {
              updatedProgress.push({ id: modalInfo?.id, value: progress });
            }
            return updatedProgress;
          });
        },
      });
    },
    {
      onError: (error: any) => {
        toast.error(
          i18next.language === "en"
            ? "Something went wrong, please try again later."
            : "حدث خطأ ما، يرجى المحاولة مرة أخرى لاحقًا."
        );
      },
      onSuccess: () => {
        toast.success(
          i18next.language === "en"
            ? "Resource Added successfully"
            : "تم إضافة الملف بنجاح"
        );
        queryClient.invalidateQueries(["get-sections", course_id]);
      },
    }
  );

  const { mutateAsync: updateResource } = useMutation(
    async (data: any) => {
      const formData = new FormData();

      formData.append("file_url", data.file_url);
      formData.append("info", data.info);
      formData.append("lesson_id", data.lesson_id);
      formData.append("_method", "put");

      return axios.post(
        `${apiRoot}/course/lessonFile/${modalInfo?.file[0].id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress((prevProgress: any) => {
              const updatedProgress = [...prevProgress];
              const index = updatedProgress.findIndex(
                (item: any) => item.id === modalInfo?.id
              );
              if (index !== -1) {
                updatedProgress[index] = { id: modalInfo?.id, value: progress };
              } else {
                updatedProgress.push({ id: modalInfo?.id, value: progress });
              }
              return updatedProgress;
            });
          },
        }
      );
    },
    {
      onError: (error: any) => {
        toast.error(
          i18next.language === "en"
            ? "Something went wrong, please try again later."
            : "حدث خطأ ما، يرجى المحاولة مرة أخرى لاحقًا."
        );
      },
      onSuccess: () => {
        toast.success(
          i18next.language === "en"
            ? "Resource Edited successfully"
            : "تم تعديل الملف بنجاح"
        );
        queryClient.invalidateQueries(["get-sections", course_id]);
      },
    }
  );

  return (
    <Modal
      open={modalOpen}
      onClose={() => handleModalClose()}
      sx={{
        width: "60vw",
        height: "50vh",
        position: "absolute",
        top: "20vh",
        left: "20vw",
        right: "20vw",
        direction: i18next.language == "ar" ? "rtl" : "ltr",
      }}
    >
      <Stack
        sx={{ py: 2, height: "100%", boxSizing: "border-box" }}
        direction="column"
      >
        <Paper
          sx={{
            flex: 1,
            mx: "auto",
            width: "90%",
            p: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          dir={i18next.language == "en" ? "ltr" : "rtl"}
        >
          {!isLoading && !loadingCreate && (
            <>
              {!fileState && (
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  gap="2%"
                  component="form"
                  justifyContent="flex-start"
                  onSubmit={handleSubmit(onSubmit)}
                  sx={{ width: "100%" }}
                >
                  <Typography
                    variant="h6"
                    sx={{ width: "100%", paddingBottom: "20px" }}
                  >
                    {lessonMethod == "update"
                      ? `${t("updateLesson")} #${modalInfo?.order}`
                      : t("addNewLesson")}
                  </Typography>
                  <Controller
                    control={control}
                    name="name"
                    render={({ field, fieldState: { invalid } }) => (
                      <TextField
                        sx={inputStyle}
                        label={t("nameAr")}
                        size="small"
                        error={invalid}
                        fullWidth
                        {...field}
                        required
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="name_en"
                    render={({ field, fieldState: { invalid } }) => (
                      <TextField
                        sx={inputStyle}
                        label={t("nameEn")}
                        size="small"
                        error={invalid}
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="description"
                    render={({ field, fieldState: { invalid } }) => (
                      <TextField
                        sx={inputStyle}
                        label={t("descAr")}
                        size="small"
                        error={invalid}
                        multiline
                        rows={1}
                        {...field}
                        required
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="description_en"
                    render={({ field, fieldState: { invalid } }) => (
                      <TextField
                        sx={inputStyle}
                        label={t("descEn")}
                        size="small"
                        error={invalid}
                        multiline
                        rows={1}
                        {...field}
                      />
                    )}
                  />
                  <div>
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      disabled={!isValid}
                      sx={{ padding: "10px" }}
                    >
                      <Typography fontSize="1rem" color="#fff">
                        {lessonMethod == "update"
                          ? t("updateLesson")
                          : t("createLesson")}
                      </Typography>
                    </Button>
                  </div>
                </Stack>
              )}
              {fileState && (
                <Stack
                  direction="row"
                  justifyContent="space-around"
                  sx={{ width: "100%" }}
                >
                  <CustomAddLessonFile
                    icon={<AiOutlinePlayCircle fontSize="xx-large" />}
                    type="video"
                    lessonId={modalInfo?.id!}
                    addResource={addResource}
                    updateResource={updateResource}
                    handleModalClose={handleModalClose}
                    lessonMethod={lessonMethod}
                  />
                  <CustomAddLessonFile
                    icon={<HiOutlineSpeakerWave fontSize="xx-large" />}
                    type="audio"
                    lessonId={modalInfo?.id!}
                    addResource={addResource}
                    updateResource={updateResource}
                    handleModalClose={handleModalClose}
                    lessonMethod={lessonMethod}
                  />
                  <CustomAddLessonFile
                    icon={<LiaBookSolid fontSize="xx-large" />}
                    type="asset"
                    lessonId={modalInfo?.id!}
                    addResource={addResource}
                    updateResource={updateResource}
                    handleModalClose={handleModalClose}
                    lessonMethod={lessonMethod}
                  />
                  {lessonMethod != "update" && (
                    <CustomAddLessonFile
                      icon={<AiOutlineFileText fontSize="xx-large" />}
                      type="test"
                      lessonId={modalInfo?.id!}
                      addResource={() => {}}
                      updateResource={() => {}}
                      handleModalClose={handleModalClose}
                      lessonMethod={lessonMethod}
                      handleaddTestModalOpen={handleaddTestModalOpen}
                      setTestModalId={setTestModalId}
                    />
                  )}
                </Stack>
              )}
            </>
          )}
          {(isLoading || loadingCreate) && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Paper>
      </Stack>
    </Modal>
  );
}

export default LessonModal;
