import React from "react";
import { uid } from "../../assets/JS/helpers";
import { MatchAnswer } from "./types";
import {
  studentCorrectedTestQuestion,
  testToCorrect,
  testToCorrectQuestion,
} from "../../interfaces/new-api-interfaces/test";
import { Box, Stack, Typography } from "@mui/material";
import { defaultTranslation } from "../../untilites/functions";
import { useTranslation } from "react-i18next";

const matchStyle = {
  border: "1px solid #ccc",
  padding: "20px",
  borderRadius: "20px",
  margin: "20px 20px 20px 0",
};

interface Props {
  question: studentCorrectedTestQuestion;
  isGraded: boolean | 0 | 1;
}
const DisplayMatchAnswer: React.FC<Props> = ({ question, isGraded }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ padding: "20px", bgcolor: "#FBFBFB" }}>
      <Stack width={"90%"} direction={"row"} justifyContent={"space-between"}>
        <Typography variant="h6">
          {defaultTranslation(question, "name")}
        </Typography>
        {isGraded == 1 && (
          <Typography variant="h6" color="mypurple2.main">
            {question.mark} {t("Mark")}
          </Typography>
        )}
      </Stack>
      {question.match_answers.map((question) => (
        <Stack key={question.id} direction="row">
          <Box
            sx={{
              ...matchStyle,
              borderColor:
                isGraded == 0
                  ? ""
                  : question.is_true
                  ? "mygreen.main"
                  : "myred.main",
              width: "40%",
            }}
          >
            <Typography>
              {defaultTranslation(question.match_left_id, "question")}
            </Typography>
          </Box>
          <Box
            sx={{
              ...matchStyle,
              borderColor:
                isGraded == 0
                  ? ""
                  : question.is_true
                  ? "mygreen.main"
                  : "myred.main",
              width: "40%",
            }}
          >
            <Typography>
              {defaultTranslation(question.match_right_id, "value")}
            </Typography>
          </Box>
        </Stack>
      ))}
    </Box>
  );
};

export default DisplayMatchAnswer;
