import React, { useState } from "react";
import i18n from "i18next";

import { Pagination } from "@mui/material";
import "../../../assets/CSS/profile.css";
import { GlobalTestResponse } from "../../../interfaces/new-api-interfaces/test";
import { useTranslation } from "react-i18next";
import AltQuizBox from "../../Test/alt-quiz-box";

interface Props {
  tests: GlobalTestResponse[];
  isShowDeleteBtn: boolean;
  isStudentProfile?: boolean;
  activeTab: string | null;
}

const Quizzes: React.FC<Props> = ({
  tests,
  isShowDeleteBtn,
  isStudentProfile,
  activeTab,
}) => {
  const { t } = useTranslation();
  //states to handle table pagination and sorting
  const ITEMS_PER_PAGE: number = 9;
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(ITEMS_PER_PAGE);

  const paginate = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPageNumber(page);
    setMin(ITEMS_PER_PAGE * (page - 1));
    setMax(page * ITEMS_PER_PAGE);
  };

  const colors = ["myorange", "myred", "myyellow", "mygreen", "mypurple"];

  return (
    <div
      id="quizzes"
      className={`profile-content-quizzes tab-pane fade pt-5  show ${
        activeTab === "quizzes" ? "active" : ""
      }`}
    >
      <div>
        <div className="section section-padding-bottom">
          <div className="container">
            <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30">
              {React.Children.toArray(
                tests && tests.length ? (
                  tests
                    .sort(
                      (a, b) =>
                        new Date(b.created_at).valueOf() -
                        new Date(a.created_at).valueOf()
                    )
                    .filter((_1: any, idx: number) => idx >= min && idx < max)
                    .map((test) => (
                      <div
                        className="col-12 col-md-6 col-lg-4 my-2"
                        key={test.id}
                        style={{ padding: "0 15px" }}
                      >
                        <AltQuizBox
                          isShowDeleteBtn={isShowDeleteBtn}
                          isStudentProfile={isStudentProfile}
                          {...test}
                        />
                      </div>
                    ))
                ) : (
                  <div className="row">
                    <div className="col-12">
                      <div className="learn-press-message success">
                        <i className="bx bx-info-circle"></i>
                        {i18n.language === "en"
                          ? "No Tests!"
                          : "لا يوجد اختبارات"}
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
            <>
              {tests?.length > ITEMS_PER_PAGE ? (
                <Pagination
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "40px",
                  }}
                  count={Math.ceil(tests.length / ITEMS_PER_PAGE)}
                  page={currentPageNumber}
                  onChange={paginate}
                  variant="outlined"
                  color="primary"
                  size="large"
                  dir="ltr"
                />
              ) : null}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quizzes;
