import React from "react";
import { useQuery } from "@tanstack/react-query";
import { Box, Skeleton, Typography } from "@mui/material";
import "../../../assets/CSS/homepage.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TopCardInfo from "../home-page-components/top-card-info";

import { useNavigate } from "react-router-dom";
import i18n from "i18next";
import { WhiteButton } from "../../Common/whiteButton";
import { defaultTranslation } from "../../../untilites/functions";
import { courseResponse } from "../../../interfaces/new-api-interfaces/course";
import { shopItemResponse } from "../../../interfaces/new-api-interfaces/shop-item";
import { BlogResponse } from "../../../interfaces/new-api-interfaces/blog";
import { TestResponse } from "../../../interfaces/new-api-interfaces/test";

interface Props {
  endpoint: string;
  path: string;
  type: string;
  color: string;
  endpointFn?: any;
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};

const TopColoredCard: React.FC<Props> = ({
  endpoint,
  path,
  type,
  color,
  endpointFn,
}) => {
  const { isLoading, data } = useQuery([`get-top-${endpoint}`], () =>
    endpointFn()
  );
  return (
    <Box
      className="colored-top"
      sx={{
        width: { xs: "80%", sm: "40%", lg: "20%", xl: "18%" },
        bgcolor: `${color}.main`,
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        padding: "20px 10px",
      }}
    >
      <Box sx={{ width: "100%", padding: "20px" }}>
        <Typography variant="h5">
          {type == "course"
            ? i18n.language === "en"
              ? "Top Courses"
              : "أفضل الدورات"
            : type == "blog"
            ? i18n.language === "en"
              ? "Top Blogs"
              : "أفضل المقالات"
            : type == "shop-item"
            ? i18n.language === "en"
              ? "Top Shop Items"
              : "أفضل عناصر المتجر"
            : i18n.language === "en"
            ? "Top Tests"
            : "أفضل الاختبارات"}
        </Typography>
      </Box>
      <Slider {...settings}>
        {React.Children.toArray(
          isLoading
            ? [1, 2, 3].map((el) => (
                <Skeleton
                  key={el}
                  variant="rectangular"
                  width={250}
                  height={150}
                />
              ))
            : type === "course"
            ? data?.map((item: courseResponse) => (
                <TopCardInfo
                  key={item.id}
                  title={"Top Courses"}
                  path={`/course-details/${item.id}`}
                  name={defaultTranslation(item, "name")}
                  description={defaultTranslation(item, "web_description")}
                />
              ))
            : type === "blog"
            ? data?.map((item: BlogResponse) => (
                <TopCardInfo
                  key={item.id}
                  title={"Top Blogs"}
                  path={`/blogs/${item.id}`}
                  name={defaultTranslation(item, "name")}
                  description={defaultTranslation(item, "description")}
                />
              ))
            : type === "shop-item"
            ? data?.map((item: shopItemResponse) => (
                <TopCardInfo
                  key={item.id}
                  title={"Top Shoped Items"}
                  path={`/shop-item/${item.id}`}
                  name={defaultTranslation(item, "name")}
                  description={defaultTranslation(item, "description")}
                />
              ))
            : data?.map((item: TestResponse) => (
                <TopCardInfo
                  key={item.id}
                  title={"Top Tests"}
                  path={`/quizz-details/${item.id}`}
                  name={defaultTranslation(item, "name")}
                  description={defaultTranslation(item, "description")}
                />
              ))
        )}
      </Slider>
      <Box
        sx={{
          display: "flex",
          justifyContent: i18n.language == "en" ? "flex-end" : "flex-start",
          width: "100%",
          padding: "20px",
        }}
      >
        <WhiteButton path={path} en="More" ar="المزيد" />
      </Box>
    </Box>
  );
};

export default TopColoredCard;
