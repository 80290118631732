import { useEffect, useId, useState } from "react";
import i18n from "i18next";
import { getImageFromServer } from "../../assets/JS/helpers";

interface Props {
  file?: string | undefined;
  handleChange: Function;
  fieldName: string;
  isCover?: boolean;
  classesForStyles?: string;
  fallback?: string;
  isContain?: boolean;
}
export const CustomImageInput: React.FC<Props> = ({
  file,
  handleChange,
  fieldName,
  isCover,
  classesForStyles,
  fallback,
  isContain,
}) => {
  const [localFile, setLocalFile] = useState<string>();
  useEffect(() => {
    setLocalFile(file || "");
  }, [file]);

  const id = useId();
  return (
    <div className={"d-flex justify-content-center " + classesForStyles}>
      <div
        className="innerImageDiv"
        style={{
          cursor: "pointer",
          position: "relative",
          backgroundImage: `url(${localFile}),url('https://backend.uh-training.com${localFile!}'),url('${fallback}'),url('/assets/images/placeholder.png')`,
          backgroundSize: !isContain ? "cover" : "100% 100%",
          backgroundPosition: "center",
          height: !isCover ? "200px" : "250px",
          width: !isCover ? "200px" : "100%",
          borderRadius: !isCover ? "50%" : "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <input
          accept="image/jpeg, image/png, image/gif, image/jpg"
          type="file"
          id={id}
          style={{ display: "none" }}
          onChange={(e: any) => {
            if (e.target.files[0]) {
              if (e.target.files[0].size > 5000 * 1048576) {
                alert(
                  i18n.language === "en"
                    ? "The file is too large , maximum 5GB "
                    : "حجم الملف كبير جداً, الحد الأقصى 5 غيغابايت"
                );
                return;
              }
              handleChange(e.target.files?.[0]);
              setLocalFile(URL.createObjectURL(e.target.files?.[0]));
            }
          }}
        />

        <label htmlFor={id}>
          <i
            style={{
              position: "absolute",
              top: "10%",
              cursor: "pointer",
              right: "10%",
              color: "black",
              border: "1px solid #000",
              background: "#fff",
              padding: "10px",
              margin: "10px",
              borderRadius: "50%",
            }}
            className="fas fa-pencil-alt"
          ></i>
        </label>
      </div>
    </div>
  );
};

export default CustomImageInput;
