import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { LoadingPage } from "../../components/Common/loadingPage.component";
import i18n from "i18next";
import { fetchApi } from "../../api/api";
import { getImageFromServer, getLinkFromServer } from "../../assets/JS/helpers";
import ShopButtons from "../../components/Shop/shop-buttons";
import { Box, Typography } from "@mui/material";
import { SideSection } from "../../components/Common/Side-section/side-section";
import { useTranslation } from "react-i18next";
import { BsDownload } from "react-icons/bs";
import { shopItemResponse } from "../../interfaces/new-api-interfaces/shop-item";
import { fetchShopItem } from "../../api/endpoints-functions";

interface Props {}

const ShopDetails: React.FC<Props> = () => {
  const { id } = useParams();
  const {
    data,
    isLoading,
    isError,
    refetch,
  }: UseQueryResult<shopItemResponse> = useQuery(["get-shop-item", id], () =>
    fetchShopItem(id!)
  );
  const handleDownload = (item: string) => {
    const link = document.createElement("a");
    link.href = getLinkFromServer(item);
    link.download = "";
    link.target = "_blank";
    link.click();
  };

  const { t } = useTranslation();

  if (isLoading) return <LoadingPage />;
  else if (data)
    return (
      <Box
        className="shop__item__details__container container"
        sx={{
          paddingTop: { xs: "50px", md: "100px" },
          paddingBottom: { xs: "50px", md: "100px" },
          flexWrap: "no-wrap",
          flexDirection: { xs: "column", md: "row" },
          gap: "20px",
          alignItems: { xs: "center", md: "flex-start" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: { xs: "center", md: "flex-start" },
            flexDirection: { xs: "column", sm: "row" },
            width: "100%",
            gap: { xs: "20px", md: 0 },
          }}
          className="row"
        >
          <Box className="col-11 col-md-6">
            <img
              style={{ borderRadius: "20px" }}
              src={getImageFromServer(data?.image!)}
              alt={data?.translations[i18n.language].name}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: 0,
            }}
            className=" col-11 col-md-5"
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "flex-start",
                gap: "20px",
                paddingBottom: "20px",
              }}
            >
              <Typography variant="h4">
                {data?.translations[i18n.language].name}
              </Typography>
              <Box
                sx={{
                  bgcolor: data?.price > 0 ? "mypurple.main" : "myorange.main",
                  borderRadius: "20px",
                  padding: "5px 10px",
                }}
              >
                {data?.price > 0 ? (
                  <Typography
                    fontWeight={600}
                    className="price"
                    fontSize="small"
                    color="#000"
                  >
                   {`${data?.price?.toLocaleString()} ${t("syp")}`}
                  </Typography>
                ) : (
                  <Typography
                    fontWeight={600}
                    className="price"
                    fontSize="small"
                    color="#000"
                  >
                    {t("GlobalFree")}
                  </Typography>
                )}
              </Box>
            </Box>
            <div>
              <Typography>
                {data?.translations[i18n.language].description}
              </Typography>
            </div>
            <div
              className="buttons_container"
              onClick={(e) => e.preventDefault()}
            >
              <ShopButtons
                id={data?.id}
                inCart={data?.in_cart}
                isBought={data?.is_bought}
                isFree={data?.price == 0}
                inWishlist={data?.in_wishlist}
                refetch={refetch}
                handleDownload={handleDownload}
                file={data?.file_url}
                isPhysical={data.is_physical}
              />
            </div>
            {!data.is_bought && data.preview_file && (
              <button
                className="home-action"
                onClick={() => handleDownload(data.preview_file)}
              >
                <Typography
                  sx={{
                    fontSize: "medium",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    whiteSpace: "nowrap",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  {i18n.language === "en"
                    ? "Download File Preview"
                    : "معاينة الملف"}
                  <BsDownload />
                </Typography>
              </button>
            )}
          </Box>
        </Box>
        <SideSection type={"shopItem/shopItem"} />
      </Box>
    );
  else return <></>;
};

export default ShopDetails;
