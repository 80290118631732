import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { getBasicDate, getImageFromServer } from "../../assets/JS/helpers";
import { Instructor } from "../../interfaces/api-interfaces/courses";
import { defaultTranslation, getLink } from "../../untilites/functions";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { FaRegCircleUser } from "react-icons/fa6";
import { AiOutlineFileText } from "react-icons/ai";
import { InstructorResponse } from "../../interfaces/new-api-interfaces/instructor";

export const InstructorCard: React.FC<InstructorResponse> = (Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Card
      onClick={() => navigate("/teacher/" + Props.id)}
      sx={{ boxShadow: "none", maxWidth: 300 }}
      className="col-10 col-md-6 col-lg-3 my-2"
    >
      <CardActionArea sx={{ background: "transparent" }}>
        <CardMedia
          component="img"
          sx={{
            height: "22rem",
            transition: "filter 0.7s ease-in-out",
            boxShadow: "5px 5px 5px -1px rgba(0, 0, 0, 0.3)",
          }}
          image={
            Props.profile_image && Props?.profile_image.length
              ? getImageFromServer(Props?.profile_image)
              : require("../../assets/images/person-fallback.jpeg")
          }
          // alt={Props.user.firstName + " " + Props.user.lastName}
          alt={
            defaultTranslation(Props, "first_name") +
            " " +
            defaultTranslation(Props, "last_name")
          }
        />
        <CardContent sx={{ background: "transparent" }}>
          <Typography gutterBottom variant="h6" component="div">
            {/* {Props.user.firstName + " " + Props.user.lastName} */}
            {defaultTranslation(Props, "first_name") +
              " " +
              defaultTranslation(Props, "last_name")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: "10px",
            }}
          >
            <Typography>{getBasicDate(Props.created_at)}</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: { xs: "10px", sm: "10px", xlg: "20px" },
                flexWrap: "nowrap",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Box sx={{ fontSize: "large" }}>
                  <AiOutlineFileText />
                </Box>
                <Typography sx={{ fontSize: "large", whiteSpace: "nowrap" }}>
                  {Props.course_enrollment} {t("course")}
                </Typography>
              </Box>
              {parseInt(Props.student_count) > 0 && (
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <Box sx={{ fontSize: "large" }}>
                    <FaRegCircleUser />
                  </Box>
                  <Typography sx={{ fontSize: "large", whiteSpace: "nowrap" }}>
                    {" "}
                    {Props.student_count} {t("CourseDetailsStudents")}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
