import { Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import ProfileAlbumItem from "../profile-components/profile-album-item";
import FullScreenDialog from "../../FullScreenModal";
import Album from "../../../pages/album/album";
import { AlbumResponse } from "../../../interfaces/new-api-interfaces/profile";
import { defaultTranslation } from "../../../untilites/functions";
import i18next from "i18next";

interface Props {
  albums: AlbumResponse[];
  activeTab: string | null;
}

const ProfileAlbums = ({ albums, activeTab }: Props) => {
  const [open, setOpen] = useState(false);
  const [clickedItemId, setClickedItemId] = useState<number>();
  const [selectedItem, setSelectedItem] = useState<AlbumResponse>();

  const handleDialogOpen = (id: number) => {
    setOpen(true);
    setClickedItemId(id);
    const item = albums.find((item) => item.id === id);
    setSelectedItem(item);
  };

  return (
    <div
      id="albums"
      className={`tab-pane show fade pt-5 ${
        activeTab === "albums" ? "active" : ""
      }`}
    >
      <Grid container spacing={2}>
        {albums.length > 0 ? (
          albums.map((album) => (
            <Grid key={album.id} item xs={12} sm={6} md={4}>
              <ProfileAlbumItem
                id={album.id}
                firstImageUrl={album.media[0].original_url}
                name={defaultTranslation(album, "name")}
                handleOpen={handleDialogOpen}
                mediaLength={album.media.length}
                date={album.created_at}
              />
            </Grid>
          ))
        ) : (
          <div className="row">
            <div className="col-12">
              <div className="learn-press-message success">
                <i className="bx bx-info-circle"></i>
                {i18next.language === "en" ? "No Results!" : "لا تملك البومات!"}
              </div>
            </div>
          </div>
        )}
      </Grid>
      {selectedItem && (
        <FullScreenDialog
          open={open}
          setOpen={setOpen}
          toolbarContent={
            <Stack
              direction={i18next.language === "en" ? "row" : "row-reverse"}
              justifyContent={"space-between"}
              width={"100%"}
              mx={"2rem"}
            >
              <Typography variant="h5" color="white">
                {defaultTranslation(selectedItem, "name")}
              </Typography>
              <Typography variant="h6" color="white">
                {selectedItem.created_at.split("T")[0]}
              </Typography>
            </Stack>
          }
        >
          <Album id={clickedItemId} />
        </FullScreenDialog>
      )}
    </div>
  );
};

export default ProfileAlbums;
