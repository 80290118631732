import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { CustomImageInput } from "../../helpers";
import fallback from "../../../assets/images/placeholder.png";
import { blobToBase64, handleFormError } from "../../../untilites/functions";
import { useState } from "react";
// import useCategories from "src/hooks/query/categories/useCategories";
// import { useTags } from "src/hooks/query/tags/useTags";

import AddCategories from "../../InstructorDashboard/DashboardCourseComponents/add-categories";
import AddTags from "../../InstructorDashboard/DashboardCourseComponents/add-tags";

import SectionsList from "./sections/SectionsList";
import { reorder } from "../add-blog-components/AddBlogForm";
import { DropResult } from "react-beautiful-dnd";

import { useEffect } from "react";
// import { LoadingButton } from "@mui/lab";
import { BlogResponse } from "../../../interfaces/new-api-interfaces/blog";
// import { UpdateBlog } from "src/hooks/query/blogs/UpdateBlog";
import { toast } from "react-toastify";
import { Stack } from "@mui/system";
import { fetchCategories, fetchTags } from "../../../api/endpoints-functions";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { updateBlog } from "../../../api/endpoints-functions";
import i18next from "i18next";

interface Props {
  data: BlogResponse;
  id: number;
}

export interface SectionType {
  file: any;
  type: string;
  translations: {
    [key: string]: {
      text: string;
    };
  };
  id?: any;
  blog_id?: number;
}

let index = 2;
const EditBlogForm = ({ data, id }: Props) => {
  const [localError, setLocalError] = useState(null);
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const {
    handleSubmit,
    setError,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const [textModalOpen, setTextModalOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState<SectionType>({
    file: "",
    type: "",
    translations: {
      ar: {
        text: "",
      },
      en: {
        text: "",
      },
    },
  });
  const [arabicDescription, setArabicDescription] = useState(
    selectedSection.translations.ar.text
  );
  const [englishDescription, setEnglishDescription] = useState(
    selectedSection.translations.en.text
  );

  useEffect(() => {
    setArabicDescription(selectedSection.translations.ar.text);
    setEnglishDescription(selectedSection.translations.en.text);
  }, [selectedSection]);

  const { data: tags, isLoading: tagsLoading } = useQuery(["tags"], () =>
    fetchTags()
  );
  const { data: categories, isLoading: catsLoading } = useQuery(
    ["categories"],
    () => fetchCategories()
  );

  const [sections, setSections] = useState<SectionType[]>([]);

  const update = useMutation(({ data, id }: any) => updateBlog(data, id), {
    onSuccess: () => {
      toast.success("Blog Updated succesfully");
      queryClient.invalidateQueries(["get-blog"]);
    },
    onError: (error: any) => {
      if (error?.response?.status === 422) {
        handleFormError(error.response, setError, toast, i18next);
      }
    },
  });

  useEffect(() => {
    if (data) {
      reset({
        image: data.image,
        name: data.translations.ar.name,
        name_en: data.translations.en.name,
        description: data.translations.ar.description,
        description_en: data.translations.en.description,
        categories: data.categories.map((cat) => cat.id),
        tags: data.tags.map((tag) => tag.id),
      });
      const sortedSections = data.blog_sections.sort(
        (a, b) => a.order - b.order
      );
      setSections(sortedSections);
    }
  }, [data, reset]);

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return;
    const newItems = reorder(sections, source.index, destination.index);
    setSections(newItems);
  };

  const onDelete = (deletedItem: SectionType) => {
    setSections((prevState) =>
      prevState.filter((item) => item.id !== deletedItem.id)
    );
  };

  const openTextModalHandler = (item: SectionType) => {
    setSelectedSection(item);
    setTextModalOpen(true);
  };

  const AddSectionHandler = () => {
    let arr: any = [];

    arr.push({
      id: index,
      name: `Section`,
      file: ``,
      type: ``,
      translations: {
        ar: {
          text: "",
        },
        en: {
          text: "",
        },
      },
    });
    setSections((prevState) => [...prevState, ...arr]);
    index++;
  };

  const addDescriptionHandler = (
    arDescription: string,
    enDescription: string,
    id: any
  ) => {
    const updatedItems = sections.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          translations: {
            ar: {
              text: arDescription,
            },
            en: {
              text: enDescription,
            },
          },
        };
      }
      return item;
    });
    setSections([...updatedItems]);
    setArabicDescription("");
    setEnglishDescription("");
    setTextModalOpen(false);
  };

  const addFileHandler = async (files: any, id: any) => {
    const base64File = await blobToBase64(files);
    const type = files.type.split("/")[0];
    const updatedItems = sections.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          file: base64File,
          type: type,
        };
      }
      return item;
    });
    setSections([...updatedItems]);
  };

  const onSubmit = (values: any) => {
    if (sections.length === 0) {
      toast.error("You should at least one section");
      return;
    }

    const data = {
      ...values,
      sections: [...sections],
    };

    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("name_en", data.name_en);
    formData.append("description", data.description);
    formData.append("description_en", data.description_en);
    formData.append("image", data.image);
    formData.append("is_show", "0");
    formData.append("is_primary", "0");
    data.categories.forEach((cat: any, index: string) => {
      formData.append(`categories[${index}]`, cat);
    });
    data.tags.forEach((tag: any, index: string) => {
      formData.append(`tags[${index}]`, tag);
    });
    data.sections.forEach((section: SectionType, index: string) => {
      formData.append(`sections[${index}][file]`, section.file);
      formData.append(`sections[${index}][type]`, section.type);
      formData.append(`sections[${index}][order]`, index);
      formData.append(`sections[${index}][text]`, section.translations.ar.text);
      formData.append(
        `sections[${index}][text_en]`,
        section.translations.en.text
      );
    });

    formData.append("_method", "PUT");

    update.mutateAsync({ data: formData, id: id });
  };

  return catsLoading || tagsLoading ? (
    <Stack justifyContent={"center"} alignItems={"center"}>
      <CircularProgress />
    </Stack>
  ) : (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack spacing={2}>
          <Card>
            <Typography sx={{ padding: "10px" }} variant="h4">
              {t("basicInfo")}
            </Typography>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item sm={12}>
                  <Box sx={{ maxWidth: "420px", margin: "auto" }}>
                    <Controller
                      name="image"
                      control={control}
                      rules={{ required: "Image is required" }}
                      render={({ field }) => (
                        <CustomImageInput
                          file={field.value}
                          handleChange={(e: any) => {
                            blobToBase64(e)
                              .then((value) => {
                                field.onChange(value);
                              })
                              .catch((err) => {
                                console.log(err);
                                setLocalError(err);
                              });
                          }}
                          fieldName={field.name}
                          classesForStyles="pb-5"
                          fallback={fallback}
                          //   id={field.name}
                          isCover={true}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: "Arabic Name is required!" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("nameAr")}
                        required
                        error={!!errors.name}
                        // helperText={errors.name?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="name_en"
                    control={control}
                    rules={{ required: "English Name is required!" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("nameEn")}
                        required
                        error={!!errors.name_en}
                        // helperText={errors.name_en?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="description"
                    control={control}
                    rules={{ required: "Arabic Description is required!" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("descAr")}
                        required
                        error={!!errors.description}
                        // helperText={errors.description?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="description_en"
                    control={control}
                    rules={{ required: "English Description is required!" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("descEn")}
                        required
                        error={!!errors.description_en}
                        // helperText={errors.description_en?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AddCategories
                    errors={errors}
                    categories={categories!}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AddTags errors={errors} tags={tags!} control={control} />
                </Grid>
              </Grid>
              <Button
                fullWidth
                variant="contained"
                sx={{ marginTop: "1rem" }}
                type="submit"
                disabled={update.isLoading}
              >
                {update.isLoading ? "Loading..." : t("update")}
              </Button>
            </CardContent>
          </Card>
          <Card title="Sections">
            <Typography sx={{ padding: "10px" }} variant="h4">
              {t("sections")}
            </Typography>
            <CardContent>
              <SectionsList
                AddSectionHandler={AddSectionHandler}
                onDragEnd={onDragEnd}
                dragItems={sections}
                onDelete={onDelete}
                openTextModalHandler={openTextModalHandler}
                selectedSection={selectedSection}
                textModalOpen={textModalOpen}
                setTextModalOpen={setTextModalOpen}
                addDescriptionHandler={addDescriptionHandler}
                arabicDescription={arabicDescription}
                englishDescription={englishDescription}
                setArabicDescription={setArabicDescription}
                setEnglishDescription={setEnglishDescription}
                onAddFile={addFileHandler}
              />
            </CardContent>
          </Card>
        </Stack>
      </form>
    </>
  );
};

export default EditBlogForm;
