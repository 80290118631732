import React from "react";
import { useTranslation } from "react-i18next";
import { Course } from "../../../interfaces/api-interfaces";
import { getImageFromServer } from "./../../../assets/JS/helpers";
import { Link } from "react-router-dom";
import { Box, CardMedia, Typography } from "@mui/material";
import { FaRegCircleUser } from "react-icons/fa6";
import { AiOutlineFileText } from "react-icons/ai";
import { courseResponse } from "../../../interfaces/new-api-interfaces/course";
import i18next from "i18next";
// @ts-ignore
import ReactStars from "react-rating-stars-component";

export const CourseBox: React.FC<courseResponse> = (course) => {
  const [imgLink, setImgLink] = React.useState(
    getImageFromServer(course.course_image)
  );
  const { t } = useTranslation();
  return (
    <Link
      to={`/course-details/${course.id}`}
      className="swiper-slide mb-30 "
      style={{ margin: "0px 1%" }}
    >
      <Box
        className="course box-shadow"
        sx={{ padding: { xs: "0px 10px", md: "0px 10px" } }}
      >
        <div className="thumbnail">
          {/* {+course.price === 0 ? <span className="badge">Free</span> : null} */}
          <div className="thumbnail">
            <div className="image">
              <CardMedia
                onError={(e: any) =>
                  setImgLink("/assets/images/placeholder.png")
                }
                component="img"
                image={imgLink}
                alt="green iguana"
                sx={{
                  borderRadius: "10px",
                  maxHeight: {
                    xs: "10rem",
                    sm: "10rem",
                    md: "8.5rem",
                    lg: "12rem",
                  },
                }}
                className="img-fluid"
              />
            </div>
          </div>
        </div>
        <div
          className="info"
          style={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          <Typography variant="h5">
            {course.translations[i18next.language].name}
          </Typography>
          {/* <Box sx={{ display: "flex", alignItems: "center", marginY: "-10px" }}>
            <Box
              style={{
                direction: "ltr",
                transform: i18next.language === "ar" ? "scaleX(-1)" : "",
              }}
            >
              <ReactStars
                size={24}
                isHalf={true}
                value={+course?.rating}
                edit={false}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                activeColor="#ffd700"
              />
            </Box>
            <div className="author-career ml-2">({course?.rating})</div>
          </Box> */}
          <Typography>
            {course.translations[i18next.language].web_description &&
              course.translations[i18next.language].web_description.slice(
                0,
                100
              )}
            {course.translations[i18next.language].web_description &&
            course.translations[i18next.language].web_description.length > 100
              ? "..."
              : ""}
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: { xs: "10px", sm: "10px", lg: "10px" },
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <Box sx={{ fontSize: "large" }}>
                <AiOutlineFileText />
              </Box>
              <Typography sx={{ fontSize: "1rem", whiteSpace: "nowrap" }}>
                {course.lesson_count} {t("CourseDetailsLessons")}
              </Typography>
            </Box>
            {course.enrollment_number > 0 && (
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <Box sx={{ fontSize: "large" }}>
                  <FaRegCircleUser />
                </Box>

                <Typography sx={{ fontSize: "1rem", whiteSpace: "nowrap" }}>
                  {" "}
                  {course.enrollment_number} {t("CourseDetailsStudents")}
                </Typography>
              </Box>
            )}
            <Box sx={{ textAlign: "right" }}>
              <span style={{ margin: 0 }} className="price">
                {course.price == 0
                  ? t("GlobalFree")
                  : `${course.price?.toLocaleString()} ${t("syp")}`}
              </span>
            </Box>
          </Box>
        </div>
      </Box>
    </Link>
  );
};
