import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, CircularProgress, Skeleton, Typography } from "@mui/material";
import { fetchCourseRelatedItems } from "../../../api/endpoints-functions";
import { defaultTranslation } from "../../../untilites/functions";
import { RelatedItemCard } from "../course-details-components/related-item-card";

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 900, // tablet breakpoint
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600, // mobile breakpoint
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

interface props {
  courseId: number;
}

const RelatedItems: React.FC<props> = (Props) => {
  const { isLoading, data } = useQuery(["get-related-courses"], () =>
    fetchCourseRelatedItems(Props.courseId)
  );

  const { t } = useTranslation();
  if (isLoading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          minHeight: "250px",
        }}
      >
        <CircularProgress />
      </Box>
    );
  return (
    <div className="related-courses-section section section-padding">
      {(data?.courses?.length! > 0 ||
        data?.shop_items?.length! > 0 ||
        data?.blogs?.length! > 0) && (
        <div className="container">
          <div className="section-title text-center mb-35" data-aos="fade-up">
            <Typography variant="h4" color="primary.main">
              {t("ToKnowMore")}
            </Typography>
          </div>
          <Box className="row">
            {data?.courses?.length! > 0 && (
              <div className="col-12 col-lg-4" data-aos="fade-up">
                <Typography
                  sx={{ padding: "10px 20px 10px 20px" }}
                  variant="h6"
                  color="primary.main"
                >
                  {t("NavHeaderCoursesGrid")} :
                </Typography>
                <Slider {...settings}>
                  {data?.courses?.map((course) => (
                    <div style={{ width: "100%" }} key={course.id}>
                      <>
                        {!isLoading ? (
                          <RelatedItemCard
                            id={course.id}
                            name={defaultTranslation(course, "name")}
                            description={defaultTranslation(
                              course,
                              "web_description"
                            )}
                            type="course"
                            image={course.course_image}
                            enrollment_number={course.enrollment_number}
                            lesson_count={course.lesson_count}
                            price={course.price}
                          />
                        ) : (
                          <Skeleton
                            variant="rectangular"
                            width={400}
                            height={250}
                            sx={{ mb: 2 }}
                          />
                        )}
                      </>
                    </div>
                  ))}
                </Slider>
              </div>
            )}
            {data?.shop_items?.length! > 0 && (
              <div className="col-12 col-lg-4" data-aos="fade-up">
                <Typography
                  sx={{ padding: "10px 20px 10px 20px" }}
                  variant="h6"
                  color="primary.main"
                >
                  {t("NavHeaderShop")} :
                </Typography>
                <Slider {...settings}>
                  {data?.shop_items?.map((shopItem) => (
                    <div style={{ width: "100%" }} key={shopItem.id}>
                      <>
                        {!isLoading ? (
                          <RelatedItemCard
                            id={shopItem.id}
                            name={defaultTranslation(shopItem, "name")}
                            description={defaultTranslation(
                              shopItem,
                              "description"
                            )}
                            type="shop-item"
                            image={shopItem.image}
                            price={shopItem.price}
                          />
                        ) : (
                          <Skeleton
                            variant="rectangular"
                            width={400}
                            height={250}
                            sx={{ mb: 2 }}
                          />
                        )}
                      </>
                    </div>
                  ))}
                </Slider>
              </div>
            )}
            {data?.blogs?.length! > 0 && (
              <div className="col-12 col-lg-4" data-aos="fade-up">
                <Typography
                  sx={{ padding: "10px 20px 10px 20px" }}
                  variant="h6"
                  color="primary.main"
                >
                  {t("GlobalBlogs")} :
                </Typography>
                <Slider {...settings}>
                  {data?.blogs?.map((blog) => (
                    <div style={{ width: "100%" }} key={blog.id}>
                      <>
                        {!isLoading ? (
                          <RelatedItemCard
                            name={defaultTranslation(blog, "name")}
                            description={defaultTranslation(
                              blog,
                              "description"
                            )}
                            createdAt={blog.created_at}
                            id={blog.id}
                            image={blog.image}
                            createdBy={blog.created_by}
                            type="blog"
                          />
                        ) : (
                          <Skeleton
                            variant="rectangular"
                            width={400}
                            height={250}
                            sx={{ mb: 2 }}
                          />
                        )}
                      </>
                    </div>
                  ))}
                </Slider>
              </div>
            )}
          </Box>
        </div>
      )}
    </div>
  );
};

export default RelatedItems;
