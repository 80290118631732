import { Navigate, Outlet } from 'react-router-dom'
import { useAtomValue } from 'jotai';
import { roleAtom } from '../store';

const StudentRoutes = () => {
  const role = useAtomValue(roleAtom);
  return (
    role === 'student' ? <Outlet /> : <Navigate to='/' />
  )
}

export default StudentRoutes;