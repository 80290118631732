import { Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  image: string;
  text: string;
  buttonText: string;
  link: string;
};

function EmptyTab({ image, text, buttonText, link }: Props) {
  const navigate = useNavigate();
  return (
    <div className="row" style={{ justifyContent: "center", width: "100%" }}>
      <div
        className="col-12 col-md-6"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={image} alt="courses empty Image" width={400} />
        <div
          className="learn-press-message success"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 20,
            padding: "20px 50px",
            alignItems: "center",
          }}
        >
          <Typography sx={{ display: "flex", gap: 1 }}>
            <i className="bx bx-info-circle"></i>

            {text}
          </Typography>

          <Button
            variant="contained"
            onClick={() => {
              navigate(link);
            }}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default EmptyTab;
