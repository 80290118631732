import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  FormControlLabel,
  Switch,
} from "@mui/material";
import i18next from "i18next";
import { studentTextConsultation } from "../../../interfaces/new-api-interfaces/consultations";
import ConsultationTableItem from "../profile-components/consultation-table-item";
import LoadingTable from "../../Common/loading-table";
import EmptyTab from "./empty-tab";
import emptyConsultation from "../../../assets/images/consultation/undraw_informed_decision_p2lh.png";

interface Props {
  Consultations: studentTextConsultation[];
  isChecked: boolean;
  setIsChecked: Function;
  isLoading: boolean;
  isFetching: boolean;
}

const TextConsultations: React.FC<Props> = ({
  Consultations,
  isChecked,
  setIsChecked,
  isLoading,
  isFetching,
}) => {
  const { t } = useTranslation();
  return (
    <div className="">
      <div className="learn-press-subtab-content">
        <div className="tab-content">
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "1rem",
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={isChecked}
                  onChange={(e) => setIsChecked(e.target.checked)}
                />
              }
              label={i18next.language === "ar" ? "مجاب عليها" : "answered"}
            />
          </Box>
          <div className="col-12 scroll">
            <LoadingTable
              isLoading={isLoading}
              isFetching={isFetching}
              columns={5}
            >
              {Consultations && Consultations.length > 0 ? (
                <TableContainer>
                  <Table className="lp-list-table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ borderTopLeftRadius: "20px" }}>
                          <Typography fontSize="large">
                            {t("consultationContent")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize="large">
                            {t("CourseDetailsInstructor")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize="large">
                            {t("GlobalCreatedAt")}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ borderTopRightRadius: "20px" }}>
                          <Typography fontSize="large">
                            {t("status")}
                          </Typography>
                        </TableCell>
                        {/* <TableCell className="column-delete" style={{ borderRadius: "0px 20px 0 0" }}>
                        {t("GlobalDelete")}
                      </TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Consultations.map(
                        (Consultation: studentTextConsultation) => (
                          <ConsultationTableItem
                            key={Consultation.id}
                            linkTo={"/consultation-details/" + Consultation.id}
                            {...Consultation}
                            Consultation={Consultation}
                          />
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <EmptyTab
                  image={emptyConsultation}
                  text={
                    i18n.language === "en"
                      ? "You don't have any consultations yet"
                      : "لا تملك استشارات بعد!"
                  }
                  buttonText={
                    i18next.language == "en"
                      ? "Start Consulting"
                      : "اطلب استشارة"
                  }
                  link="/consultations"
                />
              )}
            </LoadingTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextConsultations;
