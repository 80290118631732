import { Delete, Edit, ExpandMore, Upload } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  ListItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Draggable } from "react-beautiful-dnd";
// import ReactFileReader from "react-file-reader";

import DisplayFile from "../../../../helpers/display-file";
import { SectionType } from "../../AddBlogForm";
import FileReaderInput from "react-file-reader-input";
import FileUploader from "../../../../helpers/custom-file-uploader";
import { useTranslation } from "react-i18next";
import CustomUploader from "../../../../helpers/custom-uploader";

export type DraggableListItemProps = {
  item: SectionType;
  index: number;
  onDelete: any;
  onOpen: any;
  onAddFile: any;
  id: any;
};

const DraggableSection = ({
  item,
  index,
  onDelete,
  onOpen,
  onAddFile,
  id,
}: DraggableListItemProps) => {
  const { t } = useTranslation();
  return (
    <Draggable draggableId={id} index={index} key={id}>
      {(provided, snapshot) => (
        <Accordion
          sx={{
            backgroundColor: snapshot.isDragging
              ? "rgb(235,235,235)"
              : "#f5f5f5",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            marginBottom: "1rem",
          }}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography>{`${t("section")} ` + `# ${index + 1}`}</Typography>
              <Tooltip arrow title="Remove Section">
                <IconButton
                  onClick={() => onDelete(item)}
                  aria-label="delete"
                  color="error"
                  size="small"
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction={"row"} justifyContent={"end"}>
              <CustomUploader
                handleChange={(value: any) => onAddFile(value, item.id)}
                uploadIcon={<Upload sx={{ cursor: "pointer" }} />}
              />

              <Tooltip arrow title="Add Discription">
                <IconButton size="small" onClick={() => onOpen(item)}>
                  <Edit />
                </IconButton>
              </Tooltip>
            </Stack>
            <ListItem>
              <Box sx={{ width: "100%", marginTop: "1rem" }}>
                {item.type === "image" && (
                  <Box
                    sx={{
                      width: "600px",
                      margin: "auto",
                      marginBottom: "1rem",
                    }}
                  >
                    <img
                      style={{ width: "100%", objectFit: "cover" }}
                      src={item.file}
                      alt="file"
                    />
                  </Box>
                )}

                {item.type === "video" && (
                  <Box sx={{ marginBottom: "1rem" }}>
                    <DisplayFile type="video" file={item.file} />
                  </Box>
                )}
                <Stack direction={"row"} spacing={2} justifyContent={"center"}>
                  {item.text && (
                    <Box
                      sx={{
                        borderRadius: "8px",
                        border: "1px solid #aaa",
                        flexBasis: "50%",
                        padding: "1rem",
                      }}
                    >
                      <Typography>Arabic Description</Typography>
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: item.text,
                        }}
                      ></Box>
                    </Box>
                  )}
                  {item.text_en && (
                    <Box
                      sx={{
                        borderRadius: "8px",
                        border: "1px solid #aaa",
                        flexBasis: "50%",
                        padding: "1rem",
                      }}
                    >
                      <Typography>English Description</Typography>
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: item.text_en,
                        }}
                      ></Box>
                    </Box>
                  )}
                </Stack>
              </Box>
            </ListItem>
          </AccordionDetails>
        </Accordion>
      )}
    </Draggable>
  );
};

export default DraggableSection;
