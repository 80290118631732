import AddBlogForm from "../../components/Blog/add-blog-components/AddBlogForm";

const AddBlog = () => {
  return (
    <div className="section section-padding-bottom">
      <div className="container" style={{ paddingTop: "2rem" }}>
        <AddBlogForm />
      </div>
    </div>
  );
};

export default AddBlog;
