import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  handleNavigation: Function;
}

export default function ShopItem({ handleNavigation }: Props) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = () => {
    setAnchorEl(!anchorEl);
  };
  const { t } = useTranslation();
  return (
    <div className="text-black" style={{ width: "100%" }}>
      <ListItemButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ width: "100%" }}
      >
        <ListItemText primary={t("resourses")} />
      </ListItemButton>

      {anchorEl && (
        <List>
          <ListItem onClick={() => handleNavigation("/blog-grid")}>
            <ListItemButton>
              <ListItemText primary={t("HeaderNavBlog")} />
            </ListItemButton>
          </ListItem>
          <ListItem onClick={() => handleNavigation("/shop?type=1")}>
            <ListItemButton>
              <ListItemText primary={t("books")} />
            </ListItemButton>
          </ListItem>
          <ListItem
            onClick={() => handleNavigation("/shop?type=2")}
          >
            <ListItemButton>
              <ListItemText primary={t("trainingTools")} />
            </ListItemButton>
          </ListItem>
        </List>
      )}
    </div>
  );
}
