import i18next from "i18next";

export function blobToBase64(blob: Blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export const HandleStoreBlobAndBase64 = (
  e: any,
  handleChange: Function,
  setLocalError: Function,
  fieldName: string
) => {
  blobToBase64(e)
    .then((value) => {
      handleChange({ target: { value: value } }, fieldName);
    })
    .catch((err) => {
      console.log(err);
      setLocalError(err);
    });
};

export const b64toBlob = (b64Data: any, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const getLink = (link: string) => {
  const temp1 = link;
  if (temp1?.slice(0, 4) === "/med") {
    return process.env.REACT_APP_API_ROOT + link;
  }
  return link;
};

export const defaultTranslation = (value: any, property: any) => {
  if (i18next.language in value.translations)
    return value.translations[i18next.language][property];
  else {
    return value.translations["ar"][property];
  }
};

type ToastFunction = {
  error: (message: string) => void;
};

type I18n = {
  language: string;
};

export const handleFormError = (
  response: any,
  setError: Function,
  toast: ToastFunction,
  i18next: I18n
) => {
  if (response?.data?.errors) {
    const firstErrorKey = Object.keys(response.data.errors)[0];
    const firstErrorMessage = response.data.errors[firstErrorKey][0];
    // Set the form error using setError
    setError(firstErrorKey, {
      type: "manual",
      message: i18next.language === "en" ? firstErrorMessage : firstErrorMessage,
    });
  } else {
    toast.error(
      i18next.language === "en"
        ? "Something went wrong, please try again later."
        : "حدث خطأ ما، يرجى المحاولة مرة أخرى لاحقًا."
    );
  }
};