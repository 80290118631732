import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Sendrequest, onMessager } from "./firebase";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import useNotificationNavigation from "./useNotificationNavigation";
import { useLocation } from "react-router-dom";

interface notificationPayload {
  collapseKey: any;
  data: {
    notification_id: number;
    pointerable_id: number;
    pointerable_type: string;
  };

  from: string;
  messageId: string;
  notification: {
    title: string;
    body: string;
  };
}

const PushNotification = () => {
  const location = useLocation();
  const [notification, setNotification] = useState({
    title: "",
    body: "",
    notification_id: 0,
    pointerable_id: 0,
    pointerable_type: "",
  });
  const queryClient = useQueryClient();

  useEffect(() => {
    onMessager()
      //@ts-ignore
      .then((payload: notificationPayload) => {
        console.log(payload);
        queryClient.invalidateQueries(["get-notifications-number"]);
        setNotification({
          title: payload?.notification?.title,
          body: payload?.notification?.body,
          notification_id: payload?.data.notification_id,
          pointerable_id: payload?.data.pointerable_id,
          pointerable_type: payload?.data.pointerable_type,
        });
      })

      .catch((err: any) => console.log("failed: ", err));
  }, []);

  useEffect(() => {
    if (notification) {
      if (
        location.pathname !== "/virtual-classroom" ||
        notification.pointerable_type !== "chat"
      ) {
        notify();
      }
    }
  }, [notification]);

  const notify = () =>
    toast(<ToastDisplay notification={notification} />, {
      position: "top-left",
    });

  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            backgroundColor: "#3f3a64",
            borderRadius: "4px",
            minWidth: "300px",
            cursor: "pointer",
            minHeight: "100px",
            display:
              notification?.body || notification?.title ? "block" : "none",
          },
        }}
        containerStyle={{
          top: 40,
          left: 40,
          right: 40,
        }}
      />
    </>
  );
};

function ToastDisplay({ notification }: any) {
  const [read, setRead] = useState(false);
  const { notificationNavigationFunc } = useNotificationNavigation();
  const handleClick = () => {
    notificationNavigationFunc(
      notification.pointerable_type,
      notification.pointerable_id,
      notification.notification_id,
      read
    );
    setRead(true);
    toast.dismiss();
  };

  return (
    <Stack
      alignItems="flex-start"
      justifyContent="center"
      width="100%"
      onClick={handleClick}
      sx={{ cursor: "pointer" }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: "bold",
          color: "#fff",
          width: "100%",
        }}
      >
        {notification?.title}
      </Typography>

      <Typography sx={{ color: "#fff", width: "100%" }}>
        {notification?.body}
      </Typography>
    </Stack>
  );
}

export default PushNotification;
