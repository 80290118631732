import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  handleNavigation: Function;
  isLogin: boolean
}

export default function CoursesItem({ handleNavigation,isLogin  }: Props) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = () => {
    setAnchorEl(!anchorEl);
  };
  const { t } = useTranslation();

  return (
    <div
      className="text-black inline-block"
      onClick={handleClick}
      style={{ width: "100%" }}
    >
      <ListItemButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        sx={{ width: "100%" }}
      >
        <ListItemText primary={t("HeaderNavLearning")} />
      </ListItemButton>

      {anchorEl && (
        <List>
          <ListItem onClick={() => handleNavigation("/course-grid")}>
            <ListItemButton>
              <ListItemText primary={t("NavHeaderCoursesGrid")} />
            </ListItemButton>
          </ListItem>
          <ListItem onClick={() => handleNavigation("/teachers")}>
            <ListItemButton>
              <ListItemText primary={t("NavHeaderBecome")} />
            </ListItemButton>
          </ListItem>
          <ListItem onClick={() => handleNavigation("/quizzes-grid")}>
            <ListItemButton>
              <ListItemText primary={t("NavHeaderQuizzesGrid")} />
            </ListItemButton>
          </ListItem>
          {isLogin && (
          <ListItem
            key="vrc"
            onClick={() => handleNavigation("virtual-classroom")}
          >
            <ListItemButton>
              <ListItemText primary={t("NavHeaderVRC")} />
            </ListItemButton>
          </ListItem>
        )}
        </List>
      )}
    </div>
  );
}
