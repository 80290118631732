import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import i18n from "i18next";
import { CartTable } from "../../components/Cart/cart-sections/cart-table";
import { EmptyCart } from "../../components/Cart/cart-components/empty-cart";
import { PageTitle } from "../../components/Common/page-title-section.component";
import { LoadingPage } from "../../components/Common/loadingPage.component";
import { fetchApi } from "../../api/api";
import { CartItem } from "../../interfaces/api-interfaces/cart";
import { GridTitle } from "../../components/Common/grids-common/grid-title";
import { Box, Button, Typography } from "@mui/material";
import { fetchCartItems } from "../../api/endpoints-functions";

interface Props {}

const btnStyle = {
  borderRadius: "50px",
  padding: "10px 20px",
  gap: "5px",
  width: { xs: "45%", sm: "auto" },
  height: "fit-content",
};

const typographyStyle = {
  whiteSpace: "nowrap",
  fontSize: "1rem",
  coloe: "#fff",
};

const CartPage: React.FC<Props> = () => {
  const navigate = useNavigate();
  const { data, isLoading, isError, refetch, isRefetching } = useQuery(
    ["get-cart"],
    () => fetchCartItems()
  );

  const { t } = useTranslation();

  if (isLoading) return <LoadingPage />;
  return (
    <div>
      <GridTitle>{i18n.language === "en" ? "Cart" : "السلة"}</GridTitle>
      {data?.length === 0 ? (
        <EmptyCart />
      ) : (
        <div className="cart-section section section-padding-bottom">
          <div className="container faq-wrapper">
            <div className="row">
              <div className="col-12">
                <CartTable shopItems={data!} />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <Box
                    sx={{
                      bgcolor: "myorange.main",
                      padding: "30px",
                      borderRadius: "5px",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <Typography
                        fontSize="x-large"
                        fontWeight={600}
                        color="#444"
                      >
                        {t("totalAmount") + " : "}
                      </Typography>
                      <Typography
                        fontSize="x-large"
                        color="primary.main"
                        fontWeight={600}
                      >
                        {`${data?.reduce(
                          (acc: number, el: any) =>
                            acc + +el.price * el.quantity,
                          0
                        ).toLocaleString()} ${t("syp")}`}
                      </Typography>
                    </Box>
                  </Box>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => navigate("/checkout")}
                    sx={btnStyle}
                  >
                    <Typography sx={typographyStyle}>
                      {i18n.language === "en"
                        ? "Go to Checkout"
                        : "تابع الى الدفع "}
                    </Typography>
                  </Button>
                </Box>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartPage;
