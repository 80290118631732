import { Route, Routes, useParams } from "react-router-dom";
import HomePage from "../pages/home";
import WishlistPage from "../pages/cart/wishlist";
import BlogPage from "../pages/blog/blog-grid";
import ContactUsPage from "../pages/static-pages/contact-us";
import CoursePage from "../pages/course/course-grid";
import ProfilePage from "../pages/profile/instructor-profile";
import CartPage from "../pages/cart/cart";
import CheckOutPage from "../pages/cart/checkout";
import CourseContentPage from "../pages/course/course-content";
import CourseDetails from "../pages/course/course-details";
import BlogDetails from "../pages/blog/blog-details-section";
import PrivacyPolicySection from "../pages/static-pages/privacy-policy-section";
import { InstructorsGrid } from "../pages/instructors/instructors-grid";
import InstructorPage from "../pages/instructors/instructor";
import PrivateRoutes from "./private";
import PublicRoutes from "./public";
import InstructorRegisterForm from "../pages/Auth/instructor-register.";
import Step from "../pages/static-pages/step";
import StudentProfilePage from "../pages/profile/student-profile";
import AboutPage from "../pages/static-pages/about";
import StudentRoutes from "./student-route";
import InstructorRoutes from "./instructor-route";
import ShopPage from "../pages/shop/shop";
import NoFoundPage from "../pages/static-pages/404";
import ShopDetails from "../pages/shop/shop-details";
import EditCourseHOC from "../pages/course/EditCourseHOC";
import AddCourseHOC from "../pages/course/AddCourseHOC";
import CorrectedTestPage from "../pages/test/corrected-test";
import AddGlobal from "../pages/test/add-global-test";
import EditTest from "../components/Test/edit-test/edit-global-test";
import TestCorrection from "../pages/test/test-correction";
import CasePage from "../pages/cases/case-grid";
import CaseContent from "../pages/cases/case-content";
import QuizzesPage from "../pages/test/global-tests-grid";
import QuizzDetails from "../pages/test/global-test-details";
import TestList from "../pages/test/student-tests-to-correct";
import DisplayCaseAnswers from "../pages/cases/display-case-answers";
import TestPaper from "../pages/test/test-paper";
import Register from "../pages/Auth/register";
import Login from "../pages/Auth/login";
import EditProfile from "../pages/profile/profile-edit";
import ResetPassword from "../pages/profile/reset-password";
import AddGlobalTest from "../pages/test/add-global-test";
import EditGlobalTest from "../components/Test/edit-test/edit-global-test";
import EditTestHOC from "../pages/test/edit-test-hoc";
import TestDND from "../components/Test/edit-test/test-dnd";
import EditCourseQuestions from "../pages/test/edit-course-questions";
import CourseTestPaper from "../pages/test/course-test-paper";
import StudentsTestsToCorrect from "../pages/test/student-tests-to-correct";
import StudentsCourseTestsToCorrect from "../pages/test/student-course-tests-to-correct";
import CourseTestCorrection from "../pages/test/course-test-correction";
import StudentsTestsCorrectedList from "../pages/test/student-tests-corrected-list";
import StudentCourseTestsCorrectedList from "../pages/test/student-course-tests-corrected-list";
import CourseCorrectedTestPage from "../pages/test/course-corrected-test";
import AddBlog from "../pages/blog/add-blog";
import EditBlog from "../pages/blog/edit-blogs";
import Album from "../pages/album/album";
import Consultations from "../pages/consultations/consultations";
import TextConsultation from "../pages/consultations/add-text-consultation";
import InstructorConsultationDetails from "../pages/consultations/instructor-consultation-details";
import NotInstructorRoutes from "./not-instructor-route";
import AddVideoConsultation from "../pages/consultations/add-video-consultation";
import StudentTextConsultationDetails from "../pages/consultations/student-text-consultation-details";
import MobileNotifications from "../pages/notifications/mobile-notifications";
import Forget from "../pages/Auth/forget";
import VirtualClassRomm from "../pages/virtual-class-room";
import TermsOfService from "../pages/static-pages/terms-of-service";

const Router: React.FC = () => {
  return (
    <Routes>
      {/* guest routes */}
      <Route path="/" element={<HomePage />} />
      <Route path="/blog-grid" element={<BlogPage />} />
      <Route path="/blogs/:id" element={<BlogDetails />} />

      <Route path="/course-grid" element={<CoursePage />} />
      <Route path="/course-details/:id" element={<CourseDetails />} />

      <Route path="/case-grid" element={<CasePage />} />
      <Route path="/case-content/:id" element={<CaseContent />} />
      <Route path="/my-cases/:id" element={<DisplayCaseAnswers />} />

      <Route path="/shop" element={<ShopPage />} />

      <Route path="/quizzes-grid" element={<QuizzesPage />} />
      <Route path="/quizz-details/:id" element={<QuizzDetails />} />

      <Route path="/contact-us" element={<ContactUsPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/privacy-and-terms" element={<PrivacyPolicySection />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/ask-us" element={<Step />} />
      <Route
        path="/teachers"
        element={<InstructorsGrid isComponent={false} />}
      />
      <Route path="/teacher/:id" element={<InstructorPage />} />
      <Route path="/shop-item/:id" element={<ShopDetails />} />

      {/* instructor or student */}
      <Route element={<PrivateRoutes />}>
        <Route path="/virtual-classroom" element={<VirtualClassRomm />} />
        <Route path="/wishlist" element={<WishlistPage />} />
        <Route path="/checkout" element={<CheckOutPage />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/profile/edit" element={<EditProfile />} />
        <Route path="/edit/reset" element={<ResetPassword />} />
        <Route path="/notifications" element={<MobileNotifications />} />
      </Route>

      <Route element={<StudentRoutes />}>
        <Route path="/student-profile" element={<StudentProfilePage />} />

        <Route path="/global-test/:id" element={<TestPaper />} />
        <Route path="/corrected-test/:id" element={<CorrectedTestPage />} />

        <Route path="/course-content/:id" element={<CourseContentPage />} />
        <Route
          path="/consultation-details/:id"
          element={<StudentTextConsultationDetails />}
        />
      </Route>

      <Route element={<NotInstructorRoutes />}>
        <Route path="/consultations" element={<Consultations />} />
        <Route path="/add-consultation" element={<TextConsultation />} />
        <Route
          path="/add-video-consultation"
          element={<AddVideoConsultation />}
        />
      </Route>

      <Route element={<InstructorRoutes />}>
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/add-course" element={<AddCourseHOC />} />
        <Route path="/edit-course/:id" element={<EditCourseHOC />} />
        <Route path="/add-test" element={<AddGlobalTest />} />
        <Route path="/edit-test/:id" element={<EditTestHOC />} />
        <Route path="/edit-course-test/:id" element={<EditCourseQuestions />} />
        <Route path="/test-list/:id" element={<StudentsTestsToCorrect />} />
        <Route
          path="/course-test-list/:id"
          element={<StudentsCourseTestsToCorrect />}
        />
        <Route
          path="/test-list-corrected/:id"
          element={<StudentsTestsCorrectedList />}
        />
        <Route
          path="/course-test-list-corrected/:id"
          element={<StudentCourseTestsCorrectedList />}
        />
        <Route
          path="/test-correction/:studentId/:testId"
          element={<TestCorrection />}
        />
        <Route
          path="/course-test-correction/:studentId/:testId"
          element={<CourseTestCorrection />}
        />
        <Route path="/add-blog" element={<AddBlog />} />
        <Route path="/edit-blog/:id" element={<EditBlog />} />

        <Route
          path="instructor-consultation-details/:id"
          element={<InstructorConsultationDetails />}
        />
      </Route>

      <Route element={<PublicRoutes />}>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/instructor-register"
          element={<InstructorRegisterForm />}
        />
        <Route path="/forget" element={<Forget />} />
      </Route>
      <Route path="*" element={<NoFoundPage />} />
    </Routes>
  );
};

export default Router;
