import { Autocomplete, Chip, TextField } from "@mui/material";
import React from "react";
import { tagResponse } from "../../../interfaces/new-api-interfaces/tag";
import { defaultTranslation } from "../../../untilites/functions";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

type Props = {
  tags: tagResponse[];
  control: any;
  errors: any;
};

const AddTags = (props: Props) => {
  const { t } = useTranslation();
  return (
    <Controller
      name="tags"
      control={props.control}
      rules={{
        required:
          i18next.language == "en"
            ? "Tags is required!"
            : "الكلمات المفتاحية مطلوبة",
      }}
      defaultValue={[]}
      render={({ field }) => (
        <Autocomplete
          {...field}
          multiple
          id="tags-filled"
          options={props.tags.map((tag: tagResponse) => tag.id)}
          getOptionLabel={(option) => {
            const tag = props.tags.find((tag) => tag.id === option);
            return tag ? defaultTranslation(tag, "name") : option;
          }}
          freeSolo
          renderTags={(value: number[], getTagProps) =>
            value.map((option: number, index: number) => {
              const tag = props.tags.find((tag) => tag.id === option);
              return (
                <Chip
                  variant="outlined"
                  label={tag ? defaultTranslation(tag, "name") : option}
                  {...getTagProps({ index })}
                />
              );
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={t("addTags")}
              error={props.errors && !!props.errors.tags}
              helperText={
                props.errors && props.errors.tags && props.errors?.tags?.message
              }
            />
          )}
          value={Array.isArray(field.value) ? field.value : []}
          onChange={(event, data) => {
            field.onChange(data); // Update the field value with the tag IDs
          }}
        />
      )}
    />
  );
};

export default AddTags;
