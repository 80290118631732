import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  Grid,
  Pagination,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { profileCertificate } from "../../../interfaces/new-api-interfaces/certificate";
import ProfileCertificateItem from "./profile-certificate-item";
import { getUserCertifications } from "../../../api/endpoints-functions";
import { useQuery } from "@tanstack/react-query";
import { LoadingPage } from "../../Common/loadingPage.component";
import i18next from "i18next";
import EmptyTab from "./empty-tab";
import emptyCoures from "../../../assets/images/courses/undraw_Online_learning_re_qw08.png";
import emptyTests from "../../../assets/images/tests/undraw_Questions_re_1fy7.png";

interface Props {
  activeTab: string | null;
}

const ProfileCertificates: React.FC<Props> = ({ activeTab }) => {
  const [tabValue, setTabValue] = useState(0);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const navigate = useNavigate();
  const {
    isLoading,
    data: certifications,
    isFetching,
  } = useQuery(
    ["get-user-certifications", tabValue],
    () => getUserCertifications(tabValue == 0 ? "course" : "globaltest"),
    { keepPreviousData: true }
  );
  const ITEMS_PER_PAGE: number = 6;
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(ITEMS_PER_PAGE);

  const paginate = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPageNumber(page);
    setMin(ITEMS_PER_PAGE * (page - 1));
    setMax(page * ITEMS_PER_PAGE);
  };

  return (
    <div
      id="certificates"
      className={`tab-pane show fade pt-5 ${
        activeTab === "certificates" ? "active" : ""
      }`}
    >
      <Tabs
        value={tabValue}
        onChange={handleChangeTab}
        aria-label="basic tabs example"
      >
        <Tab
          label={
            i18next.language == "en"
              ? "Course Certifications"
              : "شهادات الدورات"
          }
          {...a11yProps(0)}
        />
        <Tab
          label={
            i18next.language == "en"
              ? "tests Certifications"
              : "شهادات الاختبارات"
          }
          {...a11yProps(1)}
        />
      </Tabs>
      {!isLoading && !isFetching ? (
        <>
          {certifications?.length != 0 ? (
            <Grid container spacing={3} sx={{ marginTop: "20px" }}>
              {certifications
                ?.filter((_1: any, idx: number) => idx >= min && idx < max)
                .map((certificate) => (
                  <>
                    <Grid item xs={12} md={4}>
                      <ProfileCertificateItem certificate={certificate} />
                    </Grid>
                  </>
                ))}
            </Grid>
          ) : tabValue == 0 ? (
            <EmptyTab
              image={emptyCoures}
              text={
                i18next.language === "en"
                  ? "You don't have any courses certificates yet!"
                  : "لا تملك شهادات دورات بعد!"
              }
              buttonText={
                i18next.language == "en" ? "start Learning" : "ابدأ بالتعلم"
              }
              link="/course-grid"
            />
          ) : (
            <EmptyTab
              image={emptyTests}
              text={
                i18next.language === "en"
                  ? "You don't have any tests certificates yet!"
                  : "لا تملك شهادات اختبارات بعد!"
              }
              buttonText={
                i18next.language == "en" ? "start Learning" : "ابدأ بالتعلم"
              }
              link="/quizzes-grid"
            />
          )}
          {certifications && certifications.length > ITEMS_PER_PAGE ? (
            <Pagination
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "20px",
              }}
              count={Math.ceil(certifications.length / ITEMS_PER_PAGE)}
              page={currentPageNumber}
              onChange={paginate}
              variant="outlined"
              color="primary"
              size="large"
              dir="ltr"
            />
          ) : null}
        </>
      ) : (
        <LoadingPage />
      )}
    </div>
  );
};

export default ProfileCertificates;
