import React from "react";
import { useNavigate } from "react-router-dom";
// @ts-ignore
import ReactStars from "react-rating-stars-component";
import { Instructor } from "../../../interfaces/api-interfaces/courses";
import { getImageFromServer } from "./../../../assets/JS/helpers";
import { Avatar, Box, Skeleton, Typography } from "@mui/material";
import { courseInstructor } from "../../../interfaces/new-api-interfaces/course";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import "../../../assets/CSS/course.css";
import { defaultTranslation } from "../../../untilites/functions";

interface Props {
  instructor: courseInstructor;
  isLoading: boolean;
}

export const CourseInstructor: React.FC<Props> = ({
  instructor,
  isLoading,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Box
      className="course-instructor"
      onClick={() => navigate("/teacher/" + instructor?.id)}
      sx={{ cursor: "pointer" }}
    >
      <div className="row" style={{ alignItems: "center" }}>
        <div
          className="col-md-4"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            {!isLoading ? (
              <Avatar
                src={
                  instructor?.users?.profile_image.length
                    ? getImageFromServer(instructor?.users?.profile_image)
                    : require("../../../assets/images/person-fallback.jpeg")
                        .default
                }
                alt="profile"
                sx={{ width: 120, height: 120 }}
              />
            ) : (
              <Skeleton variant="circular" width={150} height={150} />
            )}
          </div>
        </div>
        <Box
          className="col-md-8"
          sx={{
            display: "flex",
            justifyContent: { xs: "center", lg: "flex-start" },
            alignItems: "center",
          }}
        >
          <Box
            className=""
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", sm: "flex-start" },
            }}
          >
            {!isLoading ? (
              <>
                <Typography
                  variant="h5"
                  sx={{
                    textAlign: { xs: "center", sm: "start" },
                    paddingTop: { xs: "10px", sm: 0 },
                  }}
                >
                  {t("CourseDetailsInstructor") + " : "}
                </Typography>
                {instructor?.users && (
                  <Typography variant="h5">
                    {defaultTranslation(instructor?.users, "first_name") +
                      " " +
                      defaultTranslation(instructor?.users, "last_name")}
                  </Typography>
                )}
              </>
            ) : (
              <Skeleton variant="rectangular" width={200} height={30} />
            )}
            {/* <div className="d-flex align-items-center">
              {!isLoading ? (
                <>
                  <div className="review-star">
                    <div className="tm-star-rating">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          justifyContent: "center",
                        }}
                      >
                        <Typography>
                          {t("CourseDetailsAverageRating") + " : "}
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Box
                            style={{
                              direction: "ltr",
                              transform:
                                i18next.language === "ar" ? "scaleX(-1)" : "",
                            }}
                          >
                            <ReactStars
                              size={24}
                              isHalf={true}
                              value={+instructor?.rating}
                              edit={false}
                              emptyIcon={<i className="far fa-star"></i>}
                              halfIcon={<i className="fa fa-star-half-alt"></i>}
                              fullIcon={<i className="fa fa-star"></i>}
                              activeColor="#ffd700"
                            />
                          </Box>
                          <div className="author-career ml-2">
                            ({instructor?.rating})
                          </div>
                        </Box>
                      </Box>
                    </div>
                  </div>
                </>
              ) : (
                <Skeleton variant="rectangular" width={200} height={20} />
              )}
            </div> */}
          </Box>
        </Box>
      </div>
    </Box>
  );
};
