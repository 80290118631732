import React from "react";
import { useNavigate } from "react-router-dom";
import { getImageFromServer } from "./../../../assets/JS/helpers";
import { CartItem } from "../../../interfaces/api-interfaces";
import { useRemoveFromCart } from "../../../hooks/useEditCart";
import i18n from "i18next";
import {
  TableRow,
  TableCell,
  Typography,
  CircularProgress,
} from "@mui/material";
import { RiDeleteBinLine } from "react-icons/ri";
import { cartResponse } from "../../../interfaces/new-api-interfaces/cart";
import { defaultTranslation } from "../../../untilites/functions";
import { useTranslation } from "react-i18next";

const buttonContentStyle = {
  fontSize: "medium",
  display: "flex",
  alignItems: "center",
  gap: "10px",
  whiteSpace: "nowrap",
  width: "100%",
  justifyContent: "center",
};

export const CartTableItem: React.FC<cartResponse> = (Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { mutateAsync, isLoading: isLoadingRemove } = useRemoveFromCart();

  const link =
    Props.cartable_type === "shopitem"
      ? "shop-item"
      : Props.cartable_type === "course"
      ? "course-details"
      : "quizz-details";
  const handleRemoveFromCart = async () => {
    await mutateAsync({
      cartable_id: Props.cartable_id,
      quantity: 1,
      cartable_type: Props.cartable_type,
      user_id: Props?.user_id,
    });
  };
  return (
    <TableRow>
      <TableCell
        style={{ cursor: "pointer" }}
        className="pro-thumbnail"
        onClick={() => navigate(`/${link}/${Props.cartable_id}`)}
      >
        <img
          src={getImageFromServer(Props.image)}
          alt="Product"
          width="100px"
        />
      </TableCell>
      <TableCell
        style={{ cursor: "pointer" }}
        className="pro-thumbnail"
        onClick={() => navigate(`/${link}/${Props.cartable_id}`)}
      >
        <Typography>{t(`${Props.cartable_type}`)}</Typography>
      </TableCell>
      <TableCell
        style={{ cursor: "pointer" }}
        className="pro-title"
        onClick={() => navigate(`/${link}/${Props.cartable_id}`)}
      >
        <Typography>{defaultTranslation(Props, "name")}</Typography>
      </TableCell>
      <TableCell
        style={{ cursor: "pointer" }}
        className="pro-price"
        onClick={() => navigate(`/${link}/${Props.cartable_id}`)}
      >
        <Typography>
          <span>{`${Props.price?.toLocaleString()} ${t("syp")}`}</span>
        </Typography>
      </TableCell>
      <TableCell width={100} className="pro-remove">
        {isLoadingRemove ? (
          <CircularProgress sx={{ color: "#3f3a64" }} size="1.8rem" />
        ) : (
          <button
            onClick={() => handleRemoveFromCart()}
            className="home-action-danger"
            style={{ padding: "5px 15px" }}
          >
            <Typography sx={buttonContentStyle}>
              {i18n.language === "en" ? "Remove from Cart" : "إزالة من السلة"}
              <RiDeleteBinLine />
            </Typography>
          </button>
        )}
      </TableCell>
    </TableRow>
  );
};
