import i18next from "i18next";
import React from "react";

interface Props {}

const EmptyWishlist: React.FC<Props> = () => {
  return (
    <div>
      <div className="section section-padding-bottom">
        <div className="container">
          <div className="cart-empty-content">
            <span
              className="icon d-flex mx-auto"
              style={{ width: "100px", height: "100px" }}
            >
              <img
                src={require("../../../assets/images/empty-wishlist.png")}
                alt="empty cart"
                style={{ width: "100%", height: "100%" }}
              />
            </span>
            <h3 className="title">
              {i18next.language == "en"
                ? "Your wishlist is currently empty."
                : "المفضلة فارغة حالياً"}
            </h3>
            {/* <p>
              {i18next.language == "en"
                ? "You may check out all the available products and add some in the shop."
                : "تفقد المنتجات الموجودة في المتجر"}
            </p>
            <a href="/shop" className="btn btn-primary btn-hover-secondary">
              {i18next.language == "en"
                ? "Return to shop"
                : "الذهاب إلى المتجر"}
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyWishlist;
