import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import Results from "./results";
import ProfileCourses from "./courses-tap";
import Quizzes from "./quizzes";
import { LoadingPage } from "../../Common/loadingPage.component";
import ShopItems from "./shop-items";

import {
  fetchProfileCourses,
  fetchStudentAlbums,
  fetchStudentGlobalTests,
  fetchStudentShopItems,
  fetchStudentTests,
  fetchStudentsConsultations,
  getUserCertifications,
} from "../../../api/endpoints-functions";
import ProfileAlbums from "./albums";
import TextConsultations from "./text-consultations";
import Consultations from "./consultations";
import { useLocation } from "react-router-dom";
import Certificate from "./certificate";
import ProfileCertificates from "./certificate";
import { Typography } from "@mui/material";

interface Props {
  id: number;
}

const tabTextStyle = {
  fontSize: "18px",
  fontWeight: 700,
  cursor: "pointer",
  color: "inherit",
};

const LearnPress: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);

  const {
    data: myCourses,
    isLoading,
    isError,
  } = useQuery(["get-my-result"], () => fetchProfileCourses());

  const {
    data: myShopItems,
    isLoading: itemsLoading,
    isFetching: itemsFetching,
  } = useQuery(["student-shop-items"], () => fetchStudentShopItems());

  const { data: globalTests } = useQuery(["student-global-tests"], () =>
    fetchStudentGlobalTests()
  );
  const {
    data: results,
    isLoading: loadingResults,
    isFetching: fetchingResults,
  } = useQuery(["student-tests", isChecked], () =>
    fetchStudentTests(isChecked)
  );

  const { data: albums } = useQuery(["albums"], () => fetchStudentAlbums());

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const activeTab = params.get("activeTab");
  const defaultActiveTab = activeTab || "courses";

  return (
    <div className="learn-press-profile-section section section-padding-bottom">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="learn-press-profile-nav">
              <ul
                className="nav scrollable"
                style={{
                  flexWrap: "nowrap",
                  whiteSpace: "nowrap",
                  padding: "0px",
                }}
              >
                <li>
                  <a
                    className={defaultActiveTab === "courses" ? "active" : ""}
                    data-bs-toggle="tab"
                    href="#courses"
                  >
                    <Typography sx={tabTextStyle}>
                      {" "}
                      {t("FootelListCourses")}
                    </Typography>
                  </a>
                </li>
                {globalTests?.length != 0 && (
                  <li>
                    <a
                      className={activeTab === "quizzes" ? "active" : ""}
                      data-bs-toggle="tab"
                      href="#quizzes"
                    >
                      <Typography sx={tabTextStyle}>
                        {t("GlobalQuizzes")}
                      </Typography>
                    </a>
                  </li>
                )}

                <li>
                  <a
                    className={activeTab === "results" ? "active" : ""}
                    data-bs-toggle="tab"
                    href="#results"
                  >
                    <Typography sx={tabTextStyle}>
                      {t("GlobalResultsTests")}
                    </Typography>
                  </a>
                </li>

                {myShopItems?.length != 0 && (
                  <li>
                    <a
                      className={activeTab === "shopItems" ? "active" : ""}
                      data-bs-toggle="tab"
                      href="#shopItems"
                    >
                      <Typography sx={tabTextStyle}>
                        {t("GlobalShopItems")}
                      </Typography>
                    </a>
                  </li>
                )}
                <li>
                  <a
                    className={activeTab === "consultations" ? "active" : ""}
                    data-bs-toggle="tab"
                    href="#consultations"
                  >
                    <Typography sx={tabTextStyle}>
                      {t("consultations")}
                    </Typography>
                  </a>
                </li>
                <li>
                  <a data-bs-toggle="tab" href="#certificates">
                    <Typography sx={tabTextStyle}>
                      {t("Certificates")}
                    </Typography>
                  </a>
                </li>
                {albums?.length != 0 && (
                  <li>
                    <a
                      className={activeTab === "albums" ? "active" : ""}
                      data-bs-toggle="tab"
                      href="#albums"
                    >
                      <Typography sx={tabTextStyle}>
                        {t("ProfileAlbum")}
                      </Typography>
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="tab-content lp-profile-content">
              {isLoading ? (
                <LoadingPage />
              ) : (
                <ProfileCourses
                  courses={myCourses!}
                  activeTab={defaultActiveTab}
                />
              )}
              {globalTests && (
                <Quizzes
                  tests={globalTests!}
                  isShowDeleteBtn={false}
                  isStudentProfile={true}
                  activeTab={activeTab}
                />
              )}

              <Results
                isChecked={isChecked}
                setIsChecked={setIsChecked}
                results={results!}
                isLoading={loadingResults}
                isFetching={fetchingResults}
                activeTab={activeTab}
              />

              <Consultations activeTab={activeTab} />

              <ShopItems
                shopItem={myShopItems!}
                isLoading={itemsLoading}
                isFetching={itemsFetching}
                activeTab={activeTab}
              />

              <ProfileCertificates activeTab={activeTab} />

              {albums && (
                <ProfileAlbums albums={albums} activeTab={activeTab} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearnPress;
