import { Visibility, VisibilityOff } from "@mui/icons-material";
import { InputAdornment, TextField, IconButton, Button } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { fetchApi } from "../../../api/api";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const inputStyle = {
  width: "100%",
  marginBottom: "16px",
};

const ResetPasswordForm = ({ email, otp }: { email: string; otp: string }) => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {},
  });

  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const resetPassword = useMutation(
    (data: any) => fetchApi("/auth/user/reset-password", "POST", data),
    {
      onSuccess: (res) => {
        toast.success(res.data.message);
        navigate("/login");
      },
      onError: (error: any) => {
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).forEach((errorKey) => {
            toast.error(error.response.data.errors[errorKey].join("\n"));
          });
        }
      },
    }
  );

  const onSubmit: SubmitHandler<any> = async (data) => {
    const formData = {
      ...data,
      otp,
      email,
    };
    resetPassword.mutateAsync(formData);
  };

  return (
    <form style={{ marginTop: "1.5rem" }} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="password"
        control={control}
        defaultValue=""
        rules={{
          required: "Password is required",
          minLength: {
            value: 8,
            message: "Password must be at least 8 characters long",
          },
          validate: (value) =>
            value == confirmPassword ||
            "Password and Confirmation do not match",
        }}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("GlobalNewPassword")}
            placeholder={t("GlobalNewPassword")}
            type={showPassword ? "text" : "password"}
            required
            sx={inputStyle}
            error={errors.password?.type == "minLength" ? true : false}
            helperText={
              errors.password?.type == "minLength"
                ? errors.password?.message?.toString()
                : null
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            dir={i18next.language === "ar" ? "rtl" : "ltr"}
          />
        )}
      />
      <TextField
        label={t("GlobalConfirmPassword")}
        type={showConfirmPassword ? "text" : "password"}
        id="passwordOne"
        name="confirm_password"
        placeholder={t("GlobalConfirmPassword")}
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        required
        sx={inputStyle}
        error={confirmPassword !== watch("password") && confirmPassword != ""}
        helperText={
          confirmPassword !== watch("password") &&
          confirmPassword != "Password and Confirmation do not match"
        }
        dir={i18next.language === "ar" ? "rtl" : "ltr"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowConfirmPassword((prev) => !prev)}
              >
                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Button
        variant="contained"
        disabled={resetPassword.isLoading}
        fullWidth
        type="submit"
      >
        {t("ResetPassword")}
      </Button>
    </form>
  );
};

export default ResetPasswordForm;
