import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface Props {
  title: string;
  name: string;
  description: string;
  path: string;
}

const truncateText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};

const TopCardInfo: React.FC<Props> = ({ title, name, description, path }) => {
  const navigate = useNavigate();
  return (
    <Box
      onClick={() => navigate(path)}
      sx={{ cursor: "pointer", height: "100%" }}
    >
      <Box sx={{ padding: "10px 20px 0px 20px", borderRadius: "20px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "baseline",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Typography variant="h5">{truncateText(name, 50)}</Typography>
            <Typography variant="body1" color="#222">
              {truncateText(description, 70)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TopCardInfo;
