import React from "react";
import EditBlogForm from "../../components/Blog/edit-blog-components/EditBlogForm";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { fetchBlog } from "../../api/endpoints-functions";

const EditBlog = () => {
  const { id } = useParams();
  const { data, isLoading } = useQuery(["blog"], () => fetchBlog(id!));

  return (
    <div className="section section-padding-bottom">
      <div className="container" style={{ paddingTop: "2rem" }}>
        {data && <EditBlogForm data={data!} id={+id!} />}
      </div>
    </div>
  );
};

export default EditBlog;
