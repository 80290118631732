import React from "react";
import WishlistSection from "../../components/WishList/wishlist-sections/wishlist-section.component";
import { PageTitle } from "../../components/Common/page-title-section.component";
import { ScrollTop } from "../../components/Common/scroll-top.component";
import { useTranslation } from "react-i18next";
import { GridTitle } from "../../components/Common/grids-common/grid-title";

interface Props {}

const WishlistPage: React.FC<Props> = () => {
  const { t } = useTranslation();
  return (
    <div>
      <GridTitle>{t("NavHeaderWishlist")}</GridTitle>
      <WishlistSection />
      <ScrollTop />
    </div>
  );
};

export default WishlistPage;
