import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { fetchApi } from "../../api/api";
import i18n from 'i18next';


interface Option {
  icon: string;
  name: string;
  id: number;
  step: number;
}

interface Step {
  id: number;
  quistion: string;
  options: number[];
}

const StepComponent: React.FC = () => {
  const steps: Step[] = [
    {
      id: 1,
      quistion: "Mobile Or Web?",
      options: [1, 2],
    },
    {
      id: 2,
      quistion: "native or cross?",
      options: [3, 4],
    },
    {
      id: 3,
      quistion: "small or wide?",
      options: [5, 6],
    },
  ];
  const options: Option[] = [
    {
      icon: "https://img.icons8.com/color/512/web.png",
      id: 1,
      name: "Web",
      step: 2,
    },
    {
      icon: "https://img.icons8.com/3d-fluency/512/smartphone.png",
      id: 2,
      name: "Mobile",
      step: 2,
    },
    {
      icon: "https://img.icons8.com/color/512/react-native.png",
      id: 3,
      name: "Native",
      step: 3,
    },
    {
      icon: "https://img.icons8.com/3d-fluency/512/multiple-devices.png",
      id: 4,
      name: "Cross",
      step: 3,
    },
    {
      icon: "https://img.icons8.com/color/512/smartphone.png",
      id: 5,
      name: "Small Screens",
      step: 4,
    },
    {
      icon: "https://img.icons8.com/color/512/widescreen-tv.png",
      id: 6,
      name: "Wide Screens",
      step: 4,
    },
  ];
  //TODO: API Requist
  const [request, setRequest] = useState<{ step: number; option: number }[]>([]);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [currentStep, setCurrentStep] = useState<Step>(steps[currentStepIndex]);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const { mutateAsync } = useMutation((data: any) =>
    fetchApi("/step", "POST", data)
  );
  const navigate = useNavigate();
  const handleSubmit = async () => {
    await mutateAsync(request);
    navigate("/");
  };
  return (
    <div className="min-h-[75vh] bg-zinc-200 flex items-center w-screen flex-col pt-10">
      <h1 className="font-bold text-3xl font-sans">{currentStep?.quistion}</h1>
      <div className="flex justify-between max-w-3/4 gap-5 my-14 mx-auto flex-wrap items-center">
        {currentStep.options.map((optionIdx) => (
          <div
            key={options[optionIdx - 1].id}
            onClick={() => setSelectedOption(options[optionIdx - 1])}
            className={`
            flex flex-col w-[150px] h-[150px] rounded items-center cursor-pointer
            hover:shadow-md hover:shadow-blue-700 duration-200
            ${selectedOption?.id === options[optionIdx - 1].id
                ? "bg-blue-400 shadow-md shadow-blue-700 duration-200 text-white"
                : "bg-white text-black"
              }
            `}
          >
            <div className="w-[100px] h-[100px] overflow-hidden">
              <img
                src={options[optionIdx - 1].icon}
                alt={options[optionIdx - 1].name}
                className="w-full h-full"
              />
            </div>
            <p>{options[optionIdx - 1].name}</p>
          </div>
        ))}
      </div>
      <div className="flex items-center gap-3">
        <button
          className={`w-40 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none text-white ${request.length === 0
              ? "bg-zinc-600 cursor-not-allowed"
              : "bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300"
            }`}
          disabled={request.length === 0}
          onClick={() => {
            // FIXME: filter are being fire twise
            setRequest((pre) => pre.filter((el) => el.step === currentStep.id));
            setCurrentStepIndex((pre) => pre - 1);
            setCurrentStep(steps[currentStepIndex - 1]);
            setSelectedOption(null);
          }}
        >
          {i18n.language === "en" ? "Previous" : "السابق"}
        </button>

        {currentStepIndex !== steps.length - 1 ? (
          <button
            className={`w-40 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none text-white ${!selectedOption
                ? "bg-zinc-600 cursor-not-allowed"
                : "bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300"
              }`}
            disabled={!selectedOption}
            onClick={() => {
              setRequest((pre) => [
                ...pre,
                {
                  step: currentStep.id,
                  option: selectedOption!.id,
                },
              ]);
              setCurrentStepIndex((pre) => pre + 1);
              setCurrentStep(steps[currentStepIndex + 1]);
              setSelectedOption(null);
            }}
          >
            {i18n.language === "en" ? "Next" : "التالي"}
          </button>
        ) : (
          <button
            className="w-40 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300"
            onClick={() => {
              handleSubmit();
            }}
          >
            {i18n.language === "en" ? "Send" : "إرسال"}
          </button>
        )}
      </div>
    </div>
  );
};

export default StepComponent;
