import OptionalTest from "../../components/Test/optional-test";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { LoadingPage } from "../../components/Common/loadingPage.component";
import {
  fetchCourseTestQuestionsForStudent,
  fetchLessonInfo,
} from "../../api/endpoints-functions";
import { testAnswersRequest } from "../../interfaces/new-api-interfaces/test";
import { defaultTranslation } from "../../untilites/functions";
import { SubmitHandler, useForm } from "react-hook-form";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import WrittenQuestion from "../../components/Test/written-question";
import { useTranslation } from "react-i18next";
import MatchQuestion from "../../components/Test/match-question";
import { fetchApi } from "../../api/api";
import { toast } from "react-toastify";
import i18next from "i18next";
import { ErrorFallBackNotFound } from "../../components/Common/error-handling/error-404";

interface Props {
  id: any;
  lessonId: any;
  courseId: any;
  setOpen?: any;
  isGraded?: boolean;
  attempts?: number;
}

const CourseTestPaper: React.FC<Props> = ({
  id,
  lessonId,
  courseId,
  setOpen,
  isGraded,
  attempts,
}) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    getValues,
    clearErrors,
    setValue,
  } = useForm<testAnswersRequest>({
    defaultValues: { answer: [] },
  });

  const queryClient = useQueryClient();

  //get questions
  const { isLoading, isError, data } = useQuery(
    ["get-test-by-id", id],
    () => fetchCourseTestQuestionsForStudent(id!),
    {
      refetchOnWindowFocus: false, // Disable refetch on focus
      refetchOnReconnect: false,
    }
  );

  //get lesson info
  const { data: test, isLoading: testLoading } = useQuery(
    ["get-test", lessonId],
    () => fetchLessonInfo(lessonId!),
    {
      refetchOnWindowFocus: false, // Disable refetch on focus
      refetchOnReconnect: false,
    }
  );

  const {
    isLoading: mutationLoading,
    mutateAsync,
    isError: mutationError,
  } = useMutation(
    (data: any) => fetchApi("/test/test/answerTest", "POST", data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-sections"]);
        queryClient.invalidateQueries([
          "get-test-details",
          data?.questions[0].questionable_id,
        ]);
        queryClient.invalidateQueries(["get-course-by-id", courseId]);
        queryClient.invalidateQueries(["course-content", courseId]);
      },
      onError: (error: any) => {
        toast.error(
          i18next.language === "en"
            ? "Something went wrong, please try again later."
            : "حدث خطأ ما، يرجى المحاولة مرة أخرى لاحقًا."
        );
      },
    }
  );

  const validateSelection = (questions: any, answers: any[]) => {
    const missingQuestionIndexes: number[] = questions
      .map((question: any, index: any) => ({ ...question, index })) // Add the index to each question
      .filter((question: any) => {
        if (question.type === "options") {
          return !answers.some((answer) => answer.question_id === question.id);
        } else if (question.type === "match") {
          const matchLeftIds = question.match_lefts.map(
            (matchLeft: any) => matchLeft.id
          );
          const matchRightIds = question.match_rights.map(
            (matchRight: any) => matchRight.id
          );
          const isMatchLeftIncluded = matchLeftIds.every((leftId: any) =>
            answers.some(
              (answer) =>
                answer.question_id === question.id &&
                answer.match_left_id === leftId
            )
          );
          const isMatchRightIncluded = matchRightIds.every((rightId: any) =>
            answers.some(
              (answer) =>
                answer.question_id === question.id &&
                answer.match_right_id === rightId
            )
          );
          return !isMatchLeftIncluded || !isMatchRightIncluded;
        }
        return false;
      })
      .map((question: any) => question.index + 1);

    return {
      isValid: missingQuestionIndexes.length === 0,
      missingQuestionIndexes,
    };
  };

  const onSubmit: SubmitHandler<testAnswersRequest> = async (formData) => {
    try {
      const radioValidationResult = validateSelection(
        data?.questions,
        formData.answer
      );
      if (radioValidationResult.isValid) {
        // Proceed with form submission
        mutateAsync({
          ...formData,
          test_id: data?.questions[0].questionable_id,
          test_type: data?.questions[0].questionable_type,
          attempts: attempts,
        }).then((response) => {
          if (response.status === 200 || response.status === 201) {
            setOpen(false);
            toast.success(
              i18next.language === "en"
                ? "Test Submitted successfully"
                : "تمت الإجابة على الاختبار بنجاح"
            );
          }
        });
      } else {
        // Set an error message for the radio button
        setError("answer", {
          type: "manual",
          message: `Please select an option for questions numbers: ${radioValidationResult.missingQuestionIndexes.join(
            ", "
          )}`,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { t } = useTranslation();

  if (isError) return <ErrorFallBackNotFound />;
  if (isLoading || testLoading) return <LoadingPage />;
  else
    return (
      <section>
        {data.questions && data?.questions.length! > 0 && (
          <div
            className="pt-5"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              paddingBottom: "50px",
            }}
          >
            <div className="d-flex justify-content-center align-items-center my-5 container mx-auto">
              <Typography variant="h3" color="primary">
                {defaultTranslation(test, "name")}
              </Typography>
            </div>
            <form
              className="container mx-auto"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
                gap: "30px",
              }}
              onSubmit={handleSubmit(onSubmit)}
            >
              {data?.questions.map((question, index: number) =>
                question.type == "written" ? (
                  <Box sx={{ width: "100%" }} key={question.id}>
                    <WrittenQuestion
                      question={question}
                      setValue={setValue}
                      getValues={getValues}
                      isGraded={isGraded}
                    />
                  </Box>
                ) : question.type == "options" ? (
                  <Box key={question.id}>
                    <OptionalTest
                      question={question}
                      setValue={setValue}
                      index={index}
                      getValues={getValues}
                      clearErrors={clearErrors}
                      isGraded={isGraded}
                    />
                  </Box>
                ) : (
                  <Box key={question.id}>
                    <MatchQuestion
                      question={question}
                      setValue={setValue}
                      control={control}
                      getValues={getValues}
                      clearErrors={clearErrors}
                      isGraded={isGraded}
                    />
                  </Box>
                )
              )}
              {errors.answer && (
                <Typography color="error">{errors.answer.message}</Typography>
              )}
              <Button
                variant="contained"
                color="primary"
                className="mb-2 btn-hover-secondary btn-width-100"
                type="submit"
                disabled={isLoading} // Disable the button when isLoading is true
              >
                {mutationLoading ? ( // Show loading icon if isLoading is true
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <Typography fontSize="large" color="#fff">
                    {t("submit")}
                  </Typography>
                )}
              </Button>
            </form>
          </div>
        )}
        {data?.questions.length == 0 && <ErrorFallBackNotFound />}
      </section>
    );
};

export default CourseTestPaper;
