import React, { useEffect, useState } from "react";
import {
  Drawer,
  IconButton,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Slider,
  Typography,
  Box,
  TextField,
  Button,
  Stack,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useQuery } from "@tanstack/react-query";
import { fetchCategories } from "../../../api/endpoints-functions";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import i18next from "i18next";
import { defaultTranslation } from "../../../untilites/functions";
import { shopItemTypeResponse } from "../../../interfaces/new-api-interfaces/shop-item";
import { useLocation, useNavigate } from "react-router-dom";

interface GridFilterProps {
  priceRange: number[] | null;
  selectedCategories: number[];
  setPriceRange: Function | null;
  setSelectedCategories: Function;
  types?: shopItemTypeResponse[];
  setSelectedTypes?: Function;
  free?: boolean;
}

const GridFilter: React.FC<GridFilterProps> = ({
  priceRange,
  selectedCategories,
  setPriceRange,
  setSelectedCategories,
  types,
  setSelectedTypes,
  free,
}) => {
  const {
    data: categories,
    isLoading: l1,
    isError: e1,
  } = useQuery(["get-categories"], () => fetchCategories());
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [filterByPrice, setFilterByPrice] = useState(false);
  const [localPriceRange, setLocalPriceRange] = useState<number[]>([0]);
  const [localSelectedCategories, setLocalSelectedCategories] = useState<
    number[]
  >([]);

  const [localSelectedTypes, setLocalSelectedTypes] = useState<number[]>([]);

  const cacheRtl = createCache({
    key: "muiltr",
    stylisPlugins: undefined,
  });

  const handleToggleDrawer = () => {
    setOpen(!open);
  };

  const handlePriceFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newValue = parseInt(e.target.value);
    if (!isNaN(newValue)) {
      let newPriceRange: number[] =
        localPriceRange != null ? [...localPriceRange] : [];

      if (newPriceRange.length === 0) {
        newPriceRange = [0, 0];
      }

      newPriceRange[index] = newValue;

      if (newPriceRange[0] === 0 && newPriceRange[1] === 0) {
        newPriceRange = [0];
      }

      if (!newPriceRange[1]) {
        newPriceRange = [newPriceRange[0]];
      }

      if (setLocalPriceRange != null) setLocalPriceRange(newPriceRange);
    }
  };

  const handleCategoryChange = (categoryId: number) => {
    if (localSelectedCategories.includes(categoryId)) {
      setLocalSelectedCategories(
        localSelectedCategories.filter((id) => id !== categoryId)
      );
    } else {
      setLocalSelectedCategories([...localSelectedCategories, categoryId]);
    }
  };

  const handleTypeChange = (typeId: number) => {
    if (localSelectedTypes.includes(typeId)) {
      setLocalSelectedTypes(localSelectedTypes.filter((id) => id !== typeId));
    } else {
      setLocalSelectedTypes([...localSelectedTypes, typeId]);
    }
  };

  useEffect(() => {
    if (filterByPrice == false) {
      if (setPriceRange != null) setPriceRange([0]);
      setLocalPriceRange([0]);
    }
  }, [filterByPrice]);

  const ApplyFilters = () => {
    navigate({ pathname: location.pathname, search: `` });
    if (setPriceRange != null) setPriceRange(localPriceRange);
    if (setSelectedCategories != null)
      setSelectedCategories(localSelectedCategories);
    if (setSelectedTypes !== null && setSelectedTypes)
      setSelectedTypes(localSelectedTypes);
  };

  const ResetFilters = () => {
    setLocalSelectedCategories([]);
    setFilterByPrice(false);
    setSelectedCategories([]);
    setLocalSelectedTypes([]);
    if (setSelectedTypes) {
      setSelectedTypes([]);
    }
  };

  //filter on free
  useEffect(() => {
    if (free) {
      setFilterByPrice(true);
      if (setPriceRange != null) setPriceRange([0, 0]);
      setLocalPriceRange([0, 0]);
    } else {
      setFilterByPrice(false);
      if (setPriceRange != null) setPriceRange([0]);
      setLocalPriceRange([0]);
    }
  }, [free]);

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const searchParam = params.get("category");
      const typeSearchParam = params.get("type");
      if (searchParam) {
        setLocalSelectedCategories([parseInt(searchParam)]);
      }

      if (typeSearchParam) setLocalSelectedTypes([parseInt(typeSearchParam)]);
    }
  }, [location.search]);

  return (
    <div className="col-sm-10 col-md-1 max-mb-10 mb-20">
      <IconButton onClick={handleToggleDrawer}>
        <FilterListIcon />
      </IconButton>
      <CacheProvider value={cacheRtl}>
        <Drawer
          anchor={i18next.language == "en" ? "left" : "right"}
          dir={i18next.language == "en" ? "left" : "right"}
          open={open}
          onClose={handleToggleDrawer}
        >
          <Box
            sx={{
              padding: "20px",
              width: { xs: "70vw", md: "20vw" },
              direction: i18next.language == "en" ? "ltr" : "rtl",
            }}
          >
            <Stack direction="row" gap={3}>
              <Button
                sx={{ marginY: "20px" }}
                variant="contained"
                onClick={() => ResetFilters()}
                color="error"
              >
                {i18next.language == "en" ? "Reset Filters" : "حذف التصنيفات"}
              </Button>
              <Button
                sx={{ marginY: "20px" }}
                variant="contained"
                onClick={() => ApplyFilters()}
              >
                {i18next.language == "en" ? "Apply Filters" : "تطبيق التصنيفات"}
              </Button>
            </Stack>
            <FormGroup>
              {priceRange != null && (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterByPrice}
                        onChange={() => {
                          setFilterByPrice(!filterByPrice);
                        }}
                      />
                    }
                    label={
                      i18next.language == "en"
                        ? "Filter by price"
                        : "التصنيف حسب السعر"
                    }
                  />
                  {filterByPrice && (
                    <Box sx={{ paddingX: "20px" }}>
                      <Typography id="range-slider" gutterBottom>
                        {i18next.language === "en"
                          ? "Price Range"
                          : "نطاق السعر"}
                      </Typography>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <TextField
                          type="number"
                          value={localPriceRange[0]}
                          onChange={(e: any) => handlePriceFieldChange(e, 0)}
                          label={
                            i18next.language == "en" ? "Min" : "الحد الأدنى"
                          }
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <span style={{ margin: "0 10px" }}>-</span>
                        <TextField
                          type="number"
                          value={localPriceRange[1]}
                          onChange={(e: any) => handlePriceFieldChange(e, 1)}
                          label={
                            i18next.language == "en" ? "Max" : "الحد الأعلى"
                          }
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                    </Box>
                  )}
                </>
              )}
              <Typography variant="h6">
                {i18next.language == "en" ? "Categories" : "التصنيفات"}
              </Typography>
              {categories?.map((category) => (
                <FormControlLabel
                  key={category.id}
                  control={
                    <Checkbox
                      checked={localSelectedCategories.includes(category.id)}
                      onChange={() => handleCategoryChange(category.id)}
                    />
                  }
                  label={defaultTranslation(category, "name")}
                />
              ))}
              {types && (
                <>
                  <Typography variant="h6">
                    {i18next.language === "en" ? "Types" : "الأنواع"}
                  </Typography>
                  {types?.map((type) => (
                    <FormControlLabel
                      label={defaultTranslation(type, "name")}
                      key={type.id}
                      control={
                        <Checkbox
                          checked={localSelectedTypes.includes(type.id)}
                          onChange={() => handleTypeChange(type.id)}
                        />
                      }
                    />
                  ))}
                </>
              )}
            </FormGroup>
          </Box>
        </Drawer>
      </CacheProvider>
    </div>
  );
};

export default GridFilter;
