import OptionalTest from "../../components/Test/optional-test";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { LoadingPage } from "../../components/Common/loadingPage.component";
import {
  fetchGlobalTest,
  fetchTestQuestionsForStudent,
} from "../../api/endpoints-functions";
import { testAnswersRequest } from "../../interfaces/new-api-interfaces/test";
import { defaultTranslation } from "../../untilites/functions";
import { SubmitHandler, useForm } from "react-hook-form";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import WrittenQuestion from "../../components/Test/written-question";
import { useTranslation } from "react-i18next";
import MatchQuestion from "../../components/Test/match-question";
import { fetchApi } from "../../api/api";
import { toast } from "react-toastify";
import i18next from "i18next";
import { ErrorFallBackNotFound } from "../../components/Common/error-handling/error-404";

const TestPaper: React.FC = () => {
  const testId = useParams();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    getValues,
    clearErrors,
    setValue,
  } = useForm<testAnswersRequest>({
    defaultValues: { answer: [] },
  });
  const { isLoading, isError, data } = useQuery(
    ["get-test-by-id", testId.id],
    () => fetchTestQuestionsForStudent(testId.id!),
    {
      refetchOnWindowFocus: false, // Disable refetch on focus
      refetchOnReconnect: false,
    }
  );

  const { data: test, isLoading: testLoading } = useQuery(
    ["get-test", testId],
    () => fetchGlobalTest(testId.id!),
    {
      refetchOnWindowFocus: false, // Disable refetch on focus
      refetchOnReconnect: false,
    }
  );

  const {
    isLoading: mutationLoading,
    mutateAsync,
    isError: mutationError,
  } = useMutation(
    (data: any) => fetchApi("/test/test/answerTest", "POST", data),
    {
      onError: (error: any) => {
        toast.error(
          i18next.language === "en"
            ? "Something went wrong, please try again later."
            : "حدث خطأ ما، يرجى المحاولة مرة أخرى لاحقًا."
        );
      },
    }
  );

  //function for validation
  const validateSelection = (questions: any, answers: any[]) => {
    const missingQuestionIndexes: number[] = questions
      .map((question: any, index: any) => ({ ...question, index })) // Add the index to each question
      .filter((question: any) => {
        if (question.type === "options") {
          return !answers.some((answer) => answer.question_id === question.id);
        } else if (question.type === "match") {
          const matchLeftIds = question.match_lefts.map(
            (matchLeft: any) => matchLeft.id
          );
          const matchRightIds = question.match_rights.map(
            (matchRight: any) => matchRight.id
          );
          const isMatchLeftIncluded = matchLeftIds.every((leftId: any) =>
            answers.some(
              (answer) =>
                answer.question_id === question.id &&
                answer.match_left_id === leftId
            )
          );
          const isMatchRightIncluded = matchRightIds.every((rightId: any) =>
            answers.some(
              (answer) =>
                answer.question_id === question.id &&
                answer.match_right_id === rightId
            )
          );
          return !isMatchLeftIncluded || !isMatchRightIncluded;
        }
        return false;
      })
      .map((question: any) => question.index + 1);

    return {
      isValid: missingQuestionIndexes.length === 0,
      missingQuestionIndexes,
    };
  };

  const onSubmit: SubmitHandler<testAnswersRequest> = async (formData) => {
    try {
      const radioValidationResult = validateSelection(
        data?.questions,
        formData.answer
      );
      if (radioValidationResult.isValid) {
        // Proceed with form submission
        mutateAsync({
          ...formData,
          test_id: data?.questions[0].questionable_id,
          test_type: data?.questions[0].questionable_type,
          attempts: test?.attempts,
        }).then((response) => {
          if (response.status === 200 || response.status === 201) {
            navigate("/student-profile", { replace: true });
            toast.success(
              i18next.language === "en"
                ? "Test Submitted successfully"
                : "تمت الإجابة على الاختبار بنجاح"
            );
          }
        });
      } else {
        // Set an error message for the radio button
        setError("answer", {
          type: "manual",
          message: `Please select an option for questions numbers: ${radioValidationResult.missingQuestionIndexes.join(
            ", "
          )}`,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { t } = useTranslation();

  if (isError) return <ErrorFallBackNotFound />;
  if (isLoading || testLoading) return <LoadingPage />;
  else
    return (
      <section>
        {data?.questions?.length! > 0 && (
          <div
            className="pt-5 container mx-auto"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              paddingBottom: "50px",
            }}
          >
            <div className="d-flex justify-content-center align-items-center my-5 container mx-auto">
              <Typography variant="h4" color="primary">
                {defaultTranslation(test, "name")}
              </Typography>
            </div>
            {!!test?.is_graded && (
              <Box mb="1rem">
                <Typography variant="h5" color="primary">
                  {i18next.language === "ar"
                    ? "العلامة الكلية: "
                    : "Total Mark: "}
                  {test?.total_mark}
                </Typography>
              </Box>
            )}
            <form
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
                gap: "30px",
              }}
              onSubmit={handleSubmit(onSubmit)}
            >
              {data?.questions.map((question, index: number) =>
                question.type == "written" ? (
                  <Box sx={{ width: "100%" }} key={question.id}>
                    <WrittenQuestion
                      question={question}
                      setValue={setValue}
                      getValues={getValues}
                      isGraded={test?.is_graded}
                    />
                  </Box>
                ) : question.type == "options" ? (
                  <Box key={question.id}>
                    <OptionalTest
                      question={question}
                      setValue={setValue}
                      index={index}
                      getValues={getValues}
                      clearErrors={clearErrors}
                      isGraded={test?.is_graded}
                    />
                  </Box>
                ) : (
                  <Box key={question.id}>
                    <MatchQuestion
                      question={question}
                      setValue={setValue}
                      control={control}
                      getValues={getValues}
                      clearErrors={clearErrors}
                      isGraded={test?.is_graded}
                    />
                  </Box>
                )
              )}
              {errors.answer && (
                <Typography color="error">{errors.answer.message}</Typography>
              )}
              <Button
                variant="contained"
                color="primary"
                className="mb-2 btn-hover-secondary btn-width-100"
                type="submit"
                disabled={isLoading} // Disable the button when isLoading is true
              >
                {mutationLoading ? ( // Show loading icon if isLoading is true
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <Typography fontSize="large" color="#fff">
                    {t("submit")}
                  </Typography>
                )}
              </Button>
            </form>
          </div>
        )}
        {data?.questions?.length == 0 && <ErrorFallBackNotFound />}
      </section>
    );
};

export default TestPaper;
