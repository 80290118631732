import React, { useEffect, useState } from "react";
import { CustomTextarea } from "../../helpers";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { testToCorrectQuestion } from "../../../interfaces/new-api-interfaces/test";
import { defaultTranslation } from "../../../untilites/functions";
import i18next from "i18next";
import { BsExclamationCircleFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";

interface Props {
  isTrue: boolean;
  value: string;
  setResult: (id: number, isTrue: boolean, mark: number) => void;
  id: number;
  question: testToCorrectQuestion;
  isLoading: boolean;
  isGraded?: 0 | 1;
}

const CorrectWrittenTest: React.FC<Props> = ({
  id,
  value,
  setResult,
  question,
  isLoading,
  isGraded,
}) => {
  const [mark, setMark] = useState<number>(0);
  const { t } = useTranslation();

  useEffect(() => {
    setMark(question.written_answers[0].answer_mark);
  }, [question]);

  console.log(mark > question.mark);
  console.log(question.mark);

  return (
    <Box sx={{ bgcolor: "#FBFBFB", padding: "20px" }}>
      <Stack width={"90%"} direction={"row"} justifyContent={"space-between"}>
        <Typography variant="h6">
          {question?.translations && defaultTranslation(question, "name")}
        </Typography>
        {isGraded == 1 && (
          <Typography variant="h6" color="mypurple2.main">
            {question.mark} {t("Mark")}
          </Typography>
        )}
      </Stack>
      <CustomTextarea
        arLabel=""
        enLabel=""
        isReadOnly
        prevValue={value}
        handleChange={() => {}}
      />
      <div className="d-flex align-items-center gap-3">
        {isLoading ? ( // Check if isLoading is true
          <div className="mr-2 mt-6">
            <CircularProgress size={24} />
          </div>
        ) : (
          isGraded == 1 && (
            <>
              {/* <div className="mr-2 mt-3">
                <IconButton
                  onClick={() => {
                    setResult(+id, false, mark);
                  }}
                  disabled={mark === undefined || mark > question.mark}
                >
                  <ClearIcon
                    style={{
                      color:
                        question.written_answers[0].is_checked &&
                        !question.written_answers[0].is_true
                          ? "red"
                          : "",
                    }}
                  />
                </IconButton>
              </div> */}

              {!question.written_answers[0].is_checked ? (
                <Stack
                  direction={"row"}
                  alignItems={"flex-start"}
                  gap={"0.5rem"}
                  className="mt-4"
                >
                  <div className="">
                    <Button
                      variant="contained"
                      onClick={() => {
                        setResult(+id, true, mark);
                      }}
                      disabled={mark === undefined || mark > question.mark}
                    >
                      {i18next.language == "en" ? "submit" : "تثبيت"}
                    </Button>
                  </div>

                  <TextField
                    label={
                      i18next.language === "ar"
                        ? "العلامة المستحقة"
                        : "Obtained Mark"
                    }
                    size="small"
                    onChange={(e) => setMark(+e.target.value)}
                    sx={{ width: "160px", whiteSpace: "nowrap" }}
                    value={mark}
                    error={mark > question.mark}
                    helperText={
                      mark > question.mark
                        ? i18next.language === "ar"
                          ? "العلامة أكبر من علامة السؤال"
                          : "Mark is grater than question's mark"
                        : ""
                    }
                  />

                  <Tooltip
                    title={
                      i18next.language === "ar"
                        ? "ادخل 0 اذا كانت الاجابة خاطئة"
                        : "Add 0 if answer is uncorrect"
                    }
                    placement={i18next.language === "ar" ? "left" : "right"}
                  >
                    <IconButton>
                      <BsExclamationCircleFill
                        color="#777"
                        fontSize={"1.2rem"}
                      />
                    </IconButton>
                  </Tooltip>
                </Stack>
              ) : (
                <Typography variant="h6" color="primary" marginTop={2}>
                  {i18next.language === "ar"
                    ? "العلامة المستحقة: "
                    : "Obtained Mark: "}{" "}
                  {question.written_answers[0].answer_mark}
                </Typography>
              )}
            </>
          )
        )}
      </div>
    </Box>
  );
};

export default CorrectWrittenTest;
