import React from "react";
import { MessageBox } from "react-chat-elements";
import { vrcGroupMessage } from "../../../interfaces/new-api-interfaces/vcr";
import { defaultTranslation } from "../../../untilites/functions";
import {
  getImageFromServer,
  getUserImageFromServer,
} from "../../../assets/JS/helpers";
import { Avatar, Stack } from "@mui/material";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

type Props = {
  msg: vrcGroupMessage;
  userId: number;
};

function MessageComponent({ msg, userId }: Props) {
  const { t } = useTranslation();
  return (
    <Stack
      flexDirection={
        msg.user.id == userId
          ? i18next.language == "en"
            ? "row"
            : "row-reverse"
          : i18next.language == "en"
          ? "row-reverse"
          : "row"
      }
      justifyContent={msg.type == "system" ? "center" : "flex-start"}
      sx={{ marginTop: 2 }}
    >
      {msg.type != "system" && (
        <Avatar src={getUserImageFromServer(msg.user.profile_image)} />
      )}
      {
        //@ts-ignore
        <MessageBox
          position={msg.user.id == userId ? "left" : "right"}
          type={
            msg.type == "text"
              ? "text"
              : msg.type == "image"
              ? "photo"
              : msg.type == "audio"
              ? "audio"
              : msg.type == "video"
              ? "video"
              : msg.type == "system"
              ? "system"
              : msg.type == "asset"
              ? "file"
              : msg.type == "meeting"
              ? "meetingLink"
              : null
          }
          title={
            msg.user.id == userId
              ? t("You")
              : defaultTranslation(msg.user, "first_name") +
                " " +
                defaultTranslation(msg.user, "last_name")
          }
          text={
            msg.type == "text" || msg.type == "system"
              ? msg.message
              : msg.type == "asset"
              ? msg.message.split("/").pop()
              : msg.type == "meeting"
              ? t("ClickTojoinMeeting")
              : ""
          }
          id={msg.id}
          // avatar={getImageFromServer(msg.user.profile_image)}
          dateString={`${msg.created_at.slice(0, 10)} - ${msg.created_at.slice(
            11,
            16
          )}`}
          date={new Date(msg.created_at)}
          data={{
            uri: getImageFromServer(msg.message),
            //@ts-ignore
            videoURL:
              msg.type == "video" ? getImageFromServer(msg.message) : null,
            audioURL:
              msg.type == "audio" ? getImageFromServer(msg.message) : null,
            status: {
              click: true,
              loading: 0,
              download: true,
              autoDownload: false,
            },
            height: 300,
            width: msg.type == "video" ? 400 : 300,
          }}
          onClick={() => {
            if (msg.type === "asset" || msg.type === "image") {
              const url = getImageFromServer(msg.message);
              window.open(url, "_blank");
            }
            if (msg.type === "meeting") {
              window.open(msg.message, "_blank");
            }
          }}
          titleColor={msg.user.id == userId ? "#3f3a64" : "#ED7D64"}
          removeButton={msg.user.id == userId}
          onRemoveMessageClick={(e) => {
            e.stopPropagation();
          }}
          onTitleClick={(e) => {
            e.stopPropagation();
          }}
          styles={{
            cursor: msg.type != "text" ? "pointer" : "auto",
            textAlign: i18next.language == "en" ? "end" : "start",
            direction: i18next.language == "en" ? "ltr" : "rtl",
          }}
        />
      }
    </Stack>
  );
}

export default MessageComponent;
