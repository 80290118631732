import React from "react";
import ErrorSvg from "../../../assets/images/error/error.svg";
import { Box, Button, Typography } from "@mui/material";

export const ErrorFallBack: React.FC<any> = ({ error, resetErrorBoundary }) => {
  console.log("boundary");
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "20px",
        padding: "50px",
      }}
      className="container"
    >
      <Box style={{ textAlign: "center" }}>
        <Typography variant="h4">{error.message}</Typography>
      </Box>
      {/*<pre>{error.message}</pre>*/}
      <Box>
        <img src={ErrorSvg} width="400px" height="400px" />
      </Box>
      <Box style={{ textAlign: "center", paddingTop: 50 }}>
        <Button
          color="primary"
          variant="contained"
          onClick={resetErrorBoundary}
        >
          Try again
        </Button>
      </Box>
    </Box>
  );
};