import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import TextEditor from "../../../../TextEditor";
import { SectionType } from "../../AddBlogForm";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

interface Props {
  open: boolean;
  setOpen: any;
  section: SectionType;
  onSave: any;
  item: SectionType;
  arabicDescription: string;
  setArabicDescription: any;
  englishDescription: string;
  setEnglishDescription: any;
}

const TextModal = ({
  open,
  setOpen,
  section,
  onSave,
  item,
  arabicDescription,
  setArabicDescription,
  englishDescription,
  setEnglishDescription,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Dialog
      dir={i18next.language === "ar" ? "rtl" : "ltr"}
      maxWidth="xl"
      fullWidth
      open={open}
      onClose={() => setOpen(false)}
      disableEnforceFocus={true}
    >
      <DialogTitle align="center" fontWeight={"600"} fontSize={"2rem"}>
        {`${t("section")} #${section.id}`}
      </DialogTitle>
      <DialogContent>
        <Grid height="70vh" container spacing={2}>
          <Grid height="100%" item xs={12} sm={6}>
            <Stack height="100%" spacing={1}>
              <Typography>{t("descAr")}</Typography>
              <TextEditor
                value={arabicDescription}
                setValue={setArabicDescription}
                dir="rtl"
              />
            </Stack>
          </Grid>
          <Grid height="100%" item xs={12} sm={6}>
            <Stack height="100%" spacing={1}>
              <Typography>{t("descEn")}</Typography>
              <TextEditor
                value={englishDescription}
                setValue={setEnglishDescription}
              />
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Stack direction={"row"} spacing={1} gap={1} padding={2}>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            {t("close")}
          </Button>
          <Button
            onClick={() => {
              if (!arabicDescription || !englishDescription) {
                if (arabicDescription === "") {
                  toast.error("Arabic description is required!");
                } else {
                  toast.error("English description is required!");
                }
                return;
              }
              onSave(arabicDescription, englishDescription, item.id);
            }}
            variant="contained"
          >
            {t("save")}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default TextModal;
