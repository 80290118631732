import {
  EllipsisButton,
  InfoButton,
  VideoCallButton,
} from "@chatscope/chat-ui-kit-react";
import React from "react";
import GroupInfoModal from "../group/group-info-modal";
import { vrcGroupResponse } from "../../../interfaces/new-api-interfaces/vcr";
import useCreateVrcGoogleMeet from "../../../hooks/vrc/useCreateVrcGoogleMeet";

type Props = {
  activeConversations: vrcGroupResponse;
  role: "student" | "instructor" | "";
};

function VrcHeaderActions({ activeConversations, role }: Props) {
  const [open, setOpen] = React.useState(false);
  const createMeeting = useCreateVrcGoogleMeet(activeConversations.id);
  return (
    <>
      <GroupInfoModal
        activeConversation={activeConversations}
        open={open}
        onClose={() => setOpen(false)}
      />
      {role == "instructor" && (
        <VideoCallButton
          onClick={() => createMeeting.googleLogin()}
          disabled={createMeeting.isLoading}
        />
      )}
      <InfoButton style={{ display: "block" }} onClick={() => setOpen(true)} />
      {/* <EllipsisButton orientation="vertical" /> */}
    </>
  );
}

export default VrcHeaderActions;
