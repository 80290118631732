import { useEffect, useState } from "react";

import PhotoAlbum from "react-photo-album";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { Box, CircularProgress, Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { fetchStudentAlbum } from "../../api/endpoints-functions";
import i18next from "i18next";
import { Download } from "@mui/icons-material";

const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

export default function Album({ id }: { id: number | undefined }) {
  const [index, setIndex] = useState(-1);
  const [photos, setPhotos] = useState<any[] | undefined>([]);

  const { data, isLoading, isFetching } = useQuery(
    ["album"],
    () => fetchStudentAlbum(id!),
    {
      enabled: !!id,
    }
  );

  useEffect(() => {
    if (data && data.media) {
      const photos = data?.media?.map((photo) => {
        return {
          src: photo.original_url,
          width: 1440,
          height: 1080,
          srcSet: breakpoints.map((breakpoint) => {
            const height = Math.round((1440 / 1080) * breakpoint);
            return {
              src: photo.original_url,
              width: breakpoint,
              height,
            };
          }),
        };
      });
      setPhotos(photos);
    }
  }, [data]);

  return data && !isLoading && !isFetching && data.media.length > 0 ? (
    <Box py={"3rem"}>
      <PhotoAlbum
        photos={photos!}
        layout="masonry"
        targetRowHeight={150}
        onClick={({ index }) => setIndex(index)}
      />

      <Lightbox
        slides={photos}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        // enable optional lightbox plugins
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
        toolbar={{
          buttons: [
            "close",
            <Box
              component={"a"}
              download
              target="_blank"
              href={photos && photos[index] ? photos![index].src : ""}
              display={"flex"}
              alignItems={"center"}
              marginRight={"4rem"}
            >
              <Download sx={{ color: "white" }} />
            </Box>,
          ],
        }}
        on={{ view: ({ index: currentIndex }) => setIndex(currentIndex) }}
      />
    </Box>
  ) : data?.media.length === 0 ? (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      width={"100%"}
      height={"100%"}
    >
      {i18next.language === "ar"
        ? "لا يوجد صور ضمن الألبوم."
        : "No images in album."}
    </Stack>
  ) : (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      width={"100%"}
      height={"100%"}
    >
      <CircularProgress size={72} />
    </Stack>
  );
}
