import React from "react";
import ContactInformation from "../../components/static-pages/Contact/contact-information";
import { GoogleMap } from "../../components/Common/google-map.component";
import { PageTitle } from "../../components/Common/page-title-section.component";
import ContactForm from "../../components/static-pages/Contact/contact-form";
import { ScrollTop } from "../../components/Common/scroll-top.component";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

interface Props {}

const ContactUsPage: React.FC<Props> = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <PageTitle
        link={t("ContactUs")}
        prevLinks={[
          {
            title: t("GlobalHome"),
            link: "/",
          },
        ]}
        title=""
      />
      <ContactInformation />
      <GoogleMap mapLink="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3326.027163597128!2d36.288211684798625!3d33.526679380752206!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDMxJzM2LjEiTiAzNsKwMTcnMDkuNyJF!5e0!3m2!1sar!2s!4v1711621760775!5m2!1sar!2s" />
      {/* <ContactForm sendTo="assets/php/contact-mail.php" /> */}
      <ScrollTop />
    </Box>
  );
};

export default ContactUsPage;
