import { useQuery } from "@tanstack/react-query";
import React from "react";
import { fetchApi } from "../../api/api";
import { Link, useNavigate, useParams } from "react-router-dom";
import {LoadingPage} from "../../components/Common/loadingPage.component";
import { Card, CardMedia, Rating } from "@mui/material";
import { getImageFromServer } from "../../assets/JS/helpers";

interface Answer {
  value: string;
  next: number;
  createdAt: string;
  updatedAt: string;
  userChosen: boolean;
}

interface Question {
  question: string;
  createdAt: string;
  updatedAt: string;
  answers: Answer[];
}

interface Case {
  title: string;
  questions: Question[];
  course?: Course;
  adminResponse?: string;
}

interface Course {
  course: {
    id: number;
    name: string;
    rating: number;
    courseImage: string;
    instructor: {
      name: string;
      profileImage: string;
    };
  };
}

const DisplayCaseAnswers: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { data, isLoading, isError } = useQuery(["get-case", params.id], () =>
    fetchApi(`/user-use-case-studies/${params.id}`, "GET", {})
  );

  if (isLoading) return <LoadingPage />;
  return (
    <div>
      <div
        className=" flex items-center w-screen flex-col"
        style={{ padding: "75px" }}
      >
        <p className="text-3xl">Your Answers</p>
        {data?.data.questions.map((question: Question) => {
          return (
            <div
              key={question.question}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h1
                className="font-bold text-2xl text-align-center font-sans"
                style={{ marginBottom: "20px", marginTop: "20px" }}
              >
                {question.question}
              </h1>
              {question.answers.map((answer: Answer) => {
                return (
                  <div key={answer.value}>
                    {answer.userChosen == true && (
                      <div
                        className="flex flex-col rounded items-center hover:shadow-md hover:shadow-blue-700 duration-200 bg-blue-400 shadow-md shadow-blue-700 duration-200 text-white"
                        style={{
                          height: "auto",
                          padding: "20px",
                          minWidth: "250px",
                        }}
                      >
                        <p>{answer.value}</p>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
        {data?.data.adminResponse ? (
          <div>
            <p
              dangerouslySetInnerHTML={{ __html: data?.data.adminResponse }}
              style={{ fontSize: "xx-large", marginTop: "25px" }}
            ></p>
          </div>
        ) : (
          <div>
            <p style={{ fontSize: "xx-large", marginTop: "25px" }}>
              Pending For Results...
            </p>
          </div>
        )}
        {data?.data.course && (
          <Card
            className="course box-shadow"
            sx={{
              marginTop: "25px",
              cursor: "pointer",
              width: { xs: 300, md: 400 },
            }}
            onClick={() => navigate(`/course-details/${data?.data.course.id}`)}
          >
            <div className="course box-shadow">
              <div className="thumbnail">
                <div className="image">
                  <CardMedia
                    component="img"
                    style={{ height: "15rem" }}
                    image={getImageFromServer(data?.data.course.courseImage)}
                    alt={data?.data.course.name}
                  />
                </div>
              </div>
              <div className="info">
                <h3 style={{ paddingLeft: "3px" }} className="title">
                  {data?.data.course.name}
                </h3>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <Rating
                    name="read-only"
                    value={data?.data.course.rating}
                    readOnly
                  />
                  <p>{data?.data.course.rating}</p>
                </div>
              </div>
            </div>
          </Card>
        )}
      </div>
    </div>
  );
};

export default DisplayCaseAnswers;
