import { Stack, Typography } from "@mui/material";
import React from "react";

const ArTermsOfServices = () => {
  return (
    <div className="container">
      <div className="order-lg-2 max-mb-50">
        <Stack gap={3} sx={{ marginTop: "50px", marginBottom: "50px" }}>
          <Typography variant="h2" color="primary">
            شروط الخدمة
          </Typography>
          <Typography variant="h6">مرحباً بكم في آفاق بلا حدود.</Typography>
          <Typography>
            يرجى قراءة شروط الخدمة وسياسة الخصوصية قبل التسجيل لدى الموقع
            الإلكتروني uh-training.com أو استخدام أي جزء من محتويات المنصة
            (ونعني بكلمة "المنصة"، جميع المحتويات والصفحات التي تقع ضمن نطاق
            موقع آفاق بلا حدود الإلكتروني عبر الإنترنت، أو ضمن تطبيقات آفاق بلا
            حدود، أو كلاهما، بما في ذلك الوصول إلى أي من المواد الخاصة بالدورات،
            وغرف الدردشة، أو الخدمات الإلكترونية الأخرى. تعدّ كل من هذه الشروط
            وسياسة الخصوصية بمثابة اتفاقيات (قد يتم الإشارة إليها فيما بعد،
            مجتمعةً، باسم "اتفاقيات") بينك وبين آفاق بلا حدود. كما أنك تقبل
            وتوافق من خلال استخدامك لمنصة آفاق بلا حدود على الالتزام قانوناً
            بالاتفاقيات، سواء كنت مستخدماً مسجلاً على المنصة أم لا. تحتفظ آفاق
            بلا حدود بحق تعديل هذه الشروط في أي وقت دون إشعارك مسبقاً. تعدّ
            التغييرات التي تتم على هذه الشروط نافذةً فور نشرها على هذه الصفحة أو
            ضمن أي قسم آخر من الموقع. يُعد دخولك إلى المنصة بعد إجراء أية
            تغييرات موافقة منك على الشروط المعدّلة وكافة التغييرات الواردة فيه.
            تأكّد من أن تتطلع على هذه الصفحة بشكل دوري لتبقى مطلعاً على الإصدار
            الأحدث لهذه الشروط.
          </Typography>
          <Typography variant="h4" color="primary">
            لمحة عن آفاق بلا حدود
          </Typography>
          <Typography>
            تُقدّم آفاق بلا حدود دورات تدريبية متاحة عبر الإنترنت، من تأليف
            كليات وجامعات وغيرها من المؤسسات (والتي يُشار إليها مجتمعةً بكلمة
            "المؤسسات"، وبشكل مستقل بكلمة "مؤسسة")، والتي تشمل الفرص المتاحة
            للتفاعل بين المدرب والمتدرب، وبين المتدرب مع زملائه المتدربين،
            والتقييم الفردي لعمل المتدرب وشهادة إنجاز ("الدورات") للمتدربين
            الذين يثبتون إتقانهم لموضوعات التدريب.
          </Typography>
          <Typography variant="h4" color="primary">
            قواعد السلوك عبر الإنترنت
          </Typography>
          <Typography>
            أنت توافق على أنك مسؤول بالكامل عن استخدامك للمنصة والمواد التي يتم
            نشرها بواسطة اسم المستخدم الخاص بك، والتي يجب أن تشمل كامل المحتوى
            الذي يتم تقديمه ونشره أو توزيعه على المنصة من قبلك أو من قبل
            مستخدمين آخرين للموقع، بما في ذلك وعلى سبيل المثال لا الحصر، جميع
            المشاركات ضمن المنتدى والتعديلات على الويكي والملاحظات والأسئلة
            والتعليقات ومقاطع الفيديو وتحميلات الملفات ("مشاركات المستخدم"). كما
            أنك توافق على استخدامك للمنصة ملتزماً بالاتفاقيات وجميع القوانين
            المحلية والإقليمية والدولية والقواعد والأنظمة المعمول بها، بما في
            ذلك قوانين حقوق التأليف والنشر، وأية قوانين تتعلّق بنقل البيانات
            الفنية المصدّرة من بلد إقامتك. أنت تتعهّد من خلال هذه الوثيقة، وكشرط
            لاستخدامك للخدمات التي تقدمها آفاق بلا حدود عبر المنصة، أنك لن
            تستخدم المنصة بأي طريقة تهدف إلى الإساءة أو تعطيل أو إعاقة أو إرهاق
            خادم آفاق بلا حدود أو شبكة (شبكات) آفاق بلا حدود المتصلة بأي خادم من
            خوادمها أو التدخّل بأي طرف ثالث والتمتّع بالمنصة. كما لا يجوز لك
            محاولة الوصول غير المصرّح به إلى الموقع، وحسابات أخرى أو أنظمة
            الحاسوب أو شبكات متصلة بأي خادم لآفاق بلا حدود من خلال القرصنة
            واستخراج كلمة المرور أو أي وسيلة أخرى. لا يجوز لك الحصول أو محاولة
            الحصول على أي مواد أو المعلومات المخزنة على المنصة، أو أي من خوادمه
            أو أجهزة الحاسوب المرتبطة به من خلال أي وسيلة لم يتم إتاحتها عن قصد
            من خلال المنصة. توضّح القائمة التالية المواد التي يُمنَع منعاً باتاً
            تداولها على المنصة:
          </Typography>
          <Typography>
            <ul>
              <li>المحتوى الذي يشهّر أو يضايق أو يهدّد الآخرين</li>
              <li>المحتوى الذي يناقش أنشطة غير مشروعة</li>
              <li>
                المحتوى الذي ينتهك الملكية الفكرية لطرف ثالث، بما في ذلك وعلى
                سبيل المثال لا الحصر، حقوق التأليف والنشر أو العلامات التجارية
              </li>
              <li>
                المحتوى الإباحي أو المخلّ بالآداب أو غير اللائق أو غير المصرح به
              </li>
              <li>الإعلان أو أي شكل من أشكال الدعاية التجارية</li>
              <li>المحتويات التي لها علاقة بالأنشطة السياسية الحزبية</li>
              <li>
                الفيروسات، وأحصنة طروادة، والديدان، والقنابل الموقوتة أو ملفات
                تالفة، والبرمجيات الخبيثة وبرامج التجسس أو أي برامج أخرى مماثلة
                قد تلحق الضرر بعمل حاسوب أو ممتلكات أخرى
              </li>
              <li>
                المحتوى الذي يتضمن معلومات غير دقيقة عن قصد أو التي يتم نشرها
                بقصد تضليل الآخرين.
              </li>
            </ul>
          </Typography>
          <Typography>
            علاوة على ذلك، أنت توافق على عدم محاولة جمع معلومات أو تنزيل أي
            محتوى من محتويات المنصة بكميات كبيرة، بما يشمل ولا يقتصر على قائمة
            أو دليل مستخدمي النظام، والكتب الدراسية المتاحة عبر الإنترنت، أو
            منشورات المستخدمين أو معلوماتهم الشخصية. كما أنك توافق على عدم تحريف
            أو محاولة تحريف هويتك أثناء استخدام المنصة. إنّ أي انتهاك لبنود
            قائمة المحظورات المذكورة أعلاه تخوّل آفاق بلا حدود إنهاء استخدامك
            ووصولك و/أو مشاركتك في الموقع دون إشعار مسبق.
          </Typography>
          <Typography variant="h4" color="primary">
            حسابات المستخدمين والصلاحيات
          </Typography>
          <Typography>
            للاستفادة من المشاركة الكاملة في الأنشطة والخِدْمَات التي تقدّمها
            آفاق بلا حدود على المنصة، يجب عليك تزويدنا باسمك وعنوان البريد
            الإلكتروني وكلمة المرور الخاصة بالمستخدم وذلك لإنشاء حساب مستخدم
            جديد (والذي يُشار إليه بـ "حساب المستخدم"). إنك توافق على أنك لن
            تقوم أبداً بإفشاء أو مشاركة دخولك للمنصة أو مشاركة معلومات حساب
            المستخدم الخاص بك مع أي طرف ثالث لأي سبب من الأسباب. أثناء إنشاء
            حساب المستخدم الخاص بك، قد تتم مطالبتك بإدخال معلومات إضافية
            اختيارية (على سبيل المثال، عنوانك). أنت تصرّح أنّ جميع المعلومات
            التي قدمتها هي معلومات دقيقة وحديثة. كما أنك توافق على صيانة وتحديث
            المعلومات الخاصة بك لإبقائها دقيقة وحديثة. نحن نهتم بسرية وأمن
            المعلومات الشخصية الخاصة بك. يرجى الاطلاع على سياسة الخصوصية الخاصة
            بنا للمزيد من المعلومات حول ماهية المعلومات التي قد تجمعها آفاق بلا
            حدود عنك وكيف تستخدم آفاق بلا حدود تلك المعلومات. يُعد دخولك إلى
            المنصة دلالة على أنك توافق على جمع واستخدام المعلومات الخاصة بك عن
            قبل آفاق بلا حدود.
          </Typography>

          <Typography variant="h4" color="primary">
            حقك في استخدام المحتوى الموجود على المنصة
          </Typography>
          <Typography>
            تمّت حماية محتويات المنصة كافة من خلال القانون السوري لحماية حقوق
            التأليف والنشر رقم 00 لعام 0000، ما لم يرد بأنه ملكية عامة. إنّ جميع
            النصوص والامتحانات ومقاطع الفيديو والصور وغيرها من المواد التعليمية
            المتوفرة مع الدورات المقدّمة في هذه المنصة، هي للاستخدام الشخصي
            الخاص بك والمتعلّق بتلك الدورات فقط، ما لم يرد خلاف ذلك صراحةً ضمن
            المنصة. يخضع استخدام بعض الوثائق المرجعية والكتب الدراسية الرقمية
            والمقالات وغيرها من المعلومات على المنصة لإذن من قبل أطراف ثالثة، إذ
            يخضع استخدام هذه المعلومات لقواعد وشروط معينة سيتم نشرها جنباً إلى
            جنب مع هذه المعلومات. إنك تتعهّد بموجب هذه الوثيقة، وباستخدامك لهذا
            المنصة، على الالتزام بجميع هذه القواعد والشروط. كما أنك توافق على
            الحفاظ على جميع حقوق التأليف والنشر والإشعارات الأخرى التي تخص أي
            محتوى تحصل عليه من خلال المنصة. جميع حقوق هذه المنصة ومحتواها محفوظة
            من قِبل شركة آفاق بلا حدود للتدريب والاستشارات المحدودة المسؤولية أو
            تحت ترخيص ممنوح لشركة آفاق بلا حدود.
          </Typography>
          <Typography variant="h4" color="primary">
            منشورات المستخدم
          </Typography>

          <Typography>
            ضمانات منشورات المستخدم: على الرغم من قائمة المواد التي يُمنع منعاً
            باتاً تداولها على المنصة كما هو مبين أعلاه، والتي تتعلّق بتقديم أو
            توزيع منشورات المستخدم الخاصة بك، إلا أنك تؤكد، وتمثّل وتضمن (أ) أن
            يكون لديك الحقوق اللازمة والتراخيص والموافقات و/أو الأذونات لإعادة
            نشر منشورات المستخدم على الموقع والسماح لآفاق بلا حدود ومستخدميها
            بإعادة إنتاج وتعديل ونشر وما إلى ذلك من استخدام وتوزيع منشورات
            المستخدم الخاصة بك بطريقة تتفق مع التراخيص التي تُمنح لك في ظل هذه
            الشروط، و(ب) أنك لن تعمد إلى خرق أو انتهاك حقوق أي طرف ثالث من خلال
            تقديمك لمنشورات المستخدم الخاصة بك أو استخدامك للتراخيص الممنوحة
            أدناه. تتحمّل أنت وحدك، وليس آفاق بلا حدود، المسؤولية عن منشورات
            المستخدم الخاصة بك وعواقب مشاركتها مع الآخرين أو نشرها على المنصة.
            منح ترخيص لآفاق بلا حدود: إنك بموجب ما تقدّمه أو توزعه من منشورات
            المستخدم الخاصة بك تمنح آفاق بلا حدود في جميع أنحاء العالم حقاً غير
            حصري، وقابل للنقل، والتعيين، والترخيص الفرعي، ومدفوع بالكامل، ودون
            أي مقابل، ودائم وغير مسترد رخصةً لاستضافة ونقل وعرض وأداء وإعادة
            إنتاج وتعديل وتوزيع وإعادة توزيع وإعادة ترخيص وخلاف ذلك الاستخدام،
            وتوفير واستغلال منشورات المستخدم الخاصة بك، كلياً أو جزئياً، في أي
            شكل وبأي صيغة إعلامية، ومن خلال أي قنوات إعلامية (المعروفة الآن أو
            التي سيتم تأسيسها فيما بعد). منح الترخيص إلى المستخدمين: أنت تمنح
            بموجب ما تقوم بتقديمه أو ما توزّعه من منشورات المستخدم الخاصة بك،
            جميعَ مستخدمي المنصة رخصة غير حصرية للوصول إلى واستخدام منشورات
            المستخدم الخاصة بك فيما يتعلق باستخدامهم للمنصة ولأغراض شخصية خاصة
            بهم.
          </Typography>
          <Typography variant="h4" color="primary">
            الشهادات وما إلى ذلك
          </Typography>
          <Typography>
            قد تقدّم المؤسسات التي توفّر الدورات على المنصة شهادة إتمام للدورة
            أو إقرار آخر (والذي يُشار إليه باسم "شهادة") للمتدربين الذين، وحسب
            تقديرهم، قد أبدو تمكناً من مواد الدورات ذات الصلة. سيتم إصدار
            الشهادات من قبل آفاق بلا حدود تحت اسم المؤسسة ذات الصلة تبعاً لمنشأ
            الدورة. سيكون اتخاذ قرار بخصوص ما إذا كان سيتم منح شهادة للمتدرب
            الذي أكمل الدورة، عائداً فقط إلى تقدير المؤسسة المانحة. قد تختار
            آفاق بلا حدود و/أو أي من المؤسسات التي تقدّم دورات على المنصة،
            وتبعاً لتقديرها فقط، عدم تقديم شهادة فيما يتعلق ببعض الدورات. لا
            تسجيل آخر: لن تتمكن أثناء إتمامك لدورة عبر موقع آفاق بلا حدود، من
            تقديم طلب قبول أو التسجيل بأي برنامج لنيل درجة علمية في أي من
            المؤسسات التي تؤلّف الدورات وذلك نتيجة لتسجيل أو استكمال دورة
            تقدّمها هذه المؤسسات من خلال آفاق بلا حدود على المنصة. لا يحق لك
            استخدام أي من موارد المؤسسة خارج نطاق الدورات المقدّمة على المنصة،
            ولن تكون مؤهلاً للحصول على امتيازات أو المزايا المقدّمة للمتدربين
            المسجلين في برامج لنيل درجة لدى المؤسسات.
          </Typography>
          <Typography variant="h4" color="primary">
            العلامات التجارية
          </Typography>
          <Typography>
            استخدام آفاق بلا حدود، وغيرها من أسماء المؤسسات المشاركة والعلامات
            التجارية: إنّ اسم وشعار " آفاق بلا حدود" هما علامتان تجاريتان
            تابعتان لشركة آفاق بلا حدود للتدريب والاستشارات. وبالمثل، فإنّ
            الأسماء والشعارات، والأختام الخاصة بالمؤسسات المشاركة الأخرى هي
            علامات تجارية مملوكة من قبل المؤسسة المعنية. لا يجوز لك استخدام أي
            من علامات آفاق بلا حدود التجارية أو أي من العلامات التجارية الخاصة
            بالمؤسسات، أو ما يشبه أياً من المذكور، دون موافقة خطية مسبقة من صاحب
            العلامة التجارية ذات الصلة. لا يجوز لك استخدام أي من العلامات
            التجارية الخاصة بالمؤسسات، أو ما يشبه أياً من المذكور، لأغراض
            ترويجية، أو بأي شكل قد يدّعي أو يوحي، عمداً أو سهواً، أو يظهر أو
            يعطي انطباعاً بوجود علاقة أو تأييد من قبل هذه المؤسسات وذلك تبعاً
            لتقدير المؤسسة المعنية وحدها. لا تعود ملكية أي من العلامات التجارية
            إلى آفاق بلا حدود أو غيرها من المؤسسات المشاركة التي تظهر على المنصة
            أو في أو من خلال الخدمات المتاحة على المنصة أو من خلاله، فهي إن
            وجدت، ملكية حصرية لأصحابها. لا ينبغي أن يُفسّر أي محتوى من المحتويات
            الواردة ضمن هذه المنصة على أنه يمنح، ضمناً أو قانوناً أو خلاف ذلك،
            أي ترخيص أو حق لاستخدام أي علامة تجارية معروضة على المنصة دون الحصول
            على إذن خطي من صاحب العلامة التجارية ذات الصلة.
          </Typography>
          <Typography variant="h4" color="primary">
            التنازل عن الضمان / حدود المسؤوليات
          </Typography>
          <Typography fontWeight={600}>
            إنّ المنصة وأيّة معلومات أو محتويات أو خدمات متوفرة على أو من خلال
            المنصة مقدمة "كما هي" و "كما هي متوفرة" دون ضمان من أي نوع (صريح أو
            ضمني أو غير ذلك)، بما في ذلك، وعلى سبيل المثال لا الحصر، أي ضمانات
            ضمنية للرواج والملاءمة لغرض معين وعدم مخالفتها، فيما عدا الضمانات
            الشبيهة لهذه والتي قد لا يجوز التنازل عنها بموجب القوانين المعمول
            بها. لا تضمن آفاق بلا حدود والمشاركون بها (كما هم معرّفون أدناه) عمل
            المنصة دون انقطاع أو بشكل خال من الأخطاء، وأنّ المنصة خالية من
            الفيروسات أو المكونات الضارة الأخرى، أو أنّ الدورات أو المحتوى
            المقدّم من شأنه تلبية احتياجاتك أو توقعاتك. كما لا تضمن آفاق بلا
            حدود أو أي من المشاركين فيها دقة أو كمال ودوام أو جودة المنصة أو
            الدورات أو المحتوى أو استمرارية إتاحة أي من الدورات التعليمية أو أي
            محتوى معين. ويُقصد بـ "مشاركي آفاق بلا حدود" مؤسسة ..... ومعهد
            ....... وجامعة ...... ومؤسسات مشاركة أخرى والكيانات التي تعمل على
            تقديم المعلومات والمحتوى أو الخدمات للمنصة والمدرّبين وجميع العاملين
            والموظفين والمدرسين المساعدين. يقع استخدام هذه المنصة، والمحتوى
            والخدمات التي يتم الحصول عليها من أو عبر المنصة على مسؤوليتك الخاصة.
            كما أنّ دخولك إلى أو تنزيلك لأي من المعلومات أو المواد أو البيانات
            المتاحة من خلال المنصة أو أي من المواقع المرجعية يقع تحت ضمانك
            ومجازفتك وستكون وحدك مسؤولاً عن أي ضرر يلحق بالممتلكات الخاصة بك
            (بما في ذلك نظام الحاسوب الخاص بك) أو أي فقدان للبيانات ينتج عن
            تنزيل أو استخدام هذه المواد أو البيانات، ما لم يتم التصريح عن خلاف
            ذلك ضمن سياسة الخصوصية الخاصة بآفاق بلا حدود.
          </Typography>
          <Typography>
            التنازل المتعلّق بمنشورات المستخدم: عليك أن تدرك أنك ستتعرض أثناء
            استخدامك للمنصة لمنشورات مستخدمين متنوعة المصادر والتي لا تعتبر آفاق
            بلا حدود أو أي من مشاركيها مسؤولة عن دقتها وفائدتها وموثوقيتها أو
            حقوق الملكية الفكرية المرتبطة بمنشورات المستخدمين هذه. كما أنه يجب
            عليك أن تفهم وتقرّ بأنك قد تتعرض لمنشورات مستخدمين غير دقيقة وهجومية
            وتشهيرية أو غير لائقة أو غير مرغوب فيها وأنك توافق على التنازل، وأنك
            تتنازل بموجب هذه الوثيقة عن أية حقوق قانونية أو منصفة أو تعويضات لك
            أو قد تكون ضد آفاق بلا حدود أو أي من مشاركي آفاق بلا حدود فيما يتعلق
            بذلك. لا تصادق آفاق بلا حدود ولا أي من مشاركي آفاق بلا حدود على أي
            من منشورات المستخدمين أو أي رأي أو توصية أو مشورة يتم الإعراب عنها
            ضمن المنصة. لا تلتزم آفاق بلا حدود ولا أي من مشاركي آفاق بلا حدود
            بمراقبة أي من منشورات المستخدمين أو أي تواصل يتم مع أعضاء آخرين ضمن
            المنصة. مع ذلك، تحتفظ آفاق بلا حدود بحق مراجعة منشورات المستخدمين
            والتصرّف وفقاً لتقديرها المطلق لتعديل أو إزالة، كلياً أو جزئياً، أي
            من مشاركات المستخدمين في أي وقت ولأي سبب من الأسباب، أو السماح
            لمشاركي آفاق بلا حدود بالقيام بذلك في أي وقت ولأي سبب من الأسباب.
            ولا يقتصر الأمر على ذلك، فقد تقوم آفاق بلا حدود بالتحقيق في مزاعم
            وجود منشور لمستخدم لا يتوافق مع شروط آفاق بلا حدود لتقديم الخدمة
            وذلك عند استلام إشعار من مستخدم أو مالك المحتوى وتحدّد، وفقاً
            لتقديرها المطلق، سواء ستقوم بتعديل أو إزالة، كلياً أو جزئياً،
            منشورات المستخدم غير المطابقة لشروطها في أي وقت تختاره ودون سابق
            إنذار. روابط لمواقع أخرى: قد تتضمن المنصة وصلات إلى مواقع أخرى يتم
            صيانتها وإدارتها من قبل أشخاص آخرين. ليست آفاق بلا حدود أو أي من
            مشاركي آفاق بلا حدود مسؤولة عن مسح أو الموافقة على أو المراجعة أو
            المصادقة على المحتويات أو استخدام أي من المنتجات أو الخِدْمَات التي
            يمكن أن تقدمها هذه المواقع الأخرى. إذا قررت الوصول إلى مواقع مرتبطة
            بأطراف ثالثة فإنك تقوم بذلك على مسؤوليتك الخاصة.
          </Typography>
          <Typography fontWeight={600}>
            أنت توافق ولأقصى حد تسمح به القوانين المعمول بها على عدم مسؤولية
            آفاق بلا حدود أو أي من مشاركي آفاق بلا حدود عن أية خسارة أو ضرر،
            سواء كان حقيقياً أو مرتبطاً منطقياً أو ناشئاً عن أو متعلقاً بشروط
            تقديم الخدمة هذه، أو استخدامك (أو استخدام أي طرف ثالث) أو عدم القدرة
            على استخدام المنصة، أو مواضع المحتوى على المنصة، أو اعتمادك الخاص بك
            على المعلومات التي يتم الحصول عليها من أو عبر المنصة، سواء اعتمدت
            مطالبتك على العقد أو الضرر أو القانون أو غير ذلك. على وجه الخصوص،
            وإلى أقصى حد يسمح به القانون المعمول به، لن تتحمّل آفاق بلا حدود أو
            أي من مشاركيها مسؤولية أية ضرر سواء مباشر أو غير مباشر أو عقابي أو
            أضرار خاصة أو تحذيرية أو عرضية، سواء كانت منظورة أو غير متوقعة.
            وسواء كانت آفاق بلا حدود أو أي من مشاركيها قد تهاونت أو أخطأت (بما
            في ذلك، وعلى سبيل المثال لا الحصر، المطالبات بتهمة التشهير والأخطاء
            وخسارة الأرباح أو فقدان البيانات أو انقطاع في توافر البيانات).
          </Typography>
          <Typography variant="h4" color="primary">
            التعويض
          </Typography>
          <Typography>
            أنت توافق على الدفاع وعدم الإضرار بآفاق بلا حدود أو بمشاركي آفاق بلا
            حدود والشركات التابعة لكل منها وفروعها وموظفيها وأعضاء هيئة التدريس
            والمتدربين والزملاء، وأعضاء مجلس الإدارة المسؤولين والوكلاء
            والموظفين من وضد أي مطالبات من طرف ثالث أو إجراءات أو مطالب ناشئة من
            أو ناتجة عن أو بأي شكل من الأشكال ذات الصلة باستخدامك للمنصة، بما في
            ذلك أية مسؤولية أو مصاريف تنشأ من أي وجميع المطالبات والخسائر
            والأضرار والدعاوى والأحكام وتكاليف التقاضي (الفعلية والتابعة) وأتعاب
            المحاماة، من كل نوع وطبيعة. توفر لك آفاق بلا حدود أو أي من مشاركي
            آفاق بلا حدود، في مثل هذه الحالة وحسب تقديرها المطلق، إشعاراً خطياً
            أو شفهياً لمطالبة أو دعوى أو إجراء مماثل.
          </Typography>
          <Typography variant="h4" color="primary">
            مزودو الخدمة الخارجيين
          </Typography>
          <Typography>
            تستخدم آفاق بلا حدود بعض الخِدْمَات المزودة من قبل مزودين خارجيين.
            عند موافقتك على شروط الخدمة، فإنك أيضاً تقر بموافقتك على سياسات
            وشروط مزودي الخِدْمَات التاليين:
            <ul>
              <li>
                <a href="https://www.youtube.com/t/terms" target="_blank">
                  YouTube Terms of Service{" "}
                </a>
              </li>
              <li>
                <a href="https://policies.google.com/privacy" target="_blank">
                  Google Privacy Policy
                </a>
              </li>
            </ul>
          </Typography>
          <Typography variant="h4" color="primary">
            متفرقات
          </Typography>
          <Typography>
            حقوق الإنهاء؛ أو انقطاع الدورات والمحتوى: يجب أن توافق على السماح
            لآفاق بلا حدود بإنهاء استخدامك للموقع أو مشاركتك فيه وفقا لتقديرها
            المطلق ولأي سبب من الأسباب أو دون سبب بناءً على إخطارك بشكل مسبق.
            يُعد فصل مستخدمي المنصة الذين ينتهكون حقوق النشر بشكل متكرر سياسة
            لآفاق بلا حدود، وذلك تبعاً للظروف التي تراها مناسبة. تحتفظ آفاق بلا
            حدود ومشاركو آفاق بلا حدود بحق إلغاء أو تأخير أو إعادة جدولة تغير
            هيكلية أي دورة مقدّمة من خلال آفاق بلا حدود أو التوقف عن تقديم أي
            جزء أو كامل محتويات المنصة أو الخدمات ذات الصلة في أي وقت وذلك تبعاً
            لتقديرها الخاص، وأنت توافق على عدم مسؤولية آفاق بلا حدود أو أي من
            مشاركي آفاق بلا حدود تجاهك عن مثل هذا العمل. يمكنك إنهاء اشتراكك في
            المنصة في أي وقت إذا لم تعد راغباً في ذلك. ستنتهي الحقوق الممنوحة لك
            بموجب هذه الاتفاقية تبعاً لإنهاء حقك في استخدام المنصة، ولكن سيستمر
            العمل بالأحكام الأخرى لشروط الخدمة هذه. كامل الاتفاقية: تشكّل جميع
            هذه الشروط، وميثاق الشرف، وسياسة الخصوصية مُجمل الاتفاق بينك وبين
            آفاق بلا حدود فيما يتعلق باستخدامك للمنصة، وتحل محل أية اتفاقيات
            سابقة بينك وبين آفاق بلا حدود بخصوص استخدامك للمنصة. التنازل وقابلية
            فصل شروط الخدمة: لا يشكّل فشل آفاق بلا حدود في ممارسة أو تنفيذ أي حق
            أو شرط من شروط الخدمة تنازلاً عن هذا الحق أو الحكم. إذا وجدت محكمة
            قضائية مختصة بطلان شرط من شروط الخدمة هذه ورغم اتفاق أطراف شروط
            الخدمة على وجوب سعي المحكمة لتفعيل دور نوايا الأطراف كما هو مبين في
            الحكم؛ تظل الأحكام الأخرى لهذه الشروط قائمة وسارية بشكل كامل. اختيار
            خيار القانون/المحكمة: يجب أن توافق على أنّ هذه الشروط وأي مطالبة أو
            نزاع ينشأ عنها أو يتعلق بها أو أي محتوى أو خدمة يمكن الحصول عليها من
            أو من خلال الموقع سيخضع للقوانين المعمول بها في الجمهورية العربية
            السورية. يجب أن توافق على سماع وحلّ جميع هذه المطالبات والمنازعات في
            محاكم دمشق. كما يجب أن تُقرّ بصلاحيات محاكم الأحوال الشخصية المذكورة
            المطبّقة عليك لهذا الغرض، وأن تتنازل وتوافق على عدم الادعاء أي
            الاعتراض على هذه الإجراءات المتّبعة في المحاكم المذكورة (بما في ذلك
            أي دفاع أو اعتراض على الافتقار للصلاحيات المناسبة أو المكان أو
            الإزعاج في المحكمة). يُعتبر هذا الميثاق ساري المفعول بدءاً من تاريخ:
            13 آذار 2024
          </Typography>
          <Typography variant="h4" color="primary">
            سياسة التعاون
          </Typography>
          <Typography>
            من خلال تسجيلك في دورات آفاق بلا حدود، فإنك تنضم إلى مجتمع خاص من
            المتعلمين. إن طموح آفاق بلا حدود هو إلهام العظمة من أجل إنشاء مجتمع
            متمكن وصاحب مهارات وعقلية جديدة. تطمح آفاق بلا حدود إلى تحقيق رؤيتها
            من خلال تقديم تدريب عالي الجودة. تعتمد آفاق غير محدودة على دوافعك
            لتعلم المواد الموجودة في الدورات التدريبية والقيام بذلك بأمانة. من
            أجل المشاركة في آفاق بلا حدود يجب عليك الموافقة على تعهد ميثاق الشرف
            أدناه وأي شروط إضافية خاصة بالدورة التدريبية. سيتم نشر ميثاق الشرف
            هذا وأي شروط إضافية في محتوى كل دورة.
          </Typography>
          <Typography variant="h4" color="primary">
            ميثاق الشرف لآفاق بلا حدود
          </Typography>
          <Typography>
            من خلال التسجيل في دورات آفاق بلا حدود، أوافق على أنني سأقوم بما
            يلي:
            <ul>
              <li>
                إكمال كافة الاختبارات النصفية والنهائية بعملي الخاص وعملي الخاص
                فقط. لن أعرض عمل أي شخص آخر.
              </li>
              <li>
                الاحتفاظ بحساب مستخدم واحد فقط وعدم السماح لأي شخص آخر باستخدام
                اسم المستخدم و/أو كلمة المرور الخاصة بي.
              </li>
              <li>
                عدم المشاركة في أي نشاط من شأنه تحسين نتائجي بشكل غير أمين، أو
                تحسين نتائج الآخرين أو الإضرار بها.
              </li>
              <li>
                عدم نشر إجابات للأسئلة التي يتم استخدامها لتقييم أداء المتدربين.
              </li>
              <li>
                ما لم يشير مدرب دورة آفاق بلا حدود إلى خلاف ذلك، يتم تشجيع
                المتعلمين في دورات آفاق بلا حدود على القيام بما يلي:
                <ul>
                  <li>
                    التعاون مع الآخرين في فيديوهات المحاضرات والتمارين والواجبات
                    المنزلية والمختبرات.
                  </li>
                  <li>مناقشة المواد والمفاهيم العامة في كل دورة مع الآخرين.</li>
                  <li>
                    تقديم الأفكار والأعمال المكتوبة لزملائك المتدربين في برامج
                    آفاق بلا حدود أو غيرهم للتعليق أو النقد.
                  </li>
                </ul>
              </li>
            </ul>
          </Typography>
          <Typography variant="h4" color="primary">
            تغيير ميثاق الشرف
          </Typography>
          <Typography>
            يرجى ملاحظة أننا نقوم بمراجعة ميثاق الشرف هذا وقد نقوم بإجراء
            تغييرات عليه من وقت لآخر. أي تغييرات على ميثاق الشرف هذا ستكون سارية
            فور نشرها على هذه الصفحة. من خلال الوصول إلى المنصة بعد إجراء أي
            تغييرات، فإنك تشير إلى موافقتك على أساس مستقبلي على ميثاق الشرف
            المعدل وأي تغييرات واردة فيه. تأكد من العودة إلى هذه الصفحة بشكل
            دوري للتأكد من معرفتك بأحدث إصدار من ميثاق الشرف هذا.
          </Typography>
        </Stack>
      </div>
    </div>
  );
};

export default ArTermsOfServices;
