import { getBasicDate, getImageFromServer } from "./../../assets/JS/helpers";
import { Link } from "react-router-dom";
import AddToCart from "../Common/add-to-cart";
import { useTranslation } from "react-i18next";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Stack,
  Typography,
} from "@mui/material";
import AddToWishlist from "./add-to-wishlist";
import { shopItemResponse } from "../../interfaces/new-api-interfaces/shop-item";
import i18n from "i18next";
import { defaultTranslation } from "../../untilites/functions";

interface Props extends shopItemResponse {
  refetch?: Function;
}

const ShopItem: React.FC<Props> = ({
  in_cart,
  in_wishlist,
  is_bought,
  id,
  translations,
  image,
  price,
  refetch,
  types,
}) => {
  const { t } = useTranslation();
  return (
    <Link
      to={`/shop-item/${id}`}
      data-aos="fade-up"
      style={{ maxWidth: "300px" }}
      className="col-10 col-md-6 col-lg-3 my-2"
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {!is_bought && (
          <Box sx={{ position: "absolute", top: 10, left: 20 }}>
            <AddToWishlist id={id} inWishlist={in_wishlist} />
          </Box>
        )}
        {types && (
          <Stack
            sx={{ position: "absolute", top: 10, right: 20 }}
            flexDirection={"row"}
            gap={1}
            alignItems={"center"}
          >
            {types?.map((type, i) => (
              <Chip
                key={i}
                label={defaultTranslation(type, "name")}
                color="primary"
                variant="filled"
              />
            ))}
          </Stack>
        )}
        <CardMedia
          sx={{ height: 250, width: "100%" }}
          image={getImageFromServer(image)}
          title={translations[i18n.language].name}
        />
        <CardContent
          className="shop-card-content"
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            bgcolor: "#F8F8F8",
            height: 200,
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Typography variant="h5">
              {translations[i18n.language].name}
            </Typography>
            <Typography>
              {translations[i18n.language].description.slice(0, 30)}
              {translations[i18n.language].description.length > 30 ? "..." : ""}
            </Typography>
          </Box>
          <CardActions
            sx={{
              width: "100%",
              justifyContent: "space-around",
              padding: 0,
            }}
          >
            <Box
              sx={{
                bgcolor: price > 0 ? "mypurple.main" : "myorange.main",
                borderRadius: "20px",
                padding: "5px 10px",
              }}
            >
              {price > 0 ? (
                <Typography
                  fontWeight={600}
                  className="price"
                  fontSize="small"
                >
                  {`${price?.toLocaleString()} ${t("syp")}`}
                </Typography>
              ) : (
                <Typography
                  fontWeight={600}
                  className="price"
                  fontSize="small"
                >
                  {t("GlobalFree")}
                </Typography>
              )}
            </Box>
            <AddToCart
              id={id}
              inCart={in_cart}
              isBought={is_bought}
              type={"shopitem"}
              padding="5px 10px"
              refetch={refetch}
            />
          </CardActions>
        </CardContent>
      </Card>
    </Link>
  );
};

export default ShopItem;
