import { useMutation, useQuery } from "@tanstack/react-query";
import { useSetAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { fetchApi } from "../../api/api";
import "./index.css";
import { roleAtom, tokenAtom, userAtom, userIdAtom } from "../../store";
import i18n from "i18next";
import { toast } from "react-toastify";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { register } from "../../interfaces/new-api-interfaces/auth";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import bg from "../../assets/images/profile/profile-bg.jpg";
import { MuiPhone } from "../../components/Common/mui-phone";
import { defaultTranslation, handleFormError } from "../../untilites/functions";
import { addConsultationRequest } from "../../interfaces/new-api-interfaces/consultations";
import {
  fetchInstructors,
  fetchStudentsConsultation,
} from "../../api/endpoints-functions";
import i18next from "i18next";

interface Props {}

const inputStyle = {
  width: { xs: "100%", sm: "45%" },
  marginBottom: "30px",
};

const StudentTextConsultationDetails: React.FC<Props> = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    data: consultation,
    isLoading: l1,
    isError: e1,
  } = useQuery(["get-consultation", id], () => fetchStudentsConsultation(id!));

  const { t } = useTranslation();

  return (
    <Box
      sx={{
        backgroundImage: `url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPositionX: "right",
      }}
    >
      <div
        style={{
          direction: `${i18n.language === "en" ? "ltr" : "rtl"}`,
          padding: "5vh 0",
        }}
      >
        <Box
          className="login-form-wrapper mt-sm-50 mt-xs-50"
          sx={{
            direction: `${i18n.language === "en" ? "ltr" : "rtl"}`,
            width: { xs: "90%", md: "70%" },
            borderRadius: { xs: "10px", md: "50px" },
          }}
        >
          <Box
            style={{
              direction: `${i18n.language === "en" ? "ltr" : "rtl"}`,
              position: "relative",
              paddingTop: "50px",
              gap: "2%",
            }}
            className="register-form d-flex flex-wrap justify-content-center"
          >
            {!l1 && (
              <>
                {!consultation?.is_answered && (
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "92%" },
                      marginBottom: "30px",
                    }}
                  >
                    <Typography sx={{ textAlign: "start" }} color="orange">
                      {i18next.language == "en"
                        ? "Pending For Trainer's Answer"
                        : "بانتظار إجابة المدرب"}
                    </Typography>
                  </Box>
                )}

                <Box
                  sx={{
                    width: { xs: "100%", sm: "92%" },
                    marginBottom: "30px",
                  }}
                >
                  {!consultation?.instructor_id ? (
                    <FormControlLabel
                      sx={{
                        marginBottom: !consultation?.instructor_id
                          ? "10px"
                          : "0px",
                      }}
                      control={
                        <Checkbox checked={true} color="primary" disabled />
                      }
                      label={
                        i18next.language === "en"
                          ? "General Consultation"
                          : "استشارة عامة"
                      }
                    />
                  ) : (
                    <></>
                  )}
                  {consultation?.instructor_id && (
                    <TextField
                      value={
                        defaultTranslation(
                          consultation.instructor,
                          "first_name"
                        ) +
                        " " +
                        defaultTranslation(consultation.instructor, "last_name")
                      }
                      label={i18n.language === "en" ? "Trainer" : "المدرب"}
                      disabled
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                </Box>
                <TextField
                  multiline
                  value={consultation?.question}
                  InputProps={{ readOnly: true }}
                  label={t("consultationContent")}
                  sx={{
                    width: { xs: "100%", sm: "92%" },
                    marginBottom: "30px",
                  }}
                />
                {consultation?.consultation_answer && (
                  <>
                    <Box
                      sx={{
                        width: { xs: "100%", sm: "92%" },
                        marginBottom: "10px",
                      }}
                    >
                      <Typography sx={{ textAlign: "start" }} color="green">
                        {i18next.language == "en"
                          ? `Trainer ${defaultTranslation(
                              consultation.instructor,
                              "first_name"
                            )}
                            
                            ${defaultTranslation(
                              consultation.instructor,
                              "last_name"
                            )} Answered your Question`
                          : `المدرب ${defaultTranslation(
                              consultation.instructor,
                              "first_name"
                            )}
                          
                          ${defaultTranslation(
                            consultation.instructor,
                            "last_name"
                          )} أجاب على سؤالك`}
                      </Typography>
                    </Box>
                    <TextField
                      multiline
                      value={consultation?.consultation_answer?.answer}
                      InputProps={{ readOnly: true }}
                      label={t("trainerAnswer")}
                      sx={{
                        width: { xs: "100%", sm: "92%" },
                        marginBottom: "10px",
                      }}
                    />
                  </>
                )}
              </>
            )}

            {l1 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  minHeight: "500px",
                  paddingBottom: "150px",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Box>
        </Box>
      </div>
    </Box>
  );
};

export default StudentTextConsultationDetails;
