import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchApi } from "../../api/api";
import { LoadingPage } from "../../components/Common/loadingPage.component";
import CorrectWrittenTest from "../../components/InstructorDashboard/TestConrrection/correct-written-test";
import Button from "@mui/material/Button";
import DisplayMatchAnswer from "../../components/Test/display-match";
import { Box, Stack, Typography } from "@mui/material";
import { fetchCourseTestsToCorrect } from "../../api/endpoints-functions";
import { ErrorFallBackNotFound } from "../../components/Common/error-handling/error-404";
import { defaultTranslation } from "../../untilites/functions";
import i18next from "i18next";
import { toast } from "react-toastify";
import DisplayOptionalAnswers from "../../components/Test/display-optional-answers";
import TestCorrectionFeedback from "../../components/Test/test-correction-feedback";

interface Props {}

const CourseTestCorrection: React.FC<Props> = () => {
  const { studentId, testId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    isLoading: l1,
    isError: e1,
    data,
  } = useQuery(["get-global-test", studentId], () =>
    fetchCourseTestsToCorrect(studentId!, testId!)
  );

  const [feedback, setFeedback] = useState("");

  useEffect(() => {
    if (data) {
      setFeedback(data.notes);
    }
  }, [data]);

  const { mutate, isLoading: mutationLoading } = useMutation(
    ({ id, is_true, mark }: { id: number; is_true: boolean; mark: number }) => {
      const data: any = {
        is_true: is_true,
        test_type: "test",
        test_id: testId,
        user_id: studentId,
        answer_mark: mark,
      };
      return fetchApi(
        "/test/writtenAnswer/correctWrittenAnswer/" + id,
        "PUT",
        data
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-global-test", studentId]);
        toast.success(
          i18next.language === "en"
            ? "Question Corrected successfully"
            : "تم تصحيح السؤال بنجاح"
        );
      },
      onError: (error: any) => {
        toast.error(
          i18next.language === "en"
            ? "Something went wrong, please try again later."
            : "حدث خطأ ما، يرجى المحاولة مرة أخرى لاحقًا."
        );
      },
    }
  );

  const handleSetResult = (id: number, is_true: boolean, mark: number) => {
    mutate({ id, is_true, mark });
  };

  if (e1) return <ErrorFallBackNotFound />;
  if (l1) return <LoadingPage />;
  return (
    <section
      className="container"
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        paddingBottom: "50px",
      }}
    >
      {data?.answers.length > 0 && (
        <div className="p-10" style={{ width: "100%" }}>
          <div className="d-flex justify-content-center align-items-center my-5 container mx-auto">
            <Typography variant="h4" color="primary">
              {i18next.language == "en"
                ? "Test Correction :"
                : "تصحيح الاختبار :"}{" "}
              {data?.Test_name && defaultTranslation(data?.Test_name, "name")}
            </Typography>
          </div>
          {data.is_graded && (
            <Stack direction={"column"} gap="6px" marginBottom={"1rem"}>
              <Typography variant="h4" color="primary">
                {i18next.language === "ar"
                  ? "العلامة الكلية: "
                  : "Total Mark: "}
                {data.total_mark} {i18next.language === "en" ? "Marks" : "درجة"}{" "}
              </Typography>
              {data.is_checked && (
                <Typography variant="h5" color="primary">
                  {i18next.language === "ar"
                    ? "العلامة المستحقة: "
                    : "Obtained Mark: "}{" "}
                  {data?.mark} /{" "}
                  {i18next.language === "ar"
                    ? data.evaluations.ar.text
                    : data.evaluations.en.text}
                </Typography>
              )}
            </Stack>
          )}
          <Box sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
            {data.answers?.map((question, index: number) => (
              <Box sx={{ border: "solid 1px #ccc" }} key={question.id}>
                <Box sx={{ bgcolor: "mypurple2.main", padding: "20px" }}>
                  <Typography variant="h5" color="#fff">
                    {i18next.language == "en"
                      ? `# ${question.order} Question`
                      : `السؤال # ${question.order}`}
                  </Typography>
                </Box>
                {question.type == "written" ? (
                  <CorrectWrittenTest
                    setResult={handleSetResult}
                    value={question.written_answers[0].value}
                    isTrue={question.written_answers[0].is_true}
                    id={question.written_answers[0].id}
                    question={question}
                    isLoading={mutationLoading}
                    isGraded={data.is_graded}
                  />
                ) : question.type == "match" ? (
                  <DisplayMatchAnswer
                    isGraded={data.is_graded}
                    question={question}
                  />
                ) : (
                  <div>
                    <DisplayOptionalAnswers
                      isGraded={data.is_graded}
                      question={question}
                    />
                  </div>
                )}
              </Box>
            ))}
            {data.is_graded == 0 && (
              <Box
                sx={{
                  border: "solid 1px #ccc",
                  marginTop: "1rem",
                  padding: "20px",
                  backgroundColor: "#FBFBFB",
                }}
              >
                <TestCorrectionFeedback
                  userId={+studentId!}
                  testId={+testId!}
                  type="test"
                  value={feedback}
                  setValue={setFeedback}
                  hasFeedback={!!data.notes}
                />
              </Box>
            )}
          </Box>
          <div className=" flex flex-row-reverse mt-4 w-3/6 m-auto">
            <Button
              onClick={() => {
                navigate(`/course-test-list/${testId}`);
              }}
              variant="contained"
              className="w-1/6 m-auto"
            >
              Finish
            </Button>
          </div>
        </div>
      )}
      {data.answers.length == 0 && <ErrorFallBackNotFound />}
    </section>
  );
};

export default CourseTestCorrection;
