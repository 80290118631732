import React from "react";
import { useNavigate } from "react-router-dom";
import { getBasicDate, getImageFromServer } from "../../assets/JS/helpers";
import { TableRow, TableCell } from "@mui/material";
import { defaultTranslation } from "../../untilites/functions";
import { StudentShopItemResponse } from "../../interfaces/new-api-interfaces/profile";

type Props = {
  item: StudentShopItemResponse;
};

const ShopItemTable: React.FC<Props> = ({ item }) => {
  const navigate = useNavigate();

  return (
    <TableRow
      onClick={() => navigate(`/shop-item/${item.id}`)}
      style={{ cursor: "pointer" }}
    >
      <TableCell className="pro-thumbnail">
        <img
          style={{ width: "100px" }}
          src={getImageFromServer(item.image)}
          alt="Product"
        />
      </TableCell>
      <TableCell
        className="pro-title"
        onClick={() => navigate(`/shop-item/${item.id}`)}
      >
        {defaultTranslation(item, "name")}
      </TableCell>
      <TableCell
        className="pro-price"
        onClick={() => navigate(`/shop-item/${item.id}`)}
      >
        <span>{getBasicDate(item.created_at)}</span>
      </TableCell>
    </TableRow>
  );
};

export default ShopItemTable;
