import React from "react";
import { ShopResponse } from "../../../interfaces/api-interfaces";
import { useNavigate } from "react-router-dom";
import { useAddToCart } from "../../../hooks/useEditCart";
import { getImageFromServer } from "../../../assets/JS/helpers";
import { SpinnerLoadingIcon, JumpingPoints } from "../../Common/loading-icon";
import i18n, { t } from "i18next";
import { useRemoveFromWishlist } from "../../../hooks/useEditWishlist";
import { useAtomValue } from "jotai";
import { userAtom } from "../../../store";
import {
  TableRow,
  TableCell,
  CircularProgress,
  Typography,
  Box,
} from "@mui/material";
import { BsCart2 } from "react-icons/bs";
import { RiDeleteBinLine } from "react-icons/ri";
import AddToCart from "../../Common/add-to-cart";
import { wishListResponse } from "../../../interfaces/new-api-interfaces/cart";
import { defaultTranslation } from "../../../untilites/functions";
import { useTranslation } from "react-i18next";

interface Props extends wishListResponse {
  refetch: Function;
  index: number;
}

const buttonContentStyle = {
  fontSize: "medium",
  display: "flex",
  alignItems: "center",
  gap: "10px",
  whiteSpace: "nowrap",
  width: "100%",
  justifyContent: "center",
};

const WishlistItem: React.FC<Props> = (Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useAtomValue(userAtom);
  const { mutateAsync: postRemoveFromWishlist, isLoading: loadingWishlist } =
    useRemoveFromWishlist();
  const removeFromWishlist = async () => {
    await postRemoveFromWishlist({
      wishlistable_id: Props.wishlistable_id,
      wishlistable_type: Props.wishlistable_type,
      user_id: user?.id,
    });
    Props.refetch();
  };

  const link =
    Props.wishlistable_type === "shopitem"
      ? "shop-item"
      : Props.wishlistable_type === "course"
      ? "course-details"
      : "quizz-details";
  return (
    <TableRow sx={{ bgcolor: Props.index % 2 == 0 ? "#fff" : "#F5F5F5" }}>
      <TableCell
        style={{ cursor: "pointer" }}
        className="pro-thumbnail"
        onClick={() => navigate(`/${link}/${Props.wishlistable_id}`)}
      >
        <img
          src={getImageFromServer(Props.image)}
          width="100px"
          alt="Product"
        />
      </TableCell>
      <TableCell
        style={{ cursor: "pointer" }}
        className="pro-thumbnail"
        onClick={() => navigate(`/${link}/${Props.wishlistable_id}`)}
      >
        {t(`${Props.wishlistable_type}`)}
      </TableCell>
      <TableCell
        style={{ cursor: "pointer" }}
        className="pro-title"
        onClick={() => navigate(`/${link}/${Props.wishlistable_id}`)}
      >
        {defaultTranslation(Props, "name")}
      </TableCell>
      <TableCell
        style={{ cursor: "pointer" }}
        className="pro-price"
        onClick={() => navigate(`/${link}/${Props.wishlistable_id}`)}
      >
        {Props.price != 0 && (
          <span>{`${Props.price?.toLocaleString()} ${t("syp")}`}</span>
        )}
        {Props.price == 0 && <span>{t("GlobalFree")}</span>}
      </TableCell>

      {user?.role === "instructor" && (
        <TableCell className="pro-addtocart">
          {Props.wishlistable_type === "shopitem" && (
            <AddToCart
              inCart={Props.in_cart}
              id={Props.wishlistable_id}
              type={Props.wishlistable_type}
              isBought={Props.is_bought}
              padding="5px 15px"
              refetch={Props.refetch}
            />
          )}
        </TableCell>
      )}

      {user?.role === "student" && (
        <TableCell className="pro-addtocart">
          <AddToCart
            inCart={Props.in_cart}
            id={Props.wishlistable_id}
            type={Props.wishlistable_type}
            isBought={Props.is_bought}
            padding="5px 15px"
            refetch={Props.refetch}
          />
        </TableCell>
      )}
      <TableCell className="pro-remove">
        {loadingWishlist ? (
          <CircularProgress size={20} />
        ) : (
          <button
            onClick={() => removeFromWishlist()}
            className="home-action-danger"
            style={{ padding: "5px 15px" }}
          >
            <Typography sx={buttonContentStyle}>
              {i18n.language === "en"
                ? "Remove from Wishlist"
                : "إزالة من المفضلة"}
              <RiDeleteBinLine />
            </Typography>
          </button>
        )}
      </TableCell>
    </TableRow>
  );
};
export default WishlistItem;
