import React, { useEffect } from "react";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation, Trans } from "react-i18next";
import { fetchApi } from "../../api/api";
import { ShopResponse } from "../../interfaces/api-interfaces";
import { LoadingPage } from "../../components/Common/loadingPage.component";
import { useLocation } from "react-router-dom";
import { GridTitle } from "../../components/Common/grids-common/grid-title";
import { GridSearch } from "../../components/Common/grids-common/grid-search";
import { Box, Pagination, Skeleton, Typography } from "@mui/material";
import { GridSelect } from "../../components/Common/grids-common/grid-select";
import { shopItemResponse } from "../../interfaces/new-api-interfaces/shop-item";
import i18n from "i18next";
import ShopItem from "../../components/Shop/shop-item";
import {
  fetchShopItemTypes,
  fetchShopItems,
} from "../../api/endpoints-functions";
import useDebounce from "../../hooks/useDebounce";
import GridFilter from "../../components/Common/grids-common/grid-filter";

type filterType = "-price" | "-created_at" | "price";

const lang = i18n.language;

const ShopPage: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const showParam = queryParams.get("show");

  //states for search and filter and sort
  const [searchValue, setSearchValue] = useState("");
  const [sortValue, setsortValue] = useState<filterType>("-created_at");
  const search = useDebounce(searchValue);
  const [priceRange, setPriceRange] = useState<number[]>([0]);
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<number[]>([]);

  const { data, isLoading, isError, isFetching, refetch } = useQuery(
    [
      "get-shop-items",
      search,
      sortValue,
      priceRange,
      selectedCategories,
      selectedTypes,
    ],
    () =>
      fetchShopItems(
        search,
        sortValue,
        priceRange,
        selectedCategories,
        selectedTypes
      ),
    {
      refetchOnWindowFocus: false,
      staleTime: 120000,
    }
  );

  const ITEMS_PER_PAGE: number = 10;
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(ITEMS_PER_PAGE);

  const paginatedData = data?.slice(min, max);

  const paginate = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPageNumber(page);
    setMin(ITEMS_PER_PAGE * (page - 1));
    setMax(page * ITEMS_PER_PAGE);
  };

  const filterList = [
    {
      value: "-created_at",
      title: t("CourseGridLatest"),
    },
    {
      value: "price",
      title: t("CourseGridPriceLTH"),
    },
    {
      value: "-price",
      title: t("CourseGridPriceHTL"),
    },
  ];

  const { data: types, isLoading: typesLoading } = useQuery(["get-types"], () =>
    fetchShopItemTypes()
  );

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const searchParam = params.get("type");
      if (searchParam) {
        setSelectedTypes([parseInt(searchParam)]);
      }
    }
  }, [location.search]);

  return (
    <div>
      <GridTitle>{t("HeaderNavShop")}</GridTitle>
      <div className="section section-padding-bottom">
        <div className="container">
          <div className="row justify-content-center align-items-center max-mb-20">
            <div
              className="col-sm-10 col-md-3 max-mb-10 mb-20"
              style={{ width: !isLoading ? "fit-content" : "" }}
            >
              {!isLoading ? (
                <p className="result-count">
                  <Trans i18nKey="CourseShopSubTitle" count={data?.length}>
                    We found <span>count</span> shop items available for you
                  </Trans>
                </p>
              ) : (
                <p className="result-count">
                  <Skeleton variant="text" height={"3rem"} width={"100%"} />
                </p>
              )}
            </div>
            <GridSearch
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
            <GridSelect
              filterValue={sortValue}
              setFilterValue={setsortValue}
              filterList={filterList}
            />
            <GridFilter
              priceRange={priceRange}
              selectedCategories={selectedCategories}
              setPriceRange={setPriceRange}
              setSelectedCategories={setSelectedCategories}
              types={types}
              setSelectedTypes={setSelectedTypes}
              free={showParam == "free"}
            />
          </div>

          <div
            className={
              !isLoading
                ? "row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30 justify-content-center justify-content-lg-start"
                : ""
            }
          >
            {!isLoading ? (
              data && data?.length ? (
                paginatedData?.map((shopItem: shopItemResponse) => (
                  <>
                    <ShopItem {...shopItem} refetch={refetch} />
                  </>
                ))
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography sx={{ fontSize: "2em" }}>
                    There are no shop items yet
                  </Typography>
                </Box>
              )
            ) : (
              <>
                <div className="row max-mb-n30">
                  {[1, 2, 3, 4, 5, 6].map((el) => (
                    <div className="col-12 col-md-6 col-lg-3 my-2 c-s" key={el}>
                      <Skeleton
                        variant="rectangular"
                        width={300}
                        height={200}
                      />
                      <div className="info">
                        <Skeleton
                          style={{ margin: "30px 0" }}
                          variant="text"
                          width="50%"
                        />
                        <Skeleton
                          style={{ margin: "30px 0" }}
                          variant="text"
                          width="70%"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          {!isLoading && (
            <>
              {data?.length! > ITEMS_PER_PAGE ? (
                <Pagination
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "20px",
                    marginTop: "40px",
                  }}
                  count={Math.ceil(data?.length! / ITEMS_PER_PAGE) || 0}
                  page={currentPageNumber}
                  onChange={paginate}
                  variant="outlined"
                  color="primary"
                  size="large"
                  dir="ltr"
                />
              ) : null}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShopPage;
