import {
  BlogRequest,
  BlogResponse,
} from "../interfaces/new-api-interfaces/blog";
import { categoryResponse } from "../interfaces/new-api-interfaces/categories";
import {
  Recents,
  courseLesson,
  courseRequest,
  courseResponse,
  courseSection,
  courseTestResponse,
  relatedItemsResponse,
  review,
  studentCourse,
} from "../interfaces/new-api-interfaces/course";
import { FreeItemResponse } from "../interfaces/new-api-interfaces/free-items";
import { IntrVideoResponse } from "../interfaces/new-api-interfaces/intro-video";
import {
  AlbumResponse,
  StudentShopItemResponse,
  StudentTest,
  instructorProfile,
  profileCoursesResponse,
  profileResponse,
  testCorrectionResponse,
} from "../interfaces/new-api-interfaces/profile";
import {
  shopItemResponse,
  shopItemTypeResponse,
} from "../interfaces/new-api-interfaces/shop-item";
import { tagResponse } from "../interfaces/new-api-interfaces/tag";
import { StatisticsResponse } from "../interfaces/new-api-interfaces/statistics";
import {
  GlobalTestResponse,
  TestResponse,
  courseTestInformation,
  studentCorrectedTest,
  studentTestDetails,
  studentToCorrectList,
  testQuestionForStudent,
  testQuestionsResponse,
  testQuestionsResponseForStudent,
  testToCorrect,
} from "../interfaces/new-api-interfaces/test";
import { fetchApi } from "./api";
import {
  cartResponse,
  wishListRequest,
  wishListResponse,
} from "../interfaces/new-api-interfaces/cart";
import { InstructorResponse } from "../interfaces/new-api-interfaces/instructor";
import {
  globalTestListResponse,
  globalTestResponse,
} from "../interfaces/new-api-interfaces/global-test";
import { guideFileResponse } from "../interfaces/new-api-interfaces/guide-file";
import {
  acceptConsultationRequest,
  createLiveConsultationRoom,
  instructorConsultation,
  instructorLiveConsultation,
  rejectConsultationRequest,
  studentTextConsultation,
  studentVideoConsultation,
} from "../interfaces/new-api-interfaces/consultations";
import { CertificateResponse } from "../interfaces/new-api-interfaces/certificate";
import {
  courseUserCertificate,
  profileCertificate,
} from "../interfaces/new-api-interfaces/certificate";
import { NotificationResponse } from "../interfaces/new-api-interfaces/notification";
import {
  createGroupRequest,
  updateGroupRequest,
  vrcGroupMessage,
  vrcGroupResponse,
  createVrcMeeting,
} from "../interfaces/new-api-interfaces/vcr";
import { userStudent } from "../interfaces/new-api-interfaces/auth";

//courses

export const fetchCourse = async (
  id: string | undefined
): Promise<courseResponse> => {
  const response = await fetchApi("/course/course/" + id, "GET", {});
  return response.data.data as courseResponse;
};

export const fetchCourses = async (
  search?: string | null,
  sort?: string | null,
  priceRange?: number[],
  selectedCategories?: number[]
): Promise<courseResponse[]> => {
  let url = "/course/searchCourse?filter[is_approved]=1";

  if (
    search ||
    sort ||
    (priceRange && priceRange.length === 2) ||
    (selectedCategories && selectedCategories.length > 0)
  ) {
    url = `/course/searchCourse?filter[is_approved]=1&filter[search]=${
      search || ""
    }&sort=${sort || ""}`;

    if (priceRange) {
      if (priceRange.length === 2) {
        url += `&filter[PriceLessThan]=${priceRange[1]}&filter[PriceMoreThan]=${priceRange[0]}`;
      } else if (priceRange.length === 1) {
        if (priceRange[0]) {
          url += `&filter[PriceMoreThan]=${priceRange[0]}`;
        }
      }
    }

    if (selectedCategories && selectedCategories.length > 0) {
      selectedCategories.forEach((category) => {
        url += `&filter[Category]=${category}`;
      });
    }
  }

  const response = await fetchApi(url, "GET", {});
  return response.data.data as courseResponse[];
};
export const fetchStudentCourse = async (
  id: string
): Promise<studentCourse> => {
  const response = await fetchApi(`/course/showMyCourse/${id}`, "GET", {});
  return response.data.data as studentCourse;
};

export const fetchSections = async (
  courseId: string
): Promise<courseSection[]> => {
  const response = await fetchApi(
    `/course/section?course_id=${courseId}`,
    "GET",
    {}
  );
  const sections: courseSection[] = response.data.data;
  sections.forEach((section) => {
    section.lessons.sort((a, b) => a.order - b.order);
  });
  sections.sort((a, b) => a.order - b.order);
  return sections;
};

export const fetchSectionsWithoutFiles = async (
  courseId: string
): Promise<courseSection[]> => {
  const response = await fetchApi(
    `/course/sectionWithoutFiles?course_id=${courseId}`,
    "GET",
    {}
  );
  const sections: courseSection[] = response.data.data;
  sections.forEach((section) => {
    section.lessons.sort((a, b) => a.order - b.order);
  });
  sections.sort((a, b) => a.order - b.order);
  return sections;
};

export const fetchLessonInfo = async (id: string): Promise<courseLesson> => {
  const response = await fetchApi(`/course/lesson/${id}`, "GET", {});
  return response.data.data as courseLesson;
};

export const fetchCourseTests = async (
  id: string
): Promise<courseTestResponse[]> => {
  const response = await fetchApi(`/course/showMyTests/${id}`, "GET", {});
  return response.data.data as courseTestResponse[];
};

export const fetchCourseCerifications = async (
  id: number | string
): Promise<CertificateResponse[]> => {
  const response = await fetchApi(
    `/general/certificate/getItemCertificates?type=course&id=${id}`,
    "GET",
    {}
  );
  return response.data.data as CertificateResponse[];
};

export const getCourseUserCertifications = async (
  id: number | string
): Promise<courseUserCertificate[]> => {
  const response = await fetchApi(
    `/auth/user/showMyCertification?type=course&id=${id}`,
    "GET",
    {}
  );
  return response.data.data as courseUserCertificate[];
};

export const getGlobalTestUserCertifications = async (
  id: number | string
): Promise<courseUserCertificate[]> => {
  const response = await fetchApi(
    `/auth/user/showMyCertification?type=globaltest&id=${id}`,
    "GET",
    {}
  );
  return response.data.data as courseUserCertificate[];
};

export const getUserCertifications = async (
  type: "course" | "globaltest"
): Promise<profileCertificate[]> => {
  const response = await fetchApi(
    `/auth/user/showAllMyCertification?type=${type}`,
    "GET",
    {}
  );
  return response.data.data as profileCertificate[];
};

export const fetchCourseTestDetails = async (
  id: any
): Promise<studentTestDetails> => {
  const response = await fetchApi(
    `/test/test/getTestStudentDetails/${id}`,
    "GET",
    {}
  );
  return response.data.data as studentTestDetails;
};

export const fetchReviews = async (
  id: number,
  type: string
): Promise<review[]> => {
  const response = await fetchApi(
    `/review/review?commentable_type=${type}&commentable_id=${id}`,
    "GET",
    {}
  );
  return response.data.data as review[];
};

export const fetchCourseRelatedItems = async (
  id: number
): Promise<relatedItemsResponse> => {
  const response = await fetchApi(
    `/course/showMyRelatableItem?course_id=${id}`,
    "GET",
    {}
  );
  return response.data.data as relatedItemsResponse;
};

export const fetchCourseRecents = async (): Promise<Recents[]> => {
  const response = await fetchApi(`/course/RecentCourse`, "GET", {});
  return response.data.data as Recents[];
};

//shope items
export const fetchShopItems = async (
  search?: string | null,
  sort?: string | null,
  priceRange?: number[],
  selectedCategories?: number[],
  types?: number[]
): Promise<shopItemResponse[]> => {
  let url = "/shopItem/shopItem";

  if (
    search ||
    sort ||
    (priceRange && priceRange.length === 2) ||
    (selectedCategories && selectedCategories.length > 0)
  ) {
    url = `/shopItem/searchShopItem?filter[search]=${search || ""}&sort=${
      sort || ""
    }`;

    if (priceRange) {
      if (priceRange.length === 2) {
        url += `&filter[PriceLessThan]=${priceRange[1]}&filter[PriceMoreThan]=${priceRange[0]}`;
      } else if (priceRange.length === 1) {
        if (priceRange[0]) {
          url += `&filter[PriceMoreThan]=${priceRange[0]}`;
        }
      }
    }

    if (selectedCategories && selectedCategories.length > 0) {
      selectedCategories.forEach((category) => {
        url += `&filter[Category]=${category}`;
      });
    }

    if (types && types.length > 0) {
      types.forEach((type) => {
        url += `&filter[Type]=${type}`;
      });
    }
  }

  const response = await fetchApi(url, "GET", {});
  return response.data.data as shopItemResponse[];
};

export const fetchShopItemTypes = async (): Promise<shopItemTypeResponse[]> => {
  const response = await fetchApi(`/shopItem/shopItemType`, "GET", {});
  return response.data.data as shopItemTypeResponse[];
};

export const fetchShopItem = async (
  itemId: string
): Promise<shopItemResponse> => {
  const response = await fetchApi(`/shopItem/shopItem/${itemId}`, "GET", {});
  return response.data.data as shopItemResponse;
};

export const fetchShopItemsRecents = async (): Promise<Recents[]> => {
  const response = await fetchApi(`/shopItem/RecentShopItem`, "GET", {});
  return response.data.data as Recents[];
};

//profile

export const fetchProfile = async (): Promise<profileResponse> => {
  const response = await fetchApi(`/auth/user/showMyProfile`, "GET", {});
  return response.data.data as profileResponse;
};

//categories

export const fetchCategories = async (): Promise<categoryResponse[]> => {
  const response = await fetchApi(`/category/category`, "GET", {});
  return response.data.data as categoryResponse[];
};

// Blogs

export const fetchBlogs = async (
  search?: string | null,
  selectedCategories?: number[]
): Promise<BlogResponse[]> => {
  let url = "/blog/searchBlog?sort=-created_at";

  if (search || (selectedCategories && selectedCategories.length > 0)) {
    url = `/blog/searchBlog?sort=-created_at&filter[search]=${search || ""}
    `;

    if (selectedCategories && selectedCategories.length > 0) {
      selectedCategories.forEach((category) => {
        url += `&filter[Category]=${category}`;
      });
    }
  }

  const response = await fetchApi(url, "GET", {});
  return response.data.data as BlogResponse[];
};

export const fetchInstructorBlogs = async (
  id: number
): Promise<BlogResponse[]> => {
  const response = await fetchApi(`/auth/instructorBlog/${id}`);
  return response.data.data as BlogResponse[];
};

export const fetchBlog = async (id: string): Promise<BlogResponse> => {
  const response = await fetchApi(`/blog/blog/${id}`, "GET", {});
  response.data.data.blog_sections.sort((a: any, b: any) => a.order - b.order);
  return response.data.data as BlogResponse;
};

export const postBlog = async (data: BlogRequest): Promise<any> => {
  const response = await fetchApi(`/blog/blog`, "POST", data);
  return response.data as any;
};

export const updateBlog = async (data: any, id: number): Promise<any> => {
  const response = await fetchApi(`/blog/blog/${id}`, "POST", data);
  return response.data;
};

export const postSections = async (data: any): Promise<any> => {
  const response = await fetchApi(`/blog/blogSections`, "POST", data);
  return response.data;
};

export const deleteSection = async (id: number): Promise<any> => {
  const response = await fetchApi(`/blog/blogSections/${id}`, "DELETE");
  return response.data;
};

export const updateSection = async (data: any, id: number): Promise<any> => {
  const response = await fetchApi(`/blog/blogSections/${id}`, "POST", data);
  return response.data;
};

export const updateSectionsOrder = async (data: any): Promise<any> => {
  const response = await fetchApi(
    `/blog/blogSections/updateOrders`,
    "POST",
    data
  );
  return response.data;
};

// Home Page
export const fetchTopInstructors = async (): Promise<instructorProfile[]> => {
  const response = await fetchApi(`/auth/topInstructors`, "GET", {});
  return response.data.data as instructorProfile[];
};

export const fetchTopCategories = async (): Promise<categoryResponse[]> => {
  const response = await fetchApi(`/category/topCategories`, "GET", {});
  return response.data.data as categoryResponse[];
};

export const fetchTopCourses = async (): Promise<courseResponse[]> => {
  const response = await fetchApi(`/course/topCourses`, "GET", {});
  return response.data.data as courseResponse[];
};

export const fetchTopGlobalTests = async (): Promise<TestResponse[]> => {
  const response = await fetchApi(`/test/topGlobalTests`, "GET", {});
  return response.data.data as TestResponse[];
};

export const fetchTopBlogs = async (): Promise<BlogResponse[]> => {
  const response = await fetchApi(`/blog/topBlogs`, "GET", {});
  return response.data.data as BlogResponse[];
};
export const fetchTopShopItems = async (): Promise<shopItemResponse[]> => {
  const response = await fetchApi(`/shopItem/topShopItems`, "GET", {});
  return response.data.data as shopItemResponse[];
};
export const fetchIntroVideo = async (): Promise<IntrVideoResponse[]> => {
  const response = await fetchApi(`/homePage/introVideo`, "GET", {});
  return response.data.data as IntrVideoResponse[];
};
export const fetchStatistics = async (): Promise<StatisticsResponse[]> => {
  const response = await fetchApi(`/homePage/statisticsData`, "GET", {});
  return response.data.data as StatisticsResponse[];
};
export const fetchFreeItems = async (): Promise<FreeItemResponse[]> => {
  const response = await fetchApi(`/homePage/freeItem`, "GET", {});
  return response.data.data as FreeItemResponse[];
};

export const fetchTopFreeItems = async (): Promise<FreeItemResponse[]> => {
  const response = await fetchApi(`/homePage/topFreeItems`);
  return response.data.data as FreeItemResponse[];
};

//tags
export const fetchTags = async (): Promise<tagResponse[]> => {
  const response = await fetchApi(`/general/tag`, "GET", {});
  return response.data.data as tagResponse[];
};

// Instructor Profile

export const fetchInstructorCourses = async (): Promise<
  profileCoursesResponse[]
> => {
  const response = await fetchApi(`/auth/showMyCourse`, "GET", {});
  return response.data.data as profileCoursesResponse[];
};
export const fetchInstructorGlobalTests = async (): Promise<
  GlobalTestResponse[]
> => {
  const response = await fetchApi(`/auth/show/myGlobalTest`, "GET", {});
  return response.data.data as GlobalTestResponse[];
};
export const fetchInstructorAnsweredTests = async (
  isChecked: boolean
): Promise<testCorrectionResponse[]> => {
  const response = await fetchApi(
    `/auth/showMyAnsweredTest?checked=${isChecked}`,
    "GET",
    {}
  );
  return response.data.data as testCorrectionResponse[];
};
export const fetchInstructorAnsweredGlobalTests = async (
  isChecked: boolean
): Promise<testCorrectionResponse[]> => {
  const response = await fetchApi(
    `/auth/showMyAnsweredGlobalTest?checked=${isChecked}`,
    "GET",
    {}
  );
  return response.data.data as testCorrectionResponse[];
};

export const fetchStudentsForCourseTestList = async (
  id: string
): Promise<studentToCorrectList> => {
  const response = await fetchApi(
    `/auth/user/listUsersForTest/${id}?is_checked=0`,
    "GET",
    {}
  );
  return response.data.data as studentToCorrectList;
};

export const fetchStudentsForGlobalTestList = async (
  id: string
): Promise<studentToCorrectList> => {
  const response = await fetchApi(
    `/auth/user/listUsersForGlobalTest/${id}?is_checked=0`,
    "GET",
    {}
  );
  return response.data.data as studentToCorrectList;
};

export const fetchStudentsForCourseTestListCorrected = async (
  id: string
): Promise<studentToCorrectList> => {
  const response = await fetchApi(
    `/auth/user/listUsersForTest/${id}?is_checked=1`,
    "GET",
    {}
  );
  return response.data.data as studentToCorrectList;
};

export const fetchStudentsForGlobalTestListCorrected = async (
  id: string
): Promise<studentToCorrectList> => {
  const response = await fetchApi(
    `/auth/user/listUsersForGlobalTest/${id}?is_checked=1`,
    "GET",
    {}
  );
  return response.data.data as studentToCorrectList;
};

export const fetchBlogsInstructor = async (): Promise<BlogResponse[]> => {
  const response = await fetchApi(`/auth/showMyBlog`);
  return response.data.data as BlogResponse[];
};

// Student Profile

export const fetchStudentShopItems = async (): Promise<
  StudentShopItemResponse[]
> => {
  const response = await fetchApi(`/shopItem/shopItemUser`, "GET", {});
  return response.data.data as StudentShopItemResponse[];
};

export const fetchStudentGlobalTests = async (): Promise<
  GlobalTestResponse[]
> => {
  const response = await fetchApi(`/auth/user/showMyUnAnsweredGlobalTest`);
  return response.data.data as GlobalTestResponse[];
};
export const fetchStudentTests = async (
  isChecked: boolean
): Promise<StudentTest[]> => {
  const response = await fetchApi(
    `/auth/user/showMyCheckedGlobalTest?checked=${isChecked}`
  );
  return response.data.data as StudentTest[];
};

export const fetchProfileCourses = async (): Promise<
  profileCoursesResponse[]
> => {
  const response = await fetchApi(`/course/showMyCourse`, "GET", {});
  return response.data.data as profileCoursesResponse[];
};

export const fetchStudentAlbums = async (): Promise<AlbumResponse[]> => {
  const response = await fetchApi(`/auth/user/showMyAlbum`, "GET", {});
  return response.data.data as AlbumResponse[];
};

export const fetchStudentAlbum = async (id: number): Promise<AlbumResponse> => {
  const response = await fetchApi(`/gallery/album/${id}`);
  return response.data.data as AlbumResponse;
};

// Cart
export const fetchCartItems = async (): Promise<cartResponse[]> => {
  const response = await fetchApi("/cart/cart", "GET", {});
  return response.data.data as cartResponse[];
};

export const fetchCartItemsCount = async (): Promise<{
  count: number;
}> => {
  const response = await fetchApi("/cart/cart/count", "GET", {});
  return response.data.data as {
    count: number;
  };
};

// Wishlist
export const fetchWishistItems = async (): Promise<wishListResponse[]> => {
  const response = await fetchApi("/wishlist/wishlist", "GET", {});
  return response.data.data as wishListResponse[];
};

//notifications
export const fetchNotifications = async (
  page: number,
  items: number,
  isChecked: boolean
): Promise<NotificationResponse> => {
  const response = await fetchApi(
    `/auth/notification?page=${page}&items_per_page=${items}${
      isChecked ? "&filter[is_read]=1" : ""
    }`,
    "GET",
    {}
  );
  return response.data as NotificationResponse;
};

export const fetchNotificationsNumber = async (): Promise<{
  number: number;
}> => {
  const response = await fetchApi(
    `/auth/notification/count/un-read`,
    "GET",
    {}
  );
  return response.data.data as { number: number };
};

// Instructors Grid
export const fetchInstructors = async (
  search?: string
): Promise<InstructorResponse[]> => {
  const response = await fetchApi(
    search
      ? `/auth/searchInstructor?filter[search]=${search}`
      : "/auth/instructor",
    "GET",
    {}
  );
  return response.data.data as InstructorResponse[];
};
// Instructor Details
export const fetchInstructorDetails = async (
  id: number
): Promise<InstructorResponse> => {
  const response = await fetchApi(`/auth/instructor/${id}`);
  return response.data.data as InstructorResponse;
};

export const fetchSingleInstructorCourses = async (
  id: number
): Promise<any[]> => {
  const response = await fetchApi(`/auth/instructorCourse/${id}`);
  return response.data.data as any[];
};

export const fetchSingleInstructorGlobalTests = async (
  id: number
): Promise<GlobalTestResponse[]> => {
  const response = await fetchApi(`/auth/instructorGlobalTest/${id}`);
  return response.data.data as GlobalTestResponse[];
};

//global tests
export const fetchGlobalTests = async (
  search?: string | null,
  sort?: string | null,
  priceRange?: number[],
  selectedCategories?: number[]
): Promise<globalTestListResponse[]> => {
  let url = "/test/globalTest";

  if (
    search ||
    sort ||
    (priceRange && priceRange.length === 2) ||
    (selectedCategories && selectedCategories.length > 0)
  ) {
    url = `/test/searchGlobalTest?filter[is_approved]=1&filter[search]=${
      search || ""
    }&sort=${sort || ""}`;

    if (priceRange) {
      if (priceRange.length === 2) {
        url += `&filter[PriceLessThan]=${priceRange[1]}&filter[PriceMoreThan]=${priceRange[0]}`;
      } else if (priceRange.length === 1) {
        if (priceRange[0]) {
          url += `&filter[PriceMoreThan]=${priceRange[0]}`;
        }
      }
    }

    if (selectedCategories && selectedCategories.length > 0) {
      selectedCategories.forEach((category) => {
        url += `&filter[Category]=${category}`;
      });
    }
  }

  const response = await fetchApi(url, "GET", {});
  return response.data.data as globalTestListResponse[];
};

export const fetchGlobalTest = async (
  id: string
): Promise<globalTestResponse> => {
  const response = await fetchApi(`/test/globalTest/${id}`, "GET", {});
  return response.data.data as globalTestResponse;
};

export const fetchGlobalTestCerifications = async (
  id: string
): Promise<CertificateResponse[]> => {
  const response = await fetchApi(
    `/general/certificate/getItemCertificates?type=globaltest&id=${id}`,
    "GET",
    {}
  );
  return response.data.data as CertificateResponse[];
};

export const fetchGlobalTestQuestions = async (
  id: string
): Promise<testQuestionsResponse> => {
  const response = await fetchApi(
    `/test/globalTest/getTestQuestions/${id}`,
    "GET",
    {}
  );
  const questions: testQuestionsResponse = response.data.data;
  questions.questions.sort((a, b) => a.order - b.order);
  return questions;
};

export const fetchTestQuestions = async (
  id: string
): Promise<testQuestionsResponse> => {
  const response = await fetchApi(`/test/test/content/${id}`, "GET", {});
  const questions: testQuestionsResponse = response.data.data;
  questions.questions.sort((a, b) => a.order - b.order);
  return questions;
};

export const fetchCourseTestInformation = async (
  id: number
): Promise<courseTestInformation> => {
  const response = await fetchApi(`/test/test/${id}`, "GET", {});

  return response.data.data as courseTestInformation;
};

export const fetchTestQuestionsForStudent = async (
  id: string
): Promise<testQuestionsResponseForStudent> => {
  const response = await fetchApi(
    `/test/globalTest/getTestQuestions/forStudent/${id}`,
    "GET",
    {}
  );
  const questions: testQuestionsResponseForStudent = response.data.data;
  questions.questions.sort((a, b) => a.order - b.order);
  return questions;
};

export const fetchCourseTestQuestionsForStudent = async (
  id: string
): Promise<testQuestionsResponseForStudent> => {
  const response = await fetchApi(`/test/test/forStudent/${id}`, "GET", {});
  const questions: testQuestionsResponseForStudent = response.data.data;
  questions.questions.sort((a, b) => a.order - b.order);
  return questions;
};

export const fetchGlobalTestsToCorrect = async (
  studentId: string,
  testId: string
): Promise<testToCorrect> => {
  const response = await fetchApi(
    `/test/globalTestUser/${testId}/answers/${studentId}`,
    "GET",
    {}
  );
  const questions: testToCorrect = response.data.data;
  questions.answers.sort((a, b) => a.order - b.order);
  return questions;
};

export const fetchCourseTestsToCorrect = async (
  studentId: string,
  testId: string
): Promise<testToCorrect> => {
  const response = await fetchApi(
    `/test/test/${testId}/answers/${studentId}`,
    "GET",
    {}
  );
  const questions: testToCorrect = response.data.data;
  questions.answers.sort((a, b) => a.order - b.order);
  return questions;
};

export const fetchStudentCorrectedTest = async (
  testId: string,
  testType: string
): Promise<studentCorrectedTest> => {
  const response = await fetchApi(
    `/auth/user/showMyAnswers/?test_id=${testId}&test_type=${testType}`,
    "GET",
    {}
  );
  const questions: studentCorrectedTest = response.data.data;
  questions.answers.sort((a, b) => a.order - b.order);
  return questions;
};

export const fetchGuideFile = async (
  id: number
): Promise<guideFileResponse> => {
  const response = await fetchApi(`/homePage/showGuideFile/${id}`);
  return response.data.data as guideFileResponse;
};

export const postTestNotes = async (data: any): Promise<any> => {
  const response = await fetchApi(`/test/addTestNotes`, "POST", data);
  return response.data as any;
};

export const fetchStudentsConsultations = async (
  is_answered: boolean
): Promise<studentTextConsultation[]> => {
  const response = await fetchApi(
    `/auth/user/showMyConsultation?is_answered=${is_answered == false ? 0 : 1}`
  );
  return response.data.data as studentTextConsultation[];
};

export const fetchStudentsVideoConsultations = async (
  status: string
): Promise<studentVideoConsultation[]> => {
  const response = await fetchApi(
    `/auth/user/consultation/showMyLiveConsultation?filter[status]=${status}`
  );
  return response.data.data as studentVideoConsultation[];
};

export const fetchInstructorsConsultations = async (
  is_answered: boolean
): Promise<instructorConsultation[]> => {
  const response = await fetchApi(
    `/auth/instructor/consultation/showMyConsultations?is_answered=${
      is_answered == false ? 0 : 1
    }`
  );
  return response.data.data as instructorConsultation[];
};

export const fetchStudentsConsultation = async (
  id: string
): Promise<studentTextConsultation> => {
  const response = await fetchApi(`/consultation/consultation/${id}`);
  return response.data.data as studentTextConsultation;
};

export const fetchStudentsVideoConsultation = async (
  id: string
): Promise<studentVideoConsultation> => {
  const response = await fetchApi(`/consultation/liveConsultation/${id}`);
  return response.data.data as studentVideoConsultation;
};

// Instructor Live consultations

export const fetchInstructorLiveConsultations = async (
  status: string
): Promise<instructorLiveConsultation[]> => {
  const response = await fetchApi(
    `/auth/instructor/consultation/showMyLiveConsultations?filter[status]=${status}`
  );
  return response.data.data as instructorLiveConsultation[];
};

export const acceptLiveConsutation = async (
  id: number,
  data: any
): Promise<acceptConsultationRequest> => {
  const response = await fetchApi(
    `/consultation/liveConsultation/instructorAccept/${id}`,
    "POST",
    data
  );
  return response.data;
};

export const createLiveRoom = async (
  id: number,
  token: string
): Promise<createLiveConsultationRoom> => {
  const response = await fetchApi(
    `/consultation/liveConsultation/createLiveRoom/${id}`,
    "POST",
    { token: token }
  );
  return response.data;
};

export const rejectLiveConsultation = async (
  id: number,
  data: any
): Promise<rejectConsultationRequest> => {
  const response = await fetchApi(
    `/consultation/liveConsultation/instructorReject/${id}`,
    "POST",
    data
  );
  return response.data;
};

// Certificate

export const fetchCertificates = async (): Promise<CertificateResponse[]> => {
  const response = await fetchApi("/general/certificate");
  return response.data.data as CertificateResponse[];
};

export const assignCertificate = async (data: any): Promise<any> => {
  const response = await fetchApi("/general/certificate/assign", "POST", data);
  return response.data;
};

export const fetchItemCertificate = async (
  type: string,
  id: number
): Promise<CertificateResponse[]> => {
  const response = await fetchApi(
    `/general/certificate/getItemCertificates?type=${type}&id=${id}`
  );
  return response.data.data as CertificateResponse[];
};

//virtual class room

export const CreateVrcGroup = async (
  data: createGroupRequest
): Promise<any> => {
  const response = await fetchApi("/chat/room", "POST", data);
  return response.data;
};

export const UpdateVrcGroup = async (
  data: updateGroupRequest
): Promise<any> => {
  const response = await fetchApi(`/chat/room/${data.id}`, "PUT", data);
  return response.data;
};

export const fetchStudents = async (): Promise<userStudent[]> => {
  const response = await fetchApi(`/auth/user?role=student`, "GET", {});
  return response.data.data as userStudent[];
};

export const fetchVrcGroups = async (): Promise<vrcGroupResponse[]> => {
  const response = await fetchApi(`/chat/room`, "GET", {});
  return response.data.data as vrcGroupResponse[];
};

export const fetchVrcGroupById = async (
  id: number
): Promise<vrcGroupResponse> => {
  const response = await fetchApi(`/chat/room/${id}`, "GET", {});
  return response.data.data as vrcGroupResponse;
};

export const fetchVrcGroupMessagesById = async (
  id: number
): Promise<vrcGroupMessage[]> => {
  const response = await fetchApi(
    `/chat/chat/getAllMessageForRoom/${id}`,
    "GET",
    {}
  );
  return response.data.data as vrcGroupMessage[];
};

export const CreateVrcMeeting = async (
  id: number,
  token: string
): Promise<createVrcMeeting> => {
  const response = await fetchApi(`/chat/chat/google/meet/${id}`, "POST", {
    token: token,
  });
  return response.data;
};
