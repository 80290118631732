import React from "react";
import { Course } from "../../../interfaces/api-interfaces";
import { Link } from "react-router-dom";
import { getImageFromServer } from "../../../assets/JS/helpers";
import { Box, Typography } from "@mui/material";

interface Props {
  id: number;
  name: string;
  image: string;
  description: string;
  link: string;
}

export const SideSectionItem: React.FC<Props> = ({
  id,
  name,
  image,
  description,
  link,
}) => {
  return (
    <li
      className="widget-course"
      style={{ width: "100%", padding: "0px 10px" }}
    >
      <Link to={`/${link}/${id}`} style={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
            alignItems: "flex-start",
          }}
        >
          <Box sx={{ width: "30%" }}>
            <div
              style={{
                width: "130px",
                height: "100px",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                borderRadius: "5px",
              }}
            >
              <img
                src={getImageFromServer(image)}
                alt={name}
                style={{
                  width: "100%",
                }}
              />
            </div>
          </Box>
          <Box className="info" sx={{ width: "60%" }}>
            <Typography variant="h5" color="primary.main">
              {name}
            </Typography>
            {/* <Typography>
              {" "}
              {description?.slice(0, 30)}
              {description?.length > 30 ? "..." : ""}
            </Typography> */}
          </Box>
        </Box>
      </Link>
    </li>
  );
};
