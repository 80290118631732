import { Stack, Typography } from "@mui/material";
import React from "react";

const ArPrivacyPolicy = () => {
  return (
    <div className="container">
      <div className="order-lg-2 max-mb-50">
        <Stack gap={3} sx={{ marginTop: "50px", marginBottom: "50px" }}>
          <Typography variant="h2" color="primary">
            سياسة الخصوصية
          </Typography>
          <Typography variant="h6">آخر تحديث: 13 آذار 2024</Typography>
          <Typography>
            يوضح هذا البيان ("سياسة الخصوصية") ما نفعله ببيانات المنتسبين
            الشخصية التي نتلقاها من خلال الخدمات التعليمية ("الخدمات")،
            المُقدَّمة من آفاق بلا حدود، وكيف يمكن للمنتسبين المسجلين في دورة
            مُقدَّمة من آفاق بلا حدود ممارسة حقوقهم فيها.
          </Typography>
          <Typography variant="h4" color="primary">
            مَن نكون وكيفية الاتصال بنا:
          </Typography>
          <Typography>
            نحن آفاق بلا حدود للتدريب والاستشارات، شركة محدودة المسؤولية وهي
            مملوكة بالكامل للسيِّد محمد حسام عطايا، عنواننا شارع العفيف، دمشق،
            سوريا ("آفاق بلا حدود"). يشير ضمير المتكلم "نحن" في هذا النص إلى
            آفاق بلا حدود. نقدم منصة تعلُّم مفتوحة عبر الإنترنت للمتدرِّبين
            الذين يتطلعون إلى تطوير تعليمهم عبر منصة تُعرف باسم "آفاق بلا حدود".
            إننا نتحكم في بياناتك. وهذا يعني أننا مسؤولون عن تحديد كيفية جمع
            معلوماتك الشخصية وحفظها واستخدامها. قد نشرك من وقت لآخر أطراف ثالثة
            لمعالجة معلوماتك، وقد نعالج معلوماتك بأنفسنا (كما هو موضح بشكل أكثر
            أدناه). إذا كان لديك أي أسئلة أو مخاوف بشأن المعلومات الواردة في
            سياسة الخصوصية هذه، أو حول تعاملنا مع البيانات الشخصية بشكل عام، أو
            إذا كنت ترغب في الحصول على معلومات أكثر تفصيلًا، فيتعين عليك
            مراسلتنا عبر البريد الإلكتروني على info@uh-training.com أو مراسلتنا
            على العنوان [شارع العفيف، دمشق، سوريا] لإحاطة "مسؤول الخصوصية" علمًا
            بها.
          </Typography>
          <Typography variant="h4" color="primary">
            المعلومات التي نجمعها عنك وسبب جمعها:
          </Typography>
          <Typography>
            من خلال تسجيل حساب في آفاق بلا حدود وبدوره استخدام خدماتنا، سنجمع
            منك المعلومات التالية ("البيانات الشخصية"):
          </Typography>
          <Typography>
            <ul>
              <li>اسمك.</li>
              <li>عنوان بريدك الإلكتروني.</li>
              <li>سنة ميلادك.</li>
              <li>أعلى مستوى تعليمي حاصل عليه.</li>
              <li>الجنس.</li>
              <li>بلد الإقامة.</li>
              <li>أرقام الهاتف.</li>
              <li>
                حسابات وسائل التواصل الاجتماعي (في حالة تسجيل الدخول/التسجيل
                باستخدام وسائل التواصل الاجتماعي، وبالتحديد فيسبوك أو جوجل).
              </li>
              <li>
                سجلات زياراتك إلى آفاق بلا حدود والتفاعل مع المحتوى التعليمي؛
                مثل الصفحات ومقاطع الفيديو والتقييمات والمنشورات والتعليقات وأي
                تفاعلات أخرى تقوم بها في منتديات مناقشة الدورة.
              </li>
              <li>
                تفاصيل الدرجات التي حصلت عليها في التقييمات التي تجريها في آفاق
                بلا حدود.
              </li>
              <li>
                لقطات عبر الإنترنت للتفاعلات التي أجريتها في مقاطع فيديو
                للمحاضرات عبر الإنترنت.
              </li>
              <li>
                سجلات إنشاء المعلمين لحجرات دراسة افتراضية على آفاق بلا حدود.
              </li>
              <li>
                المعلومات التي تضيفها إلى حسابات الأطفال التي تنشئها لأطفالك على
                آفاق بلا حدود من خلال قسم أولياء الأمور على صفحة لوحة المعلومات.
              </li>
            </ul>
          </Typography>

          <Typography>
            إذا كنت ولي أمر أو وصياً تنشئ حساباً لطفلك، فسيتم جمع ("البيانات
            الشخصية") التالية للطفل:
          </Typography>
          <Typography>
            <ul>
              <li>اسم الطفل.</li>
              <li>جنس الطفل (اختياري).</li>
              <li>الصف الأكاديمي للطفل.</li>
              <li>البلد ذو المناهج التي تريد أن تتبعها برامج الطفل.</li>
            </ul>
          </Typography>
          <Typography>
            لن تشارك آفاق بلا حدود أبداً البيانات الشخصية للطفل خارجياً، أو أي
            ربط بين بيانات الطفل وبيانات ولي الأمر.
          </Typography>
          <Typography variant="h4" color="primary">
            الأساس القانوني لاستخدام بياناتك الشخصية:
          </Typography>

          <Typography>
            عند استخدام بياناتك الشخصية، نعالجها وفقاً للقواعد التالية:
          </Typography>
          <Typography>
            <ul>
              <li>
                تنفيذ عقد تكون أنت بصفتك الشخص المعني بالبيانات طرفًا فيه (بما
                في ذلك ما يتعلق بالاحتفاظ بسجلات توضح بالتفصيل التقدم المحرز في
                تعلُّمك وأدائك بهدف حفظ تقدم الدورة والشهادات).
                <ul>
                  <li>
                    من أجل المصلحة المشروعة لآفاق بلا حدود، تُنفَّذ الأنشطة
                    التالية:
                  </li>
                  <li>
                    <ul>
                      <li>
                        أغراض البحث الداخلي، على سبيل المثال تحسين تصميم الدورات
                        وطريقة تشغيلها.
                      </li>
                      <li>
                        أغراض البحث والتسويق التالية (ما لم تقرر عدم المشاركة
                        عند التسجيل أو فيما بعد):
                        <ol>
                          <li>
                            بحث داخلي فيما يتعلق بكيفية استخدام منصة آفاق بلا
                            حدود.
                          </li>
                          <li>
                            بهدف إرسال تحديثات إليك فيما يتعلق بالدورات عبر
                            الإنترنت التي تقدمها آفاق بلا حدود.
                          </li>
                        </ol>
                        <li>تحسين تجربة مستخدمي منصة آفاق بلا حدود.</li>
                        <li>
                          نشر البحوث العلمية في المجلات أو المؤتمرات. لا يتم
                          مشاركة أي بيانات تحدد هويتك في منشور علمي. إننا لا
                          ننشر إلا البيانات المُجمَّعة أو مجهولة المصدر.
                        </li>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </Typography>
          <Typography>
            لن نجمع أبدًا أي بيانات شخصية غير ضرورية منك ولن نعالج معلوماتك بأي
            طريقة، بخلاف ما هو محدد في هذه السياسة.
          </Typography>
          <Typography>
            نحن نبذل قصارى جهدنا للحفاظ على دقة بياناتك الشخصية التي نحفظها
            واكتمالها، ولضمان تحديث كل بياناتك الشخصية. ومع ذلك، يمكنك مساعدتنا
            في ذلك عن طريق الاتصال بنا على الفور إذا طرأت أي تغييرات على بياناتك
            الشخصية. لن نتحمل مسؤولية أي خسائر تتكبدها نتيجة أي معلومات شخصية
            غير دقيقة أو غير صحيحة أو ناقصة أو غير كاملة تقدمها لنا.
          </Typography>
          <Typography variant="h4" color="primary">
            مدة احتفاظنا ببياناتك الشخصية:
          </Typography>
          <Typography>
            بالنسبة لمستخدمي الاتحاد الأوروبي والمستخدمين في البلدان الأخرى التي
            لديها تشريعات حماية البيانات التي تتطلب حذف بيانات المستخدم الشخصية
            بعد مرور بعض الوقت، سيتم حذف بيانات حسابك تلقائياً بعد 7 سنوات من
            عدم النشاط. يُعرَّف عدم النشاط أنه عدم التفاعل مع محتوى الدورة. بعد
            مرور ٧ سنوات من عدم النشاط، سنتصل بك بإشعار مسبق بشهر واحد، لإبلاغك
            بأنه سيتم حذف بياناتك الشخصية من أجهزة الحاسوب التي يتم حفظها عليها،
            ومنحك خيار طلب الاحتفاظ ببياناتك الشخصية لمدة 7 سنوات أخرى.
          </Typography>
          <Typography>
            بالنسبة للمستخدمين في جميع البلدان الأخرى، ستحفظ آفاق بلا حدود
            ببيانات حسابك حتى ترسل طلباً إلينا لحذف بياناتك من خلال الموقع، وبعد
            ذلك سيتم حذف بياناتك وفقاً للجدول الزمني المُقدَّم من الموقع عندما
            تطلب الحذف.
          </Typography>
          <Typography>
            يجوز لآفاق بلا حدود تقصير فترة الاحتفاظ ببياناتك الشخصية وفقاً لكمية
            بياناتك الشخصية وطبيعتها وحساسيتها، والمخاطر المحتملة للضرر الناجم
            عن الاستخدام غير المصرح به أو الإفصاح عن بياناتك الشخصية، والأهداف
            وراء معالجة بياناتك الشخصية وما إذا كان بإمكاننا تحقيق هذه الأهداف
            من خلال وسائل أخرى، والمتطلبات القانونية المعمول بها.
          </Typography>
          <Typography variant="h4" color="primary">
            مَن نشارك بياناتك الشخصية معهم:
          </Typography>
          <Typography>
            يجوز لنا من وقت لآخر مشاركة بياناتك الشخصية مع الأطراف التالية بغرض
            تحسين محتوى الدورة وتحسين عمليات التسويق:
          </Typography>
          <Typography>
            <ul>
              <li>فريق إدارة الدورة الداخلي.</li>
              <li>فريق التسويق الداخلي.</li>
              <li>فريق القيادة الداخلي.</li>
              <li>
                الأطراف الثالثة التي نتشارك معها لأغراض إنشاء الدورة أو طريقة
                تشغيلها أو تقييمها (بما في ذلك المدرسون ومساعدو المدرسين وموظفو
                المنظمات التي تشارك مع آفاق بلا حدود لغرض دورة أنت مسجَّل فيها)،
                ووكالات التسويق التي نتشارك معها من أجل احتياجات التسويق الخاصة
                لآفاق بلا حدود. يوقع جميع الأطراف الثالثة التي تتلقى بيانات
                شخصية من آفاق بلا حدود على اتفاقية لمعالجة البيانات مع آفاق بلا
                حدود تنص على استخدام بياناتك الشخصية فقط للأغراض التي تعاقدت
                عليها آفاق بلا حدود وفقاً للائحة العامة لحماية البيانات (GDPR)،
                أو أي لوائح بيانات أخرى معمول بها، وأن الطرف الثالث يحذف بياناتك
                الشخصية في غضون 10 أيام من إنهاء العقد أو إتمامه.
              </li>
            </ul>
          </Typography>
          <Typography>
            يجوز لنا من وقت لآخر مشاركة بياناتك الشخصية مع الأطراف التالية بغرض
            تحسين محتوى الدورة وتحسين عمليات التسويق:
          </Typography>

          <Typography>
            كما يمكننا من وقت لآخر مشاركة بياناتك الشخصية (في شكل مجهول) مع
            الباحثين التربويين الخارجيين بغرض إجراء البحوث التعليمية.
          </Typography>
          <Typography variant="h4" color="primary">
            التغييرات التي تطرأ على هذه السياسة:
          </Typography>
          <Typography>
            باستثناء الحد الذي يقتضيه القانون المعمول به، نحتفظ بحق تحديث هذه
            السياسة لتعكس التغييرات التي تطرأ على ممارسات المعلومات لدينا من
            خلال نشر إشعار بالتحديث في خدماتنا بوضوح، والحصول على موافقتك، حسب
            الاقتضاء. ستصبح أي تحديثات سارية المفعول فور نشر التحديثات على هذه
            السياسة وتنطبق على كل المعلومات المُجمَّعة عنك، أو عند الاقتضاء،
            بناءً على موافقتك. أنت توافق على مراجعة هذه السياسة بشكل دوري. إذا
            أدخلنا أي تغييرات على هذه السياسة، فسنغير تاريخ "آخر تحديث" أعلاه.
          </Typography>
          <Typography variant="h4" color="primary">
            حقوقك وكيفية ممارستها:
          </Typography>
          <Typography>
            تمنحك قوانين الخصوصية حقوقًا معينة فيما يتعلق بالبيانات التي نحتفظ
            بها عنك. وفيما يلي عرض موجز لهذه الحقوق.
          </Typography>
          <Typography>
            <ul>
              <li>
                مع بعض الاستثناءات، يحق لك الحصول على نسخة من البيانات التي
                نحتفظ بها عنك. قد نفرض رسومًا معقولة مقابل النسخ الإضافية من تلك
                البيانات بعد النسخة الأولى، بناءً على التكاليف الإدارية الخاصة
                بنا. عندما تكون البيانات هي البيانات التي قدمتها لنا، يحق لك
                تلقي نسختك منها بتنسيق إلكتروني مشترك، وتقديم نسخ منها إلى أشخاص
                آخرين إن أردت ذلك.
              </li>
              <li>
                يحق لك تصحيح البيانات الشخصية التي نحتفظ بها عنك إذا كانت غير
                دقيقة في الواقع.
              </li>
              <li>
                في بعض الظروف، يحق لك محو البيانات الشخصية التي نحتفظ بها عنك.
              </li>
              <li>
                يحق لك أن تطلب منا التوقف عن استخدام بياناتك الشخصية لأغراض
                التسويق.
              </li>
              <li>
                إذا كنا نعالج بياناتك الشخصية على أساس موافقتك، فيحق لك سحب تلك
                الموافقة في أي وقت، وفي هذه الحالة سنتوقف عن المعالجة ما لم يكن
                لدينا أساس قانوني آخر للمتابعة وفقًا له. وفي بعض الحالات، ستكون
                الطريقة الوحيدة لآفاق بلا حدود لوقف هذه المعالجة هي إنهاء
                حساباتك. ستطلب آفاق بلا حدود تأكيدك قبل إنهاء حسابك من خلال
                عنوان البريد الإلكتروني الذي تطلب من خلاله سحب موافقتك. إذا لم
                تقدم تأكيدًا كتابيًا في غضون 21 يوم حتى تنهي آفاق بلا حدود
                حسابك، فسيتم اعتبار طلبك لسحب موافقتك مُلغًى.
              </li>
            </ul>
          </Typography>
          <Typography>
            من أجل حمايتك وحماية الآخرين، قد نطلب منك إثبات هويتك قبل ممارسة هذه
            الحقوق.
          </Typography>
          <Typography>
            إذا لم تكن راضيًا عن كيفية تعاملنا مع بياناتك الشخصية، فيحق لك
            التواصل معنا
          </Typography>
        </Stack>
      </div>
    </div>
  );
};

export default ArPrivacyPolicy;
