import * as React from "react";
import DraggableSection from "./DraggableSection/DraggableSection";
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd";

import { SectionType } from "../AddBlogForm";

export type DraggableListProps = {
  items: SectionType[];
  onDragEnd: OnDragEndResponder;
  onDelete?: any;
  onOpen: any;
  onAddFile: any;
};

const DraggableSections = React.memo(
  ({ items, onDragEnd, onDelete, onOpen, onAddFile }: DraggableListProps) => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-list" type="droppableItem">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {items.map((item, index) => (
                <DraggableSection
                  key={item.id}
                  id={`section-${item.id}`}
                  item={item}
                  index={index}
                  onDelete={onDelete}
                  onOpen={onOpen}
                  onAddFile={onAddFile}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
);

export default DraggableSections;
