import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { FiEdit } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { IoVideocam, IoVideocamOutline } from "react-icons/io5";
import i18next from "i18next";

type Props = {
  id: string;
};

const btnStyle = {
  borderRadius: "50px",
  padding: "10px 15px",
  gap: "5px",
  width: { xs: "90%", sm: "auto" },
};

const iconStyle = { color: "#fff", fontSize: "1rem" };

const typographyStyle = {
  whiteSpace: "nowrap",
  fontSize: "0.8rem",
  coloe: "#fff",
};

const InstructorDetailsButtons = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        gap: "20px",
        alignItems: "center",
        padding: "20px 0",
        flexWrap: { xs: "wrap", lg: "nowrap" },
        justifyContent: { xs: "center", sm: "flex-start" },
        flexDirection: { xs: "column", sm: "row" },
      }}
    >
      <Button
        color="mypurple2"
        variant="contained"
        onClick={() => navigate("/add-consultation", { state: props.id })}
        sx={btnStyle}
      >
        <FiEdit style={iconStyle} />
        <Typography sx={typographyStyle}>
          {i18next.language == "en" ? "Ask a Text Question" : "اسأل سؤال نصي"}
        </Typography>
      </Button>
      <Button
        variant="contained"
        color="myred2"
        onClick={() => navigate("/add-video-consultation", { state: props.id })}
        sx={btnStyle}
      >
        <IoVideocam style={iconStyle} />
        <Typography sx={typographyStyle}>
          {i18next.language == "en"
            ? "Book a Video Consultation"
            : "احجز استشارة فيديو"}
        </Typography>
      </Button>
    </Box>
  );
};

export default InstructorDetailsButtons;
