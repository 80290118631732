import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation, Trans } from "react-i18next";
import { BlogCard } from "../../components/Blog/blog-card";
import { ScrollTop } from "../../components/Common/scroll-top.component";
import { Pagination } from "@mui/material";
import { fetchApi } from "../../api/api";
// import { BlogResponse } from "../../interfaces/api-interfaces/blog";
import { Skeleton } from "@mui/material";
import { GridTitle } from "../../components/Common/grids-common/grid-title";
import { GridSearch } from "../../components/Common/grids-common/grid-search";
import { GridSelect } from "../../components/Common/grids-common/grid-select";
import { LoadingPage } from "../../components/Common/loadingPage.component";
import { fetchBlogs } from "../../api/endpoints-functions";
import { BlogResponse } from "../../interfaces/new-api-interfaces/blog";
import { defaultTranslation } from "../../untilites/functions";
import useDebounce from "../../hooks/useDebounce";
import GridFilter from "../../components/Common/grids-common/grid-filter";

type filterType = "default" | "date";

const BlogPage: React.FC = () => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const search = useDebounce(searchValue);
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);

  const { data, isLoading, isError, isFetching } = useQuery(
    ["get-blogs", search, selectedCategories],
    () => fetchBlogs(search, selectedCategories),
    { staleTime: 120000 }
  );

  const ITEMS_PER_PAGE: number = 9;
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(ITEMS_PER_PAGE);

  const paginatedData = data?.slice(min, max);

  const paginate = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPageNumber(page);
    setMin(ITEMS_PER_PAGE * (page - 1));
    setMax(page * ITEMS_PER_PAGE);
  };

  return (
    <div>
      <GridTitle>{t("NavHeaderBlogGrid")}</GridTitle>
      <div className="section section-padding-bottom">
        <div className="container">
          <div className="row justify-content-center align-items-center max-mb-20">
            <div
              className="col-sm-10 col-md-2 max-mb-10 mb-20"
              style={{
                width: !isLoading && !isFetching ? "fit-content" : "",
              }}
            >
              {!isLoading && !isFetching ? (
                <p className="result-count">
                  <Trans i18nKey="BlogGridSubTitle" count={data?.length}>
                    We found <span>count</span> blogs available for you
                  </Trans>
                </p>
              ) : (
                <p className="result-count">
                  <Skeleton variant="text" height={"3rem"} width={"100%"} />
                </p>
              )}
            </div>
            <GridSearch
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
            <GridFilter
              priceRange={null}
              selectedCategories={selectedCategories}
              setPriceRange={null}
              setSelectedCategories={setSelectedCategories}
            />
          </div>

          <div
            className={
              !isLoading && !isFetching
                ? "row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30 justify-content-center justify-content-md-start"
                : ""
            }
          >
            {!isLoading && !isFetching ? (
              <>
                {data && data.length ? (
                  paginatedData?.map((blog: BlogResponse) => (
                    <div
                      className="col-12 col-md-6 col-lg-4 my-2"
                      key={blog.id}
                    >
                      <BlogCard
                        key={blog.id}
                        name={defaultTranslation(blog, "name")}
                        description={defaultTranslation(blog, "description")}
                        createdAt={blog.created_at}
                        id={blog.id}
                        image={blog.image}
                        createdBy={blog.created_by}
                        instructorId={blog.user_id}
                        categories={blog.categories}
                      />
                    </div>
                  ))
                ) : (
                  <p
                    style={{
                      margin: "0 auto",
                      fontSize: "2em",
                      textAlign: "center",
                    }}
                  >
                    {t("EmptyBlogs")}
                  </p>
                )}
              </>
            ) : (
              <div className="row max-mb-n30">
                {[1, 2, 3, 4, 5, 6].map((el) => (
                  <div className="col-12 col-md-6 col-lg-3 my-2 c-s" key={el}>
                    <Skeleton variant="rectangular" width={300} height={200} />
                    <div className="info">
                      <Skeleton
                        style={{ margin: "30px 0" }}
                        variant="text"
                        width="50%"
                      />
                      <Skeleton
                        style={{ margin: "30px 0" }}
                        variant="text"
                        width="70%"
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          {data?.length! > ITEMS_PER_PAGE ? (
            <Pagination
              sx={{
                display: "flex",
                justifyContent: "center",
                pt: "20px",
              }}
              count={Math.ceil(data?.length! / ITEMS_PER_PAGE) || 0}
              page={currentPageNumber}
              onChange={paginate}
              variant="outlined"
              color="primary"
              size="large"
              dir="ltr"
            />
          ) : null}
        </div>
      </div>
      <ScrollTop />
    </div>
  );
};

export default BlogPage;
