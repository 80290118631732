import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import PageBanner from "../../components/Course/course-details-section/page-banner";
import CourseDetailsSection from "../../components/Course/course-details-section/course-details-section";
import { ScrollTop } from "../../components/Common/scroll-top.component";
import { getImageFromServer } from "../../assets/JS/helpers";
import i18next from "i18next";
import { fetchCourse } from "../../api/endpoints-functions";
import { defaultTranslation } from "../../untilites/functions";
import { LoadingPage } from "../../components/Common/loadingPage.component";
import RelatedItems from "../../components/Course/course-details-section/related-items";

interface Props {}

const CourseDetails: React.FC<Props> = () => {
  const courseId = useParams();
  const {
    isLoading,
    isError,
    refetch,
    data: course,
    isFetching
  } = useQuery(["get-course-by-id", courseId.id], () =>
    fetchCourse(courseId?.id)
  );

  if (isLoading) return <LoadingPage />;
  return (
    <div>
      <PageBanner
        overviewImageUrl={getImageFromServer(course?.overview_image!)}
        lessons={course?.lesson_count!}
        students={course?.enrollment_number!}
        price={course?.price!}
        title={defaultTranslation(course, "name")}
        description={defaultTranslation(course, "web_description")}
        instructor={course?.instructors!}
        isLoading={isLoading}
        createdAt={course?.created_at!}
        id={course?.id!}
        inCart={course?.in_cart!}
        inWishlist={course?.in_wishlist!}
        enrolled={course?.is_bought!}
        refetch={refetch}
        instructorId={course?.instructors?.id!}
        guide_file={course?.guide_file!}
        isApproved={course?.is_approved!}
      />
      <CourseDetailsSection course={course} isLoading={isLoading} />

      <RelatedItems courseId={course?.id!} />
      <ScrollTop />
    </div>
  );
};

export default CourseDetails;
