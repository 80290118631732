import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import logo from "../../assets/images/logo/logo-ar.png";
import logoEn from "../../assets/images/logo/logo-en.png";
import aboutusImage1 from "../../assets/images/about-us/about_us_1.png";
import aboutusImage2 from "../../assets/images/about-us/about_us_2.png";
import aboutusImage3 from "../../assets/images/about-us/about_us_3.png";
import i18next from "i18next";

const AboutPage: React.FC = () => {
  const { i18n } = useTranslation();
  return (
    <section
      style={{
        padding: "5rem 0",
        minHeight: "70vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "5rem",
      }}
      className="container"
    >
      <Stack
        sx={{ flexDirection: { xs: "column", md: "row" } }}
        justifyContent={"space-between"}
        gap={5}
        alignItems={"center"}
        width={"100%"}
      >
        <Stack sx={{ width: { xs: "90%", md: "30%" } }}>
          <div style={{ width: "100%", margin: "0 auto 30px" }}>
            <img
              src={i18n.language === "en" ? logoEn : logo}
              alt="UHT Logo"
              width={"100%"}
            />
          </div>
        </Stack>
        {i18n.language === "en" ? (
          <Stack sx={{ width: { xs: "90%", md: "80%" }, gap: 2 }}>
            <Typography variant="h1" color={"primary"}>
              Who We Are
            </Typography>
            <Typography fontSize={18} letterSpacing={0.5} textAlign={"justify"}>
              We are a limited liability company specialized in personal,
              administrative and leadership development In 2003. We also
              contributed to developing individuals and institutions and
              providing them with human development programs. Our work team was
              composed of local and international expertise with professional
              preparation.
            </Typography>
          </Stack>
        ) : (
          <Stack sx={{ width: { xs: "90%", md: "80%" }, gap: 2 }}>
            <Typography variant="h1" color={"primary"}>
              من نحن
            </Typography>
            <Typography fontSize={18} letterSpacing={0.5} textAlign={"justify"}>
              نحن شركة محدودة المسؤوليّة متخصصة بالتطوير الشخصي والإداري
              والقيادي تأسّست عام 2003. كما ساهمنا في تطوير الأفراد والمؤسّسات
              وتزويدهم ببرامج التنمية البشريّة. وقد تشكل فريق عملنا من الخبرات
              المحليّة والعالميّة ذات الإعداد المحترف.
            </Typography>
          </Stack>
        )}
      </Stack>

      <Stack
        sx={{ flexDirection: { xs: "column", md: "row" } }}
        justifyContent={"space-between"}
        gap={5}
        alignItems={"center"}
        width={"100%"}
      >
        <Stack sx={{ width: { xs: "90%", md: "30%" } }} alignItems={"center"}>
          <div
            style={{
              width: "60%",
              marginBottom: "30px",
              // marginRight: i18next.language === "en" ? "40%" : "0",
              // marginLeft: i18next.language === "en" ? "0" : "40%",
            }}
          >
            <img src={aboutusImage2} alt="UHT Logo" width={"100%"} />
          </div>
          <Typography
            variant="h1"
            color={"primary"}
            // sx={{ marginRight: "40%" }}
          >
            {i18next.language === "en" ? "Our Mission" : "رسالتنا"}
          </Typography>
        </Stack>
        {i18n.language === "en" ? (
          <Stack sx={{ width: { xs: "90%", md: "80%" }, gap: 2 }}>
            <Typography variant="h1" color={"primary"}>
              We Inspire Greatness
            </Typography>
            <Typography fontSize={18} letterSpacing={0.5} textAlign={"justify"}>
              We will exert all our energies and expertise to serve individuals
              and institutions. We will seek to influence them in a way that
              moves them to personal and academic greatness so that they can
              achieve their social, material, scientific and moral goals.
            </Typography>
          </Stack>
        ) : (
          <Stack sx={{ width: { xs: "90%", md: "80%" }, gap: 2 }}>
            <Typography variant="h1" color={"primary"}>
              نلهم العَظَمة
            </Typography>
            <Typography fontSize={18} letterSpacing={0.5} textAlign={"justify"}>
              سوف نبذل كل طاقاتنا وخبراتنا لخدمة الأفراد والمؤسّسات. وسوف نسعى
              إلى التآثير بهم تأثيراً ينقلهم إلى العظمة الشخصية والعلميّة
              ليستطيعوا تحقيق غاياتهم الاجتماعيّة والماديّة والعلميّة المعنوية
            </Typography>
          </Stack>
        )}
      </Stack>

      <Stack
        sx={{ flexDirection: { xs: "column", md: "row" } }}
        justifyContent={"space-between"}
        gap={5}
        alignItems={"center"}
        width={"100%"}
      >
        <Stack sx={{ width: { xs: "90%", md: "30%" } }} alignItems={"center"}>
          <div
            style={{
              width: "60%",
              marginBottom: "30px",
            }}
          >
            <img src={aboutusImage3} alt="UHT Logo" width={"100%"} />
          </div>
          <Typography
            variant="h1"
            color={"primary"}
          >
            {i18next.language === "en" ? "Our Vision" : "رؤيتنا"}
          </Typography>
        </Stack>
        {i18n.language === "en" ? (
          <Stack sx={{ width: { xs: "90%", md: "80%" }, gap: 2 }}>
            <Typography variant="h1" color={"primary"}>
              The First Choice
            </Typography>
            <Typography fontSize={18} letterSpacing={0.5} textAlign={"justify"}>
              Our vision is to be the “first choice” in the Middle East as a
              training company specialized in providing individuals and
              institutions with integrated solutions in personal, administrative
              and leadership development.
            </Typography>
          </Stack>
        ) : (
          <Stack sx={{ width: { xs: "90%", md: "80%" }, gap: 2 }}>
            <Typography variant="h1" color={"primary"}>
              الاختيار الأوَّل
            </Typography>
            <Typography fontSize={18} letterSpacing={0.5} textAlign={"justify"}>
              رؤيتنا أن نكون "الاختيار الأول" في الشرق الأوسط كشركة تدريب
              متخضّصة في تزويد لأفراد والمؤسّسات بالحلول المتكاملة في التطوير
              الشخصي والإداري والقيادي
            </Typography>
          </Stack>
        )}
      </Stack>

      <Stack
        sx={{ flexDirection: { xs: "column", md: "row" } }}
        justifyContent={"space-between"}
        gap={5}
        alignItems={"center"}
        width={"100%"}
      >
        <Stack sx={{ width: { xs: "90%", md: "30%" } }} alignItems={"center"}>
          <div
            style={{
              width: "60%",
              marginBottom: "30px",
            }}
          >
            <img src={aboutusImage1} alt="UHT Logo" width={"100%"} />
          </div>
          <Typography
            variant="h1"
            color={"primary"}
          >
            {i18next.language === "en" ? "Our Values" : "قيمنا"}
          </Typography>
        </Stack>
        {i18n.language === "en" ? (
          <Stack sx={{ width: { xs: "90%", md: "80%" }, gap: 2 }}>
            <Typography variant="h1" color={"primary"}>
              Value system
            </Typography>
            <Stack sx={{ textAlign: "center", gap: 2, fontSize: 18 }}>
              <Typography fontSize={"inherit"}>
                Respect for Human Dignity
              </Typography>
              <Typography fontSize={"inherit"} textAlign={"start"}>
                Earning Trust
              </Typography>
              <Typography fontSize={"inherit"} textAlign={"end"}>
                Abundance Mentality
              </Typography>
              <Typography fontSize={"inherit"} textAlign={"center"}>
                Initiative Spirit
              </Typography>
              <Typography fontSize={"inherit"} textAlign={"start"}>
                Excellence and Mastery
              </Typography>
            </Stack>
          </Stack>
        ) : (
          <Stack sx={{ width: { xs: "90%", md: "80%" }, gap: 2 }}>
            <Typography variant="h1" color={"primary"}>
              منظومة القيم
            </Typography>
            <Stack sx={{ textAlign: "center", gap: 2, fontSize: 18 }}>
              <Typography fontSize={"inherit"}>
                احترام الكرامة الإنسانية
              </Typography>
              <Typography fontSize={"inherit"} textAlign={"start"}>
                استحقاق الثقة
              </Typography>
              <Typography fontSize={"inherit"} textAlign={"end"}>
                عقلية الوفرة
              </Typography>
              <Typography fontSize={"inherit"} textAlign={"center"}>
                روح المبادرة
              </Typography>
              <Typography fontSize={"inherit"} textAlign={"start"}>
                التميز والإتقان
              </Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
    </section>
  );
};
export default AboutPage;
