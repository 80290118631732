import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import i18n from "i18next";

export const WhiteButton = ({ path, en, ar, padding }: any) => {
  const handleMouseOver = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.style.backgroundColor = "lightgray";
  };

  const handleMouseOut = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.style.backgroundColor = "white";
  };
  const navigate = useNavigate();

  return (
    <Button
      variant="contained"
      style={{
        backgroundColor: "white",
        whiteSpace:"nowrap",
        borderRadius: "20px",
        padding: padding ? padding : "5px 20px",
        color: "#222",
        textTransform:"none"
      }}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={(event) => {
        event.stopPropagation();
        navigate(path);
      }}
    >
      <Typography variant="body1" fontSize="1rem">
        {i18n.language === "en" ? en : ar}
      </Typography>
    </Button>
  );
};