import React from "react";
import i18n from "i18next";
import { Button } from "@mui/material";

interface Props {
  arLabel: string;
  enLabel: string;
  handlePress?: Function;
  isFail?: boolean;
  classesForStyles?: string;
  type?: "button" | "submit";
  disabled?: boolean;
}

export const CustomButton: React.FC<Props> = ({
  classesForStyles,
  handlePress,
  arLabel,
  enLabel,
  isFail,
  type = "button",
  disabled,
}) => {
  return (
    <Button
      variant="contained"
      className={
        `col-5 mx-auto my-3 p-2 text-light text-center rounded ` +
        classesForStyles
      }
      sx={{
        cursor: !disabled ? "pointer" : "not-allowed",
        bgcolor: !isFail ? "primary.main" : "error.main",
      }}
      onClick={() => (handlePress ? handlePress() : null)}
      type={type}
      disabled={disabled}
    >
      {i18n.language === "en" ? enLabel : arLabel}
    </Button>
  );
};

export default CustomButton;
