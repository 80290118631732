import React, { useEffect, useRef, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import MiniCartList from "./mini-cart-list";
import { fetchCartItemsCount } from "../../api/endpoints-functions";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

interface Props {}

const HeaderCart: React.FC<Props> = () => {
  const [isMiniCartVisible, setIsMiniCartVisible] = useState(false);
  const miniCartRef = useRef(null);
  const navigate = useNavigate();
  const { data, isLoading } = useQuery(["get-cart-count"], () =>
    fetchCartItemsCount()
  );

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      //@ts-ignore
      if (
        miniCartRef.current &&
        //@ts-ignore
        !miniCartRef.current.contains(event.target as Node) &&
        target.id !== "cart-id"
      ) {
        setIsMiniCartVisible(false);
      }
    };

    // Add event listener when mini-cart is visible
    if (isMiniCartVisible) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    // Cleanup event listener when component is unmounted or mini-cart is hidden
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isMiniCartVisible]);

  const toggleMiniCartVisibility = () => {
    setIsMiniCartVisible((prev) => !prev);
  };

  const handelClick = () => {
    if (window.innerWidth > 768) {
      toggleMiniCartVisibility();
    } else {
      navigate("/cart");
    }
  };

  return (
    <div className="header-cart">
      <Box
        className="header-cart-btn"
        sx={{ cursor: "pointer" }}
        onClick={handelClick}
      >
       {data?.count! > 0 && <span>
          {data ? (
            <span className="cart-count">{data.count}</span>
          ) : (
            <span className="cart-count">
              <CircularProgress sx={{ color: "#fff" }} size={6} />
            </span>
          )}
        </span>}
        <svg
          width="20"
          height="20"
          fill="currentColor"
          className="bi bi-cart4"
          viewBox="0 0 16 16"
          id="cart-id"
        >
          <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
        </svg>
      </Box>
      {isMiniCartVisible && (
        <div className="header-mini-cart" ref={miniCartRef}>
          <MiniCartList />
        </div>
      )}
    </div>
  );
};

export default HeaderCart;
