import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { CetificationItem } from "./content-sidebar-certificate-item";

interface Props {
  currentLesson: number;
  setCertificationsOpen: Function;
  isEnrolled: boolean;
  expanded: string | false;
  handleChangeExpanded: Function;
  setOpenedSideNav?: Function;
  name: string;
  certificationsOpen: boolean;
  progress: number;
}

export const SidebarCertificationSection: React.FC<Props> = ({
  currentLesson,
  setCertificationsOpen,
  name,
  isEnrolled,
  expanded,
  handleChangeExpanded,
  setOpenedSideNav,
  certificationsOpen,
  progress
}) => {
  return (
    <Accordion
      expanded={expanded == `panel${9999}`}
      onChange={handleChangeExpanded(`panel${9999}`)}
    >
      <AccordionSummary>
        <Typography variant="h6">{name}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className="ml-5">
          <ul
            className="section-content"
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            <CetificationItem
              setOpenedSideNav={setOpenedSideNav}
              isEnrolled={isEnrolled}
              isSectionFree={true}
              currentLesson={currentLesson}
              setCertificationsOpen={setCertificationsOpen}
              certificationsOpen={certificationsOpen}
              progress={progress}
            />
          </ul>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
