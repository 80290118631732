import { Box, Stack, TextField, Typography } from "@mui/material";
import { testQuestionForStudent } from "../../interfaces/new-api-interfaces/test";
import { useTranslation } from "react-i18next";
import { defaultTranslation } from "../../untilites/functions";
import i18next from "i18next";

interface Props {
  question: testQuestionForStudent;
  setValue: any;
  getValues: Function;
  isGraded?: 0 | 1 | boolean;
}

const inputStyle = {
  width: { xs: "100%", sm: "45%" },
  marginBottom: "30px",
};

const WrittenQuestion: React.FC<Props> = ({
  question,
  getValues,
  setValue,
  isGraded,
}) => {
  const { t } = useTranslation();

  const handleValueChange = (value: string) => {
    const currentanswer = getValues("answer");

    const existingAnswerIndex = currentanswer.findIndex(
      (answer: any) => answer?.question_id === question?.id
    );

    if (existingAnswerIndex !== -1) {
      // Update the existing answer
      const updatedanswer = currentanswer.map((answer: any, index: number) =>
        index === existingAnswerIndex ? { ...answer, value: value } : answer
      );

      // Set the updated answer array using setValue
      setValue("answer", updatedanswer);
    } else {
      // Create a new answer object and add it to the answer array
      const newAnswer = { type: "written", question_id: question.id, value };
      const updatedanswer = [...currentanswer, newAnswer];

      // Set the updated answer array using setValue
      setValue("answer", updatedanswer);
    }
  };

  return (
    <Box sx={{ border: "solid 1px #ccc" }}>
      <Box sx={{ bgcolor: "mypurple2.main", padding: "20px" }}>
        <Typography variant="h5" color="#fff">
          {i18next.language == "en"
            ? `# ${question.order} Question`
            : `السؤال # ${question.order}`}
        </Typography>
      </Box>
      <Box sx={{ padding: "20px", bgcolor: "#FBFBFB" }}>
        <Stack width={"90%"} direction={"row"} justifyContent={"space-between"}>
          <Typography variant="h6">
            {defaultTranslation(question, "name")}
          </Typography>
          {isGraded == 1 && (
            <Typography variant="h6" color="mypurple2.main">
              {question.mark} {t("Mark")}
            </Typography>
          )}
        </Stack>

        <TextField
          label={i18next.language == "en" ? "Your Answer" : "اكتب إجابتك"}
          placeholder={i18next.language == "en" ? "Your Answer" : "اكتب إجابتك"}
          required
          sx={{ ...inputStyle, marginTop: "10px", width: "90%" }}
          onChange={(e) => handleValueChange(e.target.value)}
          multiline
        />
      </Box>
    </Box>
  );
};

export default WrittenQuestion;
