import { useState } from "react";
import AddCourse from "../../components/InstructorDashboard/AddCourse/add-course";
import { Box } from "@mui/system";
import { Button, Step, StepLabel, Stepper, Typography } from "@mui/material";
import React from "react";
import AddRelatedCourseItems from "../../components/InstructorDashboard/AddCourse/add-related-course-items";
import CourseDND from "../../components/InstructorDashboard/DashboardCourseComponents/course-dnd";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import AddCourseCertificates from "../../components/InstructorDashboard/DashboardCourseComponents/add-course-certificates";

const AddCourseHOC = () => {
  const { t } = useTranslation();
  const steps = [
    { en: "Add Course Information", ar: "إضافة معلومات الدورة" },
    { en: "Add Related Items", ar: "إضافة عناصر ذات علاقة" },
    { en: "Add Course Resources", ar: "إضافة محتوى الدورة" },
    { en: "Add Certificates", ar: "إضافة شهادات" },
  ];
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [courseId, setCourseId] = useState(0);

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  return (
    <>
      <Box className="container" sx={{ paddingTop: "50px" }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">
                  {i18next.language == "en" ? "Optional" : "اختياري"}
                </Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={index} {...stepProps}>
                <StepLabel {...labelProps}>
                  {i18next.language == "en" ? label.en : label.ar}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep == 0 ? (
          <React.Fragment>
            <AddCourse setCourseId={setCourseId} handleNext={handleNext} />
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
            </Box>
          </React.Fragment>
        ) : activeStep == 1 ? (
          <React.Fragment>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              {isStepOptional(activeStep) && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSkip}
                  sx={{ mr: 1 }}
                >
                  {t("skip")}
                </Button>
              )}
            </Box>
            <AddRelatedCourseItems id={courseId} handleNext={handleNext} />
          </React.Fragment>
        ) : activeStep == 2 ? (
          <React.Fragment>
            <CourseDND isAdd id={courseId} handleNext={handleNext} />
          </React.Fragment>
        ) : (
          <AddCourseCertificates id={courseId} />
        )}
      </Box>
    </>
  );
};

export default AddCourseHOC;
