import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import i18next from "i18next";
import { fetchApi } from "../../../api/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

type Props = {
  lessonId: number;
  lessonOrder: number;
  modalOpen: boolean;
  handleModalClose: Function;
  courseId: number;
};

function LessonDeleteModal({
  modalOpen,
  lessonId,
  handleModalClose,
  lessonOrder,
  courseId,
}: Props) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { isLoading, mutateAsync: DeleteLesson } = useMutation(
    (id: any) => fetchApi(`/course/lesson/${id}`, "Delete"),
    {
      onError: (error: any) => {
        handleModalClose();
        toast.error(
          i18next.language === "en"
            ? "Something went wrong, please try again later."
            : "حدث خطأ ما، يرجى المحاولة مرة أخرى لاحقًا."
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["get-sections", courseId]);
        handleModalClose();
        toast.success(
          i18next.language === "en"
            ? "Lesson Deleted successfully"
            : "تم حذف الدرس بنجاح"
        );
      },
    }
  );

  return (
    <Modal
      open={modalOpen}
      onClose={() => handleModalClose()}
      sx={{
        width: "40vw",
        height: "20vh",
        position: "absolute",
        top: "30vh",
        left: "30vw",
        right: "30vw",
        direction: i18next.language == "ar" ? "rtl" : "ltr",
      }}
    >
      <Stack
        sx={{ py: 2, height: "100%", boxSizing: "border-box" }}
        direction="column"
      >
        <Paper
          sx={{
            flex: 1,
            mx: "auto",
            width: "90%",
            p: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          dir={i18next.language == "en" ? "ltr" : "rtl"}
        >
          {!isLoading && (
            <>
              <Stack
                direction="row"
                flexWrap="wrap"
                gap="2%"
                justifyContent="flex-start"
                sx={{ width: "100%" }}
              >
                <Typography
                  variant="h6"
                  sx={{ width: "100%", paddingBottom: "20px" }}
                >
                  {i18next.language === "en"
                    ? `Are You Sure You Want To Delete Lesson Number #${lessonOrder}`
                    : `هل انت متأكد من حذف الدرس رقم #${lessonOrder}`}
                </Typography>

                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  sx={{ padding: "10px" }}
                  onClick={() => DeleteLesson(lessonId)}
                >
                  <Typography fontSize="1rem" color="#fff">
                    {t("GlobalDelete")}
                  </Typography>
                </Button>
                <Button
                  type="submit"
                  variant="outlined"
                  size="small"
                  sx={{ padding: "10px" }}
                  onClick={() => {
                    handleModalClose();
                  }}
                >
                  <Typography fontSize="1rem" color="primary">
                    {t("cancel")}
                  </Typography>
                </Button>
              </Stack>
            </>
          )}

          {isLoading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Paper>
      </Stack>
    </Modal>
  );
}

export default LessonDeleteModal;
