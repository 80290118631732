import { Navigate, Outlet } from "react-router-dom";
import { useAtomValue } from "jotai";
import { roleAtom } from "../store";

const NotInstructorRoutes = () => {
  const role = useAtomValue(roleAtom);
  return role != "instructor" ? <Outlet /> : <Navigate to="/" />;
};

export default NotInstructorRoutes;
