import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { TiDeleteOutline } from "react-icons/ti";
import { t } from "i18next";

type Props = {
  control: any;
  getValues: Function;
  setValue: Function;
  errors: any;
  formMethod: "update" | "create";
  testId: number;
};

const AddEditTestEvaluationRanges = (props: Props) => {
  const [text, setText] = useState("");
  const [textEn, setTextEn] = useState("");
  const [value, setValue] = useState("");

  const handleAdd = (text: string, textEn: string, value: string) => {
    const currentOptions = props.getValues("evaluatable");

    const newOptions = [
      ...currentOptions,
      {
        value: value,
        text_en: textEn,
        text: text,
      },
    ];
    props.setValue("evaluatable", newOptions);
  };

  const handleDelete = (index: number, id: number) => {
    const currentOptions = props.getValues("evaluatable");
    const newOptions = [...currentOptions];

    newOptions.splice(index, 1);
    props.setValue("evaluatable", newOptions);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stack
        direction="row"
        alignItems="center"
        gap={3}
        paddingBottom="20px"
        flexWrap="wrap"
      >
        <TextField
          label={t("moreThan")}
          defaultValue=""
          onChange={(e) => {
            setValue(e.target.value);
          }}
          value={value}
          sx={{ width: "20%" }}
          size="small"
          type="number"
        />
        <TextField
          label={t("resultAr")}
          defaultValue=""
          onChange={(e) => {
            setText(e.target.value);
          }}
          value={text}
          sx={{ width: "35%" }}
          size="small"
        />
        <TextField
          label={t("resultEn")}
          defaultValue=""
          onChange={(e) => {
            setTextEn(e.target.value);
          }}
          value={textEn}
          sx={{ width: "35%" }}
          size="small"
        />

        <Button
          onClick={() => {
            handleAdd(text, textEn, value);
            setText("");
            setTextEn("");
            setValue("");
          }}
          variant="contained"
          size="small"
          disabled={text == "" || value == ""}
        >
          <Typography fontSize="small" whiteSpace="nowrap">
            {t("addEvaluation")}
          </Typography>
        </Button>
      </Stack>
      <Controller
        name="evaluatable"
        control={props.control}
        defaultValue={[]}
        render={({ field: { onChange, value } }) => (
          <>
            {value.map((option: any, index: number) => (
              <Stack
                key={index}
                direction="row"
                alignItems="center"
                paddingY={2}
                gap={2}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleDelete(index, option.id)}
                  >
                    <TiDeleteOutline />
                  </IconButton>
                </Box>
                <Stack
                  direction="row"
                  gap={2}
                  width="90%"
                  flexWrap="wrap"
                  sx={{
                    border: "solid",
                    borderWidth: "1px",
                    borderColor: "#ccc",
                    borderRadius: "10px",
                    padding: "20px",
                  }}
                >
                  <TextField
                    label={`${t("moreThan")}`}
                    defaultValue=""
                    value={option.value}
                    onChange={(e) => {
                      const newOptions = [...value];
                      newOptions[index].value = e.target.value;
                      onChange(newOptions);
                    }}
                    sx={{ width: "20%" }}
                    size="small"
                  />
                  <TextField
                    label={`${t("resultAr")}`}
                    defaultValue=""
                    value={option.text}
                    onChange={(e) => {
                      const newOptions = [...value];
                      newOptions[index].text = e.target.value;
                      onChange(newOptions);
                    }}
                    sx={{ width: "37%" }}
                    size="small"
                  />
                  <TextField
                    label={`${t("resultEn")}`}
                    defaultValue=""
                    value={option.text_en}
                    onChange={(e) => {
                      const newOptions = [...value];
                      newOptions[index].text_en = e.target.value;
                      onChange(newOptions);
                    }}
                    sx={{ width: "37%" }}
                    size="small"
                  />
                </Stack>
              </Stack>
            ))}
          </>
        )}
      />
    </Box>
  );
};

export default AddEditTestEvaluationRanges;
