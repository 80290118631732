import { Box, Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import DraggableSections from "./DraggableSections";
import TextModal from "./TextModal";
import { SectionType } from "../AddBlogForm";
import { useTranslation } from "react-i18next";

interface Props {
  AddSectionHandler: any;
  onDragEnd: any;
  dragItems: SectionType[];
  onDelete: any;
  openTextModalHandler: any;
  selectedSection: SectionType;
  textModalOpen: boolean;
  setTextModalOpen: any;
  addDescriptionHandler: any;
  arabicDescription: string;
  setArabicDescription: any;
  englishDescription: string;
  setEnglishDescription: any;
  onAddFile: any;
}

const SectionsList = ({
  AddSectionHandler,
  onDragEnd,
  dragItems,
  onDelete,
  openTextModalHandler,
  selectedSection,
  textModalOpen,
  setTextModalOpen,
  addDescriptionHandler,
  arabicDescription,
  setArabicDescription,
  englishDescription,
  setEnglishDescription,
  onAddFile,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ marginTop: "1rem", textAlign: "right" }}>
      <DraggableSections
        onDragEnd={onDragEnd}
        items={dragItems}
        onDelete={onDelete}
        onOpen={openTextModalHandler}
        onAddFile={onAddFile}
      />
      <TextModal
        section={selectedSection}
        open={textModalOpen}
        setOpen={setTextModalOpen}
        onSave={addDescriptionHandler}
        item={selectedSection}
        arabicDescription={arabicDescription}
        englishDescription={englishDescription}
        setArabicDescription={setArabicDescription}
        setEnglishDescription={setEnglishDescription}
      />
      <Button
        onClick={AddSectionHandler}
        variant="contained"
        startIcon={<Add />}
        sx={{ marginBottom: "1rem" }}
      >
        {t("addNewSection")}
      </Button>
    </Box>
  );
};

export default SectionsList;
