import React, { useState } from "react";
import i18n from "i18next";

import { Stack, TableSortLabel, Typography } from "@mui/material";
import InstructorQuizzItem from "./instructor-quizz-item";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from "@mui/material";
import "../../../assets/CSS/profile.css";
import { GlobalTestResponse } from "../../../interfaces/new-api-interfaces/test";
import { BlogResponse } from "../../../interfaces/new-api-interfaces/blog";
import InstructorBlogItem from "./instructor-blog-item";
import { BlogCard } from "../../Blog/blog-card";
import { defaultTranslation } from "../../../untilites/functions";

interface Props {
  blogs: BlogResponse[];
  isShowDeleteBtn: boolean;
  activeTab: string | null;
}

const Blogs: React.FC<Props> = (Props) => {
  //states to handle table pagination and sorting
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState<any>("created_at");
  const [order, setOrder] = useState<"asc" | "desc">("desc");

  const handleSort = (property: any) => {
    // Update the type of property
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div
      id="blogs"
      className={`profile-content-blogs tab-pane fade ${
        Props.activeTab === "blogs" ? "active" : ""
      }`}
    >
      <div className="learn-press-subtab-content">
        <div className="row">
          <div
            className={
              Props.blogs
                ? "row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30 justify-content-center justify-content-md-start"
                : ""
            }
          >
            {Props?.blogs?.map((blog) => (
              <div className="col-12 col-md-6 col-lg-4 my-2" key={blog.id}>
                <BlogCard
                  key={blog.id}
                  name={defaultTranslation(blog, "name")}
                  createdAt={blog.created_at}
                  id={blog.id}
                  image={blog.image}
                  createdBy={blog.created_by}
                  categories={blog.categories}
                  description={defaultTranslation(blog, "description")}
                  instructorId={blog.user_id}
                  isProfile={Props.isShowDeleteBtn}
                />
              </div>
            ))}
            {/* {Props.blogs?.length > 0 ? (
              <>
                {Props.isShowDeleteBtn == true && (
                  <Table>
                    <TableHead sx={{ bgcolor: "mypurple2.main" }}>
                      <TableRow>
                        <TableCell
                          sx={{ borderTopLeftRadius: "5px", width: "25%" }}
                        >
                          <TableSortLabel
                            active={orderBy === "name"}
                            direction={orderBy === "name" ? order : "asc"}
                            onClick={() => handleSort("name")}
                            sx={{
                              color: "#fff",
                              "&:hover": { color: "#fff" },
                            }}
                          >
                            <Typography fontSize="large" color="#fff">
                              {i18n.language === "en" ? "Name" : "الاسم"}
                            </Typography>
                          </TableSortLabel>
                        </TableCell>
                        <TableCell sx={{ width: "25%" }}>
                          <Typography fontSize="large" color="#fff">
                            {i18n.language === "en" ? "Description" : "الوصف"}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ width: "25%" }}>
                          <TableSortLabel
                            active={orderBy === "created_at"}
                            direction={orderBy === "created_at" ? order : "asc"}
                            onClick={() => handleSort("created_at")}
                            sx={{
                              color: "#fff",
                              "&:hover": { color: "#fff" },
                            }}
                          >
                            <Typography fontSize="large" color="#fff">
                              {i18n.language === "en" ? "Date" : "التاريخ"}
                            </Typography>
                          </TableSortLabel>
                        </TableCell>
                        {Props.isShowDeleteBtn && (
                          <TableCell
                            sx={{ borderTopRightRadius: "5px", width: "10%" }}
                          ></TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Props.blogs
                        ?.sort((a: any, b: any) => {
                          if (orderBy === null) {
                            return 0;
                          }
                          const aValue = a[orderBy];
                          const bValue = b[orderBy];
                          if (
                            typeof aValue === "string" &&
                            typeof bValue === "string"
                          ) {
                            return order === "asc"
                              ? aValue.localeCompare(bValue)
                              : bValue.localeCompare(aValue);
                          }
                          if (
                            typeof aValue === "number" &&
                            typeof bValue === "number"
                          ) {
                            return order === "asc"
                              ? aValue - bValue
                              : bValue - aValue;
                          }
                          return 0;
                        })
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((blog, index) => (
                          <InstructorBlogItem
                            key={index}
                            blog={blog}
                            index={index}
                            isShowDeleteBtn={Props.isShowDeleteBtn}
                          />
                        ))}
                    </TableBody>
                  </Table>
                )}
                {Props.isShowDeleteBtn == false && (
                  <div className="row">
                    {Props.blogs
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((blog, index) => (
                        <div
                          className="col-12 col-md-6 col-lg-4 my-2"
                          key={blog.id}
                        >
                          <BlogCard
                            name={defaultTranslation(blog, "name")}
                            description={defaultTranslation(
                              blog,
                              "description"
                            )}
                            createdAt={blog.created_at}
                            id={blog.id}
                            image={blog.image}
                            createdBy={blog.created_by}
                            instructorId={blog.user_id}
                          />
                        </div>
                      ))}
                  </div>
                )}
              </>
            ) : (
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="learn-press-message success">
                    <i className="bx bx-info-circle"></i>
                    {i18n.language === "en"
                      ? "you don't have Blogs yet!"
                      : "لا تملك مقالات سابقة!"}
                  </div>
                </div>
              </div>
            )}
            {Props.blogs?.length > 0 && Props.isShowDeleteBtn && (
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={Props.blogs?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                onRowsPerPageChange={(event) => {
                  setRowsPerPage(parseInt(event.target.value, 10));
                  setPage(0);
                }}
                dir="ltr"
              />
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
