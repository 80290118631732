import { MenuItem, Select } from "@mui/material";
import React from "react";

type Props = {
  filterValue: string;
  setFilterValue: Function;
  filterList: {
    value: string;
    title: string;
  }[];
};

export function GridSelect({ filterValue, setFilterValue, filterList }: Props) {
  return (
    <div className="col-sm-10 col-md-2 max-mb-10 mb-20">
      <Select
        MenuProps={{ disableScrollLock: true }}
        className="sort-by w-100"
        value={filterValue}
        //@ts-ignore
        onChange={(e) => setFilterValue(e.target.value)}
      >
        {filterList.map((item) => (
          <MenuItem value={item.value} key={item.value}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}
