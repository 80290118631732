import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { addToCartRequestType } from "../interfaces/api-interfaces";

import { fetchApi } from "../api/api";
import i18n from "i18next";
import { cartRequest } from "../interfaces/new-api-interfaces/cart";
import { useAtomValue } from "jotai";
import { userAtom } from "../store";

export const useAddToCart = () => {
  const user = useAtomValue(userAtom);
  const queryClient = useQueryClient();
  const { isLoading, isError, mutate, mutateAsync } = useMutation(
    ({ cartable_id, cartable_type, quantity }: cartRequest) =>
      fetchApi("/cart/cart", "POST", {
        cartable_id,
        quantity,
        cartable_type,
        user_id: user?.id,
      }),
    {
      onSuccess: () => {
        toast.success(
          i18n.language === "en"
            ? "added successfully to cart"
            : "تمت الإضافة الى السلّة بنجاح"
        );
        queryClient.invalidateQueries(["get-cart"]);
        queryClient.invalidateQueries(["get-cart-count"]);
      },
      onError: () => {
        toast.error(
          i18n.language === "en"
            ? "An error occurred while performing the operation"
            : "حدث خطأ أثناء تنفيذ العملية"
        );
      },
    }
  );
  return { isLoading, isError, mutate, mutateAsync };
};

export const useRemoveFromCart = () => {
  const user = useAtomValue(userAtom);
  const queryClient = useQueryClient();
  const { isLoading, isError, mutate, mutateAsync } = useMutation(
    ({ cartable_id, cartable_type, quantity }: cartRequest) =>
      fetchApi("/cart/cart", "POST", {
        cartable_id,
        quantity,
        cartable_type,
        user_id: user?.id,
      }),
    {
      onSuccess: () => {
        toast.success(
          i18n.language === "en"
            ? "successfully removed from cart"
            : "تم الحذف من السلة بنجاح"
        );
        queryClient.invalidateQueries(["get-cart-count"]);
        queryClient.invalidateQueries(["get-cart"]);
      },
      onError: () => {
        toast.error(
          i18n.language === "en"
            ? "An error occurred while performing the operation"
            : "حدث خطأ أثناء تنفيذ العملية"
        );
      },
    }
  );
  return { isLoading, isError, mutate, mutateAsync };
};

// export const useCartQuantityChange = () => {
//   const { isLoading, isError, mutate, mutateAsync } = useMutation(
//     ({ item, type, quantity }: addToCartRequestType) =>
//       fetchApi("/cart", "POST", {
//         item,
//         quantity,
//         type,
//       }),
//     {
//       onSuccess: () => {
//         toast.success(
//           i18n.language === "en"
//             ? "quantity edited successfully"
//             : "تم تعديل الكمية بنجاح"
//         );
//       },
//       onError: () => {
//         toast.error(
//           i18n.language === "en"
//             ? "An error occurred while performing the operation"
//             : "حدث خطأ أثناء تنفيذ العملية"
//         );
//       },
//     }
//   );
//   return { isLoading, isError, mutate, mutateAsync };
// };
