import { Box, Typography } from "@mui/material";
import React from "react";

type Props = {
  children: string;
};

export function GridTitle({ children }: Props) {
  return (
    <Box
      className="page-title-section section"
      sx={{
        padding: "3vh 0",
      }}
    >
      <div className="page-title">
        <div
          className="container"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Typography variant="h2" color="primary.main">
            {children}
          </Typography>
        </div>
      </div>
    </Box>
  );
}