import { Button, Modal, Paper, Stack } from "@mui/material";
import i18next from "i18next";
import React from "react";
import AddEditTestEvaluationRanges from "./add-edit-test-evaluation-ranges";
import { useTranslation } from "react-i18next";

type Props = {
  modalOpen: boolean;
  handleModalClose: Function;
  control: any;
  getValues: Function;
  setValues: Function;
  errors: any;
  formMethod: "update" | "create";
  testId: number;
};

const EvaluationRangesModal = ({
  modalOpen,
  handleModalClose,
  control,
  getValues,
  setValues,
  errors,
  formMethod,
  testId,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={modalOpen}
      onClose={() => handleModalClose()}
      sx={{
        direction: i18next.language == "en" ? "ltr" : "rtl",
        width: "60vw",
        height: "60vh",
        top: "20vh",
        left: "20vw",
        right: "20vw",
      }}
    >
      <Stack
        sx={{
          py: 0,
          height: "100%",
          boxSizing: "border-box",
          width: "100%",
        }}
        direction="column"
      >
        <Paper
          className="course-scrollable-side"
          sx={{
            flex: 1,
            mx: "auto",
            width: "100%",
            overflowY: "scroll",
            p: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            height: "100%",
          }}
          component={"div"}
          dir={i18next.language == "en" ? "ltr" : "rtl"}
        >
          <AddEditTestEvaluationRanges
            getValues={getValues}
            setValue={setValues}
            errors={errors}
            formMethod={formMethod}
            testId={testId}
            control={control}
          />
          <Button variant="contained" onClick={() => handleModalClose()}>
            {t("close")}
          </Button>
        </Paper>
      </Stack>
    </Modal>
  );
};

export default EvaluationRangesModal;
