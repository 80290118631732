import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify"; // Assuming you are using react-toastify
import { createLiveRoom } from "../../api/endpoints-functions";
import { createLiveConsultationRoom } from "../../interfaces/new-api-interfaces/consultations";
import { useGoogleLogin } from "@react-oauth/google";

const useCreateGoogleMeetConsultation = (id: number) => {
  const queryClient = useQueryClient();

  const { mutateAsync: accept, isLoading } = useMutation(
    ({ id, token }: createLiveConsultationRoom) => createLiveRoom(id, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["live-consultations"]);
      },
      onError: (error: any) => {
        Object.keys(error.response.data.errors).forEach((errorKey) => {
          toast.error(`${errorKey} field is required!`);
        });
      },
    }
  );

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      accept({
        id: id,
        token: tokenResponse.access_token,
      });
    },
    scope:
      "https://www.googleapis.com/auth/meetings.space.created email profile openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
  });

  return { googleLogin, isLoading };
};

export default useCreateGoogleMeetConsultation;
