import React, { useMemo } from "react";
import { CourseItem } from "./course-item";
import { Lesson } from "../../../interfaces/api-interfaces/courses";
import { Box } from "@mui/system";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Skeleton,
  Typography,
} from "@mui/material";
import { IoMdArrowDropdown } from "react-icons/io";
import { AiOutlinePlayCircle, AiOutlineFileText } from "react-icons/ai";
import { LiaBookSolid } from "react-icons/lia";
import { HiOutlineSpeakerWave } from "react-icons/hi2";
import { courseLesson } from "../../../interfaces/new-api-interfaces/course";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
interface Props {
  courseId: number;
  title: string;
  description: string;
  courseItems: courseLesson[];
  isLoading: boolean;
  isEnrolled: boolean;
  isSectionFree: boolean;
  index: number;
  color: string;
}

function countLessonTypes(lessons: courseLesson[]): {
  videos: number;
  audios: number;
  assets: number;
  tests: number;
} {
  return lessons.reduce(
    (count, lesson) => {
      switch (lesson.type) {
        case "video":
          count.videos++;
          break;
        case "audio":
          count.audios++;
          break;
        case "asset":
          count.assets++;
          break;
        case "test":
          count.tests++;
          break;
        default:
          break;
      }
      return count;
    },
    { videos: 0, audios: 0, assets: 0, tests: 0 }
  );
}

const countBoxStyle = {
  display: "flex",
  alignItems: "center",
  gap: "5px",
  fontSize: "large",
};

export const CurriculumSection: React.FC<Props> = (Props) => {
  const lessonCounts = useMemo(
    () => countLessonTypes(Props.courseItems),
    [Props.courseItems]
  );

  const { t } = useTranslation();

  return (
    <li className="single-curriculum-section">
      <Accordion>
        <AccordionSummary
          expandIcon={<IoMdArrowDropdown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            bgcolor: `${Props.color ? Props.color : "transparent"}`,
            padding: "10px 20px",
          }}
        >
          <Typography variant="h5">{Props.title}</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
        >
          <Typography>
            {Props.description ? (
              <div dangerouslySetInnerHTML={{ __html: Props.description }} />
            ) : null}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              borderTop: "1px solid #ddd",
              paddingTop: "20px",
            }}
          >
            <Typography variant="h5">
              {i18next.language == "en" ? "Included Media" : "محتوى الدورة"}
            </Typography>
            <Box sx={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
              {lessonCounts.videos != 0 && (
                <Box>
                  <Typography sx={countBoxStyle}>
                    <AiOutlinePlayCircle fontSize="x-large" />{" "}
                    {lessonCounts.videos} {t("Videos")}
                  </Typography>
                </Box>
              )}
              {lessonCounts.assets != 0 && (
                <Box>
                  <Typography sx={countBoxStyle}>
                    <LiaBookSolid fontSize="x-large" />
                    {lessonCounts.assets} {t("Readings")}
                  </Typography>
                </Box>
              )}
              {lessonCounts.audios != 0 && (
                <Box>
                  <Typography sx={countBoxStyle}>
                    <HiOutlineSpeakerWave fontSize="x-large" />
                    {lessonCounts.audios} {t("Audio")}
                  </Typography>
                </Box>
              )}
              {lessonCounts.tests != 0 && (
                <Box>
                  <Typography sx={countBoxStyle}>
                    <AiOutlineFileText fontSize="x-large" />{" "}
                    {lessonCounts.tests} {t("Quizz")}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          {/* <Accordion
            sx={{ boxShadow: "none" }}
            className="section-details-accordion"
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel2a-header"
              expandIcon={<IoMdArrowDropdown />}
              sx={{ padding: 0, width: "fit-content" }}
            >
              <Typography
                variant="h6"
                color="primary.main"
                sx={{ marginRight: "20px", fontWeight: 600 }}
              >
                {i18next.language == "en"
                  ? "Show Module's Lessons Details"
                  : "إظهار دروس القسم "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {!Props.isLoading ? (
                <>
                  {Props.courseItems.length ? (
                    <ul className="section-content">
                      {React.Children.toArray(
                        Props.courseItems.map((lesson) => (
                          <CourseItem
                            isSectionFree={Props.isSectionFree}
                            isEnrolled={Props.isEnrolled}
                            courseId={Props.courseId}
                            {...lesson}
                          />
                        ))
                      )}
                    </ul>
                  ) : (
                    <div className="learn-press-message success ml-15 mr-15">
                      <i className="bx bx-info-circle"></i> No items in this
                      section
                    </div>
                  )}
                </>
              ) : (
                <Box sx={{ pt: 0.5, width: "100%" }}>
                  <Skeleton />
                  <Skeleton width="75%" />
                  <Skeleton />
                  <Skeleton width="90%" />
                  <Skeleton />
                  <Skeleton width="75%" />
                  <Skeleton />
                  <Skeleton width="75%" />
                  <Skeleton />
                  <Skeleton width="90%" />
                  <Skeleton />
                  <Skeleton width="75%" />
                </Box>
              )}
            </AccordionDetails>
          </Accordion> */}
        </AccordionDetails>
      </Accordion>
    </li>
  );
};
