import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  ListItem,
  ListItemText,
  Skeleton,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchNotifications } from "../../api/endpoints-functions";
import NotificationItem from "./notification-item";
import i18next from "i18next";
import { Notification } from "../../interfaces/new-api-interfaces/notification";
import { fetchApi } from "../../api/api";
import NotFound from "../../assets/images/notifications/No data-amico.svg";

interface Props {
  setIsModalVisible?: Function;
}

const NotificationsList: React.FC<Props> = ({ setIsModalVisible }) => {
  const queryClient = useQueryClient();
  const { mutate: readNotifications, isLoading: isLoadingReadAll } =
    useMutation(() => fetchApi(`/auth/notification/read-all`, "PUT"), {
      onSuccess: () => {
        setNotifications([]);
        setPage(1);
        setIsChecked(false);
        queryClient.invalidateQueries(["get-notifications", page, isChecked]);
        queryClient.invalidateQueries(["get-notifications-number"]);
      },
      onError: (error) => {
        console.log(error);
      },
    });

  const [page, setPage] = useState(1);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isChecked, setIsChecked] = useState(true);

  const { data, isLoading, isFetching } = useQuery(
    ["get-notifications", page, isChecked],
    () => fetchNotifications(page, 10, isChecked),
    {
      onSuccess: (response) => {
        setNotifications([...notifications, ...response.data.data]);
      },
    }
  );

  const SkeletonCard = () => (
    <ListItem sx={{ width: "320px" }}>
      <ListItemText
        primary={
          <Stack direction="row" justifyContent="space-between">
            <Skeleton width={150} variant="text" />
            <Skeleton width={80} variant="text" />
          </Stack>
        }
        secondary={
          <>
            <Skeleton variant="text" />
            <Skeleton width={100} variant="text" />
          </>
        }
      />
    </ListItem>
  );
  return (
    <>
      <Stack gap={1} sx={{ paddingBottom: "20px" }}>
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent:
              isChecked && notifications.length != 0
                ? "space-between"
                : "flex-end",
            alignItems: "center",
            width: "100%",
            padding: i18next.language == "ar" ? "0 10px" : "",
          }}
        >
          {isChecked == true && notifications.length != 0 && (
            <Button
              onClick={() => {
                readNotifications();
              }}
              disabled={isLoadingReadAll}
            >
              {!isLoadingReadAll ? (
                <Typography variant="subtitle2" sx={{ whiteSpace: "nowrap" }}>
                  {i18next.language == "ar" ? "قراءة الكل" : "mark all as read"}
                </Typography>
              ) : (
                <Stack>
                  <CircularProgress size={20} />
                </Stack>
              )}
            </Button>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={isChecked}
                  onChange={(e) => {
                    setNotifications([]);
                    setPage(1);
                    setIsChecked(e.target.checked);
                  }}
                />
              }
              label={i18next.language === "ar" ? "غير مقروءة" : "Not Read"}
              sx={{ whiteSpace: "nowrap", direction: "rtl", margin: 0 }}
            />
          </Box>
        </Stack>
        {notifications.length > 0 ? (
          <>
            {notifications?.map((notification, index) => (
              <NotificationItem
                key={index}
                notification={notification}
                setIsModalVisible={setIsModalVisible!}
              />
            ))}
          </>
        ) : !isLoading && !isFetching ? (
          <Box p={4} maxWidth="420px">
            <img
              src={NotFound}
              style={{ objectFit: "contain", width: "100%" }}
            />
            <Typography mt={3} textAlign="center" variant="h6">
              {i18next.language === "en"
                ? "you don't have any notifications"
                : "لا يوجد لديك أي إشعارات"}
            </Typography>
          </Box>
        ) : null}
        {(isLoading || isFetching) && (
          <Stack justifyContent="center" alignItems="center">
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
          </Stack>
        )}
        {data?.meta.lastPage != data?.meta.currentPage &&
          !isLoading &&
          !isFetching && (
            <Button
              sx={{ marginTop: "10px" }}
              onClick={() => {
                setPage(page + 1);
              }}
            >
              <Typography>
                {i18next.language == "en" ? "view more" : "عرض المزيد"}
              </Typography>
            </Button>
          )}
      </Stack>
    </>
  );
};

export default NotificationsList;
