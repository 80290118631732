import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import PagesItem from "./header-mobile-items/pages-item";
import ShopItem from "./header-mobile-items/shop-item";
import CoursesItem from "./header-mobile-items/courses-item";
import Divider from "@mui/material/Divider/Divider";
import { useNavigate } from "react-router-dom";
import { MenuItem, Select, Typography } from "@mui/material";
import {
  FaInstagram,
  FaLinkedinIn,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa6";
import { PiGlobe } from "react-icons/pi";
import { useTranslation } from "react-i18next";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";

type Anchor = "right";

export default function SwipeableTemporaryDrawer({
  isLogin,
}: {
  isLogin: boolean;
}) {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };
  const handleNavigation = (path: string) => {
    setState((prevState) => ({ ...prevState, right: false })); // Close the drawer
    navigate(path);
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ auto: 250, width: "60vw" }}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
      fontSize={"1.2rem"}
    >
      <List>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            margin: "20px 0",
            justifyContent: "center",
          }}
        >
          <Select
            sx={{ width: { xs: "80%", sm: "40%" } }}
            size="small"
            MenuProps={{ disableScrollLock: true }}
            value={i18n.language}
            onChange={(event) => {
              setState((prevState) => ({ ...prevState, right: false }));
              i18n.changeLanguage(event.target.value);
              localStorage.setItem("lang", event.target.value);
            }}
            style={{ color: "#3f3a64" }}
            IconComponent={PiGlobe}
          >
            <MenuItem value="en">EN</MenuItem>
            <MenuItem value="ar">AR</MenuItem>
          </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "20px 0",
          }}
        >
          <Typography variant="body1" sx={{ color: "primary.main" }}>
            {i18n.language === "en" ? "Follow Us:" : ": تابعنا"}
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              padding: "5px 20px",
              alignItems: "center",
            }}
          >
            <Box className="header-social">
              <FaLinkedinIn color="#fff" />
            </Box>
            <Box className="header-social">
              <FaYoutube color="#fff" />
            </Box>
            <Box className="header-social">
              <FaInstagram color="#fff" />
            </Box>
            <Box className="header-social">
              <FaWhatsapp color="#fff" />
            </Box>
          </Box>
        </Box>
        <ListItem key="learning">
          <CoursesItem handleNavigation={handleNavigation} isLogin={isLogin} />
        </ListItem>
        <Divider />
        <ListItem
          key="consultations"
          onClick={() => handleNavigation("consultations")}
        >
          <ListItemButton>
            <ListItemText primary={t("consultations")} />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem key="shop">
          <ShopItem handleNavigation={handleNavigation} />
        </ListItem>
        <Divider />
        <ListItem key="pages">
          <PagesItem handleNavigation={handleNavigation} tos />
        </ListItem>
        <Divider />
        <ListItem key="pages">
          <PagesItem handleNavigation={handleNavigation} />
        </ListItem>
      </List>
    </Box>
  );

  const cacheRtl = createCache({
    key: "muiltr",
    stylisPlugins: undefined,
  });

  return (
    <div>
      <button className="toggle" onClick={toggleDrawer("right", !state.right)}>
        <i className="icon-top"></i>
        <i className="icon-middle"></i>
        <i className="icon-bottom"></i>
      </button>
      <CacheProvider value={cacheRtl}>
        <SwipeableDrawer
          anchor="right"
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
          onOpen={toggleDrawer("right", true)}
        >
          {list("right")}
        </SwipeableDrawer>
      </CacheProvider>
    </div>
  );
}
