import i18n from "i18next";
import DisplayMatchAnswer from "./display-match";
import { CustomTextarea } from "./../helpers/custom-textarea";
import { Test } from "../../interfaces/api-interfaces";
import { studentCorrectedTest } from "../../interfaces/new-api-interfaces/test";
import { Box, Button, Typography } from "@mui/material";
import { defaultTranslation } from "../../untilites/functions";
import i18next from "i18next";
import DisplayOptionalAnswers from "./display-optional-answers";
import { ErrorFallBackNotFound } from "../Common/error-handling/error-404";
import { useNavigate } from "react-router-dom";
import DisplayWrittenAnswers from "./display-written-answers";

interface Props {
  questions: studentCorrectedTest["answers"];
  isGraded: boolean | 0 | 1;
  totalMark: number;
}

const DisplayCorrectedTest: React.FC<Props> = ({
  questions,
  isGraded,
  totalMark,
}) => {
  const navigate = useNavigate();
  return (
    <section
      className="container"
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        paddingBottom: "50px",
      }}
    >
      {questions?.length > 0 && (
        <div className="p-10" style={{ width: "100%" }}>
          {/* <div className="d-flex justify-content-center align-items-center mb-5 container mx-auto">
            <Typography variant="h3" color="primary">
              Test Correction : {defaultTranslation(test, "name")}
            </Typography>
          </div> */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
            {questions?.map((question, index: number) => (
              <Box sx={{ border: "solid 1px #ccc" }} key={question.id}>
                <Box sx={{ bgcolor: "mypurple2.main", padding: "20px" }}>
                  <Typography variant="h5" color="#fff">
                    {i18next.language == "en"
                      ? `# ${question.order} Question`
                      : `السؤال # ${question.order}`}
                  </Typography>
                </Box>
                {question.type == "written" ? (
                  <DisplayWrittenAnswers
                    question={question}
                    isTrue={question.written_answers[0].is_true}
                    value={question.written_answers[0].value}
                    isGraded={isGraded}
                  />
                ) : question.type == "match" ? (
                  <DisplayMatchAnswer question={question} isGraded={isGraded} />
                ) : (
                  <div>
                    <DisplayOptionalAnswers
                      question={question}
                      isGraded={isGraded}
                    />
                  </div>
                )}
              </Box>
            ))}
          </Box>
        </div>
      )}
      {questions.length == 0 && <ErrorFallBackNotFound />}
    </section>
  );
};
export default DisplayCorrectedTest;
