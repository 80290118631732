import { Skeleton, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import DisplayFile from "../course-grid-components/display-file";
import { getImageFromServer } from "./../../../assets/JS/helpers";

interface Props {
  desctiption: string;
  descriptionVideo: string;
  isLoading: boolean;
}

export const CourseOverview: React.FC<Props> = ({
  descriptionVideo,
  desctiption,
  isLoading,
}) => {
  const { t } = useTranslation();
  return (
    <div className="course-overview">
      {/* <Typography>{desctiption}</Typography> */}
      <div className="overview-course-video">
        {!isLoading ? (
          <DisplayFile
            file={descriptionVideo ? getImageFromServer(descriptionVideo) : ""}
            type="video"
          />
        ) : (
          <Skeleton
            variant="rectangular"
            width={700}
            height={300}
            animation="wave"
          />
        )}
      </div>
    </div>
  );
};
