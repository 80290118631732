import { Box, Button, Skeleton, Typography } from "@mui/material";
import React from "react";
import FreeCard from "../home-page-components/free-card";
import { useQuery } from "@tanstack/react-query";
import i18n from "i18next";
import { fetchTopFreeItems } from "../../../api/endpoints-functions";
import { LoadingPage } from "../../Common/loadingPage.component";
import AltFreeCard from "../home-page-components/alt-free-card";

type Props = {};

export default function AltFreeSection({}: Props) {
  const { isLoading, data } = useQuery(["frees"], () => fetchTopFreeItems());

  if (isLoading) return <LoadingPage />;

  return (
    <Box className="container">
      <Box
        className="section"
        sx={{ padding: { xs: "50px 0px ", sm: "100px 0px " } }}
      >
        {!isLoading && data?.length! > 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box data-aos="fade-up">
              <Typography
                variant="h1"
                sx={{
                  textAlign: "center",
                }}
                color="primary"
              >
                {i18n.language === "en" ? (
                  <>Free Items</>
                ) : (
                  <>العناصر المجانية</>
                )}
              </Typography>

              {/* Introduction Text */}
              <Typography variant="h5" sx={{ textAlign: "center", mt: 2 }}>
                {i18n.language === "en" ? (
                  <>
                    Explore our curated collection of free resources to enhance
                    your leadership skills and personal development journey.
                  </>
                ) : (
                  <>
                    اكتشف مجموعتنا المختارة من الموارد المجانية لتعزيز مهارات
                    القيادة ورحلتك في التطوير الشخصي.
                  </>
                )}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: { xs: "40px 0", md: "50px 0px" },
                width: "100%",
              }}
              className="row"
            >
              {data!.map((free, index) => (
                <Box
                  key={index}
                  sx={{
                    flex: "1 1 auto",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%", // Full width on smaller screens
                    maxWidth: { xs: "100%", sm: "45%", md: "25%" },
                  }}
                  className="col-12 col-md-6 col-lg-3"
                >
                  <AltFreeCard free={free} />
                </Box>
              ))}
            </Box>
          </Box>
        ) : (
          <Skeleton />
        )}
      </Box>
    </Box>
  );
}
